// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { API_BO_URL } from '../../utils/config'
import { receiveCurrentBatchLease, requestSearchBatches } from '../batches/actions'
import { fetchChangeSettings } from '../settings/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'

export const DISPLAY_JSON = 'DISPLAY_JSON'
export const HIDE_JSON = 'HIDE_JSON'
export const REQUEST_JSON = 'REQUEST_JSON'

export function displayJson(json) {
  return {
    type: DISPLAY_JSON,
    visibility: true,
    json: json,
  }
}
export function hideJson() {
  return {
    type: HIDE_JSON,
    visibility: false,
  }
}
export function requestDisplayJson(collection) {
  return {
    type: REQUEST_JSON,
    visibility: true,
    collection: collection,
  }
}

export function fetchDisplayJson(jsonId, collection) {
  return async (dispatch) => {
    dispatch(requestDisplayJson(collection))
    return fetch(`${API_BO_URL()}.${collection}/${jsonId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(displayJson(json))
      })
      .catch((error) => {
        dispatch(hideJson())
        if (error.status === 403) {
          return dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get JSON',
          <Fragment>
            <div>An error does not allow to get the JSON:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}

export function fetchHideJson() {
  return async (dispatch) => {
    return dispatch(hideJson())
  }
}
