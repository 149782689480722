// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useImperativeHandle,
  useState,
} from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { GridMoreVertIcon, GridColumnMenu, GridActionsCellItem } from '@mui/x-data-grid-pro'
import {
  copyToClipboard,
  getClickAction,
  isClippyActivated,
  StripedDataGridPro,
  tellMeMore,
} from '../../utils/utilities'
import { t } from 'i18next'
import { useGridApiRef } from '@mui/x-data-grid'
import clsx from 'clsx'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { CustomTooltip, NumberOneIcon } from '../common/Style'
import { CustomNoRowsOverlay } from './Tools'
import Loading from '../Loading'
import { displayPopupMessage } from '../common/Message'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import ExpandIcon from '@mui/icons-material/Expand'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import Menu from '@mui/material/Menu'
import NewMenuItem from '@mui/material/MenuItem'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import { fetchAddUserMessage } from '../../redux/assistant/actions'
import { Trans } from 'react-i18next'
import { fetchChangeSettings } from '../../redux/settings/actions'

const useStyles = makeStyles((theme) => ({
  tableCmp: {
    top: 10,
    height: '100%',
    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    fontSize: 14,
    fontWeight: 400,
    padding: 5,
    lineHeight: 1.5,
  },
  trainingButton: {
    width: 24,
    height: 24,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  actionMenu: { justifyContent: 'left', alignItem: 'start' },
  actionDivider: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0px !important',
    margin: '5px 0px',
  },
  formControlLoading: {
    '& .MuiSelect-select.Mui-disabled': {
      cursor: 'wait !important',
    },
  },
  retired: {
    color: '#bd170b',
  },
  loadingBatchIcon: {
    animation: 'circle 1s linear infinite',
    animationDirection: 'reverse',
    fontSize: '2rem',
  },
}))

const defaultState = {
  pinnedColumns: {
    right: ['actions'],
  },
  sorting: {
    sortModel: [
      {
        field: 'global_score',
        sort: 'asc',
      },
    ],
  },
}

// const ChromatoTable = withRouter((props) => {
const ChromatoTableComponent = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    isFetching,
    columns,
    datas,
    onMoleculeClick,
    onMoleculeDoubleClick,
    onSelect,
    forwardedRef,
    chromatoGridRef,
    sortAction,
    tableItemSelectedForColor,
    canEditCalib,
    updateVisibleRowsIds,
    scrollTo,
    onOpenMultiSelectionDialog,
  } = props
  const apiRef = useGridApiRef()
  const autoSizeOptions = { includeHeaders: true, includeOutliers: true, expand: true }
  const isGlobalConfigsLoaded = useSelector((state) => state.global.isGlobalConfigsLoaded)
  const customerConfig = useSelector((state) => state.global.customerConfig)
  const matrix = useSelector(
    (state) =>
      state.analyses.items.filter((analysis) => analysis._id === props.match.params.analysisId)[0]
        ?.content?.sample_infos?.matrice,
  )

  useImperativeHandle(forwardedRef, () => ({
    clearSelection,
    customSelection,
    autosizingColumns,
    clearFilters,
    downloadCSV,
  }))

  // // If some filters are applyed and transfert the info to the chromato grid
  // useEffect(() => {
  //   const isFilters = JSON.parse(localStorage.getItem('ANALYSIS_state'))
  //   const filteredRowsLookup = apiRef?.current?.state?.filter?.filteredRowsLookup
  //   if (
  //     isFilters?.filter?.filterModel?.items?.length > 0 &&
  //     Object.keys(filteredRowsLookup)?.length > 0
  //   ) {
  //     const visibleRows = Object.keys(filteredRowsLookup).filter(
  //       (id) => filteredRowsLookup[id] === true,
  //     )
  //     debugger
  //   }
  // }, [apiRef?.current?.state])

  const featuresConfig = useSelector((state) => state.global.featuresConfig)
  // Load the config for the upload mechanism & ai assistant
  const { aiAssistantActivated } = useMemo(() => {
    if (featuresConfig) {
      return {
        aiAssistantActivated: featuresConfig?.content?.ai_assistant,
      }
    } else {
      return false
    }
  }, [featuresConfig])

  const [contextMenu, setContextMenu] = React.useState(null)
  const [selectedRow, setSelectedRow] = React.useState()
  const handleContextMenu = (event) => {
    event.preventDefault()
    setSelectedRow(event.currentTarget.getAttribute('data-id'))
    setContextMenu(
      contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null,
    )
  }
  const handleCloseContextMenu = () => {
    setContextMenu(null)
  }

  const clearSelection = useCallback(() => {
    setRowSelectionModel([])
    if (chromatoGridRef) chromatoGridRef.current.customSelection([])
    if (onSelect) onSelect([])
  }, [chromatoGridRef, onSelect])

  const clearFilters = useCallback(() => {
    if (apiRef?.current?.state?.filter?.filterModel?.items.length > 0) {
      setOpenConfirmResetFilter(true)
    } else {
      displayPopupMessage(
        dispatch,
        'info',
        t('view.analysis.chromatoTable.confirmResetFilter.title'),
        <Fragment>
          <div>{t('view.analysis.chromatoTable.confirmResetFilter.empty')}</div>
        </Fragment>,
      )
    }
  }, [apiRef, dispatch])

  const downloadCSV = useCallback(() => {
    if (apiRef?.current)
      apiRef.current.exportDataAsCsv({
        delimiter: ';',
        // utf8WithBom: true,
      })
  }, [apiRef])

  const autosizingColumns = useCallback(() => {
    apiRef.current.autosizeColumns(autoSizeOptions)
  }, [apiRef])

  const resetColumns = useCallback(() => {
    const cols = columns.map((col) => col.field)
    const dim = apiRef.current.exportState().columns.dimensions
    const colNames = Object.keys(dim)
    colNames.forEach((col) => (dim[col].width = 90))

    apiRef.current.setFilterModel({
      items: [],
      logicOperator: 'and',
      quickFilterLogicOperator: 'and',
      quickFilterValues: [],
    })
    apiRef.current.restoreState({
      ...defaultState,
      columns: {
        dimensions: { ...dim, actions: { maxWidth: 50, minWidth: 50, width: 50 } },
        orderedFields: cols,
        columnVisibilityModel: {},
      },
    })
    localStorage.removeItem('ANALYSIS_state')
  }, [apiRef, columns, defaultState])

  const customSelection = useCallback(
    (selection, fromchromatoGrid) => {
      setRowSelectionModel(selection.map((sel) => apiRef.current.getAllRowIds()[sel]))
      if (chromatoGridRef && !fromchromatoGrid) chromatoGridRef.current.customSelection(selection)
      if (onSelect) onSelect(selection)
    },
    [apiRef, chromatoGridRef, onSelect],
  )

  const [settingsDecimals, setSettingsDecimals] = useState({})
  const [settingsFormat, setSettingsFormat] = useState({})
  const [currentVisibleRows, setCurrentVisibleRows] = useState(null)
  const [openConfirmResetFilter, setOpenConfirmResetFilter] = React.useState(false)

  useEffect(() => {
    const index = datas?.map((uaf) => uaf._id)?.indexOf(scrollTo)
    // const table = document.getElementById(idTable).firstElementChild.firstElementChild
    //   .children[1].children[1]
    // table.scrollTo({
    //   top: index * 40,
    //   // behavior: 'smooth',
    // })

    // setSelected(scrollTo)
    // setScrollToRow(index + 1)
    if (datas.length > 0 && apiRef?.current?.scrollToIndexes) {
      try {
        apiRef.current.scrollToIndexes({
          rowIndex: Math.max(0, index),
          colIndex: 0,
        })
      } catch (error) {
        console.info('The table is not yet available to scroll to the previous position\n', error)
      }
    }
  }, [scrollTo, datas.length])

  // const delta = useSelector((state) => state.settings.dateTimeDelta)
  // const items = useSelector((state) => state.batches.items)
  // const isFetching = useSelector((state) => state.batches.isFetching)

  const showRetired = localStorage.getItem('SETTINGS_show_retired')
    ? localStorage.getItem('SETTINGS_show_retired') === 'true'
    : false
  const [rowSelectionModel, setRowSelectionModel] = React.useState([])
  const [settingsTimer, setSettingsTimer] = React.useState(null)
  const [sortModel, setSortModel] = React.useState(
    JSON.parse(localStorage.getItem('ANALYSIS_state'))?.sorting?.sortModel
      ? JSON.parse(localStorage.getItem('ANALYSIS_state')).sorting.sortModel
      : [
          {
            field: 'global_score',
            sort: 'asc',
          },
        ],
  )

  // const isLoading = function (row) {
  //   return !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment'
  // }

  // function autosizingColumns() {
  //   apiRef.current.autosizeColumns(autoSizeOptions)
  // }

  const columnsMemoized = useMemo(() => {
    // Apply the good order at each column render
    const order = JSON.parse(localStorage.getItem('ANALYSIS_state'))?.columns?.orderedFields
    const compareOrder = (a, b) => {
      const indexA = order?.indexOf(a.field)
      const indexB = order?.indexOf(b.field)
      if (indexA === -1 || indexB === -1) {
        return 0
      }
      return indexA - indexB
    }

    const settingsFormatSaved = localStorage.getItem('TABLE_myAnalyseMoleculesTable_columns_format')
      ? localStorage
          .getItem('TABLE_myAnalyseMoleculesTable_columns_format')
          .split('[|,|]')
          .reduce(function (map, obj) {
            map[obj.split('[|:|]')[0]] = obj.split('[|:|]')[1]
            return map
          }, {})
      : {}
    const columnFormat = localStorage.getItem('TABLE_myAnalyseMoleculesTable_columns_format')
      ? columns.reduce(function (map, obj) {
          map[obj.field] =
            typeof settingsFormatSaved[obj.field] === 'string'
              ? settingsFormatSaved[obj.field]
              : 'auto'
          return map
        }, {})
      : columns.reduce(function (map, obj) {
          map[obj.field] = 'auto'
          return map
        }, {})
    setSettingsFormat(columnFormat)

    const settingsDecimalsSaved = localStorage.getItem(
      'TABLE_myAnalyseMoleculesTable_columns_decimals',
    )
      ? localStorage
          .getItem('TABLE_myAnalyseMoleculesTable_columns_decimals')
          .split('[|,|]')
          .reduce(function (map, obj) {
            map[obj.split('[|:|]')[0]] = parseInt(obj.split('[|:|]')[1])
            return map
          }, {})
      : {}
    const columnDecimals = localStorage.getItem('TABLE_myAnalyseMoleculesTable_columns_decimals')
      ? columns.reduce(function (map, obj) {
          map[obj.field] =
            typeof settingsDecimalsSaved[obj.field] === 'number'
              ? settingsDecimalsSaved[obj.field]
              : 3
          return map
        }, {})
      : columns.reduce(function (map, obj) {
          map[obj.field] = 3
          return map
        }, {})
    setSettingsDecimals(columnDecimals)
    // return column and sort it by the good order
    return columns
      .map((col) => ({
        ...col,
        columnInfos: { decimals: columnDecimals[col.field], format: columnFormat[col.field] },
        hideHeaderTooltip:
          col.field === 'global_score' &&
          isGlobalConfigsLoaded &&
          customerConfig &&
          customerConfig.content.hasOwnProperty('columnIA') &&
          customerConfig.content.columnIA.hasOwnProperty('hideWikiTooltip') &&
          customerConfig.content.columnIA.hideWikiTooltip,
      }))
      .sort(compareOrder)
  }, [columns])

  // useEffect(() => {
  //   if (batches.length >= 100) {
  //     displayPopupMessage(
  //       dispatch,
  //       'info',
  //       t('view.backlog.info_date.title'),
  //       <Fragment>
  //         <div>{t('view.backlog.info_date.msg1')}</div>
  //         <div>{t('view.backlog.info_date.msg2')}</div>
  //       </Fragment>,
  //     )
  //   }
  // }, [batches])

  // function CustomActionIcon() {
  //   return (
  //     <CustomTooltip
  //       placement="top"
  //       arrow
  //       title={t('view.panel.batchesCmp.tooltip.other')}
  //     >
  //       <GridMoreVertIcon />
  //     </CustomTooltip>
  //   )
  // }
  const CustomActionIcon = React.memo(() => {
    return (
      <CustomTooltip placement="top" arrow title={t('view.panel.batchesCmp.tooltip.other')}>
        <GridMoreVertIcon />
      </CustomTooltip>
    )
  })

  const CustomLoading = React.memo(() => {
    return (
      <div style={{ height: 'calc(100% - 96px)', position: 'relative' }}>
        <Loading mode="miniCircle" />
      </div>
    )
  })

  const onFormatChanged = useCallback(
    (event, field) => {
      event.stopPropagation()
      event.preventDefault()
      const value = event.target.value
      localStorage.setItem(
        'TABLE_myAnalyseMoleculesTable_columns_format',
        Object.entries({ ...settingsFormat, [field]: value })
          .map((format) => format[0] + '[|:|]' + format[1])
          .join('[|,|]'),
      )
      setSettingsFormat({ ...settingsFormat, [field]: value })
    },
    [settingsFormat],
  )

  const onDecimalsChanged = useCallback(
    (event, field, forceSave, checkbox) => {
      // const value = event.target.value
      // localStorage.setItem(
      //   'TABLE_myAnalyseMoleculesTable_columns_decimals',
      //   Object.entries({...settingsDecimals, [field]: value})
      //     .map((decimals) => decimals[0] + '[|:|]' + decimals[1])
      //     .join('[|,|]'),
      // )
      // setSettingsDecimals({ ...settingsDecimals, [field]: value })

      function save(val) {
        localStorage.setItem(
          'TABLE_myAnalyseMoleculesTable_columns_decimals',
          Object.entries({ ...settingsDecimals, [field]: val })
            .map((decimals) => decimals[0] + '[|:|]' + decimals[1])
            .join('[|,|]'),
        )
        setSettingsDecimals({ ...settingsDecimals, [field]: val })
      }
      const value = checkbox
        ? event.target.checked
          ? 0
          : -1
        : parseFloat(event.currentTarget.value)
      clearTimeout(settingsTimer)
      if (forceSave) {
        // setSettingsDecimals({ ...settingsDecimals, [label]: value })
        save(value)
      } else {
        setSettingsTimer(
          setTimeout(function () {
            // setSettingsDecimals({ ...settingsDecimals, [label]: value })
            save(value)
          }, 1500),
        )
      }
    },
    [settingsDecimals, settingsTimer],
  )

  const copyToClipboardColumn = useCallback(
    (event, filed) => {
      // Keep only the filtered values if it's the case
      const values = datas
        .filter((d) => (currentVisibleRows ? currentVisibleRows.includes(d._id) : d))
        .map((d) => d[filed])
      const valuesFormated = values.join('\n')
      copyToClipboard(event, valuesFormated)
    },
    [datas, currentVisibleRows],
  )

  const CustomNumberFormat = React.memo((props) => {
    const { myCustomFormat, myCustomDecimal, field } = props
    return (
      <MenuItem
        disabled
        style={{ padding: 15, opacity: 1 }}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
        }}
      >
        <ListItemIcon style={{ minWidth: 36 }}>
          <NumberOneIcon fontSize="small" />
        </ListItemIcon>
        <ListItemSecondaryAction
          style={{ top: 6, marginLeft: 52, width: 'auto', transform: 'none' }}
        >
          <FormControl
            size="small"
            style={{ marginRight: 5, width: 'calc(50% - 3px)' }}
            variant="outlined"
          >
            <InputLabel>Format</InputLabel>
            <Select
              variant="outlined"
              className={classes.selectFormat}
              value={myCustomFormat}
              label={t('view.common.settingsView.tableTab.format')}
              // open={open[columnKey]}
              // onClose={() => handleClose(columnKey)}
              // onOpen={() => handleOpen(columnKey)}
              onChange={(event) => {
                event.stopPropagation()
                event.preventDefault()
                onFormatChanged(event, field)
              }}
            >
              <MenuItem key={'auto'} value={'auto'}>
                {t('view.common.settingsView.tableTab.auto')}
              </MenuItem>
              <MenuItem key={'std'} value={'std'}>
                {t('view.common.settingsView.tableTab.std')}
              </MenuItem>
              <MenuItem key={'sci'} value={'sci'}>
                {t('view.common.settingsView.tableTab.sci')}
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={clsx(classes.columnSizetextfield, classes.columnDecimals)}
            style={{ width: 'calc(50% - 3px)', background: 'white' }}
            label={t('view.common.settingsView.tableTab.decimals')}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start" style={{ width: 10 }}>
                  <Checkbox
                    checked={myCustomDecimal !== -1 && myCustomFormat !== 'auto'}
                    disabled={myCustomFormat === 'auto'}
                    style={{ padding: 0 }}
                    size="small"
                    onChange={(event) => onDecimalsChanged(event, field, true, true)}
                  />
                </InputAdornment>
              ),
            }}
            // disabled={decimals === -1 || format === 'auto'}
            variant="outlined"
            size="small"
            defaultValue={
              myCustomDecimal === -1 || myCustomFormat === 'auto' ? null : myCustomDecimal
            }
            onChange={(event) => onDecimalsChanged(event, field)}
            onBlur={(e) => onDecimalsChanged(e, field, true)}
          />
        </ListItemSecondaryAction>
      </MenuItem>
    )
  })

  const CustomCopyClipboard = React.memo((props) => {
    const { myCustomLabel, myCustomHandler } = props
    return (
      <MenuItem style={{ padding: 2, opacity: 1 }} onClick={myCustomHandler}>
        <ListItemIcon style={{ minWidth: 36 }}>
          <FileCopyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomLabel}</ListItemText>
      </MenuItem>
    )
  })

  const CustomAdaptSize = React.memo((props) => {
    const { myCustomLabel, myCustomHandler } = props
    return (
      <MenuItem style={{ padding: 2, opacity: 1 }} onClick={myCustomHandler}>
        <ListItemIcon style={{ minWidth: 36 }}>
          <ExpandIcon fontSize="small" style={{ rotate: '90deg' }} />
        </ListItemIcon>
        <ListItemText>{myCustomLabel}</ListItemText>
      </MenuItem>
    )
  })

  const CustomResetProps = React.memo((props) => {
    const { myCustomLabel, myCustomHandler } = props
    return (
      <MenuItem style={{ padding: 2, opacity: 1 }} onClick={myCustomHandler}>
        <ListItemIcon style={{ minWidth: 36 }}>
          <RestartAltIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomLabel}</ListItemText>
      </MenuItem>
    )
  })

  const CustomColumnMenu = React.memo((props) => {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          // Add new item
          columnMenuNumberFormat: props.colDef.customFormat ? CustomNumberFormat : null,
          columnMenuCopyClipboard: props.colDef.type !== 'actions' ? CustomCopyClipboard : null,
          columnMenuAdaptSize: props.colDef.type === 'actions' ? CustomAdaptSize : null,
          columnMenuResetProps: props.colDef.type === 'actions' ? CustomResetProps : null,
        }}
        slotProps={{
          columnMenuNumberFormat: {
            field: props.colDef.field,
            displayOrder: 15,
            myCustomFormat: settingsFormat[props.colDef.field],
            myCustomDecimal: settingsDecimals[props.colDef.field],
          },
          columnMenuCopyClipboard: {
            field: props.colDef.field,
            displayOrder: 35,
            myCustomLabel: t('view.common.table_multigrid.copy_clipboard'),
            myCustomHandler: (e) => copyToClipboardColumn(e, props.colDef.field),
          },
          columnMenuAdaptSize: {
            field: props.colDef.field,
            displayOrder: 10,
            myCustomLabel: t('view.backlog.menu.align'),
            myCustomHandler: autosizingColumns,
          },
          columnMenuResetProps: {
            field: props.colDef.field,
            displayOrder: 10,
            myCustomLabel: t('view.backlog.menu.reset'),
            myCustomHandler: resetColumns,
          },
        }}
      />
    )
  })

  const stateChanged = React.useCallback(() => {
    // Send the filter info to the chromato grid
    const isAFilters = apiRef?.current?.state?.filter?.filterModel?.items?.length > 0
    const filteredRowsLookup = apiRef?.current?.state?.filter?.filteredRowsLookup
    const visibleRows = isAFilters
      ? Object.keys(filteredRowsLookup).filter((id) => filteredRowsLookup[id] === true)
      : null
    if (JSON.stringify(currentVisibleRows) !== JSON.stringify(visibleRows)) {
      updateVisibleRowsIds(visibleRows)
      setCurrentVisibleRows(visibleRows)
    }
    // Save the state
    const stateSnapshot = apiRef.current.exportState()
    if (Object.keys(stateSnapshot).length !== 0) {
      // if (
      //   JSON.stringify(JSON.parse(localStorage.getItem('ANALYSIS_state')).columns.orderedFields) !==
      //   JSON.stringify(stateSnapshot.columns.orderedFields)
      // ) {
      //   debugger
      // }
      localStorage.setItem('ANALYSIS_state', JSON.stringify(stateSnapshot))
    }
  }, [apiRef, currentVisibleRows])

  function handleCloseConfirmResetFilter(event, confirm) {
    setOpenConfirmResetFilter(false)
    if (confirm) {
      apiRef.current.setFilterModel({
        items: [],
        logicOperator: 'and',
        quickFilterLogicOperator: 'and',
        quickFilterValues: [],
      })
    }
  }

  function handlerTellMeMore() {
    let row = datas.filter((data) => data._id === selectedRow)[0]
    if (row) {
      tellMeMore(dispatch, row, matrix)
    }
    handleCloseContextMenu()
  }

  return (
    <Fragment>
      <Dialog open={openConfirmResetFilter} onClose={handleCloseConfirmResetFilter}>
        <DialogTitle id="alert-dialog-title">
          {t('view.analysis.chromatoTable.confirmResetFilter.title')}
        </DialogTitle>
        {apiRef?.current?.state?.filter?.filterModel?.items.length > 0 ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('view.analysis.chromatoTable.confirmResetFilter.msg')}
            </DialogContentText>
            <DialogContentText>
              <ul>
                {apiRef?.current?.state?.filter?.filterModel?.items.map((filter) => (
                  <li>
                    {apiRef?.current?.state?.columns?.lookup?.[filter.field]?.headerName +
                      ' ' +
                      filter.operator +
                      (filter.value ? ' ' + filter.value : '')}
                  </li>
                ))}
              </ul>
            </DialogContentText>
            <DialogContentText>
              {t('view.analysis.chromatoTable.confirmResetFilter.operator') +
                ': ' +
                apiRef?.current?.state?.filter?.filterModel?.logicOperator}
            </DialogContentText>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText id="no-items">
              {t('view.analysis.chromatoTable.confirmResetFilter.empty')}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {apiRef?.current?.state?.filter?.filterModel?.items.length > 0 && (
            <Button onClick={(event) => handleCloseConfirmResetFilter(event, true)} autoFocus>
              {t('view.analysis.chromatoTable.confirmResetFilter.ok')}
            </Button>
          )}
          <Button onClick={handleCloseConfirmResetFilter}>
            {t('view.analysis.chromatoTable.confirmResetFilter.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.tableCmp}>
        <ErrorBoundaryGuard>
          <StripedDataGridPro
            checkboxSelection
            disableRowSelectionOnClick
            onRowClick={(params, event, details) => {
              const openFct = () => {
                onMoleculeClick(params.row)
              }
              getClickAction(dispatch, event, params.row, 'unitary_analysis', openFct)
            }}
            onRowDoubleClick={(params, event, details) => {
              onMoleculeDoubleClick(params.row)
            }}
            onRowSelectionModelChange={(newRowSelectionModel, details) => {
              const tabIndexes = newRowSelectionModel.map((rsm) =>
                details.api.getRowIndexRelativeToVisibleRows(rsm),
              )
              const totalRows = details.api.getRowsCount()
              if (newRowSelectionModel.length === totalRows) {
                onOpenMultiSelectionDialog(tabIndexes)
              }
              setRowSelectionModel(newRowSelectionModel)
              onSelect(tabIndexes)
              // Update the chromato grid selection
              if (chromatoGridRef) chromatoGridRef.current.customSelection(tabIndexes)
            }}
            rowSelectionModel={rowSelectionModel}
            isRowSelectable={(params) => {
              if (!canEditCalib) {
                return params.row.sampleType !== 'Standard'
              } else {
                return true
              }
            }}
            initialState={{
              pinnedColumns: {
                // left: [GRID_TREE_DATA_GROUPING_FIELD],
                right: ['actions'],
              },
              sorting: {
                sortModel,
              },
              ...JSON.parse(localStorage.getItem('ANALYSIS_state')),
              // filter: {
              //   ...JSON.parse(localStorage.getItem('ANALYSIS_state'))?.filter,
              //   filterModel: {
              //     ...JSON.parse(localStorage.getItem('ANALYSIS_state'))?.filter?.filterModel,
              //     quickFilterExcludeHiddenColumns: true,
              //     // quickFilterValues: ['eth']
              //   },
              // },
            }}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => {
              setSortModel(newSortModel)
              sortAction(newSortModel[0]?.field, newSortModel[0]?.sort)
            }}
            // // onFilterModelChange={(params, event, details) => {
            // //   const isFilters = JSON.parse(localStorage.getItem('ANALYSIS_state'))
            // //   const filteredRowsLookup = apiRef?.current?.state?.filter?.filteredRowsLookup
            // //   if (
            // //     isFilters?.filter?.filterModel?.items?.length > 0 &&
            // //     Object.keys(filteredRowsLookup)?.length > 0
            // //   ) {
            // //     const visibleRows = Object.keys(filteredRowsLookup).filter(
            // //       (id) => filteredRowsLookup[id] === true,
            // //     )
            // //     debugger
            // //   }
            // // }}
            onStateChange={stateChanged}
            rowHeight={40}
            loading={isFetching}
            slots={{
              moreActionsIcon: CustomActionIcon,
              noRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
              loadingOverlay: CustomLoading,
              columnMenu: CustomColumnMenu,
              // toolbar: GridToolbar,
            }}
            slotProps={{
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: 'context-menu' },
              },
            }}
            apiRef={apiRef}
            getRowClassName={(params) => {
              let classesAdded = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              if (tableItemSelectedForColor === params.id) {
                classesAdded += ' selection'
              }
              return classesAdded
            }}
            getCellClassName={(params) => {
              return 'customCell'
            }}
            disableVirtualization={datas.length < 50}
            // rowBuffer={40}
            // rowThreshold={20}
            // columnBuffer={2}
            // columnThreshold={2}
            rowBuffer={3}
            rowThreshold={3}
            columnBuffer={3}
            columnThreshold={3}
            rows={isFetching ? [] : datas}
            columns={columnsMemoized}
            pagination={false}
            hideFooter={true}
            style={{ border: 'none' }}
            // Do not use autoSize: conflic with initialState
            // autosizeOnMount
            // autosizeOptions={autoSizeOptions}
            // onResize={() => hideWatermark()} //TODO To be deleted after purchasing a license
          />
        </ErrorBoundaryGuard>
        <Menu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
          }
          slotProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault()
                handleCloseContextMenu()
              },
            },
          }}
        >
          {aiAssistantActivated && (
            <NewMenuItem onClick={handlerTellMeMore}>
              <ListItemIcon>
                {isClippyActivated() ? (
                  <AttachFileIcon fontSize="small" />
                ) : (
                  <SmartToyIcon fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>{t('view.analysis.analysis.tell_me_more')}</ListItemText>
            </NewMenuItem>
          )}
        </Menu>
      </div>
    </Fragment>
  )
}
// export default React.forwardRef((props, forwardedRef) => {
//   return useMemo(() => {
//     return <ChromatoTable {...props} forwardedRef={forwardedRef} />
//   })
// })
// const areEqual = (prevProps, nextProps) => {
//   const propsChanged = Object.keys(nextProps).filter((key) => prevProps[key] !== nextProps[key])
//
//   if (propsChanged.length > 0) {
//     // console.log('Changed props:', propsChanged)
//     propsChanged.forEach((prop) => {
//       // console.log(`${prop} changed from ${prevProps[prop]} to ${nextProps[prop]}`)
//     })
//   }
//
//   return propsChanged.length === 0
// }

const ChromatoTableWithRouter = withRouter(ChromatoTableComponent)

// export default ChromatoTableWithRouter

const ChromatoTable = React.memo(
  React.forwardRef((props, ref) => {
    return <ChromatoTableWithRouter {...props} forwardedRef={ref} />
  }),
  // areEqual,
)

export default ChromatoTable
