// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, Switch, useRouteMatch, withRouter } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Container from '@material-ui/core/Container'
import Backlog from './backlog/Backlog'
import Batch from './batch/Batch'
import Global from './global/Global'
import Analysis from './analysis/Analysis'
import Compound from './compound/Compound'
import Analysis_old from './analysis/Analysis_old'
import Compound_old from './compound/Compound_old'
import NoMatch from './NoMatch'
import NoDatabase from './NoDatabase'
import { fetchGetTasks, fetchGetToolbarTasks } from '../redux/tasks/actions'
import { fetchCleanConfigurationFeatures } from '../redux/configuration/actions'
import { useDispatch } from 'react-redux'
import { DATABASE, USE_OLD_TABLE } from '../utils/config'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    height: 'calc(100% - 0px)',
  },
}))
const RouterComponent = withRouter((props) => {
  const classes = useStyles()
  let match = useRouteMatch()
  const dispatch = useDispatch()

  // Storage of configuration id
  useEffect(function () {
    if (props.location.pathname === `/${DATABASE()}`) {
      // Remove old batch configuration id stored
      localStorage.removeItem('SETTINGS_configuration_profile')
      dispatch(fetchCleanConfigurationFeatures())
    }
  }, [])

  const oldTable = useMemo(() => {
    return USE_OLD_TABLE()
  }, [])

  const getDatabaseName = () => {
    const savedDatabase = localStorage.getItem('SERVICE_dataBase_name')
    return savedDatabase ? savedDatabase : 'no_database'
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Switch>
        <Route exact path="/">
          <Redirect to={`/?${getDatabaseName()}`} />
        </Route>
        <Route exact path={`${match.path}no_database`} component={NoDatabase} />
        <Route exact path={`${match.path}:dataBaseName/:chromatoId?`} component={Backlog} />
        <Route path={`${match.path}:dataBaseName/batch/:batchId/:chromatoId?`} component={Batch} />
        <Route
          path={`${match.path}:dataBaseName/global/:batchId/:chromatoId?`}
          component={Global}
        />
        <Route
          path={`${match.path}:dataBaseName/:batchId/analysis/:analysisId/:chromatoId?`}
          component={oldTable ? Analysis_old : Analysis}
        />
        <Route
          path={`${match.path}:dataBaseName/:batchId/compound/:moleculeEvent/:chromatoId?`}
          component={oldTable ? Compound_old : Compound}
        />
        <Route path="/reload" component={null} key="reload" />
        <Route component={NoMatch} />
      </Switch>
    </Container>
  )
})
export default RouterComponent
