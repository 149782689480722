// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const PERMISSION_ERROR = 'PERMISSION_ERROR'
export const NOT_FONDED = 'NOT_FONDED'
export const NO_ERROR = 'NO_ERROR'
export const DEFAULT_ERROR = 'DEFAULT_ERROR'

export function convertErrorCodeToType(code) {
  switch (code) {
    case 200:
      return NO_ERROR
    case 204:
      return NOT_FONDED
    case 403:
      return AUTHENTICATION_ERROR
    case 606:
      return PERMISSION_ERROR
    default:
      return DEFAULT_ERROR
  }
}