// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { fetchChangeSettings } from '../settings/actions'
import {
  addObjectsToCacheInWorker,
  clearCacheInWorker,
  getUpdatedAndPresentIdsInWorker,
} from '../indexedDbUtils'
import { DEFAULT_CACHE_MAX_SIZE, FLASK_URL } from '../../utils/config'

/**
 * Extracts sorting parameters from a given URL.
 *
 * @param {string} url - The URL to extract sorting parameters from.
 * @returns {Object|null} An object containing the sort field and direction, or null if not found.
 */
function getSorterFromUrl(url) {
  const urlObj = new URL(url)
  const params = urlObj.searchParams

  // Extraire les paramètres de tri et de direction
  const sortField = params.get('sort')
  const sortDir = params.get('dir')

  // Vérifier si les paramètres de tri et de direction existent
  if (!sortField || !sortDir) {
    return null // Retourner null si les paramètres de tri et de direction ne sont pas trouvés
  }
  // Retourner l'objet filtre
  return {
    sortField: sortField,
    sortDir: sortDir,
  }
}

/**
 * Sorts an array of objects based on a specified field and direction.
 *
 * @param {Array} objects - The array of objects to sort.
 * @param {string} sortField - The field to sort the objects by.
 * @param {string} sortDir - The direction to sort the objects ('ASC' for ascending, 'DESC' for descending).
 * @returns {Array} The sorted array of objects.
 */
function sortObjects(objects, sortField, sortDir) {
  return objects.sort((a, b) => {
    if (a[sortField] === b[sortField]) {
      return 0
    }
    if (sortDir === 'ASC') {
      return a[sortField] > b[sortField] ? 1 : -1
    } else if (sortDir === 'DESC') {
      return a[sortField] < b[sortField] ? 1 : -1
    } else {
      return 0 // En cas de direction inconnue, ne pas trier
    }
  })
}

/**
 * Handles the response from a fetch request.
 *
 * @param {Response} response - The response object from the fetch request.
 * @returns {Promise<Object>} A promise that resolves to the parsed JSON data or rejects with an error.
 * @throws {Error} Throws an error if the response is not ok.
 */
function responseTreatment(response) {
  if (!response.ok) {
    const statusText = response.statusText
    const status = response.status
    const url = response.url
    return response.text().then((errorMessage) => {
      const error = new Error(`${statusText} : ${errorMessage}`)
      if (response.headers.get('content-type') === 'application/json') {
        error.stack = JSON.stringify(
          JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
          null,
          2,
        )
      } else {
        error.stack = new Error().stack
      }
      error.statusText = statusText
      error.status = status
      error.url = url
      throw error
    })
  }
  return response.json()
}

/**
 * A Map to store locks based on the URL.
 * Each URL has an associated Promise to ensure sequential fetch operations.
 */
const locks = new Map() // Map pour stocker les locks basés sur l'URL
/**
 * Fetches and populates the cache with data from the specified URL.
 *
 * @param {string} url - The URL to fetch data from.
 * @returns {Promise<Array>} A promise that resolves to the final list of objects.
 */
export function fetchCachePopulate(url) {
  return async (dispatch, getState) => {
    // Check if a lock already exists for this URL
    if (!locks.has(url)) {
      // If there is no lock, we create one with an already resolved Promise
      locks.set(url, Promise.resolve())
    }

    // Retrieve the current Promise (previous lock) for this URL
    const previousLock = locks.get(url)

    // Create a new Promise that will execute after the previous lock
    const currentLock = (async () => {
      await previousLock // Attendez que le lock précédent soit terminé

      try {
        // Trigger the action to indicate the start of the cache update
        dispatch(requestCacheUpdate())

        // The logic of data retrieval and cache update
        const requestOptions = {
          method: 'GET',
          credentials: 'include',
        }

        const includes = 'include=' + ['_id', 'modificationDate'].join('&include=')
        const include_url = url.indexOf('/?') !== -1 ? '&' + includes : '/?' + includes

        // Retrieving data via the first query
        const response1 = await fetch(url + include_url, requestOptions)
        let data1 = await responseTreatment(response1)
        if (
          !data1.hasOwnProperty('size') &&
          !data1.hasOwnProperty('result') &&
          typeof data1 === 'object'
        ) {
          // It's because the request return just an UA, not an array of UA
          // Typically on the global view UA detail
          data1 = { result: [data1], size: 1 }
        }
        const all_uas_to_download = await getUpdatedAndPresentIdsInWorker(data1.result)
        let finalResp = all_uas_to_download.already_present

        // If some objects are not in the cache, retrieve them
        if (all_uas_to_download.to_get.length > 0) {
          const response2 = await fetch(`${FLASK_URL()}/unitary_analysis`, {
            ...requestOptions,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify([
              {
                property: '_id',
                value: all_uas_to_download.to_get,
                filterType: 'array',
                operator: 'in',
              },
            ]),
          })
          const data2 = await responseTreatment(response2)
          // dispatch(updateCache(data2))
          const maxCacheSize =
            (parseInt(localStorage.getItem('SETTINGS_cache_size'), 10) || DEFAULT_CACHE_MAX_SIZE) *
            1024 *
            1024 // 100MB
          const cachePolicy = localStorage.getItem('SETTINGS_cache_policy') || 'fifo'
          addObjectsToCacheInWorker(data2, maxCacheSize, cachePolicy, dispatch)
          finalResp = all_uas_to_download.already_present.concat(data2)
        }

        // Apply sorting if necessary
        const sorter = getSorterFromUrl(url)
        if (sorter) {
          return sortObjects(finalResp, sorter.sortField, sorter.sortDir)
        }

        // Return the final list of objects
        return finalResp
      } catch (error) {
        const authenticationError = error.status === 403
        if (authenticationError) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        throw error // Reject promise with captured error
      } finally {
        // Release the lock by restoring a resolved Promise
        locks.set(url, Promise.resolve())
        dispatch(receiveCacheUpdate())
      }
    })()

    // Associate this new lock (Promise) with the URL in the Lock Map
    locks.set(url, currentLock)

    // Return the new Promise to allow other calls to chain to it
    return currentLock
  }
}

/**
 * Action creator for requesting a cache update.
 *
 * @returns {Object} The action object with type 'REQUEST_CACHE_UPDATE'.
 */
function requestCacheUpdate() {
  return {
    type: 'REQUEST_CACHE_UPDATE',
  }
}
/**
 * Action creator for receiving a cache update.
 *
 * @returns {Object} The action object with type 'RECEIVE_CACHE_UPDATE'.
 */
function receiveCacheUpdate() {
  return {
    type: 'RECEIVE_CACHE_UPDATE',
  }
}
/**
 * Clears the cache by dispatching actions to request and receive a cache update.
 *
 * @returns {Function} A thunk that dispatches the cache clear actions.
 */
export function fetchClearCache() {
  return async (dispatch) => {
    dispatch(requestCacheUpdate())
    clearCacheInWorker(dispatch)
    dispatch(receiveCacheUpdate())
  }
}
