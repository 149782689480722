// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import * as I18n from 'i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  progressBarColo: {
    backgroundColor: theme.palette.secondary.main,
  },
  progressBarError: {
    backgroundColor: theme.palette.error.main,
  },
}))
const Progress = withRouter((props) => {
  // export default function SimulationProgress(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { editable, progress, customMessage, width, style } = props

  // useEffect(function () {}, [dispatch])

  return (
    <div
      style={{
        backgroundColor: '#e6e6e6',
        height: 40,
        opacity: editable ? 0.3 : 1,
        width: width ? width : 200,
        borderRadius: 5,
        ...style,
      }}
    >
      <div
        className={
          customMessage === I18n.t('view.panel.dropzone.sent_ko') ||
          customMessage === 'FAILED' ||
          customMessage === 'CANCELLED'
            ? classes.progressBarError
            : classes.progressBarColo
        }
        style={{
          height: 'inherit',
          textAlign: 'center',
          width: (progress ? progress : 0) + '%',
          borderRadius: 5,
          transition: 'width 500ms ease-in',
        }}
      />
      <div
        style={{
          textAlign: 'center',
          top: '-79%',
          position: 'relative',
          // lineHeight: '40px',
        }}
      >
        {customMessage && customMessage !== null ? customMessage : (progress ? progress : 0) + '%'}
      </div>
    </div>
  )
})
export default Progress
