// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { fetchChangeSettings } from '../settings/actions'
import {
  addObjectsToCacheInWorker,
  getIdListToDownload,
  terminateTheWorker,
} from '../indexedDbUtils'
import {
  API_BO_URL,
  DEFAULT_CACHE_MAX_SIZE,
  FLASK_URL,
  GET_LAST_CACHE_MODIFICATION,
  PREFETCH_LIMIT,
} from '../../utils/config'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'

/**
 * Fetches all unitary analyses for a given batch.
 *
 * @param {string} batchId - The ID of the batch to fetch unitary analyses for.
 * @returns {Function} A thunk that dispatches actions based on the fetch results.
 */
export function fetchAllUnitaryAnalysesForBatch(batchId) {
  return async (dispatch /*, getState*/) => {
    // const { prefetch } = getState()
    const last_modification_date = GET_LAST_CACHE_MODIFICATION()?.['modificationDate']
    let filter = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'modificationDate',
        value: last_modification_date,
        filterType: 'number',
        operator: 'gte',
      },
    ]
    let p = encodeURIComponent(JSON.stringify(filter))
    const url = `${API_BO_URL()}.unitary_analysis/page/?limit=${PREFETCH_LIMIT()}&filter=${p}&sort=modificationDate&dir=ASC`

    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    }
    const includes = '&include=' + ['_id', 'modificationDate'].join('&include=')
    // Retrieving data via the first query
    return fetch(url + includes, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        let data1 = result
        if (
          !data1.hasOwnProperty('size') &&
          !data1.hasOwnProperty('result') &&
          typeof data1 === 'object'
        ) {
          // It's because the request return just an UA, not an array of UA
          // Typically on the global view UA detail
          data1 = { result: [data1], size: 1 }
        }
        const uas_to_download = await getIdListToDownload(data1.result)
        if (uas_to_download?.length > 0) {
          // Request with GET/HEAD method cannot have body -> Use POST
          // Get the UAs object not present in cache
          return fetch(`${FLASK_URL()}/unitary_analysis`, {
            ...requestOptions,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify([
              {
                property: '_id',
                value: uas_to_download,
                filterType: 'array',
                operator: 'in',
              },
            ]),
          })
            .then((response) => response.json())
            .then(async (result) => {
              const maxCacheSize =
                (parseInt(localStorage.getItem('SETTINGS_cache_size'), 10) ||
                  DEFAULT_CACHE_MAX_SIZE) *
                1024 *
                1024 // 100MB
              const cachePolicy = localStorage.getItem('SETTINGS_cache_policy') || 'fifo'

              // Utilisation du Web Worker pour ajouter les objets au cache
              addObjectsToCacheInWorker(result, maxCacheSize, cachePolicy, dispatch)

              // return dispatch(receiveAllUnitaryAnalysesForBatch(result, false))
              localStorage.setItem(
                'CACHE_last_modificationDate',
                JSON.stringify({
                  // [Validation-#384]
                  // In some cases, for exemple if the prefetch page size is set to 100,
                  // and the loading page contains 100 items with the all the same modification date,
                  // we enter a loop.
                  // To avoid this, we add 1 millisecond to the last modification date to be sure
                  // to not enter in the loop.
                  // Cons: Maybe we missed some AUs if the list of AUs with the same modificationDate > page size
                  modificationDate: data1.result?.[data1.result.length - 1]?.modificationDate + 1,
                  batchId: batchId,
                }),
              )
              return data1.result.length
            })
        } else {
          localStorage.setItem(
            'CACHE_last_modificationDate',
            JSON.stringify({
              // [Validation-#384]
              // In some cases, for exemple if the prefetch page size is set to 100,
              // and the loading page contains 100 items with the all the same modification date,
              // we enter a loop.
              // To avoid this, we add 1 millisecond to the last modification date to be sure
              // to not enter in the loop.
              // Cons: Maybe we missed some AUs if the list of AUs with the same modificationDate > page size
              modificationDate:
                data1.result?.[data1.result.length - 1]?.modificationDate + 1 ||
                last_modification_date,
              batchId: batchId,
            }),
          )
          return data1.result.length
        }
      })
      .catch(async (error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        let parsedError = error
        if (error instanceof Response) {
          try {
            parsedError = await error.json()
          } catch (_) {
            parsedError = {
              status: error.status,
              statusText: error.statusText,
              url: error.url,
            }
          }
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to prefetch unitary analyses',
          <Fragment>
            <div>An error occurred while prefetching unitary analyses:</div>
            {generateErrorPart(parsedError)}
          </Fragment>,
        )
        throw error
      })
  }
}

/**
 * Terminates the web worker.
 *
 * @returns {Function} A thunk that terminates the web worker.
 */
export function fetchTerminateTheWorker() {
  return async () => {
    terminateTheWorker()
  }
}
