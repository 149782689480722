// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { API_BO_URL } from '../../utils/config'
import { receiveCurrentBatchLease, requestSearchBatches } from '../batches/actions'
import { fetchChangeSettings } from '../settings/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'

export const REQUEST_ACCOUNTINGS = 'REQUEST_ACCOUNTINGS'
export const RECEIVE_ACCOUNTINGS = 'RECEIVE_ACCOUNTINGS'

export function fetchGetAccountings(accountId, granularity, selectedFromDate, selectedToDate) {
  return async (dispatch) => {
    dispatch(requestGetAccountings())
    let filter = []
    filter.push({
      property: 'content.account_id',
      value: accountId,
      filterType: 'string',
      operator: 'like',
    })
    let f = encodeURIComponent(JSON.stringify(filter))
    return fetch(`${API_BO_URL()}.accounting/?filter=${f}&sort=creationDate&dir=DESC`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveAccountings(json))
      })
      .catch((error) => {
        if (error.status === 403) {
          return dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get accountings',
          <Fragment>
            <div>An error does not allow to get accountings:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}

export function requestGetAccountings(batchId) {
  return {
    type: REQUEST_ACCOUNTINGS,
    // batchId: batchId,
  }
}

export function receiveAccountings(accountings) {
  return {
    type: RECEIVE_ACCOUNTINGS,
    accountings: accountings,
  }
}
