// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import {
  REQUEST_SCREENING_FOR_BATCH,
  RECEIVE_SCREENING_FOR_BATCH,
  RECEIVE_METHOD_FILES,
  REQUEST_METHOD_FILES,
} from './actions'
import React from 'react'

/**
 * Initial state of the reducer.
 */
const initialState = {
  onlyLastFour: true,
  device: null,
  screenings: [],
  methodFiles: [],
  isFetchingMethodFiles: false,
}

/**
 * Reducer function to handle different action types and update the state accordingly.
 *
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action dispatched.
 * @returns {Object} The new state after applying the action.
 */
export default function (state = initialState, action) {
  // Handle different action types
  switch (action.type) {
    case REQUEST_SCREENING_FOR_BATCH:
      return {
        ...state,
        device: action.device,
        onlyLastFour: action.onlyLastFour,
      }

    case RECEIVE_SCREENING_FOR_BATCH:
      return {
        ...state,
        screenings: action.screenings,
      }

    case REQUEST_METHOD_FILES:
      return {
        ...state,
        isFetchingMethodFiles: true,
      }
    case RECEIVE_METHOD_FILES:
      return {
        ...state,
        methodFiles: action.methodFiles,
        isFetchingMethodFiles: false,
      }
    default:
      return state
  }
}
