// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, Suspense, useEffect, useMemo, useRef } from 'react'
import { alpha, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@mui/material/IconButton'
import Avatar from '@material-ui/core/Avatar'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { withRouter } from 'react-router-dom'
import {
  API_URL,
  CONFIGURATION_PROFILE,
  FLASK_URL,
  GET_PRIMARY_COLOR,
  GET_SECONDARY_COLOR,
  LANGUAGES,
  PUBLIC_URL,
  SERVICE,
  ZIP_LEVEL,
  FLASK_BASE_URL,
  DATABASE,
  USE_CACHE_DEFAULT,
} from '../utils/config'
import DeleteIcon from '@material-ui/icons/Delete'
import RefreshIcon from '@material-ui/icons/Refresh'
import Message, { displayPopupMessage, generateErrorPart } from './common/Message'
import { isLeasePassed, saveDateTimeDelta, getParentDomain } from '../utils/utilities'
import { genericStyles, NinjaPerson, TransitionBottom } from './common/Style'
import { connect, useSelector, useDispatch } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import SettingsIcon from '@material-ui/icons/Settings'
import TextField from '@material-ui/core/TextField'
import HomeIcon from '@material-ui/icons/Home'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import clsx from 'clsx'
import ClearIcon from '@material-ui/icons/Clear'
import Search from './common/Search'
import Popover from '@material-ui/core/Popover'
import { searchAnalyses } from '../redux/analyses/actions'
import WarningIcon from '@material-ui/icons/Warning'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { HexColorPicker } from 'react-colorful'
import 'react-colorful/dist/index.css'
import { Tooltip } from '@material-ui/core'
import {
  searchBatches,
  fetchCurrentBatchLease,
  receiveCurrentBatchLease,
} from '../redux/batches/actions'
import DashboardActivitiesComponent from './common/DashboardActivitiesComponent'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import DatabaseCmp from './common/DatabaseCmp'
import TuneIcon from '@material-ui/icons/Tune'
import Grid from '@material-ui/core/Grid'
import DatabaseInput from './common/DatabaseInput'
import DomainIcon from '@material-ui/icons/Domain'
import Chip from '@material-ui/core/Chip'
import { fetchChangeSettings } from '../redux/settings/actions'
import { Trans, useTranslation } from 'react-i18next'
import UploadIcon from '@mui/icons-material/Upload'
import i18n from 'i18next'
import { Stack } from '@mui/material'
import Slider from '@material-ui/core/Slider'
import image from '../resources/images/settings_background.jpg'
import UploadCmp from './common/UploadCmp'
import ConfigurationExpert from './common/ConfigurationExpert'
import ErrorBoundaryGuard from './ErrorBoundaryGuard'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { fetchConfigurations } from '../redux/configuration/actions'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import logo from '../resources/images/empty.png'
import FaceIcon from '@mui/icons-material/Face'
import Loading from './Loading'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import ScreeningServiceDialog from './dialog/ScreeningServiceDialog'
import AccountingDialog from './dialog/AccountingDialog'
import InformationDialog from './dialog/InformationDialog'
import ArticleIcon from '@mui/icons-material/Article'

const drawerWidth = 240

/**
 * Styling hook for custom CSS
 * @type {(props?: any) => ClassNameMap<string>}
 */
const useStyles = makeStyles((theme) => ({
  '& .MuiContainer-maxWidthLg': {
    maxWidth: '100%',
  },
  root: {
    display: 'flex',
    background: 'rgba(250,250,250,0.80)',
    // backgroundColor: 'pink',
  },
  toolbar: {
    paddingRight: theme.spacing(10), // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
  container: {
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    padding: 0,
    // backgroundColor: 'yellow',
    height: 'calc(100% - 90px)',
    // height: 'calc(100% - 64px)',
  },
  fixedHeight: {
    height: 240,
  },
  speedDial: {
    position: 'absolute',
    top: theme.spacing(1.2),
    right: theme.spacing(2),
    '& button, & button:hover': {
      color: 'inherit',
      backgroundColor: theme.palette.secondary.main,
    },
    '& button .MuiAvatar-colorDefault': {
      top: -7,
      color: 'inherit',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  userButton: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  settingsHeader: {
    height: 80,
    background: 'url(' + PUBLIC_URL + '/images/settings_background.jpg) no-repeat',
    backgroundSize: '100%',
    boxShadow: 'inset 0px 0px 20px 10px rgba(0,0,0,0.1)',
    paddingTop: 40,
    fontSize: 40,
    textShadow: '2px 1px 2px grey',
    color: 'white',
    width: 900,
  },
  settingsInfoText: {
    paddingTop: 15,
    paddingBottom: 10,
  },
  deletePrefsButton: {
    color: 'white',
    background: theme.palette.common.error,
    '&:hover': {
      backgroundColor: '#a10404',
    },
  },
  search: {
    position: 'relative',
    marginRight: '12px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.15),
    },
    '& div:first-child': {
      color: alpha(theme.palette.common.white, 0.7),
    },
    '&:hover div:first-child': {
      color: theme.palette.common.white,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    // pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '16ch',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
      },
    },
  },
  clearIcon: {
    color: alpha(theme.palette.common.white, 0.7),
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.common.white,
    },
  },
  bouton: {
    position: 'absolute',
    right: 10,
    bottom: 5,
  },
  popoverContainer: {
    background: '#00000021',
    '& > .MuiPopover-paper': {
      height: '80%',
      width: '80%',
      // transform: 'translate(-50%, -50%) !important',
      top: '10% !important',
      left: '10% !important',
      // padding: 20,
      overflow: 'hidden',
    },
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  colorPicker: {
    width: '100%',
    padding: '18.5px 14px',
    '& div': {
      cursor: 'pointer',
    },
  },
  colorPickerCnt: {
    border: '1px solid #c4c4c4',
    width: 'calc(50% - 5px)',
    display: 'inline-flex',
    borderRadius: 4,
  },
  colorPickerTitle: {
    backgroundColor: 'white',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 7px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    transform: 'translate(-4px, -8px) scale(0.75)',
  },
  colorChangeMessage: {
    width: 50,
    height: 16,
    display: 'inline-flex',
    position: 'relative',
    top: 2,
    borderRadius: 4,
    margin: '0px 10px',
  },
  formControlLanguage: {
    height: 56,
    '& .MuiSelect-root': { paddingTop: 14, paddingBottom: 14 },
  },
  online: {
    color: theme.palette.common.success,
  },
  offline: {
    color: theme.palette.common.error,
  },
  logo_img: {
    // width: 42,
    // height: 42,
    height: 33,
    marginRight: 15,
  },
  userName: {
    background: theme.palette.secondary.main,
    position: 'absolute',
    fontSize: 9,
    top: 26,
    right: 8,
    padding: '0px 1px',
  },
}))

/**
 * DashboardToolbar component is a React component that displays the toolbar for the dashboard.
 * It uses various Material-UI components for styling and layout, and Redux for state management.
 * The component is wrapped with `withRouter` to access routing-related props.
 *
 * @component
 * @param {object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered component.
 */
const DashboardToolbar = withRouter((props) => {
  const classes = useStyles() // Hook to use styles defined elsewhere
  const genericClasses = genericStyles() // Hook for generic styles
  const dispatch = useDispatch() // Redux dispatch function to trigger actions
  const { t } = useTranslation() // Hook for translation (i18n)

  // const AccountingDialog = React.lazy(() => import('./dialog/AccountingDialog'))
  // const InformationDialog = React.lazy(() => import('./dialog/InformationDialog'))

  // Default settings for various preferences
  const TASK_POLLING_DEFAULT = 10000
  const SHOW_UNKNOWN_DEFAULT = 'true'
  const SHOW_RETIRED_DEFAULT = 'false'
  const ANIMATION_DEFAULT = 'false'
  const EXPERT_MODE_DEFAULT = 'false'
  const HTML_SELECTION_DEFAULT = 'none'
  const MASS_CONCENTRATION_REPORTED_UNIT_DEFAULT = 'mg/kg'
  const VOLUME_CONCENTRATION_REPORTED_UNIT_DEFAULT = 'μg/l'
  const VOLUME_CONCENTRATION_UNIT_DEFAULT = 'μg/l'
  const CHROMATO_DISPLAY_DEFAULT = 'mol_only'

  // const [pythonServices, setPytonServices] = React.useState([])
  // const [servicePythonSelected, setPytonServicesSelected] = React.useState('')
  /**
   * State hooks for different settings with localStorage fallback
   */
  const [massReportedConcentrationUnitSelected, setMassReportedConcentrationUnitSelected] =
    React.useState(
      localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
        ? localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
        : MASS_CONCENTRATION_REPORTED_UNIT_DEFAULT,
    )
  const [volReportedConcentrationUnitSelected, setVolReportedConcentrationUnitSelected] =
    React.useState(
      localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
        ? localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
        : VOLUME_CONCENTRATION_REPORTED_UNIT_DEFAULT,
    )
  const [volumeConcentrationUnitSelected, setVolumeConcentrationUnitSelected] = React.useState(
    localStorage.getItem('SETTINGS_volume_concentration_unit')
      ? localStorage.getItem('SETTINGS_volume_concentration_unit')
      : VOLUME_CONCENTRATION_UNIT_DEFAULT,
  )
  const [primaryColor, setPrimaryColor] = React.useState(GET_PRIMARY_COLOR()) // Get primary color from a helper function
  const [secondaryColor, setSecondaryColor] = React.useState(GET_SECONDARY_COLOR()) // Get secondary color from a helper function
  const [showSearch, setShowSearch] = React.useState(false) // State to toggle the search bar visibility
  const [search, setSearch] = React.useState('') // Search query state
  const [displayClear, setDisplayClear] = React.useState(false) // State to control visibility of the clear button
  const [timer, setTimer] = React.useState(null) // Timer state for polling or delay purposes
  const [openDial, setOpenDial] = React.useState(false) // Controls the opening of a dialog
  const [openSettings, setOpenSettings] = React.useState(false) // Controls the opening of the settings panel
  const [openInformation, setOpenInformation] = React.useState(false) // Controls the opening of the information panel
  const [expert, setExpert] = React.useState(
    localStorage.getItem('SETTINGS_expert_mode')
      ? localStorage.getItem('SETTINGS_expert_mode')
      : EXPERT_MODE_DEFAULT,
  ) // Expert mode toggle
  const [htmlSelection, setHtmlSelection] = React.useState(
    localStorage.getItem('SETTINGS_html_selection')
      ? localStorage.getItem('SETTINGS_html_selection')
      : HTML_SELECTION_DEFAULT,
  ) // HTML selection state
  const searchGlobalRef = React.createRef() // Ref for the global search input
  const userPreferencesRef = React.createRef() // Ref for user preferences input
  const [taskPolling, setTaskPolling] = React.useState(
    localStorage.getItem('SETTINGS_task_polling')
      ? localStorage.getItem('SETTINGS_task_polling')
      : TASK_POLLING_DEFAULT,
  ) // Task polling interval setting
  const [showUnknown, setShowUnknown] = React.useState(
    localStorage.getItem('SETTINGS_show_unknown')
      ? localStorage.getItem('SETTINGS_show_unknown')
      : SHOW_UNKNOWN_DEFAULT,
  ) // Option to show unknown data
  const [showRetired, setShowRetired] = React.useState(
    localStorage.getItem('SETTINGS_show_retired')
      ? localStorage.getItem('SETTINGS_show_retired')
      : SHOW_RETIRED_DEFAULT,
  ) // Option to show retired data
  const [animation, setAnimation] = React.useState(
    localStorage.getItem('SETTINGS_animation')
      ? localStorage.getItem('SETTINGS_animation')
      : ANIMATION_DEFAULT,
  ) // Animation setting toggle
  const [useCache, setUseCache] = React.useState(
    localStorage.getItem('SETTINGS_useCache')
      ? localStorage.getItem('SETTINGS_useCache') === 'true'
      : USE_CACHE_DEFAULT,
  ) // Cache use setting
  const [chromatoDisplay, setChromatoDisplay] = React.useState(
    localStorage.getItem('SETTINGS_chromato_display')
      ? localStorage.getItem('SETTINGS_chromato_display')
      : CHROMATO_DISPLAY_DEFAULT,
  ) // Chromatography display setting
  const [language, setLanguage] = React.useState(i18n.resolvedLanguage) // Language setting from i18n
  const [zipLevel, setZipLevel] = React.useState(
    localStorage.getItem('SETTINGS_zip_level')
      ? parseFloat(localStorage.getItem('SETTINGS_zip_level'))
      : ZIP_LEVEL,
  ) // Zip level setting
  const [pythonServicesStatus, setPytonServicesStatus] = React.useState([]) // Python services status tracking

  const delta = useSelector(function (state) {
    return state.settings.dateTimeDelta
  }) // Select dateTimeDelta from the Redux store

  const featuresConfig = useSelector((state) => state.global.featuresConfig) // Fetch feature configurations
  const isServiceOffline = useSelector((state) => state.settings.configurationService) // Check if the service is offline
  const isDatabaseOffline = useSelector((state) => state.settings.configurationDatabase.length > 0) // Check if the database is offline
  const accountingActivated = useSelector(function (state) {
    return (
      typeof state?.global?.featuresConfig?.content?.accounting === 'boolean' &&
      state?.global?.featuresConfig?.content?.accounting
    )
  }) // Check if accounting is activated in the featuresConfig
  const openAccountingDialog = useSelector((state) => state.settings.displayAccountingDialog) // Check if the accounting dialog should be opened
  const openScreeningServiceDialog = useSelector(
    (state) => state.settings.displayScreeningServiceDialog,
  ) // Check if the screening service dialog should be opened

  // Determine if the upload mechanism is enabled based on feature configurations
  const upload_mechanism = useMemo(() => {
    if (featuresConfig) {
      return featuresConfig.content.upload_mechanism
    } else {
      return false
    }
  }, [featuresConfig])

  // useEffect(() => {
  //   const msg = {
  //     title: localStorage.getItem('SESSION_user_name'),
  //     message: t('view.panel.toolbar.message.connexion'),
  //     type: 'success',
  //     date: new Date().getTime(),
  //     isNew: false,
  //     connexionMessage: true,
  //   }
  //   dispatch(fetchAddMessage(msg))
  // }, [])

  // Be sure that an old lease doesn't lock the batch
  // Clean the lease during the first render
  // And reload the current lease
  /**
   * Effect to handle database and batch lease logic
   */
  useEffect(() => {
    if (!isServiceOffline) {
      saveDateTimeDelta(dispatch) // Save the current datetime delta if the service is not offline
    }
    if (
      props.history.location.pathname !== '/' &&
      props.history.location.pathname !== `/${DATABASE()}` &&
      !isDatabaseOffline
    ) {
      const batchId = props.location.pathname
        .replace(`/${DATABASE()}`, '')
        .replace(`/batch`, '')
        .replace(`/global`, '')
        .split('/')[1]
      if (batchId && batchId !== '' && batchId !== DATABASE()) {
        dispatch(fetchCurrentBatchLease(batchId, true)) // Fetch the current batch lease if the database is not offline
      }
    }
  }, [])

  // useEffect(() => {
  //   if (pythonServices.length > 0 && openSettings) {
  //     //Test if servers are online
  //     pythonServices.forEach(function (service) {
  //       fetch(`${FLASK_BASE_URL()}/${service.name}/version`, {
  //         method: 'GET',
  //         credentials: 'include',
  //       })
  //         .then((response) => {
  //           if (response.ok && response.status === 200) {
  //             return response.text()
  //           }
  //           throw new Error('Something went wrong')
  //         })
  //         .then((responseJson) => {
  //           // Service is online
  //           setPytonServicesStatus((oldStatus) => {
  //             return { ...oldStatus, [service.name]: true }
  //           })
  //         })
  //         .catch((error) => {
  //           setPytonServicesStatus((oldStatus) => {
  //             return { ...oldStatus, [service.name]: false }
  //           })
  //           console.log('The service ' + service.name + ' is offline.')
  //         })
  //     })
  //   }
  // }, [pythonServices, openSettings])

  const isLoadingBatch = useSelector(
    (state) =>
      !isLeasePassed(state.batches.currentBatchLease.date, delta) &&
      state.batches.currentBatchLease.reason === 'treatment',
  )
  const currentBatchLease = useSelector((state) => state.batches.currentBatchLease)

  // const configurations = useSelector((state) => state.configuration.configurations)
  // const configFeatures = useSelector((state) => state.configuration.configFeatures)

  /**
   * Effect to handle batch update warnings if the batch is loading
   */
  useEffect(() => {
    // Test if the batch is currently loading
    if (isLoadingBatch && props.location.pathname !== '/' + DATABASE()) {
      props.location.pathname = '/' + DATABASE() // Redirect to the database route if the batch is loading
      props.history.push('/?' + DATABASE())
      displayPopupMessage(
        dispatch,
        'warning',
        t('view.dashboardToolbar.batch_update.title'),
        <Fragment>
          <div>{t('view.dashboardToolbar.batch_update.msg1')}</div>
          <div>{t('view.dashboardToolbar.batch_update.msg2')}</div>
          <div style={{ marginTop: 10 }}>
            {t('view.dashboardToolbar.batch_update.msg3')} <i>{currentBatchLease.operator}</i>
          </div>
          <div>
            {t('view.dashboardToolbar.batch_update.msg4')} <i>{currentBatchLease.reason}</i>
          </div>
        </Fragment>,
      ) // Display a warning message about the batch update
    }
  }, [isLoadingBatch])

  const databaseInputRef = useRef(null) // Ref for database input field

  // Functions to open and close the dialog
  const handleOpen = () => {
    setOpenDial(true)
  }

  // Function to close the dialog without saving changes
  const handleClose = () => {
    setOpenDial(false)
  }

  /**
   * Function to close the settings dialog and save the changes if 'save' is true
   * @param event
   * @param save
   */
  const handleCloseSettings = (event, save) => {
    if (save) {
      // Retrieve existing settings from localStorage
      const oldPrimaryColor = localStorage.getItem('SETTINGS_primary_color')
      let oldPrimaryColorUpdate = false
      const oldSecondaryColor = localStorage.getItem('SETTINGS_secondary_color')
      let oldSecondaryColorUpdate = false
      const oldTaskPolling = localStorage.getItem('SETTINGS_task_polling')
      let oldTaskPollingUpdate = false
      const oldShowUnknown = localStorage.getItem('SETTINGS_show_unknown')
      let oldShowUnknownUpdate = false
      const oldShowRetired = localStorage.getItem('SETTINGS_show_retired')
      let oldShowRetiredUpdate = false
      const oldDatabase = localStorage.getItem('SERVICE_dataBase_name')
      let oldDatabaseUpdate = false
      const oldReportedMass = localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
      let oldReportedMassUpdate = false
      const oldReportedVolume = localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
      let oldReportedVolumeUpdate = false
      const oldVolume = localStorage.getItem('SETTINGS_volume_concentration_unit')
      let oldVolumeUpdate = false
      const oldExpert = localStorage.getItem('SETTINGS_expert_mode')
      let oldExpertUpdate = false
      const oldHtmlSelection = localStorage.getItem('SETTINGS_html_selection')
      let oldHtmlSelectionUpdate = false
      const oldChromatoDisplay = localStorage.getItem('SETTINGS_chromato_display')
      let oldChromatoDisplayUpdate = false
      // const oldConfigurationProfile = CONFIGURATION_PROFILE()
      // let oldConfigurationProfileUpdate = false
      const oldLanguage = i18n.resolvedLanguage
      let oldLanguageUpdate = false
      const oldZip = parseFloat(localStorage.getItem('SETTINGS_zip_level'))
      let oldZipUpdate = false
      const oldAnimation = localStorage.getItem('SETTINGS_animation')
      let oldAnimationUpdate = false
      const oldUseCache = localStorage.getItem('SETTINGS_useCache')
      let oldUseCacheUpdate = false

      // if (oldConfigurationProfile !== configurationProfile) {
      //   localStorage.setItem('SETTINGS_configuration_profile', configurationProfile)
      //   oldConfigurationProfileUpdate = true
      // }
      // Update settings if they've changed, and store them in localStorage
      // Check each setting, update if different from the saved value, and set the corresponding update flag to true
      if (oldChromatoDisplay !== chromatoDisplay) {
        localStorage.setItem('SETTINGS_chromato_display', chromatoDisplay)
        oldChromatoDisplayUpdate = true
      }
      if (oldReportedVolume !== volReportedConcentrationUnitSelected) {
        localStorage.setItem(
          'SETTINGS_vol_reported_concentration_unit',
          volReportedConcentrationUnitSelected,
        )
        oldReportedVolumeUpdate = true
      }
      if (oldReportedMass !== massReportedConcentrationUnitSelected) {
        localStorage.setItem(
          'SETTINGS_mass_reported_concentration_unit',
          massReportedConcentrationUnitSelected,
        )
        oldReportedMassUpdate = true
      }
      if (oldVolume !== volumeConcentrationUnitSelected) {
        localStorage.setItem('SETTINGS_volume_concentration_unit', volumeConcentrationUnitSelected)
        oldVolumeUpdate = true
      }
      if (oldDatabase !== databaseInputRef.current.children[1].value) {
        localStorage.setItem('SERVICE_dataBase_name', databaseInputRef.current.children[1].value)
        oldDatabaseUpdate = true
        // Store the database (prefix) in cookies for persistence
        document.cookie =
          'COD_db_prefix=' +
          databaseInputRef.current.children[1].value +
          '; path=/;SameSite=None;Secure;Domain=' +
          getParentDomain(window.location.hostname)
      }
      if (oldShowRetired !== showRetired) {
        localStorage.setItem('SETTINGS_show_retired', showRetired)
        oldShowRetiredUpdate = true
      }
      if (oldShowUnknown !== showUnknown) {
        localStorage.setItem('SETTINGS_show_unknown', showUnknown)
        oldShowUnknownUpdate = true
      }
      if (oldPrimaryColor !== primaryColor) {
        localStorage.setItem('SETTINGS_primary_color', primaryColor)
        oldPrimaryColorUpdate = true
      }
      if (oldTaskPolling !== taskPolling) {
        localStorage.setItem('SETTINGS_task_polling', taskPolling)
        oldTaskPollingUpdate = true
      }
      if (oldSecondaryColor !== secondaryColor) {
        localStorage.setItem('SETTINGS_secondary_color', secondaryColor)
        oldSecondaryColorUpdate = true
      }
      if (oldExpert !== expert) {
        localStorage.setItem('SETTINGS_expert_mode', expert)
        oldExpertUpdate = true
      }
      if (oldHtmlSelection !== htmlSelection) {
        localStorage.setItem('SETTINGS_html_selection', htmlSelection)
        oldHtmlSelectionUpdate = true
      }
      if (oldLanguage !== language) {
        i18n.changeLanguage(language)
        oldLanguageUpdate = true
      }
      if (oldZip !== zipLevel) {
        localStorage.setItem('SETTINGS_zip_level', zipLevel)
        oldZipUpdate = true
      }
      if (oldAnimation !== animation) {
        localStorage.setItem('SETTINGS_animation', animation)
        oldAnimationUpdate = true
      }
      if (oldUseCache !== useCache) {
        localStorage.setItem('SETTINGS_useCache', useCache)
        oldUseCacheUpdate = true
      }

      // Display a confirmation popup with the updated settings
      displayPopupMessage(
        dispatch,
        'info',
        t('view.dashboardToolbar.settings_saved.title'),
        <Fragment>
          {oldChromatoDisplayUpdate && (
            <div>
              <Trans i18nKey="view.dashboardToolbar.settings_saved.chromato_display">
                The chromatograms display is now
                {{ val: t('view.dashboardToolbar.dialogs.settings.' + chromatoDisplay) }}.
              </Trans>
            </div>
          )}
          {oldDatabaseUpdate && (
            <div>
              <Trans i18nKey="view.dashboardToolbar.settings_saved.database">
                The database is now {{ val: databaseInputRef.current.children[1].value }}.
              </Trans>
            </div>
          )}
          {oldShowUnknownUpdate && (
            <div>
              {t('view.dashboardToolbar.settings_saved.visibility')}{' '}
              {showUnknown === 'true'
                ? t('view.dashboardToolbar.settings_saved.active')
                : t('view.dashboardToolbar.settings_saved.no_active')}
              .
            </div>
          )}
          {oldShowRetiredUpdate && (
            <div>
              {t('view.dashboardToolbar.settings_saved.retired')}{' '}
              {showRetired === 'true'
                ? t('view.dashboardToolbar.settings_saved.active')
                : t('view.dashboardToolbar.settings_saved.no_active')}
              .
            </div>
          )}
          {oldTaskPollingUpdate && (
            <div>
              <Trans i18nKey="view.dashboardToolbar.settings_saved.task_polling">
                The task polling is now {{ val: taskPolling }}ms.
              </Trans>
            </div>
          )}
          {oldPrimaryColorUpdate && (
            <div>
              {t('view.dashboardToolbar.settings_saved.primary_color')}{' '}
              <div
                className={classes.colorChangeMessage}
                style={{ backgroundColor: primaryColor }}
              />
              .
            </div>
          )}
          {oldSecondaryColorUpdate && (
            <div>
              {t('view.dashboardToolbar.settings_saved.secondary_color')}{' '}
              <div
                className={classes.colorChangeMessage}
                style={{ backgroundColor: secondaryColor }}
              />
              .
            </div>
          )}
          {oldLanguageUpdate && (
            <div>
              {t('view.dialogs.settings.popup.save.msg.3')} {LANGUAGES[language].label}:
              <img
                style={{
                  width: 30,
                  marginLeft: 10,
                  border: '1px solid #80808078',
                  borderRadius: 3,
                }}
                src={PUBLIC_URL + '/images/flags/' + language + '.png'}
                alt={'flag'}
              />
            </div>
          )}
          {oldZipUpdate && (
            <div>
              {t('view.dialogs.settings.popup.save.msg.5')}: {zipLevel}
            </div>
          )}
          {oldReportedVolumeUpdate && (
            <Trans i18nKey="view.dashboardToolbar.settings_saved.mass_report_concentration">
              The mass reported concentration unit is now{' '}
              {{ val: massReportedConcentrationUnitSelected }}.
            </Trans>
          )}
          {oldReportedMassUpdate && (
            <Trans i18nKey="view.dashboardToolbar.settings_saved.vol_report_concentration">
              The volume reported concentration unit is now{' '}
              {{ val: volReportedConcentrationUnitSelected }}.
            </Trans>
          )}
          {oldVolumeUpdate && (
            <div>
              <Trans i18nKey="view.dashboardToolbar.settings_saved.volume_concentration">
                The volume concentration unit is now {{ val: volumeConcentrationUnitSelected }}.
              </Trans>
            </div>
          )}
          {oldExpertUpdate && (
            <div>
              {t('view.dashboardToolbar.settings_saved.expert_mode')}{' '}
              {expert === 'true'
                ? t('view.dashboardToolbar.settings_saved.active')
                : t('view.dashboardToolbar.settings_saved.no_active')}
              .
            </div>
          )}
          {oldHtmlSelectionUpdate && (
            <div>
              {t('view.dashboardToolbar.settings_saved.html_selection')}{' '}
              {htmlSelection === 'auto'
                ? t('view.dashboardToolbar.settings_saved.allowed')
                : t('view.dashboardToolbar.settings_saved.not_allowed')}
              .
            </div>
          )}
          {oldAnimationUpdate && (
            <div>
              {t('view.dashboardToolbar.settings_saved.animation')}{' '}
              {animation === 'true'
                ? t('view.dashboardToolbar.settings_saved.active')
                : t('view.dashboardToolbar.settings_saved.no_active')}
              .
            </div>
          )}
          {oldUseCacheUpdate && (
            <div>
              {t('view.dashboardToolbar.settings_saved.useCache')}{' '}
              {useCache === 'true'
                ? t('view.dashboardToolbar.settings_saved.active')
                : t('view.dashboardToolbar.settings_saved.no_active')}
              .
            </div>
          )}
          {/*{oldConfigurationProfileUpdate && (*/}
          {/*  <div>*/}
          {/*    <Trans i18nKey="view.dashboardToolbar.settings_saved.profile_update">*/}
          {/*      The configuration profile is now*/}
          {/*      {{*/}
          {/*        val:*/}
          {/*          configurations.filter((conf) => conf._id === configurationProfile)[0] &&*/}
          {/*          configurations.filter((conf) => conf._id === configurationProfile)[0].name,*/}
          {/*      }}*/}
          {/*      .*/}
          {/*    </Trans>*/}
          {/*  </div>*/}
          {/*)}*/}
          <div style={{ marginTop: 10 }}>
            {/*<Grid container direction="row" justifyContent="space-between" alignItems="center">*/}
            <b>{t('view.dashboardToolbar.settings_saved.reload')}</b>
            {/*  <IconButton color="primary" aria-label="refresh" onClick={reload}>*/}
            {/*    <RefreshIcon />*/}
            {/*  </IconButton>*/}
            {/*</Grid>*/}
          </div>
        </Fragment>,
      )
    }
    setOpenSettings(false)
  }

  /**
   * Function to open the settings dialog and pre-populate with saved values
   * @returns {Promise<void>}
   */
  const handleOpenSettings = async () => {
    dispatch(fetchConfigurations(DATABASE()))
    // Retrieve and set each setting to the localStorage values or default if not set
    setAnimation(
      localStorage.getItem('SETTINGS_animation')
        ? localStorage.getItem('SETTINGS_animation')
        : ANIMATION_DEFAULT,
    )
    setChromatoDisplay(
      localStorage.getItem('SETTINGS_chromato_display')
        ? localStorage.getItem('SETTINGS_chromato_display')
        : CHROMATO_DISPLAY_DEFAULT,
    )
    setPrimaryColor(GET_PRIMARY_COLOR())
    setSecondaryColor(GET_SECONDARY_COLOR())
    setTaskPolling(
      localStorage.getItem('SETTINGS_task_polling')
        ? localStorage.getItem('SETTINGS_task_polling')
        : TASK_POLLING_DEFAULT,
    )
    setShowUnknown(
      localStorage.getItem('SETTINGS_show_unknown')
        ? localStorage.getItem('SETTINGS_show_unknown')
        : SHOW_UNKNOWN_DEFAULT,
    )
    setShowRetired(
      localStorage.getItem('SETTINGS_show_retired')
        ? localStorage.getItem('SETTINGS_show_retired')
        : SHOW_RETIRED_DEFAULT,
    )
    setMassReportedConcentrationUnitSelected(
      localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
        ? localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
        : MASS_CONCENTRATION_REPORTED_UNIT_DEFAULT,
    )
    setVolReportedConcentrationUnitSelected(
      localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
        ? localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
        : VOLUME_CONCENTRATION_REPORTED_UNIT_DEFAULT,
    )
    setVolumeConcentrationUnitSelected(
      localStorage.getItem('SETTINGS_volume_concentration_unit')
        ? localStorage.getItem('SETTINGS_volume_concentration_unit')
        : VOLUME_CONCENTRATION_UNIT_DEFAULT,
    )
    setExpert(
      localStorage.getItem('SETTINGS_expert_mode')
        ? localStorage.getItem('SETTINGS_expert_mode')
        : 'false',
    )
    setHtmlSelection(
      localStorage.getItem('SETTINGS_html_selection')
        ? localStorage.getItem('SETTINGS_html_selection')
        : HTML_SELECTION_DEFAULT,
    )
    // setConfigurationProfile(CONFIGURATION_PROFILE())
    setLanguage(i18n.resolvedLanguage)
    setZipLevel(
      localStorage.getItem('SETTINGS_zip_level')
        ? parseFloat(localStorage.getItem('SETTINGS_zip_level'))
        : ZIP_LEVEL,
    )

    // setPytonServicesSelected(SERVICE())

    // const initConfigurationProfile = localStorage.getItem('SERVICE_configuration_profile')
    //   ? localStorage.getItem('SERVICE_configuration_profile')
    //   : CONFIGURATION_PROFILE()
    // setConfigurationProfile(initConfigurationProfile)

    setOpenSettings(true)

    // let filter = [
    //   {
    //     property: 'tags',
    //     value: localStorage.getItem('__config.json__ALPHACOD_SERVICE_TAG'),
    //     filterType: 'string',
    //     operator: 'like',
    //   },
    // ]
    // let f = encodeURIComponent(JSON.stringify(filter))
    // fetch(`${API_URL()}/pythonServices?filter=${f}`, {
    //   method: 'GET',
    //   credentials: 'include',
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       const statusText = response.statusText
    //       const status = response.status
    //       const url = response.url
    //       return response.text().then((errorMessage) => {
    //         const error = new Error(`${statusText} : ${errorMessage}`)
    //         if (response.headers.get('content-type') === 'application/json') {
    //           error.stack = JSON.stringify(
    //             JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
    //             null,
    //             2,
    //           )
    //         } else {
    //           error.stack = new Error().stack
    //         }
    //         error.statusText = statusText
    //         error.status = status
    //         error.url = url
    //         throw error
    //       })
    //     }
    //     return response.json()
    //   })
    //   .then(function (json) {
    //     setPytonServicesStatus(Object.assign({}, ...json.map((x) => ({ [x.name]: undefined }))))
    //     setPytonServices(
    //       json.sort(function (a, b) {
    //         if (a.name < b.name) return -1
    //         if (a.name > b.name) return 1
    //         return 0
    //       }),
    //     )
    //   })
    //   .catch(function (error) {
    //     if (error.status === 403) {
    //       dispatch(fetchChangeSettings('loginPopup', true))
    //     }
    //     displayPopupMessage(
    //       dispatch,
    //       'error',
    //       t('view.dashboardToolbar.failed_python_service.title'),
    //       <Fragment>
    //         <div>{t('view.dashboardToolbar.failed_python_service.msg')}</div>
    //         {generateErrorPart(error)}
    //       </Fragment>,
    //     )
    //   })
    //   .finally(() => {
    //     setOpenSettings(true)
    //   })
  }

  /**
   * Logout function to clear session and redirect to login page
   * @returns {Promise<void>}
   */
  const logout = async () => {
    // setOpenDial(false)
    fetch(`${API_URL()}/sessions/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .catch(function (error) {
        displayPopupMessage(
          dispatch,
          'error',
          t('view.dashboardToolbar.error_logout.title'),
          <Fragment>
            <div>{t('view.dashboardToolbar.error_logout.msg')}</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
      .finally(function () {
        document.cookie = 'ToriiSessionId=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        localStorage.removeItem('SESSION_user_name')
        localStorage.removeItem('SESSION_user_json')
        props.history.push('/?login')
      })
  }

  /**
   * Function to set the information dialog open and close the dial
   */
  const info = () => {
    setOpenDial(false)
    setOpenInformation(true)
  }

  /**
   * Function to close the dial and open the accounting settings dialog
   */
  const handleOpenAccounting = () => {
    setOpenDial(false)
    dispatch(fetchChangeSettings('displayAccountingDialog', true))
  }

  /**
   * Function to close the dial and open the screening service settings dialog
   */
  const handleOpenScreeningService = () => {
    setOpenDial(false)
    dispatch(fetchChangeSettings('displayScreeningServiceDialog', true))
  }

  /**
   * Function to close the dial and open the method manager dialog
   */
  const handleOpenMethodManager = () => {
    setOpenDial(false)
    dispatch(fetchChangeSettings('displayMethodManagerDialog', true))
  }

  /**
   * Function to reset the settings to their default values and show a reset message
   */
  const resetSettings = () => {
    localStorage.setItem('SETTINGS_chromato_display', CHROMATO_DISPLAY_DEFAULT)
    localStorage.removeItem('SETTINGS_primary_color')
    localStorage.removeItem('SETTINGS_secondary_color')
    localStorage.setItem('SETTINGS_task_polling', TASK_POLLING_DEFAULT)
    localStorage.setItem('SETTINGS_animation', ANIMATION_DEFAULT)
    localStorage.setItem('SETTINGS_show_unknown', SHOW_UNKNOWN_DEFAULT)
    localStorage.setItem('SETTINGS_show_retired', SHOW_RETIRED_DEFAULT)
    localStorage.setItem('SETTINGS_volume_concentration_unit', VOLUME_CONCENTRATION_UNIT_DEFAULT)
    localStorage.setItem(
      'SETTINGS_vol_reported_concentration_unit',
      VOLUME_CONCENTRATION_REPORTED_UNIT_DEFAULT,
    )
    localStorage.setItem(
      'SETTINGS_mass_reported_concentration_unit',
      MASS_CONCENTRATION_REPORTED_UNIT_DEFAULT,
    )
    localStorage.setItem('SETTINGS_expert_mode', 'false')
    localStorage.setItem('SETTINGS_html_selection', HTML_SELECTION_DEFAULT)
    localStorage.setItem('SETTINGS_configuration_profile', 'none')
    localStorage.removeItem('SETTINGS_zip_level')
    localStorage.removeItem('i18nextLng')
    displayPopupMessage(
      dispatch,
      'info',
      t('view.dashboardToolbar.reset_settings.title'),
      <Fragment>
        {t('view.dashboardToolbar.reset_settings.msg1')}
        <div style={{ marginTop: 10 }}>
          {/*<Grid container direction="row" justifyContent="space-between" alignItems="center">*/}
          <b>{t('view.dashboardToolbar.reset_settings.msg2')}</b>
          {/*  <IconButton color="primary" aria-label="refresh" onClick={reload}>*/}
          {/*    <RefreshIcon />*/}
          {/*  </IconButton>*/}
          {/*</Grid>*/}
        </div>
      </Fragment>,
    )
    setOpenSettings(false)
  }

  /**
   * Function to reload the page, preserving the current query string
   */
  const reload = () => {
    const current = '/' + props.location.search
    props.history.replace(`/`)
    setTimeout(() => {
      props.history.replace(current)
    })
  }

  /**
   * Function to reset the page to the database home route
   */
  const reset = () => {
    props.history.push('/?' + DATABASE())
  }

  /**
   * Function to open the wiki settings dialog
   */
  const openWiki = () => {
    dispatch(fetchChangeSettings('wikiPopup', true))
  }

  /**
   * List of toolbar actions with corresponding icons and click handlers
   */
  const actions = [
    { icon: <HomeIcon />, name: t('view.dashboardToolbar.actions.backlog'), click: reset },
    { icon: <MenuBookIcon />, name: t('view.dashboardToolbar.actions.wiki'), click: openWiki },
    { icon: <RefreshIcon />, name: t('view.dashboardToolbar.actions.refresh'), click: reload },
    {
      icon: <SettingsIcon />,
      name: t('view.dashboardToolbar.actions.settings'),
      click: handleOpenSettings,
    },
    {
      icon: <PriorityHighIcon style={{ transform: 'rotate(180deg)' }} />,
      name: t('view.dashboardToolbar.actions.info'),
      click: info,
    },
    {
      icon: <ArticleIcon />,
      name: t('view.dashboardToolbar.actions.methodManager'),
      click: handleOpenMethodManager,
    },
    {
      icon: <UploadFileIcon />,
      name: t('view.dialogs.screeningService.title'),
      click: handleOpenScreeningService,
    },
    accountingActivated
      ? {
          icon: <AccountBalanceWalletIcon />,
          name: t('view.dashboardToolbar.actions.accounting'),
          click: handleOpenAccounting,
        }
      : null,
    { icon: <LockOpenIcon />, name: t('view.dashboardToolbar.actions.logout'), click: logout },
  ].filter(Boolean) // Remove the null items

  // const handlePythonServiceChanged = (event) => {
  //   // const id = event.currentTarget.id
  //   // switch (id) {
  //   //   case 'db':
  //   //     settings.databasename = value
  //   //     setSettings(settings)
  //   //     break
  //   //   case 'port':
  //   //     settings.serviceport = value
  //   //     setSettings(settings)
  //   //     break
  //   // }
  //   const value = event.target.value
  //   setPytonServicesSelected(value)
  // }

  /**
   * Function to handle changes in the mass reported concentration setting
   * @param event
   */
  const handleMassReportedConcentrationChanged = (event) => {
    const value = event.target.value
    setMassReportedConcentrationUnitSelected(value)
  }
  /**
   * Function to handle changes in the volume reported concentration setting
   * @param event
   */
  const handleVolReportedConcentrationChanged = (event) => {
    const value = event.target.value
    setVolReportedConcentrationUnitSelected(value)
  }

  /**
   * Function to handle changes in the volume concentration setting
   * @param event
   */
  const handleVolumeConcentrationChanged = (event) => {
    const value = event.target.value
    setVolumeConcentrationUnitSelected(value)
  }

  /**
   * Function to perform a search, with a debounce to optimize performance
   * @param e
   */
  function onSearch(e) {
    const newValue = e.target.value
    clearTimeout(timer)
    const newTimer = setTimeout(function () {
      setSearch(newValue)
      if (newValue !== '') {
        const filterAnalysisService = localStorage.getItem('SEARCH_filter_service')
          ? localStorage.getItem('SEARCH_filter_service') === 'null'
            ? null
            : localStorage.getItem('SEARCH_filter_service')
          : null
        const filterAnalysisType = localStorage.getItem('SEARCH_filter_type')
          ? localStorage.getItem('SEARCH_filter_type') === 'null'
            ? null
            : localStorage.getItem('SEARCH_filter_type')
          : null
        setShowSearch(true)
        dispatch(searchAnalyses(newValue, filterAnalysisService, filterAnalysisType))
        dispatch(searchBatches(newValue, filterAnalysisService, filterAnalysisType))
      }
    }, 1500)
    setTimer(newTimer)
    setDisplayClear(newValue !== '')
  }

  /**
   * Function to clear the search input and reset the search state
   */
  function clearSearch() {
    searchGlobalRef.current.children[0].value = ''
    setSearch('')
    setDisplayClear(false)
    // dispatch(
    //     fetchUnitaryAnalysesFiltered(
    //         this.props.match.params.analysisId,
    //         filterSelected,
    //         checked,
    //         localStorage.getItem('analyseSorter'),
    //         null,
    //     ),
    // )
  }

  /**
   * Function to handle the opening and closing of the information dialog
   * @param event
   * @param open
   */
  function openCloseInformation(event, open) {
    event.stopPropagation()
    event.preventDefault()
    setOpenInformation(open)
  }

  /**
   * Function to close the search display
   * @param event
   */
  function handleCloseSearch(event) {
    event.stopPropagation()
    event.preventDefault()
    setShowSearch(false)
  }

  /**
   * Function to handle changes in the task polling setting
   * @param event
   */
  const handleTaskPollingChanged = (event) => {
    const value = event.target.value
    setTaskPolling(value)
  }

  /**
   * Function to handle changes in the show unknown setting
   * @param event
   */
  const handleShowUnknownChanged = (event) => {
    const value = event.target.value
    setShowUnknown(value)
  }

  /**
   * Function to handle changes in the show retired setting
   * @param event
   */
  const handleShowRetiredChanged = (event) => {
    const value = event.target.value
    setShowRetired(value)
  }
  /**
   * Function to handle changes in the animation setting
   * @param event
   */
  const handleAnimationChanged = (event) => {
    const value = event.target.value
    setAnimation(value)
  }
  /**
   * Function to handle changes in the use cache setting
   * @param event
   */
  const handleUseCacheChanged = (event) => {
    const value = event.target.value
    setUseCache(value)
  }
  /**
   * Function to handle changes in the expert mode setting
   * @param event
   */
  const handleExpertChanged = (event) => {
    const value = event.target.value
    setExpert(value)
  }
  /**
   * Function to handle changes in the HTML selection setting
   * @param event
   */
  const handleHTMLSelectionChanged = (event) => {
    const value = event.target.value
    setHtmlSelection(value)
  }
  /**
   * Function to handle changes in the chromato display setting
   * @param event
   */
  const handleChromatoDisplayChanged = (event) => {
    const value = event.target.value
    setChromatoDisplay(value)
  }

  /**
   * Function to handle changes in the zip level setting
   * @param event
   * @param newValue
   */
  function handleChangeZipLevel(event, newValue) {
    setZipLevel(newValue)
  }

  // const handleConfigurationProfileChanged = (event) => {
  //   const value = event.target.value
  //   setConfigurationProfile(value)
  // }

  /**
   * Function to open the file upload dialog
   */
  function onOpenUpload() {
    // dispatch(fetchChangeSettings('clickUpload', true))
    // document.querySelector('input[type=file]').click()
    document.getElementById('dropZoneInputCmp').click()
  }

  /**
   * Function to handle language changes
   * @param current
   */
  function handleChangeLanguage(current) {
    setLanguage(current.target.value)
  }

  return (
    <Fragment>
      {openScreeningServiceDialog && (
        <ErrorBoundaryGuard isDialog>
          <Suspense fallback={<Loading mode="miniCircle" />}>
            <ScreeningServiceDialog open={openScreeningServiceDialog} />
          </Suspense>
        </ErrorBoundaryGuard>
      )}
      {openAccountingDialog && (
        <ErrorBoundaryGuard isDialog>
          <Suspense fallback={<Loading mode="miniCircle" />}>
            <AccountingDialog open={openAccountingDialog} />
          </Suspense>
        </ErrorBoundaryGuard>
      )}
      {openInformation && (
        <ErrorBoundaryGuard isDialog>
          <Suspense fallback={<Loading mode="miniCircle" />}>
            <InformationDialog
              open={openInformation}
              closeFct={(event) => openCloseInformation(event, false)}
            />
          </Suspense>
        </ErrorBoundaryGuard>
      )}
      {openSettings && (
        <Suspense fallback={<Loading mode="miniCircle" />}>
          <Dialog
            open={openSettings}
            fullWidth={false}
            maxWidth={'xl'}
            onClose={(event) => handleCloseSettings(event, false)}
            TransitionComponent={TransitionBottom}
            aria-labelledby="draggable-dialog-title"
          >
            {/*<DialogTitle className={classes.settingsHeader}>Settings</DialogTitle>*/}
            <DialogTitle style={{ padding: 0, width: 900 }}>
              <Box className={genericClasses.header}>
                <CardMedia
                  className={genericClasses.cardImg}
                  style={{ opacity: 0.8 }}
                  component="img"
                  alt="header image"
                  height="130"
                  image={image}
                  title="Header image"
                />
                <span className={genericClasses.title}>{t('view.dialogs.settings.title')}</span>
                {localStorage.getItem('SESSION_user_json') &&
                JSON.parse(localStorage.getItem('SESSION_user_json')).admin ? (
                  <ErrorBoundaryGuard isDialog>
                    <ConfigurationExpert />
                  </ErrorBoundaryGuard>
                ) : (
                  <SettingsIcon className={genericClasses.icon} />
                )}
              </Box>
            </DialogTitle>
            <DialogContent style={{ marginBottom: 20 }}>
              <DialogContentText className={classes.settingsInfoText}>
                {t('view.dashboardToolbar.dialogs.settings.title')}
              </DialogContentText>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={6} style={{ paddingRight: 20, borderRight: '0px solid #e6e6e6' }}>
                  {/*<Grid container direction="row" alignItems="center">*/}
                  {/*  <FormControl*/}
                  {/*    style={{ width: 'calc(50% - 5px)', marginRight: 5 }}*/}
                  {/*    required*/}
                  {/*    variant="outlined"*/}
                  {/*    className={classes.formControl}*/}
                  {/*    error={*/}
                  {/*      servicePythonSelected === '' ||*/}
                  {/*      servicePythonSelected === null ||*/}
                  {/*      servicePythonSelected === undefined ||*/}
                  {/*      pythonServices.map((ps) => ps.name).indexOf(servicePythonSelected) === -1*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <InputLabel>*/}
                  {/*      {t('view.dashboardToolbar.dialogs.settings.business_service')}*/}
                  {/*    </InputLabel>*/}
                  {/*    <Select*/}
                  {/*      variant="outlined"*/}
                  {/*      onChange={handlePythonServiceChanged}*/}
                  {/*      label={t('view.dashboardToolbar.dialogs.settings.business_service')}*/}
                  {/*      value={servicePythonSelected}*/}
                  {/*    >*/}
                  {/*      {pythonServices.map((service) => (*/}
                  {/*        <MenuItem key={service.name} value={service.name}>*/}
                  {/*          <Tooltip*/}
                  {/*            arrow*/}
                  {/*            disableHoverListener={!service.description}*/}
                  {/*            placement="left"*/}
                  {/*            title={*/}
                  {/*              <div style={{ fontSize: 13, padding: 5 }}>{service.description}</div>*/}
                  {/*            }*/}
                  {/*          >*/}
                  {/*            <div style={{ width: '100%' }}>*/}
                  {/*              <Stack*/}
                  {/*                direction="row"*/}
                  {/*                justifyContent="space-between"*/}
                  {/*                alignItems="center"*/}
                  {/*                spacing={2}*/}
                  {/*              >*/}
                  {/*                <div>{service.name}</div>*/}
                  {/*                {pythonServicesStatus &&*/}
                  {/*                  typeof pythonServicesStatus[service.name] === 'boolean' &&*/}
                  {/*                  (pythonServicesStatus[service.name] ? (*/}
                  {/*                    <CloudQueueIcon className={classes.online} />*/}
                  {/*                  ) : (*/}
                  {/*                    <CloudOffIcon className={classes.offline} />*/}
                  {/*                  ))}*/}
                  {/*              </Stack>*/}
                  {/*            </div>*/}
                  {/*          </Tooltip>*/}
                  {/*        </MenuItem>*/}
                  {/*      ))}*/}
                  {/*    </Select>*/}
                  {/*  </FormControl>*/}
                  {/*  <div style={{ width: 'calc(50% - 5px)', marginLeft: 5 }}>*/}
                  {/*    <ErrorBoundaryGuard isDialog>*/}
                  {/*      <DatabaseInput ref={databaseInputRef} visible={openSettings} />*/}
                  {/*    </ErrorBoundaryGuard>*/}
                  {/*  </div>*/}
                  {/*</Grid>*/}
                  <Grid container direction="row" alignItems="center" style={{ marginTop: 0 }}>
                    {/*<FormControl*/}
                    {/*  required*/}
                    {/*  fullWidth*/}
                    {/*  variant="outlined"*/}
                    {/*  style={{ width: 'calc(50% - 5px)', marginRight: 5 }}*/}
                    {/*  className={classes.formControl}*/}
                    {/*  error={configurationProfile === null || configurationProfile === ''}*/}
                    {/*>*/}
                    {/*  <InputLabel>*/}
                    {/*    {t('view.dashboardToolbar.dialogs.settings.config_profil')}*/}
                    {/*  </InputLabel>*/}
                    {/*  <Select*/}
                    {/*    variant="outlined"*/}
                    {/*    onChange={handleConfigurationProfileChanged}*/}
                    {/*    label={t('view.dashboardToolbar.dialogs.settings.config_profil')}*/}
                    {/*    value={configurationProfile}*/}
                    {/*  >*/}
                    {/*    <MenuItem key={'none'} value={'none'}>*/}
                    {/*      {t('view.dashboardToolbar.dialogs.settings.none')}*/}
                    {/*    </MenuItem>*/}
                    {/*    {configurations &&*/}
                    {/*      configurations.map((conf) => (*/}
                    {/*        <MenuItem key={conf.name} value={conf._id}>*/}
                    {/*          {conf.name}*/}
                    {/*        </MenuItem>*/}
                    {/*      ))}*/}
                    {/*  </Select>*/}
                    {/*</FormControl>*/}
                    {/*<FormControl*/}
                    {/*  style={{ width: 'calc(50% - 5px)', marginRight: 5 }}*/}
                    {/*  required*/}
                    {/*  variant="outlined"*/}
                    {/*  className={classes.formControl}*/}
                    {/*  error={*/}
                    {/*    servicePythonSelected === '' ||*/}
                    {/*    servicePythonSelected === null ||*/}
                    {/*    servicePythonSelected === undefined ||*/}
                    {/*    pythonServices.map((ps) => ps.name).indexOf(servicePythonSelected) === -1*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  <InputLabel>*/}
                    {/*    {t('view.dashboardToolbar.dialogs.settings.business_service')}*/}
                    {/*  </InputLabel>*/}
                    {/*  <Select*/}
                    {/*    variant="outlined"*/}
                    {/*    onChange={handlePythonServiceChanged}*/}
                    {/*    label={t('view.dashboardToolbar.dialogs.settings.business_service')}*/}
                    {/*    value={servicePythonSelected}*/}
                    {/*  >*/}
                    {/*    {pythonServices.map((service) => (*/}
                    {/*      <MenuItem key={service.name} value={service.name}>*/}
                    {/*        <Tooltip*/}
                    {/*          arrow*/}
                    {/*          disableHoverListener={!service.description}*/}
                    {/*          placement="left"*/}
                    {/*          title={*/}
                    {/*            <div style={{ fontSize: 13, padding: 5 }}>{service.description}</div>*/}
                    {/*          }*/}
                    {/*        >*/}
                    {/*          <div style={{ width: '100%' }}>*/}
                    {/*            <Stack*/}
                    {/*              direction="row"*/}
                    {/*              justifyContent="space-between"*/}
                    {/*              alignItems="center"*/}
                    {/*              spacing={2}*/}
                    {/*            >*/}
                    {/*              <div>{service.name}</div>*/}
                    {/*              {pythonServicesStatus &&*/}
                    {/*                typeof pythonServicesStatus[service.name] === 'boolean' &&*/}
                    {/*                (pythonServicesStatus[service.name] ? (*/}
                    {/*                  <CloudQueueIcon className={classes.online} />*/}
                    {/*                ) : (*/}
                    {/*                  <CloudOffIcon className={classes.offline} />*/}
                    {/*                ))}*/}
                    {/*            </Stack>*/}
                    {/*          </div>*/}
                    {/*        </Tooltip>*/}
                    {/*      </MenuItem>*/}
                    {/*    ))}*/}
                    {/*  </Select>*/}
                    {/*</FormControl>*/}
                    <div style={{ width: 'calc(50% - 5px)', marginLeft: 0 }}>
                      <ErrorBoundaryGuard isDialog>
                        <DatabaseInput ref={databaseInputRef} visible={openSettings} />
                      </ErrorBoundaryGuard>
                    </div>
                    <FormControl
                      style={{ width: 'calc(50% - 5px)', marginLeft: 10 }}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-label">
                        {t('view.panel.toolbar.language.label')}
                      </InputLabel>
                      <Select
                        label={t('view.panel.toolbar.language.label')}
                        onChange={handleChangeLanguage}
                        value={language}
                        className={classes.formControlLanguage}
                      >
                        {Object.keys(LANGUAGES)?.map((l, index) => (
                          <MenuItem value={l} key={index}>
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              <img
                                style={{
                                  width: 40,
                                  marginRight: 20,
                                  border: '1px solid #80808078',
                                  borderRadius: 3,
                                }}
                                src={PUBLIC_URL + '/images/flags/' + l + '.png'}
                              />
                              {LANGUAGES[l].label}
                            </Stack>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container direction="row" alignItems="center" style={{ marginTop: 30 }}>
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      style={{ width: 'calc(50% - 5px)', marginRight: 5 }}
                      className={classes.formControl}
                      error={showUnknown === null || showUnknown === ''}
                    >
                      <InputLabel>
                        {t('view.dashboardToolbar.dialogs.settings.unknown_mol')}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={handleShowUnknownChanged}
                        label={t('view.dashboardToolbar.dialogs.settings.unknown_mol')}
                        value={showUnknown}
                      >
                        {['true', 'false'].map((v) => (
                          <MenuItem key={v} value={v}>
                            {t('view.dashboardToolbar.dialogs.settings.' + v)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      style={{ width: 'calc(50% - 5px)', marginLeft: 5 }}
                      className={classes.formControl}
                      error={showUnknown === null || showUnknown === ''}
                    >
                      <InputLabel>
                        {t('view.dashboardToolbar.dialogs.settings.chromato_display')}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={handleChromatoDisplayChanged}
                        label={t('view.dashboardToolbar.dialogs.settings.chromato_display')}
                        value={chromatoDisplay}
                      >
                        {['mol_only', 'istd_combi'].map((v) => (
                          <MenuItem key={v} value={v}>
                            {t('view.dashboardToolbar.dialogs.settings.' + v)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container direction="row" alignItems="center" style={{ marginTop: 30 }}>
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      style={{ width: 'calc(50% - 5px)', marginRight: 5 }}
                      className={classes.formControl}
                    >
                      <InputLabel>{t('view.dashboardToolbar.dialogs.settings.retired')}</InputLabel>
                      <Select
                        variant="outlined"
                        onChange={handleShowRetiredChanged}
                        label={t('view.dashboardToolbar.dialogs.settings.retired')}
                        value={showRetired}
                      >
                        {['true', 'false'].map((v) => (
                          <MenuItem key={v} value={v}>
                            {t('view.dashboardToolbar.dialogs.settings.' + v)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container direction="row" alignItems="center" style={{ marginTop: 30 }}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      style={{ width: 'calc(50% - 5px)', marginRight: 5 }}
                      className={classes.formControl}
                      error={taskPolling === null || taskPolling === ''}
                      onChange={handleTaskPollingChanged}
                      type="number"
                      id="outlined-basic-polling"
                      label={t('view.dashboardToolbar.dialogs.settings.task_polling')}
                      value={taskPolling}
                    />
                    {upload_mechanism && (
                      <div style={{ width: 'calc(50% - 5px)', marginLeft: 5 }}>
                        <div className={classes.colorPickerCnt} style={{ width: '100%' }}>
                          <Slider
                            valueLabelDisplay="auto"
                            step={1}
                            color="secondary"
                            marks
                            min={1}
                            max={9}
                            style={{ margin: 13, zIndex: 1000 }}
                            onChange={handleChangeZipLevel}
                            value={zipLevel}
                          />
                          <div className={classes.colorPickerTitle}>
                            {t('view.dialogs.settings.compressionLevel')}
                          </div>
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid container direction="row" alignItems="center" style={{ marginTop: 30 }}>
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      style={{ width: 'calc(50% - 5px)', marginRight: 5 }}
                      className={classes.formControl}
                    >
                      <InputLabel>
                        {t('view.dashboardToolbar.dialogs.settings.animation')}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={handleAnimationChanged}
                        label={t('view.dashboardToolbar.dialogs.settings.animation')}
                        value={animation}
                      >
                        {['true', 'false'].map((v) => (
                          <MenuItem key={v} value={v}>
                            {t('view.dashboardToolbar.dialogs.settings.' + v)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      style={{ width: 'calc(50% - 5px)', marginLeft: 5 }}
                      className={classes.formControl}
                    >
                      <InputLabel>
                        {t('view.dashboardToolbar.dialogs.settings.useCache')}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={handleUseCacheChanged}
                        label={t('view.dashboardToolbar.dialogs.settings.useCache')}
                        value={useCache}
                      >
                        {['true', 'false'].map((v) => (
                          <MenuItem key={v} value={v}>
                            {t('view.dashboardToolbar.dialogs.settings.' + v)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 20, borderLeft: '1px solid #e6e6e6' }}>
                  <div className={classes.colorPickerCnt} style={{ marginRight: 5 }}>
                    <ErrorBoundaryGuard>
                      <HexColorPicker
                        className={classes.colorPicker}
                        color={primaryColor}
                        onChange={setPrimaryColor}
                      />
                    </ErrorBoundaryGuard>
                    <div className={classes.colorPickerTitle}>
                      {t('view.dashboardToolbar.dialogs.settings.primary_color')}
                    </div>
                  </div>
                  <div className={classes.colorPickerCnt} style={{ marginLeft: 5 }}>
                    <ErrorBoundaryGuard>
                      <HexColorPicker
                        className={classes.colorPicker}
                        color={secondaryColor}
                        onChange={setSecondaryColor}
                      />
                    </ErrorBoundaryGuard>
                    <div className={classes.colorPickerTitle}>
                      {t('view.dashboardToolbar.dialogs.settings.secondary_color')}
                    </div>
                  </div>
                  <Grid container direction="row" alignItems="center" style={{ marginTop: 30 }}>
                    <FormControl
                      style={{ width: 'calc(50% - 5px)', marginRight: 5 }}
                      required
                      variant="outlined"
                      // className={classes.formControl}
                      error={
                        massReportedConcentrationUnitSelected === '' ||
                        massReportedConcentrationUnitSelected === null ||
                        massReportedConcentrationUnitSelected === undefined
                      }
                    >
                      <InputLabel>
                        {t('view.dashboardToolbar.dialogs.settings.mass_reported_conc')}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={handleMassReportedConcentrationChanged}
                        label={t('view.dashboardToolbar.dialogs.settings.mass_reported_conc')}
                        value={massReportedConcentrationUnitSelected}
                      >
                        {['mg/kg', 'μg/kg', 'ng/kg', 'ppm', 'ppb', 'ppt'].map((unit) => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      style={{ width: 'calc(50% - 5px)', marginLeft: 5 }}
                      required
                      variant="outlined"
                      error={
                        volReportedConcentrationUnitSelected === '' ||
                        volReportedConcentrationUnitSelected === null ||
                        volReportedConcentrationUnitSelected === undefined
                      }
                    >
                      <InputLabel>
                        {t('view.dashboardToolbar.dialogs.settings.vol_reported_conc')}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={handleVolReportedConcentrationChanged}
                        label={t('view.dashboardToolbar.dialogs.settings.vol_reported_conc')}
                        value={volReportedConcentrationUnitSelected}
                      >
                        {['μg/l', 'mg/l', 'ng/l', 'pg/l', 'n mol/l', 'p mol/l'].map((unit) => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container direction="row" alignItems="center" style={{ marginTop: 30 }}>
                    <FormControl
                      style={{ width: 'calc(50% - 5px)', marginRight: 5 }}
                      required
                      variant="outlined"
                      // className={classes.formControl}
                      error={
                        volumeConcentrationUnitSelected === '' ||
                        volumeConcentrationUnitSelected === null ||
                        volumeConcentrationUnitSelected === undefined
                      }
                    >
                      <InputLabel>
                        {t('view.dashboardToolbar.dialogs.settings.volume_conc')}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={handleVolumeConcentrationChanged}
                        label={t('view.dashboardToolbar.dialogs.settings.volume_conc')}
                        value={volumeConcentrationUnitSelected}
                      >
                        {['μg/l', 'mg/l', 'ng/l', 'pg/l', 'n mol/l', 'p mol/l'].map((unit) => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container direction="row" alignItems="center" style={{ marginTop: 30 }}>
                    <FormControl
                      variant="outlined"
                      style={{ width: 'calc(50% - 5px)', marginRight: 5 }}
                      className={classes.formControl}
                    >
                      <InputLabel>
                        {t('view.dashboardToolbar.dialogs.settings.html_selection')}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={handleHTMLSelectionChanged}
                        label={t('view.dashboardToolbar.dialogs.settings.html_selection')}
                        value={htmlSelection}
                      >
                        {['allowed', 'not_allowed'].map((v) => (
                          <MenuItem key={v} value={v === 'allowed' ? 'auto' : 'none'}>
                            {t('view.dashboardToolbar.dialogs.settings.' + v)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      style={{ width: 'calc(50% - 5px)', marginLeft: 5 }}
                      className={classes.formControl}
                    >
                      <InputLabel>
                        {t('view.dashboardToolbar.dialogs.settings.expert_mode')}
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={handleExpertChanged}
                        label={t('view.dashboardToolbar.dialogs.settings.expert_mode')}
                        value={expert}
                      >
                        {['active', 'not_active'].map((v) => (
                          <MenuItem key={v} value={v === 'active' ? 'true' : 'false'}>
                            {t('view.dashboardToolbar.dialogs.settings.' + v)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                className={classes.deletePrefsButton}
                startIcon={<DeleteIcon />}
                onClick={resetSettings}
              >
                {t('view.dashboardToolbar.dialogs.settings.reset')}
              </Button>
              <div style={{ width: 'calc(100% - 333px)' }}></div>
              <Button
                autoFocus
                onClick={(event) => handleCloseSettings(event, true)}
                color="primary"
                disabled={taskPolling === null || taskPolling === ''}
              >
                {t('view.dashboardToolbar.dialogs.settings.save')}
              </Button>
              <Button onClick={(event) => handleCloseSettings(event, false)} color="primary">
                {t('view.dashboardToolbar.dialogs.settings.cancel')}
              </Button>
            </DialogActions>
          </Dialog>
        </Suspense>
      )}
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {/*<IconButton*/}
          {/*  edge="start"*/}
          {/*  color="inherit"*/}
          {/*  aria-label="open drawer"*/}
          {/*  onClick={handleNavigatorMenu}*/}
          {/*  className={classes.menuButton}*/}
          {/*>*/}
          {/*  <MenuIcon />*/}
          {/*</IconButton>*/}
          <img id="APPLICATION_LOGO_ID" src={logo} alt="Logo" className={classes.logo_img} />
          {localStorage.getItem('__config.json__APPLICATION_SUBTITLE') === '' ? (
            <Typography
              id="APPLICATION_TITLE_ID"
              className={classes.title}
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
            >
              {/*{localStorage.getItem('__config.json__APPLICATION_TITLE')}*/}
            </Typography>
          ) : (
            <div className={classes.title}>
              <table>
                <thead>
                  <tr>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Typography
                        id="APPLICATION_TITLE_ID"
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        style={{ lineHeight: '20px' }}
                      >
                        {/*{localStorage.getItem('__config.json__APPLICATION_TITLE')}*/}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography
                        id="APPLICATION_SUBTITLE_ID"
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{ fontSize: '0.7em', float: 'right', lineHeight: '10px' }}
                      >
                        {/*{localStorage.getItem('__config.json__APPLICATION_SUBTITLE')}*/}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {/*<IconButton color="inherit" onClick={handleExportAuditTrail}>*/}
          {/*<PictureAsPdfIcon />*/}
          {/*</IconButton>*/}
          {/*<IconButton color="inherit">*/}
          {/*  <Badge badgeContent={4} color="secondary">*/}
          {/*    <NotificationsIcon />*/}
          {/*  </Badge>*/}
          {/*</IconButton>*/}
          {/*{allTasksLength !== 0 && (*/}
          {window.location.pathname &&
            window.location.pathname.split('/')[1] !==
              localStorage.getItem('__config.json__PATHNAME_PRODUCTION') && (
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <Chip
                  icon={<DomainIcon style={{ marginLeft: 8 }} />}
                  label={window.location.pathname.split('/')[1]}
                  color="secondary"
                  size="small"
                />
              </Typography>
            )}
          {upload_mechanism && (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
              <Tooltip
                arrow
                title={
                  <div style={{ fontSize: 13, padding: 5 }}>
                    {t('view.panel.toolbar.toolbar.upload_tooltip')}
                  </div>
                }
              >
                <IconButton
                  color="inherit"
                  size="large"
                  onClick={onOpenUpload}
                  style={{ marginRight: 20 }}
                >
                  <UploadIcon />
                </IconButton>
              </Tooltip>
              <ErrorBoundaryGuard isDialog>
                <UploadCmp />
              </ErrorBoundaryGuard>
            </Stack>
          )}
          {!isDatabaseOffline && (
            <ErrorBoundaryGuard>
              <DatabaseCmp />
            </ErrorBoundaryGuard>
          )}
          <ErrorBoundaryGuard>
            <Message />
          </ErrorBoundaryGuard>
          {!isDatabaseOffline && (
            <ErrorBoundaryGuard>
              <DashboardActivitiesComponent />
            </ErrorBoundaryGuard>
          )}
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              id="globalSearchButton"
              placeholder={t('view.dashboardToolbar.search')}
              autoComplete="new-password"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={onSearch}
              ref={searchGlobalRef}
            />
            {displayClear && (
              <IconButton
                className={clsx(classes.clearIcon, classes.searchIcon)}
                aria-label="clear"
                onClick={clearSearch}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>
          <Tooltip
            arrow
            placement="left-start"
            title={
              <div style={{ fontSize: 13, padding: 5 }}>
                {localStorage.SESSION_user_json
                  ? JSON.parse(localStorage.SESSION_user_json).fullName
                  : localStorage.SESSION_user_name}
              </div>
            }
          >
            <SpeedDial
              ariaLabel="SpeedDial"
              className={classes.speedDial}
              FabProps={{
                size: 'small',
                // color: 'transparent',
                disableFocusRipple: true,
                disableRipple: true,
              }}
              icon={
                <SpeedDialIcon
                  icon={
                    <Avatar alt="User" /*src={user}*/ className={classes.userButton}>
                      {JSON.parse(localStorage.getItem('SESSION_user_json'))?.admin ? (
                        <NinjaPerson style={{ marginRight: 3, height: 26 }} />
                      ) : (
                        <FaceIcon style={{ marginRight: 2, height: 26, width: 26 }} />
                      )}
                      <div className={classes.userName}>
                        {localStorage.SESSION_user_name
                          ? localStorage.SESSION_user_name.substring(0, 2)
                          : ''}
                      </div>
                    </Avatar>
                  }
                  openIcon={<KeyboardArrowDownIcon style={{ left: 5, width: 30, height: 30 }} />}
                />
              }
              onClose={handleClose}
              onClick={handleOpen}
              open={openDial}
              direction="down"
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={action.click}
                />
              ))}
            </SpeedDial>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Popover
        id={'search-popover'}
        open={showSearch}
        onClose={(event) => handleCloseSearch(event)}
        anchorEl={document.getElementById('globalSearchButton')}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.popoverContainer}
      >
        <ErrorBoundaryGuard isDialog>
          <Search researched={search} onClose={(e) => handleCloseSearch(e)} />
        </ErrorBoundaryGuard>
        <Button
          color="primary"
          className={classes.bouton}
          onClick={(event) => handleCloseSearch(event)}
        >
          {t('view.dashboardToolbar.popover.close')}
        </Button>
      </Popover>
    </Fragment>
  )
})

export default DashboardToolbar
