// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { useMemo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchUpdateDetailInfo } from '../../../redux/detail/actions'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import Grid from '@material-ui/core/Grid'
import * as PropTypes from 'prop-types'
import { alpha, Menu, MenuItem, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => ({
  tableContainer: {},
  selectedItem: {
    color: theme.palette.secondary.main,
    '& .MuiButton-label': {
      border: '1px solid ' + theme.palette.secondary.main,
      borderRadius: 3,
      padding: '0px 5px',
      background: alpha(theme.palette.secondary.main, 0.1),
    },
    // background: alpha(theme.palette.secondary.main, 0.3),
  },
  paginButton: {
    padding: '0px 10px',
  },
  paginButtonTest: {
    '& .MuiButton-label': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 115,
      justifyContent: 'start',
      display: 'block',
    },
  },
}))

function Fragment(props) {
  return null
}

Fragment.propTypes = { children: PropTypes.node }
const Pagination = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { collection, style } = props

  const [menuAnchor, setMenuAnchor] = React.useState(null)
  const [split, setSplit] = React.useState({ index: -1, direction: '' })

  // const unitaryAnalyses = useSelector(function (state) {
  //   return state.unitaryAnalyses.unitaryAnalysesFiltered
  // })
  const unitaryAnalysesAndCompound = useSelector(function (state) {
    if (collection === 'compound') {
      return state.unitaryAnalyses.unitaryAnalysesCompound
    } else {
      return state.unitaryAnalyses.unitaryAnalysesFiltered
    }
  })
  const unitaryAnalysesActive = useSelector(function (state) {
    return state.detail.detail.item
  })
  const unitaryAnalysesAnchor = useSelector(function (state) {
    return state.detail.detail.anchorEl
  })

  const unitaryAnalysesAndCompoundSplited = useMemo(() => {
    if (split.index === -1) {
      return unitaryAnalysesAndCompound
    } else {
      if (split.direction === 'left') {
        return unitaryAnalysesAndCompound.slice(0, split.index)
      } else {
        return unitaryAnalysesAndCompound.slice(split.index, unitaryAnalysesAndCompound.length)
      }
    }
  }, [JSON.stringify(split)])

  const currentUnitaryAnalyseIndex = useMemo(() => {
    return unitaryAnalysesAndCompound.map((uac) => uac._id).indexOf(unitaryAnalysesActive._id)
  }, [unitaryAnalysesActive])

  const prevUnitaryAnalyse = (index) => {
    const prevItem = unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex - index]
    dispatch(fetchUpdateDetailInfo(prevItem, 0, false, unitaryAnalysesAnchor, collection))
  }
  const nextUnitaryAnalyse = (index) => {
    let nextItem = unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex + index]
    dispatch(fetchUpdateDetailInfo(nextItem, 0, false, unitaryAnalysesAnchor, collection))
  }

  function handleClose(event, index) {
    setMenuAnchor(null)
    if (!isNaN(index) && index !== currentUnitaryAnalyseIndex) {
      let nextItem = unitaryAnalysesAndCompound[index]
      dispatch(fetchUpdateDetailInfo(nextItem, 0, false, unitaryAnalysesAnchor, collection))
    }
  }

  const StyledBadge = withStyles((theme) => ({
    badge: {
      padding: '2px 2px 1px',
      fontSize: 7,
      height: 'auto',
      width: 'auto',
      minWidth: 11,
      color: theme.palette.primary.main,
      border: '1px solid ' + theme.palette.primary.main,
      background: 'transparent',
    },
  }))(Badge)

  return (
    <div style={style}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <ButtonGroup variant="text" color="primary" aria-label="outlined primary button group">
          {unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex - 1] && (
            <Tooltip
              arrow
              placement="top"
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  <Trans i18nKey="view.common.chromatoDetail.pagination.jump">
                    Jump to
                    {{
                      val: unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex - 1][
                        collection === 'compound' ? 'analyse' : 'name'
                      ],
                    }}
                  </Trans>
                </div>
              }
            >
              <Button
                className={classes.paginButton}
                startIcon={<ArrowLeftIcon />}
                onClick={() => prevUnitaryAnalyse(1)}
              ></Button>
            </Tooltip>
          )}
          {unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex - 3] && (
            <Tooltip
              arrow
              placement="top"
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  {t('view.common.chromatoDetail.pagination.prev')}
                </div>
              }
            >
              <Button
                className={classes.paginButton}
                onClick={function (e) {
                  setSplit({ index: currentUnitaryAnalyseIndex - 2, direction: 'left' })
                  setMenuAnchor(e.currentTarget)
                }}
                style={{ fontSize: '0.55rem' }}
              >
                <StyledBadge badgeContent={currentUnitaryAnalyseIndex - 2} color="primary">
                  ...
                </StyledBadge>
              </Button>
            </Tooltip>
          )}
          {unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex - 2] && (
            <Tooltip
              arrow
              placement="top"
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  <Trans i18nKey="view.common.chromatoDetail.pagination.jump">
                    Jump to
                    {{
                      val: unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex - 2][
                        collection === 'compound' ? 'analyse' : 'name'
                      ],
                    }}
                  </Trans>
                </div>
              }
            >
              <Button
                className={clsx(classes.paginButton, classes.paginButtonTest)}
                onClick={() => prevUnitaryAnalyse(2)}
                style={{ fontSize: '0.55rem' }}
              >
                {
                  unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex - 2][
                    collection === 'compound' ? 'analyse' : 'name'
                  ]
                }
              </Button>
            </Tooltip>
          )}
          {unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex - 1] && (
            <Tooltip
              arrow
              placement="top"
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  <Trans i18nKey="view.common.chromatoDetail.pagination.jump">
                    Jump to
                    {{
                      val: unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex - 1][
                        collection === 'compound' ? 'analyse' : 'name'
                      ],
                    }}
                  </Trans>
                </div>
              }
            >
              <Button
                className={clsx(classes.paginButton, classes.paginButtonTest)}
                onClick={() => prevUnitaryAnalyse(1)}
                style={{ fontSize: '0.7rem' }}
              >
                {
                  unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex - 1][
                    collection === 'compound' ? 'analyse' : 'name'
                  ]
                }
              </Button>
            </Tooltip>
          )}
          <Tooltip
            arrow
            placement="top"
            title={
              <div style={{ fontSize: 13, padding: 5 }}>
                {t('view.common.chromatoDetail.pagination.to')}
              </div>
            }
          >
            <Button
              onClick={function (e) {
                setSplit({ index: -1, direction: '' })
                setMenuAnchor(e.currentTarget)
              }}
              className={clsx(classes.selectedItem, classes.paginButton)}
            >
              {collection === 'compound'
                ? unitaryAnalysesActive.analyse
                : unitaryAnalysesActive.name}
            </Button>
          </Tooltip>
          {unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex + 1] && (
            <Tooltip
              arrow
              placement="top"
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  <Trans i18nKey="view.common.chromatoDetail.pagination.jump">
                    Jump to
                    {{
                      val: unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex + 1][
                        collection === 'compound' ? 'analyse' : 'name'
                      ],
                    }}
                  </Trans>
                </div>
              }
            >
              <Button
                className={clsx(classes.paginButton, classes.paginButtonTest)}
                onClick={() => nextUnitaryAnalyse(1)}
                style={{ fontSize: '0.7rem' }}
              >
                {
                  unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex + 1][
                    collection === 'compound' ? 'analyse' : 'name'
                  ]
                }
              </Button>
            </Tooltip>
          )}
          {unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex + 2] && (
            <Tooltip
              arrow
              placement="top"
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  <Trans i18nKey="view.common.chromatoDetail.pagination.jump">
                    Jump to
                    {{
                      val: unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex + 2][
                        collection === 'compound' ? 'analyse' : 'name'
                      ],
                    }}
                  </Trans>
                </div>
              }
            >
              <Button
                className={clsx(classes.paginButton, classes.paginButtonTest)}
                onClick={() => nextUnitaryAnalyse(2)}
                style={{ fontSize: '0.55rem' }}
              >
                {
                  unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex + 2][
                    collection === 'compound' ? 'analyse' : 'name'
                  ]
                }
              </Button>
            </Tooltip>
          )}
          {unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex + 3] && (
            <Tooltip
              arrow
              placement="top"
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  {t('view.common.chromatoDetail.pagination.next')}
                </div>
              }
            >
              <Button
                className={classes.paginButton}
                onClick={function (e) {
                  setSplit({ index: currentUnitaryAnalyseIndex + 3, direction: 'right' })
                  setMenuAnchor(e.currentTarget)
                }}
                style={{ fontSize: '0.55rem' }}
              >
                <StyledBadge
                  badgeContent={
                    unitaryAnalysesAndCompound.length - (currentUnitaryAnalyseIndex + 3)
                  }
                  color="primary"
                >
                  ...
                </StyledBadge>
              </Button>
            </Tooltip>
          )}
          {unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex + 1] && (
            <Tooltip
              arrow
              placement="top"
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  <Trans i18nKey="view.common.chromatoDetail.pagination.jump">
                    Jump to
                    {{
                      val: unitaryAnalysesAndCompound[currentUnitaryAnalyseIndex + 1][
                        collection === 'compound' ? 'analyse' : 'name'
                      ],
                    }}
                  </Trans>
                </div>
              }
            >
              <Button
                className={classes.paginButton}
                endIcon={<ArrowRightIcon />}
                onClick={() => nextUnitaryAnalyse(1)}
              ></Button>
            </Tooltip>
          )}
        </ButtonGroup>
      </Grid>
      <Menu
        id="compoundMenu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => handleClose()}
      >
        {unitaryAnalysesAndCompoundSplited.map((uac, index) => (
          <MenuItem
            key={index}
            selected={split.index === -1 && index === currentUnitaryAnalyseIndex}
            onClick={(event) => handleClose(event, index)}
          >
            {collection === 'compound' ? uac.analyse : uac.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
})

export default Pagination
