// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import { connect, useSelector } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import Title from '../Title'
import { API_BO_URL, FLASK_URL } from '../../utils/config'
import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'
import ProgressCmp from '../common/ProgressCmp'
import { BootstrapTooltip, CustomTooltip, XLSFileInvert } from '../common/Style'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import Divider from '@material-ui/core/Divider'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import { Tooltip } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import IconButton from '@material-ui/core/IconButton'
import CircularProgressCmp from '../common/CircularProgressCmp'
import NoteButton from '../common/NoteButton'
import Link from '@material-ui/core/Link/Link'
import { displayPopupMessage } from '../common/Message'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import StorageIcon from '@material-ui/icons/Storage'
import LoopIcon from '@material-ui/icons/Loop'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { isLeasePassed } from '../../utils/utilities'
import ExtraMenu from './ExtraMenu'
import ApprovalIcon from '@mui/icons-material/Approval'
import { Stack } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import FaceIcon from '@mui/icons-material/Face'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { handleDownloadReport } from '../backlog/Tools'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

/**
 * Styles for the Information component
 */
const styles = (theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '0px 0px 4px 4px',
    padding: theme.spacing(2),
    height: '100%',
    // justifyContent: 'flex-start',
    // flex: '1 1 auto',
    // position: 'relative',
    overflow: 'hidden',
    '&:hover .collapsor': {
      opacity: 1,
      bottom: 2,
    },
  },
  progression: {
    padding: 12,
    paddingTop: 0,
  },
  chip: {
    marginRight: 5,
    marginBottom: 5,
    borderColor: 'inherit',
    '& > svg': {
      color: 'inherit',
      marginLeft: '8px !important',
    },
  },
  chipTooltip: {
    fontSize: 14,
  },
  flagInfo: {
    color: theme.palette.common.infoBlue,
    backgroundColor: alpha(theme.palette.common.infoBlue, 0.05),
  },
  flagWarning: {
    color: theme.palette.common.warning,
    backgroundColor: alpha(theme.palette.common.warning, 0.05),
  },
  flagError: {
    color: theme.palette.common.error,
    backgroundColor: alpha(theme.palette.common.error, 0.05),
  },
  divider: {
    paddingBottom: 12,
    paddingTop: 5,
  },
  priorityIcon: {
    top: 4,
    position: 'relative',
    marginRight: 10,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  collapsor: {
    '&.collapsed': { opacity: 1, bottom: 2 },
    '&.notCollapsed': { opacity: 0, bottom: 12 },
    background: 'white',
    width: 50,
    height: 10,
    left: 'calc(50% - 25px)',
    position: 'absolute',
    borderRadius: '0px 0px 15px 15px',
    transition: 'all 125ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    // boxShadow:
    //   '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  collapseButton: {
    width: 30,
    height: 30,
    padding: 0,
    bottom: -4,
    left: 'calc(50% - 15px)',
    position: 'absolute',
    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      background: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  reportButton: {
    marginLeft: 5,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
})

/**
 * Maps Redux state to component props
 * @param {Object} state - Redux state
 * @returns {Object} - Extracted properties from Redux store
 */
const mapStateToProps = (state) => {
  const { settings } = state
  return {
    delta: settings.dateTimeDelta,
  }
}

/**
 * Connects the component to the Redux store and applies styles.
 * @component
 * @extends React.Component
 * @param {Object} props - Component properties
 */
export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      /**
       * Information component that displays batch information.
       * This component is connected to the Redux store and styled with Material-UI.
       * It includes functionality to collapse/expand the view and display various batch details.
       */
      class Information extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            collapsed: localStorage.getItem('BATCH_information_collapsed')
              ? JSON.parse(localStorage.getItem('BATCH_information_collapsed')) === true
              : false,
            anchorEl: null,
            openExtraMenu: false,
            extractMenuAnchor: null,
          }
          this.collapse = this.collapse.bind(this)
        }

        // async componentDidMount() {
        //   const { batch } = this.props
        //
        //   let operatorFilter = {
        //     property: 'content.operator',
        //     value: batch.content.operator,
        //     filterType: 'string',
        //     operator: 'eq',
        //   }
        //   let op = encodeURIComponent(JSON.stringify(operatorFilter))
        //   const resFilter = await fetch(`${API_BO_URL()}.batch/page/?filter=%5B${op}%5D`, {
        //     method: 'GET',
        //     credentials: 'include',
        //   })
        //   const jsonFilter = await resFilter.json()
        //   this.setState({ operator: jsonFilter.size })
        // }

        /**
         * Opens the extract menu
         * @param event
         */
        openExtractMenu = (event) => {
          this.setState({ extractMenuAnchor: event.currentTarget })
        }

        /**
         * Closes the extract menu
         */
        handleCloseExtraMenu = () => {
          this.setState({ extractMenuAnchor: null })
        }

        /**
         * Toggles the collapsed state of the component
         */
        collapse() {
          const { collapsed } = this.state
          this.setState({ collapsed: !collapsed })
          localStorage.setItem('BATCH_information_collapsed', !collapsed)
        }

        /**
         * Renders the Information component.
         * @returns {JSX.Element} The rendered component.
         */
        render() {
          const { classes, batch, dispatch, delta } = this.props
          const { operator, collapsed, anchorEl, openExtraMenu, extractMenuAnchor } = this.state
          const isLoading =
            !isLeasePassed(batch?.lease?.date, delta) && batch?.lease?.reason === 'treatment'
          const me = this
          const openOtherMenu = function (event, row) {
            me.setState({
              anchorEl: { anchor: event.currentTarget, id: row.id, row: row },
              openExtraMenu: true,
            })
          }
          const extraMenuOpen = Boolean(extractMenuAnchor)

          /**
           * Closes the extra menu
           */
          const onClose = function () {
            me.setState({
              anchorEl: null,
              openExtraMenu: false,
            })
          }

          return (
            <Fragment>
              <ExtraMenu anchorEl={anchorEl} open={openExtraMenu} onClose={onClose} hideOpenBatch />
              <Paper
                elevation={0}
                className={classes.paper}
                style={{
                  height: collapsed ? '10px' : '100%',
                  paddingTop: collapsed ? '0px' : '10px',
                  paddingBottom: 0,
                }}
              >
                <div style={{ opacity: collapsed ? 0 : 1 }}>
                  <Title>
                    <Grid container spacing={3} direction="row" justifyContent="space-between">
                      <Grid item style={{ paddingBottom: 0, height: 50 }}>
                        <Box>
                          {t('view.batch.information.batch')} {batch ? batch.name : ''}
                        </Box>
                      </Grid>
                      {batch?.tags.indexOf('calibration_reference') !== -1 && (
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>{t('view.backlog.calibRef')}</div>
                          }
                        >
                          <Grid item style={{ paddingBottom: 0, height: 50 }}>
                            <BookmarkBorderIcon className={classes.priorityIcon} />
                          </Grid>
                        </Tooltip>
                      )}
                      {batch?.content?.stamp && (
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                              >
                                <AccessTimeIcon style={{ width: '0.7em', marginRight: 10 }} />
                                {batch?.content?.stamp?.date
                                  ? new Date(
                                      (batch.content.stamp.date - delta) * 1000,
                                    ).toLocaleString()
                                  : '--'}
                              </Stack>
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                              >
                                <FaceIcon style={{ width: '0.7em', marginRight: 10 }} />
                                {batch?.content?.stamp?.operator?.name
                                  ? batch.content.stamp.operator?.name
                                  : '--'}
                              </Stack>
                            </div>
                          }
                        >
                          <Grid item style={{ paddingBottom: 0, height: 50 }}>
                            <ApprovalIcon
                              className={classes.priorityIcon}
                              style={{ marginRight: 10 }}
                            />
                            {batch?.content?.stamp?.date
                              ? new Date((batch.content.stamp.date - delta) * 1000).toLocaleString()
                              : '--'}
                          </Grid>
                        </Tooltip>
                      )}
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {t('view.batch.information.device')}
                          </div>
                        }
                      >
                        <Grid item style={{ paddingBottom: 0, height: 50 }}>
                          {/*<AssignmentLateIcon className={classes.priorityIcon} />*/}
                          {/*<StorageIcon style={{ marginRight: 12, color: '#737373', height: 19 }} />*/}
                          <StorageIcon className={classes.priorityIcon} />
                          {batch.content.device}
                        </Grid>
                      </Tooltip>
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {t('view.batch.information.priority')}
                          </div>
                        }
                      >
                        <Grid item style={{ paddingBottom: 0, height: 50 }}>
                          <AssignmentLateIcon className={classes.priorityIcon} />
                          {batch.content.priority}
                        </Grid>
                      </Tooltip>
                      {/*<Grid item>{batch.content.due_date}</Grid>*/}
                      {batch.content.due_date &&
                        batch.content.due_date !== '0000-00-00 00:00:00' && (
                          <Grid item style={{ paddingBottom: 0, height: 50 }}>
                            {new Intl.DateTimeFormat('en-GB', {
                              year: 'numeric',
                              month: 'long',
                              day: '2-digit',
                            }).format(new Date(batch.content.due_date))}
                          </Grid>
                        )}
                      <Grid item style={{ height: 43, width: 48, padding: 0, paddingTop: 7 }}>
                        <ErrorBoundaryGuard isDialog>
                          <NoteButton
                            title
                            row={{
                              _id: batch._id,
                              notes: batch.content.notes ? batch.content.notes : [],
                              child_notes: batch.content.child_notes
                                ? batch.content.child_notes
                                : { calibrations: 0, analysis: 0 },
                            }}
                            collection={'batch'}
                            batchName={batch.name}
                          />
                        </ErrorBoundaryGuard>
                      </Grid>
                      <Grid item style={{ height: 43, width: 48, padding: 0, paddingTop: 7 }}>
                        <Menu
                          anchorEl={extractMenuAnchor}
                          open={extraMenuOpen}
                          onClose={this.handleCloseExtraMenu}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem
                            onClick={(event) => {
                              this.handleCloseExtraMenu()
                              handleDownloadReport(event, batch, false, dispatch)
                            }}
                          >
                            {t('view.batch.information.normal')}
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              this.handleCloseExtraMenu()
                              handleDownloadReport(event, batch, true, dispatch)
                            }}
                          >
                            {t('view.batch.information.detail')}
                          </MenuItem>
                        </Menu>
                        <Tooltip
                          arrow
                          title={
                            <div
                              style={{
                                fontSize: 13,
                                padding: 5,
                              }}
                            >
                              {t('view.batch.information.extract')}
                            </div>
                          }
                        >
                          <IconButton
                            aria-label="reportXLSX"
                            className={clsx(classes.reportButton)}
                            onClick={this.openExtractMenu}
                          >
                            <XLSFileInvert
                              style={{ marginRight: 8, width: 22, height: 22 }}
                              className={classes.colorBlue}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item style={{ height: 43 }}>
                        <ErrorBoundaryGuard isDialog>
                          <CircularProgressCmp />
                        </ErrorBoundaryGuard>
                      </Grid>
                      {batch.content.operator && (
                        <Grid item style={{ paddingBottom: 0, height: 50 }}>
                          <Box>
                            <Badge color="secondary" badgeContent={operator}>
                              <Avatar alt="Owner" /*src={user}*/>
                                {batch.content.operator &&
                                  batch.content.operator[
                                    batch.content.operator.length - 1
                                  ].name.substring(0, 2)}
                              </Avatar>
                            </Badge>
                          </Box>
                        </Grid>
                      )}
                      <Grid
                        container
                        style={{ padding: 12, paddingTop: 0, width: 'calc(100% - 50px)' }}
                      >
                        {/*{batch.content.flags.length !== 0 && (*/}
                        {/*  <Grid item xs={12} className={classes.divider}>*/}
                        {/*    <Divider variant="middle" />*/}
                        {/*  </Grid>*/}
                        {/*)}*/}
                        {batch.content.flags &&
                          batch.content.flags.map((row) => (
                            <BootstrapTooltip
                              title={row.reason}
                              className={classes.chipTooltip}
                              level={row.level}
                              key={`${batch._id}_${row.level}_${row.title}`}
                            >
                              <Chip
                                variant="outlined"
                                className={clsx(
                                  classes.chip,
                                  row.level === 'warning'
                                    ? classes.flagWarning
                                    : row.level === 'error'
                                    ? classes.flagError
                                    : classes.flagInfo,
                                )}
                                icon={
                                  row.level === 'warning' ? (
                                    <WarningIcon />
                                  ) : row.level === 'error' ? (
                                    <ErrorIcon />
                                  ) : (
                                    <InfoIcon />
                                  )
                                }
                                label={row.title}
                              />
                            </BootstrapTooltip>
                          ))}
                      </Grid>
                      <Grid
                        item
                        style={{ padding: 0, height: 40, width: 40, margin: '4px 4px 0px 0px' }}
                      >
                        <IconButton
                          onClick={(event) => {
                            openOtherMenu(event, batch)
                          }}
                          style={{ padding: 8 }}
                        >
                          <CustomTooltip
                            placement="top"
                            arrow
                            title={
                              isLoading ? (
                                <span>
                                  {t('view.panel.batchesCmp.tooltip.loading')}
                                  <div>
                                    {t('view.panel.batchesCmp.tooltip.operator')}:
                                    {batch.lease.operator}
                                  </div>
                                  <div>
                                    {t('view.panel.batchesCmp.tooltip.reason')}:{' '}
                                    {batch.lease.reason}
                                  </div>
                                </span>
                              ) : (
                                t('view.panel.batchesCmp.tooltip.other')
                              )
                            }
                          >
                            {isLoading ? (
                              <LoopIcon className={classes.loadingBatchIcon} />
                            ) : (
                              <MoreVertIcon />
                            )}
                          </CustomTooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Title>
                </div>
                <div
                  className={clsx(
                    collapsed ? 'collapsed' : 'notCollapsed',
                    'collapsor',
                    classes.collapsor,
                  )}
                >
                  <div
                    style={{
                      position: 'absolute',
                      width: 48,
                      height: 5,
                      top: -2,
                      left: 1,
                      background: 'white',
                    }}
                  ></div>
                  <IconButton
                    aria-label="collapse"
                    onClick={this.collapse}
                    className={classes.collapseButton}
                    style={{ transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)' }}
                  >
                    <ExpandLessIcon />
                  </IconButton>
                </div>
              </Paper>
            </Fragment>
          )
        }
      },
    ),
  ),
)
