// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useState } from 'react'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { FormLabel, makeStyles, Radio, RadioGroup, TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { DATABASE, FLASK_URL, PUBLIC_URL, RELEASE_VERSION_PATH, SERVICE } from '../../utils/config'
import { displayPopupMessage } from '../common/Message'
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone'
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone'
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone'
import ArchiveTwoToneIcon from '@material-ui/icons/ArchiveTwoTone'
import Tooltip from '@material-ui/core/Tooltip'
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'
import KoIcon from '@material-ui/icons/Clear'
import OkIcon from '@material-ui/icons/Done'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { fetchChangeSettings } from '../../redux/settings/actions'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { FormControlLabel } from '@mui/material'
import { formulas_dico } from '../../utils/utilities'
import { t } from 'i18next'

/**
 * Styles for the FormulasDialog component
 */
const useStyles = makeStyles((theme) => ({
  informationContainer: {
    paddingLeft: 10,
    '& .MuiGrid-item': {
      paddingTop: 15,
    },
  },
  auditButton: {
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  customRadioGroup: {
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#0000008a',
    },
  },
}))

/**
 * Dialog component for selecting and modifying formulas in an analysis.
 * @param {Object} props - Component props
 * @param {Object} props.analyse - Analysis data
 * @param {Object} props.batch - Batch data
 * @param {boolean} props.open - Whether the dialog is open
 * @param {Function} props.closeFct - Function to close the dialog
 * @param {Function} props.onSaveAnalyseValue - Function to save modified formula values
 */
export default function FormulasDialog({ analyse, batch, open, closeFct, onSaveAnalyseValue }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [tmpValues, setTmpValues] = useState({})
  const [formulaSelected, setFormulaSelected] = useState()

  /**
   * Effect triggered when the dialog opens.
   * Initializes the temporary formula values based on the current analysis.
   */
  useEffect(
    function () {
      if (open) {
        let tmp = {}
        Object.keys(formulas_dico).forEach((dico) => {
          Object.entries(
            analyse.content.hasOwnProperty('formulas_infos_by_moc')
              ? analyse.content.formulas_infos_by_moc
              : {},
          ).forEach((moc) => {
            moc[1].forEach((formula) => {
              if (
                formula.hasOwnProperty('id') &&
                analyse.content.formula_infos.hasOwnProperty('id') &&
                formula.id.toString() === analyse.content.formula_infos.id.toString()
              ) {
                // In case of formula is the selected formula, we have to get
                // the value from "analyse.content.formula_infos" and not from
                // "analyse.content.formulas_infos_by_moc[id]"
                formula = analyse.content.formula_infos
              }
              if (
                formula.resultat_calcule_sans_rdt.indexOf(dico) !== -1 &&
                formula.hasOwnProperty('id')
              ) {
                tmp = {
                  ...tmp,
                  [formula.id.toString()]: {
                    ...tmp[formula.id.toString()],
                    [dico]:
                      formulas_dico[dico].origin === 'formulas'
                        ? formula[formulas_dico[dico].correspondence]
                        : analyse.content[formulas_dico[dico].correspondence],
                  },
                }
              }
            })
          })
        })
        setTmpValues(tmp)

        if (analyse.content.formula_infos.hasOwnProperty('id')) {
          setFormulaSelected(analyse.content.formula_infos.id.toString())
        }
      }
    },
    [open],
  )

  /**
   * Handles closing of the dialog and saves formula values if 'save' is true.
   * @param {boolean} save - Whether to save the selected formula
   */
  const handleClose = (save) => {
    closeFct()
    if (save) {
      // Save the formulas
      const newFormula = Object.entries(analyse.content.formulas_infos_by_moc)
        .map((moc) =>
          moc[1].filter((formule) => {
            const test = formule.id === parseFloat(formulaSelected)
            // Dont update the moc, its not done in backend
            // if (test) {
            //   analyse.content.moc = moc[0]
            // }
            return test
          }),
        )
        .flat()
      if (newFormula && newFormula.length === 1) {
        analyse.content.formula_infos = newFormula[0]
        const refDico = tmpValues[formulaSelected]
        // Convert all values to float
        Object.keys(refDico).forEach((ref) => (refDico[ref] = parseFloat(refDico[ref])))
        Object.keys(refDico).forEach((formula) =>
          Object.keys(refDico[formula]).forEach((dico) => {
            if (dico !== 'index') {
              refDico[formula][dico] = parseFloat(refDico[formula][dico])
              if (formulas_dico[dico].origin === 'formulas') {
                analyse.content.formula_infos[formulas_dico[dico].correspondence] =
                  refDico[formula][dico]
              } else {
                analyse.content[formulas_dico[dico].correspondence] = refDico[formula][dico]
              }
            }
          }),
        )
      }
      onSaveAnalyseValue(
        { [formulaSelected]: tmpValues[formulaSelected] },
        analyse,
        'formula_infos',
        true,
      )
    }
  }

  /**
   * Converts formula expressions into a UI-friendly format with editable fields.
   * @param {string} key - Formula ID
   * @param {string} formu - Formula expression
   * @returns {Array} - JSX elements representing the formula
   */
  const convert_formulas = (key, formu) => {
    let result = formu
    result = result.replaceAll('CST', '1000')
    Object.keys(formulas_dico).forEach((dico) => {
      result = result.replaceAll(dico, '#@#' + dico + '#@#')
    })
    result = result.split('#@#')
    return result.map((res) => {
      if (formulas_dico[res] && tmpValues[key]) {
        return (
          <TextField
            style={{ margin: 5, width: 100 }}
            size="small"
            type="number"
            label={res}
            variant="outlined"
            disabled={formulaSelected !== key || formulas_dico[res]['readOnly']}
            value={tmpValues[key][res]}
            onChange={(event) =>
              setTmpValues({
                ...tmpValues,
                [key]: {
                  ...tmpValues[key],
                  [res]: event.target.value,
                },
              })
            }
          />
        )
      } else {
        return res
      }
    })
  }

  /**
   * Handles changes in the selected formula.
   * @param {Event} evt - Change event
   */
  function handleFormulasChange(evt) {
    setFormulaSelected(evt.target.value)
  }

  return (
    <Dialog
      open={open}
      // fullWidth={true}
      maxWidth={'md'}
      onClose={(event) => handleClose(false)}
      TransitionComponent={TransitionTop}
      aria-labelledby="dialog-reset-title"
    >
      <DialogTitle style={{ padding: 0 }}>
        <Box className={classes.header}>
          <CardMedia
            className={classes.cardImg}
            component="img"
            alt="header image"
            height="130"
            image={PUBLIC_URL + '/images/formulas.jpg'}
            title="Formulas"
          />
          <span className={classes.titleCard}>{t('view.dialogs.formulas.title')}</span>
        </Box>
      </DialogTitle>
      <DialogContent style={{ margin: '24px 10px 10px' }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={formulaSelected}
            onChange={handleFormulasChange}
            className={classes.customRadioGroup}
          >
            {analyse.content.formulas_infos_by_moc &&
              Object.entries(analyse.content.formulas_infos_by_moc).map((moc, index) => (
                <Fragment key={moc[0] + '_' + index}>
                  <FormLabel
                    style={{ margin: '25px 0px 5px' }}
                    id={'radio-buttons-group-' + moc[0]}
                  >
                    {moc[0]}
                  </FormLabel>
                  {moc[1]
                    .filter((moc) => moc.hasOwnProperty('id'))
                    .map((f, index) => (
                      <FormControlLabel
                        key={f.id.toString() + '_' + index}
                        value={f.id.toString()}
                        control={<Radio />}
                        label={
                          <Typography
                            component="div"
                            color="primary"
                            style={{ lineHeight: '48px', marginTop: 3 }}
                          >
                            {convert_formulas(f.id.toString(), f.resultat_calcule_sans_rdt)}
                            {f.moc_nom && (
                              <span
                                style={{ marginLeft: '10px', fontWeight: 'bold', color: 'grey' }}
                              >
                                {f.moc_nom}
                              </span>
                            )}
                          </Typography>
                        }
                      />
                    ))}
                </Fragment>
              ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={(event) => handleClose(true)} color="primary">
          {t('view.dialogs.formulas.save')}
        </Button>
        <Button onClick={(event) => handleClose(false)} color="primary">
          {t('view.dialogs.formulas.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
