// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import Title from '../Title'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableMultiGrid from '../common/Table_MultiGrid'
import clsx from 'clsx'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import { withRouter } from 'react-router-dom'
import { CONFIGURATION_PROFILE, DATABASE, FLASK_URL } from '../../utils/config'
import Tooltip from '@material-ui/core/Tooltip'
import {
  getComparator,
  stableSort,
  getClickAction,
  moveSampleToSubBatch,
  createSubBatch,
} from '../../utils/utilities'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import InputLabel from '@material-ui/core/InputLabel'
import AlphacodModifiedButtonIcon from '../common/AlphacodModifiedButtonIcon'
import NoteButton from '../common/NoteButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import {
  fetchAddAnalysisVisibilityTag,
  fetchRemoveAnalysisVisibilityTag,
} from '../../redux/analyses/actions'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import Switch from '@material-ui/core/Switch'
import FolderIcon from '@material-ui/icons/Folder'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { fetchChangeSettings } from '../../redux/settings/actions'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import MoveDialog from './MoveDialog'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Stack } from '@mui/material'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme) => ({
  paper: {
    boxShadow:
      '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    height: 'calc(100% - 35px)',
    overflow: 'hidden',
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  notSelected: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  selectedRow: {
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.1)',
    '& .nameAdorment': {
      display: 'flex !important',
    },
  },
  blink: {
    background: alpha(theme.palette.secondary.light, 0.19),
  },
  selectedRowBlink: {
    background: alpha(theme.palette.secondary.light, 0.37),
  },
  noBlink: {
    cursor: 'pointer',
  },
  cellFlags: {
    padding: 7,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.common.black, 0.03),
    },
  },
  toolbar: {
    position: 'absolute',
    top: 48,
    left: 70,
    background: '#fff',
    padding: 5,
    paddingRight: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  formControlMove: {
    marginTop: 15,
  },
  container: {
    '& .MuiOutlinedInput-input': {
      padding: 10,
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32,
    },
  },
  moveChip: {
    margin: 3,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  visibilityButton: {
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  optionLabel: {
    color: alpha(theme.palette.common.black, 0.54),
    fontSize: '1rem',
    marginRight: 20,
  },
  optionSelected: {
    color: theme.palette.secondary.main,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  visibilityDisabled: {
    opacity: 0.5,
  },
})

const mapStateToProps = (state) => {
  const { analyses, configuration } = state
  return {
    analysis: analyses.items,
    isFetching: analyses.isFetching,
    analysesSentToLims: analyses.analysesSentToLims,
    analysesNotSentToLims: analyses.analysesNotSentToLims,
    configSelected: configuration.configurations.filter(
      (config) => config._id === CONFIGURATION_PROFILE(),
    )[0],
  }
}

const BorderLinearProgress = withStyles({
  root: {
    top: 5,
    height: 10,
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
})(LinearProgress)

const CustomDialog = withStyles((theme) => ({
  paper: {
    height: '100%',
  },
}))(Dialog)

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class OthersTable extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            error: null,
            echClient: [],
            batch: null,
            selection: [],
            open: false,
            moveQuality: {},
            direction: 'asc',
            order: 'name',
            openBatchMove: false,
            familyBatches: null,
            option: 'move',
            moveBatch: null,
            openBatchCreate: false,
            createBatch: null,
            openExtentedView: false,
            recomputeCalibrationCheckbox: false,
          }
          this.tableMultiGridRef = React.createRef()
          this.onSelect = this.onSelect.bind(this)
          this.multiValidate = this.multiValidate.bind(this)
          this.handleClose = this.handleClose.bind(this)
          this.onChangeMoveBatch = this.onChangeMoveBatch.bind(this)
        }

        onSelect(selected) {
          this.setState({
            selection: selected.map((i) => this.getEchClient()[i]),
          })
        }

        onChangeMoveBatch(event) {
          const { familyBatches } = this.state
          const name = familyBatches[event.target.value].name
          this.setState({ moveBatch: { value: event.target.value, name: name } })
        }

        onChangeOption(event) {
          this.setState({ option: event.target.checked ? 'copy' : 'move' })
        }

        onChangeCreateBatch(event) {
          this.setState({ createBatch: event.target.value })
        }

        async multiValidate(value) {
          const dispatch = this.props.dispatch
          switch (value) {
            case 0:
              const selection = this.state.selection
              const moveQuality = selection.reduce(function (map, obj) {
                map[obj._id] = {
                  value: obj.name.match(/^std ([0-9]*) .*$/i)
                    ? obj.name.match(/^std ([0-9]*) .*$/i)[1]
                    : obj.name.match(/^([0-9]{6}).*$/i)
                    ? obj.name.match(/^([0-9]{6}).*$/i)[1]
                    : undefined,
                  type: obj.name.match(/^([0-9]{6}).*$/i) ? 'sample' : 'calibration',
                }
                if (!obj.name.match(/^([0-9]{6}).*$/i)) {
                  map[obj._id]['suffix'] = 'default'
                }
                return map
              }, {})
              this.setState({ open: true, moveQuality: moveQuality })
              break
            case 1:
              this.setState({ openBatchCreate: true, createBatch: null })
              break
            case 2:
              this.setState({
                openBatchMove: true,
                moveBatch: null,
              })
              fetch(`${FLASK_URL()}/batch/${this.props.match.params.batchId}/family`, {
                method: 'GET',
                credentials: 'include',
              })
                .then((response) => {
                  if (!response.ok) {
                    const statusText = response.statusText
                    const status = response.status
                    const url = response.url
                    return response.text().then((errorMessage) => {
                      const error = new Error(`${statusText} : ${errorMessage}`)
                      if (response.headers.get('content-type') === 'application/json') {
                        error.stack = JSON.stringify(
                          JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                          null,
                          2,
                        )
                      } else {
                        error.stack = new Error().stack
                      }
                      error.statusText = statusText
                      error.status = status
                      error.url = url
                      throw error
                    })
                  }
                  return response.json()
                })
                .then((json) => {
                  this.setState({
                    familyBatches: json,
                  })
                })
                .catch((error) => {
                  if (error.status === 403) {
                    dispatch(fetchChangeSettings('loginPopup', true))
                  }
                  displayPopupMessage(
                    dispatch,
                    'error',
                    t('view.batch.othersTable.error_family_title'),
                    <Fragment>
                      <div>{t('view.batch.othersTable.error_family_msg')}</div>
                      {generateErrorPart(error)}
                    </Fragment>,
                  )
                })
              break
            default:
              break
          }
        }

        handleClose(event, save) {
          const tableMultiGridRef = this.tableMultiGridRef
          const me = this
          me.setState({ open: false })
          if (typeof save === 'boolean' && save) {
            tableMultiGridRef.current.clearSelection()
          }
        }

        onChangeVisibility(event, item) {
          const { dispatch } = this.props
          event.stopPropagation()
          event.preventDefault()

          const displayMessage = (mode, error) => {
            displayPopupMessage(
              dispatch,
              error ? 'error' : 'success',
              t('view.batch.othersTable.visibility_title'),
              <Fragment>
                {error ? (
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.batch.othersTable.visibility_msg1">
                        The visibility for the analysis <i>{{ val: item.name }}</i> can't be
                        updated:
                      </Trans>
                    </div>
                    {generateErrorPart(error)}
                  </Fragment>
                ) : (
                  <div>
                    <Trans i18nKey="view.batch.othersTable.visibility_msg2">
                      The analysis <i>{{ val1: item.name }}</i> is now
                      {{
                        val2:
                          mode === 'add'
                            ? t('view.batch.othersTable.visible')
                            : t('view.batch.othersTable.hidden'),
                      }}{' '}
                      in the navigation panel.
                    </Trans>
                  </div>
                )}
              </Fragment>,
            )
          }
          // Add or Remove tag off_visibility on analysis object
          if (item.visibility) {
            dispatch(fetchRemoveAnalysisVisibilityTag(item))
              .then(function (resp) {
                displayMessage('remove')
              })
              .catch(function (error) {
                displayMessage('remove', error)
              })
          } else {
            dispatch(fetchAddAnalysisVisibilityTag(item))
              .then(function (resp) {
                if (resp.error) {
                  displayMessage('add', resp.error)
                } else {
                  displayMessage('add')
                }
              })
              .catch(function (error) {
                displayMessage('add', error)
              })
          }
        }

        getEchClient() {
          const { analysis, analysesSentToLims, analysesNotSentToLims } = this.props
          const { direction, order } = this.state
          const analysisFiltered = analysis
            .filter((analyse) => analyse.content.type === 'other' && !analyse.content.inProgress)
            .map((ech) => ({
              ...ech,
              sentToLims:
                analysesSentToLims && analysesSentToLims.indexOf(ech._id) !== -1
                  ? true
                  : analysesNotSentToLims && analysesNotSentToLims.indexOf(ech._id) !== -1
                  ? false
                  : null,
              visibility: !(ech.tags && ech.tags.toString().indexOf('off_visibility') !== -1),
            }))
          return stableSort(analysisFiltered, getComparator(direction, order))
        }

        render() {
          const { classes, dispatch, tableItemSelectedForColor, isFetching } = this.props
          const {
            selection,
            open,
            openBatchMove,
            familyBatches,
            option,
            moveBatch,
            openBatchCreate,
            createBatch,
            openExtentedView,
          } = this.state

          const tableMultiGridRef = this.tableMultiGridRef
          const me = this

          const onDisplayClient = (id) => {
            localStorage.setItem('BATCH_previous_selection', id)
            this.props.history.push('/?' + DATABASE() + '/' + this.props.match.params.batchId + '/analysis/' + id)
          }

          const echClient = this.getEchClient()

          const handleOpenRenameDialog = (event, row) => {
            event.stopPropagation()
            event.preventDefault()
            dispatch(fetchChangeSettings('analysisToRename', row))
          }

          const selectionEmpty = selection.length === 0

          const handleCloseBatchMove = (event, confirm) => {
            this.setState({ openBatchMove: false })
            if (typeof confirm === 'boolean' && confirm) {
              this.tableMultiGridRef.current.clearSelection()
              moveSampleToSubBatch(dispatch, moveBatch.value, moveBatch.name, selection, option)
            }
          }

          const handleCloseBatchCreate = (event, confirm) => {
            this.setState({ openBatchCreate: false })
            // const batchName = this.createSubBatchRef.current.children[1].children[0].value
            if (typeof confirm === 'boolean' && confirm) {
              this.tableMultiGridRef.current.clearSelection()
              createSubBatch(
                dispatch,
                this.props.match.params.batchId,
                createBatch,
                selection,
                option,
              )
            }
          }

          const getRenderCmp = (fullscreenMode) => {
            return (
              <Paper className={classes.paper} elevation={fullscreenMode ? 0 : 1}>
                <Dialog
                  open={openBatchCreate}
                  onClose={handleCloseBatchCreate}
                  TransitionComponent={TransitionTop}
                  aria-labelledby="draggable-dialog-title"
                >
                  <StyledDialogTitle>{t('view.batch.othersTable.move_copy')}</StyledDialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('view.batch.othersTable.selected_samples')}
                    </DialogContentText>
                    <div
                      style={{
                        maxWidth: 600,
                        maxHeight: 400,
                        overflow: 'scroll',
                        marginTop: 6,
                        marginBottom: 20,
                      }}
                    >
                      {selection.map((item, index) => (
                        <Chip
                          key={item.name + '_' + index}
                          className={classes.moveChip}
                          label={item.name}
                        />
                      ))}
                    </div>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                      style={{ marginBottom: 4 }}
                    >
                      <Grid item className={classes.optionLabel}>
                        {t('view.batch.othersTable.option')}
                      </Grid>
                      <Grid item className={option === 'move' ? classes.optionSelected : null}>
                        {t('view.batch.othersTable.move')}
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={option === 'copy'}
                          color="default"
                          inputProps={{ 'aria-label': 'checkbox with default color' }}
                          onChange={(event) => this.onChangeOption(event)}
                        />
                      </Grid>
                      <Grid item className={option === 'copy' ? classes.optionSelected : null}>
                        {t('view.batch.othersTable.copy')}
                      </Grid>
                    </Grid>
                    <TextField
                      required
                      fullWidth
                      id="create-sub-batch"
                      label={t('view.batch.othersTable.sub_batch')}
                      variant="outlined"
                      onChange={(event) => this.onChangeCreateBatch(event)}
                      error={
                        createBatch === '' || createBatch === null || createBatch === undefined
                      }
                      style={{ marginLeft: 75, marginBottom: 10, width: 'calc(100% - 75px)' }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={(event) => handleCloseBatchCreate(event, true)}
                      disabled={
                        createBatch === '' || createBatch === null || createBatch === undefined
                      }
                      color="primary"
                    >
                      {t('view.batch.othersTable.create')}
                    </Button>
                    <Button
                      onClick={(event) => handleCloseBatchCreate(event, false)}
                      color="primary"
                    >
                      {t('view.batch.othersTable.cancel')}
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openBatchMove}
                  onClose={handleCloseBatchMove}
                  TransitionComponent={TransitionTop}
                  aria-labelledby="draggable-dialog-title"
                  style={{ minWidth: 400 }}
                >
                  <StyledDialogTitle>
                    {t('view.batch.othersTable.move_copy_existing')}
                  </StyledDialogTitle>
                  {familyBatches ? (
                    <DialogContent>
                      <DialogContentText>{t('view.batch.othersTable.sure')}</DialogContentText>
                      <DialogContentText>
                        {t('view.batch.othersTable.will_moved')}
                        <div style={{ marginTop: 5 }}>
                          {selection.map((item) => (
                            <Chip key={item.name} className={classes.moveChip} label={item.name} />
                          ))}
                        </div>
                      </DialogContentText>
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item className={classes.optionLabel}>
                          {t('view.batch.othersTable.option')}
                        </Grid>
                        <Grid item className={option === 'move' ? classes.optionSelected : null}>
                          {t('view.batch.othersTable.move')}
                        </Grid>
                        <Grid item>
                          <Switch
                            checked={option === 'copy'}
                            color="default"
                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                            onChange={(event) => this.onChangeOption(event)}
                          />
                        </Grid>
                        <Grid item className={option === 'copy' ? classes.optionSelected : null}>
                          {t('view.batch.othersTable.copy')}
                        </Grid>
                      </Grid>
                      <FormControl
                        required
                        fullWidth
                        variant="outlined"
                        className={classes.formControlMove}
                        error={moveBatch === '' || moveBatch === null || moveBatch === undefined}
                        style={{ marginLeft: 75, width: 'calc(100% - 75px)' }}
                      >
                        <InputLabel>{t('view.batch.othersTable.batch')}</InputLabel>
                        <Select
                          variant="outlined"
                          onChange={this.onChangeMoveBatch}
                          label={t('view.batch.othersTable.batch')}
                        >
                          {Object.entries(familyBatches).map((batch) => (
                            <MenuItem key={batch[0]} value={batch[0]}>
                              {batch[1].name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </DialogContent>
                  ) : (
                    <DialogContent>
                      <DialogContentText>
                        {t('view.batch.othersTable.no_sub_batch')}
                      </DialogContentText>
                      <div style={{ marginBottom: 20 }}>
                        {selection.map((item) => (
                          <Chip key={item.name} className={classes.moveChip} label={item.name} />
                        ))}
                      </div>
                      <DialogContentText>
                        {t('view.batch.othersTable.create_destination')}
                      </DialogContentText>
                    </DialogContent>
                  )}

                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={(event) => handleCloseBatchMove(event, true)}
                      color="primary"
                      disabled={moveBatch === '' || moveBatch === null || moveBatch === undefined}
                    >
                      {t('view.batch.othersTable.move')}
                    </Button>
                    <Button onClick={(event) => handleCloseBatchMove(event, false)} color="primary">
                      {t('view.batch.othersTable.cancel')}
                    </Button>
                  </DialogActions>
                </Dialog>
                <ErrorBoundaryGuard isDialog>
                  <MoveDialog
                    selection={selection}
                    open={open}
                    closeFct={this.handleClose}
                    origin={'other'}
                  />
                </ErrorBoundaryGuard>
                <Title>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {/*<Grid item>*/}
                    <Box>{t('view.batch.othersTable.others')}</Box>
                    {!fullscreenMode && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <div className={classes.tooltip}>
                            {t('view.batch.othersTable.fullscreen')}
                          </div>
                        }
                      >
                        <IconButton
                          size="small"
                          aria-label="extend"
                          onClick={() => {
                            this.setState({ openExtentedView: true })
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {/*</Grid>*/}
                  </Grid>
                </Title>
                <div className={classes.tableContainer}>
                  <ErrorBoundaryGuard>
                    <TableMultiGrid
                      ref={this.tableMultiGridRef}
                      collection={'analysis'}
                      onMoleculeClick={(item) => onDisplayClient(item._id)}
                      onMoleculeDoubleClick={() => true}
                      tableItemSelectedForColor={tableItemSelectedForColor}
                      idTable={'myOthersTable'}
                      orderBy={'name'}
                      selection
                      onSelect={this.onSelect}
                      data={echClient}
                      isFetchingData={isFetching}
                      sortAction={function (order, direction) {
                        tableMultiGridRef.current.clearSelection()
                        me.setState({
                          order: order,
                          direction: direction,
                        })
                      }}
                      columns={[
                        {
                          key: 'visibility',
                          label: t('view.batch.othersTable.visibility'),
                          size: 70,
                        },
                        { key: 'flags', label: t('view.batch.othersTable.flags'), size: 70 },
                        {
                          key: 'sentToLims',
                          label: t('view.batch.samplesTable.column.reported'),
                          size: 70,
                          customDisplay: function (value) {
                            return value ? (
                              <CheckIcon style={{ color: '#0000008a' }} />
                            ) : value === false ? (
                              <CloseIcon style={{ color: '#0000008a' }} />
                            ) : null
                          },
                          customTitle: function () {
                            return (
                              <Tooltip
                                title={
                                  <div className={classes.tooltip}>
                                    {t('view.batch.samplesTable.column.reported_lims')}
                                  </div>
                                }
                              >
                                <span>{t('view.batch.samplesTable.column.reported')}</span>
                              </Tooltip>
                            )
                          },
                        },
                        { key: 'notes', label: t('view.batch.othersTable.notes'), size: 60 },
                        // {
                        //   key: 'modified',
                        //   label: 'Modified',
                        //   size: 75,
                        // },
                        { key: 'name', label: t('view.batch.othersTable.name'), size: 150 },
                        {
                          key: 'progression',
                          label: t('view.batch.othersTable.progression'),
                          size: 180,
                        },
                      ]}
                      fixedRowCount={1}
                      rowHeight={40}
                      getColumnRender={function (
                        columnIndex,
                        key,
                        rowIndex,
                        style,
                        item,
                        rowClassNames,
                        columnKey,
                        moleculeOverState,
                        moleculeOver,
                        selected,
                        onMoleculeClick,
                        onMoleculeDoubleClick,
                        columns,
                      ) {
                        if (columnKey === 'name') {
                          return (
                            <Tooltip
                              key={key}
                              arrow
                              title={<div className={classes.tooltip}>{item.content.file}</div>}
                            >
                              <div
                                style={style}
                                className={clsx(
                                  item.visibility ? null : classes.disabled,
                                  rowClassNames,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onDisplayClient(item._id)
                                  }
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={0}
                                >
                                  <div>{item.name}</div>
                                  <div
                                    style={{ display: 'none', height: 20 }}
                                    className={'nameAdorment'}
                                  >
                                    <IconButton
                                      style={{ padding: 0 }}
                                      onClick={(event) => handleOpenRenameDialog(event, item)}
                                      onMouseDown={(event) => event.preventDefault()}
                                    >
                                      <Tooltip
                                        key={key}
                                        arrow
                                        placement={'top'}
                                        title={
                                          <div className={classes.tooltip}>
                                            {t('view.batch.qualityControlTable.rename')}
                                          </div>
                                        }
                                      >
                                        <DriveFileRenameOutlineIcon />
                                      </Tooltip>
                                    </IconButton>
                                  </div>
                                </Stack>
                              </div>
                            </Tooltip>
                          )
                        }
                        if (columnKey === 'visibility') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                item.visibility ? null : classes.visibilityDisabled,
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(dispatch, event, item, 'analysis', openFct)
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                            >
                              <Tooltip
                                arrow
                                title={
                                  <div className={classes.tooltip}>
                                    {t('view.batch.othersTable.show_hide')}
                                  </div>
                                }
                              >
                                <IconButton
                                  aria-label="changeVisibility"
                                  className={classes.visibilityButton}
                                  onClick={(event) => me.onChangeVisibility(event, item)}
                                >
                                  {!item.visibility ? (
                                    <VisibilityOffIcon
                                      // style={{ color: '#AF0000' }}
                                      className={'visibilityOffIcon'}
                                    />
                                  ) : (
                                    <VisibilityIcon className={'visibilityIcon'} />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </div>
                          )
                        }
                        if (columnKey === 'notes') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                item.visibility ? null : classes.disabled,
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(dispatch, event, item, 'analysis', openFct)
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                            >
                              <ErrorBoundaryGuard isDialog>
                                <NoteButton
                                  row={{
                                    _id: item._id,
                                    notes: item.content.notes ? item.content.notes : [],
                                  }}
                                  collection={'analysis'}
                                />
                              </ErrorBoundaryGuard>
                            </div>
                          )
                        }
                        if (columnKey === 'modified') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                item.visibility ? null : classes.disabled,
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(dispatch, event, item, 'analysis', openFct)
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                            >
                              <ErrorBoundaryGuard isDialog>
                                <AlphacodModifiedButtonIcon item={item} />
                              </ErrorBoundaryGuard>
                            </div>
                          )
                        }
                        if (columnKey === 'flags') {
                          return (
                            <div
                              style={style}
                              key={item._id + columnKey + rowIndex + key}
                              className={clsx(
                                item.visibility ? null : classes.disabled,
                                'cellFlags',
                                rowClassNames,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onDisplayClient(item._id)}
                              onClick={function (event) {
                                const openFct = () => {}
                                getClickAction(dispatch, event, item, 'analysis', openFct)
                              }}
                            >
                              <ErrorBoundaryGuard isDialog>
                                <AlphacodFlagIcon item={item.content} hideGeneralTab={true} />
                              </ErrorBoundaryGuard>
                            </div>
                          )
                        }
                        if (columnKey === 'progression') {
                          return (
                            <Tooltip
                              key={item._id + columnKey + rowIndex + key}
                              arrow
                              title={
                                <div className={classes.tooltip}>
                                  <div>
                                    {t('view.batch.othersTable.progression') +
                                      `: ${
                                        item.content.progression_requested
                                          ? item.content.progression_requested.toFixed(2)
                                          : '0.00'
                                      } %`}
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    {t('view.batch.othersTable.detected') +
                                      `: ${
                                        item.content.nb_detected_validated
                                          ? item.content.nb_detected_validated
                                          : '0'
                                      }`}
                                  </div>
                                  <div>
                                    {t('view.batch.othersTable.suspected') +
                                      `: ${
                                        item.content.nb_suspected_validated
                                          ? item.content.nb_suspected_validated
                                          : '0'
                                      }`}
                                  </div>
                                  <div>
                                    {t('view.batch.othersTable.excluded') +
                                      `: ${
                                        item.content.nb_excluded_validated
                                          ? item.content.nb_excluded_validated
                                          : '0'
                                      }`}
                                  </div>
                                  <div style={{ marginTop: 10 }}>{`OK: ${
                                    item.content.nb_ok_validated
                                      ? item.content.nb_ok_validated
                                      : '0'
                                  }`}</div>
                                  <div>{`KO: ${
                                    item.content.nb_ko_validated
                                      ? item.content.nb_ko_validated
                                      : '0'
                                  }`}</div>
                                </div>
                              }
                            >
                              <div
                                style={style}
                                // style={
                                //   columnIndex === columns.length - 1
                                //     ? {
                                //         ...style,
                                //         width: `calc(100% - ${
                                //           columns.reduce((acc, col) => acc + col.size, 0) -
                                //           columns[columnIndex].size
                                //         }px)`,
                                //       }
                                //     : { style }
                                // }
                                className={clsx(
                                  item.visibility ? null : classes.disabled,
                                  rowClassNames,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onDisplayClient(item._id)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onDisplayClient(item._id)
                                  }
                                  getClickAction(dispatch, event, item, 'analysis', openFct)
                                }}
                              >
                                <ErrorBoundaryGuard isDialog>
                                  <BorderLinearProgress
                                    // className={classes.margin}
                                    variant="determinate"
                                    // color="secondary"
                                    value={
                                      item.content.progression_requested
                                        ? item.content.progression_requested
                                        : 0
                                    }
                                  />
                                </ErrorBoundaryGuard>
                              </div>
                            </Tooltip>
                          )
                        }
                        return null
                      }}
                    />
                  </ErrorBoundaryGuard>
                </div>
                {!selectionEmpty && (
                  <span
                    className={classes.toolbar}
                    style={{ visibility: selectionEmpty ? 'hidden' : 'visible' }}
                  >
                    <div>
                      <Divider orientation="vertical" />
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {t('view.batch.othersTable.move_qc')}
                          </div>
                        }
                      >
                        <IconButton
                          className={classes.toolbarButton}
                          aria-label="MoveToQualityControl"
                          onClick={() => this.multiValidate(0)}
                        >
                          <SwapHorizIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {t('view.batch.othersTable.move_copy')}
                          </div>
                        }
                      >
                        <IconButton
                          className={classes.toolbarButton}
                          aria-label="Create sub-batch"
                          onClick={() => this.multiValidate(1)}
                        >
                          <CreateNewFolderIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        title={
                          <div className={classes.tooltip}>
                            {t('view.batch.othersTable.move_existing')}
                          </div>
                        }
                      >
                        <IconButton
                          className={classes.toolbarButton}
                          aria-label="Move to an existing batch"
                          onClick={() => this.multiValidate(2)}
                        >
                          <FolderIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </span>
                )}
              </Paper>
            )
          }
          return (
            <Fragment>
              <CustomDialog
                fullWidth={true}
                maxWidth={'xl'}
                open={openExtentedView}
                onClose={() => this.setState({ openExtentedView: false })}
                TransitionComponent={TransitionTop}
                aria-labelledby="draggable-dialog-title"
              >
                {getRenderCmp(true)}
                <DialogActions>
                  <Button
                    onClick={() => this.setState({ openExtentedView: false })}
                    color="primary"
                  >
                    {t('view.batch.othersTable.close')}
                  </Button>
                </DialogActions>
              </CustomDialog>
              {getRenderCmp()}
            </Fragment>
          )
        }
      },
    ),
  ),
)
