// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React from 'react'
import { withRouter } from 'react-router-dom'
import { DRAWER_WIDTH } from '../utils/config'
import { Stack } from '@mui/material'

const EmptyBacklog = withRouter((props) => {
  return (
    <div
      style={{
        background: 'white',
        width: '94%',
        height: 'calc(100% - 8px)',
        padding: 10,
        marginLeft: '3%',
        borderRadius: '0px 0px 4px 4px',
        // boxShadow:
        //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      }}
    ></div>
  )
})
export default EmptyBacklog
