// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import { getBatchById } from '../../redux/batches/selectors'
import { getAnalysisById } from '../../redux/analyses/selectors'
import { t } from 'i18next'

const styles = () => ({
  progression: {
    padding: 12,
    paddingTop: 0,
  },
})

const mapStateToProps = (state, ownProps) => {
  const { batches, analyses } = state
  const batch = getBatchById(batches, ownProps.match.params.batchId)
  const analysis = getAnalysisById(analyses, ownProps.match.params.analysisId)
  return {
    batch,
    analysis,
  }
}

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class Detail extends React.Component {
        render() {
          const { classes, batch, analysis, type } = this.props

          let analysisProgress = null
          if (type !== 'analyse') {
            analysisProgress = batch.content.analyses_progression_requested
              ? batch.content.analyses_progression_requested
              : 0
          } else {
            analysisProgress = analysis.content.progression_requested
              ? analysis.content.progression_requested
              : 0
          }

          return (
            <Fragment>
              {(!type || (type && type === 'calibration')) && (
                <Grid
                  container
                  style={!type ? { paddingRight: 20, width: '50%' } : { width: '100%' }}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Box>{t('view.common.progressCmp.prog_calib')}</Box>
                  </Grid>
                  <Grid item>
                    <Box style={{ fontSize: '0.8rem', paddingTop: 12 }}>
                      {batch.content.calibration_progression_requested
                        ? batch.content.calibration_progression_requested.toFixed(2) + '%'
                        : '0.00%'}
                    </Box>
                  </Grid>
                </Grid>
              )}
              {(!type || (type && type === 'analyse')) && (
                <Grid
                  container
                  style={!type ? { paddingLeft: 20, width: '50%' } : { width: '100%' }}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Box>
                      {type !== 'analyse'
                        ? t('view.common.progressCmp.prog_analysis')
                        : t('view.common.progressCmp.prog')}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box style={{ fontSize: '0.8rem', paddingTop: 12 }}>
                      {analysisProgress.toFixed(2) + '%'}
                    </Box>
                  </Grid>
                </Grid>
              )}
              {(!type || (type && type === 'calibration')) && (
                <Grid
                  item
                  xs={!type ? 6 : 12}
                  className={classes.progress}
                  style={!type ? { paddingRight: 20 } : {}}
                >
                  <LinearProgress
                    variant="determinate"
                    value={
                      batch.content.calibration_progression_requested
                        ? parseFloat(batch.content.calibration_progression_requested.toFixed(2))
                        : 0
                    }
                  />
                </Grid>
              )}
              {(!type || (type && type === 'analyse')) && (
                <Grid
                  item
                  xs={!type ? 6 : 12}
                  className={classes.progress}
                  style={!type ? { paddingLeft: 20 } : {}}
                >
                  <LinearProgress
                    variant="determinate"
                    value={parseFloat(analysisProgress.toFixed(2))}
                  />
                </Grid>
              )}
            </Fragment>
          )
        }
      },
    ),
  ),
)
