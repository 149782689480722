// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Loading from '../Loading'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import StarIcon from '@material-ui/icons/Star'
import { t } from 'i18next'
import { generateUUID } from '../../utils/utilities'

const styles = (theme) => ({
  info: {
    color: theme.palette.common.infoBlue,
  },
  warning: {
    color: theme.palette.common.warning,
  },
  error: {
    color: theme.palette.common.error,
  },
  chipTooltip: {
    fontSize: 14,
    '& .mainIcon .MuiSvgIcon-root': {
      width: 35,
      height: 35,
    },
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.secondary.main,
    width: '90%',
    opacity: 0.5,
    marginTop: 20,
    marginBottom: 30,
  },
  mainContainer: {
    height: '100%',
    overflowY: 'auto',
    marginLeft: 20,
  },
})

export default withRouter(
  withStyles(styles)(
    class Detail extends React.Component {
      constructor(props) {
        super(props)
        this.state = {
          activePan: true,
        }
      }

      getClassificationIcon = function (classification) {
        const { classes } = this.props
        switch (classification) {
          case 'info':
            return <InfoIcon className={classes.info} />
          case 'warning':
            return <WarningIcon className={classes.warning} />
          case 'error':
            return <ErrorIcon className={classes.error} />
          default:
            return <Fragment />
        }
      }

      getFlagIcon = function (item) {
        const flags = item.flags
        var flags_dict = new Map()
        flags_dict.set('info', 0)
        flags_dict.set('warning', 1)
        flags_dict.set('error', 2)
        var icon_value = -1
        var icon = ''

        const flags_globals = flags.globals ? flags.globals : []
        flags_globals.forEach((flag) => {
          if (flags_dict.get(flag.level) > icon_value) {
            icon_value = flags_dict.get(flag.level)
            icon = flag.level
          }
        })
        const flags_channels = flags.channels ? flags.channels : {}
        const flags_channels_entries = Object.entries(flags_channels)
        if (flags_channels_entries.length > 0 && icon_value !== 2) {
          flags_channels_entries.forEach((channel) => {
            channel[1].forEach((flag) => {
              if (flags_dict.get(flag.level) > icon_value) {
                if (channel[0] !== item.mainChannel.toString() && flag.level === 'error') {
                  icon_value = 1
                  icon = 'warning'
                } else {
                  icon_value = flags_dict.get(flag.level)
                  icon = flag.level
                }
              }
            })
          })
        }
        return icon
      }

      getGlobalInfo = function (mainChannel, flags, classification, chipTooltip, mainIcon) {
        const flags_globals = flags && flags.globals ? flags.globals : []
        const flags_globals_items = flags_globals.map((flag, index) => {
          return (
            <Grid item key={generateUUID()}>
              <table
                style={{ marginLeft: 20, marginRight: 20, marginBottom: 10 }}
                key={generateUUID()}
              >
                <thead>
                  <tr>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'top', width: 35 }}>
                      {this.getClassificationIcon(flag.level)}
                    </td>
                    <td style={{ lineHeight: 1.2 }}>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {flag.title}
                              {flag.classification && (
                                <span style={{ fontSize: 13 }}> {flag.classification}</span>
                              )}
                              {':'}
                            </td>
                          </tr>
                          <tr style={{ fontSize: 12 }}>
                            <td>
                              <i>{flag.reason}</i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          )
        })
        return (
          <div className={chipTooltip}>
            {flags_globals_items.length > 0 ? (
              <div style={{ marginBottom: 10, marginTop: 10 }}>
                <table style={{ marginBottom: 30 }}>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={'mainIcon'} style={{ verticalAlign: 'top', width: 40 }}>
                        {mainIcon}
                      </td>
                      <td style={{ fontSize: 16 }}>
                        {t('view.common.alphacod_flag_icon.classification')}
                        {classification && classification.global ? ' ' + classification.global : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Grid container direction="row">
                  {flags_globals_items}
                </Grid>
              </div>
            ) : (
              ''
            )}
          </div>
        )
      }

      getChannelsInfo = function (mainChannel, flags, classification, chipTooltip) {
        const flags_channels = flags && flags.channels ? flags.channels : {}
        const flags_channel_entries = Object.entries(flags_channels)
        const classification_channels =
          classification && classification.channels ? classification.channels : {}

        const classification_channel_entries = Object.entries(classification_channels)
        const flags_channels_items = flags_channel_entries.map((channel, index) => {
          const flags = channel[1].map((flag, index) => {
            return (
              <table
                style={{ marginLeft: 20, marginRight: 20, marginBottom: 10 }}
                key={generateUUID()}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'top', width: 35 }}>
                      {this.getClassificationIcon(flag.level)}
                    </td>
                    <td style={{ lineHeight: 1.2 }}>
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {flag.title}
                              <span style={{ fontSize: 13 }}>
                                {flag.classification ? ' (' + flag.classification + ')' : ''}
                              </span>
                              :
                            </td>
                          </tr>
                          <tr style={{ fontSize: 12 }}>
                            <td>
                              <i>{flag.reason}</i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            )
          })
          return (
            <React.Fragment key={generateUUID()}>
              <div style={{ marginBottom: 20, marginTop: 20, marginLeft: 10 }}>
                <div style={{ fontSize: 16, marginBottom: 10, marginTop: 10 }}>
                  {t('view.common.alphacod_flag_icon.channel')} {channel[0]}:{' '}
                  {classification_channel_entries.length > 0 &&
                  classification_channel_entries[index]
                    ? classification_channel_entries[index][1]
                    : ''}{' '}
                  {channel[0] === mainChannel.toString() && (
                    <StarIcon style={{ fontSize: 20, color: 'gold' }} />
                  )}
                </div>

                <Grid container direction="row">
                  {flags}
                </Grid>
              </div>
            </React.Fragment>
          )
        })
        return (
          <div className={chipTooltip}>
            {flags_channels_items.length > 0 ? (
              <React.Fragment>{flags_channels_items}</React.Fragment>
            ) : (
              ''
            )}
          </div>
        )
      }

      render() {
        const { classes, item } = this.props
        if (!item) {
          return <Loading mode={'miniCircle'} />
        }

        const renderIcon = () => {
          let _flagIcon = this.getFlagIcon(item)
          switch (_flagIcon) {
            case 'info':
              return <InfoIcon className={classes.info} />
            case 'warning':
              return <WarningIcon className={classes.warning} />
            case 'error':
              return <ErrorIcon className={classes.error} />
            default:
              return <Fragment />
          }
        }

        return (
          <Box className={classes.mainContainer}>
            {this.getGlobalInfo(
              item.mainChannel,
              item.flags,
              item.classification,
              classes.chipTooltip,
              renderIcon(),
            )}
            {(item.flags && item.flags.globals ? item.flags.globals : []).length > 0 ? (
              <hr className={classes.divider} />
            ) : (
              ''
            )}
            {/*<hr className={classes.divider} />*/}
            {this.getChannelsInfo(
              item.mainChannel,
              item.flags,
              item.classification,
              classes.chipTooltip,
            )}
          </Box>
        )
      }
    },
  ),
)
