// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import {
  fetchUnitaryAnalysesDistinct,
  fetchUnitaryAnalysesFiltered,
} from '../../redux/unitaryAnalyses/actions'
import { withRouter } from 'react-router-dom'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import Title from '../Title'
import { getAnalysisById } from '../../redux/analyses/selectors'
import Loading from '../Loading'
import ProgressCmp from '../common/ProgressCmp'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import Tooltip from '@material-ui/core/Tooltip'
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly'
import PetsIcon from '@material-ui/icons/Pets'
import EcoIcon from '@material-ui/icons/Eco'
import clsx from 'clsx'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import ArchiveIcon from '@material-ui/icons/Archive'
import {
  computeAIAnalyse,
  getAnalyseDemandeesTooltip,
  handleValidation,
  openInNewTab,
  saveAnalyseValue,
} from '../../utils/utilities'
import {
  Molecule,
  Nothing,
  Relance,
  BootstrapTooltip,
  XLSFileInvert,
  RemoveFilterIcon,
  ExcelIcon,
} from '../common/Style'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import Badge from '@material-ui/core/Badge'
import ErrorIcon from '@material-ui/icons/Error'
import HelpIcon from '@material-ui/icons/Help'
import CancelIcon from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import { fetchUnitaryCalibrationsDistinct } from '../../redux/unitaryCalibrations/actions'
import Chip from '@material-ui/core/Chip/Chip'
import { fetchAddTask, fetchFinishTask } from '../../redux/tasks/actions'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import Link from '@material-ui/core/Link/Link'
import { FLASK_URL, USE_OLD_TABLE } from '../../utils/config'
import GetAppIcon from '@material-ui/icons/GetApp'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import CircularProgressCmp from '../common/CircularProgressCmp'
import Typography from '@material-ui/core/Typography'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { palette } from '@material-ui/system'
import NoteButton from '../common/NoteButton'
import { displayPopupMessage } from '../common/Message'
import NotesIcon from '@material-ui/icons/Notes'
import FilterButton from './FilterButton'
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter'
import LimsAutoValidationConfirm from '../common/LimsAutoValidationConfirm'
import { fetchAutoValidationLims } from '../../redux/lims/actions'
import SettingsView from '../common/settingsView/SettingsView'
import FunctionsIcon from '@material-ui/icons/Functions'
import FormulasDialog from '../dialog/FormulasDialog'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import PsychologyIcon from '@mui/icons-material/Psychology'
import { t } from 'i18next'
import * as I18n from 'i18next'
import MenuItem from '@mui/material/MenuItem'
import { handleDownloadReport } from '../backlog/Tools'
import Menu from '@mui/material/Menu'

/**
 * Define custom styles for the Backlog component.
 * @param theme Material-UI theme object.
 */
const styles = (theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '0px 0px 4px 4px',
    padding: theme.spacing(2),
    paddingTop: 10,
    paddingBottom: 3,
    // height: '100%',
    // justifyContent: 'flex-start',
    // flex: '1 1 auto',
    // position: 'relative',
    overflow: 'hidden',
    '&:hover .collapsor': {
      opacity: 1,
      bottom: 2,
    },
  },
  buttonCalib: {
    borderRadius: '18px !important',
    backgroundColor: '#e0e0e0',
    padding: '3px 12px 3px 14px',
    marginRight: 20,
    height: 32,
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
    '&.Mui-selected .MuiBadge-badge': {
      color: alpha(theme.palette.secondary.main, 0.8),
    },
  },
  spacer: {
    // flexGrow: 1,
    align: 'right',
  },
  progress: {
    paddingTop: '0px !important',
    paddingBottom: 10,
    // display:'flex'
  },
  iconValid: {
    fontSize: 13,
  },
  filterIcon: {
    color: theme.palette.primary.main,
  },

  progression: {
    padding: 12,
    paddingTop: 0,
    '& .MuiGrid-item': {
      fontSize: '1rem',
    },
  },
  pesee: {
    width: 80,
    '& input': {
      textAlign: 'end',
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },
  analyseInfoText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  colorGreen: {
    color: 'green',
  },
  colorBrown: {
    color: 'brown',
  },
  colorBlue: {
    color: theme.palette.primary.main,
  },
  colorPurple: {
    color: 'purple',
  },
  iconDisabled: {
    color: 'grey',
    opacity: 0.2,
  },
  iconBio: {
    fontSize: 30,
    position: 'relative',
    top: 3,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },

  search: {
    position: 'relative',
    marginRight: '12px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.06),
    },
    '& div:first-child': {
      color: alpha(theme.palette.common.black, 0.54),
    },
    '&:hover div:first-child': {
      color: theme.palette.secondary.main,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
      marginTop: 3,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    // pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    // color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '16ch',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
      },
    },
  },
  clearIcon: {
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  filterButton: {
    borderRadius: 18,
    border: '1px solid #c5c5c5',
    backgroundColor: 'rgb(224 224 224)',
    marginRight: 5,
    marginLeft: 15,
    width: 50,
    height: 34,
    padding: 8,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  notSelected: {
    '&:hover': {
      // color: theme.palette.primary.main,
    },
  },
  toggleButtonGroup: {
    '& .MuiToggleButtonGroup-grouped': {
      padding: 0,
    },
  },
  badgeCount: {
    '& .MuiBadge-badge': {
      right: '-12px',
      top: '-5px',
      color: alpha(theme.palette.primary.main, 0.6),
      background: 'white',
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountRect: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: alpha(theme.palette.primary.main, 0.6),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountSelected: {
    '& .MuiBadge-badge': {
      right: '-12px',
      top: '-5px',
      background: 'white',
      color: alpha(theme.palette.secondary.main, 0.8),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountRectSelected: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: alpha(theme.palette.secondary.main, 0.8),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  chip: {
    marginRight: 5,
    marginBottom: 5,
    borderColor: 'inherit',
    '& > svg': {
      color: 'inherit',
      marginLeft: '8px !important',
    },
  },
  chipTooltip: {
    fontSize: 14,
  },
  flagInfo: {
    color: theme.palette.common.infoBlue,
    backgroundColor: alpha(theme.palette.common.infoBlue, 0.05),
  },
  flagWarning: {
    color: theme.palette.common.warning,
    backgroundColor: alpha(theme.palette.common.warning, 0.05),
  },
  flagError: {
    color: theme.palette.common.error,
    backgroundColor: alpha(theme.palette.common.error, 0.05),
  },
  collapsor: {
    '&.collapsed': { opacity: 1, bottom: 2 },
    '&.notCollapsed': { opacity: 0, bottom: 12 },
    transition: 'all 125ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    background: 'white',
    width: 50,
    height: 10,
    left: 'calc(50% - 25px)',
    position: 'absolute',
    borderRadius: '0px 0px 15px 15px',
    // boxShadow:
    //   '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  collapseButton: {
    width: 30,
    height: 30,
    padding: 0,
    bottom: -4,
    left: 'calc(50% - 15px)',
    position: 'absolute',
    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      background: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  removeFilter: {
    color: theme.palette.primary.main,
  },
  reportButton: {
    padding: 0,
    top: -7,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
})

/**
 * Maps the state to the component's props.
 * @param {Object} state - The current state.
 * @param {Object} ownProps - The component's own props.
 * @returns {Object} - The mapped props.
 */
const mapStateToProps = (state, ownProps) => {
  const { analyses, unitaryAnalyses, configuration } = state
  const analyse = getAnalysisById(analyses, ownProps.match.params.analysisId)
  return {
    analyse,
    isFetchingUnitaryAnalysesDistinct: unitaryAnalyses.isFetchingUnitaryAnalysesDistinct,
    unitaryAnalysesDistinct: unitaryAnalyses.unitaryAnalysesDistinct,
    configFeatures: configuration.configFeatures,
    isFetchingConfigFeatures: configuration.isFetchingConfigFeatures,
  }
}

const tableOldVersion = USE_OLD_TABLE()

/**
 * Connects the component to the Redux store and provides routing capabilities.
 */
export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      /**
       * Information component that displays analysis information.
       * This component is connected to the Redux store and provides routing capabilities.
       */
      class Information extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            countLoaded: false,
            filterCount: null,
            error: null,
            isLoaded: false,
            analyse: null,
            items: [],
            analysis: null,
            timer: null,
            batch: null,
            order: 'asc',
            orderBy: '#',
            checked: localStorage.getItem('ANALYSIS_filter_requested')
              ? JSON.parse(localStorage.getItem('ANALYSIS_filter_requested')) === true
              : true,
            untreatedChecked: localStorage.getItem('ANALYSIS_filter_untreated')
              ? JSON.parse(localStorage.getItem('ANALYSIS_filter_untreated')) === true
              : false,
            filterSelected: localStorage.getItem('ANALYSIS_filter_classification')
              ? parseInt(localStorage.getItem('ANALYSIS_filter_classification'))
              : 1,
            filterSelected2: localStorage.getItem('ANALYSIS_filter_classification2')
              ? parseInt(localStorage.getItem('ANALYSIS_filter_classification2'))
              : 4,
            validationDetected: localStorage.getItem('ANALYSIS_filter_detected')
              ? JSON.parse(localStorage.getItem('ANALYSIS_filter_detected')) === true
              : false,
            validationNotDetected: localStorage.getItem('ANALYSIS_filter_notDetected')
              ? JSON.parse(localStorage.getItem('ANALYSIS_filter_notDetected')) === true
              : false,
            validationRelaunch: localStorage.getItem('ANALYSIS_filter_relaunch')
              ? JSON.parse(localStorage.getItem('ANALYSIS_filter_relaunch')) === true
              : false,
            displayClear: false,
            search: null,
            collapsed: localStorage.getItem('ANALYSIS_information_collapsed')
              ? JSON.parse(localStorage.getItem('ANALYSIS_information_collapsed')) === true
              : false,
            openAutoValidation: false,
            openFormulasDialog: false,
            extractMenuAnchor: null,
          }
          this.onSearch = this.onSearch.bind(this)
          this.clearSearch = this.clearSearch.bind(this)
          this.collapse = this.collapse.bind(this)
          this.searchRef = React.createRef()
          this.tableMultiGridRef = props.tableMultiGridRef
          this.handleCloseAutoValidation = this.handleCloseAutoValidation.bind(this)
          this.onSaveAnalyseValue = this.onSaveAnalyseValue.bind(this)
        }

        /**
         * Lifecycle method that is called after the component is mounted.
         * Dispatches an action to fetch unitary analyses distinct data.
         */
        componentDidMount() {
          const { dispatch } = this.props
          const { analysisId } = this.props.match.params
          dispatch(
            fetchUnitaryAnalysesDistinct(
              analysisId,
              JSON.parse(localStorage.getItem('ANALYSIS_filter_requested')) === true,
            ),
          )
        }

        /**
         * Lifecycle method that is called after the component updates.
         * Dispatches an action to fetch unitary analyses distinct data if the analysis ID changes.
         *
         * @param {Object} prevProps - The previous props.
         * @param {Object} prevState - The previous state.
         */
        componentDidUpdate(prevProps, prevState) {
          const { dispatch } = this.props
          const { analysisId } = this.props.match.params
          if (analysisId !== prevProps.match.params.analysisId) {
            dispatch(
              fetchUnitaryAnalysesDistinct(
                analysisId,
                JSON.parse(localStorage.getItem('ANALYSIS_filter_requested')) === true,
              ),
            )
            this.searchRef.current.children[0].value = ''
            this.setState({ search: '', displayClear: false })
          }
        }

        /**
         * Updates the analysis value based on the input event.
         *
         * @param {Object} e - The input event.
         * @param {Object} analyse - The analysis object.
         * @param {string} paramValue - The parameter value to update.
         */
        onUpdateAnalyseValue(e, analyse, paramValue) {
          const { timer } = this.state
          const newValue = e.target.value
          clearTimeout(timer)
          const newTimer = setTimeout(
            // function () {
            this.onSaveAnalyseValue(newValue, analyse, paramValue),
            // }.bind(this),
            1500,
          )
          this.setState({ timer: newTimer })
        }

        /**
         * Saves the updated analysis value.
         *
         * @param {string} newValue - The new value to save.
         * @param {Object} analyse - The analysis object.
         * @param {string} paramValue - The parameter value to update.
         * @param {string} [formula] - Optional formula parameter.
         */
        onSaveAnalyseValue(newValue, analyse, paramValue, formula) {
          const { dispatch, batch } = this.props
          saveAnalyseValue(dispatch, batch, newValue, analyse, paramValue, formula)
        }

        /**
         * Handles the search input change event.
         * Clears the current selection and dispatches an action to fetch filtered unitary analyses.
         *
         * @param {Object} e - The input change event.
         */
        onSearch(e) {
          this.tableMultiGridRef.current.clearSelection()
          const {
            timer,
            filterSelected,
            filterSelected2,
            checked,
            untreatedChecked,
            validationDetected,
            validationNotDetected,
            validationRelaunch,
          } = this.state
          const { dispatch, isKOOK } = this.props
          const newValue = e.target.value
          clearTimeout(timer)
          const newTimer = setTimeout(
            function () {
              this.setState({ search: newValue })
              dispatch(
                fetchUnitaryAnalysesFiltered(
                  this.props.match.params.analysisId,
                  !isKOOK ? filterSelected : filterSelected2,
                  checked,
                  untreatedChecked,
                  validationDetected,
                  validationNotDetected,
                  validationRelaunch,
                  localStorage.getItem('ANALYSIS_sorter'),
                  newValue,
                ),
              )
            }.bind(this),
            1500,
          )
          this.setState({ timer: newTimer, displayClear: newValue !== '' })
        }

        /**
         * Clears the search input and resets the search state.
         * Dispatches an action to fetch filtered unitary analyses with no search term.
         */
        clearSearch() {
          const {
            filterSelected,
            filterSelected2,
            checked,
            untreatedChecked,
            validationDetected,
            validationNotDetected,
            validationRelaunch,
          } = this.state
          const { dispatch, isKOOK } = this.props
          this.searchRef.current.children[0].value = ''
          this.setState({ search: '', displayClear: false })
          dispatch(
            fetchUnitaryAnalysesFiltered(
              this.props.match.params.analysisId,
              !isKOOK ? filterSelected : filterSelected2,
              checked,
              untreatedChecked,
              validationDetected,
              validationNotDetected,
              validationRelaunch,
              localStorage.getItem('ANALYSIS_sorter'),
              null,
            ),
          )
        }

        /**
         * Toggles the collapsed state of the component and updates local storage.
         */
        collapse() {
          const { collapsed } = this.state
          this.setState({ collapsed: !collapsed })
          localStorage.setItem('ANALYSIS_information_collapsed', !collapsed)
        }

        /**
         * Handles the closing of the auto-validation dialog.
         * @param {Object} event - The event object.
         */
        handleCloseAutoValidation(event) {
          // const { dispatch, batch } = this.props
          this.setState({ openAutoValidation: false })
        }

        /**
         * Opens the extract menu
         * @param event
         */
        openExtractMenu = (event) => {
          this.setState({ extractMenuAnchor: event.currentTarget })
        }

        /**
         * Closes the extract menu
         */
        handleCloseExtraMenu = () => {
          this.setState({ extractMenuAnchor: null })
        }

        /**
         * Renders the Information component.
         * @returns {JSX.Element} The rendered component.
         */
        render() {
          const {
            dispatch,
            classes,
            analyse,
            batch,
            isKOOK,
            isFetchingUnitaryAnalysesDistinct,
            unitaryAnalysesDistinct,
            idTable,
            propsColumns,
            parentMultiGridRef,
            configFeatures,
            isFetchingConfigFeatures,
          } = this.props
          const analyseID = this.props.match.params.analysisId
          const {
            checked,
            untreatedChecked,
            validationDetected,
            validationNotDetected,
            validationRelaunch,
            filterSelected,
            filterSelected2,
            countLoaded,
            filterCount,
            search,
            collapsed,
            openAutoValidation,
            openFormulasDialog,
            extractMenuAnchor,
          } = this.state
          // function applyFilter(filter) {
          //   console.log('Filter:' + filter)
          //   this.setState({ filterSelected: filter })
          //   dispatch(fetchUnitaryAnalysesFiltered(analyseID, filter))
          // }

          const extraMenuOpen = Boolean(extractMenuAnchor)

          if (analyse) {
            document.title =
              analyse.name + ' - ' + localStorage.getItem('__config.json__APPLICATION_TITLE')
          } else {
            document.title = localStorage.getItem('__config.json__APPLICATION_TITLE')
          }

          /**
           * Applies the selected filter to the unitary analyses.
           * Clears the current selection and updates the filter state.
           *
           * @param {Object} event - The event object.
           * @param {number} newFilter - The new filter value to apply.
           */
          const applyFilter = (event, newFilter) => {
            this.tableMultiGridRef.current.clearSelection()
            if (!isKOOK) {
              localStorage.setItem('ANALYSIS_filter_classification', newFilter)
              this.setState({ filterSelected: newFilter })
            } else {
              localStorage.setItem('ANALYSIS_filter_classification2', newFilter)
              this.setState({ filterSelected2: newFilter })
            }

            if (newFilter !== null) {
              this.setState({
                validationDetected: false,
                validationNotDetected: false,
                validationRelaunch: false,
              })
              localStorage.setItem('ANALYSIS_filter_detected', 'false')
              localStorage.setItem('ANALYSIS_filter_notDetected', 'false')
              localStorage.setItem('ANALYSIS_filter_relaunch', 'false')
            }

            dispatch(
              fetchUnitaryAnalysesFiltered(
                analyseID,
                newFilter,
                this.state.checked,
                this.state.untreatedChecked,
                newFilter !== null ? false : this.state.validationDetected,
                newFilter !== null ? false : this.state.validationNotDetected,
                newFilter !== null ? false : this.state.validationRelaunch,
                localStorage.getItem('ANALYSIS_sorter'),
                this.state.search,
              ),
            )
          }

          /**
           * Handles the change event for the requested filter.
           * Clears the current selection and dispatches an action to fetch filtered unitary analyses.
           */
          const handleChange = () => {
            this.tableMultiGridRef.current.clearSelection()
            const filter = !isKOOK ? this.state.filterSelected : this.state.filterSelected2
            dispatch(
              fetchUnitaryAnalysesFiltered(
                analyseID,
                filter,
                !this.state.checked,
                this.state.untreatedChecked,
                this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                localStorage.getItem('ANALYSIS_sorter'),
                this.state.search,
              ),
            )
            localStorage.setItem('ANALYSIS_filter_requested', (!this.state.checked).toString())
            this.setState((prevState) => ({ checked: !prevState.checked }))
            dispatch(fetchUnitaryAnalysesDistinct(analyseID, !this.state.checked))
          }

          /**
           * Handles the change event for the untreated filter.
           * Clears the current selection and dispatches an action to fetch filtered unitary analyses.
           */
          const handleUntreatedChange = () => {
            this.tableMultiGridRef.current.clearSelection()
            const filter = !isKOOK ? this.state.filterSelected : this.state.filterSelected2
            const pressed = !this.state.untreatedChecked
            if (pressed) {
              this.setState({
                validationDetected: false,
                validationNotDetected: false,
                validationRelaunch: false,
              })
              localStorage.setItem('ANALYSIS_filter_detected', 'false')
              localStorage.setItem('ANALYSIS_filter_notDetected', 'false')
              localStorage.setItem('ANALYSIS_filter_relaunch', 'false')
            }
            dispatch(
              fetchUnitaryAnalysesFiltered(
                analyseID,
                filter,
                this.state.checked,
                !this.state.untreatedChecked,
                pressed ? false : this.state.validationDetected,
                pressed ? false : this.state.validationNotDetected,
                pressed ? false : this.state.validationRelaunch,
                localStorage.getItem('ANALYSIS_sorter'),
                this.state.search,
              ),
            )
            localStorage.setItem(
              'ANALYSIS_filter_untreated',
              (!this.state.untreatedChecked).toString(),
            )
            this.setState((prevState) => ({ untreatedChecked: !prevState.untreatedChecked }))
            // this.count(this.state.checked, !this.state.untreatedChecked)
          }

          /**
           * Handles the change event for the validation detected filter.
           * Clears the current selection and dispatches an action to fetch filtered unitary analyses.
           */
          const handleChangeValidationDetected = () => {
            this.tableMultiGridRef.current.clearSelection()
            const filter = !isKOOK ? this.state.filterSelected : this.state.filterSelected2
            const pressed = !this.state.validationDetected
            if (pressed) {
              this.setState({
                untreatedChecked: false,
                filterSelected: null,
                filterSelected2: null,
              })
              localStorage.setItem('ANALYSIS_filter_untreated', 'false')
              localStorage.setItem('ANALYSIS_filter_classification', null)
              localStorage.setItem('ANALYSIS_filter_classification2', null)
            }

            dispatch(
              fetchUnitaryAnalysesFiltered(
                analyseID,
                pressed ? null : filter,
                this.state.checked,
                pressed ? false : this.state.untreatedChecked,
                !this.state.validationDetected,
                this.state.validationNotDetected,
                this.state.validationRelaunch,
                localStorage.getItem('ANALYSIS_sorter'),
                this.state.search,
              ),
            )
            localStorage.setItem(
              'ANALYSIS_filter_detected',
              (!this.state.validationDetected).toString(),
            )
            this.setState((prevState) => ({ validationDetected: !prevState.validationDetected }))
          }

          /**
           * Handles the change event for the validation not detected filter.
           * Clears the current selection and dispatches an action to fetch filtered unitary analyses.
           */
          const handleChangeValidationNotDetected = () => {
            this.tableMultiGridRef.current.clearSelection()

            this.setState((prevState) => {
              const filter = !isKOOK ? prevState.filterSelected : prevState.filterSelected2
              const pressed = !prevState.validationDetected

              if (pressed) {
                // Reset filters when validationDetected is toggled off
                localStorage.setItem('ANALYSIS_filter_untreated', 'false')
                localStorage.setItem('ANALYSIS_filter_classification', null)
                localStorage.setItem('ANALYSIS_filter_classification2', null)

                return {
                  untreatedChecked: false,
                  filterSelected: null,
                  filterSelected2: null,
                }
              }

              // Dispatch action with updated state
              dispatch(
                fetchUnitaryAnalysesFiltered(
                  analyseID,
                  pressed ? null : filter,
                  prevState.checked,
                  pressed ? false : prevState.untreatedChecked,
                  prevState.validationDetected,
                  !prevState.validationNotDetected,
                  prevState.validationRelaunch,
                  localStorage.getItem('ANALYSIS_sorter'),
                  prevState.search,
                ),
              )

              // Update localStorage with the new filter status
              localStorage.setItem(
                'ANALYSIS_filter_notDetected',
                (!prevState.validationNotDetected).toString(),
              )

              // Return updated state for `validationNotDetected`
              return { validationNotDetected: !prevState.validationNotDetected }
            })
          }

          /**
           * Handles the change event for the validation relaunch filter.
           * Clears the current selection and dispatches an action to fetch filtered unitary analyses.
           */
          const handleChangeValidationRelaunch = () => {
            this.tableMultiGridRef.current.clearSelection()
            const filter = !isKOOK ? this.state.filterSelected : this.state.filterSelected2
            const pressed = !this.state.validationDetected
            if (pressed) {
              this.setState({
                untreatedChecked: false,
                filterSelected: null,
                filterSelected2: null,
              })
              localStorage.setItem('ANALYSIS_filter_untreated', 'false')
              localStorage.setItem('ANALYSIS_filter_classification', null)
              localStorage.setItem('ANALYSIS_filter_classification2', null)
            }
            dispatch(
              fetchUnitaryAnalysesFiltered(
                analyseID,
                pressed ? null : filter,
                this.state.checked,
                pressed ? false : this.state.untreatedChecked,
                this.state.validationDetected,
                this.state.validationNotDetected,
                !this.state.validationRelaunch,
                localStorage.getItem('ANALYSIS_sorter'),
                this.state.search,
              ),
            )
            localStorage.setItem(
              'ANALYSIS_filter_relaunch',
              (!this.state.validationRelaunch).toString(),
            )
            this.setState((prevState) => ({ validationRelaunch: !prevState.validationRelaunch }))
          }

          /**
           * Custom styled ToggleButton component.
           * @param {Object} theme - The Material-UI theme object.
           * @returns {Object} - The styles for the ToggleButton component.
           */
          const StyledToggleButton = withStyles((theme) => ({
            selected: {
              backgroundColor: theme.palette.secondary.light + ' !important',
              color: 'white !important',
            },
          }))(ToggleButton)

          if (!analyse || isFetchingConfigFeatures) {
            return <Loading mode={'miniCircle'} />
          }

          /**
           * Opens the formulas dialog.
           */
          const openFormulasDialogClicked = () => {
            this.setState({ openFormulasDialog: true })
          }
          /**
           * Closes the formulas dialog.
           */
          const closeFormulasDialogClicked = () => {
            this.setState({ openFormulasDialog: false })
          }
          /**
           * Handles the AI Assistant functionality.
           * Displays a popup message if AI mode is disabled.
           * Otherwise, calls the service to launch the AI.
           */
          const _handleAiAssistant = () => {
            if (!configFeatures || (configFeatures && configFeatures.ai_mode === 'off')) {
              displayPopupMessage(
                dispatch,
                'info',
                t('view.dialogs.infosPanel.aiMode'),
                t('view.dialogs.infosPanel.aiModeDisabled'),
              )
            } else {
              //TODO call the service to launch the AI
              displayPopupMessage(
                dispatch,
                'info',
                t('view.dialogs.infosPanel.aiMode'),
                t('view.analysis.information.not_available'),
              )
            }
          }

          /**
           * Renders the Information component.
           * @returns {JSX.Element} The rendered component.
           */
          return (
            <Fragment>
              <Menu
                anchorEl={extractMenuAnchor}
                open={extraMenuOpen}
                onClose={this.handleCloseExtraMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={(event) => {
                    this.handleCloseExtraMenu()
                    handleDownloadReport(event, batch, false, dispatch)
                  }}
                >
                  {t('view.batch.information.normal')}
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    this.handleCloseExtraMenu()
                    handleDownloadReport(event, batch, true, dispatch)
                  }}
                >
                  {t('view.batch.information.detail')}
                </MenuItem>
              </Menu>
              {analyse.content.formula_infos && (
                <ErrorBoundaryGuard isDialog>
                  <FormulasDialog
                    analyse={analyse}
                    batch={batch}
                    open={openFormulasDialog}
                    closeFct={closeFormulasDialogClicked}
                    onSaveAnalyseValue={this.onSaveAnalyseValue}
                  />
                </ErrorBoundaryGuard>
              )}
              <ErrorBoundaryGuard isDialog>
                <LimsAutoValidationConfirm
                  open={openAutoValidation}
                  closeFct={this.handleCloseAutoValidation}
                />
              </ErrorBoundaryGuard>
              <Paper
                elevation={0}
                className={classes.paper}
                style={{
                  height: collapsed ? '10px' : '100%',
                  paddingTop: collapsed ? '0px' : '10px',
                }}
              >
                <div style={{ opacity: collapsed ? 0 : 1 }}>
                  <Title>
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item style={{ marginRight: 15, paddingBottom: 4 }}>
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>
                              {t('view.analysis.information.batch_name')}
                            </div>
                          }
                        >
                          <Box style={{ display: 'inline' }}>{batch.name}</Box>
                        </Tooltip>
                      </Grid>
                      {/*<Grid item>*/}
                      {/*<Tooltip arrow title={<div className={classes.tooltip}>Analysis name</div>}>*/}
                      {/*<Box style={{ display: 'inline' }}>{analyse.name}</Box>*/}
                      {/*</Tooltip>*/}
                      {/*</Grid>*/}
                      {analyse.content.sample_infos &&
                        analyse.content.sample_infos.matrice &&
                        analyse.content.report_infos &&
                        analyse.content.report_infos.num && (
                          <Grid
                            item
                            className={classes.analyseInfoText}
                            style={{ margin: '0px 15px' }}
                          >
                            <Tooltip
                              arrow
                              title={
                                <div className={classes.tooltip}>
                                  {t('view.analysis.information.report_num')}
                                </div>
                              }
                            >
                              <Box style={{ display: 'inline' }}>
                                {analyse.content.report_infos.num}
                              </Box>
                            </Tooltip>
                            :
                            <Tooltip
                              arrow
                              title={
                                <div className={classes.tooltip}>
                                  {t('view.analysis.information.matrix')}
                                </div>
                              }
                            >
                              <Box style={{ display: 'inline' }}>
                                {analyse.content.sample_infos.matrice}
                              </Box>
                            </Tooltip>
                          </Grid>
                        )}
                      {analyse.content.moc && (
                        <Grid item style={{ margin: '0px 15px' }}>
                          <Tooltip
                            arrow
                            title={
                              <div className={classes.tooltip}>
                                {t('view.analysis.information.moc')}
                              </div>
                            }
                          >
                            <Box style={{ display: 'inline' }}>{analyse.content.moc}</Box>
                          </Tooltip>
                        </Grid>
                      )}
                      {analyse.content.formula_infos &&
                        configFeatures &&
                        typeof configFeatures.info_sample_formula === 'boolean' &&
                        configFeatures.info_sample_formula && (
                          <Grid item style={{ margin: '0px 15px' }}>
                            <Tooltip
                              arrow
                              title={
                                <div className={classes.tooltip}>
                                  {t('view.analysis.information.open_edit')}
                                </div>
                              }
                            >
                              <IconButton
                                style={{
                                  padding: 0,
                                  top: 3,
                                  verticalAlign: 'top',
                                }}
                                className={classes.colorBlue}
                                aria-label="openFormulas"
                                onClick={openFormulasDialogClicked}
                              >
                                <FunctionsIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      {analyse.content.formula_infos && (
                        // <Grid item style={{ paddingRight: 0, fontSize: 14 }}>
                        <Grid item>
                          <Tooltip
                            arrow
                            title={
                              <div className={classes.tooltip}>
                                {t('view.analysis.information.dilution_val')}
                              </div>
                            }
                          >
                            <Box style={{ display: 'inline' }}>
                              {t('view.analysis.information.dilution') + ' '}
                              {analyse.content.formula_infos &&
                                analyse.content.formula_infos.facteur_dilution}
                            </Box>
                          </Tooltip>
                        </Grid>
                      )}
                      {analyse.content.sample_infos && (
                        <Grid>
                          <Tooltip
                            arrow
                            title={
                              <div className={classes.tooltip}>
                                {t('view.analysis.information.weighing_val')}
                              </div>
                            }
                          >
                            <Box style={{ display: 'inline' }}>
                              {t('view.analysis.information.weighing') +
                                ' ' +
                                (analyse.content.pesee ? analyse.content.pesee + 'g' : '--')}
                            </Box>
                          </Tooltip>
                          {/*v_ext:*/}
                          {/*<Input*/}
                          {/*  type="number"*/}
                          {/*  style={{ marginRight: 40 }}*/}
                          {/*  className={classes.pesee}*/}
                          {/*  defaultValue={analyse.content.sample_infos ? analyse.content.v_ext : ''}*/}
                          {/*  inputProps={{ min: 0 }}*/}
                          {/*  onChange={(event) => this.onUpdateAnalyseValue(event, analyse, 'v_ext')}*/}
                          {/*/>*/}
                          {/*Dilution:*/}
                          {/*<Input*/}
                          {/*  type="number"*/}
                          {/*  className={classes.pesee}*/}
                          {/*  style={{ marginRight: 40 }}*/}
                          {/*  value={analyse.content.sample_infos ? analyse.content.dilution : ''}*/}
                          {/*  inputProps={{ min: 0 }}*/}
                          {/*  onChange={(event) =>*/}
                          {/*    this.onUpdateAnalyseValue(event, analyse, 'dilution')*/}
                          {/*  }*/}
                          {/*/>*/}
                          {/*Weighing:*/}
                          {/*<Input*/}
                          {/*  type="number"*/}
                          {/*  className={classes.pesee}*/}
                          {/*  value={analyse.content.sample_infos ? analyse.content.pesee : ''}*/}
                          {/*  inputProps={{ min: 0 }}*/}
                          {/*  endAdornment={<InputAdornment position="end">g</InputAdornment>}*/}
                          {/*  onChange={(event) =>*/}
                          {/*    this.onUpdateAnalyseValue(event, analyse, 'weighing')*/}
                          {/*  }*/}
                          {/*  // onKeyDown={(event) => this.onKeyDown(event)}*/}
                          {/*/>*/}
                        </Grid>
                      )}
                      <Grid item style={{ paddingBottom: 4 }}>
                        <Fragment>
                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {!configFeatures ||
                                (configFeatures && configFeatures.ai_mode === 'off')
                                  ? t('view.dialogs.infosPanel.aiModeDisabled')
                                  : t('view.dialogs.infosPanel.aiModeEnable')}
                              </div>
                            }
                          >
                            <IconButton
                              aria-label="aiAssistant"
                              style={{
                                padding: 0,
                                marginLeft: 10,
                                marginRight: 10,
                                position: 'relative',
                                top: -8,
                                border: '1px dashed',
                              }}
                              // onClick={_handleAiAssistant}
                              onClick={() => {
                                computeAIAnalyse(dispatch, analyse, batch)
                              }}
                            >
                              <PsychologyIcon
                                style={{ fontSize: 29 }}
                                color={
                                  !configFeatures ||
                                  (configFeatures && configFeatures.ai_mode === 'off')
                                    ? 'disabled'
                                    : 'primary'
                                }
                              />
                            </IconButton>
                          </Tooltip>
                          <div
                            style={{
                              borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                              marginBottom: 2,
                              height: 20,
                              display: 'inline-block',
                              position: 'relative',
                            }}
                          />
                        </Fragment>
                        <ErrorBoundaryGuard isDialog>
                          <NoteButton
                            title
                            row={{
                              _id: analyse._id,
                              notes: analyse.content.notes ? analyse.content.notes : [],
                            }}
                            collection={'analysis'}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              position: 'relative',
                              top: -8,
                            }}
                          />
                        </ErrorBoundaryGuard>
                        <div
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            marginLeft: 7,
                            marginRight: 17,
                            marginBottom: 2,
                            height: 20,
                            display: 'inline-block',
                            position: 'relative',
                          }}
                        />
                        {configFeatures &&
                          typeof configFeatures.info_webLims === 'boolean' &&
                          configFeatures.info_webLims &&
                          analyse.content.type === 'sample' && (
                            <Tooltip
                              arrow
                              title={
                                <div
                                  style={{
                                    fontSize: 13,
                                    padding: 5,
                                  }}
                                >
                                  {t('view.analysis.information.open_weblims')}
                                </div>
                              }
                            >
                              <OpenInBrowserIcon
                                style={{ marginRight: 8 }}
                                className={
                                  analyse.content.type && analyse.content.type === 'sample'
                                    ? classes.colorBlue
                                    : classes.iconDisabled
                                }
                                onClick={() => {
                                  openInNewTab(
                                    'http://lims.phytocontrol.local/myweblims/index.php?r=t_pes_calcul_multiresidus%2FchoixEch&service=GC&searchech=' +
                                      analyse.name,
                                  )
                                }}
                              />
                            </Tooltip>
                          )}
                        {configFeatures &&
                          typeof configFeatures.info_audit_trail === 'boolean' &&
                          configFeatures.info_audit_trail && (
                            <Tooltip
                              arrow
                              title={
                                <div
                                  style={{
                                    fontSize: 13,
                                    padding: 5,
                                  }}
                                >
                                  {t('view.analysis.information.get_audit_trail')}
                                </div>
                              }
                            >
                              <Link
                                href={`${FLASK_URL()}/analysis/${analyse._id}/audit_trail`}
                                color="inherit"
                                onClick={() => {
                                  displayPopupMessage(
                                    dispatch,
                                    'info',
                                    t('view.analysis.information.export_audit'),
                                    <Fragment>
                                      <div>
                                        {t('view.analysis.information.sample_selected')}{' '}
                                        {analyse.name}
                                      </div>
                                    </Fragment>,
                                  )
                                }}
                              >
                                <GetAppIcon
                                  style={{ marginRight: 8 }}
                                  className={classes.colorBlue}
                                />
                              </Link>
                            </Tooltip>
                          )}
                        <Tooltip
                          arrow
                          title={
                            <div
                              style={{
                                fontSize: 13,
                                padding: 5,
                              }}
                            >
                              {t('view.analysis.information.report_data')}
                            </div>
                          }
                        >
                          <IconButton
                            aria-label="reportXLSX"
                            className={clsx(classes.reportButton)}
                            onClick={this.openExtractMenu}
                          >
                            <XLSFileInvert
                              style={{ marginRight: 8, width: 22, height: 22 }}
                              className={classes.colorBlue}
                            />
                          </IconButton>
                        </Tooltip>
                        <div
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            marginLeft: 7,
                            marginRight: 17,
                            marginBottom: 2,
                            height: 20,
                            display: 'inline-block',
                            position: 'relative',
                          }}
                        />
                        <Tooltip
                          arrow
                          title={
                            analyse.content.sample_infos &&
                            analyse.content.sample_infos.analyses_demandees
                              ? getAnalyseDemandeesTooltip(
                                  analyse.content.sample_infos.analyses_demandees,
                                )
                              : ''
                          }
                        >
                          <ArchiveIcon
                            style={{ marginRight: 8 }}
                            className={
                              analyse.content.sample_infos &&
                              analyse.content.sample_infos.analyses_demandees
                                ? classes.colorBlue
                                : classes.iconDisabled
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          arrow
                          title={
                            analyse.content.sample_infos &&
                            analyse.content.sample_infos.remarques &&
                            analyse.content.sample_infos.remarques.trim() !== '' ? (
                              <div className={classes.tooltip}>
                                {analyse.content.sample_infos.remarques}
                              </div>
                            ) : (
                              ''
                            )
                          }
                        >
                          <NotesIcon
                            style={{ marginRight: 17, marginTop: 6 }}
                            className={
                              analyse.content.sample_infos &&
                              analyse.content.sample_infos.remarques &&
                              analyse.content.sample_infos.remarques.trim() !== ''
                                ? classes.colorBlue
                                : classes.iconDisabled
                            }
                          />
                        </Tooltip>

                        {configFeatures &&
                          typeof configFeatures.info_sample_category === 'boolean' &&
                          configFeatures.info_sample_category && (
                            <Fragment>
                              <div
                                style={{
                                  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                                  marginRight: 17,
                                  marginBottom: 2,
                                  height: 20,
                                  display: 'inline-block',
                                  position: 'relative',
                                }}
                              />
                              <Tooltip
                                arrow
                                title={
                                  analyse.content.sample_infos &&
                                  analyse.content.sample_infos.feed ? (
                                    <div className={classes.tooltip}>
                                      {t('view.analysis.information.feed')}{' '}
                                    </div>
                                  ) : (
                                    ''
                                  )
                                }
                              >
                                <PetsIcon
                                  style={{ marginRight: 7 }}
                                  className={
                                    analyse.content.sample_infos &&
                                    analyse.content.sample_infos.feed
                                      ? classes.colorBrown
                                      : classes.iconDisabled
                                  }
                                />
                              </Tooltip>
                              <Tooltip
                                arrow
                                title={
                                  analyse.content.sample_infos &&
                                  analyse.content.sample_infos.bbf ? (
                                    <div className={classes.tooltip}>
                                      {t('view.analysis.information.baby')}
                                    </div>
                                  ) : (
                                    ''
                                  )
                                }
                              >
                                <ChildFriendlyIcon
                                  style={{ marginRight: 5 }}
                                  className={
                                    analyse.content.sample_infos && analyse.content.sample_infos.bbf
                                      ? classes.colorPurple
                                      : classes.iconDisabled
                                  }
                                />
                              </Tooltip>
                              <Tooltip
                                arrow
                                title={
                                  analyse.content.sample_infos &&
                                  analyse.content.sample_infos.bio ? (
                                    <div className={classes.tooltip}>
                                      {t('view.analysis.information.bio')}
                                    </div>
                                  ) : (
                                    ''
                                  )
                                }
                              >
                                <EcoIcon
                                  className={clsx(
                                    classes.iconBio,
                                    analyse.content.sample_infos && analyse.content.sample_infos.bio
                                      ? classes.colorGreen
                                      : classes.iconDisabled,
                                  )}
                                />
                              </Tooltip>
                            </Fragment>
                          )}

                        <div
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            marginRight: 10,
                            marginLeft: 10,
                            marginBottom: 2,
                            height: 20,
                            display: 'inline-block',
                            position: 'relative',
                          }}
                        />
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>
                              {analyse.content.type === 'sample'
                                ? t('view.analysis.information.auto_analysis')
                                : t('view.analysis.information.auto_available')}
                            </div>
                          }
                        >
                          <IconButton
                            disabled={
                              analyse.content.type !== 'sample' ||
                              analyse.content.progression_requested === 100
                            }
                            style={{ padding: 0, top: 7, verticalAlign: 'top', marginRight: 55 }}
                            className={classes.colorBlue}
                            aria-label="autoValidation"
                            onClick={() => {
                              dispatch(fetchAutoValidationLims(this.props.match.params.analysisId))
                              this.setState({ openAutoValidation: true })
                            }}
                          >
                            <PhotoFilterIcon />
                          </IconButton>
                        </Tooltip>
                        <ErrorBoundaryGuard isDialog>
                          <CircularProgressCmp
                            type={'analyse'}
                            style={{ position: 'absolute', right: 66, height: 35 }}
                          />
                        </ErrorBoundaryGuard>
                        <div
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            marginRight: 10,
                            marginBottom: 2,
                            height: 20,
                            display: 'inline-block',
                            position: 'relative',
                          }}
                        />
                        <ErrorBoundaryGuard isDialog>
                          <SettingsView
                            idTable={idTable}
                            propsColumns={propsColumns}
                            parentMultiGridRef={parentMultiGridRef}
                            origin={'analysis'}
                          />
                        </ErrorBoundaryGuard>
                      </Grid>
                      {/*<Grid item>*/}
                      {/*  <StyledRating*/}
                      {/*    name="Rating"*/}
                      {/*    readOnly="true"*/}
                      {/*    defaultValue={2}*/}
                      {/*    getLabelText={(value) => `${value} Flag${value !== 1 ? 's' : ''}`}*/}
                      {/*    precision={0.5}*/}
                      {/*    icon={<FlagIcon fontSize="inherit" />}*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                    </Grid>

                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      style={{ marginTop: 5 }}
                    >
                      {/*<Grid item xs={12} style={{ padding: '12px 12px 8px 12px' }}>*/}
                      {/*  <Divider variant="middle" />*/}
                      {/*</Grid>*/}
                      <Grid item style={{ paddingTop: 4, paddingBottom: 8 }}>
                        <ErrorBoundaryGuard isDialog>
                          <FilterButton
                            deviceType={analyse.content.chromatography_type}
                            columns={propsColumns}
                          />
                        </ErrorBoundaryGuard>
                      </Grid>
                      <Grid item style={{ paddingTop: 4, paddingBottom: 8 }}>
                        <ToggleButtonGroup
                          value={!isKOOK ? filterSelected : filterSelected2}
                          exclusive
                          onChange={applyFilter}
                          aria-label="text alignment"
                        >
                          <StyledToggleButton
                            className={classes.buttonCalib}
                            value={0}
                            aria-label="ISTD"
                          >
                            <Tooltip
                              key={'tooltip_istd'}
                              arrow
                              title={
                                <div style={{ fontSize: 13, padding: 5 }}>
                                  {t('view.analysis.information.all_istd')}
                                </div>
                              }
                            >
                              <Badge
                                className={classes.badgeCount}
                                max={10000}
                                showZero={!isFetchingUnitaryAnalysesDistinct}
                                badgeContent={
                                  !isFetchingUnitaryAnalysesDistinct && unitaryAnalysesDistinct.True
                                    ? unitaryAnalysesDistinct.True.count
                                    : 0
                                }
                              >
                                <GroupWorkIcon style={{ marginRight: 5 }} />
                                {t('view.analysis.information.istd')}
                              </Badge>
                            </Tooltip>
                            {/*{countLoaded && filterCount.True ? filterCount.True.count : 0}*/}
                          </StyledToggleButton>

                          <StyledToggleButton
                            className={classes.buttonCalib}
                            value={1}
                            aria-label="Detected"
                            style={{
                              display: isKOOK ? 'none' : 'flex',
                            }}
                          >
                            <Tooltip
                              key={'tooltip_filter_detected'}
                              arrow
                              title={
                                <div style={{ fontSize: 13, padding: 5 }}>
                                  {t('view.analysis.information.all_detected')}
                                </div>
                              }
                            >
                              <Badge
                                className={classes.badgeCount}
                                max={10000}
                                showZero={!isFetchingUnitaryAnalysesDistinct}
                                badgeContent={
                                  !isFetchingUnitaryAnalysesDistinct &&
                                  unitaryAnalysesDistinct.detected
                                    ? unitaryAnalysesDistinct.detected.count
                                    : 0
                                }
                              >
                                <ErrorIcon style={{ marginRight: 5 }} />
                                {/*Detected*/}
                              </Badge>
                            </Tooltip>
                          </StyledToggleButton>
                          <StyledToggleButton
                            className={classes.buttonCalib}
                            value={2}
                            aria-label="Suspect"
                            style={{
                              display: isKOOK ? 'none' : 'flex',
                            }}
                          >
                            <Tooltip
                              key={'tooltip_filter_suspected'}
                              arrow
                              title={
                                <div style={{ fontSize: 13, padding: 5 }}>
                                  {t('view.analysis.information.all_suspected')}
                                </div>
                              }
                            >
                              <Badge
                                className={classes.badgeCount}
                                max={10000}
                                showZero={!isFetchingUnitaryAnalysesDistinct}
                                badgeContent={
                                  !isFetchingUnitaryAnalysesDistinct &&
                                  unitaryAnalysesDistinct.suspected
                                    ? unitaryAnalysesDistinct.suspected.count
                                    : 0
                                }
                              >
                                <HelpIcon style={{ marginRight: 5 }} />
                                {/*Suspected*/}
                              </Badge>
                            </Tooltip>
                          </StyledToggleButton>

                          <StyledToggleButton
                            className={classes.buttonCalib}
                            value={3}
                            aria-label="Others"
                            style={{
                              display: isKOOK ? 'none' : 'flex',
                            }}
                          >
                            <Tooltip
                              key={'tooltip_filter_excluded'}
                              arrow
                              title={
                                <div style={{ fontSize: 13, padding: 5 }}>
                                  {t('view.analysis.information.all_excluded')}
                                </div>
                              }
                            >
                              <Badge
                                className={classes.badgeCount}
                                max={10000}
                                showZero={!isFetchingUnitaryAnalysesDistinct}
                                badgeContent={
                                  !isFetchingUnitaryAnalysesDistinct &&
                                  unitaryAnalysesDistinct.excluded
                                    ? unitaryAnalysesDistinct.excluded.count
                                    : 0
                                }
                              >
                                <CancelIcon style={{ marginRight: 5 }} />
                                {/*Excluded*/}
                              </Badge>
                            </Tooltip>
                            {/*{countLoaded && filterCount.excluded ? filterCount.excluded.count : 0}*/}
                          </StyledToggleButton>
                          <StyledToggleButton
                            className={classes.buttonCalib}
                            value={4}
                            aria-label="OK"
                            style={{
                              display: !isKOOK ? 'none' : 'flex',
                            }}
                          >
                            <Tooltip
                              key={'tooltip_filter_ok'}
                              arrow
                              title={
                                <div style={{ fontSize: 13, padding: 5 }}>
                                  {t('view.analysis.information.all_ok')}
                                </div>
                              }
                            >
                              <Badge
                                className={classes.badgeCount}
                                max={10000}
                                showZero={!isFetchingUnitaryAnalysesDistinct}
                                badgeContent={
                                  !isFetchingUnitaryAnalysesDistinct && unitaryAnalysesDistinct.ok
                                    ? unitaryAnalysesDistinct.ok.count
                                    : 0
                                }
                              >
                                <ThumbUpIcon />
                              </Badge>
                            </Tooltip>
                          </StyledToggleButton>
                          <StyledToggleButton
                            className={classes.buttonCalib}
                            value={5}
                            aria-label="KO"
                            style={{
                              display: !isKOOK ? 'none' : 'flex',
                            }}
                          >
                            <Tooltip
                              key={'tooltip_filter_ko'}
                              arrow
                              title={
                                <div style={{ fontSize: 13, padding: 5 }}>
                                  {t('view.analysis.information.all_ko')}
                                </div>
                              }
                            >
                              <Badge
                                className={classes.badgeCount}
                                max={10000}
                                showZero={!isFetchingUnitaryAnalysesDistinct}
                                badgeContent={
                                  !isFetchingUnitaryAnalysesDistinct && unitaryAnalysesDistinct.ko
                                    ? unitaryAnalysesDistinct.ko.count
                                    : 0
                                }
                              >
                                <ThumbDownIcon />
                              </Badge>
                            </Tooltip>
                          </StyledToggleButton>
                        </ToggleButtonGroup>
                        {/*<FormControlLabel*/}
                        {/*  style={{ marginLeft: 50 }}*/}
                        {/*  control={<Switch checked={untreatedChecked} onChange={handleUntreatedChange} />}*/}
                        {/*  label="Untreated" //NewReleasesIcon*/}
                        {/*/>*/}
                        {/*<FormControlLabel*/}
                        {/*  style={{ marginLeft: 20 }}*/}
                        {/*  control={<Switch checked={checked} onChange={handleChange} />}*/}
                        {/*  label="Requested"*/}
                        {/*/>*/}
                        <div style={{ display: 'inline-flex' }}>
                          <Tooltip
                            key={'tooltip_requested'}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {t('view.analysis.information.filter_requested')}
                              </div>
                            }
                          >
                            <Badge
                              className={clsx(
                                classes.badgeCountRect,
                                checked ? classes.badgeCountRectSelected : null,
                              )}
                              max={10000}
                              showZero={!isFetchingUnitaryAnalysesDistinct}
                              overlap="circular"
                              badgeContent={
                                !isFetchingUnitaryAnalysesDistinct &&
                                unitaryAnalysesDistinct.requested
                                  ? unitaryAnalysesDistinct.requested.count
                                  : 0
                              }
                            >
                              <IconButton
                                style={{ marginLeft: 50 }}
                                aria-label="requested"
                                className={clsx(
                                  classes.filterButton,
                                  checked ? classes.selected : classes.notSelected,
                                )}
                                onClick={handleChange}
                              >
                                <PersonPinIcon />
                              </IconButton>
                            </Badge>
                          </Tooltip>
                          <Tooltip
                            key={'tooltip_untreated'}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {t('view.analysis.information.filter_untreated')}
                              </div>
                            }
                          >
                            <Badge
                              className={clsx(
                                classes.badgeCountRect,
                                untreatedChecked ? classes.badgeCountRectSelected : null,
                              )}
                              max={10000}
                              showZero={!isFetchingUnitaryAnalysesDistinct}
                              overlap="circular"
                              badgeContent={
                                !isFetchingUnitaryAnalysesDistinct && unitaryAnalysesDistinct['0']
                                  ? unitaryAnalysesDistinct['0'].count
                                  : 0
                              }
                            >
                              <IconButton
                                aria-label="untreated"
                                className={clsx(
                                  classes.filterButton,
                                  untreatedChecked ? classes.selected : classes.notSelected,
                                )}
                                onClick={handleUntreatedChange}
                              >
                                <NewReleasesIcon />
                              </IconButton>
                            </Badge>
                          </Tooltip>
                          <Tooltip
                            key={'tooltip_detected'}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {t('view.analysis.information.filter_detected')}
                              </div>
                            }
                          >
                            <Badge
                              className={clsx(
                                classes.badgeCountRect,
                                validationDetected ? classes.badgeCountRectSelected : null,
                              )}
                              max={10000}
                              showZero={!isFetchingUnitaryAnalysesDistinct}
                              overlap="circular"
                              badgeContent={
                                !isFetchingUnitaryAnalysesDistinct && unitaryAnalysesDistinct['1']
                                  ? unitaryAnalysesDistinct['1'].count
                                  : 0
                              }
                            >
                              <IconButton
                                aria-label="detected"
                                className={clsx(
                                  classes.filterButton,
                                  validationDetected ? classes.selected : classes.notSelected,
                                )}
                                onClick={handleChangeValidationDetected}
                              >
                                {isKOOK ? <CheckIcon /> : <Molecule />}
                              </IconButton>
                            </Badge>
                          </Tooltip>
                          <Tooltip
                            key={'tooltip_notdetected'}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {t('view.analysis.information.filter_not_detected')}
                              </div>
                            }
                          >
                            <Badge
                              className={clsx(
                                classes.badgeCountRect,
                                validationNotDetected ? classes.badgeCountRectSelected : null,
                              )}
                              max={10000}
                              showZero={!isFetchingUnitaryAnalysesDistinct}
                              overlap="circular"
                              badgeContent={
                                !isFetchingUnitaryAnalysesDistinct && unitaryAnalysesDistinct['2']
                                  ? unitaryAnalysesDistinct['2'].count
                                  : 0
                              }
                            >
                              <IconButton
                                aria-label="notDetected"
                                className={clsx(
                                  classes.filterButton,
                                  validationNotDetected ? classes.selected : classes.notSelected,
                                )}
                                onClick={handleChangeValidationNotDetected}
                              >
                                {isKOOK ? <ClearIcon /> : <Nothing />}
                              </IconButton>
                            </Badge>
                          </Tooltip>
                          {!isKOOK && (
                            <Tooltip
                              key={'tooltip_relaunch'}
                              arrow
                              title={
                                <div style={{ fontSize: 13, padding: 5 }}>
                                  {t('view.analysis.information.filter_relaunch')}
                                </div>
                              }
                            >
                              <Badge
                                className={clsx(
                                  classes.badgeCountRect,
                                  validationRelaunch ? classes.badgeCountRectSelected : null,
                                )}
                                max={10000}
                                showZero={!isFetchingUnitaryAnalysesDistinct}
                                overlap="circular"
                                badgeContent={
                                  !isFetchingUnitaryAnalysesDistinct && unitaryAnalysesDistinct['3']
                                    ? unitaryAnalysesDistinct['3'].count
                                    : 0
                                }
                              >
                                <IconButton
                                  aria-label="relaunch"
                                  className={clsx(
                                    classes.filterButton,
                                    validationRelaunch ? classes.selected : classes.notSelected,
                                  )}
                                  onClick={handleChangeValidationRelaunch}
                                >
                                  <Relance />
                                </IconButton>
                              </Badge>
                            </Tooltip>
                          )}
                        </div>
                      </Grid>
                      <Grid style={{ marginTop: 6 }}>
                        {analyse &&
                          analyse.content.flags &&
                          analyse.content.flags.globals &&
                          analyse.content.flags.globals.map((row, index) => (
                            <BootstrapTooltip
                              interactive
                              title={
                                <Fragment>
                                  <div style={{ fontWeight: 'bold' }}>
                                    Classification: {row.classification}
                                  </div>
                                  <div
                                    style={{
                                      marginTop: 15,
                                      color: '#000000DE',
                                      maxHeight: 400,
                                      overflow: 'scroll',
                                    }}
                                  >
                                    {row.reason}
                                  </div>
                                </Fragment>
                              }
                              className={classes.chipTooltip}
                              level={row.level}
                              key={'global_flags_' + index}
                              // key={`${analyse._id}_${row.level}_${row.title}`}
                            >
                              <Chip
                                variant="outlined"
                                className={clsx(
                                  classes.chip,
                                  row.level === 'warning'
                                    ? classes.flagWarning
                                    : row.level === 'error'
                                    ? classes.flagError
                                    : classes.flagInfo,
                                )}
                                icon={
                                  row.level === 'warning' ? (
                                    <WarningIcon />
                                  ) : row.level === 'error' ? (
                                    <ErrorIcon />
                                  ) : (
                                    <InfoIcon />
                                  )
                                }
                                label={row.title}
                              />
                            </BootstrapTooltip>
                          ))}
                      </Grid>
                      {!tableOldVersion && (
                        <Grid>
                          <Tooltip
                            arrow
                            title={
                              <div className={classes.tooltip}>
                                {t('view.analysis.information.removeFilters')}
                              </div>
                            }
                          >
                            <IconButton
                              color="inherit"
                              aria-label="removeFilter"
                              onClick={() => {
                                this.tableMultiGridRef.current.clearFilters()
                              }}
                            >
                              <RemoveFilterIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            arrow
                            title={
                              <div className={classes.tooltip}>
                                {t('view.analysis.information.download')}
                              </div>
                            }
                          >
                            <IconButton
                              color="inherit"
                              aria-label="download"
                              onClick={() => {
                                this.tableMultiGridRef.current.downloadCSV()
                              }}
                            >
                              <ExcelIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid>
                        <div className={classes.search}>
                          <div className={classes.searchIcon}>
                            <SearchIcon />
                          </div>
                          <InputBase
                            placeholder={t('view.analysis.information.search')}
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={this.onSearch}
                            ref={this.searchRef}
                          />
                          {this.state.displayClear && (
                            <IconButton
                              className={clsx(classes.clearIcon, classes.searchIcon)}
                              aria-label="clear"
                              onClick={this.clearSearch}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Title>
                </div>
                <div
                  className={clsx(
                    collapsed ? 'collapsed' : 'notCollapsed',
                    'collapsor',
                    classes.collapsor,
                  )}
                >
                  <div
                    style={{
                      position: 'absolute',
                      width: 48,
                      height: 5,
                      top: -2,
                      left: 1,
                      background: 'white',
                    }}
                  ></div>
                  <IconButton
                    aria-label="collapse"
                    onClick={this.collapse}
                    className={classes.collapseButton}
                    style={{ transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)' }}
                  >
                    <ExpandLessIcon />
                  </IconButton>
                </div>
              </Paper>
            </Fragment>
          )
        }
      },
    ),
  ),
)
