// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { useEffect, useRef } from 'react'

export function useWhyDidYouRenderTool(componentName, props, state = {}) {
  const previousProps = useRef(props)
  const previousState = useRef(state)

  useEffect(() => {
    const changedProps = Object.keys(props).reduce((diff, key) => {
      if (props[key] !== previousProps.current[key]) {
        diff[key] = { from: previousProps.current[key], to: props[key] }
      }
      return diff
    }, {})

    const changedState = Object.keys(state).reduce((diff, key) => {
      if (state[key] !== previousState.current[key]) {
        diff[key] = { from: previousState.current[key], to: state[key] }
      }
      return diff
    }, {})

    if (Object.keys(changedProps).length > 0 || Object.keys(changedState).length > 0) {
      console.log(`[${componentName}] Re-render triggered by:`, {
        changedProps,
        changedState,
      })
    }

    previousProps.current = props
    previousState.current = state
  })
}
