// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

// import Worker from 'worker-loader!./cacheWorker'
import Worker from './cacheWorker'
import { WORKER_CALLBACK_TIMEOUT } from '../utils/config'

const worker = new Worker()
const callbacks = new Map()

/**
 * Retrieves the callback timeout value from localStorage or uses the default value.
 *
 * @returns {number} The callback timeout value in milliseconds.
 */
function getConvertedCallbackTimeout() {
  return WORKER_CALLBACK_TIMEOUT() * 1000
}

/**
 * Sets a callback function for a specific message ID with an optional timeout.
 *
 * @param {string} messageId - The unique identifier for the message.
 * @param {function} callback - The callback function to be executed when the message is received.
 * @param {number} [timeout=WORKER_CALLBACK_TIMEOUT] - The time in milliseconds to wait before the callback is considered timed out.
 */
function setWorkerCallback(messageId, callback, timeout = getConvertedCallbackTimeout()) {
  callbacks.set(messageId, callback)

  // Auto-clean if worker does not respond in time
  setTimeout(() => {
    if (callbacks.has(messageId)) {
      console.warn(`Callback for messageId ${messageId} timed out`)

      const timeoutCallback = callbacks.get(messageId)
      if (timeoutCallback) {
        timeoutCallback({ timeout: true, success: false })
      }

      callbacks.delete(messageId)
    }
  }, timeout)
}

/**
 * Generates a unique message ID.
 *
 * @returns {string} A unique message ID.
 */
function generateMessageId() {
  return Math.random().toString(36).substr(2, 9)
}

/**
 * Handles messages received from the Web Worker.
 *
 * @param {MessageEvent} event - The message event from the Web Worker.
 */
worker.onmessage = function (event) {
  const { messageId, success, ...rest } = event.data
  if (messageId && callbacks.has(messageId)) {
    const callback = callbacks.get(messageId)

    try {
      callback({ success, ...rest })
    } catch (error) {
      console.error(`Error executing callback for messageId: ${messageId}`, error)
    }

    // On supprime toujours le callback, succès ou erreur
    callbacks.delete(messageId)
  } else {
    console.warn('Received worker message without a known messageId:', event.data)
  }
}

/**
 * Handles errors from the Web Worker.
 *
 * @param {ErrorEvent} error - The error event from the Web Worker.
 */
worker.onerror = function (error) {
  console.error('Worker error:', error)
}

/**
 * Initializes the cache size using a Web Worker by opening the IndexedDB database,
 * retrieving all cached objects, and dispatching the total cache size.
 *
 * @param {function} dispatch - The Redux dispatch function to update the cache size in the state.
 */
export function initializeCacheSizeInWorker(dispatch) {
  const messageId = generateMessageId()

  setWorkerCallback(messageId, ({ action, success, size, error, timeout }) => {
    if (timeout) {
      console.warn('Worker did not respond in time for initializeCacheSizeInWorker')
    } else if (success && action === 'get_cache_size') {
      dispatch({ type: 'SET_CACHE_SIZE', size: size })
    } else {
      console.error('Worker failed to calculate cache size:', error)
    }
  })

  worker.postMessage({
    action: 'get_cache_size',
    messageId,
  })
}

/**
 * Adds objects to the cache using a Web Worker.
 *
 * @param {Array<Object>} toAdd - The array of objects to add to the cache.
 * @param {number} maxCacheSize - The maximum allowed size of the cache.
 * @param {string} cachePolicy - The cache eviction policy ('fifo', 'lru', 'lfu', 'random').
 * @param {function} dispatch - The Redux dispatch function to update the cache size in the state.
 */
export function addObjectsToCacheInWorker(toAdd, maxCacheSize, cachePolicy, dispatch) {
  const messageId = generateMessageId()

  setWorkerCallback(messageId, ({ action, success, totalSize, error, timeout }) => {
    if (timeout) {
      console.warn('Worker did not respond in time for addObjectsToCacheInWorker')
    }
    if (success && action === 'add_to_cache') {
      dispatch({ type: 'SET_CACHE_SIZE', size: totalSize })
    } else {
      console.error('Worker cache error:', error)
    }
  })

  worker.postMessage({
    action: 'add_to_cache',
    messageId,
    data: { toAdd, maxCacheSize, cachePolicy },
  })
}

/**
 * Retrieves a list of IDs to download using a Web Worker.
 *
 * @param {Array<Object>} items - The array of items to process.
 * @returns {Promise<Array>} A promise that resolves to an array of IDs to download.
 */
export function getIdListToDownload(items) {
  return new Promise((resolve, reject) => {
    const messageId = generateMessageId()

    setWorkerCallback(messageId, ({ action, success, result, error, timeout }) => {
      if (timeout) {
        console.warn('Worker did not respond in time for getIdListToDownload')
        resolve([])
      } else if (success && action === 'get_list_to_download') {
        resolve(result)
      } else {
        reject(error)
      }
    })

    worker.postMessage({ action: 'get_list_to_download', messageId, data: { items } })
  })
}

/**
 * Retrieves the updated and present IDs using a Web Worker.
 *
 * @param {Array<Object>} items - The array of items to process.
 * @returns {Promise<Object>} A promise that resolves to an object containing `to_get` and `already_present` arrays.
 */
export function getUpdatedAndPresentIdsInWorker(items) {
  return new Promise((resolve, reject) => {
    const messageId = generateMessageId()

    setWorkerCallback(messageId, ({ action, success, result, error, timeout }) => {
      if (timeout) {
        console.warn('Worker did not respond in time for getUpdatedAndPresentIdsInWorker')
        resolve([])
      }
      if (success && action === 'get_current_and_list_to_download') {
        resolve(result) // { to_get, already_present }
      } else {
        reject(error)
      }
    })

    worker.postMessage({
      action: 'get_current_and_list_to_download',
      messageId,
      data: { items },
    })
  })
}

/**
 * Clears the cache using a Web Worker.
 *
 * @param {function} dispatch - The Redux dispatch function to update the cache state.
 */
export function clearCacheInWorker(dispatch) {
  const messageId = generateMessageId()

  setWorkerCallback(messageId, ({ action, success, error, timeout }) => {
    if (action === 'clear_cache') {
      if (timeout) {
        console.warn('Worker did not respond in time for clearCacheInWorker')
      }
      if (success) {
        dispatch({ type: 'SET_CACHE_SIZE', size: 0 })
        localStorage.removeItem('CACHE_last_modificationDate')
        dispatch({ type: 'RECEIVE_CACHE_UPDATE' })
      } else {
        console.error('Worker error clearing cache:', error)
      }
    }
  })

  dispatch({ type: 'REQUEST_CACHE_UPDATE' })
  worker.postMessage({ action: 'clear_cache', messageId })
}

/**
 * Terminates the Web Worker.
 */
export function terminateTheWorker() {
  worker.terminate()
}
