// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect } from 'react'
import {
  buildATrainingBatch,
  isAlreadyRetrieve,
  retrieveBatches,
  retrieveCurrentBatch,
  updateGlobalPeak,
} from '../../utils/utilities'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { t } from 'i18next'

export default function CreateTrainingBatchDialog({ open, closeFct, batch }) {
  const dispatch = useDispatch()
  const [trainingBatchName, setTrainingBatchName] = React.useState('')

  useEffect(() => {
    if (batch) {
      setTrainingBatchName(batch.name + '_copy')
    }
  }, [batch])

  const handleConfirmClose = (event, confirm) => {
    closeFct()
    if (confirm) {
      buildATrainingBatch(dispatch, batch, trainingBatchName)
    }
  }

  const onChangeCreateBatch = (event) => {
    setTrainingBatchName(event.target.value)
  }

  return (
    <Dialog
      open={open}
      onClose={(event) => handleConfirmClose(event, false)}
      TransitionComponent={TransitionTop}
      aria-labelledby="dialog-reset-title"
    >
      <StyledDialogTitle>{t('view.dialogs.create_training.title')}</StyledDialogTitle>
      <DialogContent style={{ width: 600 }}>
        <DialogContentText>{t('view.dialogs.create_training.msg')}</DialogContentText>
        <TextField
          autoFocus
          required
          fullWidth
          // ref={this.createSubBatchRef}
          id="create-sub-batch-training"
          label={t('view.dialogs.create_training.name')}
          variant="outlined"
          value={trainingBatchName}
          onChange={(event) => onChangeCreateBatch(event)}
          error={
            trainingBatchName === '' ||
            trainingBatchName === null ||
            trainingBatchName === undefined
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => handleConfirmClose(event, true)}
          color="primary"
          disabled={
            trainingBatchName === '' ||
            trainingBatchName === null ||
            trainingBatchName === undefined
          }
        >
          {t('view.dialogs.create_training.build')}
        </Button>
        <Button autoFocus onClick={(event) => handleConfirmClose(event, false)} color="primary">
          {t('view.dialogs.create_training.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
