// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { useEffect } from 'react'
import { genericStyles, TransitionBottom } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { alpha, makeStyles, OutlinedInput } from '@material-ui/core'
import i18n, { t } from 'i18next'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import image from '../../resources/images/accounting_background.jpg'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { withRouter } from 'react-router-dom'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { fetchGetAccountings } from '../../redux/accountings/actions'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import MenuItem from '@material-ui/core/MenuItem'
import { getTimeEndDay, getTimeStartDay } from '../../utils/utilities'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Stack, TextField, Tooltip } from '@mui/material'
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Bar } from 'react-chartjs-2'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton from '@mui/material/IconButton'
import TollIcon from '@mui/icons-material/Toll'
import Typography from '@mui/material/Typography'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  settingsInfoText: {
    paddingTop: 15,
    paddingBottom: 10,
  },
  datePickers: {
    '& input': {
      padding: '12px 14px',
      width: 90,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
  date: {
    border: '1px solid #c4c4c4',
    borderRadius: 4,
  },
  fromCtn: {
    marginRight: -1,
    '& .MuiInputBase-root': {
      paddingRight: 0,
      // borderRadius: '4px 0px 0px 4px',
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'end',
    },
    '& fieldset': {
      // borderRight: 0,
      border: 0,
    },
    '& button, & .MuiInputAdornment-root': {
      display: 'none',
    },
  },
  separator: {
    // borderTop: '1px solid',
    // borderBottom: '1px solid',
    // borderColor: '#c4c4c4',
    // height: 56,
    // paddingTop: 13,
    // marginLeft: '0px !important',
  },
  toCtn: {
    marginRight: 0,
    marginLeft: '0px !important',
    '& .MuiInputBase-root': {
      paddingRight: 0,
      // borderRadius: '0px 4px 4px 0px',
    },
    '& fieldset': {
      // borderLeft: 0,
      border: 0,
    },
    '& button, & .MuiInputAdornment-root': {
      display: 'none',
    },
  },
  filters: {
    marginBottom: 20,
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  toggleButton: {
    marginTop: '0px !important',
    '& button': {
      padding: 10,
    },
  },
  total: {
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.05),
    padding: '5px 10px',
    borderRadius: 10,
  },
}))

const AccountingDialog = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const genericClasses = genericStyles()

  const language = i18n.resolvedLanguage
  const { open } = props

  const [granularity, setGranularity] = React.useState(
    localStorage.getItem('ACCOUNTING_filter_granularity')
      ? localStorage.getItem('ACCOUNTING_filter_granularity')
      : 'month',
  )
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    localStorage.getItem('ACCOUNTING_filter_fromDate')
      ? new Date(localStorage.getItem('ACCOUNTING_filter_fromDate'))
      : new Date(getTimeStartDay() - 604800000),
  )
  const [selectedToDate, setSelectedToDate] = React.useState(
    localStorage.getItem('ACCOUNTING_filter_toDate')
      ? new Date(localStorage.getItem('ACCOUNTING_filter_toDate'))
      : new Date(getTimeEndDay()),
  )
  const [openFrom, setOpenFrom] = React.useState(false)
  const [openTo, setOpenTo] = React.useState(false)

  const accountings = useSelector((state) => state.accountings.accountings)
  const isLoading = useSelector((state) => state.accountings.isFetchingAccountings)

  useEffect(() => {
    if (open) {
      dispatch(fetchGetAccountings('client A', granularity, selectedFromDate, selectedToDate))
    }
  }, [open, granularity, selectedFromDate, selectedToDate])

  const handleClose = () => {
    dispatch(fetchChangeSettings('displayAccountingDialog', false))
  }

  function dateOperation(date, selector, op) {
    const newDate = new Date(date)
    switch (selector) {
      case 'hours':
        newDate.setHours(newDate.getHours() + op)
        break
      case 'day':
        newDate.setDate(newDate.getDate() + op)
        break
      case 'week':
        newDate.setDate(newDate.getDate() + op * 7)
        break
      case 'month':
        newDate.setMonth(newDate.getMonth() + op)
        break
      case 'year':
        newDate.setFullYear(newDate.getFullYear() + op)
        break
    }
    return newDate
  }

  function getSelector(granularity) {
    switch (granularity) {
      case 'month':
        return 'year'
      case 'day':
        return 'month'
      case 'hours':
        return 'day'
      default:
        return 'year'
    }
  }

  function getViewFromGranularity(granularity) {
    switch (granularity) {
      case 'month':
        return ['year', 'month']
      case 'day':
        return ['year', 'month', 'day']
      case 'hours':
        return ['year', 'month', 'day', 'hours']
      default:
        return ['year']
    }
  }

  function getWidthFromGranularity(granularity) {
    switch (granularity) {
      case 'month':
        return 150
      case 'day':
        return 135
      case 'hours':
        return 170
      default:
        return 85
    }
  }

  function genererLabels(dateDebut, dateFin) {
    if (dateFin < dateDebut) {
      console.error('Erreur : La date de fin est inférieure à la date de début.')
      return []
    }

    var labels = []
    var dateCourante = new Date(dateDebut)

    while (dateCourante <= dateFin) {
      // Sonar ignore: dateFin is not meant to be modified
      var jour = dateCourante.toLocaleDateString(language, { day: 'numeric' })
      var jourSemaine = dateCourante.toLocaleDateString(language, { weekday: 'long' })
      var jourMois = dateCourante.toLocaleDateString(language, { day: 'numeric', month: 'long' })
      var heuresMinutes = dateCourante.toLocaleTimeString(language, {
        hour: 'numeric',
        // minute: 'numeric',
      })
      var mois = dateCourante.toLocaleDateString(language, { month: 'short' })
      var annee = dateCourante.toLocaleDateString(language, { year: 'numeric' })

      switch (granularity) {
        case 'month':
          var labelM = mois + '/' + annee
          labels.push(labelM)
          dateCourante.setMonth(dateCourante.getMonth() + 1)
          break
        case 'day':
          var labelD = `${jour}`
          labels.push(labelD)
          dateCourante.setDate(dateCourante.getDate() + 1)
          break
        case 'hours':
          var labelH = `${heuresMinutes}`
          labels.push(labelH)
          dateCourante.setHours(dateCourante.getHours() + 1)
          break
        default:
          var labelY = `${annee}`
          labels.push(labelY)
          dateCourante.setFullYear(dateCourante.getFullYear() + 1)
          break
      }
    }

    return labels
  }

  function handleDateChange(date, origin) {
    let from = selectedFromDate
    let to = selectedToDate
    const selector = getSelector(granularity)

    switch (origin) {
      case 'from':
        setSelectedFromDate(date)
        localStorage.setItem('ACCOUNTING_filter_fromDate', date)
        const newToDateOneMonthLater = dateOperation(date, selector, 1)
        if (
          granularity !== 'year' &&
          (newToDateOneMonthLater < to.getTime() || date.getTime() > to.getTime())
        ) {
          setSelectedToDate(newToDateOneMonthLater)
          localStorage.setItem('ACCOUNTING_filter_toDate', newToDateOneMonthLater)
        }
        break
      case 'to':
        setSelectedToDate(date)
        localStorage.setItem('ACCOUNTING_filter_toDate', date)
        const newFromDateOneMonthLater = dateOperation(date, selector, -1)
        if (
          granularity !== 'year' &&
          (newFromDateOneMonthLater > from.getTime() || date.getTime() < from.getTime())
        ) {
          setSelectedFromDate(newFromDateOneMonthLater)
          localStorage.setItem('ACCOUNTING_filter_fromDate', newFromDateOneMonthLater)
        }
        break
      default:
        break
    }
    //
    // dispatch(
    //   fetchBatches(
    //     dateFilterActive ? Math.trunc((origin === 'from' ? date : from).getTime() / 1000) : null,
    //     dateFilterActive ? Math.trunc((origin === 'to' ? date : to).getTime() / 1000) : null,
    //     wipChecked,
    //     backlogFilterService,
    //     backlogFilterType,
    //     backlogFilterDevice,
    //   ),
    // )
  }

  function handleGranularityChange(event) {
    setGranularity(event.target.value)
    localStorage.setItem('ACCOUNTING_filter_granularity', event.target.value)
    localStorage.setItem(
      'ACCOUNTING_filter_toDate',
      dateOperation(selectedFromDate, getSelector(event.target.value), 1),
    )
    setSelectedToDate(dateOperation(selectedFromDate, getSelector(event.target.value), 1))
  }

  function handleNext() {
    localStorage.setItem(
      'ACCOUNTING_filter_fromDate',
      dateOperation(selectedFromDate, granularity, 1),
    )
    setSelectedFromDate(dateOperation(selectedFromDate, granularity, 1))
    localStorage.setItem('ACCOUNTING_filter_toDate', dateOperation(selectedToDate, granularity, 1))
    setSelectedToDate(dateOperation(selectedToDate, granularity, 1))
  }

  function handlePrev() {
    localStorage.setItem(
      'ACCOUNTING_filter_fromDate',
      dateOperation(selectedFromDate, granularity, -1),
    )
    setSelectedFromDate(dateOperation(selectedFromDate, granularity, -1))
    localStorage.setItem('ACCOUNTING_filter_toDate', dateOperation(selectedToDate, granularity, -1))
    setSelectedToDate(dateOperation(selectedToDate, granularity, -1))
  }

  return (
    <Dialog
      open={open}
      fullWidth={false}
      maxWidth={'xl'}
      onClose={handleClose}
      TransitionComponent={TransitionBottom}
    >
      <span>
        <DialogTitle style={{ padding: 0 }}>
          <Box className={genericClasses.header}>
            <CardMedia
              className={genericClasses.cardImg}
              component="img"
              alt="header image"
              height="130"
              image={image}
              title="Header image"
            />
            <span className={genericClasses.title}>{t('view.dialogs.accounting.title')}</span>
            <AccountBalanceWalletIcon className={genericClasses.icon} />
          </Box>
        </DialogTitle>
        <DialogContent style={{ marginTop: 10 }}>
          <Stack direction="column" justifyContent="space-between" alignItems="center" spacing={3}>
            {/*<DialogContentText className={classes.settingsInfoText}>*/}
            {/*  {t('view.dialogs.accounting.title')}*/}
            {/*</DialogContentText>*/}
            {/*<Stack*/}
            {/*  direction="row"*/}
            {/*  justifyContent="space-between"*/}
            {/*  alignItems="center"*/}
            {/*  spacing={2}*/}
            {/*  className={classes.filters}*/}
            {/*>*/}
            {/*  <span>*/}
            {/*    <LocalizationProvider dateAdapter={AdapterDateFns}>*/}
            {/*      <DateTimePicker*/}
            {/*        className={classes.fromCtn}*/}
            {/*        style={{ marginRight: -1 }}*/}
            {/*        label={t('view.backlog.from')}*/}
            {/*        value={selectedFromDate}*/}
            {/*        onChange={(e) => handleDateChange(e, 'from')}*/}
            {/*      />*/}
            {/*      <DateTimePicker*/}
            {/*        className={classes.toCtn}*/}
            {/*        style={{ marginRight: 15 }}*/}
            {/*        label={t('view.backlog.to')}*/}
            {/*        value={selectedToDate}*/}
            {/*        onChange={(e) => handleDateChange(e, 'to')}*/}
            {/*      />*/}
            {/*    </LocalizationProvider>*/}
            {/*  </span>*/}
            {/*  <FormControl style={{ width: 200 }}>*/}
            {/*    <InputLabel id="accounting-multiple-name-label">*/}
            {/*      {t('view.dialogs.accounting.granularity')}*/}
            {/*    </InputLabel>*/}
            {/*    <Select*/}
            {/*      labelId="accounting-multiple-name-label"*/}
            {/*      value={granularity}*/}
            {/*      onChange={handleGranularityChange}*/}
            {/*      input={<OutlinedInput label={t('view.dialogs.accounting.granularity')} />}*/}
            {/*      startIcon={*/}
            {/*        <FormatLineSpacingIcon*/}
            {/*          sx={{*/}
            {/*            transform: 'rotate(-90deg)',*/}
            {/*          }}*/}
            {/*        />*/}
            {/*      }*/}
            {/*    >*/}
            {/*      {['day', 'yesterday', 'week', 'month', 'year'].map((name) => (*/}
            {/*        <MenuItem key={name} value={name}>*/}
            {/*          {t('view.dialogs.accounting.' + name)}*/}
            {/*        </MenuItem>*/}
            {/*      ))}*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Stack>*/}
            <Stack
              style={{ width: '100%', marginTop: 0 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <ToggleButtonGroup
                color="primary"
                value={granularity}
                exclusive
                onChange={handleGranularityChange}
                className={classes.toggleButton}
              >
                <ToggleButton value="year">{t('view.dialogs.accounting.year')}</ToggleButton>
                <ToggleButton value="month">{t('view.dialogs.accounting.month')}</ToggleButton>
                {/*<ToggleButton value="week">{t('view.dialogs.accounting.week')}</ToggleButton>*/}
                <ToggleButton value="day">{t('view.dialogs.accounting.day')}</ToggleButton>
                <ToggleButton value="hours">{t('view.dialogs.accounting.hour')}</ToggleButton>
              </ToggleButtonGroup>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
                <IconButton
                  aria-label="prev"
                  style={{ paddingLeft: 18, paddingRight: 6 }}
                  onClick={handlePrev}
                  disabled={new Date() < dateOperation(selectedFromDate, granularity, -1)}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                {/*<Select*/}
                {/*  value={10}*/}
                {/*  // onChange={handleChange}*/}
                {/*  inputProps={{ 'aria-label': 'range' }}*/}
                {/*>*/}
                {/*  <MenuItem value="">*/}
                {/*    <em>None</em>*/}
                {/*  </MenuItem>*/}
                {/*  <MenuItem value={10}>Ten</MenuItem>*/}
                {/*  <MenuItem value={20}>Twenty</MenuItem>*/}
                {/*  <MenuItem value={30}>Thirty</MenuItem>*/}
                {/*</Select>*/}
                <Stack
                  className={classes.date}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {/*<DateRangePicker*/}
                    {/*  open={openFrom}*/}
                    {/*  onOpen={() => setOpenFrom(true)}*/}
                    {/*  // onClose={() => setOpenFrom(false)}*/}
                    {/*  slots={{ field: SingleInputDateRangeField }}*/}
                    {/*  slotProps={{*/}
                    {/*    textField: {*/}
                    {/*      onClick: () => setOpenFrom(true),*/}
                    {/*    },*/}
                    {/*  }}*/}
                    {/*/>*/}
                    {/*<MobileDatePicker*/}
                    {/*  open={openFrom}*/}
                    {/*  views={['month', 'year']}*/}
                    {/*  onOpen={() => setOpenFrom(true)}*/}
                    {/*  onClose={() => setOpenFrom(false)}*/}
                    {/*  value={selectedFromDate}*/}
                    {/*  onChange={(e) => handleDateChange(e, 'from')}*/}
                    {/*  slotProps={{*/}
                    {/*    textField: {*/}
                    {/*      onClick: () => setOpenFrom(true),*/}
                    {/*    },*/}
                    {/*  }}*/}
                    {/*/>*/}
                    <MobileDateTimePicker
                      autoFocus={false}
                      maxDate={new Date()}
                      views={getViewFromGranularity(granularity)}
                      openTo={granularity}
                      className={classes.fromCtn}
                      // label={t('view.backlog.from')}
                      value={selectedFromDate}
                      onAccept={(e) => handleDateChange(e, 'from')}
                      onOpen={() => setOpenFrom(true)}
                      onClose={() => setOpenFrom(false)}
                      open={openFrom}
                      slotProps={{
                        textField: {
                          style: { width: getWidthFromGranularity(granularity) },
                          onClick: () => setOpenFrom(true),
                        },
                      }}
                    />
                    <Typography className={classes.separator}>-</Typography>
                    <MobileDateTimePicker
                      autoFocus={false}
                      TrapFocusProps={{ disableEnforceFocus: true }}
                      SelectProps={{
                        MenuProps: {
                          disableEnforceFocus: true,
                        },
                      }}
                      maxDate={new Date()}
                      views={getViewFromGranularity(granularity)}
                      openTo={granularity}
                      className={classes.toCtn}
                      // label={t('view.backlog.to')}
                      value={selectedToDate}
                      onAccept={(e) => handleDateChange(e, 'to')}
                      onOpen={() => setOpenTo(true)}
                      onClose={() => setOpenTo(false)}
                      open={openTo}
                      slotProps={{
                        textField: {
                          style: { width: getWidthFromGranularity(granularity) },
                          onClick: () => setOpenTo(true),
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {/*<Tooltip*/}
                  {/*  placement="top"*/}
                  {/*  arrow*/}
                  {/*  title={*/}
                  {/*    <div className={classes.tooltip}>{t('view.dialogs.accounting.reset')}</div>*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <IconButton aria-label="reset" style={{ marginRight: 12 }}>*/}
                  {/*    <EventRepeatIcon />*/}
                  {/*  </IconButton>*/}
                  {/*</Tooltip>*/}
                </Stack>
                <IconButton
                  aria-label="next"
                  onClick={handleNext}
                  disabled={new Date() < dateOperation(selectedToDate, granularity, 1)}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Stack>
              <Tooltip
                placement="top"
                arrow
                title={<div className={classes.tooltip}>{t('view.dialogs.accounting.conso')}</div>}
              >
                <Stack
                  className={classes.total}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.5}
                >
                  <TollIcon style={{ fontSize: 'xx-large' }} />
                  <Typography variant="h4">245</Typography>
                </Stack>
              </Tooltip>
            </Stack>
            <Bar
              data={{
                // labels: [
                //   'January',
                //   'February',
                //   'March',
                //   'April',
                //   'May',
                //   'Jun',
                //   'Jully',
                //   'August',
                //   'September',
                //   'October',
                //   'November',
                //   'December',
                // ],
                labels: genererLabels(selectedFromDate, selectedToDate),
                datasets: [
                  {
                    label: 'Project A',
                    backgroundColor: '#caf270',
                    data: [12, 59, 5, 56, 58, 12, 59, 87, 45, 21, 19, 8],
                  },
                  {
                    label: 'Project B',
                    backgroundColor: '#45c490',
                    data: [12, 59, 5, 56, 58, 12, 59, 85, 23, 17, 6, 45],
                  },
                  {
                    label: 'Project C',
                    backgroundColor: '#008d93',
                    data: [12, 59, 5, 56, 58, 12, 59, 65, 51, 45, 33, 41],
                  },
                  {
                    label: 'Project D',
                    backgroundColor: '#2e5468',
                    data: [12, 59, 5, 56, 58, 12, 59, 12, 74, 61, 20, 48],
                  },
                ],
              }}
              options={{
                tooltips: {
                  displayColors: true,
                  callbacks: {
                    mode: 'x',
                  },
                },
                scales: {
                  xAxes: [
                    {
                      stacked: true,
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        callback: function (value, index, values) {
                          // Séparer le mois et l'année sur deux lignes
                          switch (granularity) {
                            case 'month':
                              const [mois, annee] = value.split('/')
                              return [mois, annee]
                            default:
                              return value
                          }
                        },
                      },
                    },
                  ],
                  yAxes: [
                    {
                      stacked: true,
                      ticks: {
                        beginAtZero: true,
                      },
                      type: 'linear',
                    },
                  ],
                },
                responsive: true,
                legend: { position: 'bottom' },
              }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('view.dialogs.accounting.close')}
          </Button>
        </DialogActions>
      </span>
    </Dialog>
  )
})
export default AccountingDialog
