// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { genericStyles, TaskTooltipMessage, TransitionBottom } from './Style'
import { Store } from 'react-notifications-component'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import { PUBLIC_URL } from '../../utils/config'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Tooltip } from '@mui/material'
import Badge from '@material-ui/core/Badge'
import clsx from 'clsx'
import IconButton from '@mui/material/IconButton'
import DialogActions from '@mui/material/DialogActions'
import Avatar from '@mui/material/Avatar'
import { withRouter } from 'react-router-dom'
import MessageEmpty from '@mui/icons-material/ChatBubble'
import MessageFull from '@mui/icons-material/Chat'
import InfoIcon from '@mui/icons-material/Info'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import StarsIcon from '@mui/icons-material/Stars'
import {
  fetchAddMessage,
  fetchCleanNewMessages,
  fetchGetMessages,
} from '../../redux/message/actions'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useTranslation } from 'react-i18next'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { TimelineOppositeContent } from '@mui/lab'
import Chip from '@mui/material/Chip'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import StarIcon from '@mui/icons-material/Star'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { t } from 'i18next'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton'
import { styled } from '@mui/material/styles'
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'

/**
 * Styles for the Message component.
 */
const useStyles = makeStyles((theme) => ({
  settingsInfoText: {
    paddingTop: 24,
    paddingBottom: 10,
    '& > div': {
      marginTop: -8,
    },
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: 'white',
    textShadow: '2px 1px 10px white',
  },
  loading: {
    marginRight: 25,
    '& .MuiBadge-badge': {
      top: 10,
      right: 10,
      // background: 'white',
      // color: '#000000DE',
    },
  },
  badgeCountRect: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: alpha(theme.palette.primary.main, 0.6),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountRectSelected: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: alpha(theme.palette.secondary.main, 0.8),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  filterButton: {
    borderRadius: '18px !important',
    border: '1px solid #c5c5c5 !important',
    backgroundColor: 'rgb(224 224 224) !important',
    marginRight: '0px !important',
    marginLeft: '15px !important',
    width: '50px !important',
    height: '34px !important',
    padding: '8px !important',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.05) + ' !important',
    },
  },
  selected: {
    backgroundColor: theme.palette.secondary.main + ' !important',
    color: 'white !important',
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main + ' !important',
    },
  },
  notSelected: {
    '&:hover': {
      // color: theme.palette.primary.main,
    },
  },
  tooltip_notification: {
    '& .rnc__notification-item': {
      paddingLeft: 30,
      borderLeft: '8px solid',
    },
    '& .rnc__notification-content': {
      padding: '10px 15px',
      display: 'grid',
    },
    '& .rnc__notification-content::before': {
      fontSize: 26,
      top: 15,
      left: 10,
    },
    '& .notification__timer': {
      height: 2,
      marginTop: 5,
    },
  },
  newMessageIcon: {
    color: '#ffc800',
    position: 'absolute',
    top: -10,
    right: 13,
  },
  mobileToolbar: {
    padding: 0,
    height: 36,
    margin: 3,
  },
  customRootDialog: {
    height: 'auto !important',
    maxHeight: '70% !important',
    width: '700px !important',
  },
  newBadge: {
    '& .MuiBadge-badge': {
      right: -10,
    },
  },
  scrollToBottom: {
    background: theme.palette.primary.main,
    width: 50,
    height: 50,
    position: 'absolute',
    bottom: 80,
    right: 28,
    borderRadius: 25,
    color: 'white',
  },
}))

const muteColor = '#e68d09'

/**
 * Message Component
 * Handles displaying and managing notifications/messages.
 */
const Message = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const genericClasses = genericStyles()

  // State for message and notification settings
  const [openMessage, setOpenMessage] = React.useState(false)
  const [newChecked, setNewChecked] = React.useState(
    localStorage.getItem('MESSAGE_filter_new')
      ? localStorage.getItem('MESSAGE_filter_new') === 'true'
      : false,
  )
  const [infoChecked, setInfoChecked] = React.useState(
    localStorage.getItem('MESSAGE_filter_info')
      ? localStorage.getItem('MESSAGE_filter_info') === 'true'
      : true,
  )
  const [successChecked, setSuccessChecked] = React.useState(
    localStorage.getItem('MESSAGE_filter_success')
      ? localStorage.getItem('MESSAGE_filter_success') === 'true'
      : true,
  )
  const [warningChecked, setWarningChecked] = React.useState(
    localStorage.getItem('MESSAGE_filter_warning')
      ? localStorage.getItem('MESSAGE_filter_warning') === 'true'
      : true,
  )
  const [muteMessage, setMuteMessage] = React.useState(
    localStorage.getItem('SETTINGS_mute_message')
      ? localStorage.getItem('SETTINGS_mute_message') === 'true'
      : false,
  )
  const [muteSound, setMuteSound] = React.useState(
    localStorage.getItem('SETTINGS_mute_sound')
      ? localStorage.getItem('SETTINGS_mute_sound') === 'true'
      : false,
  )
  const [errorChecked, setErrorChecked] = React.useState(
    localStorage.getItem('MESSAGE_filter_error')
      ? localStorage.getItem('MESSAGE_filter_error') === 'true'
      : true,
  )
  const [displayScrollToBottom, setDisplayScrollToBottom] = React.useState(false)
  const timelineRef = useRef(null)

  /**
   * Fetches messages when the component mounts.
   */
  useEffect(
    function () {
      dispatch(fetchGetMessages())
    },
    [dispatch],
  )

  const ColoredToggleButton = styled(ToggleButton)({
    '&:hover': {
      // backgroundColor: '#0000000d !important',
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white !important',
      backgroundColor: muteColor + ' !important',
    },
  })

  /**
   * Function to scroll to the bottom of the message timeline.
   */
  const scrollToBottom = () => {
    if (timelineRef.current) {
      // TODO Add update displayScrollToBottom on container size change
      // timelineRef.current.parentElement.onresize = function (e) {
      //   console.log('RESIZE')
      // }
      // timelineRef.current.parentElement.onchange = function (e) {
      //   console.log('CHANGE')
      // }
      timelineRef.current.parentElement.onscroll = function (e) {
        setDisplayScrollToBottom(
          Math.abs(
            timelineRef.current.parentElement.offsetHeight +
              timelineRef.current.parentElement.scrollTop -
              timelineRef.current.parentElement.scrollHeight,
          ) > 15,
        )
      }
      timelineRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    } else {
      setTimeout(scrollToBottom, 500)
    }
  }

  /**
   * Retrieves the user connection time from the Redux state.
   */
  const connexionTime = useSelector(function (state) {
    return state.settings.userConnexion
  })

  /**
   * Function to filter messages based on the selected filters.
   * It filters messages by type (info, success, warning, danger) and checks if the message is new.
   *
   * @param {Array} list - List of messages to filter.
   * @returns {Array} - Filtered messages based on the selected options.
   */
  const filterFunction = (list) => {
    return list
      .filter(function (item) {
        switch (item.type) {
          case 'info':
            return infoChecked
          case 'success':
            return successChecked
          case 'warning':
            return warningChecked
          case 'danger':
            return errorChecked
          default:
            return false
        }
      })
      .filter(function (item) {
        if (newChecked) {
          return item.isNew
        } else {
          return true
        }
      })
  }

  /**
   * Retrieves all messages from the Redux state.
   */
  const allMessages = useSelector((state) => state.message.messages)
  /**
   * Retrieves only new messages from the Redux state, limiting the results to the last 10 messages.
   */
  const newMessages = useSelector(function (state) {
    const newNotes = state.message.messages.filter((msg) => msg.isNew)
    return newNotes.slice(Math.max(newNotes.length - 10, 0))
  })
  /**
   * Retrieves filtered messages based on user-selected filters.
   */
  const messagesFiltered = useSelector((state) => filterFunction(state.message.messages))

  /**
   * Toggles the filter for new messages.
   * Updates local storage and state accordingly.
   */
  const handleNew = () => {
    localStorage.setItem('MESSAGE_filter_new', (!newChecked).toString())
    setNewChecked(!newChecked)
  }
  /**
   * Toggles the filter for informational messages.
   * Updates local storage and state accordingly.
   */
  const handleInfo = () => {
    localStorage.setItem('MESSAGE_filter_info', (!infoChecked).toString())
    setInfoChecked(!infoChecked)
  }
  /**
   * Toggles the filter for success messages.
   * Updates local storage and state accordingly.
   */
  const handleSuccess = () => {
    localStorage.setItem('MESSAGE_filter_success', (!successChecked).toString())
    setSuccessChecked(!successChecked)
  }
  /**
   * Toggles the filter for warning messages.
   * Updates local storage and state accordingly.
   */
  const handleWarning = () => {
    localStorage.setItem('MESSAGE_filter_warning', (!warningChecked).toString())
    setWarningChecked(!warningChecked)
  }
  /**
   * Toggles the filter for error messages.
   * Updates local storage and state accordingly.
   */
  const handleError = () => {
    localStorage.setItem('MESSAGE_filter_error', (!errorChecked).toString())
    setErrorChecked(!errorChecked)
  }

  /**
   * Function to handle opening the message dialog.
   * It fetches messages and scrolls to the bottom.
   */
  function handleOpenMessage() {
    dispatch(fetchGetMessages())
    setOpenMessage(true)
    scrollToBottom()
  }

  /**
   * Function to handle closing the message dialog.
   * It cleans new messages from the state.
   */
  function handleCloseMessage() {
    dispatch(fetchCleanNewMessages())
    setOpenMessage(false)
  }

  // const TaskTooltip = withStyles({
  //   tooltip: {
  //     maxWidth: 'none',
  //     maxHeight: 800,
  //     overflow: 'scroll',
  //     padding: '5px 0px 0px 10px',
  //   },
  // })(Tooltip)

  return (
    <Fragment>
      <Dialog
        open={openMessage}
        onClose={handleCloseMessage}
        TransitionComponent={TransitionBottom}
        aria-labelledby="dialog-title-user"
        fullWidth
        maxWidth={'lg'}
        classes={{
          paper: classes.customRootDialog,
        }}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Box className={classes.header}>
            <CardMedia
              className={classes.cardImg}
              component="img"
              alt="header image"
              height={130}
              image={PUBLIC_URL + '/images/messages_bkg_2.jpg'}
              title="Header image"
            />
            <span className={classes.titleCard}>{t('view.panel.toolbar.message.title')}</span>
            <NotificationsIcon className={genericClasses.icon} />
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden', minHeight: 60 }}>
          <div className={classes.settingsInfoText}>
            <Grid container spacing={1} direction="row" justifyContent="space-between">
              <Grid item style={{ padding: 0 }}>
                <Tooltip
                  key={'tooltip_new'}
                  arrow
                  title={
                    <div style={{ fontSize: 13, padding: 5 }}>
                      {t('view.panel.toolbar.message.tooltip.new_notif')}
                    </div>
                  }
                >
                  <Badge
                    className={clsx(
                      classes.badgeCountRect,
                      newChecked ? classes.badgeCountRectSelected : null,
                    )}
                    max={10000}
                    // showZero={true}
                    overlap="circular"
                    badgeContent={0}
                  >
                    <IconButton
                      aria-label="new"
                      className={clsx(
                        classes.filterButton,
                        newChecked ? classes.selected : classes.notSelected,
                      )}
                      onClick={handleNew}
                      size="large"
                    >
                      <StarsIcon />
                    </IconButton>
                  </Badge>
                </Tooltip>
                <Tooltip
                  key={'tooltip_info'}
                  arrow
                  title={
                    <div style={{ fontSize: 13, padding: 5 }}>
                      {t('view.panel.toolbar.message.tooltip.info_notif')}
                    </div>
                  }
                >
                  <Badge
                    className={clsx(
                      classes.badgeCountRect,
                      infoChecked ? classes.badgeCountRectSelected : null,
                    )}
                    max={10000}
                    // showZero={true}
                    overlap="circular"
                    badgeContent={0}
                  >
                    <IconButton
                      aria-label="running"
                      className={clsx(
                        classes.filterButton,
                        infoChecked ? classes.selected : classes.notSelected,
                      )}
                      onClick={handleInfo}
                      size="large"
                    >
                      <InfoIcon />
                    </IconButton>
                  </Badge>
                </Tooltip>
                <Tooltip
                  key={'tooltip_success'}
                  arrow
                  title={
                    <div style={{ fontSize: 13, padding: 5 }}>
                      {t('view.panel.toolbar.message.tooltip.success_notif')}
                    </div>
                  }
                >
                  <Badge
                    className={clsx(
                      classes.badgeCountRect,
                      successChecked ? classes.badgeCountRectSelected : null,
                    )}
                    max={10000}
                    // showZero={true}
                    overlap="circular"
                    badgeContent={0}
                  >
                    <IconButton
                      aria-label="running"
                      className={clsx(
                        classes.filterButton,
                        successChecked ? classes.selected : classes.notSelected,
                      )}
                      onClick={handleSuccess}
                      size="large"
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  </Badge>
                </Tooltip>
                <Tooltip
                  key={'tooltip_warning'}
                  arrow
                  title={
                    <div style={{ fontSize: 13, padding: 5 }}>
                      {t('view.panel.toolbar.message.tooltip.warning_notif')}
                    </div>
                  }
                >
                  <Badge
                    className={clsx(
                      classes.badgeCountRect,
                      warningChecked ? classes.badgeCountRectSelected : null,
                    )}
                    max={10000}
                    // showZero={true}
                    overlap="circular"
                    badgeContent={0}
                  >
                    <IconButton
                      aria-label="running"
                      className={clsx(
                        classes.filterButton,
                        warningChecked ? classes.selected : classes.notSelected,
                      )}
                      onClick={handleWarning}
                      size="large"
                    >
                      <WarningIcon />
                    </IconButton>
                  </Badge>
                </Tooltip>
                <Tooltip
                  key={'tooltip_error'}
                  arrow
                  title={
                    <div style={{ fontSize: 13, padding: 5 }}>
                      {t('view.panel.toolbar.message.tooltip.error_notif')}
                    </div>
                  }
                >
                  <Badge
                    className={clsx(
                      classes.badgeCountRect,
                      errorChecked ? classes.badgeCountRectSelected : null,
                    )}
                    max={10000}
                    // showZero={true}
                    overlap="circular"
                    badgeContent={0}
                  >
                    <IconButton
                      aria-label="running"
                      className={clsx(
                        classes.filterButton,
                        errorChecked ? classes.selected : classes.notSelected,
                      )}
                      onClick={handleError}
                      size="large"
                    >
                      <ErrorIcon />
                    </IconButton>
                  </Badge>
                </Tooltip>
                {!muteMessage && (
                  <Tooltip
                    key={'notification_mute'}
                    arrow
                    title={
                      <div style={{ fontSize: 13, padding: 5 }}>
                        {t('view.panel.toolbar.message.tooltip.sound_notif')}:{' '}
                        {muteSound
                          ? t('view.panel.toolbar.message.tooltip.mute_actif')
                          : t('view.panel.toolbar.message.tooltip.mute_nonactif')}
                      </div>
                    }
                  >
                    <ColoredToggleButton
                      size="small"
                      disabled={muteMessage}
                      style={{
                        position: 'absolute',
                        right: 55,
                        borderRadius: 5,
                        padding: 3,
                      }}
                      value="mute"
                      selected={muteSound}
                      onChange={() => {
                        localStorage.setItem('SETTINGS_mute_sound', (!muteSound).toString())
                        setMuteSound((oldValue) => !oldValue)
                      }}
                    >
                      <VolumeOffIcon />
                    </ColoredToggleButton>
                  </Tooltip>
                )}
                <Tooltip
                  key={'tooltip_mute'}
                  arrow
                  title={
                    <div style={{ fontSize: 13, padding: 5 }}>
                      {t('view.panel.toolbar.message.tooltip.mute_notif')}:{' '}
                      {muteMessage
                        ? t('view.panel.toolbar.message.tooltip.mute_actif')
                        : t('view.panel.toolbar.message.tooltip.mute_nonactif')}
                    </div>
                  }
                >
                  <ColoredToggleButton
                    size="small"
                    style={{
                      position: 'absolute',
                      right: 15,
                      borderRadius: 5,
                      padding: 3,
                    }}
                    value="muted"
                    selected={muteMessage}
                    onChange={() => {
                      localStorage.setItem('SETTINGS_mute_message', (!muteMessage).toString())
                      setMuteMessage((oldValue) => !oldValue)
                    }}
                  >
                    <NotificationsOffIcon />
                  </ColoredToggleButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Timeline style={{ marginTop: 0, marginBottom: 0 }} align="left" ref={timelineRef}>
            {messagesFiltered.map((message, index) => (
              <TimelineItem key={index + '_message'}>
                <TimelineOppositeContent
                  align="right"
                  variant="body2"
                  color="text.secondary"
                  style={{ marginTop: 7, width: 132, flex: 'none' }}
                >
                  <Chip
                    icon={<AccessTimeIcon style={{ width: '0.7em' }} />}
                    label={new Date(message.date).toLocaleTimeString()}
                    style={{ backgroundColor: 'transparent' }}
                  />
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    style={{
                      width: 50,
                      height: 50,
                      margin: 0,
                      padding: 0,
                      background: 'none',
                      boxShadow: 'none',
                    }}
                  >
                    {message.type === 'info' && (
                      <InfoIcon
                        style={{ width: 50, height: 50 }}
                        className={'notification_color_icon--info'}
                      />
                    )}
                    {message.type === 'warning' && (
                      <WarningIcon
                        style={{ width: 50, height: 50 }}
                        className={'notification_color_icon--warning'}
                      />
                    )}
                    {message.type === 'danger' && (
                      <ErrorIcon
                        style={{ width: 50, height: 50 }}
                        className={'notification_color_icon--danger'}
                      />
                    )}
                    {message.type === 'success' && message.connexionMessage !== true && (
                      <CheckCircleIcon
                        style={{ width: 50, height: 50 }}
                        className={'notification_color_icon--success'}
                      />
                    )}
                    {message.connexionMessage && (
                      <AccountCircleIcon
                        style={{ width: 50, height: 50 }}
                        className={'notification_color_icon--success'}
                      />
                    )}
                  </TimelineDot>
                  {index !== messagesFiltered.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent
                  style={{ minHeight: 70, marginBottom: 10, width: 'calc(100% - 175px)' }}
                >
                  <Badge
                    badgeContent={<StarIcon style={{ color: '#ffc800' }} />}
                    invisible={message.isNew !== true}
                    className={classes.newBadge}
                  >
                    <div className={'notification_color_title--' + message.type}>
                      {message.title}
                    </div>
                  </Badge>
                  {/*<div className={'notification_color_title--' + message.type}>{message.title}</div>*/}
                  <div>{message.message}</div>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
          {timelineRef.current && displayScrollToBottom && (
            <div className={classes.scrollToBottom}>
              <IconButton
                style={{ width: 50, height: 50 }}
                aria-label="scroll to bottom"
                onClick={() =>
                  timelineRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
                }
              >
                <ArrowDownwardIcon style={{ color: 'white' }} />
              </IconButton>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 8 }}>
          <Button size={'medium'} onClick={handleCloseMessage} color="primary">
            {t('view.panel.toolbar.message.button.close')}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.loading}>
        <Badge badgeContent={newMessages.length} overlap={'circular'} color="secondary">
          <TaskTooltipMessage
            arrow
            title={
              newMessages.length === 0 ? (
                <div style={{ fontSize: 13, padding: 5 }}>
                  {t('view.panel.toolbar.message.tooltip.no_new_msg')}
                </div>
              ) : (
                <div style={{ width: 300 }}>
                  <div style={{ fontSize: 17, padding: '12px 5px 20px' }}>
                    {t('view.panel.toolbar.message.tooltip.last_new_msg') + ':'}
                  </div>
                  <ColoredToggleButton
                    size="small"
                    style={{ position: 'absolute', top: 6, right: 0, borderRadius: 5, padding: 3 }}
                    value="muted"
                    selected={muteMessage}
                    onChange={() => {
                      localStorage.setItem('SETTINGS_mute_message', (!muteMessage).toString())
                      setMuteMessage((oldValue) => !oldValue)
                    }}
                  >
                    <NotificationsOffIcon />
                  </ColoredToggleButton>
                  {newMessages.map((message, index) => (
                    <div
                      key={'notification_cmp_' + index}
                      className={clsx('notification_cmp', classes.tooltip_notification)}
                    >
                      <div className={'notification'}>
                        <div
                          className={
                            'rnc__notification-item notification__cmp rnc__notification-item--' +
                            message.type
                          }
                        >
                          <div className={'rnc__notification-content'}>
                            <div className={'rnc__notification-message'}>
                              <div
                                style={{
                                  float: 'left',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  width: 'calc(100% - 62px)',
                                }}
                              >
                                {message.title}
                              </div>
                              <div style={{ position: 'absolute', right: 15 }}>
                                {new Date(message.date).toLocaleTimeString()}
                              </div>
                            </div>
                            <div className={'notification__timer'}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
            }
          >
            <IconButton
              aria-label="messages"
              style={{ color: 'inherit' }}
              onClick={handleOpenMessage}
              size="large"
            >
              {allMessages.length === 0 ? (
                muteMessage ? (
                  <NotificationsOffOutlinedIcon />
                ) : (
                  <NotificationsNoneIcon />
                )
              ) : muteMessage ? (
                <NotificationsOffIcon />
              ) : (
                <NotificationsIcon />
              )}
            </IconButton>
          </TaskTooltipMessage>
        </Badge>
      </div>
    </Fragment>
  )
})

/**
 * Function to extract status information from a message element.
 * It recursively traverses the React element tree to find status information.
 *
 * @param message - The message element from which to extract the status.
 * @returns {string | null} - The extracted status, or null if not found.
 */
function extractDataFromMessage(message) {
  function extractStatusFromElement(element) {
    if (element && element.props && element.props.children) {
      const children = Array.isArray(element.props.children)
        ? element.props.children
        : [element.props.children]

      for (const child of children) {
        if (child && typeof child === 'object' && child.$$typeof) {
          extractStatusFromElement(child)
        } else if (typeof child === 'string') {
          if (child.includes(t('view.common.message.status'))) {
            status = element.props.children[2]
          }
        }
      }
    }
  }
  let status = null
  extractStatusFromElement(message)
  return status
}

// Audio file for notification sound
const audio = new Audio(`${PUBLIC_URL}/notification.mp3`)
audio.volume = 0.1

/**
 * Function to play a notification sound.
 * Catches and logs errors if the sound playback fails.
 */
const playNotificationSound = () => {
  audio.play().catch((error) => console.log('Audio playback error:', error))
}

export default Message

/**
 * Function to display a popup notification message.
 * It checks user settings to determine if notifications or sounds should be muted.
 * If not muted, it plays a notification sound and shows a popup notification.
 *
 * @param dispatch - Redux dispatch function.
 * @param severity - Type of message (success, info, warning, error).
 * @param title - Title of the notification.
 * @param message - Message content.
 * @param timer - (Optional) Duration for the notification.
 */
export const displayPopupMessage = (dispatch, severity, title, message, timer) => {
  const shouldNotDisplay = localStorage.getItem('SETTINGS_mute_message')
    ? localStorage.getItem('SETTINGS_mute_message') === 'true'
    : false
  const shouldNotDisplaySound = localStorage.getItem('SETTINGS_mute_sound')
    ? localStorage.getItem('SETTINGS_mute_sound') === 'true'
    : false
  const extractedStatus = extractDataFromMessage(message)

  // Create message object for storage
  const msg = {
    title: title,
    message: message,
    type: severity.replace('error', 'danger'), // Convert "error" to "danger" for consistency
    date: new Date().getTime(),
    isNew: true,
  }
  // Prevent notifications from being displayed on the assistant page
  if (!window.location.href.endsWith('/?assistant')) {
    // Check if message should be muted or hidden
    if (shouldNotDisplay || (extractedStatus === 403 && severity === 'error')) {
      dispatch(fetchAddMessage(msg)) // Store message in Redux without displaying a popup
    } else {
      if (!shouldNotDisplaySound) {
        playNotificationSound() // Play notification sound if not muted
      }
      // Display notification popup
      Store.addNotification({
        title: title,
        message: message,
        type: severity.replace('error', 'danger'), // success, info, warning, danger
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__bounceInUp', 'animate__faster'],
        animationOut: ['animate__animated', 'animate__bounceOutRight', 'animate__faster'],
        dismiss: {
          duration: timer ? timer : 4000,
          onScreen: true,
          pauseOnHover: true,
        },
        onRemoval: () => {
          dispatch(fetchAddMessage(msg)) // Store message in Redux after removal
        },
      })
    }
  }
}

/**
 * Function to generate an error display component.
 * This function creates a user-friendly UI for displaying error messages,
 * allowing users to copy error details to the clipboard.
 *
 * @param {Object} data - The error object containing details such as status, statusText, URL, and stack trace.
 * @returns {JSX.Element | string} - A JSX component displaying error details or a string if data is not an object.
 */
export const generateErrorPart = (data) => {
  /**
   * Function to copy error details to the clipboard.
   * If the Clipboard API is not available, it uses a textarea fallback method.
   *
   * @param {Event} event - The click event triggering the copy action.
   * @param {string} text - The text content to be copied.
   */
  function onClipboardButtonClick(event, text) {
    event.stopPropagation()
    event.preventDefault()
    // Fallback method if Clipboard API is not supported
    if (!navigator.clipboard) {
      let textArea = document.createElement('textarea')
      textArea.value = text

      // Prevent scrolling to the bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        const successful = document.execCommand('copy')
        const msg = successful
          ? t('view.errorBoundary.copy.succes')
          : t('view.errorBoundary.copy.unsucces')
        console.log(t('view.errorBoundary.copy.return_msg') + ' ' + msg)
      } catch (err) {
        console.error(t('view.errorBoundary.copy.error_msg'), err)
      }

      document.body.removeChild(textArea)
      return
    }
    // Modern Clipboard API
    navigator.clipboard.writeText(text).then(
      function () {
        console.log(t('view.errorBoundary.copy.async_succes_msg'))
      },
      function (err) {
        console.error(t('view.errorBoundary.copy.error_msg'), err)
      },
    )
  }

  // Check if the error object contains meaningful information
  if (data.status || data.statusText || data.url) {
    return (
      <div className={'custom_error_msg_part_style'}>
        <IconButton
          onClick={(event) =>
            onClipboardButtonClick(
              event,
              (data.status ? t('view.common.message.status') + ' ' + data.status + '\n' : '') +
                (data.statusText
                  ? t('view.common.message.statustext') + ' ' + data.statusText + '\n'
                  : '') +
                (data.url ? t('view.common.message.url') + ' ' + data.url + '\n' : '') +
                (data.stack ? t('view.common.message.stack') + '\n' + data.stack + '\n' : ''),
            )
          }
          aria-label="delete"
          size="small"
        >
          <FileCopyIcon fontSize="inherit" />
        </IconButton>
        {data.status && (
          <div>
            <span>
              {t('view.common.message.status')} {data.status}
            </span>
          </div>
        )}
        {data.statusText && (
          <div>
            <span>
              {t('view.common.message.statustext')} {data.statusText}
            </span>
          </div>
        )}
        {data.url && (
          <div>
            <span>
              {t('view.common.message.url')} {data.url}
            </span>
          </div>
        )}
        {data.stack &&
          // <div>
          //   <span>{t('view.common.message.stack')}</span>
          //   <pre style={{ overflow: 'auto', margin: 0, maxHeight: 260 }}>{data.stack}</pre>
          // </div>
          (data.status && data.status === 401 ? (
            <div>
              <span>{t('view.common.message.msg')}</span> {JSON.parse(data.stack).message}
            </div>
          ) : (
            <details
              style={{
                whiteSpace: 'pre-wrap',
                overflow: 'auto',
                marginTop: 5,
                minHeight: 20,
              }}
            >
              <summary style={{ outline: 'none' }}>{t('view.common.message.stack')}</summary>
              <pre style={{ overflow: 'auto', margin: 0, maxHeight: 260 }}>{data.stack}</pre>
            </details>
          ))}
        {/*{data.stack && (*/}
        {/*  <pre style={{ overflow: 'auto' }}>*/}
        {/*    {JSON.stringify(*/}
        {/*      JSON.parse(*/}
        {/*        data.stack*/}
        {/*          .toString()*/}
        {/*          .replace('Stack: {', '{')*/}
        {/*          .replaceAll('\\n    ', '')*/}
        {/*          .replaceAll('\\n', ''),*/}
        {/*      ),*/}
        {/*      null,*/}
        {/*      2,*/}
        {/*    )}*/}
        {/*  </pre>*/}
        {/*)}*/}
      </div>
    )
  } else {
    // Return the error message as a string if no structured error details are found
    return data.toString()
  }
}
