// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { Fragment, useEffect, useMemo } from 'react'
import { fetchAllUnitaryAnalysesForBatch } from '../../redux/prefetch/actions'
import {
  CACHE_IS_USED,
  DATABASE,
  DEFAULT_CACHE_POLLING,
  GET_LAST_CACHE_MODIFICATION,
  PREFETCH_LIMIT,
} from '../../utils/config'

/**
 * Prefetch component that handles prefetching of data based on the current route.
 * Wrapped with `withRouter` to access routing-related props.
 *
 * @param {object} props - The properties passed to the component.
 * @returns {React.Fragment} A React Fragment.
 */
const Prefetch = withRouter((props) => {
  const dispatch = useDispatch()
  const cachePrefetching = localStorage.getItem('SETTINGS_cache_prefetch') === 'true'
  const path = props.location.pathname

  /**
   * Selector to get the login popup error state from the Redux store.
   * @returns {boolean} True if there is a login popup error, otherwise false.
   */
  const errorLogin = useSelector((state) => state.settings.loginPopup)

  /**
   * Selector to get the configuration service error state from the Redux store.
   * @returns {boolean} True if there is a configuration service error, otherwise false.
   */
  const errorService = useSelector((state) => state.settings.configurationService)

  /**
   * Selector to get the configuration database error state from the Redux store.
   * @returns {boolean} True if there is a configuration database error, otherwise false.
   */
  const errorDatabase = useSelector((state) => state.settings.configurationDatabase.length > 0)

  /**
   * Memoized value that combines all error states.
   * @returns {boolean} True if any of the error states are true, otherwise false.
   */
  const errorConfig = useMemo(() => {
    return errorLogin || errorService || errorDatabase
  }, [errorLogin, errorService, errorDatabase])

  /**
   * Memoized value that extracts the batchId from the current URL path.
   * @returns {string|null} The extracted batchId or null if not found.
   */
  const batchId = useMemo(() => {
    // Extract the batchId from URL
    const match = path.match(
      `^/${DATABASE()}/(?:batch/|global/|)([a-zA-Z0-9]*)(?:/(analysis|compound)/([a-zA-Z0-9]*))?(?:/([a-zA-Z0-9]*))?$`,
    )
    return match && match !== '' && match[1] !== 'reload' ? match[1] : null
  }, [path])

  /**
   * Retrieves the polling interval for prefetching from localStorage or uses the default value.
   *
   * @returns {number} The polling interval in milliseconds.
   */
  function getPrefetchPolling() {
    return (
      (parseInt(localStorage.getItem('SETTINGS_cache_polling'), 10) || DEFAULT_CACHE_POLLING) * 1000
    )
  }

  /**
   * Fetches all unitary analyses for a given batch.
   *
   * @param {string} batchId - The ID of the batch to fetch data for.
   * @param {object} isActiveRef - A reference object to control if the fetching is still active.
   */
  const fetchData = (batchId, isActiveRef) => {
    // Vérifie si la récupération est toujours active
    if (!isActiveRef.current) return
    dispatch(fetchAllUnitaryAnalysesForBatch(batchId))
      .then((numbersObjectsDownloaded) => {
        if (numbersObjectsDownloaded === PREFETCH_LIMIT()) {
          // Continue fetching immediately
          if (isActiveRef.current) fetchData(batchId, isActiveRef)
        } else if (numbersObjectsDownloaded < PREFETCH_LIMIT()) {
          // Test again after 10 seconds
          setTimeout(function () {
            if (isActiveRef.current) fetchData(batchId, isActiveRef)
          }, getPrefetchPolling())
        }
      })
      .catch(() => {
        setTimeout(function () {
          if (isActiveRef.current) fetchData(batchId, isActiveRef)
        }, getPrefetchPolling())
      })
  }

  /**
   * useEffect hook to handle data prefetching based on the current route and cache settings.
   * It sets up a flag to control if the fetching loop should continue and performs cleanup on unmount.
   */
  useEffect(() => {
    // Flag pour contrôler si la boucle doit continuer
    const isActiveRef = { current: true }
    if (batchId && cachePrefetching && CACHE_IS_USED() && !errorConfig) {
      // In case of the batch changed, we have to reset the lastModification index
      if (batchId !== GET_LAST_CACHE_MODIFICATION()?.['batchId']) {
        localStorage.setItem(
          'CACHE_last_modificationDate',
          JSON.stringify({
            modificationDate: 0,
            batchId: batchId,
          }),
        )
      }
      fetchData(batchId, isActiveRef)
    } else {
      isActiveRef.current = false
    }
    return () => {
      // Cleanup on unmount
      // Lorsque le composant est démonté ou que batchId change, on arrête la récupération
      isActiveRef.current = false
    }
  }, [batchId, cachePrefetching, errorConfig])

  return <Fragment />
})
export default Prefetch
