// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useRef } from 'react'
import { makeStyles, Tooltip, withStyles } from '@material-ui/core'
import { getTimeEndDay, getTimeStartDay } from '../../utils/utilities'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import Chip from '@material-ui/core/Chip'
import WarningIcon from '@material-ui/icons/Warning'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import { PUBLIC_URL } from '../../utils/config'
import Grid from '@material-ui/core/Grid'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Badge from '@material-ui/core/Badge'
import clsx from 'clsx'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import Typography from '@material-ui/core/Typography'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineDot from '@material-ui/lab/TimelineDot'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import Paper from '@material-ui/core/Paper'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import Avatar from '@material-ui/core/Avatar'
import LinearProgress from '@material-ui/core/LinearProgress'
import { fetchGetTasks, fetchGetToolbarTasks } from '../../redux/tasks/actions'
import { withRouter } from 'react-router-dom'
import { alpha } from '@material-ui/core/styles'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { TaskTooltipDAC, TransitionBottom } from './Style'
import { displayPopupMessage } from './Message'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import FileCopyIcon from '@mui/icons-material/FileCopy'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  settingsInfoText: {
    paddingTop: 15,
    paddingBottom: 10,
  },
  searchTask: {
    position: 'relative',
    marginRight: '12px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.06),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.06),
    },
    '& div:first-child': {
      color: alpha(theme.palette.common.black, 0.7),
    },
    '&:hover div:first-child': {
      color: theme.palette.common.black,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    // pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '16ch',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
      },
    },
  },
  clearIconTask: {
    color: alpha(theme.palette.common.black, 0.7),
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.common.black,
    },
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  loading: {
    marginRight: 25,
  },
  moveChip: {
    margin: 3,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  timeLineDotEnd: {
    top: 18,
    width: 40,
    height: 40,
    padding: 4,
    color: theme.palette.common.success,
  },
  timeLineDotEndError: {
    top: 18,
    width: 40,
    height: 40,
    padding: 4,
    color: theme.palette.common.error,
  },
  successState: {
    backgroundColor: 'white',
    borderColor: theme.palette.common.success,
  },
  errorState: {
    backgroundColor: 'white',
    borderColor: theme.palette.common.error,
  },
  loadingStateDot: {
    backgroundColor: 'white',
  },
  loadingState: {
    backgroundColor: 'white',
    borderColor: theme.palette.primary.main,
  },
  errorWarning: {
    color: theme.palette.common.error,
  },
  taskAllFinishedIcon: {
    color: 'inherit',
    border: '3px dashed',
    borderColor: theme.palette.secondary.main,
    padding: 4,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  datePickers: {
    '& input': {
      padding: '12px 14px',
      width: 90,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
  fromCtn: {
    '& .MuiInputBase-root': {
      borderRadius: '4px 0px 0px 4px',
    },
    '& fieldset': {
      borderRight: 0,
    },
  },
  toCtn: {
    '& .MuiInputBase-root': {
      borderRadius: '0px 4px 4px 0px',
    },
  },
  avatarTask: {
    float: 'left',
    height: 26,
    width: 26,
    marginRight: 8,
    fontSize: 'inherit',
    backgroundColor: theme.palette.primary.main,
  },
  badgeCountRect: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: alpha(theme.palette.primary.main, 0.6),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountRectSelected: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: alpha(theme.palette.secondary.main, 0.8),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  filterButton: {
    borderRadius: 18,
    border: '1px solid #c5c5c5',
    backgroundColor: 'rgb(224 224 224)',
    marginRight: 0,
    marginLeft: 15,
    width: 50,
    height: 34,
    padding: 8,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  notSelected: {
    '&:hover': {
      // color: theme.palette.primary.main,
    },
  },
  scrollToBottom: {
    background: theme.palette.primary.main,
    width: 50,
    height: 50,
    position: 'absolute',
    bottom: 80,
    right: 28,
    borderRadius: 25,
    color: 'white',
  },
}))
const DashboardActivitiesComponent = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [openTask, setOpenTask] = React.useState(false)
  const [searchTask, setSearchTask] = React.useState('')
  const [fromDate, setFromDate] = React.useState(new Date(getTimeStartDay() - 604800000))
  const [toDate, setToDate] = React.useState(new Date(getTimeEndDay()))
  const [displayClearTask, setDisplayClearTask] = React.useState(false)
  const [timerTask, setTimerTask] = React.useState(null)
  const [runningChecked, setRunningChecked] = React.useState(true)
  const [finishedChecked, setFinishedChecked] = React.useState(true)
  const [errorChecked, setErrorChecked] = React.useState(true)
  const [displayScrollToBottom, setDisplayScrollToBottom] = React.useState(false)

  const searchTaskRef = React.createRef()
  const timelineRef = useRef(null)

  const hideDefaultState = props.hideDefaultState

  const scrollToBottom = () => {
    if (timelineRef.current) {
      // TODO Add update displayScrollToBottom on container size change
      // timelineRef.current.parentElement.onresize = function (e) {
      //   console.log('RESIZE')
      // }
      // timelineRef.current.parentElement.onchange = function (e) {
      //   console.log('CHANGE')
      // }
      timelineRef.current.parentElement.onscroll = function (e) {
        setDisplayScrollToBottom(
          Math.abs(
            timelineRef.current.parentElement.offsetHeight +
              timelineRef.current.parentElement.scrollTop -
              timelineRef.current.parentElement.scrollHeight,
          ) > 15,
        )
      }
      timelineRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    } else {
      setTimeout(scrollToBottom, 500)
    }
  }

  useEffect(
    function () {
      dispatch(fetchGetTasks(toDate, fromDate, runningChecked, finishedChecked, errorChecked))
      dispatch(fetchGetToolbarTasks())
    },
    [dispatch],
  )

  const filterFunction = (list) => {
    return list
      .filter((item) => item.date <= toDate && item.date >= fromDate)
      .filter(function (item) {
        switch (item.state.value) {
          case 'running':
            return runningChecked
          case 'success':
            return finishedChecked
          case 'error':
            return errorChecked
          default:
            return false
        }
      })
      .filter((task) =>
        // (task.operator && task.operator.toLowerCase().includes(searchTask.toLowerCase())) ||
        // (task.items &&
        //   task.items
        //     .map(
        //       (item) =>
        //         item.name +
        //         ',' +
        //         (item.analysis ? item.analysis.name : '') +
        //         ',' +
        //         (item.batch ? item.batch.name : '') +
        //         ',' +
        //         item.action +
        //         ',' +
        //         (item.compound ? item.compound.name : ''),
        //     )
        //     .join()
        //     .toLowerCase()
        //     .includes(searchTask.toLowerCase())),
        JSON.stringify(task).toLowerCase().includes(searchTask.toLowerCase()),
      )
  }

  // Activities window
  const allTasks = useSelector((state) => filterFunction(state.tasks.tasks))
  const totalTasks = useSelector((state) => state.tasks.totalTasks)
  const tasks = useSelector((state) => state.tasks.tasks.filter((task) => task.percentage !== 100))
  const tasksLength = useSelector(
    (state) => state.tasks.tasks.filter((task) => task.percentage !== 100).length,
  )
  const allTasksLength = useSelector((state) => filterFunction(state.tasks.tasks).length)

  // Activity component for polling
  const allPollingTasks = useSelector((state) => filterFunction(state.tasks.pollingTasks))
  const pollingTasks = useSelector((state) =>
    state.tasks.pollingTasks.filter((task) => task.percentage !== 100),
  )
  const pollingTasksLength = useSelector(
    (state) => state.tasks.pollingTasks.filter((task) => task.percentage !== 100).length,
  )

  // const handleClose = (event, save) => {
  //   closeFct()
  //   if (save) {
  //     resetUnitaryCalibration(event, calibration, dispatch)
  //   }
  // }

  useEffect(
    function () {
      if (totalTasks > 100 && openTask) {
        displayPopupMessage(
          dispatch,
          'warning',
          t('view.common.dashboard_activities.warning_title'),
          <Fragment>
            <div>
              <Trans i18nKey="view.common.dashboard_activities.warning_msg1">
                {{ val: totalTasks }} matching activities.
              </Trans>
            </div>
            <div>{t('view.common.dashboard_activities.warning_msg2')}</div>
            <div>{t('view.common.dashboard_activities.warning_msg3')}</div>
          </Fragment>,
          5000,
        )
      }
    },
    [totalTasks],
  )

  const handleError = () => {
    dispatch(fetchGetTasks(toDate, fromDate, runningChecked, finishedChecked, !errorChecked))
    setErrorChecked(!errorChecked)
  }

  const handleFinished = () => {
    dispatch(fetchGetTasks(toDate, fromDate, runningChecked, !finishedChecked, errorChecked))
    setFinishedChecked(!finishedChecked)
  }

  const handleRunning = () => {
    dispatch(fetchGetTasks(toDate, fromDate, !runningChecked, finishedChecked, errorChecked))
    setRunningChecked(!runningChecked)
  }

  function handleOpenLoading() {
    dispatch(fetchGetTasks(toDate, fromDate, runningChecked, finishedChecked, errorChecked))
    setOpenTask(true)
    scrollToBottom()
  }

  function handleCloseTask() {
    setOpenTask(false)
  }

  function clearTaskSearch() {
    searchTaskRef.current.children[0].value = ''
    setSearchTask('')
    setDisplayClearTask(false)
  }

  function handleDateChange(date, origin) {
    switch (origin) {
      case 'from':
        setFromDate(date)
        dispatch(fetchGetTasks(toDate, date, runningChecked, finishedChecked, errorChecked))
        break
      case 'to':
        setToDate(date)
        dispatch(fetchGetTasks(date, fromDate, runningChecked, finishedChecked, errorChecked))
        break
      default:
        break
    }
  }

  function onSearchTask(e) {
    const newValue = e.target.value
    clearTimeout(timerTask)
    const newTimer = setTimeout(function () {
      setSearchTask(newValue)
    }, 1500)
    setTimerTask(newTimer)
    setDisplayClearTask(newValue !== '')
  }

  function applyDateFilter(event, filter) {
    let timeToRetrieve = 0
    switch (filter) {
      case 'year':
        timeToRetrieve = 31536000000
        break
      case 'month':
        timeToRetrieve = 2628000000
        break
      case 'week':
        timeToRetrieve = 604800000
        break
      default:
        break
    }
    const newFromDate = new Date(getTimeStartDay() - timeToRetrieve)
    const newToDate = new Date(getTimeEndDay())
    setFromDate(newFromDate)
    setToDate(newToDate)
    dispatch(fetchGetTasks(newToDate, newFromDate, runningChecked, finishedChecked, errorChecked))
  }

  // const TaskTooltipDAC = withStyles({
  //   tooltip: {
  //     maxWidth: 'none',
  //     maxHeight: 800,
  //     overflow: 'scroll',
  //   },
  // })(Tooltip)
  function onClipboardButtonClick(event, text) {
    event.stopPropagation()
    event.preventDefault()
    if (!navigator.clipboard) {
      let textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        const successful = document.execCommand('copy')
        const msg = successful
          ? t('view.errorBoundary.copy.succes')
          : t('view.errorBoundary.copy.unsucces')
        console.log(t('view.errorBoundary.copy.return_msg') + ' ' + msg)
      } catch (err) {
        console.error(t('view.errorBoundary.copy.error_msg'), err)
      }

      document.body.removeChild(textArea)
      return
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log(t('view.errorBoundary.copy.async_succes_msg'))
      },
      function (err) {
        console.error(t('view.errorBoundary.copy.error_msg'), err)
      },
    )
  }

  return (
    <Fragment>
      <Dialog
        open={openTask}
        onClose={handleCloseTask}
        TransitionComponent={TransitionBottom}
        aria-labelledby="dialog-title-user"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Box className={classes.header}>
            <CardMedia
              className={classes.cardImg}
              component="img"
              alt="header image"
              height="130"
              image={PUBLIC_URL + '/images/user_preferences.jpg'}
              title="Header image"
            />
            <span className={classes.titleCard}>{t('view.common.dashboard_activities.title')}</span>
          </Box>
        </DialogTitle>
        <DialogContent
          style={{
            zIndex: 1,
            overflow: 'hidden',
            minHeight: 90,
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          }}
        >
          {/*{allTasksLength === 0 && (*/}
          {/*  <DialogContentText className={classes.settingsInfoText}>*/}
          {/*    <div>There are no tasks to display at the moment.</div>*/}
          {/*  </DialogContentText>*/}
          {/*)}*/}
          <div className={classes.settingsInfoText}>
            <Grid container spacing={1} direction="row" justifyContent="space-between">
              <Grid item style={{ padding: 0 }} className={classes.datePickers}>
                <ButtonGroup
                  // variant="text"
                  color="primary"
                  aria-label="text primary button group"
                  style={{ marginTop: 16, marginRight: 30 }}
                >
                  <Button
                    style={{
                      padding: '9px 16px 8px',
                      color: '#757575',
                      borderColor: 'rgb(196 196 196)',
                    }}
                    onClick={(event) => applyDateFilter(event, 'year')}
                  >
                    {t('view.common.dashboard_activities.year')}
                  </Button>
                  <Button
                    style={{
                      padding: '9px 16px 8px',
                      color: '#757575',
                      borderColor: 'rgb(196 196 196)',
                    }}
                    onClick={(event) => applyDateFilter(event, 'month')}
                  >
                    {t('view.common.dashboard_activities.month')}
                  </Button>
                  <Button
                    style={{
                      padding: '9px 16px 8px',
                      color: '#757575',
                      borderColor: 'rgb(196 196 196)',
                    }}
                    onClick={(event) => applyDateFilter(event, 'week')}
                  >
                    {t('view.common.dashboard_activities.week')}
                  </Button>
                </ButtonGroup>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.fromCtn}
                    style={{ marginRight: -1 }}
                    disableToolbar
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-from"
                    label={t('view.common.dashboard_activities.from')}
                    value={fromDate}
                    onChange={(e) => handleDateChange(e, 'from')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardDatePicker
                    style={{ marginRight: 15 }}
                    className={classes.toCtn}
                    disableToolbar
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-to"
                    label={t('view.common.dashboard_activities.to')}
                    value={toDate}
                    onChange={(e) => handleDateChange(e, 'to')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item style={{ padding: '17px 0px 0px' }}>
                <Tooltip
                  key={'tooltip_running'}
                  arrow
                  title={
                    <div style={{ fontSize: 13, padding: 5 }}>
                      {t('view.common.dashboard_activities.filter_running')}
                    </div>
                  }
                >
                  <Badge
                    className={clsx(
                      classes.badgeCountRect,
                      runningChecked ? classes.badgeCountRectSelected : null,
                    )}
                    max={10000}
                    // showZero={true}
                    overlap="circular"
                    badgeContent={0}
                  >
                    <IconButton
                      aria-label="running"
                      className={clsx(
                        classes.filterButton,
                        runningChecked ? classes.selected : classes.notSelected,
                      )}
                      onClick={handleRunning}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Badge>
                </Tooltip>
                <Tooltip
                  key={'tooltip_finish'}
                  arrow
                  title={
                    <div style={{ fontSize: 13, padding: 5 }}>
                      {t('view.common.dashboard_activities.filter_finished')}
                    </div>
                  }
                >
                  <Badge
                    className={clsx(
                      classes.badgeCountRect,
                      finishedChecked ? classes.badgeCountRectSelected : null,
                    )}
                    max={10000}
                    // showZero={true}
                    overlap="circular"
                    badgeContent={0}
                  >
                    <IconButton
                      aria-label="running"
                      className={clsx(
                        classes.filterButton,
                        finishedChecked ? classes.selected : classes.notSelected,
                      )}
                      onClick={handleFinished}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Badge>
                </Tooltip>
                <Tooltip
                  key={'tooltip_error'}
                  arrow
                  title={
                    <div style={{ fontSize: 13, padding: 5 }}>
                      {t('view.common.dashboard_activities.filter_failed')}
                    </div>
                  }
                >
                  <Badge
                    className={clsx(
                      classes.badgeCountRect,
                      errorChecked ? classes.badgeCountRectSelected : null,
                    )}
                    max={10000}
                    // showZero={true}
                    overlap="circular"
                    badgeContent={0}
                  >
                    <IconButton
                      aria-label="running"
                      className={clsx(
                        classes.filterButton,
                        errorChecked ? classes.selected : classes.notSelected,
                      )}
                      onClick={handleError}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Badge>
                </Tooltip>
              </Grid>
              <Grid style={{ padding: '17px 0px 0px' }}>
                <div className={classes.searchTask}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder={t('view.common.dashboard_activities.search')}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={onSearchTask}
                    ref={searchTaskRef}
                  />
                  {displayClearTask && (
                    <IconButton
                      className={clsx(classes.clearIconTask, classes.searchIcon)}
                      aria-label="clear"
                      onClick={clearTaskSearch}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogContent
          style={{
            zIndex: 0,
            backgroundColor: '#e8e8e847',
          }}
        >
          <Timeline align="left" style={{ marginTop: 0, marginBottom: 0 }} ref={timelineRef}>
            {allTasks.map((task, i) => (
              <TimelineItem key={task._id + '_' + i}>
                <TimelineOppositeContent style={{ flex: 0.3 }}>
                  <Typography variant="body2" color="textSecondary" style={{ marginTop: 6 }}>
                    {new Date(task.date).toLocaleString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {task.operator}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    style={{ padding: 0 }}
                    className={
                      task.state.value === 'success'
                        ? classes.successState
                        : task.state.value === 'error'
                        ? classes.errorState
                        : classes.loadingStateDot
                    }
                  >
                    {task.percentage !== 100 && <CircularProgress thickness={6} color="primary" />}
                    {task.state.value === 'success' && (
                      <CheckIcon className={classes.timeLineDotEnd} />
                    )}
                    {task.state.value === 'error' && (
                      <CloseIcon className={classes.timeLineDotEndError} />
                    )}
                  </TimelineDot>
                  {allTasks.length !== i + 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Paper
                    elevation={3}
                    style={{ borderLeftWidth: 4, borderLeftStyle: 'solid' }}
                    className={clsx(
                      classes.paper,
                      task.state.value === 'success'
                        ? classes.successState
                        : task.state.value === 'error'
                        ? classes.errorState
                        : classes.loadingState,
                    )}
                  >
                    <Typography variant="h6" component="h1">
                      <div style={{ float: 'left' }}>
                        {task.title}
                        <ArrowRightAltIcon
                          style={{ margin: '0px 10px 2px', verticalAlign: 'middle' }}
                        />
                        {task.operation}
                      </div>
                      <div style={{ float: 'right' }}>
                        {task.percentage !== 100 && `${task.percentage}%`}
                        {task.state.value === 'error' && (
                          <Tooltip
                            arrow
                            interactive
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {task.state.message.hasOwnProperty('status') ||
                                task.state.message.hasOwnProperty('statusText') ||
                                task.state.message.hasOwnProperty('url') ||
                                task.state.message.hasOwnProperty('stack') ? (
                                  <span>
                                    {task.state.message.hasOwnProperty('stack') && (
                                      <IconButton
                                        style={{
                                          color: 'white',
                                          position: 'absolute',
                                          float: 'right',
                                          right: 20,
                                        }}
                                        onClick={(event) =>
                                          onClipboardButtonClick(
                                            event,
                                            (task.state.message.hasOwnProperty('status')
                                              ? t('view.common.message.status') +
                                                ' ' +
                                                task.state.message.status +
                                                '\n'
                                              : '') +
                                              (task.state.message.hasOwnProperty('statusText')
                                                ? t('view.common.message.statustext') +
                                                  ' ' +
                                                  task.state.message.statusText +
                                                  '\n'
                                                : '') +
                                              (task.state.message.hasOwnProperty('url')
                                                ? t('view.common.message.url') +
                                                  ' ' +
                                                  task.state.message.url +
                                                  '\n'
                                                : '') +
                                              (task.state.message.hasOwnProperty('stack')
                                                ? t('view.common.message.stack') +
                                                  '\n' +
                                                  task.state.message.stack +
                                                  '\n'
                                                : ''),
                                          )
                                        }
                                        aria-label="delete"
                                        size="small"
                                      >
                                        <FileCopyIcon fontSize="inherit" />
                                      </IconButton>
                                    )}
                                    {task.state.message.hasOwnProperty('status') && (
                                      <div>
                                        <span>{t('view.common.message.status')}</span>{' '}
                                        {task.state.message.status}
                                      </div>
                                    )}
                                    {task.state.message.hasOwnProperty('statusText') && (
                                      <div>
                                        <span>{t('view.common.message.statustext')}</span>{' '}
                                        {task.state.message.statusText}
                                      </div>
                                    )}
                                    {task.state.message.hasOwnProperty('url') && (
                                      <div>
                                        <span>{t('view.common.message.url')}</span>{' '}
                                        {task.state.message.url}
                                      </div>
                                    )}
                                    {task.state.message.hasOwnProperty('stack') && (
                                      <div>
                                        <span>{t('view.common.message.stack')}</span>
                                        <pre
                                          style={{
                                            overflow: 'auto',
                                            margin: 0,
                                            background: '#ffffff4f',
                                            borderRadius: 5,
                                            padding: 5,
                                            maxHeight: 260,
                                          }}
                                        >
                                          {task.state.message.stack}
                                        </pre>
                                      </div>
                                    )}
                                  </span>
                                ) : (
                                  task.state.message.toString()
                                )}
                              </div>
                            }
                          >
                            <WarningIcon className={classes.errorWarning} />
                          </Tooltip>
                        )}
                      </div>
                    </Typography>
                    <div
                      style={{ maxHeight: 80, overflow: 'auto', fontSize: '1rem', lineHeight: 1.5 }}
                    >
                      {task.action === 'retrieve_batch' && (
                        <span>
                          <div style={{ float: 'left' }}>
                            {t('view.common.dashboard_activities.application')}:{' '}
                            {task.items[0].applicationName}
                          </div>
                          {task.percentage !== 100 && (
                            <div style={{ float: 'right' }}>{task.items[0].status}</div>
                          )}
                          <div style={{ float: 'left', marginLeft: 50 }}>
                            {t('view.common.dashboard_activities.num')}: {task.items[0].num}
                          </div>
                          {task.items[0].inputs.filter((input) => input.name === 'mode')[0] && (
                            <div style={{ float: 'left', marginLeft: 50 }}>
                              {t('view.common.dashboard_activities.mode')}:{' '}
                              {
                                task.items[0].inputs.filter((input) => input.name === 'mode')[0]
                                  .value
                              }
                            </div>
                          )}
                        </span>
                      )}
                      {task.action !== 'retrieve_batch' &&
                        task.items &&
                        task.items.map((item) => (
                          <Tooltip
                            key={`tooltip_${item.id}`}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {item.analysis && item.analysis.name && (
                                  <div>
                                    {t('view.common.dashboard_activities.analysis')}:{' '}
                                    {item.analysis.name}
                                  </div>
                                )}
                                {item.batch && item.batch.name && (
                                  <div>
                                    {t('view.common.dashboard_activities.batch')}: {item.batch.name}
                                  </div>
                                )}
                                {item.compound && item.compound.name && (
                                  <div>
                                    {t('view.common.dashboard_activities.compound')}:{' '}
                                    {item.compound.name}
                                  </div>
                                )}
                              </div>
                            }
                          >
                            <Chip
                              key={`chip_${item.id}`}
                              variant="outlined"
                              size="small"
                              style={{ margin: 3, border: 'none' }}
                              label={item.name}
                              className={classes.moveChip}
                            />
                          </Tooltip>
                        ))}
                    </div>
                    <div
                      style={{ maxHeight: 80, overflow: 'auto', fontSize: '1rem', lineHeight: 1.5 }}
                    >
                      {task.action === 'retrieve_batch' && (
                        <span>
                          {task.items &&
                            task.items.map((item) => (
                              <Tooltip
                                key={`tooltip_${item.id}`}
                                arrow
                                title={
                                  <div style={{ fontSize: 13, padding: 5 }}>
                                    {item.inputs &&
                                      item.inputs.filter(
                                        (input) => input.name === 'batch_id',
                                      )[0] && (
                                        <div>
                                          <b>{t('view.common.dashboard_activities.batch_id')}:</b>{' '}
                                          <i>
                                            {
                                              item.inputs.filter(
                                                (input) => input.name === 'batch_id',
                                              )[0].value
                                            }
                                          </i>
                                        </div>
                                      )}
                                    {item.inputs &&
                                      item.inputs.filter(
                                        (input) => input.name === 'batch_device',
                                      )[0] && (
                                        <div>
                                          <b>
                                            {t('view.common.dashboard_activities.batch_device')}:
                                          </b>{' '}
                                          <i>
                                            {
                                              item.inputs.filter(
                                                (input) => input.name === 'batch_device',
                                              )[0].value
                                            }
                                          </i>
                                        </div>
                                      )}
                                    {item.inputs &&
                                      item.inputs.filter(
                                        (input) => input.name === 'batch_date',
                                      )[0] && (
                                        <div>
                                          <b>{t('view.common.dashboard_activities.batch_date')}:</b>{' '}
                                          <i>
                                            {
                                              item.inputs.filter(
                                                (input) => input.name === 'batch_date',
                                              )[0].value
                                            }
                                          </i>
                                        </div>
                                      )}
                                    {item.inputs &&
                                      item.inputs.filter(
                                        (input) => input.name === 'batch_type',
                                      )[0] && (
                                        <div>
                                          <b>{t('view.common.dashboard_activities.batch_type')}:</b>{' '}
                                          <i>
                                            {
                                              item.inputs.filter(
                                                (input) => input.name === 'batch_type',
                                              )[0].value
                                            }
                                          </i>
                                        </div>
                                      )}
                                    {item.inputs &&
                                      item.inputs.filter(
                                        (input) => input.name === 'scan_dir',
                                      )[0] && (
                                        <div>
                                          <b>{t('view.common.dashboard_activities.directory')}:</b>{' '}
                                          <i>
                                            {
                                              item.inputs.filter(
                                                (input) => input.name === 'scan_dir',
                                              )[0].value
                                            }
                                          </i>
                                        </div>
                                      )}
                                  </div>
                                }
                              >
                                <Chip
                                  key={`chip_${item.id}`}
                                  variant="outlined"
                                  size="small"
                                  style={{ margin: 3, border: 'none' }}
                                  label={
                                    item.inputs &&
                                    item.inputs.filter((input) => input.name === 'batch_name')[0]
                                      ? item.inputs.filter(
                                          (input) => input.name === 'batch_name',
                                        )[0].value
                                      : t('view.common.dashboard_activities.unknown')
                                  }
                                  className={classes.moveChip}
                                />
                              </Tooltip>
                            ))}
                        </span>
                      )}
                    </div>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
          {timelineRef.current && displayScrollToBottom && (
            <div className={classes.scrollToBottom}>
              <IconButton
                aria-label="scroll to bottom"
                onClick={() =>
                  timelineRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
                }
              >
                <ArrowDownwardIcon style={{ color: 'white' }} />
              </IconButton>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            zIndex: 1,
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <Button onClick={handleCloseTask} color="primary">
            {t('view.common.dashboard_activities.close')}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.loading}>
        <Badge badgeContent={pollingTasksLength} overlap="circular" color="secondary">
          {pollingTasksLength === 0 && !hideDefaultState && (
            <Tooltip
              arrow
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  {t('view.common.dashboard_activities.all_finished')}
                </div>
              }
            >
              <IconButton
                style={{ color: 'inherit', padding: 0 }}
                aria-label="loading"
                onClick={handleOpenLoading}
              >
                <CheckIcon className={classes.taskAllFinishedIcon} />
              </IconButton>
            </Tooltip>
          )}
          {pollingTasksLength !== 0 && (
            <Fragment>
              <CircularProgress thickness={6} color="secondary" />
              <TaskTooltipDAC
                interactive={pollingTasks.length > 14}
                arrow
                title={
                  <div style={{ width: 300, margin: '8px 5px 5px' }}>
                    {pollingTasks.map((task) => (
                      <div
                        key={task.id}
                        style={{
                          backgroundColor: 'rgba(255,255,255,0.2)',
                          fontSize: 13,
                          color: 'inherit',
                          padding: 10,
                          marginBottom: 10,
                          borderRadius: 5,
                        }}
                      >
                        <Avatar alt="User" className={classes.avatarTask}>
                          {task.operator.substring(0, 2).toLocaleUpperCase()}
                        </Avatar>
                        <div style={{ height: 20 }}>
                          <div
                            style={{
                              float: 'left',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              width: 'calc(100% - 110px)',
                            }}
                          >
                            {task.title}
                          </div>
                          <div style={{ float: 'right' }}>
                            {getTimeStartDay() >= task.date
                              ? new Date(task.date).toLocaleDateString()
                              : new Date(task.date).toLocaleTimeString()}
                          </div>
                        </div>
                        <div>
                          <LinearProgress
                            style={{ height: 6, borderRadius: 4 }}
                            color="secondary"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                }
              >
                <IconButton
                  aria-label="loading"
                  style={{
                    position: 'absolute',
                    top: -4,
                    left: -4,
                    animation: 'spin 2s linear infinite',
                    color: 'inherit',
                  }}
                  onClick={handleOpenLoading}
                >
                  <SettingsIcon />
                </IconButton>
              </TaskTooltipDAC>
            </Fragment>
          )}
        </Badge>
      </div>
    </Fragment>
  )
})
export default DashboardActivitiesComponent
