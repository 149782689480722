// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useMemo, useRef } from 'react'
import { useRouteMatch, withRouter } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { displayPopupMessage } from './common/Message'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import Typography from '@material-ui/core/Typography'
import { t } from 'i18next'
import { genericStyles, ExcelIcon } from './common/Style'
import { useTheme } from '@mui/material/styles'
import { useGridApiRef } from '@mui/x-data-grid'
import { fetchGetLogs, fetchClearLogs, FILTER_LIST } from '../redux/logs/actions'
import { fetchChangeSettings } from '../redux/settings/actions'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import AdbIcon from '@mui/icons-material/Adb'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import { copyToClipboard, getTimeEndDay, getTimeStartDay } from '../utils/utilities'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import TerminalIcon from '@mui/icons-material/Terminal'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import ListItemText from '@material-ui/core/ListItemText'
import Stack from '@mui/material/Stack'
import Button from '@material-ui/core/Button'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Checkbox } from '@mui/material'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InputIcon from '@mui/icons-material/Input'
import Chip from '@material-ui/core/Chip'
import ReplayIcon from '@mui/icons-material/Replay'
import ErrorBoundaryGuard from './ErrorBoundaryGuard'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { alpha, makeStyles } from '@material-ui/core'
import { CustomNoRowsOverlay } from './batch/Tools'
import { Trans } from 'react-i18next'
import Loading from './Loading'
import Polling from './common/Polling'
import { DATABASE, DRAWER_WIDTH, PUBLIC_URL } from '../utils/config'
import { fetchGetCodDriveConfigs } from '../redux/codDriveConfiguration/actions'
import SplitterLayout from 'react-splitter-layout'
import JSONTree from 'react-json-tree'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { CardActionArea } from '@mui/material'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { palette } from '@material-ui/system'
import DatabaseInput from './common/DatabaseInput'
import DatabaseCmp from './common/DatabaseCmp'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import DataObjectIcon from '@mui/icons-material/DataObject'
import Divider from '@material-ui/core/Divider'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import { fetchBatches } from '../redux/batches/actions'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { fetchGlobalConfigurations } from '../redux/global/actions'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { getBatchRawById } from '../redux/batches/selectors'
import { fetchGetBatchTasks } from '../redux/tasks/actions'
import DomainIcon from '@material-ui/icons/Domain'
import FolderIcon from '@material-ui/icons/Folder'

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  backgroundImg: {
    position: 'fixed',
    marginLeft: 'calc(50% - 400px)',
  },
  container: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    position: 'fixed',
  },
  notFound: {
    textAlign: 'center',
    position: 'relative',
    fontFamily: 'monospace',
    fontSize: 135,
    height: 220,
  },
  notFound1: {
    textAlign: 'center',
    position: 'relative',
    fontFamily: 'monospace',
    fontSize: 40,
    marginTop: 270,
  },
  notFound2: {
    textAlign: 'center',
    position: 'relative',
    fontFamily: 'monospace',
    fontSize: 20,
    marginBottom: 100,
  },
  buttonHome: {
    animation: 'heartbeat 1.5s infinite',
    marginLeft: 'calc(50% - 73px)',
    width: 150,
    height: 150,
    borderRadius: 75,
    backgroundColor: '#bfbfbf',
    '&:hover': {
      animation: 'none',
      backgroundColor: '#6d6c6c',
    },
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    '& .heartEmpty': {
      opacity: 1,
      color: 'white',
      position: 'absolute',
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '& .heartFull': {
      opacity: 0,
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&:hover .heartEmpty': {
      opacity: 0,
    },
    '&:hover .heartFull': {
      opacity: 1,
      color: 'red',
    },
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: 64,
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    position: 'absolute',
    zIndex: 1000000,
  },
  content: {
    width: '100%',
    position: 'relative',
    height: 'calc(100% - 64px)',
    top: 64,
  },
  modalContent: {
    height: '100%',
  },
  cardImg: {
    // filter: 'blur(3px)',
    height: 130,
    userSelect: 'none',
    opacity: 0.5,
    borderRadius: '5px 5px 0px 0px',
    backgroundImage: 'url(' + PUBLIC_URL + '/images/logs_bkg.jpg' + ')',
    backgroundSize: 'cover',
  },
  titleCard: {
    position: 'absolute',
    top: 41,
    left: 34,
    fontSize: 40,
    // color: theme.palette.secondary.main,
    color: 'white',
    textShadow: '2px 1px 10px white',
  },
  info: {
    color: '#547fa8',
  },
  success: {
    color: '#20a91b',
  },
  warning: {
    color: '#e68d09',
  },
  error: {
    color: '#c70505',
  },
  debug: {
    color: '#287b2a',
  },
  critical: {
    color: '#ff0000',
  },
  search: {
    color: '#656565',
  },
  info_bkg: {
    background: alpha('#547fa8', 0.05),
  },
  success_bkg: {
    background: alpha('#20a91b', 0.05),
  },
  warning_bkg: {
    background: alpha('#e68d09', 0.05),
  },
  error_bkg: {
    background: alpha('#c70505', 0.05),
  },
  debug_bkg: {
    background: alpha('#287b2a', 0.05),
  },
  critical_bkg: {
    background: alpha('#ff0000', 0.05),
  },
  search_bkg: {
    background: alpha('#656565', 0.05),
  },
  filterSelector: {
    height: 40,
    '& > div': {
      paddingTop: 4,
      paddingBottom: 4,
      height: 40,
    },
  },
  filterCmp: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 13px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    '& .MuiChip-deleteIcon': {
      color: 'inherit',
      opacity: 0.3,
    },
    '& .MuiChip-icon': {
      color: 'inherit',
      // opacity: 0.8,
      maxWidth: 22,
      maxHeight: 22,
    },
    '& .MuiChip-deleteIcon:hover': {
      color: 'inherit',
      opacity: 1,
    },
  },
  searchTextfield: {
    '& .MuiInputBase-adornedEnd': {
      paddingRight: 0,
    },
  },
  errorButton: {
    border: '1px solid rgba(211, 47, 47, 0.5)',
    backgroundColor: 'rgba(211, 47, 47, 0.04)',
    color: 'rgb(211, 47, 47)',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(211, 47, 47, 0.04)',
      border: '1px solid rgb(211, 47, 47)',
    },
  },
  firstSplitter: {
    '& > .layout-pane': {
      paddingLeft: 10,
    },
    '& > .layout-pane:first-child': {
      paddingLeft: 0,
      paddingRight: 10,
    },
  },
  containerSplitter: {
    width: 'calc(100% - 10px)',
    '& > .layout-pane': {
      paddingTop: 10,
      paddingLeft: 10,
    },
    '& > .layout-pane:first-child': {
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: 10,
    },
  },
  jsonTree: {
    borderRadius: 5,
    background: '#fbfbfb63',
    height: 'calc(100% - 40px)',
    width: 'calc(100% - 40px)',
    padding: 20,
    margin: 0,
    overflow: 'scroll',
    '& ul': {
      backgroundColor: 'transparent !important',
    },
  },
  cardSelected: {
    background: alpha(theme.palette.primary.main, 0.2),
  },
  codDriveSelect: {
    minWidth: 120,
    color: 'white',
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255,255,255,0.7)',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& fieldset': {
      borderColor: '#ffffff4f',
    },
    '& label, & svg': {
      color: 'inherit',
    },
    '& .MuiOutlinedInput-root': {
      height: 38,
      color: 'inherit',
    },
  },
  expandButton: {
    // position: 'absolute',
    // top: 7,
    // right: 10,
    color: 'white',
  },
  openedButton: {
    background: '#0000001A',
  },
  spinning: {
    animation: 'circle 1s linear infinite reverse',
  },
  fromCtn: {
    '& .MuiInputBase-root': {
      borderRadius: 0,
    },
    '& fieldset': {
      borderRight: 0,
    },
  },
  toCtn: {
    '& .MuiInputBase-root': {
      borderRadius: '0px 4px 4px 0px',
    },
  },
  refreshTexfield: {
    '& .MuiInputBase-root': {
      borderRadius: '0px 4px 4px 0px',
    },
  },
  refreshButton: {
    position: 'relative',
    display: 'inline-block',
  },
  refreshButtonContainer: {
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 36,
      left: 0,
      width: '100%',
      height: 4,
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      zIndex: -1,
      transform: 'scaleX(0)',
      transformOrigin: 'left',
      transition: 'transform 0s' /* Instantaneous reset */,
    },
    '&.running::stop': {
      animation: 'none',
    },
    '&.running::before': {
      animation: 'fillAnimation var(--transition-duration, 5s) linear forwards',
    },
  },
  '@keyframes fillAnimation': {
    '0%': {
      transform: 'scaleX(0)',
    },
    '100%': {
      transform: 'scaleX(1)',
    },
  },
  toolbar: {
    // paddingRight: theme.spacing(10), // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}))

const filtersList = ['info', 'warning', 'error', 'debug', 'critical']
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const Logs = withRouter((props) => {
  const dispatch = useDispatch()
  const genericClasses = genericStyles()
  const classes = useStyles()
  const theme = useTheme()
  const apiRef = useGridApiRef()
  const match = useRouteMatch()
  const history = props.history

  const dateFns = new DateFnsUtils()

  // const codDriveId = match.params.codDriveId
  const typeId = match.params.typeId
  const urlLogsType = match.params.logsType

  const delta = useSelector((state) => state.settings.dateTimeDelta)
  const configs = useSelector((state) => state.codDriveConfiguration.codDriveConfig)
  const customerConfig = useSelector((state) => state.global.customerConfig)
  const logs = useSelector((state) => state.logs.logs)
  const totalLogs = useSelector((state) => state.logs.total)
  const isFetchingLogs = useSelector((state) => state.logs.isFetchingLogs)
  const isPollingLogs = useSelector((state) => state.logs.isPolling)
  const isGlobalConfigsLoaded = useSelector((state) => state.global.isGlobalConfigsLoaded)
  const batchTasks = useSelector((state) => state.tasks.batchTasks)
  const isFetchingBatchTasks = useSelector((state) => state.tasks.isFetchingBatchTasks)

  const [dateFilterActive, setDateFilterActive] = React.useState(
    localStorage.getItem('LOG_activeDateFilter')
      ? localStorage.getItem('LOG_activeDateFilter') === 'true'
      : false,
  )
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    localStorage.getItem('LOG_filter_fromDate')
      ? new Date(localStorage.getItem('LOG_filter_fromDate'))
      : new Date(getTimeStartDay() - 86400000),
  )
  const [selectedToDate, setSelectedToDate] = React.useState(
    localStorage.getItem('LOG_filter_toDate')
      ? new Date(localStorage.getItem('LOG_filter_toDate'))
      : new Date(getTimeEndDay()),
  )

  const [expanded, setExpanded] = React.useState(
    localStorage.getItem('LOG_DRIVE_expanded')
      ? localStorage.getItem('LOG_DRIVE_expanded') === 'true'
      : false,
  )
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [searchFilterTmp, setSearchFilterTmp] = React.useState(
    localStorage.getItem('LOG_search') ? localStorage.getItem('LOG_search') : '',
  )
  const [searchFilter, setSearchFilter] = React.useState(
    localStorage.getItem('LOG_search') ? localStorage.getItem('LOG_search') : '',
  )
  const [searchFilterActif, setSearchFilterActif] = React.useState(
    !!localStorage.getItem('LOG_search'),
  )
  const [pollingIsRunning, setPollingIsRunning] = React.useState(
    localStorage.getItem('LOG_DRIVE_polling')
      ? localStorage.getItem('LOG_DRIVE_polling') === 'true'
      : true,
  )
  const [animationIsRunning, setAnimationIsRunning] = React.useState(true)
  const openMenu = Boolean(anchorEl)

  const [filters, setFilters] = React.useState(
    localStorage.getItem('LOG_filters')
      ? JSON.parse(localStorage.getItem('LOG_filters'))
      : FILTER_LIST,
  )

  const [limit, setLimit] = React.useState(
    localStorage.getItem('LOG_limit') ? parseFloat(localStorage.getItem('LOG_limit')) : 100,
  )
  const [polling, setPolling] = React.useState(
    localStorage.getItem('LOG_polling') ? parseFloat(localStorage.getItem('LOG_polling')) : 30,
  )

  const [contextMenu, setContextMenu] = React.useState(null)
  const [selectedRow, setSelectedRow] = React.useState(null)

  const [batchObject, setBatchObject] = React.useState(null)

  const appTitle = useMemo(() => {
    let existingTitle = ''
    let existingSubtitle = ''

    // Apply the customer title
    if (isGlobalConfigsLoaded) {
      if (customerConfig && customerConfig.content.hasOwnProperty('title')) {
        existingTitle = customerConfig.content.title
      } else {
        existingTitle = localStorage.getItem('__config.json__APPLICATION_TITLE') || ''
      }
    }

    // Apply the customer subtitle
    if (isGlobalConfigsLoaded) {
      if (customerConfig && customerConfig.content.hasOwnProperty('subtitle')) {
        existingSubtitle = customerConfig.content.subtitle
      } else {
        existingSubtitle = localStorage.getItem('__config.json__APPLICATION_SUBTITLE') || ''
      }
    }

    const subtitleFromLocalStorage = localStorage.getItem('__config.json__APPLICATION_SUBTITLE')

    return !subtitleFromLocalStorage ? (
      <Typography
        id="APPLICATION_TITLE_ID"
        className={classes.title}
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
      >
        {existingTitle}
      </Typography>
    ) : (
      <div className={classes.title}>
        <table>
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Typography
                  id="APPLICATION_TITLE_ID"
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  style={{ lineHeight: '20px' }}
                >
                  {existingTitle}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  id="APPLICATION_SUBTITLE_ID"
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ fontSize: '0.7em', float: 'right', lineHeight: '10px' }}
                >
                  {existingSubtitle}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }, [isGlobalConfigsLoaded, customerConfig])

  const logsType = useMemo(() => {
    return urlLogsType
  }, [urlLogsType])

  const codConfigOpened = useMemo(() => {
    return typeId === 'undefined' ? undefined : typeId
  }, [typeId])

  useEffect(() => {
    const fetchData = async () => {
      const batchData = await getBatchRawById(codConfigOpened, dispatch)
      setBatchObject(batchData)
    }
    if (logsType === 'batch') {
      if (codConfigOpened !== undefined) {
        fetchData()
      }
    }
  }, [codConfigOpened, logsType, dispatch])

  const detailObject = useMemo(() => {
    if (logsType === 'coddrive') {
      return configs.filter((c) => c._id === codConfigOpened)[0]
    } else if (logsType === 'batch') {
      return batchObject
    }
  }, [codConfigOpened, configs, logsType, batchObject])

  const batchLogs = useMemo(() => {
    if (logsType === 'batch' && batchTasks) {
      return batchTasks.flatMap((entry) =>
        entry.logs.map((log) => {
          const [dateStr, processId, level, ...msgArray] = log.split(' - ')
          const cleanedDateStr = dateStr.replace(',', '.')
          const date = new Date(cleanedDateStr.trim()).getTime() / 1000
          return {
            date: date,
            processId: parseInt(processId.trim()),
            level: level.trim(),
            msg: msgArray.join(' - ').trim(),
          }
        }),
      )
    }
  }, [batchTasks])

  const stateSnapshotKey = useMemo(() => {
    if (logsType === 'coddrive') {
      return 'LOG_DRIVE_state'
    } else if (logsType === 'batch') {
      return 'LOG_BATCH_state'
    }
  }, [logsType])

  useEffect(() => {
    dispatch(fetchClearLogs())
    dispatch(fetchGlobalConfigurations())
    // dispatch(fetchGetLogs(false, null, typeId))
    // dispatch(fetchGetCodDriveConfigs())
    // Start the logs polling
    // if (pollingIsRunning) {
    //   dispatch(fetchChangeSettings('logsPopup', true))
    // }
  }, [])

  useEffect(() => {
    if (logsType === 'coddrive') {
      dispatch(fetchGetCodDriveConfigs())
    } else if (logsType === 'batch') {
      // dispatch(fetchGetCodDriveConfigs())
    }
  }, [logsType])

  useEffect(() => {
    if (codConfigOpened !== undefined && logsType === 'coddrive') {
      dispatch(fetchGetLogs(false, null, codConfigOpened)).then(() => {
        // Start the logs polling
        if (codConfigOpened !== undefined && pollingIsRunning) {
          dispatch(fetchChangeSettings('logsPopup', true))
        }
      })
    } else if (logsType === 'batch') {
      dispatch(fetchGetBatchTasks(typeId))
    }
  }, [codConfigOpened, logsType])

  useEffect(() => {
    if (isPollingLogs || isFetchingLogs || !pollingIsRunning) {
      setAnimationIsRunning(false)
      // setTimeout(() => setAnimationIsRunning(true), 0);
    } else {
      setAnimationIsRunning(true)
    }
  }, [isPollingLogs, isFetchingLogs, pollingIsRunning])

  const saveSnapshot = React.useCallback(() => {
    const stateSnapshot = apiRef.current.exportState()
    if (Object.keys(stateSnapshot).length !== 0) {
      localStorage.setItem(stateSnapshotKey, JSON.stringify(stateSnapshot))
    }
  }, [apiRef, logsType])

  const jsonTheme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#000000',
    base01: '#202020',
    base02: '#303030',
    base03: '#505050',
    base04: '#b0b0b0',
    base05: '#d0d0d0',
    base06: '#e0e0e0',
    base07: '#ffffff',
    base08: '#eb008a',
    base09: '#f29333',
    base0A: '#f8ca12',
    base0B: '#37b349',
    base0C: '#00aabb',
    base0D: '#0e5a94',
    base0E: '#b31e8d',
    base0F: '#7a2d00',
  }

  const getLevelIcon = (level) => {
    switch (level) {
      case 'info':
        return <InfoOutlinedIcon style={{ color: '#547fa8' }} />
      case 'error':
        return <ErrorOutlineOutlinedIcon style={{ color: '#c70505' }} />
      case 'warning':
        return <WarningAmberOutlinedIcon style={{ color: '#e68d09' }} />
      case 'debug':
        return <AdbIcon style={{ color: '#287b2a' }} />
      case 'critical':
        return <NewReleasesIcon style={{ color: '#ff0000' }} />
    }
  }

  const columns = useMemo(() => {
    const allColumns = [
      {
        field: 'level',
        display: true,
        resizable: false,
        disableReorder: true,
        width: 50,
        headerName: t('view.dialogs.logs.header_names.level'),
        renderHeader: () => '',
        renderCell: ({ value }) =>
          // <Stack
          //   direction="row"
          //   justifyContent="flex-start"
          //   alignItems="center"
          //   spacing={1}
          //   className={classes[value]}
          // >
          //   {getLevelIcon(value)} <span>{value}</span>
          // </Stack>
          getLevelIcon(value),
      },
      {
        field: 'date',
        display: true,
        width: 180,
        type: 'dateTime',
        headerName: t('view.dialogs.logs.header_names.date'),
        valueGetter: ({ value }) => value && new Date((value - delta) * 1000),
        valueFormatter: ({ value }) => {
          if (value instanceof Date) {
            return dateFns.format(value, 'yyyy/MM/dd - HH:mm:ss')
          }
          return ''
        },
      },
      {
        field: 'processId',
        display: logsType === 'batch',
        type: 'string',
        width: 120,
        headerName: t('view.dialogs.logs.header_names.processId'),
      },
      {
        field: 'action',
        display: logsType === 'coddrive',
        type: 'string',
        width: 120,
        headerName: t('view.dialogs.logs.header_names.action'),
      },
      {
        field: 'device',
        display: logsType === 'coddrive',
        type: 'string',
        width: 90,
        headerName: t('view.dialogs.logs.header_names.device'),
      },
      {
        field: 'batchId',
        display: logsType === 'coddrive',
        type: 'string',
        width: 130,
        headerName: t('view.dialogs.logs.header_names.batchId'),
      },
      {
        field: 'batchName',
        display: logsType === 'coddrive',
        type: 'string',
        width: 120,
        headerName: t('view.dialogs.logs.header_names.batchName'),
      },
      {
        field: 'batchPath',
        display: logsType === 'coddrive',
        type: 'string',
        width: 200,
        headerName: t('view.dialogs.logs.header_names.batchPath'),
      },
      {
        field: 'msg',
        display: true,
        resizable: false,
        hideable: false,
        type: 'string',
        flex: 1,
        headerName: t('view.dialogs.logs.header_names.msg'),
      },
    ]
    return allColumns.filter((column) => Boolean(column.display))
  }, [logsType])

  const cleanLogs = useMemo(
    () =>
      logs.map((log) => ({
        id: log._id,
        date: log.content.created,
        level: log.content.level,
        msg: log.content.msg,
        action: log.content.action,
        batchName: log.content.batch_name,
        batchPath: log.content.batch_path,
        batchId: log.content.batch_id,
        device: log.content.device,
      })),
    [logs],
  )

  function updateLimit(value) {
    setLimit(value)
    localStorage.setItem('LOG_limit', value)
  }

  function updatePolling(value) {
    // localStorage.setItem('LOG_polling', value)
    // setPolling(value);
    // setAnimationIsRunning(false);
    // setTimeout(() => setAnimationIsRunning(true), 0);

    setPolling(value)
    localStorage.setItem('LOG_polling', value)
    //Stop / start the polling to take into account the new value
    dispatch(fetchChangeSettings('logsPopup', false))
    setPollingIsRunning(false)
    setTimeout(() => {
      setPollingIsRunning(true)
      dispatch(fetchChangeSettings('logsPopup', true))
    }, 0) // Restart immediately
  }
  const handleFilterDelete = (filter) => {
    const newFilters = filters.filter((f) => f !== filter)
    localStorage.setItem('LOG_filters', JSON.stringify(newFilters))
    setFilters(newFilters)
    dispatch(fetchGetLogs(false, null, typeId))
  }
  const handleOpenButton = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleFiltersChange = (filter) => {
    if (filters.indexOf(filter) > -1) {
      const newFilters = filters.filter((f) => f !== filter)
      localStorage.setItem('LOG_filters', JSON.stringify(newFilters))
      setFilters(newFilters)
    } else {
      const newFilters = JSON.parse(JSON.stringify(filters))
      newFilters.push(filter)
      localStorage.setItem('LOG_filters', JSON.stringify(newFilters))
      setFilters(newFilters)
    }
    dispatch(fetchGetLogs(false, null, typeId))
  }

  function applySearchFilter() {
    localStorage.setItem('LOG_search', searchFilterTmp)
    setSearchFilter(searchFilterTmp)
    setSearchFilterActif(true)
    dispatch(fetchGetLogs(false, null, typeId))
    setAnchorEl(null)
  }

  function clearSearchFilter() {
    localStorage.removeItem('LOG_search')
    setSearchFilterActif(false)
    setSearchFilterTmp('')
    setSearchFilter('')
    dispatch(fetchGetLogs(false, null, typeId))
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
    const rowId = event.currentTarget.getAttribute('data-id')
    const row = cleanLogs.filter((l) => l.id === rowId)[0]
    setSelectedRow(row)
    setContextMenu(
      contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null,
    )
  }

  const handleCloseContextMenu = () => {
    setContextMenu(null)
  }

  function copyToClipboardMenu(event) {
    setContextMenu(null)

    copyToClipboard(
      event,
      dateFns.format(new Date((selectedRow.date - delta) * 1000), 'yyyy/MM/dd - HH:mm:ss') +
        ' : ' +
        selectedRow.level +
        (logsType === 'coddrive' ? '' : '\nProcess ID: ' + selectedRow.processId) +
        (logsType === 'coddrive' ? '\nAction: ' + selectedRow.action : '') +
        (logsType === 'coddrive' ? '\nDevice: ' + selectedRow.device : '') +
        (logsType === 'coddrive' ? '\nBatch name: ' + selectedRow.batchName : '') +
        (logsType === 'coddrive' ? '\nBatch ID: ' + selectedRow.batchId : '') +
        (logsType === 'coddrive' ? '\nBatch path: ' + selectedRow.batchPath : '') +
        '\n\n' +
        selectedRow.msg,
    )
  }

  const contentSecondCntRef = React.createRef()
  function onContentSecondarySplitterSizeSave() {
    localStorage.setItem(
      'LAYOUT_CODDRIVE_secondaryPaneSize',
      contentSecondCntRef.current.state.secondaryPaneSize,
    )
  }

  function onExpandClick() {
    localStorage.setItem('LOG_DRIVE_expanded', !expanded)
    setExpanded((oldState) => !oldState)
  }

  function downloadCSV() {
    if (apiRef?.current)
      apiRef.current.exportDataAsCsv({
        delimiter: ';',
        fileName:
          (logsType === 'coddrive'
            ? configs.filter((conf) => conf._id === codConfigOpened)[0]?.name
            : batchObject?.name) + '_logs',
        // utf8WithBom: true,
      })
  }

  function handleChangeDateFilterActive() {
    localStorage.setItem('LOG_activeDateFilter', !dateFilterActive)
    setDateFilterActive((oldState) => !oldState)
    dispatch(fetchGetLogs(false, null, typeId))
  }

  function startStopPolling() {
    dispatch(fetchChangeSettings('logsPopup', !pollingIsRunning))
    setPollingIsRunning((oldState) => !oldState)
  }

  function handleDateChange(date, origin) {
    const oneDay = 86400000

    // let from = selectedFromDate
    // let to = selectedToDate

    switch (origin) {
      case 'from':
        setSelectedFromDate(date)
        localStorage.setItem('LOG_filter_fromDate', date)
        if (date.getTime() > selectedToDate.getTime()) {
          const newToDateOneDayAfter = new Date(new Date(date).getDate() + 1)
          setSelectedToDate(newToDateOneDayAfter)
          localStorage.setItem('LOG_filter_fromDate', newToDateOneDayAfter)
        }
        break
      case 'to':
        setSelectedToDate(date)
        localStorage.setItem('LOG_filter_toDate', date)
        if (date.getTime() < selectedFromDate.getTime()) {
          const newFromDateOneDayBefore = new Date(new Date(date).getDate() - 1)
          setSelectedFromDate(newFromDateOneDayBefore)
          localStorage.setItem('LOG_filter_toDate', newFromDateOneDayBefore)
        }
        break
      default:
        break
    }
    dispatch(fetchGetLogs(false, null, typeId))

    // dispatch(
    //   fetchBatches(
    //     dateFilterActive ? Math.trunc((origin === 'from' ? date : from).getTime() / 1000) : null,
    //     dateFilterActive ? Math.trunc((origin === 'to' ? date : to).getTime() / 1000) : null,
    //     wipChecked,
    //     backlogFilterService,
    //     backlogFilterType,
    //     backlogFilterDevice,
    //   ),
    // )
  }

  return (
    <Fragment>
      <Polling />
      <div className={classes.container}>
        <AppBar position="absolute" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            {/*<div className={classes.header}>*/}
            {appTitle}
            {/*<FormControl*/}
            {/*  className={classes.codDriveSelect}*/}
            {/*  style={{ marginRight: 30 }}*/}
            {/*  size="small"*/}
            {/*>*/}
            {/*  <InputLabel id="demo-select-small-label">Logs type</InputLabel>*/}
            {/*  <Select*/}
            {/*    value={logsType}*/}
            {/*    label="Logs type"*/}
            {/*    onChange={(e) => history.push(`/?logs/${e.target.value}`)}*/}
            {/*  >*/}
            {/*    <MenuItem value={'coddrive'}>{'COD Drive logs'}</MenuItem>*/}
            {/*    <MenuItem value={'batch'}>{'Batch logs'}</MenuItem>*/}
            {/*  </Select>*/}
            {/*</FormControl>*/}
            {logsType === 'batch' && (
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <Chip
                  style={{ paddingTop: 2 }}
                  icon={<FolderIcon style={{ marginLeft: 8, marginTop: -3 }} />}
                  label={<span style={{ marginRight: 4 }}>{batchObject?.name}</span>}
                  color="secondary"
                  size="small"
                />
              </Typography>
            )}
            {logsType === 'coddrive' && (
              <Fragment>
                <div className={classes.title}>
                  <FormControl
                    className={classes.codDriveSelect}
                    // style={{ position: 'absolute', top: 13, marginLeft: '50%', display: 'flex' }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-label">COD Drive</InputLabel>
                    <Select
                      value={codConfigOpened}
                      label="COD Drive"
                      onChange={(e) =>
                        history.push(`/?${DATABASE()}/logs/${logsType}/${e.target.value}`)
                      }
                    >
                      {configs.map((config) => (
                        <MenuItem value={config._id}>{config.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <ErrorBoundaryGuard>
                  <DatabaseCmp />
                </ErrorBoundaryGuard>
              </Fragment>
            )}
            <Tooltip
              arrow
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  {t('view.analysis.information.download')}
                </div>
              }
            >
              <IconButton
                style={{ marginRight: 15, height: 48 }}
                color="inherit"
                aria-label="download"
                onClick={downloadCSV}
              >
                <ExcelIcon style={{ width: 24, height: 24, marginTop: 3 }} />
              </IconButton>
            </Tooltip>
            {/*<div style={{ color: 'white', position: 'absolute', top: 7, right: 45 }}>*/}
            {/*</div>*/}
            <Tooltip
              arrow
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  {expanded ? t('view.dialogs.logs.hide') : t('view.dialogs.logs.display')}
                </div>
              }
            >
              <IconButton
                aria-label="delete"
                className={clsx(classes.expandButton, expanded ? classes.openedButton : '')}
                onClick={onExpandClick}
              >
                <DataObjectIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            {/*</div>*/}
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <SplitterLayout
            customClassName={classes.firstSplitter}
            ref={contentSecondCntRef}
            percentage
            onDragEnd={onContentSecondarySplitterSizeSave}
            secondaryMinSize={10}
            primaryMinSize={50}
            primaryIndex={0}
            secondaryInitialSize={
              localStorage.getItem('LAYOUT_CODDRIVE_secondaryPaneSize')
                ? parseFloat(localStorage.getItem('LAYOUT_CODDRIVE_secondaryPaneSize'))
                : 30
            }
          >
            <div style={{ height: 'calc(100% - 30px)' }}>
              <div className={classes.modalContent}>
                <Menu
                  open={contextMenu !== null}
                  onClose={handleCloseContextMenu}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenu !== null
                      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                      : undefined
                  }
                  slotProps={{
                    root: {
                      onContextMenu: (e) => {
                        e.preventDefault()
                        handleCloseContextMenu()
                      },
                    },
                  }}
                >
                  <MenuItem onClick={copyToClipboardMenu}>
                    <ListItemIcon>
                      <FileCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('view.dialogs.logs.clipboard')}</ListItemText>
                  </MenuItem>
                </Menu>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  alignItems="stretch"
                  spacing={0}
                  style={{ height: '100%' }}
                >
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    style={{ padding: '20px 20px 0px' }}
                  >
                    <Stack
                      direction="row"
                      justifyContent={logsType === 'coddrive' ? 'space-between' : 'flex-start'}
                      alignItems="center"
                      spacing={logsType === 'coddrive' ? 1 : 12}
                      className={classes.filterCmp}
                    >
                      <div>
                        <Button
                          disabled={codConfigOpened === undefined}
                          className={
                            filters.length === 0 && !searchFilterActif
                              ? classes.errorButton
                              : classes.default
                          }
                          variant="outlined"
                          disableElevation
                          onClick={handleOpenButton}
                          startIcon={<FilterAltOutlinedIcon />}
                          endIcon={<KeyboardArrowDownIcon />}
                          style={{ height: 40 }}
                        ></Button>
                        <Menu
                          autoFocus={false}
                          disableAutoFocusItem={true}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          open={openMenu}
                          onClose={handleClose}
                        >
                          {filtersList.map((name) => (
                            <MenuItem
                              key={'_' + name}
                              value={name}
                              onClick={() => handleFiltersChange(name)}
                            >
                              <Checkbox checked={filters.indexOf(name) > -1} />
                              <ListItemText
                                style={{ margin: '0px 20px' }}
                                primary={t('view.dialogs.logs.filters.' + name)}
                              />
                            </MenuItem>
                          ))}
                          {/*<TextField*/}
                          {/*  onKeyDown={(e) => {*/}
                          {/*    if (*/}
                          {/*      e.key === 'Enter' &&*/}
                          {/*      searchFilterTmp !== '' &&*/}
                          {/*      searchFilter !== searchFilterTmp*/}
                          {/*    ) {*/}
                          {/*      // Valid with entry key from keyboard*/}
                          {/*      applySearchFilter()*/}
                          {/*    } else {*/}
                          {/*      e.stopPropagation()*/}
                          {/*    }*/}
                          {/*  }}*/}
                          {/*  InputProps={{*/}
                          {/*    startAdornment: (*/}
                          {/*      <InputAdornment position="start">*/}
                          {/*        <SearchIcon />*/}
                          {/*      </InputAdornment>*/}
                          {/*    ),*/}
                          {/*    endAdornment: (*/}
                          {/*      <InputAdornment position="end">*/}
                          {/*        {searchFilterActif && searchFilter === searchFilterTmp ? (*/}
                          {/*          <IconButton color="primary" onClick={clearSearchFilter}>*/}
                          {/*            <HighlightOffIcon fontSize="inherit" />*/}
                          {/*          </IconButton>*/}
                          {/*        ) : searchFilterTmp !== '' ? (*/}
                          {/*          <IconButton color="primary" onClick={applySearchFilter}>*/}
                          {/*            <InputIcon fontSize="inherit" />*/}
                          {/*          </IconButton>*/}
                          {/*        ) : (*/}
                          {/*          ''*/}
                          {/*        )}*/}
                          {/*      </InputAdornment>*/}
                          {/*    ),*/}
                          {/*  }}*/}
                          {/*  size="small"*/}
                          {/*  variant="outlined"*/}
                          {/*  className={classes.searchTextfield}*/}
                          {/*  style={{*/}
                          {/*    margin: '10px 15px 8px',*/}
                          {/*    borderTop: '1px solid lightgrey',*/}
                          {/*    paddingTop: 17,*/}
                          {/*    width: 260,*/}
                          {/*  }}*/}
                          {/*  value={searchFilterTmp}*/}
                          {/*  onChange={(event) => {*/}
                          {/*    setSearchFilterTmp(event.currentTarget.value)*/}
                          {/*  }}*/}
                          {/*/>*/}
                        </Menu>
                      </div>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {FILTER_LIST.filter((f) => filters.indexOf(f) !== -1).map((value) => (
                          <Chip
                            icon={getLevelIcon(value)}
                            onDelete={
                              codConfigOpened === undefined
                                ? undefined
                                : () => handleFilterDelete(value)
                            }
                            key={value}
                            label={t('view.dialogs.logs.filters.' + value).toLowerCase()}
                            style={{ height: 26, marginRight: 5, borderRadius: 16 }}
                            className={clsx(classes[value], classes[value + '_bkg'])}
                          />
                        ))}
                        {searchFilterActif && (
                          <Chip
                            icon={<SearchIcon />}
                            onDelete={clearSearchFilter}
                            key={'searched'}
                            label={searchFilter}
                            style={{ height: 26, marginRight: 5, borderRadius: 16 }}
                            className={clsx(classes.search, classes.search_bkg)}
                          />
                        )}
                      </Box>
                      {logsType === 'coddrive' && (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <div
                            style={{
                              // border: '1px solid rgba(0, 0, 0, 0.23)',
                              borderRadius: 4,
                              display: 'inline-block',
                              // marginTop: 16,
                              // marginRight: 15,
                            }}
                          >
                            <Stack
                              direction="row"
                              // divider={
                              //   <Divider orientation="vertical" flexItem style={{ margin: 0 }} />
                              // }
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={0}
                              style={{ height: 41 }}
                            >
                              <Checkbox
                                disabled={codConfigOpened === undefined}
                                checked={dateFilterActive}
                                onChange={handleChangeDateFilterActive}
                                style={{
                                  borderRadius: '4px 0px 0px 4px',
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                  borderRight: 'none',
                                  height: 40,
                                  width: 40,
                                }}
                              />
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                  ampm={false}
                                  disabled={!dateFilterActive || codConfigOpened === undefined}
                                  size="small"
                                  className={classes.fromCtn}
                                  style={{ margin: '0px -1px 0px 0px', width: 250 }}
                                  disableToolbar
                                  inputVariant="outlined"
                                  format="yyyy/MM/dd - HH:mm:ss"
                                  margin="normal"
                                  id="date-picker-from"
                                  label={t('view.backlog.from')}
                                  value={selectedFromDate}
                                  onChange={(e) => handleDateChange(e, 'from')}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                                <KeyboardDateTimePicker
                                  ampm={false}
                                  disabled={!dateFilterActive || codConfigOpened === undefined}
                                  size="small"
                                  style={{ margin: '0px 15px 0px 0px', width: 250 }}
                                  className={classes.toCtn}
                                  disableToolbar
                                  inputVariant="outlined"
                                  format="yyyy/MM/dd - HH:mm:ss"
                                  margin="normal"
                                  id="date-picker-to"
                                  label={t('view.backlog.to')}
                                  value={selectedToDate}
                                  onChange={(e) => handleDateChange(e, 'to')}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Stack>
                          </div>
                          {/*<Grid item style={{ marginRight: 10, width: 100 }}>*/}
                          <TextField
                            disabled={codConfigOpened === undefined}
                            label={t('view.dialogs.logs.limit')}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={limit}
                            onChange={(event) => {
                              updateLimit(event.target.value)
                            }}
                            variant="outlined"
                            size="small"
                            style={{ width: 100 }}
                          />
                          {/*</Grid>*/}
                          {/*<Grid item style={{ marginRight: 10, width: 100 }}>*/}
                          <Stack
                            direction="row"
                            // divider={
                            //   <Divider orientation="vertical" flexItem style={{ margin: 0 }} />
                            // }
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0}
                            style={{ height: 41 }}
                          >
                            <Button
                              disabled={codConfigOpened === undefined}
                              style={{
                                height: 40,
                                paddingRight: 0,
                                paddingLeft: 0,
                                width: 50,
                                minWidth: 50,
                                borderRadius: '4px 0px 0px 4px',
                                borderRight: 'none',
                              }}
                              color={pollingIsRunning ? 'secondary' : ''}
                              onClick={startStopPolling}
                              variant="outlined"
                              startIcon={
                                pollingIsRunning && codConfigOpened !== undefined ? (
                                  <PauseIcon />
                                ) : (
                                  <PlayArrowIcon />
                                )
                              }
                            ></Button>
                            <TextField
                              disabled={!pollingIsRunning || codConfigOpened === undefined}
                              label={t('view.dialogs.logs.refresh')}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                inputProps: { min: 5 },
                                endAdornment: <InputAdornment position="end">s</InputAdornment>,
                              }}
                              value={polling}
                              onChange={(event) => {
                                let value = parseInt(event.target.value, 10)
                                if (value < 5) value = 5
                                updatePolling(value)
                              }}
                              variant="outlined"
                              size="small"
                              style={{ width: 100, borderRadius: '0px 4px 4px 0px' }}
                              className={classes.refreshTexfield}
                            />
                          </Stack>
                          <div
                            // className='button-container'
                            className={clsx(
                              classes.refreshButtonContainer,
                              animationIsRunning && codConfigOpened !== undefined
                                ? 'running'
                                : 'stop',
                            )}
                            style={{ '--transition-duration': `${polling}s`, borderRadius: 4 }}
                          >
                            <Button
                              // className={`button ${pollingIsRunning ? 'running' : ''}`}
                              className={clsx(
                                classes.refreshButton,
                                // pollingIsRunning ? 'running' : '',
                              )}
                              disabled={
                                isPollingLogs || isFetchingLogs || codConfigOpened === undefined
                              }
                              style={{
                                height: 40,
                                paddingRight: 0,
                                paddingLeft: 0,
                                width: 50,
                                minWidth: 50,
                                // borderRadius: '0px 4px 4px 0px',
                                // marginLeft: 0,
                              }}
                              onClick={() => dispatch(fetchGetLogs(false, null, typeId))}
                              variant="outlined"
                              startIcon={
                                <ReplayIcon
                                  style={{ height: 20, marginTop: 4 }}
                                  className={
                                    isPollingLogs || isFetchingLogs ? classes.spinning : ''
                                  }
                                />
                              }
                            ></Button>
                          </div>
                        </Stack>
                      )}
                    </Stack>
                  </Typography>
                  <ErrorBoundaryGuard>
                    <DataGridPro
                      initialState={{
                        pinnedColumns: {
                          // left: [GRID_TREE_DATA_GROUPING_FIELD],
                          right: ['actions'],
                        },
                        sorting: {
                          sortModel: [
                            {
                              field: 'date',
                              sort: 'desc',
                            },
                          ],
                        },
                        ...JSON.parse(localStorage.getItem(stateSnapshotKey)),
                      }}
                      onStateChange={saveSnapshot}
                      getRowClassName={(params) => classes[params.row.level + '_bkg']}
                      getRowHeight={() => 'auto'}
                      sx={{
                        [`& .${gridClasses.cell}`]: {
                          py: 1,
                          borderColor: '#e0e0e0a6',
                          alignItems: 'baseline',
                        },
                        [`& .${gridClasses.cell}:focus`]: {
                          outline: 'none',
                        },
                      }}
                      loading={logsType === 'coddrive' ? isFetchingLogs : isFetchingBatchTasks}
                      slots={{
                        // moreActionsIcon: CustomActionIcon,
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: () => (
                          <div
                            style={{
                              height: 'calc(100% - 232px)',
                              width: '100%',
                              position: 'fixed',
                            }}
                          >
                            <Loading mode="alpha" size={10} />
                          </div>
                        ),
                      }}
                      slotProps={{
                        row: {
                          onContextMenu: handleContextMenu,
                          style: { cursor: 'context-menu' },
                        },
                      }}
                      apiRef={apiRef}
                      // getRowClassName={(params) =>
                      //   params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                      // }
                      // getCellClassName={(params) => {
                      //   return 'customCell'
                      // }}
                      // disableVirtualization={batches.length < 50}
                      rowBuffer={40}
                      rowThreshold={20}
                      columnBuffer={2}
                      columnThreshold={2}
                      // treeData
                      rows={logsType === 'coddrive' ? cleanLogs : batchLogs}
                      columns={columns}
                      // getTreeDataPath={(row) => row.hierarchy}
                      // groupingColDef={{
                      //   headerName: (
                      //     <SubdirectoryArrowRightIcon
                      //       style={{ position: 'relative', fontSize: 20, top: 5, color: '#0000008A' }}
                      //     />
                      //   ),
                      //   hideDescendantCount: true,
                      //   valueFormatter: () => '',
                      //   width: 40,
                      // }}
                      pagination={false}
                      hideFooter={true}
                      style={{ border: 'none', height: 'calc(100% - 65px)' /*color: 'white'*/ }}
                      // Do not use autoSize: conflic with initialState
                      // autosizeOnMount
                      // autosizeOptions={autoSizeOptions}
                      disableRowSelectionOnClick
                      // onRowClick={(params, event, details) =>
                      //   onClick(event, params.row, delta, props.history, dispatch)
                      // }
                      // onResize={() => hideWatermark()} //TODO To be deleted after purchasing a license
                    />
                  </ErrorBoundaryGuard>
                </Stack>
              </div>
              <Stack
                style={{ padding: '5px 10px' }}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="caption" display="block">
                  <Trans i18nKey="view.dialogs.logs.info">
                    {/*{{ val1: cleanLogs.length }} most recent of the {{ val2: totalLogs }}*/}
                    {{ val: cleanLogs.length }} logs loaded
                  </Trans>
                </Typography>
              </Stack>
            </div>
            {expanded && (
              <div className={classes.jsonTree}>
                <ErrorBoundaryGuard>
                  <JSONTree
                    shouldExpandNode={(keyPath, data, level) => level < 3}
                    data={detailObject}
                    theme={jsonTheme}
                    invertTheme={false}
                    hideRoot={true}
                    className={classes.jsonTree}
                  />
                </ErrorBoundaryGuard>
              </div>
            )}
          </SplitterLayout>
        </div>
      </div>
    </Fragment>
  )
})
export default Logs
