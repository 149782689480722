// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Tooltip } from '@material-ui/core'
import { fetchChangeSettings } from '../../../redux/settings/actions'
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
import { t } from 'i18next'
import IconButton from '@mui/material/IconButton'
import Chip from '@mui/material/Chip'
import Loading from '../../Loading'
import Badge from '@mui/material/Badge'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import clippyWait from '../../../resources/images/clippy_wait.gif'
import clippyRead from '../../../resources/images/clippy_read.gif'
import assistantIcon from '../../../resources/images/assistant-ia_def.png'
import clippyAnim1 from '../../../resources/images/clippy_0.gif'
import AIAssistantDialog from './AIAssistantDialog'
import clippyAnim2 from '../../../resources/images/clippy_1.gif'
import clippyAnim3 from '../../../resources/images/clippy_2.gif'
import clippyAnim4 from '../../../resources/images/clippy_3.gif'
import clippyAnim5 from '../../../resources/images/clippy_4.gif'
import clippyAnim6 from '../../../resources/images/clippy_5.gif'
import clippyAnim7 from '../../../resources/images/clippy_6.gif'
import clippyAnim8 from '../../../resources/images/clippy_7.gif'
import { fetchAssitantStatus } from '../../../redux/assistant/actions'

const useStyles = makeStyles((theme) => ({
  offline: {
    border: '2px solid white',
    color: 'white',
    background: '#a1a1a1',
  },
  online: {
    border: '2px solid white',
    color: 'white',
    background: 'green',
  },
  adminSpecialHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  badgeIcon: {
    width: 10,
    height: 10,
  },
  assistantBadge: {
    '& .MuiBadge-badge': {
      padding: 0,
      background: 'white',
      color: 'white',
      border: '2px solid white',
    },
  },
  assistantBadgeOnline: {
    '& .MuiBadge-badge': {
      // color: 'green',
      background: 'green',
    },
  },
  assistantBadgeOffline: {
    '& .MuiBadge-badge': {
      // color: 'red',
      background: '#a1a1a1',
    },
  },
  badgeAdmin: {
    '& .MuiBadge-badge': {
      position: 'relative',
      bottom: -38,
      right: 32,
    },
  },
  clippyImg: {
    width: 55,
    borderRadius: 20,
  },
  assistantImg: {
    width: 35,
    background: theme.palette.primary.main,
    borderRadius: 25,
    padding: '7px 3px',
  },
}))

const AIButton = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const animations = [
    clippyAnim1,
    clippyAnim2,
    clippyAnim3,
    clippyAnim4,
    clippyAnim5,
    clippyAnim6,
    clippyAnim7,
    clippyAnim8,
  ]
  const durations = [13600, 7500, 8400, 8100, 3500, 2600, 4500, 5500]
  const admin = JSON.parse(localStorage.getItem('SESSION_user_json'))?.admin

  const [currentAnim, setCurrentAnim] = useState(clippyAnim1)

  const [isHovered, setIsHovered] = useState(false)

  const open = useSelector(function (state) {
    return state.settings.assistantPopup
  })
  const assistant_loaded = useSelector(function (state) {
    return state.assistant.loaded
  })
  const ai_fetching = useSelector(function (state) {
    return state.assistant.ai_fetching
  })
  const systemError = useSelector(function (state) {
    return state.assistant.ai_status === 'offline'
  })
  const external_windows_opened = useSelector(function (state) {
    return state.assistant.externalWindowOpened
  })
  const loginDialog = useSelector(function (state) {
    return state.settings.loginPopup
  })
  const assistantType = useSelector(function (state) {
    return state.settings.assistantType
  })
  const clippyActivated = useMemo(() => {
    return admin && assistantType === 'clippy'
  }, [assistantType])

  useEffect(() => {
    dispatch(fetchAssitantStatus()).catch((error) =>
      console.warn('A.I. Assistant version:\n' + error),
    )
    const interval = setInterval(() => {
      dispatch(fetchAssitantStatus()).catch((error) =>
        console.warn('A.I. Assistant version:\n' + error),
      )
    }, 60000) // 60000 ms = 1 minute
    return () => clearInterval(interval) // Nettoyage de l'intervalle lors du démontage
  }, [])

  useEffect(() => {
    if (!clippyActivated) return // Ne pas démarrer l'intervalle si Clippy non actif
    const changeAnimation = () => {
      let randomIndex
      let newAnim
      // Boucle pour garantir que l'animation suivante est différente de l'actuelle
      do {
        randomIndex = Math.floor(Math.random() * animations.length)
        newAnim = animations[randomIndex]
      } while (newAnim === currentAnim)
      setCurrentAnim(newAnim)
      return durations[randomIndex] // Retourner la durée correspondante à l'animation choisie
    }
    const intervalFunction = () => {
      const currentDuration = changeAnimation() // Changer d'animation et obtenir la nouvelle durée
      // Utiliser setTimeout pour planifier le prochain changement d'animation
      const timeoutId = setTimeout(intervalFunction, currentDuration)
      return () => clearTimeout(timeoutId) // Nettoyer le timeout lorsqu'il y a une nouvelle animation ou démontage du composant
    }
    // Démarrer le changement d'animation dès que le composant est monté
    const timeoutId = intervalFunction()
    return () => clearTimeout(timeoutId) // Nettoyage du timeout lorsque le composant est démonté ou qu'une nouvelle animation démarre
  }, [clippyActivated]) // Déclencher l'effet uniquement lorsque Clippy change

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  const openFct = () => {
    dispatch(fetchChangeSettings('assistantPopup', true))
    setIsHovered(false)
  }

  return (
    !external_windows_opened &&
    !loginDialog &&
    (open ? (
      <AIAssistantDialog />
    ) : (
      <Tooltip
        arrow
        placement="top"
        title={
          <div style={{ fontSize: 13, padding: 5, textAlign: 'center' }}>
            {t('view.dialogs.assistant.title')}
            {assistant_loaded && (
              <div style={{ marginTop: 5 }}>
                <Chip
                  size="small"
                  label={
                    systemError
                      ? t('view.dialogs.assistant.offline')
                      : t('view.dialogs.assistant.online')
                  }
                  variant="outlined"
                  className={systemError ? classes.offline : classes.online}
                />
              </div>
            )}
          </div>
        }
      >
        <Fragment>
          {!clippyActivated && assistant_loaded && !systemError && ai_fetching && (
            <Loading
              mode={'typing'}
              style={{ width: 40, position: 'absolute', right: 37, bottom: 65 }}
            />
          )}
          <IconButton
            size="large"
            aria-label="assistant"
            color="secondary"
            onClick={openFct}
            style={{
              position: 'absolute',
              // bottom: clippyActivated ? 88 : 63,
              bottom: 0,
              // left: `calc(100% - ${clippyActivated ? 120 : 82}px)`,
              right: clippyActivated ? 0 : 20,
            }}
            className={clippyActivated ? classes.adminSpecialHover : undefined}
            onMouseEnter={clippyActivated ? handleMouseEnter : undefined}
            onMouseLeave={clippyActivated ? handleMouseLeave : undefined}
          >
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              overlap="circular"
              badgeContent={
                assistant_loaded ? (
                  systemError ? (
                    <CloseIcon className={classes.badgeIcon} />
                  ) : (
                    <DoneIcon className={classes.badgeIcon} />
                  )
                ) : (
                  0
                )
              }
              className={clsx(
                classes.assistantBadge,
                systemError ? classes.assistantBadgeOffline : classes.assistantBadgeOnline,
                clippyActivated ? classes.badgeAdmin : undefined,
              )}
            >
              <img
                src={
                  clippyActivated && isHovered
                    ? clippyWait
                    : clippyActivated
                    ? assistant_loaded && !systemError && ai_fetching
                      ? clippyRead
                      : currentAnim
                    : assistantIcon
                }
                alt="Assistant"
                className={clippyActivated ? classes.clippyImg : classes.assistantImg}
                style={{ width: clippyActivated ? 85 : 50, height: clippyActivated ? 69 : 50 }}
              />
            </Badge>
          </IconButton>
        </Fragment>
      </Tooltip>
    ))
  )
})
export default AIButton
