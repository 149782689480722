// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React from 'react'
import { API_BO_URL } from '../../utils/config'
import { displayAuditTrail } from '../auditTrail/actions'

export const GET_DATA = 'GET_DATA'

export function fetchGetData() {
  return async (dispatch) => {
    const url = 'http://10.140.9.32:7777/Chien/Panga/last'

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
    const data = await response.json()
    return dispatch(getData(data))
  }
}
export function getData(data) {
  return {
    type: GET_DATA,
    data: data,
  }
}
