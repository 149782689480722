// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { API_BO_URL, API_URL } from '../../utils/config'
import { fetchChangeSettings } from '../settings/actions'
import { requestUnitaryAnalysesCompoundsForDetail } from '../unitaryAnalyses/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'
import { receiveCurrentBatchLease } from '../batches/actions'
import { getTimeEndDay, getTimeStartDay } from '../../utils/utilities'
import { FILTER_LIST, RECEIVE_LOGS, REQUEST_LOGS, REQUEST_LOGS_POLLING } from '../logs/actions'

export const REQUEST_POLLING_TASKS = 'REQUEST_POLLING_TASKS'
export const REQUEST_TASKS = 'REQUEST_TASKS'
export const RECEIVE_TASKS = 'RECEIVE_TASKS'
export const ADD_TASK = 'ADD_TASK'
export const REMOVE_TASK = 'REMOVE_TASK'
export const FINISH_TASK = 'FINISH_TASK'
export const CLEAR_TASK = 'CLEAR_TASK'
export const RECEIVE_TASK = 'RECEIVE_TASK'
export const REQUEST_BATCH_TASKS = 'REQUEST_BATCH_TASKS'
export const RECEIVE_BATCH_TASKS = 'RECEIVE_BATCH_TASKS'

export function requestPollingTasks(gatewayTasks) {
  return {
    type: REQUEST_POLLING_TASKS,
    gatewayPollingTasks: gatewayTasks.tasks,
  }
}
export function requestTasks() {
  return {
    type: REQUEST_TASKS,
  }
}
export function receiveTasks(gatewayTasks, totalTasks) {
  return {
    type: RECEIVE_TASKS,
    gatewayTasks: gatewayTasks.tasks,
    totalTasks: totalTasks,
  }
}
export function addTask(task) {
  return {
    type: ADD_TASK,
    task: task,
  }
}
export function removeTask(task) {
  return {
    type: REMOVE_TASK,
    task: task,
  }
}
export function finishTask(taskId, status, message) {
  return {
    type: FINISH_TASK,
    taskId: taskId,
    status: status,
    message: message,
  }
}

export function fetchGetToolbarTasks() {
  return async (dispatch, getState) => {
    const { tasks } = getState()
    let filter = [
      {
        property: 'tags',
        value: localStorage.getItem('__config.json__ALPHACOD_TAG'),
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'status',
        value: 'FINISHED',
        filterType: 'string',
        operator: 'ne',
      },
      {
        property: 'status',
        value: 'FAILED',
        filterType: 'string',
        operator: 'ne',
      },
    ]
    let v = encodeURIComponent(JSON.stringify(filter))
    let sort = 'sort=creationDate&dir=DESC'
    let limit = 'limit=100'
    return fetch(`${API_URL()}/tasks/page/?${sort}&${limit}&filter=${v}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(requestPollingTasks(filterAndConvertTasks(tasks, json.result)))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get tasks',
          <Fragment>
            <div>An error does not allow to get tasks:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}

export function fetchGetTasks(toDate, fromDate, runningChecked, finishedChecked, errorChecked) {
  return async (dispatch, getState) => {
    dispatch(requestTasks())
    const { tasks } = getState()
    let filter = [
      {
        property: 'tags',
        value: localStorage.getItem('__config.json__ALPHACOD_TAG'),
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'creationDate',
        value: toDate.getTime() / 1000,
        filterType: 'number',
        operator: 'lte',
      },
      {
        property: 'creationDate',
        value: fromDate.getTime() / 1000,
        filterType: 'number',
        operator: 'gte',
      },
    ]
    if (!runningChecked) {
      // ACTIVE_STATUS = ['REGISTERED', 'RUNNING', 'PAUSED', 'WAITING', 'SUBMITTING', 'SCHEDULING', 'UNKNOWN']
      filter.push(
        {
          property: 'status',
          value: 'RUNNING',
          filterType: 'string',
          operator: 'ne',
        },
        {
          property: 'status',
          value: 'REGISTERED',
          filterType: 'string',
          operator: 'ne',
        },
        {
          property: 'status',
          value: 'PAUSED',
          filterType: 'string',
          operator: 'ne',
        },
        {
          property: 'status',
          value: 'WAITING',
          filterType: 'string',
          operator: 'ne',
        },
        {
          property: 'status',
          value: 'SUBMITTING',
          filterType: 'string',
          operator: 'ne',
        },
        {
          property: 'status',
          value: 'SCHEDULING',
          filterType: 'string',
          operator: 'ne',
        },
        {
          property: 'status',
          value: 'UNKNOWN',
          filterType: 'string',
          operator: 'ne',
        },
      )
    }
    if (!finishedChecked) {
      //     COMPLETE_STATUS = ['FINISHED', 'FAILED', 'CANCELLED']
      filter.push({
        property: 'status',
        value: 'FINISHED',
        filterType: 'string',
        operator: 'ne',
      })
    }
    if (!errorChecked) {
      //  FAILURE_STATUS = ['FAILED', 'CANCELLED']
      filter.push({
        property: 'status',
        value: 'FAILED',
        filterType: 'string',
        operator: 'ne',
      })
      filter.push({
        property: 'status',
        value: 'CANCELLED',
        filterType: 'string',
        operator: 'ne',
      })
    }
    let v = encodeURIComponent(JSON.stringify(filter))
    let sort = 'sort=creationDate&dir=DESC'
    let limit = 'limit=100'
    return fetch(`${API_URL()}/tasks/page/?${sort}&${limit}&filter=${v}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveTasks(filterAndConvertTasks(tasks, json.result), json.size))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get tasks',
          <Fragment>
            <div>An error does not allow to get tasks:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}

export function fetchAddTask(task) {
  return async (dispatch) => {
    return dispatch(addTask(task))
  }
}

export function fetchFinishTask(taskId, state, message) {
  return async (dispatch) => {
    return dispatch(finishTask(taskId, state, message))
  }
}

export function fetchRemoveTask(task) {
  return async (dispatch) => {
    return dispatch(removeTask(task))
  }
}

function filterAndConvertTasks(stateTasks, tasks) {
  if (tasks) {
    const otherTask = tasks.map((gatewayTask) => ({
      id: gatewayTask._id,
      batchId:
        gatewayTask.tags
          .toString()
          .indexOf(localStorage.getItem('__config.json__ALPHACOD_SCANDIR_TAG')) !== -1 &&
        gatewayTask.inputs.filter((input) => input.name === 'batch_id')[0]
          ? gatewayTask.inputs.filter((input) => input.name === 'batch_id')[0].value
          : null,
      title:
        gatewayTask.tags
          .toString()
          .indexOf(localStorage.getItem('__config.json__ALPHACOD_SCANDATE_TAG')) !== -1
          ? 'Retrieve batches'
          : gatewayTask.tags
              .toString()
              .indexOf(localStorage.getItem('__config.json__ALPHACOD_SCANDIR_TAG')) !== -1
          ? 'Update batch'
          : 'Unknown',
      operator: gatewayTask.owner.name,
      date: gatewayTask.creationDate * 1000,
      action: 'retrieve_batch',
      percentage: gatewayTask.status === 'FINISHED' || gatewayTask.status === 'FAILED' ? 100 : 50,
      state: {
        value:
          gatewayTask.status === 'FINISHED'
            ? 'success'
            : gatewayTask.status === 'FAILED'
            ? 'error'
            : 'running',
        message: gatewayTask.message,
      },
      operation:
        gatewayTask.tags
          .toString()
          .indexOf(localStorage.getItem('__config.json__ALPHACOD_SCANDATE_TAG')) !== -1
          ? 'get all the new batches of the day'
          : gatewayTask.tags
              .toString()
              .indexOf(localStorage.getItem('__config.json__ALPHACOD_SCANDIR_TAG')) !== -1
          ? 'get all new batch data'
          : 'unknown',
      items: [
        {
          applicationName: gatewayTask.name,
          num: gatewayTask.num,
          status: gatewayTask.status,
          tags: gatewayTask.tags,
          inputs: gatewayTask.inputs,
        },
      ],
    }))

    return {
      tasks: otherTask
        .concat(
          stateTasks.tasks.filter((item) => otherTask.map((task) => task.id).indexOf(item.id) < 0),
        )
        .sort(function (a, b) {
          if (a.date < b.date) return -1
          if (a.date > b.date) return 1
          return 0
        }),
    }
  } else {
    return {
      tasks: [],
    }
  }
}

const arraysEqual = function (a, b) {
  if (a.length === 0 && b.length === 0) return true
  if (a.length !== b.length) return false

  for (const objA of a) {
    if (!b.includes(objA)) {
      return false
    }
  }
  for (const objB of b) {
    if (!a.includes(objB)) {
      return false
    }
  }
  return true
}

/*****************************/
/*  Get the task for upload  */
/*****************************/

export function fetchGetTask(taskId) {
  return async (dispatch) => {
    return fetch(`${API_URL()}/tasks/${taskId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(requestPollingUploadingTask(json))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get task',
          <Fragment>
            <div>An error does not allow to get the task:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
export function fetchClearTask() {
  return async (dispatch) => {
    return dispatch(requestClearTask())
  }
}

export function requestPollingUploadingTask(gatewayTask) {
  return {
    type: RECEIVE_TASK,
    gatewayTask: gatewayTask,
  }
}
export function requestClearTask() {
  return {
    type: CLEAR_TASK,
  }
}

let controllerTasksSaved = null
export function fetchGetBatchTasks(batchId) {
  return async (dispatch, getState) => {
    const { logs } = getState()

    if (controllerTasksSaved) {
      controllerTasksSaved.abort()
    }
    const controller = new AbortController()
    controllerTasksSaved = controller

    dispatch(requestGetBatchTasks(batchId))

    const filters = localStorage.getItem('LOG_filters')
      ? JSON.parse(localStorage.getItem('LOG_filters'))
      : FILTER_LIST
    const search = localStorage.getItem('LOG_search')

    return fetch(`${API_BO_URL()}.batch/${batchId}/tasks`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((resp) => {
        return dispatch(receiveBatchTasks(resp))
      })
      .catch((error) => {
        if (error.status === 403) {
          return dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get Tasks',
          <Fragment>
            <div>An error does not allow to get Batch tasks:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
        dispatch(receiveBatchTasks([]))
      })
      .finally(() => {
        if (controllerTasksSaved === controller) {
          controllerTasksSaved = null
        }
        controller.abort()
      })
  }
}

export function requestGetBatchTasks(batchId) {
  return {
    type: REQUEST_BATCH_TASKS,
    batchId: batchId,
  }
}

export function receiveBatchTasks(tasks) {
  return {
    type: RECEIVE_BATCH_TASKS,
    batchTasks: tasks,
  }
}
