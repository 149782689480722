// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CookieIcon, ToriiIcon } from '../common/Style'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Draggable from 'react-draggable'
import { t } from 'i18next'
import Button from '@material-ui/core/Button'
import { DATABASE, GET_TORII_BASE_URL, PUBLIC_URL, USE_OLD_TABLE } from '../../utils/config'
import Box from '@material-ui/core/Box'
import Stack from '@mui/material/Stack'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ReplayIcon from '@mui/icons-material/Replay'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import TuneIcon from '@mui/icons-material/Tune'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { withRouter } from 'react-router-dom'
import TerminalIcon from '@mui/icons-material/Terminal'
import SettingsIcon from '@mui/icons-material/Settings'
import { isClippyActivated, openInNewTab } from '../../utils/utilities'
import TimerIcon from '@mui/icons-material/Timer'
import TimerOffIcon from '@mui/icons-material/TimerOff'
import { isExecutionTimeActive } from '../../utils/utilities'
import TableChartIcon from '@mui/icons-material/TableChart'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import UserPreferencesDialog from './UserPreferencesDialog'

const useStyles = makeStyles((theme) => ({
  customModal: {
    display: 'none',
    position: 'fixed',
    top: 'calc(50% - 200px)',
    left: 'calc(50% - 300px)',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1400,
    borderRadius: 5,
    width: 350,
  },
  open: {
    display: 'block',
  },
  modalContent: {
    padding: 20,
    /* Style de votre contenu modal */
  },
  title: {
    cursor: 'move',
    color: theme.palette.primary.main,
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
    borderRadius: '5px 5px 0px 0px',
  },
  cardImg: {
    filter: 'blur(3px)',
    height: 90,
    userSelect: 'none',
    opacity: 0.5,
    borderRadius: '5px 5px 0px 0px',
    backgroundImage: 'url(' + PUBLIC_URL + '/images/helper_bkg.jpg' + ')',
    backgroundSize: 'cover',
  },
  titleCard: {
    position: 'absolute',
    top: 20,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
}))

const HelperDialog = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const modalRef = useRef(null)

  const admin = JSON.parse(localStorage.getItem('SESSION_user_json'))?.admin

  const open = useSelector(function (state) {
    return state.settings.helperPopup
  })
  const wikiCODDocLanguageAvailable = useSelector((state) => state.settings.wikiCODDoc !== null)

  const [executionTime, setExecutionTime] = React.useState(isExecutionTimeActive())
  const [clippyActivated, setClippyActivated] = React.useState(isClippyActivated())
  const [tableOldVersion, setTableOldVersion] = React.useState(USE_OLD_TABLE())
  const [openUserPrefs, setOpenUserPrefs] = React.useState(false)

  const aiAssistantActivated = useSelector(function (state) {
    return (
      typeof state?.global?.featuresConfig?.content?.ai_assistant === 'boolean' &&
      state?.global?.featuresConfig?.content?.ai_assistant
    )
  })

  const handleCleanUserPrefs = () => {
    setOpenUserPrefs(true)
    dispatch(fetchChangeSettings('helperPopup', false))
  }

  const handleDeleteCookie = () => {
    const cookieName = 'ToriiSessionId'
    // cookieStore
    //   .getAll()
    //   .then((results) =>
    //     results
    //       .filter((result) => result.name === cookieName && result.domain !== location.hostname)
    //       .forEach((cookie) =>
    //         cookieStore.delete({ name: cookieName, domain: cookie.domain }),
    //       ),
    //   )
    cookieStore.getAll().then((results) => {
      const deletePromises = results
        .filter((result) => result.name === cookieName && result.domain !== location.hostname)
        .map((cookie) => cookieStore.delete({ name: cookieName, domain: cookie.domain }))

      // Attendre que toutes les suppressions soient terminées
      Promise.all(deletePromises).then(() => {
        window.location.reload() // Recharger la page une fois toutes les promesses résolues
      })
    })
    // document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    // window.location.reload()
  }

  const openToriiInTab = () => {
    const url = `${GET_TORII_BASE_URL()}`
    openInNewTab(url)
  }

  const handleExecutionTime = () => {
    sessionStorage.setItem('table_execution_time', !executionTime)
    setExecutionTime((oldValue) => !oldValue)
  }

  const handleClippyActivated = () => {
    localStorage.setItem('SETTINGS_assistant_extra', !clippyActivated)
    dispatch(fetchChangeSettings('assistantType', !clippyActivated ? 'clippy' : 'default'))
    setClippyActivated((oldValue) => !oldValue)
  }

  const handleTableVersion = () => {
    localStorage.setItem('SETTINGS_table_old_version', !tableOldVersion)
    setTableOldVersion((oldValue) => !oldValue)
    // Refresh page to properly
    window.location.reload()
  }

  const handleOpenWiki = () => {
    dispatch(fetchChangeSettings('helperPopup', false))
    dispatch(fetchChangeSettings('wikiPopup', true))
  }

  const handleOpenLogs = () => {
    dispatch(fetchChangeSettings('helperPopup', false))
    // dispatch(fetchChangeSettings('logsPopup', true))
    // event.stopPropagation()
    // event.preventDefault()
    openInNewTab(`${window.location.origin}${window.location.pathname}?${DATABASE()}/logs/coddrive`)
  }

  const closeFct = () => {
    dispatch(fetchChangeSettings('helperPopup', false))
  }

  const closeUserPrefs = () => {
    setOpenUserPrefs(false)
  }

  function openServiceHelper() {
    dispatch(fetchChangeSettings('helperPopup', false))
    dispatch(fetchChangeSettings('servicePopup', true))
  }

  return (
    <Fragment>
      <Draggable handle=".modal-handle">
        <div ref={modalRef} className={clsx(classes.customModal, open ? classes.open : '')}>
          <Box className={clsx('modal-handle', classes.title, classes.header)}>
            <div className={classes.cardImg}></div>
            <span className={classes.titleCard}>{t('view.dialogs.config.helper.title')}</span>
          </Box>
          <div className={classes.modalContent}>
            <Stack spacing={2}>
              <ButtonGroup
                orientation="vertical"
                variant="outlined"
                aria-label="outlined button group"
              >
                {wikiCODDocLanguageAvailable && (
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<MenuBookIcon />}
                    onClick={() => handleOpenWiki()}
                  >
                    {t('view.dialogs.config.helper.wiki')}
                  </Button>
                )}
                <Button
                  color="primary"
                  startIcon={<TerminalIcon />}
                  onClick={() => handleOpenLogs()}
                >
                  {t('view.dialogs.config.helper.logs')}
                </Button>
              </ButtonGroup>
              <ButtonGroup
                orientation="vertical"
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  color="primary"
                  startIcon={<ReplayIcon />}
                  onClick={() => window.location.reload()}
                >
                  {t('view.dialogs.config.helper.reload')}
                </Button>
                <Button
                  color="primary"
                  startIcon={<PersonOutlineIcon />}
                  onClick={() => dispatch(fetchChangeSettings('loginPopup', true))}
                >
                  {t('view.dialogs.config.helper.relogin')}
                </Button>
                <Button color="primary" startIcon={<TuneIcon />} onClick={handleCleanUserPrefs}>
                  {t('view.dialogs.config.helper.pref')}
                </Button>
                <Button color="primary" startIcon={<ToriiIcon />} onClick={openToriiInTab}>
                  {t('view.dialogs.config.helper.torii')}
                </Button>
                <Button color="primary" startIcon={<CookieIcon />} onClick={handleDeleteCookie}>
                  {t('view.dialogs.config.helper.cookie')}
                </Button>
              </ButtonGroup>

              <ButtonGroup
                orientation="vertical"
                variant="outlined"
                aria-label="outlined button group"
              >
                {admin && aiAssistantActivated && (
                  <Button
                    color="primary"
                    startIcon={clippyActivated ? <AttachFileIcon /> : <SmartToyIcon />}
                    onClick={handleClippyActivated}
                  >
                    {t('view.dialogs.config.helper.assistant')}
                  </Button>
                )}
                {/*<Button color="primary" startIcon={<SettingsIcon />} onClick={openServiceHelper}>*/}
                {/*  {t('view.dialogs.config.helper.service')}*/}
                {/*</Button>*/}
                <Button
                  color="primary"
                  startIcon={executionTime ? <TimerIcon /> : <TimerOffIcon />}
                  onClick={handleExecutionTime}
                >
                  {t('view.dialogs.config.helper.time')}
                </Button>
                <Button color="primary" startIcon={<TableChartIcon />} onClick={handleTableVersion}>
                  {t('view.dialogs.config.helper.table_version')} :{' '}
                  {tableOldVersion
                    ? t('view.dialogs.config.helper.old_version')
                    : t('view.dialogs.config.helper.new_version')}
                </Button>
              </ButtonGroup>
            </Stack>
          </div>
          <Stack
            style={{ padding: 10 }}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Button color="primary" onClick={closeFct}>
              {t('view.dialogs.config.helper.close')}
            </Button>
          </Stack>
        </div>
      </Draggable>
      <UserPreferencesDialog open={openUserPrefs} closeUserPrefs={closeUserPrefs} />
    </Fragment>
  )
})
export default HelperDialog
