// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { alpha } from '@material-ui/core'
import { displayPopupMessage } from './Message'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

const styles = (theme) => ({
  batchRetired: {
    backgroundColor: theme.palette.common.error,
    width: '100%',
    height: 'calc(100% - 64px)',
    position: 'absolute',
  },
  batchRetiredTitle: {
    backgroundColor: alpha(theme.palette.common.error, 0.4),
    border: '2px solid',
    borderColor: '#ff0000',
    color: '#ff0000',
    position: 'absolute',
    zIndex: 10000000,
    top: 5,
    left: 'calc(50% - 160px)',
    padding: '0px 13px',
    borderRadius: 10,
    '& h1': {
      margin: 10,
    },
  },
  dialogRetired: {
    '& .MuiDialog-paperFullScreen': {
      background: 'transparent',
      overflow: 'hidden',
    },
  },
  contentRetired: {
    transform: 'rotate(33deg)',
    height: 115,
    backgroundSize: 420,
    backgroundImage:
      'linear-gradient(124deg, red 20%, white 20% 41%, red 41% 62%, white 62% 82%, red 82%)',
    position: 'absolute',
    width: '150%',
    top: '50%',
    left: '-20%',
    boxShadow: '1px 3px 16px -1px rgba(0,0,0,0.75)',
  },
  contentRetired2: {
    transform: 'rotate(-22deg)',
  },
  contentRetiredMessage: {
    left: '25%',
    top: 80,
    position: 'absolute',
    width: '50%',
    boxShadow: '1px 3px 16px -1px rgba(0,0,0,0.75)',
    background: 'rgb(253, 236, 234)',
    color: '#f44336',
    padding: 25,
    fontSize: 'xx-large',
    paddingLeft: 200,
    paddingBottom: 90,
    borderLeft: '15px solid rgb(97, 26, 21)',
  },
  contentRetiredMessageBis: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
})

// const RedButton = withStyles((theme) => ({
//   root: {
//     color: theme.palette.getContrastText(red[500]),
//     backgroundColor: '#f44336',
//     '&:hover': {
//       backgroundColor: red[700],
//     },
//   },
// }))(Button)
//
// const GreenButton = withStyles((theme) => ({
//   root: {
//     color: 'white',
//     backgroundColor: green[500],
//     '&:hover': {
//       backgroundColor: green[700],
//     },
//   },
// }))(Button)

export default withRouter(
  connect()(
    withStyles(styles)(
      class RetiredCmp extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            open: true,
          }
          this.handleClose = this.handleClose.bind(this)
        }

        handleClose(event) {
          this.setState({ open: false })
        }

        render() {
          const { classes, dispatch, batch } = this.props
          const { open } = this.state

          if (/*open &&*/ batch && batch.content.retired) {
            displayPopupMessage(
              dispatch,
              'error',
              t('view.common.retiredCmp.title'),
              <Fragment>
                <div>
                  <Trans i18nKey="view.common.retiredCmp.msg1">
                    The batch <i>{{ val: batch.name }}</i> is currently retired.
                  </Trans>
                </div>
                <div>{t('view.common.retiredCmp.msg2')}</div>
              </Fragment>,
            )
          }

          return (
            <span style={{ display: batch && batch.content.retired ? 'block' : 'none' }}>
              {/*<div style={{ display: open ? 'none' : 'block' }} className={classes.batchRetired} />*/}
              {/*<div*/}
              {/*    style={{ display: open ? 'none' : 'block' }}*/}
              {/*    className={classes.batchRetiredTitle}*/}
              {/*>*/}
              <div className={classes.batchRetired} />
              <div className={classes.batchRetiredTitle}>
                <h1>
                  <ErrorOutlineIcon style={{ position: 'absolute', top: 12 }} />
                  <span style={{ marginLeft: 35 }}>{t('view.common.retiredCmp.retired')}</span>
                </h1>
              </div>
              {/*<Dialog*/}
              {/*  fullScreen*/}
              {/*  open={open && batch && batch.content.retired}*/}
              {/*  onClose={(event) => this.handleClose(event)}*/}
              {/*  TransitionComponent={TransitionTop}*/}
              {/*  aria-labelledby="dialog-reset-title"*/}
              {/*  className={classes.dialogRetired}*/}
              {/*>*/}
              {/*  <DialogContent style={{ opacity: 0.9 }}>*/}
              {/*    <div className={classes.contentRetired} />*/}
              {/*    <div className={clsx(classes.contentRetired, classes.contentRetired2)} />*/}
              {/*  </DialogContent>*/}
              {/*</Dialog>*/}
              {/*<Dialog*/}
              {/*  fullScreen*/}
              {/*  open={open && batch && batch.content.retired}*/}
              {/*  onClose={(event) => this.handleClose(event)}*/}
              {/*  TransitionComponent={TransitionBottom}*/}
              {/*  aria-labelledby="dialog-reset-title"*/}
              {/*  className={clsx(classes.dialogRetired, classes.contentRetiredMessageBis)}*/}
              {/*>*/}
              {/*  <DialogContent>*/}
              {/*    <div className={classes.contentRetiredMessage}>*/}
              {/*      <WarningIcon*/}
              {/*        style={{ position: 'absolute', left: 20, width: 150, height: 150 }}*/}
              {/*      />*/}
              {/*      <div style={{ color: 'rgb(97, 26, 21)' }}>*/}
              {/*        The batch <i style={{ textDecoration: 'underline' }}>{batch.name}</i> is*/}
              {/*        currently retired.*/}
              {/*      </div>*/}
              {/*      <div style={{ color: '#6f0000' }}>You shouldn't work on it.</div>*/}
              {/*      <DialogActions*/}
              {/*        style={{ paddingTop: 23, position: 'absolute', bottom: 15, right: 15 }}*/}
              {/*      >*/}
              {/*        <GreenButton*/}
              {/*          variant="contained"*/}
              {/*          size="large"*/}
              {/*          startIcon={<VerifiedUserIcon />}*/}
              {/*          endIcon={<ArrowUpwardIcon />}*/}
              {/*          onClick={() => this.props.history.push('/')}*/}
              {/*        >*/}
              {/*          GO HOME*/}
              {/*        </GreenButton>*/}
              {/*        <RedButton*/}
              {/*          variant="contained"*/}
              {/*          size="large"*/}
              {/*          startIcon={<WarningIcon />}*/}
              {/*          endIcon={<ArrowForwardIcon />}*/}
              {/*          onClick={(event) => this.handleClose(event)}*/}
              {/*        >*/}
              {/*          CONTINUE*/}
              {/*        </RedButton>*/}
              {/*      </DialogActions>*/}
              {/*    </div>*/}
              {/*  </DialogContent>*/}
              {/*</Dialog>*/}
            </span>
          )
        }
      },
    ),
  ),
)
