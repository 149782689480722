// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { useRef, useState, useMemo, useEffect } from 'react'
import { Scatter } from 'react-chartjs-2'
import 'chartjs-plugin-dragdata'
import '../../../utils/chartjs-zoom-plugin'
import * as ChartAnnotation from 'chartjs-plugin-annotation' //DO NOT REMOVE !!!!
import { updatePeak, updateGlobalPeak, resetPeak, generateUUID } from '../../../utils/utilities'
import { ResetZoom, ResetPeak, ChromatoRecentred } from '../Style'
import { makeStyles, Tooltip } from '@material-ui/core'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import ChromatoApplyAllDialog from '../../dialog/ChromatoApplyAllDialog'
import clsx from 'clsx'
import { displayPopupMessage } from '../Message'
import { fetchChangeLock } from '../../../redux/unitaryAnalyses/actions'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockCloseIcon from '@material-ui/icons/Lock'
import { fetchChangeLockDetail } from '../../../redux/detail/actions'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import {
  GET_CHROMATO_OPACITY,
  GET_CHROMATO_WEIGHT,
  GET_DEFAULT_PEAK_MARGINS,
  GET_DEFAULT_WINDOW_CHROMATO_IS_PEAK,
  GET_DEFAUT_PEAK_MARGINS,
  ZOOM_DEFAULT_FACTO,
} from '../../../utils/config'
import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'
import { t } from 'i18next'
import { useWhyDidYouRenderTool } from '../whyDidYouRenderTool'

/**
 * Define custom styles for the Backlog component.
 * @param theme Material-UI theme object.
 */
const useStyles = makeStyles((theme) => ({
  resetZoom: {
    position: 'absolute',
    padding: 7,
    bottom: 37,
    right: 18,
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  resetPeak: {
    position: 'absolute',
    padding: 7,
    bottom: 37,
    right: 54,
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  speedDial: {
    position: 'absolute',
    top: 44,
    right: 15,
    '& button': {
      margin: '2px 8px',
    },
  },
  speedDialActionIcon: {
    width: 18,
    top: 3,
    position: 'absolute',
  },
}))

// const colorChromato = ['#16b41d', '#ff5424', '#00BCD4', '#6a32ef', '#d947c9', '#0004ff', '#f1bd22']

/**
 * ChromatoDetailScatter component renders a scatter plot for chromatographic data.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.istdView - Indicates if the view is for ISTD.
 * @param {Function} props.getSelectedChannel - Function to get the selected channel.
 * @param {Object} props.unitaryAnalyse - The unitary analysis data.
 * @param {Function} props.dispatch - The dispatch function for Redux actions.
 * @param {Object} props.collection - The collection data.
 * @param {string} [props.title=''] - The title of the scatter plot.
 * @param {boolean} [props.showLegend=false] - Flag to show or hide the legend.
 * @param {boolean} [props.showChannels=false] - Flag to show or hide channels.
 * @param {number} [props.chromatoSize=0] - The size of the chromatogram.
 * @param {Array} props.currentPeaks - The current peaks data.
 * @param {Object} props.visibilities - The visibility settings for various elements.
 * @param {Array} props.data - The data to be displayed in the scatter plot.
 * @param {string} props.displayCurve - The curve to be displayed.
 * @param {Function} props.setGraphPositionToIstd - Function to set the graph position to ISTD.
 * @param {Object} props.graphPositionISTD - The graph position for ISTD.
 * @param {boolean} props.synchIstd - Flag to synchronize ISTD.
 * @param {Function} props.setSyncIstd - Function to set the synchronization of ISTD.
 * @param {Object} props.mainUnitaryAnalyse - The main unitary analysis data.
 * @param {number} props.opacity - The opacity of the scatter plot.
 * @param {boolean} props.canEdit - Flag to allow editing.
 */
function ChromatoDetailScatter({
  istdView,
  getSelectedChannel,
  unitaryAnalyse,
  dispatch,
  collection,
  title = '',
  showLegend = false,
  showChannels = false,
  chromatoSize = 0,
  currentPeaks,
  visibilities,
  data,
  // colors,
  displayCurve,
  setGraphPositionToIstd,
  graphPositionISTD,
  synchIstd,
  setSyncIstd,
  mainUnitaryAnalyse,
  opacity,
  canEdit,
}) {
  /**
   * Custom styles for the component.
   */
  const classes = useStyles()

  /**
   * Multiplication factor for the selected chromato weight.
   */
  const selectionFactor = 3

  /**
   * Reference to the chart instance.
   */
  const chartRef = useRef(null)

  /**
   * Stable key for unique identification.
   */
  const stableKey_1 = useRef(generateUUID())

  /**
   * Another stable key for unique identification.
   */
  const stableKey_2 = useRef(generateUUID())

  /**
   * State to track if the graph has moved.
   */
  const [hasMoved, setHasMoved] = useState(false)

  /**
   * State to store the graph position.
   */
  const [graphPosition, setGraphPosition] = useState({
    'x-axis-1': {},
    'y-axis-1': {},
  })

  /**
   * State to track if the peak has changed.
   */
  const [hasChanged, setHasChanged] = useState(unitaryAnalyse?.isPeakModified)

  /**
   * State to enable or disable panning.
   */
  const [activePan, setActivePan] = useState(true)

  /**
   * State to store the coordinates of the peaks.
   */
  const [peaksCoord, setPeaksCoord] = useState(currentPeaks)

  /**
   * State to trigger interpolation update.
   */
  const [updateInterpolation, setUpdateInterpolation] = useState(false)

  /**
   * State to control the open state of the SpeedDial.
   */
  const [open, setOpen] = React.useState(false)

  /**
   * State to control the open state of the confirmation dialog.
   */
  const [openConfirm, setOpenConfirm] = React.useState(false)

  // useWhyDidYouRenderTool(
  //   'ChromatoDetailScatter',
  //   {
  //     istdView,
  //     getSelectedChannel,
  //     unitaryAnalyse,
  //     dispatch,
  //     collection,
  //     title,
  //     showLegend,
  //     showChannels,
  //     chromatoSize,
  //     currentPeaks,
  //     visibilities,
  //     data,
  //     // colors,
  //     displayCurve,
  //     setGraphPositionToIstd,
  //     graphPositionISTD,
  //     synchIstd,
  //     setSyncIstd,
  //     mainUnitaryAnalyse,
  //     opacity,
  //     canEdit,
  //   },
  //   { hasChanged, activePan, peaksCoord, updateInterpolation, open, openConfirm },
  // )

  /**
   * Generates a unique key for each dataset.
   * @returns {string} A unique key.
   */
  const datasetKeyProvider = () => {
    return btoa(Math.random()).substring(0, 12)
  }

  /**
   * Updates the peaks coordinates state when currentPeaks or updateInterpolation changes.
   */
  useEffect(() => {
    if (!updateInterpolation) {
      setPeaksCoord(currentPeaks)
    } else {
      setUpdateInterpolation(false)
    }
  }, [JSON.stringify(currentPeaks), updateInterpolation])

  /**
   * Returns the border dash pattern for a given channel.
   * @param {number} channel - The channel number.
   * @returns {number[]} The border dash pattern.
   */
  const getBorderDash = (channel) => {
    switch (channel) {
      case 1:
        return []
      case 2:
        return [6, 3]
      case 3:
        return [2, 2]
      default:
        return [2, 2]
    }
  }

  /**
   * Filters the data to get the unitary analysis for ISTD.
   */
  const unitaryAnalyseIstd = data.filter(
    (d) => d.analyseId === unitaryAnalyse.analyseId && d.isIstd,
  )[0]

  /**
   * Memoizes the datasets for the scatter plot.
   */
  const datasets = useMemo(() => {
    let datasets = [
      {
        data: unitaryAnalyse.main_channel_data.data,
        label: unitaryAnalyse.name + '_' + unitaryAnalyse.analyseId,
        backgroundColor: '#165c90',
        pointBorderColor: '#165c90',
        borderColor: '#165c90',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#e7aa70',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 0,
        showLine: true,
        hoverBorderWidth: 2,
        borderWidth:
          displayCurve === unitaryAnalyse.analyseId + unitaryAnalyse.main_channel_data.channelNumber
            ? GET_CHROMATO_WEIGHT() * selectionFactor
            : GET_CHROMATO_WEIGHT(),
        dragData: !!canEdit,
        fill: false,
        fillBetweenSet:
          mainUnitaryAnalyse.selectedChannel === unitaryAnalyse.main_channel_data.channelNumber
            ? 0
            : undefined,
        fillBetweenColor:
          mainUnitaryAnalyse.selectedChannel === unitaryAnalyse.main_channel_data.channelNumber
            ? 'rgba(225,149,66,' + opacity + ')' //'#e19542', // Add transparency
            : undefined,
        tension: 0,
        borderDash: getBorderDash(unitaryAnalyse.main_channel_data.channelNumber),
        hidden:
          !visibilities[unitaryAnalyse.filter] ||
          !visibilities.channels[unitaryAnalyse.main_channel_data.channelNumber] ||
          !visibilities.analyses[
            unitaryAnalyse.analyseId + unitaryAnalyse.main_channel_data.channelNumber
          ],
        // "#00dD0030"
        // fillBetweenSet: 1,
        // fillBetweenColor: 'rgba(255,0,0, 0.2)',
      },
    ]
    unitaryAnalyse.channels_data.forEach(function (channel, index) {
      if (
        visibilities[unitaryAnalyse.filter] &&
        visibilities.channels[channel.channelNumber] &&
        visibilities.analyses[unitaryAnalyse.analyseId + channel.channelNumber]
      ) {
        datasets.push({
          data: channel.data,
          label: unitaryAnalyse.name + '_' + unitaryAnalyse.analyseId + '_' + index,
          borderColor: '#165c90',
          // borderColor: colorChromato[parseInt(index)],
          pointBorderWidth: 0,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 0,
          showLine: true,
          hoverBorderWidth: 2,
          borderWidth:
            displayCurve === unitaryAnalyse.analyseId + channel.channelNumber
              ? GET_CHROMATO_WEIGHT() * selectionFactor
              : GET_CHROMATO_WEIGHT(),
          dragData: !!canEdit,
          fill: false,
          tension: 0,
          borderDash: getBorderDash(channel.channelNumber),
          fillBetweenSet:
            mainUnitaryAnalyse.selectedChannel === channel.channelNumber ? 0 : undefined,
          fillBetweenColor:
            mainUnitaryAnalyse.selectedChannel === channel.channelNumber
              ? 'rgba(225,149,66,' + opacity + ')'
              : undefined, //'#e19542', // Add transparency,
          hidden:
            !visibilities[unitaryAnalyse.filter] ||
            !visibilities.channels[channel.channelNumber] ||
            !visibilities.analyses[unitaryAnalyse.analyseId + channel.channelNumber],
        })
      }
    })
    return datasets
  }, [unitaryAnalyse._id, visibilities, displayCurve])

  // Restore old saved chart position/zoom after render
  // useEffect(() => {
  //   if (chartRef?.current?.chartInstance) {
  //     Object.entries(chartRef.current.chartInstance.scales).forEach(([scale, chart]) => {
  //       const tickOptions = chart.options.ticks
  //       tickOptions.min = graphPosition[scale].min
  //       tickOptions.max = graphPosition[scale].max
  //     })
  //   }
  // }, [datasets])

  // Apply compound chart position/zoom after render on ISTD chart to synchronise
  /**
   * useEffect hook to apply compound chart position/zoom after render on ISTD chart to synchronize.
   */
  useEffect(() => {
    // if (istdView && chartInstance) {
    if (chartRef?.current?.chartInstance) {
      Object.entries(chartRef.current.chartInstance.scales).forEach(([scale, chart]) => {
        if (scale === 'x-axis-1') {
          const tickOptions = chart.options.ticks
          tickOptions.min = graphPositionISTD[scale].min
          tickOptions.max = graphPositionISTD[scale].max
        }
      })
    }
    setHasMoved(true)
    // setGraphPosition(graphPositionISTD)
    setGraphPosition({ ...graphPosition, 'x-axis-1': graphPositionISTD['x-axis-1'] })
  }, [graphPositionISTD])

  /**
   * Configuration for the peaks chart dataset.
   * This dataset represents the peaks in the scatter plot.
   */
  const peaksChart =
    visibilities.peak &&
    visibilities.channels[mainUnitaryAnalyse.selectedChannel] &&
    visibilities[unitaryAnalyse.filter] &&
    visibilities.analyses[unitaryAnalyse.analyseId + mainUnitaryAnalyse.selectedChannel]
      ? {
          data:
            !visibilities.peak ||
            !visibilities.channels[mainUnitaryAnalyse.selectedChannel] ||
            !visibilities[unitaryAnalyse.filter] ||
            !visibilities.analyses[unitaryAnalyse.analyseId + mainUnitaryAnalyse.selectedChannel]
              ? []
              : peaksCoord,
          label: unitaryAnalyse.name + '_' + unitaryAnalyse.analyseId + '_Peak',
          pointStyle: 'triangle',
          backgroundColor: '#e19542',
          pointBorderColor: '#e19542',
          borderColor: '#e19542',
          pointBackgroundColor: '#e19542',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#165c90',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          showLine: false,
          borderWidth: GET_CHROMATO_WEIGHT(),
          tension: 0,
          hidden:
            !visibilities.peak ||
            !visibilities.channels[mainUnitaryAnalyse.selectedChannel] ||
            !visibilities[unitaryAnalyse.filter] ||
            !visibilities.analyses[unitaryAnalyse.analyseId + mainUnitaryAnalyse.selectedChannel],
          // fill: false,
          // fillBetweenSet: 0,
          // fillBetweenColor: 'rgba(5,5,255, 0.2)',
        }
      : {}

  let annotations = []
  if (visibilities.rt) {
    if (
      (!istdView && unitaryAnalyse.rt) ||
      (istdView && unitaryAnalyseIstd && unitaryAnalyseIstd.rt)
    ) {
      annotations.push({
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-1',
        value: istdView ? unitaryAnalyseIstd.rt : unitaryAnalyse.rt,
        borderColor: '#e19542',
        borderWidth: 1,
        label: {
          backgroundColor: '#ffffff55',
          fontColor: '#e19542',
          content: 'RT Cal',
          position: 'bottom',
          enabled: true,
          fontSize: 10,
        },
      })
    }
    if (
      !istdView &&
      unitaryAnalyse.rtCorrected
      //   ||
      // (istdView && unitaryAnalyseIstd && unitaryAnalyseIstd.rtCorrected)
    ) {
      annotations.push({
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-1',
        value: istdView ? unitaryAnalyseIstd.rtCorrected : unitaryAnalyse.rtCorrected,
        borderColor: '#165c90',
        borderWidth: 1,
        label: {
          backgroundColor: '#ffffff55',
          fontColor: '#165c90',
          content: 'RT Cor',
          position: 'top',
          enabled: true,
          fontSize: 10,
        },
      })
    }
    if (
      (istdView && unitaryAnalyseIstd && unitaryAnalyseIstd.ret_time) ||
      (!istdView &&
        mainUnitaryAnalyse &&
        mainUnitaryAnalyse.type === 'ISTD' &&
        mainUnitaryAnalyse.ret_time)
    ) {
      annotations.push({
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-1',
        value: istdView ? unitaryAnalyseIstd.ret_time : unitaryAnalyse.ret_time,
        borderColor: '#737373',
        borderWidth: 1,
        label: {
          backgroundColor: '#ffffff55',
          fontColor: '#737373',
          content: 'RT Mth',
          position: 'top',
          enabled: true,
          fontSize: 10,
        },
      })
    }
  }

  /**
   * Resets the peak to its initial state.
   *
   * @param {Object} event - The event object.
   */
  const onReset = (event) => {
    // if (initPeaks.length !== 0) {
    resetPeak(
      unitaryAnalyse,
      null,
      dispatch,
      collection,
      getSelectedChannel(unitaryAnalyse._id),
    ).then((response) => {
      const channel =
        response?.unitaryAnalysesUpdated?.uas?.[0]?.content?.channels[
          getSelectedChannel(unitaryAnalyse._id)
        ]
      const initial_selected_policy = channel?.initial_selected_policy
      const peakBase = channel?.candidates?.[initial_selected_policy]?.peak?.base
      if (peakBase) {
        setPeaksCoord([peakBase.left, peakBase.right])
      }
    })
    setUpdateInterpolation(true)
    setHasChanged(false)
    // setPeaksCoord(initPeaks)
    // } else {
    //   // Retro-compatibility before sprint15.2
    //   setHasChanged(false)
    //   displayPopupMessage(
    //     dispatch,
    //     'warning',
    //     t('view.common.chromatoDetail.chromatoDetailScatter.reset.title'),
    //     t('view.common.chromatoDetail.chromatoDetailScatter.reset.msg'),
    //   )
    // }
    setOpen(false)
  }

  /**
   * Memoized function to calculate the default window coordinates for the scatter plot.
   * It calculates the minimum and maximum x and y values with a margin.
   *
   * @returns {Object} An object containing the min and max values for x and y axes.
   */
  const defaultWindowCoord = useMemo(() => {
    const peak =
      unitaryAnalyse.channels?.[unitaryAnalyse.selectedChannel]?.candidates?.[
        unitaryAnalyse.channels?.[unitaryAnalyse.selectedChannel]?.initial_selected_policy
      ]?.peak
    let marge = 0.01
    let min_x = unitaryAnalyse.main_channel_data.data[0].x
    let max_x =
      unitaryAnalyse.main_channel_data.data[unitaryAnalyse.main_channel_data.data.length - 1].x
    // Add negative marge of 10% under 0 if the lock under 0 is unlocked
    let min_y = unitaryAnalyse.unlocked
      ? (graphPosition['y-axis-1'].max
          ? graphPosition['y-axis-1'].max
          : Object.entries(unitaryAnalyse.channels)
              .map((c) => c[1].candidates?.[c[1]?.initial_selected_policy]?.peak)
              .every((p) => p && p.ampl)
          ? Math.max(
              ...Object.keys(unitaryAnalyse.channels).map((k) =>
                Math.min(
                  unitaryAnalyse.channels[k].ampl[peak?.pos] * ZOOM_DEFAULT_FACTO(),
                  unitaryAnalyse.channels[k].ampl_max * 1.1,
                ),
              ),
            )
          : 0) * -0.1
      : 0
    let max_y = Object.entries(unitaryAnalyse.channels)
      .map((c) => c[1].candidates?.[c[1]?.initial_selected_policy]?.peak)
      .every((p) => p && p.ampl)
      ? Math.max(
          ...Object.keys(unitaryAnalyse.channels).map((k) =>
            Math.min(
              // unitaryAnalyse.channels[k].peak.ampl * ZOOM_DEFAULT_FACTO(),
              unitaryAnalyse.channels[k].ampl[peak?.pos] * ZOOM_DEFAULT_FACTO(),
              unitaryAnalyse.channels[k].ampl_max * 1.1,
            ),
          ),
        )
      : undefined
    if (GET_DEFAULT_WINDOW_CHROMATO_IS_PEAK() && peak !== undefined) {
      const margeX = GET_DEFAULT_PEAK_MARGINS() / 100
      const margeY = 0.1
      min_x = Math.min(...['left', 'right'].map((pt) => peak?.base?.[pt]?.x))
      max_x = Math.max(...['left', 'right'].map((pt) => peak?.base?.[pt]?.x))
      min_y = Math.min(...['left', 'right'].map((pt) => peak?.base?.[pt]?.y))
      const datas =
        unitaryAnalyse.selectedChannel === unitaryAnalyse.mainChannel
          ? unitaryAnalyse.main_channel_data?.data
          : unitaryAnalyse.channels_data.filter(
              (chdata) => chdata.channelNumber === unitaryAnalyse.selectedChannel,
            )[0]?.data
      const maxYInRange = (data, min, max) =>
        Math.max(
          ...data.filter((point) => point.x >= min && point.x <= max).map((point) => point.y),
        )
      max_y = maxYInRange(datas, min_x, max_x)
      return {
        min_x: min_x - (max_x - min_x) * margeX,
        max_x: max_x + (max_x - min_x) * margeX,
        min_y: min_y - (max_y - min_y) * margeY,
        max_y: max_y + (max_y - min_y) * margeY,
      }
    } else {
      return {
        min_x: min_x - (max_x - min_x) * marge,
        max_x: max_x - (max_x - min_x) * marge,
        min_y: min_y,
        max_y: max_y,
      }
    }
  }, [unitaryAnalyse.analyseId, unitaryAnalyse.name, unitaryAnalyse.selectedChannel])

  /**
   * Resets the zoom and pan to display the entire chromatogram.
   * @param {Object} e - The event object.
   */
  const onEntireChromato = (e) => {
    if (chartRef.current) {
      setHasMoved(false)
      setOpen(false)

      if (chartRef?.current?.chartInstance) {
        chartRef.current.chartInstance.resetZoom()
      }

      // Step 1 : Identify visible analyses with their channel
      const visibleEntries = Object.entries(visibilities.analyses)
        .filter(([key, isVisible]) => isVisible) // Garder uniquement les analyses visibles
        .map(([key]) => {
          // Trouver le channel à partir du dernier caractère
          const channel = parseInt(key.slice(-1), 10) // Dernier caractère est le numéro de channel
          const analyseId = key.slice(0, -1) // Tout sauf le dernier caractère représente l'analyse
          return { analyseId, channel }
        })

      // Step 2 : Initialize min/max values
      let minX = Infinity
      let maxX = -Infinity
      let minY = Infinity
      let maxY = -Infinity

      // Step 3 : Retrieve coordinates of visible analyses and channels
      visibleEntries.forEach(({ analyseId, channel }) => {
        // Find the corresponding analysis in `data`
        const analysis = data.find((item) => item.analyseId === analyseId)
        if (!analysis) return

        // Check that the channel is globally visible
        if (!visibilities.channels[channel]) return

        // Check the data of main_channel_data if the channel matches
        if (analysis.main_channel_data.channelNumber === channel) {
          analysis.main_channel_data.data.forEach((point) => {
            minX = Math.min(minX, point.x)
            maxX = Math.max(maxX, point.x)
            minY = Math.min(minY, point.y)
            maxY = Math.max(maxY, point.y)
          })
        }

        // Check the data of channels_data if the channel matches
        analysis.channels_data.forEach((channelData) => {
          if (channelData.channelNumber === channel) {
            channelData.data.forEach((point) => {
              minX = Math.min(minX, point.x)
              maxX = Math.max(maxX, point.x)
              minY = Math.min(minY, point.y)
              maxY = Math.max(maxY, point.y)
            })
          }
        })
      })
      const marge = 0.05
      setGraphPosition({
        'x-axis-1': {
          min: minX - (maxX - minX) * marge,
          max: maxX + (maxX - minX) * marge,
        },
        'y-axis-1': {
          min: minY - (maxY - minY) * marge,
          max: maxY + (maxY - minY) * marge,
        },
      })
    }
  }

  /**
   * Resets the zoom and pan to display the entire chromatogram and saves the state.
   * @param {Object} e - The event object.
   */
  const onEntireChromato_save = (e) => {
    if (chartRef.current) {
      const visibleCharts = data.filter(
        (compound) =>
          visibilities[compound.filter] &&
          visibilities.channels[compound.main_channel_data.channelNumber] &&
          (visibilities.analyses[compound.analyseId + compound.main_channel_data.channelNumber] ||
            !compound.channels_data.every(
              (cd) => !visibilities.analyses[compound.analyseId + cd.channelNumber],
            )),
      )
      if (chartRef?.current?.chartInstance) {
        chartRef.current.chartInstance.resetZoom()
      }
      setHasMoved(false)
      setOpen(false)
      // const minY = Math.min(
      //   ...Object.keys(unitaryAnalyse.channels).map((k) =>
      //     Math.min(...unitaryAnalyse.channels[k].ampl),
      //   ),
      // )
      const minY = Math.min(
        ...visibleCharts.map((chart) =>
          Math.min(
            ...Object.keys(chart.channels)
              .filter((c) => visibilities.analyses[chart.analyseId + c])
              .map((k) => Math.min(...chart.channels[k].ampl)),
          ),
        ),
      )
      // const maxY = Math.max(
      //   ...Object.keys(unitaryAnalyse.channels).map((k) =>
      //     Math.max(...unitaryAnalyse.channels[k].ampl),
      //   ),
      // )
      const maxY = Math.max(
        ...visibleCharts.map((chart) =>
          Math.max(
            ...Object.keys(chart.channels)
              .filter((c) => visibilities.analyses[chart.analyseId + c])
              .map((k) => Math.max(...chart.channels[k].ampl)),
          ),
        ),
      )
      // const minX = Math.min(...unitaryAnalyse.channels_data.map((channel) => channel.data[0].x))
      const minX = Math.min(
        ...visibleCharts.map((chart) =>
          Math.min(...chart.channels_data.map((channel) => channel.data[0].x)),
        ),
      )
      // const maxX = Math.max(
      //   ...unitaryAnalyse.channels_data.map((channel) => channel.data[channel.data.length - 1].x),
      // )
      const maxX = Math.min(
        ...visibleCharts.map((chart) =>
          Math.max(
            ...chart.channels_data.map((channel) => channel.data[channel.data.length - 1].x),
          ),
        ),
      )
      setGraphPosition({
        'x-axis-1': {
          min: minX - (maxX - minX) * 0.05,
          max: maxX + (maxX - minX) * 0.05,
        },
        'y-axis-1': {
          min: minY - (maxY - minY) * 0.05,
          max: maxY + (maxY - minY) * 0.05,
        },
      })
    }
  }

  /**
   * Focuses the view on the peak by resetting the zoom and pan.
   * @param {Object} e - The event object.
   */
  const onPeakFocus = (e) => {
    if (chartRef?.current?.chartInstance) {
      chartRef.current.chartInstance.resetZoom()
    }
    setHasMoved(false)
    setOpen(false)

    const base =
      unitaryAnalyse.channels?.[unitaryAnalyse.selectedChannel]?.candidates?.[
        unitaryAnalyse.selected_policy
      ]?.peak?.base

    const min_x = Math.min(...['left', 'right'].map((pt) => base?.[pt]?.x))
    const max_x = Math.max(...['left', 'right'].map((pt) => base?.[pt]?.x))

    const datas =
      unitaryAnalyse.selectedChannel === unitaryAnalyse.mainChannel
        ? unitaryAnalyse.main_channel_data?.data
        : unitaryAnalyse.channels_data.filter(
            (chdata) => chdata.channelNumber === unitaryAnalyse.selectedChannel,
          )[0]?.data
    const maxYInRange = (data, min, max) =>
      Math.max(...data.filter((point) => point.x >= min && point.x <= max).map((point) => point.y))

    const min_y = Math.min(...['left', 'right'].map((pt) => base?.[pt]?.y))
    const max_y = maxYInRange(datas, min_x, max_x)

    const marge = 0.1

    setGraphPosition({
      'x-axis-1': {
        min: min_x - (max_x - min_x) * marge,
        max: max_x + (max_x - min_x) * marge,
      },
      'y-axis-1': {
        min: min_y - (max_y - min_y) * marge,
        max: max_y + (max_y - min_y) * marge,
      },
    })
  }

  /**
   * Clears the zoom and pan settings, resetting the graph position.
   * @param {Object} e - The event object.
   */
  const onClear = (e) => {
    if (chartRef?.current?.chartInstance) {
      chartRef.current.chartInstance.resetZoom()
    }
    setHasMoved(false)
    setOpen(false)
    setGraphPosition({
      'x-axis-1': {},
      'y-axis-1': {},
    })
  }

  /**
   * Handles the click event on the graph.
   * If the Ctrl key is pressed, it updates the peak coordinates.
   *
   * @param {Object} event - The event object.
   * @param {number} x - The x-coordinate of the click event.
   */
  const onGraphClick = (event, x) => {
    if (!event.ctrlKey) return
    if (peaksCoord.length <= 1) {
      const selectedChannel = getSelectedChannel(unitaryAnalyse._id)
      let y = unitaryAnalyse.main_channel_data.data.find((e) => e.x >= x).y
      if (unitaryAnalyse.mainChannel !== selectedChannel) {
        y = unitaryAnalyse.channels_data
          .filter((chdata) => chdata.channelNumber === selectedChannel)[0]
          .data.find((e) => e.x >= x).y
      }
      if (y !== undefined) {
        const coord = { x: x, y: y }
        // Update peak only if we have left AND right
        if (peaksCoord.length === 1) {
          // sort the coord to have the left point always with the x min
          const coordinates = x > peaksCoord[0].x ? [...peaksCoord, coord] : [coord, ...peaksCoord]
          updatePeak(
            unitaryAnalyse,
            null,
            coordinates,
            dispatch,
            collection,
            selectedChannel,
            false,
          ).catch((error) => {
            setPeaksCoord([])
          })
        }
        setUpdateInterpolation(true)
        setHasChanged(true)
        setPeaksCoord([...peaksCoord, coord])
      }
    }
  }

  // let analysesDataset = []
  // let colorIndex = 0
  // data.forEach(function (compound) {
  //   if (unitaryAnalyse._id !== compound._id) {
  //     if (
  //       visibilities.channels[compound.main_channel_data.channelNumber] &&
  //       visibilities.analyses[compound._id]
  //     ) {
  //       analysesDataset.push({
  //         data: compound.main_channel_data.data,
  //         label: compound.name + '_' + compound.analyse + '_main',
  //         borderColor: compound.color,
  //         pointBorderWidth: 0,
  //         pointHoverRadius: 5,
  //         pointHoverBorderWidth: 2,
  //         pointRadius: 0,
  //         pointHitRadius: 0,
  //         showLine: true,
  //         borderWidth: 0.5,
  //         dragData: false,
  //         fill: false,
  //         tension: 0,
  //       })
  //     }
  //     if (visibilities.analyses[compound._id]) {
  //       compound.channels_data.forEach(function (channel) {
  //         if(visibilities.channels[channel.channelNumber]) {
  //           analysesDataset.push({
  //             data: channel.data,
  //             label: compound.name + '_' + compound.analyse + '_' + channel.channelNumber,
  //             borderColor: colors[colorIndex],
  //             pointBorderWidth: 0,
  //             pointHoverRadius: 5,
  //             pointHoverBorderWidth: 2,
  //             pointRadius: 0,
  //             pointHitRadius: 0,
  //             showLine: true,
  //             borderWidth: 0.5,
  //             dragData: false,
  //             fill: false,
  //             tension: 0,
  //           })
  //           colorIndex = colorIndex + 1
  //         }
  //       })
  //     }
  //   }
  // })

  /**
   * Memoizes the dataset for analyses.
   * Filters and processes the data to create datasets for the scatter plot.
   *
   * @returns {Array} The dataset for analyses.
   */
  const analysesDataset = useMemo(() => {
    let analysesDataset = []
    data.forEach(function (compound) {
      if (unitaryAnalyse._id !== compound._id && visibilities[compound.filter]) {
        if (
          visibilities.channels[compound.main_channel_data.channelNumber] &&
          visibilities.analyses[compound.analyseId + compound.main_channel_data.channelNumber]
        ) {
          analysesDataset.push({
            data: compound.main_channel_data.data,
            label: compound.name + '_' + compound.analyse + '_main_' + compound._id,
            borderColor: compound.color,
            pointBorderWidth: 0,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 0,
            showLine: true,
            hoverBorderWidth: 2,
            borderWidth:
              displayCurve === compound.analyseId + compound.main_channel_data.channelNumber
                ? GET_CHROMATO_WEIGHT() * selectionFactor
                : GET_CHROMATO_WEIGHT(),
            dragData: !!canEdit,
            tension: 0,
            borderDash: getBorderDash(compound.main_channel_data.channelNumber),
            hidden:
              !visibilities[compound.filter] ||
              !visibilities.channels[compound.main_channel_data.channelNumber] ||
              !visibilities.analyses[compound.analyseId + compound.main_channel_data.channelNumber],
            fill: false,
            fillBetweenSet:
              compound.isIstd &&
              compound.analyseId === unitaryAnalyse.analyseId &&
              mainUnitaryAnalyse.selectedChannel === compound.main_channel_data.channelNumber
                ? 0
                : undefined,
            fillBetweenColor:
              compound.isIstd &&
              compound.analyseId === unitaryAnalyse.analyseId &&
              mainUnitaryAnalyse.selectedChannel === compound.main_channel_data.channelNumber
                ? 'rgba(225,149,66,' + opacity + ')' //'#e19542', // Add transparency
                : undefined,
          })
        }
        compound.channels_data.forEach(function (channel) {
          if (
            visibilities.channels[channel.channelNumber] &&
            visibilities.analyses[compound.analyseId + channel.channelNumber]
          ) {
            analysesDataset.push({
              data: channel.data,
              label:
                compound.name +
                '_' +
                compound.analyseId +
                '_' +
                channel.channelNumber +
                '_' +
                compound._id,
              borderColor: compound.color,
              // borderColor: colors[colorIndex],
              pointBorderWidth: 0,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 0,
              showLine: true,
              hoverBorderWidth: 2,
              borderWidth:
                displayCurve === compound.analyseId + channel.channelNumber
                  ? GET_CHROMATO_WEIGHT() * selectionFactor
                  : GET_CHROMATO_WEIGHT(),
              dragData: !!canEdit,
              tension: 0,
              borderDash: getBorderDash(channel.channelNumber),
              hidden:
                !visibilities[compound.filter] ||
                !visibilities.channels[channel.channelNumber] ||
                !visibilities.analyses[compound.analyseId + channel.channelNumber],
              fill: false,
              fillBetweenSet:
                compound.isIstd &&
                compound.analyseId === unitaryAnalyse.analyseId &&
                mainUnitaryAnalyse.selectedChannel === channel.channelNumber
                  ? 0
                  : undefined,
              fillBetweenColor:
                compound.isIstd &&
                compound.analyseId === unitaryAnalyse.analyseId &&
                mainUnitaryAnalyse.selectedChannel === channel.channelNumber
                  ? 'rgba(225,149,66,' + opacity + ')'
                  : undefined, //'#e19542', // Add transparency,
            })
          }
          // colorIndex = colorIndex + 1
        })
      }
    })
    return analysesDataset
  }, [data, visibilities, displayCurve, unitaryAnalyse._id])

  /**
   * Handles the opening of the SpeedDial component.
   */
  const handleOpen = () => {
    setOpen(true)
  }

  /**
   * Handles the closing of the SpeedDial component.
   */
  const handleClose = () => {
    setOpen(false)
  }

  /**
   * Handles the closing of the confirmation dialog.
   *
   * @param {Object} event - The event object.
   * @param {Object} unitaryanalysis - The unitary analysis data.
   * @param {boolean} save - Flag to indicate if the changes should be saved.
   */
  const handleConfirmClose = (event, unitaryanalysis, save) => {
    setOpenConfirm(false)
    if (save) {
      updateGlobalPeak(dispatch, unitaryanalysis)
    }
  }

  /**
   * Handles the global reintegration event.
   * Opens the confirmation dialog and prevents the default event behavior.
   *
   * @param {Object} event - The event object.
   */
  const handleGlobalReintegration = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setOpenConfirm(true)
    setOpen(false)
  }

  /**
   * Toggles the lock state of the unitary analysis.
   * Dispatches actions to change the lock state in the unitary analysis and its details.
   *
   * @param {Object} e - The event object.
   */
  const onLockUnlock = (e) => {
    dispatch(fetchChangeLock(unitaryAnalyse._id, collection))
    dispatch(fetchChangeLockDetail(unitaryAnalyse._id))
    setOpen(false)
  }

  /**
   * Toggles the synchronization of the ISTD (Internal Standard) view.
   * @param {Object} event - The event object.
   */
  function handleSyncIstd(event) {
    setSyncIstd(!synchIstd)
    setOpen(false)
  }

  /**
   * Handles the panning event on the scatter plot.
   * Updates the graph position state and synchronizes the ISTD chart if necessary.
   *
   * @param {Object} event - The event object containing chart instance and scale information.
   */
  function onScatterPan(event) {
    setHasMoved(true)
    const newPos = {
      'x-axis-1': {
        min: event.chart.scales['x-axis-1'].options.ticks.min,
        max: event.chart.scales['x-axis-1'].options.ticks.max,
      },
      'y-axis-1': {
        min: event.chart.scales['y-axis-1'].options.ticks.min,
        max: event.chart.scales['y-axis-1'].options.ticks.max,
      },
    }
    setGraphPosition(newPos)
    // Send position to istd chart only from nonIstd chart
    // if (!istdView && synchIstd) {
    if (synchIstd) {
      setGraphPositionToIstd(newPos)
    }
  }

  /**
   * Handles the zoom event on the scatter plot.
   * Updates the graph position state and synchronizes the ISTD chart if necessary.
   *
   * @param {Object} event - The event object containing chart instance and scale information.
   */
  function onScatterZoom(event) {
    setHasMoved(true)
    const newPos = {
      'x-axis-1': {
        min: event.chart.scales['x-axis-1'].options.ticks.min,
        max: event.chart.scales['x-axis-1'].options.ticks.max,
      },
      'y-axis-1': {
        min: event.chart.scales['y-axis-1'].options.ticks.min,
        max: event.chart.scales['y-axis-1'].options.ticks.max,
      },
    }
    setGraphPosition(newPos)
    // Send position to istd chart only from nonIstd chart
    if (!istdView && synchIstd) {
      setGraphPositionToIstd(newPos)
    }
  }

  /**
   * Memoizes the scatter plot data, combining peaks, datasets, and analyses datasets.
   *
   * @returns {Object} The memoized scatter plot data.
   */
  const scatterDatas = useMemo(
    () => ({
      datasets: [peaksChart, ...datasets, ...analysesDataset],
    }),
    [peaksChart, datasets, analysesDataset],
  )

  /**
   * Renders the ChromatoDetailScatter component.
   *
   * @returns {JSX.Element} The rendered component.
   */
  return (
    <span
      style={{ height: visibilities.istd ? '50%' : '100%', width: '100%', position: 'relative' }}
    >
      <>
        <span style={{ position: 'absolute', top: '40px', left: '66px' }}>
          {istdView && (
            <div style={{ color: 'black', fontSize: 15, marginBottom: 5 }}>
              {mainUnitaryAnalyse.istdName}
            </div>
          )}
          {data.map(function (compound) {
            return Object.entries(compound.channels ? compound.channels : []).map((channel) => {
              if (
                visibilities.channels[channel[0]] &&
                // visibilities.analyses[compound._id + channel[0]] &&
                visibilities.analyses[compound.analyseId + channel[0]] &&
                visibilities[compound.filter]
              ) {
                return (
                  <div
                    style={{
                      color: compound.color,
                      fontSize: 10,
                      fontWeight: displayCurve === compound.analyseId + channel[0] ? 700 : 400,
                    }}
                    // key={`transition_${compound.analyse}${channel[0]}`}
                    key={stableKey_1.current + channel}
                  >
                    {`${compound.analyse} ch${channel[0]}: ${channel[1].q1} >  ${channel[1].q3}${
                      unitaryAnalyse.analyseId === compound.analyseId && // only on the opened UA
                      parseInt(channel[0]) === mainUnitaryAnalyse.selectedChannel
                        ? ' ▲'
                        : ''
                    }`}
                  </div>
                )
              } else {
                // return <span key={`transition_${compound.analyse}${channel[0]}`}></span>
                return <span key={stableKey_2.current + channel}></span>
              }
            })
          })}
          <span
            style={{
              // position: 'absolute',
              // top: 36,
              // right: 72,
              fontSize: 10,
              background: 'rgb(255 255 255 / 44%)',
              borderRadius: 5,
              padding: 2,
            }}
          >
            <Tooltip
              arrow
              placement="left-start"
              title={
                <div style={{ fontSize: 12, padding: 5 }}>
                  {t('view.common.chromatoDetail.chromatoDetailScatter.area') +
                    ' ' +
                    unitaryAnalyse.area}
                </div>
              }
            >
              <div>{`A: ${unitaryAnalyse.area ? unitaryAnalyse.area.toFixed(2) : '--'}`}</div>
            </Tooltip>
            <Tooltip
              arrow
              placement="left-start"
              title={
                <div style={{ fontSize: 12, padding: 5 }}>
                  {t('view.common.chromatoDetail.chromatoDetailScatter.height') +
                    ' ' +
                    unitaryAnalyse.height}
                </div>
              }
            >
              <div>{`H: ${unitaryAnalyse.height ? unitaryAnalyse.height.toFixed(2) : '--'}`}</div>
            </Tooltip>
            <Tooltip
              arrow
              placement="left-start"
              title={
                <div style={{ fontSize: 12, padding: 5 }}>
                  {t('view.common.chromatoDetail.chromatoDetailScatter.rtc') +
                    ' ' +
                    unitaryAnalyse.rt_peak}
                </div>
              }
            >
              <div>{`RT: ${
                unitaryAnalyse.rt_peak ? unitaryAnalyse.rt_peak.toFixed(2) : '--'
              }`}</div>
            </Tooltip>
            <Tooltip
              arrow
              placement="left-start"
              title={
                <div style={{ fontSize: 12, padding: 5 }}>
                  {t('view.common.chromatoDetail.chromatoDetailScatter.vol') +
                    ' ' +
                    unitaryAnalyse.amt_conc}
                </div>
              }
            >
              <div>{`C: ${
                unitaryAnalyse.amt_conc ? unitaryAnalyse.amt_conc.toFixed(4) : '--'
              }`}</div>
            </Tooltip>
          </span>
        </span>
      </>
      <ErrorBoundaryGuard isDialog>
        <ChromatoApplyAllDialog
          open={openConfirm}
          closeFct={handleConfirmClose}
          unitaryAnalyse={unitaryAnalyse}
        />
      </ErrorBoundaryGuard>
      <Scatter
        //   key={`scatter_${uidKey}`}
        datasetKeyProvider={datasetKeyProvider}
        ref={chartRef}
        data={scatterDatas}
        // data={{
        //   // datasets: [peaksChart, ...datasets, ...analysesDataset],
        //   datasets: [peaksChart, ...datasets, ...analysesDataset].map((d) => ({
        //     ...d,
        //     hidden: !d.active,
        //   })),
        //   //   .filter(
        //   //   (dataset) => dataset !== undefined,
        //   // ),
        //   // datasets: istdView
        //   //   ? [peaksChart, ...analysesDataset]
        //   //   : [peaksChart, ...datasets, ...analysesDataset],
        // }}
        options={{
          chartArea: {
            backgroundColor: 'rgba(255,255,255,0.5)',
          },
          animation: {
            duration: 0, //Disable animation (for performance)
          },
          responsiveAnimationDuration: 0, //Disable resize animation (for performance)
          responsive: true,
          maintainAspectRatio: false,
          dragData: !!canEdit,
          dragX: true,
          elements: {
            line: {
              tension: 0, // disables bezier curves (for performance)
            },
          },
          // No need to block under 0
          // dragOptions: {
          //   magnet: {
          //     to: function (value) {
          //       if (value.y < 0)
          //         return {
          //           x: value.x,
          //           y: 0,
          //         }
          //       return value
          //     },
          //   },
          // },
          onClick: function (event) {
            if (onGraphClick) {
              const chart = chartRef?.current?.chartInstance
              let valueX = null,
                valueY = null
              Object.values(chart?.scales).forEach((scale) => {
                if (scale.isHorizontal()) {
                  valueX = scale.getValueForPixel(event.offsetX)
                } else {
                  valueY = scale.getValueForPixel(event.offsetY)
                }
              })
              onGraphClick(event, valueX)
            }
          },
          onDrag: function (e) {
            e.preventDefault()
            setActivePan(false)
            e.target.style.cursor = 'grabbing'
          },
          onDragEnd: function (e, datasetIndex, index, value) {
            e.target.style.cursor = 'default'
            e.preventDefault()
            // CAN NOT FIND AN OTHER WAY TO GET SELECTED CHANNEL HERE
            const selectedChannel = getSelectedChannel(unitaryAnalyse._id)
            let pt = unitaryAnalyse.main_channel_data.data.find((e) => e.x >= value.x)
            if (unitaryAnalyse.mainChannel !== selectedChannel) {
              pt = unitaryAnalyse.channels_data
                .filter((chdata) => chdata.channelNumber === selectedChannel)[0]
                .data.find((e) => e.x >= value.x)
            }
            if (pt && value.y > pt.y) {
              value.y = pt.y
            }
            updatePeak(unitaryAnalyse, index, value, dispatch, collection, selectedChannel)
            setActivePan(true)
            setHasChanged(true)
          },
          hover: {
            animationDuration: 0, // Disable hover animation (for performance)
            onHover: function (e, activeElements, chart) {
              if (!chartRef.current) return
              const currentChart = chartRef.current // Récupérer l'instance du graphique
              const point = currentChart.chartInstance.getElementsAtEventForMode(
                e,
                'nearest',
                { intersect: true },
                true,
              )
              e.target.style.cursor = point.length === 1 ? 'grab' : 'default'
            },
          },
          title: {
            display: true,
            text: title,
            // fontSize: 20,
          },
          legend: {
            display: showLegend,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  min: graphPosition['x-axis-1'].min
                    ? graphPosition['x-axis-1'].min
                    : defaultWindowCoord.min_x,
                  max: graphPosition['x-axis-1'].max
                    ? graphPosition['x-axis-1'].max
                    : defaultWindowCoord.max_x,
                  // min: -2,
                  // maxTicksLimit: 5,
                  // beginAtZero: true,
                  callback: function (value) {
                    return value.toFixed(1)
                  },
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  maxTicksLimit: 4,
                  // min: unitaryAnalyse.main_channel_data.ampl_max * -0.3,
                  min: graphPosition['y-axis-1'].min
                    ? graphPosition['y-axis-1'].min
                    : defaultWindowCoord.min_y,
                  max: graphPosition['y-axis-1'].max
                    ? graphPosition['y-axis-1'].max
                    : defaultWindowCoord.max_y,
                  // Ymin = 0
                  // Ymax = max(  min(zoom_default_facto * channel[i].peak.ampl, channel[i].ampl.max()) for i in range(nb_channels) )

                  // beginAtZero: true,
                  callback: function (value) {
                    if (value < 0) {
                      return ''
                    }
                    if (value > 9999) {
                      return value.toExponential(1)
                    }
                    return value.toFixed(1)
                  },
                },
              },
            ],
          },
          annotation: {
            annotations: annotations,
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                var label =
                  '( ' +
                  parseFloat(tooltipItem.label).toFixed(2) +
                  ' : ' +
                  parseFloat(tooltipItem.value).toFixed(2) +
                  ' )'
                return label
              },
            },
          },
          plugins: {
            zoom: {
              // Container for pan options
              pan: {
                // Boolean to enable panning
                enabled: activePan,
                // Panning directions.
                mode: 'xy',
                // TODO Min and Max of range
                // rangeMin: {
                //     // Format of min zoom range depends on scale type
                //     x: null,
                //     y: null
                // },
                // rangeMax: {
                //     // Format of max zoom range depends on scale type
                //     x: null,
                //     y: null
                // }
                onPan: onScatterPan,
              },
              // Container for zoom options
              zoom: {
                // Boolean to enable zooming
                enabled: true,
                // Enable drag-to-zoom behavior
                drag: false,
                // Zooming directions.
                mode: 'xy',
                // TODO Min and Max of range
                // rangeMin: {
                //     // Format of min zoom range depends on scale type
                //     x: null,
                //     y: null
                // },
                // rangeMax: {
                //     // Format of max zoom range depends on scale type
                //     x: null,
                //     y: null
                // }
                onZoom: onScatterZoom,
              },
            },
          },
        }}
      />
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={classes.speedDial}
        icon={<SpeedDialIcon icon={<MoreHorizIcon />} openIcon={<ExpandMoreIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={'down'}
        FabProps={{
          size: 'small',
          disableFocusRipple: true,
          disableRipple: true,
        }}
      >
        <SpeedDialAction
          key={'global_display'}
          icon={<AspectRatioIcon />}
          tooltipTitle={t('view.common.chromatoDetail.chromatoDetailScatter.display')}
          onClick={() => onEntireChromato()}
        />
        <SpeedDialAction
          key={'peak_display'}
          icon={<ChromatoRecentred style={{ width: 24, height: 24 }} />}
          tooltipTitle={t('view.common.chromato.chromatoScatter.recentredWindow')}
          onClick={() => onPeakFocus()}
        />
        {visibilities.istd && (
          <SpeedDialAction
            key={'Sync chromato zoom/pan'}
            icon={synchIstd ? <LinkIcon /> : <LinkOffIcon />}
            tooltipTitle={t('view.common.chromatoDetail.chromatoDetailScatter.sync')}
            onClick={(event) => handleSyncIstd(event)}
          />
        )}
        {/*{!istdView && (*/}
        <SpeedDialAction
          FabProps={{ disabled: !canEdit }}
          key={'global integration'}
          icon={<DoneAllIcon />}
          tooltipTitle={t('view.common.chromatoDetail.chromatoDetailScatter.apply')}
          onClick={(event) => handleGlobalReintegration(event)}
        />
        {/*)}*/}
        {/*{!istdView && hasChanged && (*/}
        {hasChanged && (
          <SpeedDialAction
            FabProps={{ disabled: !canEdit }}
            key={'reset peak'}
            icon={<ResetPeak />}
            tooltipTitle={t('view.common.chromatoDetail.chromatoDetailScatter.reset_int')}
            onClick={(event) => onReset(event)}
          />
        )}
        {hasMoved && (
          <SpeedDialAction
            key={'reset zoom'}
            icon={<ResetZoom />}
            tooltipTitle={t('view.common.chromatoDetail.chromatoDetailScatter.reset_zoom')}
            onClick={() => onClear()}
          />
        )}
        {/*{!istdView && (*/}
        <SpeedDialAction
          FabProps={{ disabled: !canEdit }}
          key={'lock under zero'}
          icon={unitaryAnalyse.unlocked ? <LockOpenIcon /> : <LockCloseIcon />}
          tooltipTitle={t('view.common.chromatoDetail.chromatoDetailScatter.lock')}
          onClick={(event) => onLockUnlock(event)}
        />
        {/*)}*/}
      </SpeedDial>
    </span>
  )
}

// const areEqual = (prevProps, nextProps) => {
//   const propsChanged = Object.keys(nextProps).filter((key) => prevProps[key] !== nextProps[key])
//
//   if (propsChanged.length > 0) {
//     console.log('Changed props:', propsChanged)
//     propsChanged.forEach((prop) => {
//       console.log(`${prop} changed from`, prevProps[prop], 'to', nextProps[prop])
//     })
//   }
//
//   return propsChanged.length === 0
// }
export default React.memo(ChromatoDetailScatter /*, areEqual*/)
