// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

/**************************** Tools **********************************/

import { t } from 'i18next'

/**
 * Gets the next channel number.
 * Determines the next channel number based on the current main channel number and the available channels.
 *
 * @param {number} main_channel_number - The current main channel number.
 * @param {object} channels - An object containing the available channels.
 * @returns {number|null} The next channel number, or null if no channels are available.
 */
const getNextChannel = (main_channel_number, channels) => {
  const channels_number = Object.keys(channels).length
  if (channels_number > 0) {
    return channels_number > 1 && main_channel_number + 1 <= channels_number
      ? parseFloat(Object.keys(channels)[main_channel_number])
      : parseFloat(Object.keys(channels)[0])
  } else {
    return null
  }
}

/**
 * Extracts extra information from the provided flags.
 * Determines the highest severity level, checks for blocking flags, and identifies if all blocking flags are ignored.
 *
 * @param {Array} flags - The array of flag objects.
 * @returns {object} An object containing the highest severity level, a boolean indicating if all blocking flags are ignored, and a boolean indicating if there are any blocking flags.
 */
function extractExtraFlagInfo(flags) {
  const severityOrder = ['low', 'middle', 'high']
  const hasBlockingTrue = flags.some((item) => item.blocking === true && item.ignore === false)
  const hasBlockingExist = flags.some((item) => item.blocking === true)
  const allBlockingIgnored = flags.every((item) => !item.blocking || item.ignore)
  let highestSeverityIndex = -1
  flags.forEach((item) => {
    if (item.title.includes('Probable detection')) {
      highestSeverityIndex = Math.max(highestSeverityIndex, severityOrder.indexOf('high'))
    } else if (item.title.includes('Rare detection')) {
      highestSeverityIndex = Math.max(highestSeverityIndex, severityOrder.indexOf('middle'))
    } else if (item.title.includes('Interfering detection')) {
      highestSeverityIndex = Math.max(highestSeverityIndex, severityOrder.indexOf('low'))
    }
  })
  return highestSeverityIndex !== -1 && hasBlockingExist
    ? {
        severity: severityOrder[highestSeverityIndex],
        ignored: allBlockingIgnored,
      }
    : null
}

/**
 * Retrieves the modifications made to the unitary analysis (UA).
 * Iterates through the channels of the UA content to check if the peaks have been updated.
 *
 * @param {object} ua - The unitary analysis object.
 * @returns {Array} An array of modifications.
 */
export const getModifications = (ua) => {
  let modification = []
  // Is peaks updated ?
  Object.keys(ua.content.channels).forEach((channel) => {
    const main_peak_base_left_init = ua.content.channels[channel].base_left_init
      ? ua.content.channels[channel].base_left_init
      : null
    const main_peak_base_right_init = ua.content.channels[channel].base_right_init
      ? ua.content.channels[channel].base_right_init
      : null
    const peaks = ua.content?.channels[channel]?.candidates?.[
      ua.content?.selected_policy
    ]?.peak?.hasOwnProperty('base')
      ? ua.content.channels[channel].candidates[ua.content.selected_policy].peak.base
      : null
    if (peaks && main_peak_base_left_init && main_peak_base_right_init) {
      if (peaks.left.x !== main_peak_base_left_init.x) {
        modification.push(
          t('view.common.utils.modifications.left_x') +
            ' ' +
            main_peak_base_left_init.x +
            ' -> ' +
            peaks.left.x,
        )
      }
      if (peaks.left.y !== main_peak_base_left_init.y) {
        modification.push(
          t('view.common.utils.modifications.left_y') +
            ' ' +
            main_peak_base_left_init.y +
            ' -> ' +
            peaks.left.y,
        )
      }
      if (peaks.right.x !== main_peak_base_right_init.x) {
        modification.push(
          t('view.common.utils.modifications.right_x') +
            ' ' +
            main_peak_base_right_init.x +
            ' -> ' +
            peaks.right.x,
        )
      }
      if (peaks.right.y !== main_peak_base_right_init.y) {
        modification.push(
          t('view.common.utils.modifications.right_y') +
            ' ' +
            main_peak_base_right_init.y +
            ' -> ' +
            peaks.right.y,
        )
      }
    }
  })
  return modification
}

/**************************** REBUILD ANALYSES FOR GUI **********************************/

/**
 * Rebuilds the analyses object for the GUI.
 * Sorts the global flags of the analyses content by title.
 *
 * @param {object} analyses - The analyses object to be rebuilt.
 * @returns {object} The rebuilt analyses object with sorted global flags.
 */
export function rebuildAnalyses(analyses) {
  return {
    ...analyses,
    content: {
      ...analyses.content,
      flags: {
        globals: analyses.content.flags.sort(function (a, b) {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        }),
      },
    },
  }
}

/**************************** REBUILD UA FOR GUI **********************************/
/**
 * Computes the unitary analyses for the global table based on the given criteria.
 * This function extracts specific values from the unitary analysis object according to the provided criteria.
 *
 * @param {object} unitAnalyse - The unitary analysis object.
 * @param {string} criteria - The criteria used to extract values from the unitary analysis object.
 * @returns {any} The computed value based on the criteria.
 */
export function computeUnitaryAnalysesForGlobalTable(unitAnalyse, criteria) {
  // Function to get the value from a complexe object
  function computeSpecialValue(currentObject) {
    const channel = localStorage.getItem('GLOBAL_table_channel')
      ? localStorage.getItem('GLOBAL_table_channel')
      : 'main'
    switch (criteria) {
      // ion_ratio
      case 'content.ion_ratio[&]content.main_ion_ratio_key':
        return currentObject.content.ion_ratio && currentObject.content.main_ion_ratio_key
          ? currentObject.content.ion_ratio[currentObject.content.main_ion_ratio_key]
          : null
      // modified
      case '_id[&]creationDate[&]modificationDate':
        return currentObject.creationDate !== currentObject.modificationDate
      // validation
      case 'content.validation[&]content.classification':
        return currentObject.content.validation
      // rt
      case 'content.main_channel[&]content.channels.[CHANNEL].rt_calib':
        let valueRt = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueRt = currentObject.content.channels[currentObject.content.main_channel].rt_calib
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueRt = currentObject.content.channels[channel].rt_calib
        }
        return valueRt
      // mass_conc
      case 'content.main_channel[&]content.channels.[CHANNEL].concentration_mass':
        let valueMC = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueMC =
            currentObject.content.channels[currentObject.content.main_channel].concentration_mass
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueMC = currentObject.content.channels[channel].concentration_mass
        }
        return valueMC
      // amt_conc
      case 'content.main_channel[&]content.channels.[CHANNEL].concentration[&]content.channels.[CHANNEL].q1':
        let valueC = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueC = currentObject.content.channels[currentObject.content.main_channel].concentration
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueC = currentObject.content.channels[channel].concentration
        }
        return valueC
      // loq
      case 'content.parameters.loq[&]content.main_channel[&]content.channels.[CHANNEL].q1':
        if (currentObject.content && currentObject.content.parameters) {
          return currentObject.content.parameters.loq
        }
        return null
      // mass_conc_rdt
      case 'content.main_channel[&]content.channels.[CHANNEL].concentration_mass_rdt':
        let valueCMR = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueCMR =
            currentObject.content.channels[currentObject.content.main_channel]
              .concentration_mass_rdt
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueCMR = currentObject.content.channels[channel].concentration_mass_rdt
        }
        return valueCMR
      // area
      case 'content.main_channel[&]content.channels.[CHANNEL].peak.area':
      case 'content.main_channel[&]content.channels.[CHANNEL].selected_policy[&]content.channels.[CHANNEL].candidates.[CANDIDATES].peak.area':
        if (
          channel === 'main' &&
          currentObject.content?.channels?.[currentObject.content?.main_channel]?.candidates?.[
            currentObject.content?.channels[currentObject.content?.main_channel]?.selected_policy
          ]?.peak?.hasOwnProperty('area')
        ) {
          return currentObject.content.channels?.[currentObject.content.main_channel].candidates[
            currentObject.content.channels[currentObject.content.main_channel].selected_policy
          ].peak.area
        } else if (
          currentObject.content?.channels?.[channel]?.candidates?.[
            currentObject?.content?.channels?.[channel]?.selected_policy
          ]?.peak?.hasOwnProperty('area')
        ) {
          return currentObject.content.channels[channel].candidates[
            currentObject.content.channels[channel].selected_policy
          ].peak.area
        } else {
          return null
        }
      // isArea
      case 'content.main_channel[&]content.channels.[CHANNEL].istd_area':
        let valueA = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueA = currentObject.content.channels[currentObject.content.main_channel].istd_area
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueA = currentObject.content.channels[channel].istd_area
        }
        return valueA
      // areaRatio
      case 'content.main_channel[&]content.channels.[CHANNEL].area_ratio':
        let valueR = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueR = currentObject.content.channels[currentObject.content.main_channel].area_ratio
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueR = currentObject.content.channels[channel].area_ratio
        }
        return valueR
      // s_n
      case 'content.main_channel[&]content.channels.[CHANNEL].selected_policy[&]content.channels.[CHANNEL].candidates.[CANDIDATES].peak.snrs':
        let peak = null
        if (
          channel === 'main' &&
          currentObject.content?.channels?.[currentObject.content?.main_channel]?.candidates?.[
            currentObject.content?.channels[currentObject.content?.main_channel]?.selected_policy
          ]?.hasOwnProperty('peak')
        ) {
          peak =
            currentObject.content.channels?.[currentObject.content.main_channel].candidates[
              currentObject.content.channels[currentObject.content.main_channel].selected_policy
            ].peak
        } else if (
          currentObject.content?.channels?.[channel]?.candidates?.[
            currentObject?.content?.channels?.[channel]?.selected_policy
          ]?.hasOwnProperty('peak')
        ) {
          peak =
            currentObject.content.channels[channel].candidates[
              currentObject.content.channels[channel].selected_policy
            ].peak
        }
        if (peak !== null) {
          return peak?.snrs?.methods?.[peak.snrs?.default]
            ? parseFloat(peak?.snrs?.methods?.[peak.snrs?.default].value)
            : null
        } else {
          return null
        }
      // conc_target
      case 'content.gt_concentration[&]content.main_channel[&]content.channels.[CHANNEL].q1':
        if (currentObject.content) {
          return currentObject.content.gt_concentration
        }
        return null
      // height
      case 'content.main_channel[&]content.channels.[CHANNEL].selected_policy[&]content.channels.[CHANNEL].candidates.[CANDIDATES].peak.ampl':
        if (
          channel === 'main' &&
          currentObject.content?.channels?.[currentObject.content?.main_channel]?.candidates?.[
            currentObject.content?.channels[currentObject.content?.main_channel]?.selected_policy
          ]?.peak?.hasOwnProperty('ampl')
        ) {
          return currentObject.content.channels?.[currentObject.content.main_channel].candidates[
            currentObject.content.channels[currentObject.content.main_channel].selected_policy
          ].peak.ampl
        } else if (
          currentObject.content?.channels?.[channel]?.candidates?.[
            currentObject?.content?.channels?.[channel]?.selected_policy
          ]?.peak?.hasOwnProperty('ampl')
        ) {
          return currentObject.content.channels[channel].candidates[
            currentObject.content.channels[channel].selected_policy
          ].peak.ampl
        } else {
          return null
        }
      // refIons
      case 'content.main_channel[&]content.channels.[CHANNEL].q1[&]content.channels.[CHANNEL].q3[&]content.molecule.channels.[CHANNEL].q1[&]content.molecule.channels.[CHANNEL].q3':
        let valueRI = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels
        ) {
          valueRI =
            getNextChannel(currentObject.content.main_channel, currentObject.content.channels) &&
            currentObject.content.molecule.channels[
              getNextChannel(currentObject.content.main_channel, currentObject.content.channels)
            ]
              ? currentObject.content.molecule.channels[
                  getNextChannel(currentObject.content.main_channel, currentObject.content.channels)
                ].q1 +
                ' > ' +
                currentObject.content.molecule.channels[
                  getNextChannel(currentObject.content.main_channel, currentObject.content.channels)
                ].q3
              : ''
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueRI =
            getNextChannel(
              currentObject.content.channels[channel],
              currentObject.content.channels,
            ) &&
            currentObject.content.molecule.channels[
              getNextChannel(
                currentObject.content.channels[channel],
                currentObject.content.channels,
              )
            ]
              ? currentObject.content.molecule.channels[
                  getNextChannel(
                    currentObject.content.channels[channel],
                    currentObject.content.channels,
                  )
                ].q1 +
                ' > ' +
                currentObject.content.molecule.channels[
                  getNextChannel(
                    currentObject.content.channels[channel],
                    currentObject.content.channels,
                  )
                ].q3
              : ''
        }
        return valueRI
      // rt_peak
      case 'content.main_channel[&]content.channels.[CHANNEL].selected_policy[&]content.channels.[CHANNEL].candidates.[CANDIDATES].peak.time':
        if (
          channel === 'main' &&
          currentObject.content?.channels?.[currentObject.content?.main_channel]?.candidates?.[
            currentObject.content?.channels[currentObject.content?.main_channel]?.selected_policy
          ]?.peak?.hasOwnProperty('time')
        ) {
          return currentObject.content.channels?.[currentObject.content.main_channel].candidates[
            currentObject.content.channels[currentObject.content.main_channel].selected_policy
          ].peak.time
        } else if (
          currentObject.content?.channels?.[channel]?.candidates?.[
            currentObject?.content?.channels?.[channel]?.selected_policy
          ]?.peak?.hasOwnProperty('time')
        ) {
          return currentObject.content.channels[channel].candidates[
            currentObject.content.channels[channel].selected_policy
          ].peak.time
        } else {
          return null
        }
      // devRt
      case 'content.main_channel[&]content.channels.[CHANNEL].dev_rt':
        let valueRT = null
        if (
          channel === 'main' &&
          currentObject?.content?.channels?.[currentObject?.content?.main_channel]
        ) {
          valueRT = currentObject.content.channels[currentObject.content.main_channel].dev_rt
        } else if (currentObject?.content?.channels?.[channel]) {
          valueRT = currentObject.content.channels[channel].dev_rt
        }
        return valueRT
      // devRrt
      case 'content.main_channel[&]content.channels.[CHANNEL].dev_rrt_calibration':
        let valueRRT = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueRRT =
            currentObject.content.channels[currentObject.content.main_channel].dev_rrt_calibration
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueRRT = currentObject.content.channels[channel].dev_rrt_calibration
        }
        return valueRRT
      // devAcc
      case 'content.main_channel[&]content.channels.[CHANNEL].dev_accuracy':
        let valueACC = null
        if (
          channel === 'main' &&
          currentObject.content &&
          currentObject.content.main_channel &&
          currentObject.content.channels &&
          currentObject.content.channels[currentObject.content.main_channel]
        ) {
          valueACC = currentObject.content.channels[currentObject.content.main_channel].dev_accuracy
        } else if (
          currentObject.content &&
          currentObject.content.channels &&
          currentObject.content.channels[channel]
        ) {
          valueACC = currentObject.content.channels[channel].dev_accuracy
        }
        return valueACC
    }
  }

  let objetCourant = unitAnalyse
  // Case for multiple include in request
  if (criteria.indexOf('[&]') !== -1) {
    let concValues = {}
    criteria.split('[&]').forEach(function (c) {
      // Add all non content elements
      if (c.indexOf('content') !== 0) {
        let objetRec = unitAnalyse
        const segments = c.split('.')
        for (let i = 0; i < segments.length; i++) {
          let segment = segments[i]
          objetRec = objetRec[segment]
          if (objetRec === undefined) {
            console.log('Le chemin est invalide.')
            break
          } else if (i === segments.length - 1) {
            concValues[segment] = objetRec
          }
        }
      }
    })
    // Add the content elements
    concValues['content'] = unitAnalyse.content
    objetCourant = computeSpecialValue(concValues)
  } else {
    // Case for single include in request
    const segments = criteria.split('.')
    for (let i = 0; i < segments.length; i++) {
      let segment = segments[i]
      objetCourant = objetCourant[segment]
      // Assurez-vous que l'objet existe à chaque niveau
      if (objetCourant === undefined) {
        // console.log('Le chemin est invalide.')
        break
      }
    }
  }
  return {
    name: unitAnalyse.name,
    _id: unitAnalyse._id,
    analysisName: unitAnalyse.content.analysis.name,
    batchId: unitAnalyse.content.batch.id,
    event: unitAnalyse.content.molecule.event,
    analyseId: unitAnalyse.content.analysis.id,
    value: objetCourant,
    raw: unitAnalyse,
  }
}

/**
 * Rebuilds the unitary analyses object for the GUI.
 * Sorts the flags of each channel in the unitary analysis content by title.
 *
 * @param {object} unitAnalyse - The unitary analysis object to be rebuilt.
 * @returns {object} The rebuilt unitary analysis object with sorted channel flags.
 */
export function rebuildUnitaryAnalyses(unitAnalyse) {
  let channelsFlags = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    if (entry[1].flags.length !== 0)
      channelsFlags[entry[0]] = entry[1].flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      })
  })
  let channelsClassification = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    channelsClassification[entry[0]] = entry[1].classification
  })

  const amt_conc_channels = {}
  for (
    let step = 1;
    step <=
    Number(localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'));
    step++
  ) {
    if (
      unitAnalyse.content.channels &&
      unitAnalyse.content.channels[step] &&
      unitAnalyse.content.channels[step].concentration &&
      unitAnalyse.content.channels[step].concentration !== '' &&
      unitAnalyse.content.channels[step].concentration !== null
    ) {
      amt_conc_channels['amt_conc_' + step] = unitAnalyse.content.channels[step].concentration
    }
  }

  const mass_conc_channels = {}
  for (
    let step = 1;
    step <=
    Number(localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'));
    step++
  ) {
    const isValidConcentration = unitAnalyse.content?.channels?.[step]?.concentration_mass
    if (
      isValidConcentration !== '' &&
      isValidConcentration !== null &&
      isValidConcentration !== undefined
    ) {
      mass_conc_channels['mass_conc_' + step] = parseFloat(isValidConcentration)
    }
  }

  const peak =
    unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]?.candidates?.[
      unitAnalyse.content?.selected_policy
    ]?.peak

  return {
    selected_policy: unitAnalyse.content?.selected_policy,
    recovery_factor: unitAnalyse.content.recovery_factor,
    extraFlags: extractExtraFlagInfo(unitAnalyse.content.flags),
    ...amt_conc_channels,
    recovery: unitAnalyse.content.channels?.[unitAnalyse.content?.main_channel]?.recovery,
    parameters: unitAnalyse.content.parameters,
    dev_ion_ratio: unitAnalyse.content.main_dev_ion_ratio,
    ion_ratio:
      unitAnalyse.content.ion_ratio && unitAnalyse.content.main_ion_ratio_key
        ? unitAnalyse.content.ion_ratio[unitAnalyse.content.main_ion_ratio_key]
        : null,
    use_loq_default:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['use_loq_default']
        ? unitAnalyse.content.parameters['use_loq_default']
        : '',
    event: unitAnalyse.content.molecule.event,
    analyse: unitAnalyse.content.analysis.name,
    analyseId: unitAnalyse.content.analysis.id,
    rt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_calib
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_calib)
      : '',
    rtCorrected: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_corrected
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_corrected)
      : '',
    peakName: unitAnalyse.content.molecule,
    mass_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass,
        )
      : '',
    ...mass_conc_channels,
    mass_conc_rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .concentration_mass_rdt
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass_rdt,
        )
      : '',
    rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt)
      : '',
    amt_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration)
      : '',
    area: peak?.hasOwnProperty('area') ? parseFloat(peak.area) : '',
    // r2: '',
    isArea:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel] &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_area
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_area)
        : '',
    areaRatio:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel] &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].area_ratio
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].area_ratio)
        : '',
    isPeak_istdGroup: unitAnalyse.content.molecule.istd_name,
    istd_state: unitAnalyse.content.channels?.[unitAnalyse?.content?.main_channel]?.istd_state
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_state
      : '',
    istd_dev_area: unitAnalyse.content.channels?.[unitAnalyse?.content?.main_channel]?.istd_dev_area
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_dev_area)
      : '',
    s_n:
      peak && peak?.snrs?.methods?.[peak.snrs?.default]
        ? parseFloat(peak?.snrs?.methods?.[peak.snrs?.default].value)
        : '',
    tailing_factor: peak?.hasOwnProperty('tailing_factor') ? parseFloat(peak.tailing_factor) : '',
    asymetry_factor: peak?.hasOwnProperty('asymetry_factor')
      ? parseFloat(peak.asymetry_factor)
      : '',
    sampleType: unitAnalyse.content.sample_type,
    conc_target: unitAnalyse.content.gt_concentration
      ? parseFloat(unitAnalyse.content.gt_concentration)
      : null,
    level: unitAnalyse.content.level ? unitAnalyse.content.level : null,
    height: peak?.hasOwnProperty('ampl') ? parseFloat(peak.ampl) : '',
    type: unitAnalyse.content.istd && unitAnalyse.content.istd === 'True' ? 'ISTD' : 'Target',
    m_z: unitAnalyse.content.main_channel,
    refIons: getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
      ? unitAnalyse.content.molecule.channels[
          getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
        ].q1 +
        ' > ' +
        unitAnalyse.content.molecule.channels[
          getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
        ].q3
      : '',
    devRt: unitAnalyse?.content?.channels?.[unitAnalyse?.content?.main_channel]?.dev_rt
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rt)
      : '',
    devRrt:
      unitAnalyse.content.main_channel &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rrt_calibration
        ? parseFloat(
            unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rrt_calibration,
          )
        : '',
    devAccuracy: unitAnalyse.content.channels[unitAnalyse.content.main_channel.toString()]
      .dev_accuracy
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel.toString()].dev_accuracy,
        )
      : '',
    status: unitAnalyse.content.classification,
    validation: unitAnalyse.content.validation,
    main_channel_data: {
      channelNumber: unitAnalyse.content.main_channel,
      data: unitAnalyse.content.time.map((v, i) => ({
        x: v,
        y: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl[i],
      })),
      q1: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q1
        : null,
      q3: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q3
        : null,
      ampl_max: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl_max,
    },
    name: unitAnalyse.name,
    main_peak_base: peak ? peak.base : [],
    main_peak_base_left_init: unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]
      ?.candidates?.[unitAnalyse.content?.initial_selected_policy]?.peak?.base?.left
      ? unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]?.candidates?.[
          unitAnalyse.content?.initial_selected_policy
        ]?.peak?.base?.left
      : [],
    main_peak_base_right_init: unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]
      ?.candidates?.[unitAnalyse.content?.initial_selected_policy]?.peak?.base?.right
      ? unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]?.candidates?.[
          unitAnalyse.content?.initial_selected_policy
        ]?.peak?.base?.right
      : [],
    isPeakModified: Object.values(unitAnalyse.content?.channels || {}).some(
      (channel) => channel?.selected_policy !== channel?.initial_selected_policy,
    ),
    channels_data: Object.keys(unitAnalyse.content.channels)
      .filter((key) => parseInt(key) !== unitAnalyse.content.main_channel)
      .map(function (key) {
        let channel = unitAnalyse.content.channels[key]
        return {
          channelNumber: parseInt(key),
          data: unitAnalyse.content.time.map((v, i) => ({
            x: v,
            y: channel.ampl[i],
          })),
          q1: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q1
            : null,
          q3: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q3
            : null,
          peak: channel?.candidates?.[unitAnalyse.content?.selected_policy]?.peak,
        }
      }),
    rt_peak: peak?.hasOwnProperty('time') ? parseFloat(peak.time) : '',
    _id: unitAnalyse._id,
    batch_name: unitAnalyse.content.batch.name,
    batchId: unitAnalyse.content.batch.id,
    global_classification: unitAnalyse.content.classification,
    requested: unitAnalyse.content.requested ? 'True' : 'False',
    modification_date: new Date(unitAnalyse.modificationDate * 1000).toLocaleString(),
    flags: {
      globals: unitAnalyse.content.flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      }),
      channels: channelsFlags,
    },
    classification: {
      global: unitAnalyse.content.classification,
      channels: channelsClassification,
    },
    analyseType: unitAnalyse.content.analysis_type,
    mainChannel: unitAnalyse.content.main_channel,
    selectedChannel: unitAnalyse.content.main_channel,
    channelDate: new Date().toString(),
    channels: unitAnalyse.content.channels,
    global_score: unitAnalyse.content.global_score,
    // modified: getModifications(unitAnalyse),
    modified: unitAnalyse.creationDate !== unitAnalyse.modificationDate,
    notes: unitAnalyse.content.notes ? unitAnalyse.content.notes : [],
    loq:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['loq']
        ? parseFloat(unitAnalyse.content.parameters['loq'])
        : '',
    unlocked:
      peak?.base?.hasOwnProperty('left') &&
      peak?.base?.hasOwnProperty('right') &&
      (peak.base.left.y < 0 || peak.base.right.y < 0),
    lmr: unitAnalyse.content.molecule && unitAnalyse.content.molecule.eu_lmr,
    score_details: unitAnalyse.content.score_details,
    ret_time: unitAnalyse.content.molecule.ret_time,
    istdEvent: unitAnalyse.content.molecule.istd,
    istdName: unitAnalyse.content.molecule.istd_name,
    peak: peak?.priority,
  }
}

/**
 * Rebuilds the unitary compounds object for the GUI.
 * Sorts the flags of each channel in the unitary analysis content by title.
 *
 * @param {object} unitAnalyse - The unitary analysis object to be rebuilt.
 * @returns {object} The rebuilt unitary analysis object with sorted channel flags.
 */
export function rebuildUnitaryCompounds(unitAnalyse) {
  let channelsFlags = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    if (entry[1].flags.length !== 0)
      channelsFlags[entry[0]] = entry[1].flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      })
  })
  let channelsClassification = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    channelsClassification[entry[0]] = entry[1].classification
  })
  const amt_conc_channels = {}
  for (
    let step = 1;
    step <=
    Number(localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'));
    step++
  ) {
    if (
      unitAnalyse.content.channels &&
      unitAnalyse.content.channels[step] &&
      unitAnalyse.content.channels[step].concentration &&
      unitAnalyse.content.channels[step].concentration !== '' &&
      unitAnalyse.content.channels[step].concentration !== null
    ) {
      amt_conc_channels['amt_conc_' + step] = unitAnalyse.content.channels[step].concentration
    }
  }
  const mass_conc_channels = {}
  for (
    let step = 1;
    step <=
    Number(localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'));
    step++
  ) {
    const isValidConcentration = unitAnalyse.content?.channels?.[step]?.concentration_mass
    if (
      isValidConcentration !== '' &&
      isValidConcentration !== null &&
      isValidConcentration !== undefined
    ) {
      mass_conc_channels['mass_conc_' + step] = parseFloat(isValidConcentration)
    }
  }
  const peak =
    unitAnalyse.content?.channels[unitAnalyse.content?.main_channel]?.candidates?.[
      unitAnalyse.content?.selected_policy
    ]?.peak
  return {
    selected_policy: unitAnalyse.content?.selected_policy,
    recovery_factor: unitAnalyse.content.recovery_factor,
    extraFlags: extractExtraFlagInfo(unitAnalyse.content.flags),
    recovery: unitAnalyse.content.channels?.[unitAnalyse.content?.main_channel]?.recovery,
    ...amt_conc_channels,
    '#': unitAnalyse.content.order,
    dev_ion_ratio: unitAnalyse.content.main_dev_ion_ratio,
    ion_ratio:
      unitAnalyse.content.ion_ratio && unitAnalyse.content.main_ion_ratio_key
        ? unitAnalyse.content.ion_ratio[unitAnalyse.content.main_ion_ratio_key]
        : null,
    order: unitAnalyse.content.order,
    event: unitAnalyse.content.molecule.event,
    analyse: unitAnalyse.content.analysis.name,
    analyseId: unitAnalyse.content.analysis.id,
    rt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_calib
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_calib)
      : '',
    rtCorrected: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_corrected
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rt_corrected)
      : '',
    peakName: unitAnalyse.content.molecule.name,
    mass_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass,
        )
      : '',
    ...mass_conc_channels,
    mass_conc_rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .concentration_mass_rdt
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass_rdt,
        )
      : '',
    rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt)
      : '',
    amt_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration)
      : '',
    area: peak?.hasOwnProperty('area') ? parseFloat(peak.area) : '',
    // r2: '',
    isArea:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel] &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_area
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_area)
        : '',
    areaRatio:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel] &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].area_ratio
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].area_ratio)
        : '',
    isPeak_istdGroup: unitAnalyse.content.molecule.istd_name,
    istd_state: unitAnalyse.content.channels?.[unitAnalyse?.content?.main_channel]?.istd_state
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_state
      : '',
    istd_dev_area: unitAnalyse.content.channels?.[unitAnalyse?.content?.main_channel]?.istd_dev_area
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].istd_dev_area)
      : '',
    s_n:
      peak && peak?.snrs?.methods?.[peak.snrs?.default]
        ? parseFloat(peak?.snrs?.methods?.[peak.snrs?.default].value)
        : '',
    tailing_factor: peak?.hasOwnProperty('tailing_factor') ? parseFloat(peak.tailing_factor) : '',
    asymetry_factor: peak?.hasOwnProperty('asymetry_factor')
      ? parseFloat(peak.asymetry_factor)
      : '',
    sampleType: unitAnalyse.content.sample_type,
    conc_target: unitAnalyse.content.gt_concentration
      ? parseFloat(unitAnalyse.content.gt_concentration)
      : null,
    level: unitAnalyse.content.level ? unitAnalyse.content.level : null,
    height: peak?.hasOwnProperty('ampl') ? parseFloat(peak.ampl) : '',
    // type: unitAnalyse.content.molecule.istd_name,
    type: unitAnalyse.content.istd && unitAnalyse.content.istd === 'True' ? 'ISTD' : 'Target',
    m_z: unitAnalyse.content.main_channel,
    refIons: getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
      ? unitAnalyse.content.molecule.channels[
          getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
        ].q1 +
        ' > ' +
        unitAnalyse.content.molecule.channels[
          getNextChannel(unitAnalyse.content.main_channel, unitAnalyse.content.channels)
        ].q3
      : '',
    devRt: unitAnalyse?.content?.channels?.[unitAnalyse?.content?.main_channel]?.dev_rt
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rt)
      : '',
    devRrt:
      unitAnalyse.content.main_channel &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rrt_calibration
        ? parseFloat(
            unitAnalyse.content.channels[unitAnalyse.content.main_channel].dev_rrt_calibration,
          )
        : '',
    devAccuracy: unitAnalyse.content.channels[unitAnalyse.content.main_channel.toString()]
      .dev_accuracy
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel.toString()].dev_accuracy,
        )
      : '',
    status: unitAnalyse.content.classification,
    validation: unitAnalyse.content.validation,
    // unitaryAnalyse: unitAnalyse,
    main_channel_data: {
      channelNumber: unitAnalyse.content.main_channel,
      area: peak?.hasOwnProperty('area') ? parseFloat(peak.area) : '',
      concentration: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration)
        : '',
      data: unitAnalyse.content.time.map((v, i) => ({
        x: v,
        y: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl[i],
      })),
      q1: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q1
        : null,
      q3: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q3
        : null,
      ampl_max: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl_max,
    },
    name: unitAnalyse.name,
    main_peak_base: peak ? peak.base : [],
    main_peak_base_left_init: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .base_left_init
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].base_left_init
      : [],
    main_peak_base_right_init: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .base_right_init
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].base_right_init
      : [],
    isPeakModified: Object.values(unitAnalyse.content?.channels || {}).some(
      (channel) => channel?.selected_policy !== channel?.initial_selected_policy,
    ),
    channels_data: Object.keys(unitAnalyse.content.channels)
      .filter((key) => parseInt(key) !== unitAnalyse.content.main_channel)
      .map(function (key) {
        let channel = unitAnalyse.content.channels[key]
        return {
          channelNumber: parseInt(key),
          concentration: channel.concentration,
          area: channel?.candidates?.[unitAnalyse.content?.selected_policy]?.peak?.hasOwnProperty(
            'area',
          )
            ? channel.candidates[unitAnalyse.content.selected_policy].peak.area
            : '',
          data: unitAnalyse.content.time.map((v, i) => ({
            x: v,
            y: channel.ampl[i],
          })),
          q1: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q1
            : null,
          q3: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q3
            : null,
          peak: channel?.candidates?.[unitAnalyse.content?.selected_policy]?.peak,
        }
      }),
    rt_peak: peak?.hasOwnProperty('time') ? parseFloat(peak.time) : '',
    _id: unitAnalyse._id,
    batch_name: unitAnalyse.content.batch.name,
    batchId: unitAnalyse.content.batch.id,
    global_classification: unitAnalyse.content.classification,
    requested: unitAnalyse.content.requested ? 'True' : 'False',
    modification_date: new Date(unitAnalyse.modificationDate * 1000).toLocaleString(),
    flags: {
      globals: unitAnalyse.content.flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      }),
      channels: channelsFlags,
    },
    classification: {
      global: unitAnalyse.content.classification,
      channels: channelsClassification,
    },
    analyseType: unitAnalyse.content.analysis_type,
    mainChannel: unitAnalyse.content.main_channel,
    selectedChannel: unitAnalyse.content.main_channel,
    channels: unitAnalyse.content.channels,
    global_score: unitAnalyse.content.global_score,
    // modified: getModifications(unitAnalyse),
    modified: unitAnalyse.creationDate !== unitAnalyse.modificationDate,
    notes: unitAnalyse.content.notes ? unitAnalyse.content.notes : [],
    suffix: unitAnalyse.content.suffix,
    loq:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['loq']
        ? parseFloat(unitAnalyse.content.parameters['loq'])
        : '',
    unlocked:
      peak?.base?.hasOwnProperty('left') &&
      peak?.base?.hasOwnProperty('right') &&
      (peak.base.left.y < 0 || peak.base.right.y < 0),
    lmr: unitAnalyse.content.molecule && unitAnalyse.content.molecule.eu_lmr,
    parameters: unitAnalyse.content.parameters,
    score_details: unitAnalyse.content.score_details,
    ret_time: unitAnalyse.content.molecule.ret_time,
    istdEvent: unitAnalyse.content.molecule.istd,
    istdName: unitAnalyse.content.molecule.istd_name,
    peak: peak?.priority,
  }
}

/**
 * Rebuilds the unitary calibrations object for the GUI.
 * Sorts the flags of each channel in the unitary calibration content by title.
 *
 * @param {object} unitCalibration - The unitary calibration object to be rebuilt.
 * @returns {object} The rebuilt unitary calibration object with sorted channel flags.
 */
export function rebuildUnitaryCalibrations(unitCalibration) {
  let channelsFlags = {}
  Object.entries(unitCalibration.content.channels).forEach(function (entry) {
    if (entry[1].flags.length !== 0)
      channelsFlags[entry[0]] = entry[1].flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      })
  })
  let channelsClassification = {}
  Object.entries(unitCalibration.content.channels).forEach(function (entry) {
    channelsClassification[entry[0]] = entry[1].classification
  })
  let _included =
    unitCalibration.content.channels[unitCalibration.content.main_channel] &&
    unitCalibration.content.channels[unitCalibration.content.main_channel].included
      ? unitCalibration.content.channels[unitCalibration.content.main_channel].included
      : {}
  return {
    extraFlags: extractExtraFlagInfo(unitCalibration.content.flags),
    _id: unitCalibration._id,
    name: unitCalibration.name,
    event: unitCalibration.content.molecule.event,
    r2:
      unitCalibration.content.channels[unitCalibration.content.main_channel] &&
      unitCalibration.content.channels[unitCalibration.content.main_channel]
        .coefficient_of_determination
        ? parseFloat(
            unitCalibration.content.channels[
              unitCalibration.content.main_channel
            ].coefficient_of_determination.toFixed(6),
          )
        : '',
    rt:
      unitCalibration.content.channels[unitCalibration.content.main_channel] &&
      unitCalibration.content.channels[unitCalibration.content.main_channel].rt
        ? parseFloat(
            unitCalibration.content.channels[unitCalibration.content.main_channel].rt.toFixed(2),
          )
        : '',
    istd: unitCalibration.content.molecule.istd_name,
    type:
      unitCalibration.content.istd && unitCalibration.content.istd === 'True' ? 'ISTD' : 'Target',
    state: unitCalibration.content.classification,
    validation: unitCalibration.content.validation,
    flags: {
      globals: unitCalibration.content.flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      }),
      channels: channelsFlags,
    },
    classification: {
      global: unitCalibration.content.classification,
      channels: channelsClassification,
    },
    analyseType: 'calibration',
    mainChannel: unitCalibration.content.main_channel,
    batchId: unitCalibration.content.batch.id,
    batchName: unitCalibration.content.batch.name,
    istd_group: unitCalibration.content.molecule.istd,
    initial_included:
      unitCalibration.content.channels[unitCalibration.content.main_channel] &&
      unitCalibration.content.channels[unitCalibration.content.main_channel].initial_included
        ? unitCalibration.content.channels[unitCalibration.content.main_channel].initial_included
        : _included,
    initial_interpolation_type:
      unitCalibration.content.parameters && unitCalibration.content.parameters.interpolation_type
        ? unitCalibration.content.parameters.interpolation_type
        : '',
    initial_interpolation_weights_policy:
      unitCalibration.content.parameters &&
      unitCalibration.content.parameters.interpolation_weights_policy
        ? unitCalibration.content.parameters.interpolation_weights_policy
        : '',
    initial_interpolation_zero_policy:
      unitCalibration.content.parameters &&
      unitCalibration.content.parameters.interpolation_zero_policy
        ? unitCalibration.content.parameters.interpolation_zero_policy
        : '',
    modified: unitCalibration.creationDate !== unitCalibration.modificationDate,
    notes: unitCalibration.content.notes ? unitCalibration.content.notes : [],
    ret_time: unitCalibration.content.molecule.ret_time
      ? unitCalibration.content.molecule.ret_time
      : '',
    q1: unitCalibration.content.molecule.channels[unitCalibration.content.main_channel].q1
      ? unitCalibration.content.molecule.channels[unitCalibration.content.main_channel].q1
      : '',
    q3: unitCalibration.content.molecule.channels[unitCalibration.content.main_channel].q3
      ? unitCalibration.content.molecule.channels[unitCalibration.content.main_channel].q3
      : '',
    // curve_fit: unitCalibration.content?.parameters?.interpolation_type,
    // curve_weighting: unitCalibration.content?.parameters?.interpolation_weights_policy,
    // curve_zero: unitCalibration.content?.parameters?.interpolation_zero_policy,
    curve_fit:
      unitCalibration.content.channels[unitCalibration.content.main_channel]?.interpolation?.type,
    curve_weighting:
      unitCalibration.content.channels[unitCalibration.content.main_channel]?.interpolation
        ?.weights_policy,
    curve_zero:
      unitCalibration.content.channels[unitCalibration.content.main_channel]?.interpolation
        ?.zero_policy,
    std_dev:
      unitCalibration.content.channels[unitCalibration.content.main_channel] &&
      unitCalibration.content.channels[unitCalibration.content.main_channel].standard_deviation
        ? parseFloat(
            unitCalibration.content.channels[
              unitCalibration.content.main_channel
            ].standard_deviation.toFixed(6),
          )
        : '',
    nb_points: Object.values(
      unitCalibration.content.channels[unitCalibration.content.main_channel]?.included,
    )?.filter(Boolean)?.length,
  }
}

/**
 * Rebuilds the unitary analyses object for LIMS (Laboratory Information Management System).
 * Sorts the flags of each channel in the unitary analysis content by title.
 *
 * @param {object} unitAnalyse - The unitary analysis object to be rebuilt.
 * @param {object} analyse - The analysis object.
 * @returns {object} The rebuilt unitary analysis object with sorted channel flags.
 */
export function rebuildUnitaryAnalysesLims_OLD(unitAnalyse, analyse) {
  let channelsFlags = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    if (entry[1].flags.length !== 0)
      channelsFlags[entry[0]] = entry[1].flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      })
  })
  let channelsClassification = {}
  Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
    channelsClassification[entry[0]] = entry[1].classification
  })
  return {
    extraFlags: extractExtraFlagInfo(unitAnalyse.content.flags),
    analyse: unitAnalyse.content.analysis.name,
    analyseId: unitAnalyse.content.analysis.id,
    molecule: unitAnalyse.content.molecule,
    event: unitAnalyse.content.molecule.event,
    reported: unitAnalyse.content.reported ? 'True' : 'False',
    reported_msg: unitAnalyse.content.reported_msg ? unitAnalyse.content.reported_msg : '',
    mass_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass,
        )
      : '',
    mass_conc_rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .concentration_mass_rdt
      ? parseFloat(
          unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass_rdt,
        )
      : '',
    rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt)
      : '',
    amt_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration
      ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration)
      : '',
    chromatography_type: analyse && analyse.content.chromatography_type,
    weighing: analyse && analyse.content.sample_infos ? analyse.content.pesee : '',
    v_ext: analyse && analyse.content.sample_infos ? analyse.content.formula_infos.vol_sol_ext : '',
    dilution:
      analyse && analyse.content.sample_infos ? analyse.content.formula_infos.facteur_dilution : '',
    sm_concentration:
      analyse && analyse.content.sample_infos ? analyse.content.sm_concentration : '',
    status: unitAnalyse.content.classification,
    validation: unitAnalyse.content.validation,
    name: unitAnalyse.name,
    _id: unitAnalyse._id,
    batch_name: unitAnalyse.content.batch.name,
    requested: unitAnalyse.content.requested ? 'True' : 'False',
    modification_date: new Date(unitAnalyse.modificationDate * 1000).toLocaleString(),
    analyseType: unitAnalyse.content.analysis_type,
    flags: {
      globals: unitAnalyse.content.flags.sort(function (a, b) {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      }),
      channels: channelsFlags,
    },
    mainChannel: unitAnalyse.content.main_channel,
    global_classification: unitAnalyse.content.classification,
    main_peak_base:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak.base
        ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak.base
        : [],
    main_peak_base_left_init: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .base_left_init
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].base_left_init
      : [],
    main_peak_base_right_init: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
      .base_right_init
      ? unitAnalyse.content.channels[unitAnalyse.content.main_channel].base_right_init
      : [],
    main_channel_data: {
      channelNumber: unitAnalyse.content.main_channel,
      data: unitAnalyse.content.time.map((v, i) => ({
        x: v,
        y: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl[i],
      })),
      q1: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q1
        : null,
      q3: unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel]
        ? unitAnalyse.content.molecule.channels[unitAnalyse.content.main_channel].q3
        : null,
      ampl_max: unitAnalyse.content.channels[unitAnalyse.content.main_channel].ampl_max,
    },
    channels_data: Object.keys(unitAnalyse.content.channels)
      .filter((key) => parseInt(key) !== unitAnalyse.content.main_channel)
      .map(function (key) {
        let channel = unitAnalyse.content.channels[key]
        return {
          channelNumber: parseInt(key),
          data: unitAnalyse.content.time.map((v, i) => ({
            x: v,
            y: channel.ampl[i],
          })),
          q1: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q1
            : null,
          q3: unitAnalyse.content.molecule.channels[key]
            ? unitAnalyse.content.molecule.channels[key].q3
            : null,
        }
      }),
    rt_peak:
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak &&
      unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak.time
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].peak.time)
        : '',
    classification: {
      global: unitAnalyse.content.classification,
      channels: channelsClassification,
    },
    modified: unitAnalyse.creationDate !== unitAnalyse.modificationDate,
    notes: unitAnalyse.content.notes ? unitAnalyse.content.notes : [],
    loq:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['loq']
        ? parseFloat(unitAnalyse.content.parameters['loq'])
        : '',
    lod:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['lod']
        ? parseFloat(unitAnalyse.content.parameters['lod'])
        : '',
    use_loq_default:
      unitAnalyse.content.parameters && unitAnalyse.content.parameters['use_loq_default']
        ? unitAnalyse.content.parameters['use_loq_default']
        : '',
    reported_conc: unitAnalyse.content.reported_conc
      ? parseFloat(unitAnalyse.content.reported_conc)
      : undefined,
    factor: unitAnalyse.content.factor ? parseFloat(unitAnalyse.content.factor) : undefined,
  }
}

/**
 * Rebuilds the unitary analyses object for LIMS (Laboratory Information Management System).
 * Sorts the flags of each channel in the unitary analysis content by title.
 *
 * @param {object} unitAnalyse - The unitary analysis object to be rebuilt.
 * @returns {object} The rebuilt unitary analysis object with sorted channel flags.
 */
export function rebuildUnitaryAnalysesLims(unitAnalyse) {
  if (unitAnalyse.content.channels) {
    let channelsFlags = {}
    Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
      if (entry[1].flags.length !== 0)
        channelsFlags[entry[0]] = entry[1].flags.sort(function (a, b) {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        })
    })
    let channelsClassification = {}
    Object.entries(unitAnalyse.content.channels).forEach(function (entry) {
      channelsClassification[entry[0]] = entry[1].classification
    })
    return {
      extraFlags: extractExtraFlagInfo(unitAnalyse.content.flags),
      homologation: unitAnalyse?.content?.ua_infos?.homologation,
      batchId: unitAnalyse.content.batch.id,
      batch_name: unitAnalyse.content.batch.name,
      event: unitAnalyse.content.event,
      analyse: unitAnalyse.content.analysis.name,
      analyseId: unitAnalyse.content.analysis.id,
      molecule: unitAnalyse.content.molecule,
      reported: unitAnalyse.content.reported ? 'True' : 'False',
      reported_msg: unitAnalyse.content.reported_msg ? unitAnalyse.content.reported_msg : '',
      mass_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass
        ? parseFloat(
            unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass,
          )
        : '',
      mass_conc_rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel]
        .concentration_mass_rdt
        ? parseFloat(
            unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration_mass_rdt,
          )
        : '',
      rdt: unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].rdt)
        : '',
      amt_conc: unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration
        ? parseFloat(unitAnalyse.content.channels[unitAnalyse.content.main_channel].concentration)
        : '',
      status: unitAnalyse.content.classification,
      validation: unitAnalyse.content.validation,
      _id: unitAnalyse._id,
      flags: {
        globals: unitAnalyse.content.flags.sort(function (a, b) {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        }),
        channels: channelsFlags,
      },
      mainChannel: unitAnalyse.content.main_channel,
      classification: {
        global: unitAnalyse.content.classification,
        channels: channelsClassification,
      },
      loq:
        unitAnalyse.content.parameters && unitAnalyse.content.parameters['loq']
          ? parseFloat(unitAnalyse.content.parameters['loq'])
          : '',
      lod:
        unitAnalyse.content.parameters && unitAnalyse.content.parameters['lod']
          ? parseFloat(unitAnalyse.content.parameters['lod'])
          : '',
      use_loq_default:
        unitAnalyse.content.parameters && unitAnalyse.content.parameters['use_loq_default']
          ? unitAnalyse.content.parameters['use_loq_default']
          : '',
      reported_conc: unitAnalyse.content.reported_conc
        ? parseFloat(unitAnalyse.content.reported_conc)
        : undefined,
      factor: unitAnalyse.content.factor ? parseFloat(unitAnalyse.content.factor) : undefined,
      moc: unitAnalyse.content.moc,
    }
  } else {
    // UA generated only to displayed for the Analysis with only UA as non-detected
    // Only need the analysis id, name and validation != 1 to be take into account and invisible
    return {
      analyse: unitAnalyse.content.analysis.name,
      analyseId: unitAnalyse.content.analysis.id,
      validation: unitAnalyse.content.validation ? unitAnalyse.content.validation : null,
      molecule: {},
      reported: 'False',
      reported_msg: '',
      mass_conc: 0,
      mass_conc_rdt: 0,
      rdt: 0,
      amt_conc: 0,
      status: '',
      _id: unitAnalyse._id,
      flags: {
        globals: [],
        channels: [],
      },
      mainChannel: 1,
      classification: {
        global: '',
        channels: [],
      },
      loq: '',
      lod: '',
      use_loq_default: '',
      reported_conc: undefined,
      factor: undefined,
      moc: unitAnalyse.content.moc ? unitAnalyse.content.moc : null,
    }
  }
}
