// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import {store} from "react-notifications-component";
import React, {Fragment} from "react";

// export const REQUEST_MESSAGE = 'REQUEST_MESSAGE'
// export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE'
// export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const GET_MESSAGES = 'GET_MESSAGES'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const CLEAN_NEW_MESSAGES = 'CLEAN_NEW_MESSAGES'

// export function requestDisplayMessage() {
//   return {
//     type: REQUEST_MESSAGE,
//   }
// }
// export function displayMessage(message) {
//   return {
//     type: DISPLAY_MESSAGE,
//     visibility: true,
//     message: message,
//   }
// }
// export function hideMessage() {
//   return {
//     type: HIDE_MESSAGE,
//     visibility: false,
//   }
// }
//
// export function fetchDisplayMessage(severity, title, message) {
//   return async (dispatch) => {
//     dispatch(requestDisplayMessage())
//     const msg = {
//       severity: severity,
//       text: message,
//       title: title,
//     }
//     return dispatch(displayMessage(msg))
//   }
// }
//
// export function fetchHideMessage() {
//   return async (dispatch) => {
//     dispatch(requestDisplayMessage())
//     return dispatch(hideMessage())
//   }
// }



export function fetchAddMessage(message) {
  return async (dispatch) => {
    return dispatch(addMessage(message))
  }
}
export function addMessage(message) {
  return {
    type: ADD_MESSAGE,
    message: message,
  }
}
export function fetchGetMessages() {
  return async (dispatch) => {
    return dispatch(getMessages())
  }
}
export function getMessages() {
  return {
    type: GET_MESSAGES,
  }
}
export function fetchCleanNewMessages() {
  return async (dispatch) => {
    return dispatch(cleanNewMessages())
  }
}
export function cleanNewMessages() {
  return {
    type: CLEAN_NEW_MESSAGES,
  }
}