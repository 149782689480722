// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useMemo } from 'react'
import Paper from '@material-ui/core/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import Title from '../Title'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import { withRouter } from 'react-router-dom'
import { API_BO_URL, DATABASE, FLASK_URL, PUBLIC_URL } from '../../utils/config'
import Tooltip from '@material-ui/core/Tooltip'
import {
  getComparator,
  stableSort,
  createSubBatch,
  moveSampleToSubBatch,
  fetchReportToLims,
  openInNewTab,
  getClickAction,
  fetchLmrReportToLims,
  getHeaderTooltipCmp,
  StripedDataGridPro,
} from '../../utils/utilities'
import { TransitionTop, StyledDialogTitle } from '../common/Style'
import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import LimsTable from './LimsTable'
import { fetchUnitaryAnalysesLims } from '../../redux/lims/actions'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Button from '@material-ui/core/Button/Button'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { getBatchById } from '../../redux/batches/selectors'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import HelpIcon from '@material-ui/icons/HelpOutline'
import CancelIcon from '@material-ui/icons/CancelOutlined'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import { fetchAddTask, fetchFinishTask } from '../../redux/tasks/actions'
import Switch from '@material-ui/core/Switch'
import NoteButton from '../common/NoteButton'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import FolderIcon from '@material-ui/icons/Folder'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { fetchChangeSettings } from '../../redux/settings/actions'
import FunctionsIcon from '@material-ui/icons/Functions'
import FormulasCmp from './FormulasCmp'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import MoveDialog from './MoveDialog'
import { Stack } from '@mui/material'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { CustomNoRowsOverlay } from './Tools'
import { useGridApiRef } from '@mui/x-data-grid'
import Loading from '../Loading'
import { getFlagName } from '../analysis/Tools'
import { flagsFilterOperators } from '../common/ColumnFilters'
import { getFlagIcon } from '../backlog/Tools'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { openAnalysisInNewTab } from '../compound/Tools'
import { fetchDisplayJson } from '../../redux/json/actions'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    height: 'calc(100% - 35px)',
    overflow: 'hidden',
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.common.black, 0.03),
    },
  },
  toolbar: {
    position: 'absolute',
    top: 48,
    left: 70,
    background: '#fff',
    padding: 5,
    paddingRight: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  formControl: {
    marginTop: 15,
  },
  moveChip: {
    margin: 3,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  optionLabel: {
    color: alpha(theme.palette.common.black, 0.54),
    fontSize: '1rem',
    marginRight: 20,
  },
  optionSelected: {
    color: theme.palette.secondary.main,
  },
  actionMenu: { justifyContent: 'left', alignItem: 'start', paddingTop: 6, paddingBottom: 6 },
  actionDivider: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0px !important',
    margin: '5px 0px',
  },
}))

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    width: '100%',
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
})(LinearProgress)

const CustomDialog = withStyles((theme) => ({
  paper: {
    height: '100%',
  },
}))(Dialog)

const SamplesTable = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const apiRef = useGridApiRef()
  const batch = useSelector((state) => getBatchById(state.batches, props.match.params.batchId))
  const analysis = useSelector((state) => state.analyses.items)
  const analysesSentToLims = useSelector((state) => state.analyses.analysesSentToLims)
  const analysesNotSentToLims = useSelector((state) => state.analyses.analysesNotSentToLims)
  const isFetching = useSelector((state) => state.analyses.isFetching)
  const wikiCODHelper = useSelector((state) => state.settings.wikiCODHelper)
  const configFeatures = useSelector((state) => state.configuration.configFeatures)

  const [rowSelectionModel, setRowSelectionModel] = React.useState([])
  const [openMove, setOpenMove] = React.useState(false)
  const [openBatchMove, setOpenBatchMove] = React.useState(false)
  const [openLims, setOpenLims] = React.useState(false)
  const [selection, setSelection] = React.useState([])
  const [openExtentedView, setOpenExtentedView] = React.useState(false)
  const [nonDetectedUA, setNonDetectedUA] = React.useState([])
  const [openBatchCreate, setOpenBatchCreate] = React.useState(false)
  const [createBatch, setCreateBatch] = React.useState(null)
  const [option, setOption] = React.useState('move')
  const [familyBatches, setFamilyBatches] = React.useState(null)
  const [moveBatch, setMoveBatch] = React.useState(null)
  const [openFormulasDialog, setOpenFormulasDialog] = React.useState(false)

  const defaultState = {
    sorting: {
      sortModel: [
        {
          field: 'nb_detected',
          sort: 'desc',
        },
      ],
    },
  }

  const saveSnapshot = React.useCallback(
    (fullscreenMode) => {
      if (apiRef?.current?.exportState) {
        const stateSnapshot = apiRef.current.exportState()
        if (Object.keys(stateSnapshot).length !== 0) {
          localStorage.setItem('BATCH_samples_state', JSON.stringify(stateSnapshot))
        }
      }
    },
    [apiRef],
  )

  const echClient = useMemo(() => {
    return getEchClient()
  }, [JSON.stringify(analysis)])

  const selectionEmpty = useMemo(() => {
    return selection.length === 0
  }, [JSON.stringify(selection)])

  function getEchClient() {
    return analysis
      .filter((analyse) => analyse.content.type === 'sample' && !analyse.content.inProgress)
      .map((ech) => ({
        ...ech.content,
        original_row: ech,
        id: ech._id,
        _id: ech._id,
        batchId: ech.content.batch.id,
        analyseId: ech._id,
        name: ech.name,
        infos: ech.content.sample_infos,
        nb_detected: ech.content.nb_detected,
        nb_suspected: ech.content.nb_suspected,
        nb_excluded: ech.content.nb_excluded,
        sentToLims:
          analysesSentToLims && analysesSentToLims.indexOf(ech._id) !== -1
            ? true
            : analysesNotSentToLims && analysesNotSentToLims.indexOf(ech._id) !== -1
            ? false
            : null,
      }))
  }

  function onSelect(selected) {
    setSelection(selected.map((i) => getEchClient()[i]))
  }

  function clearSelection() {
    setRowSelectionModel([])
    if (onSelect) onSelect([])
  }

  function handleCloseMove(event, save) {
    setOpenMove(false)
    if (typeof save === 'boolean' && save) {
      clearSelection()
    }
  }

  function responseReportToLimsSuccess(resp, taskId, keepOpenAndRefresh) {
    let severity = 'success'
    let message = (
      <Fragment>
        <div>
          <Trans i18nKey="view.batch.samplesTable.fully_exported">
            The selected sample are <b>fully</b> exported to LIMS:
          </Trans>
        </div>
        <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
          {resp.uas_ok.map((item) => (
            <Tooltip arrow key={item._id + '-tooltip'} title={item.content.analysis.name}>
              <Chip
                variant="outlined"
                size="small"
                style={{ margin: 3 }}
                key={item._id}
                label={item.name}
              />
            </Tooltip>
          ))}
        </div>
      </Fragment>
    )
    if (resp.uas_ok && resp.uas_ok.length === 0) {
      severity = 'warning'
      message = (
        <Fragment>
          <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
            {t('view.batch.samplesTable.no_exported')}
            {resp.uas_ko.map((item) => (
              <Tooltip arrow key={item._id + '-tooltip'} title={item.content.analysis.name}>
                <Chip
                  variant="outlined"
                  size="small"
                  style={{ margin: 3 }}
                  key={item._id}
                  label={
                    item.content.reported_msg
                      ? item.name + ': ' + item.content.reported_msg
                      : item.name + ': Not expected error'
                  }
                />
              </Tooltip>
            ))}
          </div>
          {/*<div>{resp.message}</div>*/}
          {/*<div>Please contact your administrator.</div>*/}
        </Fragment>
      )
    } else {
      if (resp.uas_ko && resp.uas_ko.length > 0) {
        severity = 'warning'
        message = (
          <Fragment>
            <div>
              <Trans i18nKey="view.batch.samplesTable.partially_exported">
                The selected sample are <b>partially</b> exported to LIMS.
              </Trans>
            </div>
            <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
              {t('view.batch.samplesTable.successful_export')}
              {resp.uas_ok.map((item) => (
                <Tooltip arrow key={item._id + '-tooltip'} title={item.content.analysis.name}>
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{ margin: 3 }}
                    key={item._id}
                    label={item.name}
                  />
                </Tooltip>
              ))}
            </div>
            <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
              {t('view.batch.samplesTable.cannot_export')}
              {resp.uas_ko.map((item) => (
                <Tooltip arrow key={item._id + '-tooltip'} title={item.content.analysis.name}>
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{ margin: 3 }}
                    key={item._id}
                    label={
                      item.content.reported_msg
                        ? item.name + ': ' + item.content.reported_msg
                        : item.name + ': ' + t('view.batch.samplesTable.not_exported')
                    }
                  />
                </Tooltip>
              ))}
            </div>
          </Fragment>
        )
      }
    }
    displayPopupMessage(dispatch, severity, t('view.batch.samplesTable.export_lims_title'), message)
    dispatch(fetchFinishTask(taskId, 'success', t('view.batch.samplesTable.fine')))

    // Reload the UA to set Reported tick in table
    if (keepOpenAndRefresh) {
      const selectionFiltered = selection.filter(
        (analyse) => analyse.content.progression_requested !== 0,
      )
      dispatch(fetchUnitaryAnalysesLims(selectionFiltered))
    }
  }
  function responseReportToLimsError(error, taskId, selectionToSend) {
    if (error && error.status !== undefined && error.status === 401) {
      error.statusText = t('view.common.utils.lease_locked.code')
      displayPopupMessage(
        dispatch,
        'warning',
        t('view.batch.samplesTable.export_lims_title'),
        <Fragment>
          <div>{t('view.batch.samplesTable.cannot_export')}</div>
          {generateErrorPart(error)}
        </Fragment>,
      )
    } else {
      displayPopupMessage(
        dispatch,
        'error',
        t('view.batch.samplesTable.export_lims_title'),
        <Fragment>
          <div>Can not export to LIMS:</div>
          <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
            {selectionToSend.map((item) => (
              <Chip
                variant="outlined"
                size="small"
                style={{ margin: 3 }}
                key={item._id}
                label={item.name}
              />
            ))}
          </div>
          <div>{t('view.batch.samplesTable.admin')}</div>
          {generateErrorPart(error)}
        </Fragment>,
      )
    }
    dispatch(fetchFinishTask(taskId, 'error', error))
  }

  function handleCloseLims(event, save, exportResult, exportLMR, mocFilter) {
    if (typeof save === 'boolean' && save) {
      const selectionToSend = selection.filter(function (selectionUpdated) {
        return (
          selectionUpdated.limsUA &&
          selectionUpdated.limsUA.length !== 0 &&
          selectionUpdated.content.progression_requested !== 0
        )
      })
      const resultToSend = selectionToSend.map((ua) => ua._id)
      if (resultToSend.length === 0) {
        displayPopupMessage(
          dispatch,
          'warning',
          t('view.batch.samplesTable.export_lims_title'),
          <Fragment>
            <div>{t('view.batch.samplesTable.export_lims_msg')}</div>
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'info',
          t('view.batch.samplesTable.export_lims_title'),
          <Fragment>
            <div>{t('view.batch.samplesTable.will_exported')}</div>
            <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
              {selectionToSend.map((item, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  size="small"
                  style={{ margin: 3 }}
                  label={item.name}
                />
              ))}
            </div>
            <div style={{ marginTop: 10 }}>
              <i>{t('view.batch.samplesTable.time')}</i>
            </div>
          </Fragment>,
        )
        const taskId = new Date().getTime() + '_export_lims_' + selectionToSend[0]._id
        const task = {
          id: taskId,
          title: t('view.batch.samplesTable.export_lims_title'),
          operator: localStorage.getItem('SESSION_user_name'),
          date: new Date().getTime(),
          action: 'export_to_lims',
          percentage: 50,
          state: { value: 'running', message: '' },
          operation: 'Commit',
          items: selectionToSend.map((orData) => ({
            id: orData._id,
            name: orData.name,
            batch: {
              id: batch._id,
              name: batch.name,
            },
          })),
        }
        dispatch(fetchAddTask(task))
        if (exportResult) {
          dispatch(fetchReportToLims(selectionToSend, batch._id, nonDetectedUA, mocFilter))
            .then(function (resp) {
              // "!exportLMR" -> Reload the UA to set Reported tick in table
              responseReportToLimsSuccess(resp, taskId, !exportLMR)
            })
            .catch(function (error) {
              responseReportToLimsError(error, taskId, selectionToSend)
            })
        }
        if (exportLMR) {
          dispatch(fetchLmrReportToLims(selectionToSend, batch._id, mocFilter))
            .then(function (resp) {
              // Close the Export windows in this case (LMR exported with success)
              setOpenLims(false)
              clearSelection()
              responseReportToLimsSuccess(resp, taskId)
            })
            .catch(function (error) {
              responseReportToLimsError(error, taskId, selectionToSend)
            })
        }
      }
    } else {
      setOpenLims(false)
    }
  }

  function handleCloseBatchCreate(event, confirm) {
    setOpenBatchCreate(false)
    if (typeof confirm === 'boolean' && confirm) {
      clearSelection()
      createSubBatch(dispatch, props.match.params.batchId, createBatch, selection, option)
    }
  }

  function onChangeCreateBatch(event) {
    setCreateBatch(event.target.value)
  }

  function onChangeOption(event) {
    setOption(event.target.checked ? 'copy' : 'move')
  }
  function handleCloseBatchMove(event, confirm) {
    setOpenBatchMove(false)
    if (typeof confirm === 'boolean' && confirm) {
      clearSelection()
      moveSampleToSubBatch(dispatch, moveBatch.value, moveBatch.name, selection, option)
    }
  }

  function onChangeMoveBatch(event) {
    const name = familyBatches[event.target.value].name
    setMoveBatch({ value: event.target.value, name: name })
  }
  function openFormulasDialogClicked() {
    setOpenFormulasDialog(true)
  }
  function closeFormulasDialogClicked() {
    setOpenFormulasDialog(false)
  }

  function CustomLoading() {
    return (
      <div style={{ height: 'calc(100% - 96px)', position: 'relative' }}>
        <Loading mode="miniCircle" />
      </div>
    )
  }

  function onDisplayClient(id) {
    localStorage.setItem('BATCH_previous_selection', id)
    props.history.push('/?' + DATABASE() + '/' + props.match.params.batchId + '/analysis/' + id)
  }

  function handleOpenRenameDialog(event, row) {
    event.stopPropagation()
    event.preventDefault()
    dispatch(fetchChangeSettings('analysisToRename', row))
  }

  async function multiValidate(value) {
    switch (value) {
      case 0:
        setOpenBatchCreate(true)
        setCreateBatch(null)
        break
      case 1:
        setOpenBatchMove(true)
        setMoveBatch(null)
        fetch(`${FLASK_URL()}/batch/${props.match.params.batchId}/family`, {
          method: 'GET',
          credentials: 'include',
        })
          .then((response) => {
            if (!response.ok) {
              const statusText = response.statusText
              const status = response.status
              const url = response.url
              return response.text().then((errorMessage) => {
                const error = new Error(`${statusText} : ${errorMessage}`)
                if (response.headers.get('content-type') === 'application/json') {
                  error.stack = JSON.stringify(
                    JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                    null,
                    2,
                  )
                } else {
                  error.stack = new Error().stack
                }
                error.statusText = statusText
                error.status = status
                error.url = url
                throw error
              })
            }
            return response.json()
          })
          .then((json) => {
            setFamilyBatches(json)
          })
          .catch((error) => {
            if (error.status === 403) {
              dispatch(fetchChangeSettings('loginPopup', true))
            }
            displayPopupMessage(
              dispatch,
              'error',
              t('view.batch.othersTable.error_family_title'),
              <Fragment>
                <div>{t('view.batch.othersTable.error_family_msg')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          })
        break
      case 2:
        // Open window to move to QC or Others
        setOpenMove(true)
        break
      default:
        break
    }
  }

  function handleDisplayLims() {
    const selectionFiltered = selection.filter(
      (analyse) => analyse.content.progression_requested !== 0,
    )
    if (selectionFiltered.length === 0) {
      displayPopupMessage(
        dispatch,
        'warning',
        t('view.batch.samplesTable.export_lims_title'),
        <Fragment>
          <div>{t('view.batch.samplesTable.no_analysis')}</div>
          <div>{t('view.batch.samplesTable.validate')}</div>
        </Fragment>,
      )
    } else {
      setOpenLims(true)
      dispatch(fetchUnitaryAnalysesLims(selectionFiltered))
        .then(function (resp) {
          setNonDetectedUA(resp.lims.nonDetectedUA)
          displayPopupMessage(
            dispatch,
            'success',
            t('view.batch.samplesTable.opening_export'),
            t('view.batch.samplesTable.opening_ok'),
          )
        })
        .catch(function (error) {
          if (error && error.status !== undefined && error.status === 401) {
            error.statusText = t('view.common.utils.lease_locked.code')
            setOpenLims(false)
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.batch.samplesTable.opening_export'),
              <Fragment>
                <div>{t('view.batch.samplesTable.opening_ko')}</div>
                <div>{generateErrorPart(error)}</div>
              </Fragment>,
            )
          } else {
            displayPopupMessage(
              dispatch,
              'error',
              t('view.batch.samplesTable.opening_export'),
              <Fragment>
                <div>{t('view.batch.samplesTable.opening_ko')}</div>
                <div>
                  {typeof error === 'string'
                    ? error
                    : error.message
                    ? error.message
                    : error.toString()}
                </div>
              </Fragment>,
            )
          }
        })
    }
  }

  const columnsMemoized = useMemo(() => {
    return [
      {
        field: 'sentToLims',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        type: 'boolean',
        width: 80,
        headerName: t('view.batch.samplesTable.column.reported'),
        renderCell: function ({ value }) {
          return value ? (
            <CheckIcon style={{ color: '#0000008a' }} />
          ) : value === false ? (
            <CloseIcon style={{ color: '#0000008a' }} />
          ) : null
        },
      },
      {
        field: 'flags',
        type: 'custom',
        width: 80,
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.samplesTable.column.flags'),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <ErrorBoundaryGuard isDialog>
              <AlphacodFlagIcon item={row} hideGeneralTab={true} />
            </ErrorBoundaryGuard>
          )
        },
        valueFormatter: (params) => {
          return getFlagName(params.api.getRow(params.id))
        },
        filterOperators: flagsFilterOperators,
        valueGetter: ({ value }) => {
          switch (getFlagIcon((value.globals || []).filter((f) => !f.bypass_backlog))) {
            case 'info':
              return 0
            case 'action':
              return 1
            case 'warning':
              return 2
            case 'error':
              return 3
            default:
              return -1
          }
        },
      },
      {
        field: 'notes',
        type: 'number',
        width: 80,
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.samplesTable.column.notes'),
        valueGetter: ({ value }) => value?.length,
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <ErrorBoundaryGuard isDialog>
              <NoteButton
                row={{
                  _id: row.id,
                  notes: row.notes ? row.notes : [],
                }}
                collection={'analysis'}
              />
            </ErrorBoundaryGuard>
          )
        },
      },
      {
        field: 'name',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.samplesTable.column.name'),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <Tooltip arrow title={<div className={classes.tooltip}>{row.file}</div>}>
              <Stack
                style={{ overflow: 'hidden' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
              >
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {row.name}
                </div>
                <div
                  style={{ display: 'none', height: 20, marginBottom: 7, marginLeft: 5 }}
                  className={'nameAdorment'}
                >
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={(event) => handleOpenRenameDialog(event, row.original_row)}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    <Tooltip
                      arrow
                      placement={'top'}
                      title={
                        <div className={classes.tooltip}>
                          {t('view.batch.qualityControlTable.rename')}
                        </div>
                      }
                    >
                      <DriveFileRenameOutlineIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              </Stack>
            </Tooltip>
          )
        },
      },
      {
        field: 'infos',
        type: 'string',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.samplesTable.column.matrix'),
        valueGetter: ({ value }) =>
          value && value.matrice_id_labo
            ? value.matrice_id_labo
            : value && value.matrice
            ? value.matrice
            : '',
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <Tooltip title={value !== '' ? <div className={classes.tooltip}>{value}</div> : value}>
              <div>{value}</div>
            </Tooltip>
          )
        },
      },
      {
        field: 'nb_detected',
        width: 70,
        type: 'number',
        headerName: t('view.batch.samplesTable.column.detected'),
        renderHeader: (params) =>
          getHeaderTooltipCmp(
            params,
            wikiCODHelper,
            <ErrorIcon style={{ opacity: 0.8, marginTop: 26 }} />,
          ),
      },
      {
        field: 'nb_suspected',
        width: 70,
        type: 'number',
        headerName: t('view.batch.samplesTable.column.suspected'),
        renderHeader: (params) =>
          getHeaderTooltipCmp(
            params,
            wikiCODHelper,
            <HelpIcon style={{ opacity: 0.8, marginTop: 26 }} />,
          ),
      },
      {
        field: 'nb_excluded',
        width: 70,
        type: 'number',
        headerName: t('view.batch.samplesTable.column.excluded'),
        renderHeader: (params) =>
          getHeaderTooltipCmp(
            params,
            wikiCODHelper,
            <CancelIcon style={{ opacity: 0.8, marginTop: 26 }} />,
          ),
      },
      {
        field: 'progression',
        minWidth: 120,
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.samplesTable.column.progression'),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <Tooltip
              key={row.id}
              arrow
              title={
                <div className={classes.tooltip}>
                  <div>
                    {t('view.batch.samplesTable.column.progression') +
                      `: ${
                        row.progression_requested ? row.progression_requested.toFixed(2) : '0.00'
                      } %`}
                  </div>
                  <div style={{ marginTop: 10 }}>
                    {t('view.batch.samplesTable.column.detected') +
                      `: ${row.nb_detected_validated ? row.nb_detected_validated : '0'}`}
                  </div>
                  <div>
                    {t('view.batch.samplesTable.column.suspected') +
                      `: ${row.nb_suspected_validated ? row.nb_suspected_validated : '0'}`}
                  </div>
                  <div>
                    {t('view.batch.samplesTable.column.excluded') +
                      `: ${row.nb_excluded_validated ? row.nb_excluded_validated : '0'}`}
                  </div>
                  <div style={{ marginTop: 10 }}>{`OK: ${
                    row.nb_ok_validated ? row.nb_ok_validated : '0'
                  }`}</div>
                  <div>{`KO: ${row.nb_ko_validated ? row.nb_ko_validated : '0'}`}</div>
                </div>
              }
            >
              <BorderLinearProgress
                variant="determinate"
                value={row.progression_requested ? row.progression_requested : 0}
              />
            </Tooltip>
          )
        },
      },
      {
        field: 'actions',
        type: 'actions',
        hideable: false,
        resizable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: false,
        disableReorder: true,
        disableExport: true,
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        // getActions: ({ row, ...otherParams }) => [
        getActions: ({ row, ...otherParams }) => {
          let tabActions = []
          if (
            configFeatures &&
            typeof configFeatures.info_audit_trail === 'boolean' &&
            configFeatures.info_audit_trail
          ) {
            tabActions.push(
              <GridActionsCellItem
                className={classes.actionMenu}
                showInMenu
                icon={<GetAppIcon />}
                label={t('view.batch.samplesTable.get_audit')}
                onClick={() => {
                  displayPopupMessage(
                    dispatch,
                    'info',
                    t('view.batch.samplesTable.export_audit'),
                    <Fragment>
                      <div>{t('view.batch.samplesTable.analysis_selected') + ' ' + row.name}</div>
                    </Fragment>,
                  )
                  let url = `${FLASK_URL()}/analysis/${row._id}/audit_trail`
                  let link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', `audit_trail_${row.name}.txt`)
                  document.body.appendChild(link)
                  // Force download
                  link.click()
                  // Clean up and remove the link
                  link.parentNode.removeChild(link)
                }}
              />,
            )
          }
          if (
            configFeatures &&
            typeof configFeatures.info_webLims === 'boolean' &&
            configFeatures.info_webLims
          ) {
            tabActions.push(
              <GridActionsCellItem
                className={classes.actionMenu}
                showInMenu
                icon={<OpenInBrowserIcon />}
                label={t('view.batch.samplesTable.open_weblims')}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  openInNewTab(
                    'http://lims.phytocontrol.local/myweblims/index.php?r=t_pes_calcul_multiresidus%2FchoixEch&service=GC&searchech=' +
                      row.name,
                  )
                }}
              />,
            )
          }
          tabActions.push([
            <GridActionsCellItem
              className={classes.actionMenu}
              showInMenu
              icon={<OpenInBrowserIcon />}
              label={t('view.batch.othersTable.actions.open')}
              onClick={() => props.history.push('/?' + DATABASE() + '/' + row.batchId + '/analysis/' + row._id)}
            />,
            <GridActionsCellItem
              className={classes.actionMenu}
              showInMenu
              icon={<OpenInNewIcon />}
              label={t('view.batch.othersTable.actions.openNew')}
              onClick={() => openAnalysisInNewTab(row)}
            />,
            <GridActionsCellItem
              disabled={true}
              className={classes.actionDivider}
              showInMenu
              label={''}
            />,
            <GridActionsCellItem
              onClick={() => {
                dispatch(fetchDisplayJson(row._id, 'analysis'))
              }}
              className={classes.actionMenu}
              showInMenu
              icon={<SearchIcon />}
              label={t('view.batch.othersTable.actions.explore')}
            />,
          ])
          return tabActions.flat()
        },
      },
    ]
  }, [])

  const getRenderCmp = (fullscreenMode) => {
    return (
      <Fragment>
        <ErrorBoundaryGuard isDialog>
          <MoveDialog
            selection={selection}
            open={openMove}
            closeFct={handleCloseMove}
            origin={'sample'}
          />
        </ErrorBoundaryGuard>
        <ErrorBoundaryGuard isDialog>
          <LimsTable open={openLims} closeFct={handleCloseLims} />
        </ErrorBoundaryGuard>
        <Dialog
          open={openBatchCreate}
          onClose={handleCloseBatchCreate}
          TransitionComponent={TransitionTop}
          aria-labelledby="draggable-dialog-title"
        >
          <StyledDialogTitle>{t('view.batch.samplesTable.move_copy')}</StyledDialogTitle>
          <DialogContent>
            <DialogContentText>{t('view.batch.samplesTable.selected_samples')}</DialogContentText>
            <div
              style={{
                maxWidth: 600,
                maxHeight: 400,
                overflow: 'scroll',
                marginTop: 6,
                marginBottom: 20,
              }}
            >
              {selection.map((item, index) => (
                <Chip
                  key={item.name + '_' + index}
                  className={classes.moveChip}
                  label={item.name}
                />
              ))}
            </div>
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              style={{ marginBottom: 4 }}
            >
              <Grid item className={classes.optionLabel}>
                {t('view.batch.samplesTable.option')}
              </Grid>
              <Grid item className={option === 'move' ? classes.optionSelected : null}>
                {t('view.batch.samplesTable.move')}
              </Grid>
              <Grid item>
                <Switch
                  checked={option === 'copy'}
                  color="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                  onChange={onChangeOption}
                />
              </Grid>
              <Grid item className={option === 'copy' ? classes.optionSelected : null}>
                {t('view.batch.samplesTable.copy')}
              </Grid>
            </Grid>
            <TextField
              required
              fullWidth
              id="create-sub-batch"
              label={t('view.batch.samplesTable.sub_batch_name')}
              variant="outlined"
              onChange={onChangeCreateBatch}
              error={createBatch === '' || createBatch === null || createBatch === undefined}
              style={{ marginLeft: 75, marginBottom: 10, width: 'calc(100% - 75px)' }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={(event) => handleCloseBatchCreate(event, true)}
              disabled={createBatch === '' || createBatch === null || createBatch === undefined}
              color="primary"
            >
              {t('view.batch.samplesTable.create')}
            </Button>
            <Button onClick={(event) => handleCloseBatchCreate(event, false)} color="primary">
              {t('view.batch.samplesTable.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openBatchMove}
          onClose={handleCloseBatchMove}
          TransitionComponent={TransitionTop}
          aria-labelledby="draggable-dialog-title"
          style={{ minWidth: 400 }}
        >
          <StyledDialogTitle>{t('view.batch.samplesTable.move_copy_to')}</StyledDialogTitle>
          {familyBatches ? (
            <DialogContent>
              <DialogContentText>{t('view.batch.samplesTable.sure_to_move')}</DialogContentText>
              <DialogContentText>
                {t('view.batch.samplesTable.will_be_moved')}
                <div style={{ marginTop: 5 }}>
                  {selection.map((item) => (
                    <Chip key={item.name} className={classes.moveChip} label={item.name} />
                  ))}
                </div>
              </DialogContentText>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item className={classes.optionLabel}>
                  {t('view.batch.samplesTable.option')}
                </Grid>
                <Grid item className={option === 'move' ? classes.optionSelected : null}>
                  {t('view.batch.samplesTable.move')}
                </Grid>
                <Grid item>
                  <Switch
                    checked={option === 'copy'}
                    color="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    onChange={onChangeOption}
                  />
                </Grid>
                <Grid item className={option === 'copy' ? classes.optionSelected : null}>
                  {t('view.batch.samplesTable.copy')}
                </Grid>
              </Grid>
              <FormControl
                required
                fullWidth
                variant="outlined"
                className={classes.formControl}
                error={moveBatch === '' || moveBatch === null || moveBatch === undefined}
                style={{ marginLeft: 75, width: 'calc(100% - 75px)' }}
              >
                <InputLabel>{t('view.batch.samplesTable.batch')}</InputLabel>
                <Select
                  variant="outlined"
                  onChange={onChangeMoveBatch}
                  label={t('view.batch.samplesTable.batch')}
                >
                  {Object.entries(familyBatches).map((batch) => (
                    <MenuItem key={batch[0]} value={batch[0]}>
                      {batch[1].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogContentText>{t('view.batch.samplesTable.you_havent')}</DialogContentText>
              <div style={{ marginBottom: 20 }}>
                {selection.map((item) => (
                  <Chip key={item.name} className={classes.moveChip} label={item.name} />
                ))}
              </div>
              <DialogContentText>{t('view.batch.samplesTable.create_before')}</DialogContentText>
            </DialogContent>
          )}

          <DialogActions>
            <Button
              autoFocus
              onClick={(event) => handleCloseBatchMove(event, true)}
              color="primary"
              disabled={moveBatch === '' || moveBatch === null || moveBatch === undefined}
            >
              {t('view.batch.samplesTable.move')}
            </Button>
            <Button onClick={(event) => handleCloseBatchMove(event, false)} color="primary">
              {t('view.batch.samplesTable.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <ErrorBoundaryGuard isDialog>
          <FormulasCmp
            analyses={echClient}
            batch={batch}
            open={openFormulasDialog}
            closeFct={closeFormulasDialogClicked}
          />
        </ErrorBoundaryGuard>
        <Paper className={classes.paper} elevation={0}>
          <Title>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              {/*<Grid item>*/}
              <Box>{t('view.batch.samplesTable.customer_samples')}</Box>
              <span>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <div className={classes.tooltip}>{t('view.batch.samplesTable.apply_mass')}</div>
                  }
                >
                  <IconButton
                    style={{
                      padding: 3,
                    }}
                    className={classes.colorBlue}
                    aria-label="openFormulas"
                    onClick={openFormulasDialogClicked}
                  >
                    <FunctionsIcon />
                  </IconButton>
                </Tooltip>
                {!fullscreenMode && (
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <div className={classes.tooltip}>
                        {t('view.batch.samplesTable.open_fullscreen')}
                      </div>
                    }
                  >
                    <IconButton
                      size="small"
                      aria-label="extend"
                      onClick={() => {
                        setOpenExtentedView(true)
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </span>
            </Grid>
          </Title>
          <div className={classes.tableContainer}>
            <ErrorBoundaryGuard>
              <StripedDataGridPro
                initialState={{
                  ...defaultState,
                  ...JSON.parse(localStorage.getItem('BATCH_samples_state')),
                }}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel)
                  setSelection(
                    echClient
                      .filter((objet) => newRowSelectionModel.includes(objet._id))
                      .map((tmpRow) => tmpRow.original_row),
                  )
                }}
                rowSelectionModel={rowSelectionModel}
                onStateChange={() => saveSnapshot(fullscreenMode)}
                rowHeight={40}
                loading={isFetching}
                slots={{
                  // moreActionsIcon: CustomActionIcon,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                  loadingOverlay: CustomLoading,
                  // columnMenu: CustomColumnMenu,
                }}
                apiRef={apiRef}
                getRowClassName={(params) => {
                  let classesAdded = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  // if (isLoading(params.row)) {
                  //   classesAdded += ' loading'
                  // }
                  return classesAdded
                }}
                getCellClassName={(params) => {
                  return 'customCell'
                }}
                // disableVirtualization={batches.length < 50}
                rowBuffer={3}
                rowThreshold={3}
                columnBuffer={3}
                columnThreshold={3}
                rows={echClient}
                columns={columnsMemoized}
                pagination={false}
                hideFooter={true}
                style={{ border: 'none' }}
                // Do not use autoSize: conflic with initialState
                // autosizeOnMount
                // autosizeOptions={autoSizeOptions}
                onRowClick={(params, event, details) => {
                  const openFct = () => {
                    onDisplayClient(params.row.id)
                  }
                  getClickAction(dispatch, event, params.row, 'analysis', openFct)
                }}
                // onResize={() => hideWatermark()} //TODO To be deleted after purchasing a license
              />
            </ErrorBoundaryGuard>
          </div>
          {!selectionEmpty && (
            <span
              className={classes.toolbar}
              style={{ visibility: selectionEmpty ? 'hidden' : 'visible' }}
            >
              <div>
                <Tooltip
                  arrow
                  title={
                    <div className={classes.tooltip}>
                      {t('view.batch.samplesTable.moveQCOthers')}
                    </div>
                  }
                >
                  <IconButton
                    className={classes.toolbarButton}
                    aria-label="Move to QC Others"
                    onClick={() => multiValidate(2)}
                  >
                    <SwapHorizIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  title={
                    <div className={classes.tooltip}>{t('view.batch.samplesTable.move_copy')}</div>
                  }
                >
                  <IconButton
                    className={classes.toolbarButton}
                    aria-label="Create sub-batch"
                    onClick={() => multiValidate(0)}
                  >
                    <CreateNewFolderIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  title={
                    <div className={classes.tooltip}>
                      {t('view.batch.samplesTable.move_copy_exist')}
                    </div>
                  }
                >
                  <IconButton
                    className={classes.toolbarButton}
                    aria-label="Move to an existing batch"
                    onClick={() => multiValidate(1)}
                  >
                    <FolderIcon />
                  </IconButton>
                </Tooltip>
                {configFeatures &&
                  typeof configFeatures.info_webLims === 'boolean' &&
                  configFeatures.info_webLims && (
                    <Tooltip
                      arrow
                      title={
                        <div className={classes.tooltip}>
                          {t('view.batch.samplesTable.open_export_lims')}
                        </div>
                      }
                    >
                      <IconButton
                        className={classes.toolbarButton}
                        aria-label="lims"
                        onClick={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          handleDisplayLims()
                        }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  )}
              </div>
            </span>
          )}
        </Paper>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <CustomDialog
        fullWidth={true}
        maxWidth={'xl'}
        open={openExtentedView}
        onClose={() => setOpenExtentedView(false)}
        TransitionComponent={TransitionTop}
        aria-labelledby="draggable-dialog-title"
      >
        {openExtentedView && getRenderCmp(true)}
        <DialogActions>
          <Button onClick={() => setOpenExtentedView(false)} color="primary">
            {t('view.batch.samplesTable.close')}
          </Button>
        </DialogActions>
      </CustomDialog>
      {getRenderCmp()}
    </Fragment>
  )
})
export default SamplesTable
