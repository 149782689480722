// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

export const PIN_UNPIN_DRAWER = 'PIN_UNPIN_DRAWER'

export function pinUnpinDrawer(isUnpin) {
  return {
    type: PIN_UNPIN_DRAWER,
    pin: isUnpin,
  }
}

export function fetchPinUnpinDrawer(isPin) {
  return async (dispatch) => {
    return dispatch(pinUnpinDrawer(isPin))
  }
}
