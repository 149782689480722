// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { REQUEST_ACCOUNTINGS, RECEIVE_ACCOUNTINGS } from './actions'
import React from 'react'

const initialState = {
  accountings: [],
  isFetchingAccountings: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_ACCOUNTINGS:
      return {
        ...state,
        isFetchingAccountings: true,
      }

    case RECEIVE_ACCOUNTINGS:
      return {
        ...state,
        accountings: action.accountings,
        isFetchingAccountings: false,
      }
    default:
      return state
  }
}
