// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { API_BO_URL } from '../../utils/config'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'
import { fetchChangeSettings } from '../settings/actions'
import { receiveCurrentBatchLease } from '../batches/actions'

export const REQUEST_SCREENING_FOR_BATCH = 'REQUEST_SCREENING_FOR_BATCH'
export const RECEIVE_SCREENING_FOR_BATCH = 'RECEIVE_SCREENING_FOR_BATCH'
export const REQUEST_METHOD_FILES = 'REQUEST_METHOD_FILES'
export const RECEIVE_METHOD_FILES = 'RECEIVE_METHOD_FILES'

/**
 * Fetches screening data for a given batch.
 *
 * @param {Object} batch - The batch object containing device and chromatography type.
 * @param {boolean} onlyLastFour - Flag to determine if only the last four items should be fetched.
 * @returns {Function} A thunk that dispatches actions based on the fetch result.
 */
export function fetchScreeningForBatch(batch, onlyLastFour) {
  return async (dispatch) => {
    let device = batch.device
    // Convert device for matching with screening collection
    const convertDevice = device
      .toLowerCase()
      .replace(/[() ]/g, '')
      .replace('tq', '')
      .replace('scion', 'tq')
    // const GC_DEVICES_DICO = {"TQ01": "SCION 1", "TQ02": "SCION (2)", "TQ03": "SCION 3", "TQ04": "SCION 4", "TQ05": "SCION 5",
    //   "TQ06": "SCION 6", "TQ07": "SCION 7", "TQ08": "SCION 8", "TQ09": "SCION 9", "TQ10": "SCION 10",
    //   "TQ11": "SCION 11", "TQ12": "SCION 12", "TQ13": "SCION 13", "TQ14": "SCION 14", "TQ15": "SCION 15",
    //   "TQ16": "SCION 16"}

    dispatch(requestScreeningForBatch(convertDevice, onlyLastFour))
    let filter = [
      {
        property: 'content.device',
        value: convertDevice,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.chromatography_type',
        value: batch.chromatoType,
        filterType: 'string',
        operator: 'eq',
      },
    ]
    let v = encodeURIComponent(JSON.stringify(filter))
    // set a limit to get only the 4th items
    const sortForLast4 = onlyLastFour ? 'limit=4&' : ''
    // always sort by date_ts
    return fetch(
      `${API_BO_URL()}.screening/?${sortForLast4}sort=content.date_ts&dir=DESC&filter=${v}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    )
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        var screenings_without_multiple = []
        var screenings_name = []
        json.forEach(function (item) {
          // screening is ordered by dtae_ts
          if (!screenings_name.includes(item.name)) {
            screenings_name.push(item.name)
            screenings_without_multiple.push(item)
          }
        })
        return dispatch(receiveScreeningForBatch(screenings_without_multiple))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get Screening ',
          <Fragment>
            <div>An error does not allow the get screening:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}

/**
 * Action creator for requesting screening data for a batch.
 *
 * @param {string} device - The device identifier.
 * @param {boolean} onlyLastFour - Flag to determine if only the last four items should be fetched.
 * @returns {Object} The action object with type, device, and onlyLastFour properties.
 */
export function requestScreeningForBatch(device, onlyLastFour) {
  return {
    type: REQUEST_SCREENING_FOR_BATCH,
    device: device,
    onlyLastFour: onlyLastFour,
  }
}
/**
 * Action creator for receiving screening data for a batch.
 *
 * @param {Array} screenings - The array of screening objects.
 * @returns {Object} The action object with type and screenings properties.
 */
export function receiveScreeningForBatch(screenings) {
  return {
    type: RECEIVE_SCREENING_FOR_BATCH,
    screenings: screenings,
  }
}

/**
 * Fetches method files.
 *
 * @returns {Function} A thunk that dispatches actions based on the fetch result.
 */
export function fetchGetMethodFiles() {
  return async (dispatch) => {
    dispatch(requestGetMethodFiles())
    let filter = [
      // {
      //   property: 'content.device',
      //   value: convertDevice,
      //   filterType: 'string',
      //   operator: 'eq',
      // },
      // {
      //   property: 'content.chromatography_type',
      //   value: batch.chromatoType,
      //   filterType: 'string',
      //   operator: 'eq',
      // },
    ]
    let v = encodeURIComponent(JSON.stringify(filter))

    return fetch(`${API_BO_URL()}.screening/?sort=name&dir=ASC&filter=${v}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        let methods_without_multiple = []
        let methods_name = []
        json.forEach(function (item) {
          if (!methods_name.includes(item.name)) {
            methods_name.push(item.name)
            methods_without_multiple.push(item)
          }
        })
        // return dispatch(receiveGetMethodFiles(methods_without_multiple))
        return dispatch(receiveGetMethodFiles(json))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get method files ',
          <Fragment>
            <div>An error does not allow the get method files:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}

/**
 * Action creator for requesting method files.
 *
 * @returns {Object} The action object with type property.
 */
export function requestGetMethodFiles() {
  return {
    type: REQUEST_METHOD_FILES,
  }
}
/**
 * Action creator for receiving method files.
 *
 * @param {Array} methodFiles - The array of method file objects.
 * @returns {Object} The action object with type and methodFiles properties.
 */
export function receiveGetMethodFiles(methodFiles) {
  return {
    type: RECEIVE_METHOD_FILES,
    methodFiles: methodFiles,
  }
}
