// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useMemo } from 'react'
import { BootstrapTooltip, genericStyles } from '../common/Style'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Paper } from '@mui/material'
import { getAnalysisById } from '../../redux/analyses/selectors'
import TextField from '@mui/material/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@mui/material/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import FunctionsIcon from '@material-ui/icons/Functions'
import FormulasDialog from '../dialog/FormulasDialog'
import { formulas_dico, saveAnalyseValue } from '../../utils/utilities'
import Box from '@material-ui/core/Box'
import SelectAllIcon from '@material-ui/icons/SelectAll'
import { convert_formulas } from '../batch/FormulasCmp'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import Chip from '@material-ui/core/Chip/Chip'
import clsx from 'clsx'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import { alpha } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  customRootDialog: {
    // height: isMobile ? '100%' : '70%',
    width: 396,
    backgroundColor: 'transparent',
  },
  paper: {
    padding: 10,
    height: '100%',
    overflow: 'hidden',
    borderRadius: 4,
    // boxShadow:
    //   'rgb(0 0 0 / 20%) 0px 2px 7px 1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    '& input': {
      padding: '10px 14px',
    },
    '& .MuiTextField-root': {
      margin: '10px 0px',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    // marginTop: theme.spacing(10),
  },
  cardImg: {
    // position: 'absolute',
    // top: 17,
    // height: 110,
    // width: 396,
    // objectFit: 'none',
    background: 'lightblue',
  },
  copyright: {
    marginTop: 10,
  },
  important: {
    fontSize: '0.9rem',
  },
  passwordField: {
    marginTop: 5,
    '& legend > span': {
      paddingRight: 13,
    },
    '& .Mui-focused button': {
      color: theme.palette.primary.main,
    },
    '& .MuiIconButton-root:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
      transition: 'color 150ms ease-in',
    },
  },
  detailsTitle: {
    color: theme.palette.primary.main,
    fontSize: '1.2rem',
    marginLeft: 10,
  },
  colorBlue: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  formulaCnt: {
    border: '1px solid #c4c4c4',
    width: 'calc(50% - 5px)',
    display: 'inline-flex',
    borderRadius: 4,
  },
  formulaTitle: {
    backgroundColor: 'white',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 7px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    transform: 'translate(-4px, -8px) scale(0.75)',
  },
  noItem: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    top: 'calc(50% - 24px)',
    position: 'relative',
    fontWeight: 400,
    fontSize: '1.5rem',
  },
  chip: {
    marginRight: 5,
    marginBottom: 5,
    borderColor: 'inherit',
    '& > svg': {
      color: 'inherit',
      marginLeft: '8px !important',
    },
  },
  chipTooltip: {
    fontSize: 14,
  },
  flagInfo: {
    color: theme.palette.common.infoBlue,
    backgroundColor: alpha(theme.palette.common.infoBlue, 0.05),
  },
  flagWarning: {
    color: theme.palette.common.warning,
    backgroundColor: alpha(theme.palette.common.warning, 0.05),
  },
  flagError: {
    color: theme.palette.common.error,
    backgroundColor: alpha(theme.palette.common.error, 0.05),
  },
}))
const AnalysisDetail = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { items, batch } = props
  const [openFormulasDialog, setOpenFormulasDialog] = React.useState()

  const analyse = useSelector(function (state) {
    const analysisId = items && items[0] ? items[0].analyseId : null
    return getAnalysisById(state.analyses, analysisId)
  })
  const configFeatures = useSelector(function (state) {
    return state.configuration.configFeatures
  })
  const onSaveAnalyseValue = (newValue, analyse, paramValue, formula) => {
    saveAnalyseValue(dispatch, batch, newValue, analyse, paramValue, formula)
  }
  let formulas_dico_updated = { ...formulas_dico }

  return (
    <Fragment>
      {analyse && analyse.content.formula_infos && (
        <ErrorBoundaryGuard isDialog>
          <FormulasDialog
            analyse={analyse}
            batch={batch}
            open={openFormulasDialog}
            closeFct={() => setOpenFormulasDialog(false)}
            onSaveAnalyseValue={onSaveAnalyseValue}
          />
        </ErrorBoundaryGuard>
      )}
      <Paper
        elevation={0}
        className={classes.paper}
        style={
          {
            // height: collapsed ? '10px' : '100%',
            // paddingTop: collapsed ? '0px' : '10px',
          }
        }
      >
        {!analyse ? (
          <Typography className={classes.noItem} variant="h5" noWrap>
            {t('view.compound.detail.no_selection')}
            <Box>
              <SelectAllIcon style={{ fontSize: 40 }} />
            </Box>
          </Typography>
        ) : analyse.content.type !== 'sample' ? (
          <Typography className={classes.noItem} variant="h5" noWrap>
            {t('view.compound.detail.no_detail')}
            {/*<Box>*/}
            {/*  <SelectAllIcon style={{ fontSize: 40 }} />*/}
            {/*</Box>*/}
          </Typography>
        ) : (
          <Fragment>
            <div className={classes.detailsTitle} style={{ marginBottom: 10, marginTop: 7 }}>
              {t('view.compound.detail.details')}
              <span style={{ float: 'right' }}>
                {analyse?.content?.flags?.globals.map((row, index) => (
                  <BootstrapTooltip
                    interactive
                    title={
                      <Fragment>
                        <div style={{ fontWeight: 'bold' }}>
                          Classification: {row.classification}
                        </div>
                        <div
                          style={{
                            marginTop: 15,
                            color: '#000000DE',
                            maxHeight: 400,
                            overflow: 'scroll',
                          }}
                        >
                          {row.reason}
                        </div>
                      </Fragment>
                    }
                    className={classes.chipTooltip}
                    level={row.level}
                    key={'global_flags_' + index}
                    // key={`${analyse._id}_${row.level}_${row.title}`}
                  >
                    <Chip
                      variant="outlined"
                      className={clsx(
                        classes.chip,
                        row.level === 'warning'
                          ? classes.flagWarning
                          : row.level === 'error'
                          ? classes.flagError
                          : classes.flagInfo,
                      )}
                      icon={
                        row.level === 'warning' ? (
                          <WarningIcon />
                        ) : row.level === 'error' ? (
                          <ErrorIcon />
                        ) : (
                          <InfoIcon />
                        )
                      }
                      label={row.title}
                    />
                  </BootstrapTooltip>
                ))}
              </span>
            </div>
            <div style={{ overflow: 'auto', height: 'calc(100% - 46px)' }}>
              {analyse && analyse.content.report_infos && analyse.content.report_infos.num && (
                <TextField
                  fullWidth
                  size="small"
                  label={t('view.compound.detail.report_number')}
                  variant="outlined"
                  value={analyse.content.report_infos.num}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              {analyse && analyse.content.sample_infos && analyse.content.sample_infos.matrice && (
                <TextField
                  fullWidth
                  size="small"
                  label={t('view.compound.detail.matrix')}
                  variant="outlined"
                  value={analyse.content.sample_infos.matrice}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              {analyse && analyse.content.moc && (
                <TextField
                  fullWidth
                  size="small"
                  label="MOC"
                  variant="outlined"
                  value={analyse.content.moc}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              {analyse &&
                analyse.content.formula_infos &&
                analyse.content.formula_infos.facteur_dilution && (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('view.compound.detail.dilution')}
                    variant="outlined"
                    value={analyse.content.formula_infos.facteur_dilution}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              {analyse && analyse.content.pesee && (
                <TextField
                  fullWidth
                  size="small"
                  label={t('view.compound.detail.weighing')}
                  variant="outlined"
                  value={analyse.content.pesee + 'g'}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              {analyse &&
                analyse.content.formula_infos &&
                analyse.content.formula_infos.resultat_calcule_sans_rdt && (
                  <div style={{ width: '100%', marginTop: 10 }}>
                    <div
                      className={classes.formulaCnt}
                      style={{ width: '100%', position: 'relative' }}
                    >
                      <Typography
                        component="div"
                        // color="primary"
                        style={{ lineHeight: '48px', marginTop: 3, padding: '5px 10px' }}
                      >
                        {convert_formulas(
                          analyse.content.formula_infos,
                          analyse.content.formula_infos.resultat_calcule_sans_rdt,
                          formulas_dico_updated,
                        )}
                      </Typography>
                      <div className={classes.formulaTitle} style={{ left: 2 }}>
                        {t('view.compound.detail.formula')}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </Fragment>
        )}
      </Paper>
    </Fragment>
  )
})
export default AnalysisDetail
