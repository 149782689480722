// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import {
  GET_AI_MESSAGES,
  ADD_AI_MESSAGE,
  RECEIVE_AI_MESSAGE,
  ADD_USER_MESSAGE,
  RECEIVE_ASSISTANT_STATUS,
  TELL_ME_MORE,
  CLEAR_AI_MESSAGES, CMD_SYSTEM,
} from './actions'

const initialState = {
  messages: [],
  ai_fetching: false,
  ai_status: 'offline',
  loaded: false,
  externalWindowOpened: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'ASSISTANT_WINDOW_CLOSED':
      return {
        ...state,
        externalWindowOpened: false,
      }
    case 'ASSISTANT_WINDOW_OPENED':
      return {
        ...state,
        externalWindowOpened: true,
      }
    case RECEIVE_ASSISTANT_STATUS:
      return {
        ...state,
        ai_status: action.status,
        loaded: true,
      }
    case GET_AI_MESSAGES:
      return {
        ...state,
        messages: state.messages.sort(function (a, b) {
          if (a.date < b.date) return -1
          if (a.date > b.date) return 1
          return 0
        }),
      }
    case ADD_AI_MESSAGE:
      state.messages.push(action.message)
      return {
        ...state,
      }
    case ADD_USER_MESSAGE:
      state.messages.push(action.message)
      return {
        ...state,
        ai_fetching: true,
      }
    case RECEIVE_AI_MESSAGE:
      if (action.ai_message) {
        state.messages.push(action.ai_message)
      }
      return {
        ...state,
        ai_fetching: false,
      }
    case TELL_ME_MORE:
      return {
        ...state,
        ai_fetching: true,
      }
    case CMD_SYSTEM:
      return {
        ...state,
        ai_fetching: true,
      }
    case CLEAR_AI_MESSAGES:
      return {
        ...state,
        messages: [],
      }
    default:
      return state
  }
}
