// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles, alpha } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge/Badge'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import Tooltip from '@material-ui/core/Tooltip'
import { t } from 'i18next'

const styles = (theme) => ({
  badgeMetabolite: {
    top: -10,
    marginLeft: 2,
    '& .MuiBadge-badge': {
      width: 15,
      height: 15,
      padding: 0,
      minWidth: 0,
      right: -8,
      color: alpha(theme.palette.primary.main, 0.6),
      background: 'white',
      // boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
      border: '1px solid lightgrey',
    },
  },
  iconMetabolite: {
    fontSize: 13,
    position: 'relative',
  },
})

export default connect()(
  withStyles(styles)(
    class MetaboliteBadge extends React.Component {
      render() {
        const { classes, molecule } = this.props

        return molecule?.res_sum && molecule?.res_sum.name ? (
          <Fragment>
            <div
              style={{
                maxWidth: 'calc(100% - 16px)',
              }}
            >
              <Tooltip
                arrow
                placement="top"
                title={
                  <div style={{ fontSize: 13, padding: 5 }}>
                    {molecule.known_name ? molecule.known_name : molecule.name}
                  </div>
                }
              >
                <div
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {molecule.name}
                </div>
              </Tooltip>
            </div>
            <Badge
              className={classes.badgeMetabolite}
              badgeContent={
                <Tooltip
                  key={'tooltip_metabolite'}
                  arrow
                  title={
                    <Fragment>
                      <div
                        style={{
                          fontSize: 13,
                          fontWeight: 500,
                          padding: '5px 10px',
                          border: '1px solid white',
                          borderRadius: 5,
                          margin: '5px 0px 10px 0px',
                          textAlign: 'center',
                        }}
                      >
                        {molecule.res_sum.name}
                      </div>
                      <table>
                        <tr>
                          <td style={{ paddingRight: 10 }}>Case</td>
                          <td>{molecule.res_sum.case}</td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              paddingRight: 10,
                              verticalAlign: 'top',
                            }}
                          >
                            {t('view.common.metabolite.formula')}
                          </td>
                          <td>
                            {molecule.res_sum.coeff_str &&
                              molecule.res_sum.coeff_str.split(';').map((s, index) => (
                                <Fragment key={index}>
                                  {' '}
                                  {s} <br />{' '}
                                </Fragment>
                              ))}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: 10 }}>Coefficient</td>
                          <td>
                            {molecule.res_sum.coeff
                              ? molecule.name + ': ' + molecule.res_sum.coeff
                              : '--'}
                          </td>
                        </tr>
                        {molecule.res_sum.reason && (
                          <tr>
                            <td
                              style={{
                                paddingRight: 10,
                                verticalAlign: 'top',
                              }}
                            >
                              Info
                            </td>
                            <td>{molecule.res_sum.reason}</td>
                          </tr>
                        )}
                      </table>
                    </Fragment>
                  }
                >
                  <BubbleChartIcon color="secondary" className={classes.iconMetabolite} />
                </Tooltip>
              }
            >
              {/*{molecule.name}*/}
            </Badge>
          </Fragment>
        ) : (
          <div
            style={{
              maxWidth: '100%',
            }}
          >
            <Tooltip
              arrow
              placement="top"
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  {molecule?.known_name ? molecule?.known_name : molecule?.name}
                </div>
              }
            >
              <div
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {molecule?.name}
              </div>
            </Tooltip>
          </div>
        )
      }
    },
  ),
)
