// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { t } from 'i18next'
import { FormControlLabel, Tooltip } from '@mui/material'
import clsx from 'clsx'
import Switch from '@material-ui/core/Switch'
import SyncIcon from '@mui/icons-material/Sync'
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled'
import React from 'react'
import { alpha, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  syncSwitch: {
    zIndex: 1101,
    position: 'absolute',
    top: 10,
    left: 20,
    backgroundColor: alpha('#ffffff', 0.4),
    padding: '5px 6px 5px 5px',
    borderRadius: 5,
    '& .MuiTypography-root': {
      fontSize: 13,
      opacity: 0.5,
      height: 16,
    },
    '&.syncActive .MuiTypography-root': {
      opacity: 1,
      color: theme.palette.secondary.main,
    },
    '& svg': {
      height: 18,
    },
  },
}))

export const SyncSwitch = React.memo(({ autoUpdate, onToggle }) => {
  const classes = useStyles()
  return (
    <Tooltip
      key={'tooltip_sync'}
      placement="right"
      arrow
      title={
        <div style={{ fontSize: 13, padding: 5 }}>
          {autoUpdate ? t('view.common.detail.sync') : t('view.common.detail.no_sync')}
        </div>
      }
    >
      <FormControlLabel
        className={clsx(classes.syncSwitch, autoUpdate ? 'syncActive' : '')}
        control={<Switch checked={autoUpdate} size="small" onChange={onToggle} />}
        label={autoUpdate ? <SyncIcon /> : <SyncDisabledIcon />}
      />
    </Tooltip>
  )
})
