// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React from 'react'
import { API_URL, DATABASE } from './config'
import { fetchChangeSettings } from '../redux/settings/actions'
import { displayPopupMessage } from '../view/common/Message'
import { Trans } from 'react-i18next'
import { t } from 'i18next'

/**
 * Tests the availability of the database.
 * Constructs a filter to check the availability of the database and fetches the database information from the server.
 * If the server response is not OK, it throws an error with the response details.
 * If the server response is OK, it resolves the promise with the response data.
 * If an error occurs, it rejects the promise with the error details.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @returns {Promise<void>} A promise that resolves when the database availability is checked.
 */
export function testDatabaseAvailability(dispatch) {
  return new Promise((resolve, reject) => {
    const urlDatabase = DATABASE().startsWith('login') ? '' : DATABASE()
    let filter = encodeURIComponent(
      JSON.stringify([
        {
          property: 'name',
          value: '\\.batch',
          filterType: 'string',
          operator: 'like',
        },
      ]),
    )
    let url = `${API_URL()}/bo_classes/page/?filter=${filter}`
    // Get all available databases
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json()
        }
        throw new Error('Something went wrong')
      })
      .then((responseJson) => {
        // Test if the database is in the list
        const listOfDBName = responseJson.result.map((db) => db.name.replace('.batch', ''))
        const selectedDB = localStorage.getItem('SERVICE_dataBase_name')
        const defaultDB = localStorage.getItem('__config.json__DEFAULT_DATA_BASE_NAME')
        const urlDBAvailable = urlDatabase !== '' && listOfDBName.indexOf(urlDatabase) !== -1
        const selectedDBAvailable = selectedDB && listOfDBName.indexOf(selectedDB) !== -1
        const defaultDBAvailable =
          responseJson.result.map((db) => db.name.replace('.batch', '')).indexOf(defaultDB) !== -1
        if (urlDBAvailable) {
          dispatch(fetchChangeSettings('configurationDatabase', []))
          localStorage.setItem('SERVICE_dataBase_name', urlDatabase)
          resolve(true)
        } else if (selectedDBAvailable) {
          displayPopupMessage(
            dispatch,
            'info',
            <Trans i18nKey="view.common.utils.test_db.title_1">Database change</Trans>,
            <Trans i18nKey="view.common.utils.test_db.msg_selected">
              The URL database is not available. The selected database ({{ val: selectedDB }}) will
              be used.
            </Trans>,
          )
          dispatch(fetchChangeSettings('configurationDatabase', []))
          localStorage.setItem('SERVICE_dataBase_name', selectedDB)
          resolve({
            redirectTo: selectedDB, // Exemple de valeur
          })
        } else if (defaultDBAvailable) {
          displayPopupMessage(
            dispatch,
            'info',
            <Trans i18nKey="view.common.utils.test_db.title_1">Database change</Trans>,
            selectedDB ? (
              <Trans i18nKey="view.common.utils.test_db.default_1">
                The URL and the selected database ({{ val1: selectedDB }}) are not available. The
                default database ({{ val2: defaultDB }}) will be used.
              </Trans>
            ) : (
              <Trans i18nKey="view.common.utils.test_db.default_2">
                The URL database is not available. The default database ({{ val: defaultDB }}) will
                be used.
              </Trans>
            ),
          )
          dispatch(fetchChangeSettings('configurationDatabase', []))
          localStorage.setItem('SERVICE_dataBase_name', defaultDB)
          resolve({
            redirectTo: defaultDB, // Exemple de valeur
          })
        } else {
          displayPopupMessage(
            dispatch,
            'error',
            <Trans i18nKey="view.common.utils.test_db.title">Databases not available</Trans>,
            selectedDB ? (
              <Trans i18nKey="view.common.utils.test_db.noDB_1">
                The URL, the selected database ({{ val1: selectedDB }}) and the default one (
                {{ val2: defaultDB }}) are currently not available.
              </Trans>
            ) : (
              <Trans i18nKey="view.common.utils.test_db.noDB_2">
                The URL database, and the default one ({{ val: defaultDB }}) are currently not
                available.
              </Trans>
            ),
          )
          // Display the database selection popup
          dispatch(
            fetchChangeSettings(
              'configurationDatabase',
              responseJson.result.map((db) => db.name.replace('.batch', '')),
            ),
          )
          resolve(false)
        }
      })
      .catch((error) => {
        // In this case, it's not that the database is inaccessible, but the service is unavailable
        displayPopupMessage(
          dispatch,
          'error',
          <Trans i18nKey="view.common.utils.test_db.title">Databases not available</Trans>,
          <div>
            <div>
              <Trans i18nKey="view.common.utils.test_db.msg1">
                The databases are currently not accessible.
              </Trans>
            </div>
            <div style={{ marginTop: 10 }}>
              <Trans i18nKey="view.common.utils.test_db.msg2">
                Please contact your administrator.
              </Trans>
            </div>
          </div>,
        )
        // Add a specific key as DB to indicate there is no DB to select due to an error
        dispatch(fetchChangeSettings('configurationDatabase', ['ERROR_DB_UNAVAILABLE']))
        resolve(false)
      })
      .finally(() => {
        // Set the lock to say that the DB is loaded (or not)
        dispatch(fetchChangeSettings('dataBaseLoaded', true))
      })
  })
}
