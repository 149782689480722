// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import ListUI from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import Drawer from '@mui/material/Drawer'
import { withRouter } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import HomeIcon from '@material-ui/icons/Home'
import FolderIcon from '@material-ui/icons/Folder'
import WorkIcon from '@material-ui/icons/Work'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import Collapse from '@material-ui/core/Collapse'
import { List, AutoSizer } from 'react-virtualized'
import 'react-virtualized/styles.css'
import clsx from 'clsx'
import LockIcon from '@material-ui/icons/Lock'
import IconButton from '@material-ui/core/IconButton'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { fetchPinUnpinDrawer } from '../redux/drawer/actions'
import { Analysis, Analyses, Molecule } from './common/Style'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import ClearIcon from '@material-ui/icons/Clear'
import { Tooltip } from '@material-ui/core'
import SplitterLayout from 'react-splitter-layout'
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck'
import { DATABASE, DRAWER_WIDTH, GET_PRIMARY_COLOR, GET_SECONDARY_COLOR } from '../utils/config'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import ErrorBoundaryGuard from './ErrorBoundaryGuard'
import { fetchDisplayJson } from '../redux/json/actions'
import { openInNewTab } from '../utils/utilities'
import { displayPopupMessage } from './common/Message'
import { t } from 'i18next'
import { forceUAFetching } from '../redux/unitaryAnalyses/actions'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import { getBatchById } from '../redux/batches/selectors'
import BlockIcon from '@material-ui/icons/Block'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import SaveIcon from '@mui/icons-material/Save'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import { fetchSaveNewAnalysesList } from '../redux/analyses/actions'

/**
 * Get the width of the navigation drawer.
 * @returns {number} The width of the navigation drawer.
 */
const getNavWidth = function () {
  return localStorage.getItem('LAYOUT_first_secondaryPaneSize')
    ? localStorage.getItem('LAYOUT_first_secondaryPaneSize')
    : DRAWER_WIDTH()
}

/**
 * Define custom styles for the Backlog component.
 * @param theme Material-UI theme object.
 */
const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  drawer: {
    width: DRAWER_WIDTH(),
    flexShrink: 0,
  },
  drawerPaper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    border: 'none',
    width: 'calc(100% - 5px)',
    position: 'absolute',
  },
  drawerContainer: {
    overflow: 'hidden',
    '& .splitter-layout': {
      height: 'calc(100% - 130px)',
      top: 130,
      '& > .layout-pane': {
        overflow: 'hidden',
      },
      '& > .layout-pane:first-child': {
        // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
  },
  drawerContainerWithBatch: {
    '& .splitter-layout': {
      top: '180px !important',
      height: 'calc(100% - 181px) !important',
    },
  },
  drawerContainerWithBatchPinned: {
    '& .splitter-layout': {
      top: '116px !important',
      height: 'calc(100% - 117px) !important',
    },
  },
  drawerContainerWithBatchAndGLobalPinned: {
    '& .splitter-layout': {
      top: '166px !important',
      height: 'calc(100% - 167px) !important',
    },
  },
  drawerContainerWithBatchOrGLobalPinned: {
    '& .splitter-layout': {
      top: '118px !important',
      height: 'calc(100% - 119px) !important',
    },
  },
  drawerContainerWithBatchAndGLobal: {
    '& .splitter-layout': {
      top: '230px !important',
      height: 'calc(100% - 231px) !important',
    },
  },
  drawerContainerWithBatchOrGLobal: {
    '& .splitter-layout': {
      top: '180px !important',
      height: 'calc(100% - 181px) !important',
    },
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
    color: 'white',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '& .analyseType': {
      background: theme.palette.secondary.light + ' !important',
      color: 'white !important',
      border: '1px solid white !important',
    },
  },
  noSelected: {
    whiteSpace: 'nowrap',
  },
  test: {
    overflow: 'hidden',
    padding: 0,
    '& .MuiTypography-body1': {
      fontSize: '0.875rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  compoundList: {
    height: 'calc(100% - 56px)',
    '&:focus': {
      outline: 'none',
    },
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  analysesContainer: {
    height: 'calc(100% - 60px)',
    overflow: 'hidden',
    // marginRight: 9,
    marginBottom: 6,
    '&:hover': {
      overflowY: 'auto',
    },
    '& .MuiTypography-displayBlock': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'calc(100% - 30px)',
    },
  },
  listContainer: {
    overflow: 'hidden !important',
    '&:hover': {
      overflowY: 'auto !important',
    },
  },
  drawerShadow: {
    '& > div': {
      boxShadow: '0px 2px 7px 1px rgb(0 0 0 / 10%)',
    },
  },
  drawerOpen: {
    overflow: 'hidden',
    // width: 'calc(100% - 8px)',
    width: DRAWER_WIDTH(),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .analyseType': {
      right: 14,
      bottom: 7,
      fontSize: 11,
      padding: '2px 5px',
      transition: 'all 0.5s ease',
      color: '#757575',
      background: 'white',
      border: '1px solid #757575',
    },
    '& .compoundType': {
      opacity: 0,
      transition: 'all 0.5s ease',
    },
    '& .analysesProgression': {
      position: 'absolute',
      left: 39,
      height: 15,
      top: 10,
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .analyseSelected .analysesProgression': {
      color: 'white',
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
    '& .analyseType': {
      right: 6,
      bottom: 18,
      fontSize: 9,
      padding: '1px 4px',
      transition: 'all 0.5s ease',
      background: '#757575',
      color: 'white',
    },
    '& .analyseProvided': {
      display: 'none !important',
    },
    '& .analysesProgression': {
      position: 'absolute',
      right: 2,
      height: 15,
      bottom: 7,
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .analyseSelected .analysesProgression': {
      color: 'white',
    },
    '& .analyseSelected .analyseType': {
      background: 'white !important',
      color: theme.palette.secondary.light + ' !important',
      border: 'none !important',
    },
    '& .compoundType': {
      opacity: 1,
      transition: 'all 0.5s ease',
      right: 6,
      bottom: 22,
      fontSize: 9,
      padding: '1px 4px',
      background: '#757575',
      color: 'white',
    },
    '& .analyseSelected .compoundType': {
      background: 'white !important',
      color: theme.palette.secondary.light + ' !important',
      border: 'none !important',
    },
  },
  drawerPinButton: {
    width: 47,
    height: 53,
    top: 56,
    right: 0,
    left: 'auto',
    position: 'absolute',
    paddingTop: 6,
    color: theme.palette.secondary.main,
    zIndex: 10000000,
    borderRadius: '0px 0px 0px 16px',
    backgroundColor: 'white',
    '& > .MuiButtonBase-root': {
      borderRadius: '0px 0px 0px 16px',
    },
  },
  analyseIcon: {
    // background: theme.palette.secondary.main,
    borderRadius: 5,
    position: 'absolute',
    // left: 24,
    // bottom: 18,
    // fontSize: 10,
    // padding: '1px 4px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.common.black, 0.06),
    // },
    '& div:first-child': {
      color: alpha(theme.palette.common.black, 0.54),
    },
    '&:hover div:first-child': {
      color: theme.palette.secondary.main,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    // pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearIcon: {
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    color: 'black',
    paddingLeft: 30,
    // paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    borderRadius: '7px 0px 0px 7px',
    [theme.breakpoints.up('sm')]: {
      width: '0ch',
      '&:focus': {
        width: 95,
        paddingLeft: 36,
        paddingRight: 36,
        backgroundColor: alpha(theme.palette.common.black, 0.06),
      },
    },
  },
  forceToDisplayInput: {
    width: 95,
    paddingLeft: 36,
    paddingRight: 36,
    backgroundColor: alpha(theme.palette.common.black, 0.06),
  },
  textNavigation: {
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  navigationItemTitle: {
    paddingRight: 0,
  },
  backgroundSplitter: {
    background: 'rgba(0, 0, 0, 0.12)',
    width: '80%',
    marginLeft: '10%',
    height: 1,
    position: 'relative',
    bottom: -2,
  },
  backgroundSplitterHider: {
    background: 'white',
    height: 3,
    width: 50,
    position: 'absolute',
    left: 'calc(50% - 25px)',
  },
  navigationSplitterInPinMode: {
    top: '68px !important',
    height: 'calc(100% - 69px) !important',
  },
  drawerPinButtonPinned: {
    top: 0,
    width: 47,
    paddingTop: 0,
  },
  listText: {
    '& span': {
      fontSize: '14px',
    },
  },
  providedItem: {
    padding: '4px 16px 3px',
  },
  // drawerPinned: {
  //   width: getNavWidth() + 'px',
  //   minWidth: 240,
  // },
  customListItem: {
    overflow: 'hidden',
  },
})
/**
 * Maps the state from the Redux store to the component's props.
 * @param {Object} state - The current state of the Redux store.
 * @returns {Object} The mapped props.
 */
const mapStateToProps = (state) => {
  const { analyses, drawer } = state
  return {
    pinDrawer: drawer.pin,
    analysis: analyses.items,
  }
}
/**
 * Sorts an array of objects by the 'name' property in alphabetical order.
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @returns {number} -1 if 'a' should come before 'b', 1 if 'a' should come after 'b', 0 if they are equal.
 */
const sortByName = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1
  }
  return 0
}

/**
 * Sorts an array of objects by the 'order' property in ascending order.
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @returns {number} -1 if 'a' should come before 'b', 1 if 'a' should come after 'b', 0 if they are equal.
 */
const sortByOrder = (a, b) => {
  if (a.content.order < b.content.order) {
    return -1
  }
  if (a.content.order > b.content.order) {
    return 1
  }
  return 0
}

const grid = 8

/**
 * Gets the style for a draggable item.
 * @param {boolean} isDragging - Whether the item is being dragged.
 * @param {Object} draggableStyle - The default styles provided by the draggable component.
 * @returns {Object} The combined style object.
 */
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 0,
  height: 36,
  fontSize: 14,
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? alpha(GET_PRIMARY_COLOR(), 0.1) : 'transparent',

  // styles we need to apply on draggables
  ...draggableStyle,
})

/**
 * Gets the style for a list when an item is being dragged over it.
 * @param {boolean} isDraggingOver - Whether an item is being dragged over the list.
 * @returns {Object} The style object.
 */
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? alpha(GET_SECONDARY_COLOR(), 0.1) : 'transparent',
  padding: 0,
  // width: 250,
})

/**
 * Connects the component to the Redux store and wraps it with routing capabilities.
 * @component
 * @param {Object} mapStateToProps - Function to map state to props.
 * @param {Object} styles - Custom styles for the component.
 * @returns {React.Component} The connected and styled component with routing.
 */
export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      /**
       * Navigation component that handles the rendering and interaction of the navigation drawer.
       * It connects to the Redux store and provides routing capabilities.
       */
      class Navigation extends React.Component {
        constructor(props, context) {
          super(props, context)
          this.state = {
            error: null,
            isLoaded: false,
            items: [],
            openAnalyses: true,
            openCompounds: true,
            drawerOpen: localStorage.getItem('NAVIGATION_pin_panel')
              ? localStorage.getItem('NAVIGATION_pin_panel') === 'true'
              : false,
            searchCompound: '',
            timerCompound: null,
            displayClearCompound: false,
            searchAnalyse: '',
            timerAnalyse: null,
            displayClearAnalyse: false,
            compoundSort: localStorage.getItem('NAVIGATION_compound_sort')
              ? localStorage.getItem('NAVIGATION_compound_sort')
              : 'alpha',
            analysesOrdered: [],
            edit: false,
            isSearchFocused: false,
            isCompoundListInitialized: false,
          }
          this.refsRowsCompound = {}
          this.listCompounds = []
          this.listIstd = []
          this.enterDrawer = this.enterDrawer.bind(this)
          this.leaveDrawer = this.leaveDrawer.bind(this)
          this.handlePinDrawButton = this.handlePinDrawButton.bind(this)
          this.onSearchCompound = this.onSearchCompound.bind(this)
          this.clearSearchCompound = this.clearSearchCompound.bind(this)
          this.searchCompoundRef = React.createRef()
          this.onSearchAnalyse = this.onSearchAnalyse.bind(this)
          this.clearSearchAnalyse = this.clearSearchAnalyse.bind(this)
          this.searchAnalyseRef = React.createRef()
          this.navigationSplitterRef = React.createRef()
          this.onDragEnd = this.onDragEnd.bind(this)
          this.compoundTableRef = React.createRef()
        }

        /**
         * Updates the order of analyses based on the 'order' property and filters them by visibility tag.
         * @param {Function} callback - The callback function to execute after updating the order.
         */
        updateAnalysisOrder(callback) {
          const { analysis } = this.props
          const visibilityTag = 'off_visibility'
          const filterByVisibilityTag = (an) => !an.tags.includes(visibilityTag)

          if (analysis) {
            const analysesOrdered = [...analysis].sort(sortByOrder).filter(filterByVisibilityTag)

            this.setState({ analysesOrdered }, callback)
          }
        }

        /**
         * Scrolls to the selected items in the compound and analysis lists.
         * It calculates the position of the selected items and scrolls the lists to make them visible.
         */
        scrollToSelectedItems() {
          const { analysesOrdered } = this.state
          const { match } = this.props
          const idCompound = parseInt(match.params.moleculeEvent)
          const idAnalyse = match.params.analysisId

          if (idCompound && this.listCompounds.length > 0) {
            const table = document.getElementById('myCompoundList')
            // const table = this.compoundTableRef.current
            const index = this.listCompounds.map((item) => item.event).indexOf(idCompound)
            if (table && index !== -1) {
              // this.compoundTableRef.current.scrollToRow(index + 5)
              setTimeout(() => {
                table.scrollTo({
                  top: index * 36 - 60,
                })
              }, 100)
            }
          }

          if (idAnalyse && analysesOrdered) {
            const table = document.getElementById('myAnalyseList')
            const index = analysesOrdered.map((item) => item._id).indexOf(idAnalyse)
            if (table && index !== -1) {
              table.scrollTo({
                top: index * 36 - 60,
              })
            }
          }
        }

        /**
         * Handles the event when rows are rendered in the compound list.
         * It initializes the compound list and scrolls to the selected compound.
         */
        handleRowsRendered = () => {
          // if (!this.state.initialScrollCompleted) {
          //   this.setState({ initialScrollCompleted: true }, () => {
          //     this.scrollToCompound();
          //     this.scrollToAnalysis();
          //   });
          // }
          const { match } = this.props
          const { isCompoundListInitialized } = this.state
          const idCompound = parseInt(match.params.moleculeEvent)
          if (idCompound && !isCompoundListInitialized) {
            // this.scrollToSelectedItems()
            debugger
            this.setState({ isCompoundListInitialized: true })
            const index = this.listCompounds.map((item) => item.event).indexOf(idCompound)
            const table = this.compoundTableRef.current
            table.scrollToRow(index + 5)
          }
        }

        /**
         * Lifecycle method called when the component is mounted.
         * It updates the analysis order and scrolls to the selected items.
         */
        componentDidMount() {
          // const { analysis } = this.props
          // const visibilityTag = localStorage.getItem('__config.json__ANALYSIS_VISIBILITY_TAG')
          // const filterByVisibilityTag = (an) => !an.tags.includes(visibilityTag)
          // const analysesOrdered = [...analysis].sort(sortByOrder).filter(filterByVisibilityTag)
          // this.setState({
          //   analysesOrdered: analysesOrdered,
          // })
          // setTimeout(() => {
          //   const idCompound = parseInt(this.props.match.params.moleculeEvent)
          //   const idAnalyse = this.props.match.params.analysisId
          //   if (idCompound) {
          //     const table = document.getElementById('myCompoundList')
          //     const index = this.listCompounds.map((item) => item.event).indexOf(idCompound)
          //     if (table) {
          //       table.scrollTo({
          //         top: index * 36 - 60,
          //       })
          //     }
          //   }
          //   if (idAnalyse && analysesOrdered) {
          //     const table = document.getElementById('myAnalyseList')
          //     const index = analysesOrdered.map((item) => item._id).indexOf(idAnalyse)
          //     if (table) {
          //       table.scrollTo({
          //         top: index * 36 - 60,
          //       })
          //     }
          //   }
          // }, 1000)
          this.updateAnalysisOrder(() => {
            this.scrollToSelectedItems()
          })
        }

        /**
         * Lifecycle method called when the component updates.
         * It updates the analysis order and scrolls to the selected items if the analysis list or URL parameters change.
         * @param {Object} prevProps - The previous props of the component.
         */
        componentDidUpdate(prevProps) {
          const { analysis, match } = this.props

          // Test if analysis list changed
          // By a new order, visibility or progression
          if (
            prevProps.analysis
              .map((a) => a._id + a.tags + a.content.progression_requested)
              .toString() !==
            analysis.map((a) => a._id + a.tags + a.content.progression_requested).toString()
          ) {
            this.updateAnalysisOrder(() => {
              this.scrollToSelectedItems()
            })
          }

          // Test if URL params changed
          if (
            prevProps.match.params.moleculeEvent !== match.params.moleculeEvent ||
            prevProps.match.params.analysisId !== match.params.analysisId
          ) {
            this.scrollToSelectedItems()
          }
        }

        /**
         * Handles the event when the mouse enters the drawer.
         * It sets the state to open the drawer.
         */
        enterDrawer() {
          this.setState({ drawerOpen: true })
        }
        /**
         * Handles the event when the mouse leaves the drawer.
         * It sets the state to close the drawer if it is not pinned.
         */
        leaveDrawer() {
          if (localStorage.getItem('NAVIGATION_pin_panel') !== 'true') {
            this.setState({ drawerOpen: false })
          }
        }

        /**
         * Handles the event when the pin drawer button is clicked.
         * It toggles the pin state of the navigation drawer and updates the Redux store.
         * @param {Object} event - The event object.
         */
        handlePinDrawButton(event) {
          const { dispatch } = this.props
          event.stopPropagation()
          event.preventDefault()
          const oldPinState = localStorage.getItem('NAVIGATION_pin_panel') === 'true'
          localStorage.setItem('NAVIGATION_pin_panel', !oldPinState)
          dispatch(fetchPinUnpinDrawer(!oldPinState))
        }

        /**
         * Handles the search input for compounds.
         * It sets a timer to delay the search and updates the state with the search value.
         * @param {Object} e - The event object from the input field.
         */
        onSearchCompound(e) {
          const { timerCompound } = this.state
          const newValue = e.target.value
          clearTimeout(timerCompound)
          const newTimer = setTimeout(
            function () {
              this.setState({ searchCompound: newValue })
            }.bind(this),
            500,
          )
          this.setState({ timerCompound: newTimer, displayClearCompound: newValue !== '' })
        }

        /**
         * Clears the search input for compounds.
         * It resets the search input field and updates the state to hide the clear button.
         */
        clearSearchCompound() {
          this.searchCompoundRef.current.children[0].value = ''
          this.setState({ searchCompound: '', displayClearCompound: false })
        }

        /**
         * Handles the search input for analyses.
         * It sets a timer to delay the search and updates the state with the search value.
         * @param {Object} e - The event object from the input field.
         */
        onSearchAnalyse(e) {
          const { timerAnalyse } = this.state
          const newValue = e.target.value
          clearTimeout(timerAnalyse)
          const newTimer = setTimeout(
            function () {
              this.setState({
                searchAnalyse: newValue,
              })
            }.bind(this),
            500,
          )
          this.setState({ timerAnalyse: newTimer, displayClearAnalyse: newValue !== '' })
        }

        /**
         * Clears the search input for analyses.
         * It resets the search input field and updates the state to hide the clear button.
         */
        clearSearchAnalyse() {
          const { analysesOrdered } = this.state
          this.searchAnalyseRef.current.children[0].value = ''
          this.setState({
            searchAnalyse: '',
            displayClearAnalyse: false,
          })
        }

        /**
         * Reorders a list by moving an item from the start index to the end index.
         * @param {Array} list - The list to reorder.
         * @param {number} startIndex - The index of the item to move.
         * @param {number} endIndex - The index to move the item to.
         * @returns {Array} The reordered list.
         */
        reorder(list, startIndex, endIndex) {
          const result = Array.from(list)
          const [removed] = result.splice(startIndex, 1)
          result.splice(endIndex, 0, removed)
          return result
        }

        /**
         * Handles the event when dragging ends.
         * It reorders the analyses list based on the new position of the dragged item.
         * @param {Object} result - The result object from the drag event.
         */
        onDragEnd(result) {
          // dropped outside the list
          if (!result.destination) {
            return
          }
          this.setState((prevState) => ({
            analysesOrdered: this.reorder(
              prevState.analysesOrdered,
              result.source.index,
              result.destination.index,
            ),
          }))
        }

        /**
         * Saves the new order of analyses to the Redux store and displays a success or error message.
         */
        saveNewOrder() {
          const { dispatch, batch } = this.props
          const newListToSave = this.state.analysesOrdered?.reduce((acc, item, index) => {
            acc[item._id] = index
            return acc
          }, {})
          if (newListToSave) {
            dispatch(fetchSaveNewAnalysesList(newListToSave, batch._id))
              .then(() =>
                displayPopupMessage(
                  dispatch,
                  'success',
                  t('view.navigation.order_title'),
                  t('view.navigation.order_msg'),
                ),
              )
              .catch((error) =>
                displayPopupMessage(
                  dispatch,
                  'error',
                  t('view.navigation.order_title'),
                  <Fragment>
                    <div>{t('view.navigation.order_error')}</div>
                    <div>{error.toString()}</div>
                  </Fragment>,
                ),
              )
          }
        }

        /**
         * Renders the Navigation component.
         * @returns {JSX.Element} The rendered component.
         */
        render() {
          const { classes, batch, pinDrawer, dispatch } = this.props
          const {
            analysesOrdered,
            drawerOpen: open,
            searchCompound,
            searchAnalyse,
            compoundSort,
            edit,
            isSearchFocused,
          } = this.state
          const history = this.props.history
          const addRefCompound = function (row, i) {
            this.refsRowsCompound[row.event] = i
          }.bind(this)

          /**
           * ListItemLink component renders a list item with a link.
           * It handles navigation and displays additional information in a tooltip.
           *
           * @param {Object} props - The properties passed to the component.
           * @param {boolean} props.edit - Whether the item is in edit mode.
           * @param {Object} props.provided - The provided drag handle props.
           * @param {React.Element} props.icon - The icon to display in the list item.
           * @param {string} props.primary - The primary text to display in the list item.
           * @param {string} props.to - The URL to navigate to when the list item is clicked.
           * @param {boolean} props.selected - Whether the list item is selected.
           * @param {Object} props.tag - Additional tag element to display in the list item.
           * @param {Object} props.infos - Additional information to display in the tooltip.
           * @param {string} props.fullName - The full name to display in the tooltip.
           * @param {React.Element} props.completed - The completed icon to display in the list item.
           * @param {Object} props.keyboardAction - The keyboard action configuration.
           * @param {boolean} props.disabled - Whether the list item is disabled.
           * @returns {JSX.Element} The rendered list item link component.
           */
          function ListItemLink(props) {
            const {
              edit,
              provided,
              icon,
              primary,
              to,
              selected,
              tag,
              infos,
              fullName,
              completed,
              keyboardAction,
              disabled,
            } = props

            // const renderLink = React.useMemo(
            //   () =>
            //     React.forwardRef((itemProps, ref) => (
            //       <RouterLink to={to} ref={ref} {...itemProps} />
            //     )),
            //   [to],
            // )

            /**
             * Renders a list item with a link.
             * @returns {JSX.Element} The rendered list item.
             */
            const listItem = (
              <ListItem
                disabled={disabled}
                button
                onClick={function (event) {
                  event.stopPropagation()
                  event.preventDefault()
                  if (keyboardAction) {
                    if (event.ctrlKey && event.altKey) {
                      if (keyboardAction.prefix === 'compound') {
                        displayPopupMessage(
                          dispatch,
                          'info',
                          t('view.navigation.compound_info_title'),
                          <Fragment>
                            <div>{t('view.navigation.compound_info_msg1')}.</div>
                            <div>{t('view.navigation.compound_info_msg2')}.</div>
                            <div>{t('view.navigation.compound_info_msg3')}.</div>
                          </Fragment>,
                        )
                      } else {
                        dispatch(fetchDisplayJson(keyboardAction.itemId, keyboardAction.prefix))
                      }
                    } else if (event.ctrlKey) {
                      let url = ''
                      let url_collection = keyboardAction.prefix
                      let batch_id = keyboardAction.batchId
                      url = `${window.location.origin}${
                        window.location.pathname
                      }?${DATABASE()}/${batch_id}/${keyboardAction.prefix}/${keyboardAction.itemId}`
                      openInNewTab(url)
                    } else {
                      // Force to change the status of fetching to true
                      // It's avoid display old UA in the new view at the beginning
                      dispatch(forceUAFetching()).then(() => {
                        history.push(to.replace('/', '/?' + DATABASE() + (to === '/' ? '' : '/')))
                      })
                    }
                  } else {
                    history.push(to.replace('/', '/?' + DATABASE() + (to === '/' ? '' : '/')))
                  }
                }}
                className={clsx(
                  classes.customListItem,
                  selected ? classes.selected : null,
                  selected ? 'analyseSelected' : '',
                  provided ? classes.providedItem : '',
                )}
              >
                {provided && (
                  <div
                    className={'analyseProvided'}
                    {...provided.dragHandleProps}
                    style={{ display: edit ? 'inline-flex' : 'none', cursor: 'grab', minWidth: 30 }}
                  >
                    <DragIndicatorIcon {...(!selected && { color: 'secondary' })} />
                  </div>
                )}
                {completed}
                {tag}
                {icon ? (
                  <ListItemIcon className={selected ? classes.selected : null}>{icon}</ListItemIcon>
                ) : null}
                <ListItemText
                  primary={primary}
                  className={clsx(
                    selected ? classes.selected : classes.noSelected,
                    classes.listText,
                  )}
                />
              </ListItem>
            )

            return infos && infos.matrice ? (
              <Tooltip
                arrow
                title={
                  <div className={classes.tooltip}>{fullName + ' [' + infos.matrice + ']'}</div>
                }
              >
                {listItem}
              </Tooltip>
            ) : fullName ? (
              <Tooltip arrow title={<div className={classes.tooltip}>{fullName}</div>}>
                {listItem}
              </Tooltip>
            ) : (
              listItem
            )
          }

          /**
           * PropTypes for the ListItemLink component.
           * @property {React.Element} icon - The icon to display in the list item.
           * @property {Object} infos - Additional information to display in the tooltip.
           * @property {string} primary - The primary text to display in the list item.
           * @property {string} to - The URL to navigate to when the list item is clicked.
           * @property {boolean} selected - Whether the list item is selected.
           * @property {React.Element} tag - Additional tag element to display in the list item.
           */
          ListItemLink.propTypes = {
            icon: PropTypes.element,
            infos: PropTypes.object,
            primary: PropTypes.string.isRequired,
            to: PropTypes.string.isRequired,
            selected: PropTypes.bool,
            tag: PropTypes.element,
          }

          /**
           * Toggles the open state of the analyses section.
           */
          const handleAnalysesClick = () => {
            this.setState((prevState) => ({ openAnalyses: !prevState.openAnalyses }))
          }

          /**
           * Toggles the open state of the compounds section.
           */
          const handleCompoundsClick = () => {
            this.setState((prevState) => ({ openCompounds: !prevState.openCompounds }))
          }

          // const compoundList = Object.values(batch.content.molecules).sort(sortByName)
          if (batch.content.hasOwnProperty('molecules')) {
            this.listCompounds = Object.values(batch.content.molecules).sort(sortByName)
          }

          if (batch.content.hasOwnProperty('internal_standards')) {
            this.listIstd = Object.values(batch.content.internal_standards).map(
              (intStd) => intStd.event,
            )
          }

          if (searchCompound !== '') {
            var me = this
            this.listCompounds = this.listCompounds.filter(function (uc) {
              if (searchCompound.toLowerCase() === 'istd') {
                return me.listIstd.indexOf(uc.event) !== -1
              } else {
                return uc.name.toLowerCase().includes(searchCompound.toLowerCase())
              }
            })
          }
          // Show/Hide unknown molecule
          if (localStorage.getItem('SETTINGS_show_unknown') === 'false') {
            this.listCompounds = this.listCompounds.filter(function (mol) {
              return mol.unknown
                ? mol.unknown === false
                : !mol.name.toLowerCase().includes('unknown_')
            })
          }
          const compoundList = this.listCompounds.sort(function (a, b) {
            if (compoundSort === 'alpha') {
              if (a['name'].toLowerCase() < b['name'].toLowerCase()) return -1
              if (a['name'].toLowerCase() > b['name'].toLowerCase()) return 1
              return 0
            } else {
              if (a['ret_time'] < b['ret_time']) return -1
              if (a['ret_time'] > b['ret_time']) return 1
              return 0
            }
          })

          /**
           * Renders a row in the compound list.
           * @param {Object} params - The parameters for the row renderer.
           * @param {string} params.key - The unique key for the row.
           * @param {number} params.index - The index of the row.
           * @param {Object} params.style - The style to apply to the row.
           * @returns {JSX.Element} The rendered row.
           */
          const rowRenderer = ({ key, index, style }) => {
            const value = compoundList[index]
            return (
              <ListItem
                key={key}
                style={style}
                className={classes.test}
                ref={(i) => {
                  addRefCompound(value, i)
                }}
              >
                {value && (
                  <ListItemLink
                    keyboardAction={{
                      prefix: 'compound',
                      itemId: value.event,
                      batchId: this.props.match.params.batchId,
                    }}
                    fullName={value.name}
                    key={key}
                    style={style}
                    primary={value.name}
                    to={'/' + this.props.match.params.batchId + '/compound/' + value.event}
                    icon={<Molecule />}
                    selected={value.event.toString() === this.props.match.params.moleculeEvent}
                    tag={
                      <Fragment>
                        <div className={clsx('compoundType', classes.analyseIcon)}>
                          {value.name.substring(0, 3)}
                        </div>
                        {this.listIstd.indexOf(value.event) !== -1 && (
                          <div
                            style={{ bottom: 12 }}
                            className={clsx('analyseType', classes.analyseIcon)}
                          >
                            ISTD
                          </div>
                        )}
                      </Fragment>
                    }
                  />
                )}
              </ListItem>
            )
          }

          /**
           * Renders a label for the given type of icon.
           * @param {string} type - The type of the icon.
           * @returns {string} The label for the icon.
           */
          const iconLabelRender = (type) => {
            switch (type) {
              case 'blank':
                return 'blk'
              case 'calibration':
                return 'std'
              case 'matrix_bio':
                return 'mb'
              case 'matrix_bio_doped':
                return 'mbd'
              case 'sample':
                return 'spl'
              case 'std_end':
                return 'stde'
              case 'other':
                return 'oth'
              default:
                return '?'
            }
          }

          /**
           * Saves the size of the navigation splitter to local storage.
           */
          const onNavigationSplitterSizeSave = function () {
            localStorage.setItem(
              'LAYOUT_navigation_secondaryPaneSize',
              this.navigationSplitterRef.current.state.secondaryPaneSize,
            )
          }.bind(this)

          return (
            <Drawer
              variant="permanent"
              classes={{
                paper: clsx(classes.drawerPaper, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
                [classes.drawerShadow]: !pinDrawer && open,
              })}
              onMouseEnter={this.enterDrawer}
              onMouseLeave={this.leaveDrawer}
              sx={{
                '& .MuiPaper-root': {
                  width: pinDrawer ? 'calc(100% - 5px)' : open ? getNavWidth() + 'px' : 60,
                  minWidth: open ? DRAWER_WIDTH() - 10 : 'auto',
                },
              }}
            >
              {!pinDrawer && <div style={{ height: 65 }} />}
              <div
                className={clsx(
                  pinDrawer
                    ? !this.props.match.url.startsWith('/' + DATABASE() + '/batch/') &&
                      !this.props.match.url.startsWith('/' + DATABASE() + '/global/')
                      ? classes.drawerContainerWithBatchAndGLobalPinned
                      : !this.props.match.url.startsWith('/' + DATABASE() + '/batch/') ||
                        !this.props.match.url.startsWith('/' + DATABASE() + '/global/')
                      ? classes.drawerContainerWithBatchOrGLobalPinned
                      : null
                    : !this.props.match.url.startsWith('/' + DATABASE() + '/batch/') &&
                      !this.props.match.url.startsWith('/' + DATABASE() + '/global/')
                    ? classes.drawerContainerWithBatchAndGLobal
                    : !this.props.match.url.startsWith('/' + DATABASE() + '/batch/') ||
                      !this.props.match.url.startsWith('/' + DATABASE() + '/global/')
                    ? classes.drawerContainerWithBatchOrGLobal
                    : null,
                  classes.drawerContainer,
                )}
                style={{ display: 'contents' }}
              >
                {(pinDrawer || open) && (
                  <div
                    className={clsx(
                      classes.drawerPinButton,
                      pinDrawer ? classes.drawerPinButtonPinned : '',
                    )}
                  >
                    <IconButton color="inherit" onClick={this.handlePinDrawButton}>
                      {pinDrawer ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                  </div>
                )}
                <ListUI>
                  <ListItemLink primary={t('view.navigation.backlog')} to="/" icon={<HomeIcon />} />
                  {!this.props.match.url.startsWith('/' + DATABASE() + '/batch/') && (
                    <ListItemLink
                      primary={t('view.navigation.batch')}
                      to={'/batch/' + batch._id}
                      icon={<FolderIcon />}
                    />
                  )}
                  {!this.props.match.url.startsWith('/' + DATABASE() + '/global/') && (
                    <ListItemLink
                      primary={t('view.navigation.global')}
                      to={'/global/' + batch._id}
                      icon={<CalendarViewMonthIcon />}
                    />
                  )}
                </ListUI>
                <Divider style={{ width: '80%', marginLeft: '10%' }} />
                <SplitterLayout
                  percentage
                  vertical
                  customClassName={pinDrawer ? classes.navigationSplitterInPinMode : ''}
                  ref={this.navigationSplitterRef}
                  onDragEnd={onNavigationSplitterSizeSave}
                  secondaryInitialSize={
                    localStorage.getItem('LAYOUT_navigation_secondaryPaneSize')
                      ? parseFloat(localStorage.getItem('LAYOUT_navigation_secondaryPaneSize'))
                      : 50
                  }
                >
                  <Fragment>
                    <ListItem className={classes.navigationItemTitle}>
                      <ListItemIcon>
                        <Analyses />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('view.navigation.analyses')}
                        className={classes.textNavigation}
                      />
                      {searchAnalyse === '' && !isSearchFocused && (
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {edit
                                ? t('view.navigation.save_order')
                                : t('view.navigation.reorder')}
                            </div>
                          }
                        >
                          <IconButton
                            style={{ padding: 0, marginRight: edit ? 36 : 0 }}
                            onClick={() => {
                              if (edit) {
                                this.setState({
                                  edit: false,
                                })
                                this.saveNewOrder()
                              } else {
                                this.setState({
                                  edit: true,
                                  searchAnalyse: '',
                                })
                              }
                            }}
                          >
                            {edit ? <SaveIcon color="secondary" /> : <MoveDownIcon />}
                          </IconButton>
                        </Tooltip>
                      )}
                      {!edit && (
                        <div className={classes.search}>
                          <div className={classes.searchIcon}>
                            <SearchIcon />
                          </div>
                          <InputBase
                            placeholder={t('view.navigation.search') + '...'}
                            classes={{
                              root: classes.inputRoot,
                              input: clsx(
                                classes.inputInput,
                                this.state.displayClearAnalyse && classes.forceToDisplayInput,
                              ),
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={this.onSearchAnalyse}
                            ref={this.searchAnalyseRef}
                            onFocus={() => {
                              this.setState({ isSearchFocused: true })
                            }}
                            onBlur={() => {
                              this.setState({ isSearchFocused: false })
                            }}
                          />
                          {this.state.displayClearAnalyse && (
                            <IconButton
                              className={clsx(classes.clearIcon, classes.searchIcon)}
                              aria-label="clear"
                              onClick={this.clearSearchAnalyse}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </div>
                      )}
                    </ListItem>
                    <Collapse
                      className={classes.analysesContainer}
                      in={this.state.openAnalyses}
                      timeout="auto"
                      unmountOnExit
                      id="myAnalyseList"
                    >
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable" style={{ padding: 0 }}>
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {analysesOrdered
                                .filter(function (uc) {
                                  return typeof searchAnalyse === 'string' && searchAnalyse !== ''
                                    ? uc.name.toLowerCase().includes(searchAnalyse.toLowerCase())
                                    : true
                                })
                                .map((row, index) => (
                                  <Draggable key={row._id} draggableId={row._id} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                        )}
                                      >
                                        {/*{item.content}*/}
                                        <Fragment key={index + '_' + row.name}>
                                          <ListItemLink
                                            edit={edit}
                                            provided={provided}
                                            keyboardAction={{
                                              prefix: 'analysis',
                                              itemId: row._id,
                                              batchId: this.props.match.params.batchId,
                                            }}
                                            fullName={
                                              row.content.file.split('/')[
                                                row.content.file.split('/').length - 1
                                              ]
                                            }
                                            infos={row.content.sample_infos}
                                            primary={row.name}
                                            to={
                                              '/' +
                                              this.props.match.params.batchId +
                                              '/analysis/' +
                                              row._id
                                            }
                                            icon={<Analysis />}
                                            selected={
                                              row._id === this.props.match.params.analysisId
                                                ? true
                                                : false
                                            }
                                            key={row._id}
                                            tag={
                                              <div
                                                className={clsx('analyseType', classes.analyseIcon)}
                                              >
                                                {iconLabelRender(row.content.type)}
                                              </div>
                                            }
                                            completed={
                                              row.content.progression_requested === 100 ? (
                                                <LibraryAddCheckIcon
                                                  className={'analysesProgression'}
                                                />
                                              ) : null
                                            }
                                          />
                                        </Fragment>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Collapse>
                    <div className={classes.backgroundSplitter}>
                      <div className={classes.backgroundSplitterHider}></div>
                    </div>
                  </Fragment>
                  <Fragment>
                    <ListItem className={classes.navigationItemTitle}>
                      <ListItemIcon>
                        <BubbleChartIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('view.navigation.compounds')}
                        className={classes.textNavigation}
                      />
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <div style={{ fontSize: 13, padding: 5 }}>
                            {compoundSort === 'alpha' ? (
                              <Fragment>
                                <div>{t('view.navigation.sorted_name')}</div>
                                <div>{t('view.navigation.sort_ret')}</div>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <div>{t('view.navigation.sorted_ret')}</div>
                                <div>{t('view.navigation.sort_name')}</div>
                              </Fragment>
                            )}
                          </div>
                        }
                      >
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => {
                            localStorage.setItem(
                              'NAVIGATION_compound_sort',
                              compoundSort === 'alpha' ? 'retTime' : 'alpha',
                            )
                            this.setState({
                              compoundSort: compoundSort === 'alpha' ? 'retTime' : 'alpha',
                            })
                          }}
                        >
                          <ImportExportIcon />
                        </IconButton>
                      </Tooltip>
                      <div className={classes.search}>
                        <div className={classes.searchIcon}>
                          <SearchIcon />
                        </div>
                        <InputBase
                          placeholder={t('view.navigation.search') + '...'}
                          classes={{
                            root: classes.inputRoot,
                            input: clsx(
                              classes.inputInput,
                              this.state.displayClearCompound && classes.forceToDisplayInput,
                            ),
                          }}
                          inputProps={{ 'aria-label': 'search' }}
                          onChange={this.onSearchCompound}
                          ref={this.searchCompoundRef}
                        />
                        {this.state.displayClearCompound && (
                          <IconButton
                            className={clsx(classes.clearIcon, classes.searchIcon)}
                            aria-label="clear"
                            onClick={this.clearSearchCompound}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </div>
                    </ListItem>
                    <div style={{ flex: '1 1 auto' }} className={classes.compoundList}>
                      <ErrorBoundaryGuard>
                        <AutoSizer>
                          {({ height, width }) => (
                            <List
                              ref={this.compoundTableRef}
                              height={height}
                              width={width}
                              rowHeight={36}
                              rowCount={compoundList.length}
                              rowRenderer={rowRenderer}
                              className={classes.listContainer}
                              id="myCompoundList"
                              // onRowsRendered={this.handleRowsRendered}
                            />
                          )}
                        </AutoSizer>
                      </ErrorBoundaryGuard>
                    </div>
                  </Fragment>
                </SplitterLayout>
              </div>
            </Drawer>
          )
        }
      },
    ),
  ),
)
