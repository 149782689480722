// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { t } from 'i18next'
import React, { Fragment } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { DataGridPro } from '@mui/x-data-grid-pro'
import Box from '@material-ui/core/Box'
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined'
import IconButton from '@material-ui/core/IconButton'
import ReplayIcon from '@material-ui/icons/Replay'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import { alpha } from '@material-ui/core'
import { fetchAddTask, fetchFinishTask } from '../../redux/tasks/actions'
import {
  fetchGenerateBatchMethod,
  fetchRetireBatch,
  fetchSetAsCalibRefBatch,
  fetchStampBatch,
  fetchUpdatePriority,
} from '../../redux/batches/actions'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import { Trans } from 'react-i18next'
import {
  generateUUID,
  getClickAction,
  isLeasePassed,
  openInNewTab,
  saveBatchBackup,
} from '../../utils/utilities'
import { API_BO_URL, DATABASE, FLASK_URL } from '../../utils/config'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { fetchAnalysesSentToLims, receiveAnalyses } from '../../redux/analyses/actions'
import { fetchDisplayJson } from '../../redux/json/actions'
import { useDispatch } from 'react-redux'

/**
 * Custom styles for the component using Material-UI's makeStyles.
 * @param {object} theme - The theme object provided by Material-UI.
 * @returns {object} - The styles object.
 */
const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 14,
    fontWeight: 400,
    padding: 5,
    lineHeight: 1.5,
  },
  addActionMessage: {
    border: '1px solid lightgrey',
    borderRadius: 5,
    padding: '5px 0px',
    marginBottom: 10,
    width: '100%',
    background: alpha('#8c8c8c', 0.05),
  },
  action: {
    color: theme.palette.common.infoBlue,
  },
  trainingButtonTooltip: {
    padding: 0,
  },
  info: {
    color: theme.palette.common.infoBlue,
  },
  success: {
    color: theme.palette.common.success,
  },
  warning: {
    color: theme.palette.common.warning,
  },
  error: {
    color: theme.palette.common.error,
  },
}))

/**
 * A styled LinearProgress component with custom styles.
 * @type {React.Component}
 */
export const BorderLinearProgress = withStyles({
  root: {
    height: 16,
    borderRadius: 16,
    width: '100%',
  },
  bar: {
    borderRadius: 16,
  },
})(LinearProgress)
/**
 * A styled Badge component with custom styles.
 * @type {React.Component}
 */
export const StyledBadge = withStyles((theme) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    padding: '0 4px',
  },
}))(Badge)
/**
 * A styled Tooltip component with custom styles.
 * @type {React.Component}
 */
export const FlagsTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 15,
    padding: '5px 0px',
    boxShadow: ' 0px 0px 10px 1px rgba(0,0,0,0.7)',
    maxWidth: 330,
    '& td': {
      maxWidth: 275,
    },
  },
})(Tooltip)

/**
 * Component to display a tooltip with operator information.
 * @param {Object} props - The component props.
 * @param {Object} props.row - The data row containing operator information.
 * @returns {JSX.Element} - The rendered tooltip component.
 */
export function OperatorTooltip({ row }) {
  const classes = useStyles()
  return row.operator && row.operator[row.operator.length - 1].date ? (
    <div className={classes.tooltip}>
      <div
        style={{
          marginBottom: 11,
          padding: 7,
          borderBottom: '2px solid darkgrey',
          fontWeight: 500,
        }}
      >
        {t('view.backlog.last_modif')}
      </div>
      {row.operator
        .sort(function (a, b) {
          if (a.date < b.date) return -1
          if (a.date > b.date) return 1
          return 0
        })
        .slice(
          row.operator.length - 10 < 0 ? 0 : row.operator.length - 10,
          row.operator.length - 10 < 0 ? 10 : row.operator.length,
        )
        .map((op, index) => (
          <div
            key={'operator_' + index + '_' + row.operator[row.operator.length - 1]}
            style={{
              border:
                row.operator.length - 1 === index || index === 9 ? '1px solid darkgrey' : 'none',
              borderRadius: 5,
              padding: 7,
              marginTop: row.operator.length - 1 === index || index === 9 ? 10 : 0,
              fontWeight: row.operator.length - 1 === index || index === 9 ? 400 : 200,
            }}
          >
            <div
              style={{
                display: row.operator.length - 1 || (index === 9) === index ? 'block' : 'none',
                position: 'absolute',
                border: '1px solid darkgray',
                padding: '0px 5px',
                borderRadius: 4,
                background: '#707070',
                bottom: 56,
                left: 19,
                fontSize: 10,
              }}
            >
              last
            </div>
            <div style={{ float: 'left', textAlign: 'end', marginRight: 15 }}>
              <div>{new Date(parseFloat(op.date) * 1000).toLocaleDateString()}</div>
              <div style={{ fontSize: 11 }}>
                {new Date(parseFloat(op.date) * 1000).toLocaleTimeString()}
              </div>
            </div>
            <div style={{ marginLeft: 90, whiteSpace: 'nowrap' }}>
              <div>{op.name}</div>
              <div style={{ fontSize: 11 }}> {op.type}</div>
            </div>
          </div>
        ))}
    </div>
  ) : (
    ''
  )
}
/**
 * Get the icon type based on the flags provided.
 * @param {Array} flags - The array of flag objects.
 * @returns {string} - The icon type ('info', 'action', 'warning', 'error').
 */
export function getFlagIcon(flags) {
  var flags_dict = new Map()
  flags_dict.set('info', 0)
  flags_dict.set('action', 1)
  flags_dict.set('warning', 2)
  flags_dict.set('error', 3)
  var icon_value = -1
  var icon = ''
  flags?.forEach((flag) => {
    // Not select Unknown molecule warning
    if (flags_dict.get(flag.level) > icon_value && !flag.title.includes('Unknown molecule')) {
      // Add action flag if warning
      if (flag.title.includes('Ambiguous screenings') || flag.title.includes('No sample')) {
        icon_value = flags_dict.get('action')
        icon = 'action'
      } else {
        icon_value = flags_dict.get(flag.level)
        icon = flag.level
      }
    }
  })
  return icon
}
/**
 * Component to display flags with tooltips.
 * @param {Object} props - The component props.
 * @param {Object} props.row - The data row containing flags.
 * @param {boolean} props.isLoading - Flag to indicate if data is loading.
 * @param {Function} props.setOpenConfirmUpdateBatch - Function to set the state for opening the confirm update batch dialog.
 * @param {Function} props.setCurrentUpdateBatch - Function to set the current batch to be updated.
 * @returns {JSX.Element} - The rendered flag item component.
 */
export function FlagItem(props) {
  const classes = useStyles()
  const { row, isLoading, setOpenConfirmUpdateBatch, setCurrentUpdateBatch } = props
  const dispatch = useDispatch()
  return (
    <FlagsTooltip
      interactive={'true'}
      placement="right"
      title={
        !isLoading ? (
          <Box style={{ margin: 10 }}>
            {row.flags &&
              row.flags.globals &&
              //Case if flag are action type
              (row.flags.globals
                .map((flag) => flag.title)
                .toString()
                .indexOf('Ambiguous screenings') !== -1 ||
                row.flags.globals
                  .map((flag) => flag.title)
                  .toString()
                  .indexOf('No sample') !== -1) && (
                <table key={generateUUID()} className={classes.addActionMessage}>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'top', width: 29 }}>
                        <LiveHelpOutlinedIcon className={classes.action} />
                      </td>
                      <td style={{ lineHeight: 1.2 }}>
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{t('view.backlog.action_required')}</td>
                            </tr>
                            <tr style={{ fontSize: 12 }}>
                              <td>
                                <i>{t('view.backlog.update_replace')} </i>
                                <IconButton
                                  aria-label="retrieveTooltip"
                                  className={classes.trainingButtonTooltip}
                                  onClick={(event) =>
                                    handleUpdateBatch(
                                      event,
                                      row,
                                      dispatch,
                                      // setOpenConfirmUpdateBatch,
                                      // setCurrentUpdateBatch,
                                    )
                                  }
                                >
                                  <ReplayIcon />
                                </IconButton>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            {row.flags?.globals
              ?.filter((f) => !f.bypass_backlog)
              .map((flag, index) => (
                <table key={generateUUID()}>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'top', width: 35 }}>
                        {flag.level === 'info' ? (
                          <InfoIcon className={classes.info} />
                        ) : flag.level === 'warning' ? (
                          <WarningIcon className={classes.warning} />
                        ) : flag.level === 'error' ? (
                          <ErrorIcon className={classes.error} />
                        ) : (
                          <Fragment />
                        )}
                      </td>
                      <td style={{ lineHeight: 1.2 }}>
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{flag.title}:</td>
                            </tr>
                            <tr style={{ fontSize: 12 }}>
                              <td>
                                <i>{flag.reason}</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
          </Box>
        ) : (
          ''
        )
      }
    >
      {getFlagIcon(row.flags?.globals?.filter((f) => !f.bypass_backlog)) === 'info' ? (
        <InfoIcon className={classes.info} />
      ) : getFlagIcon(row.flags?.globals?.filter((f) => !f.bypass_backlog)) === 'action' ? (
        <LiveHelpOutlinedIcon className={classes.action} />
      ) : getFlagIcon(row.flags?.globals?.filter((f) => !f.bypass_backlog)) === 'warning' ? (
        <WarningIcon className={classes.warning} />
      ) : getFlagIcon(row.flags?.globals?.filter((f) => !f.bypass_backlog)) === 'error' ? (
        <ErrorIcon className={classes.error} />
      ) : (
        <Fragment />
      )}
      {}
    </FlagsTooltip>
  )
}

/**
 * Get the icon type based on the flags provided in the item.
 * @param {Object} item - The item containing flags.
 * @param {Object} item.flags - The flags object.
 * @param {Array} item.flags.globals - The global flags array.
 * @param {Object} item.flags.channels - The channels flags object.
 * @returns {string} - The icon type ('info', 'action', 'warning', 'error').
 */
export function getFlagName(item) {
  const flags = item.flags
  var flags_dict = new Map()
  flags_dict.set('info', 0)
  flags_dict.set('action', 1)
  flags_dict.set('warning', 2)
  flags_dict.set('error', 3)
  var icon_value = -1
  var icon = ''

  const flags_globals = flags.globals ? flags.globals : []
  flags_globals
    .filter((f) => !f.bypass_backlog)
    .forEach((flag) => {
      if (flags_dict.get(flag.level) > icon_value) {
        if (flag.title.includes('Ambiguous screenings') || flag.title.includes('No sample')) {
          icon_value = flags_dict.get('action')
          icon = 'action'
        } else {
          icon_value = flags_dict.get(flag.level)
          icon = flag.level
        }
      }
    })
  const flags_channels = flags.channels ? flags.channels : {}
  const flags_channels_entries = Object.entries(flags_channels)
  if (flags_channels_entries.length > 0 && icon_value !== 2) {
    flags_channels_entries.forEach((channel) => {
      channel[1]
        .filter((f) => !f.bypass_backlog)
        .forEach((flag) => {
          if (flags_dict.get(flag.level) > icon_value) {
            if (channel[0] !== item.mainChannel.toString() && flag.level === 'error') {
              icon_value = 1
              icon = 'warning'
            } else {
              icon_value = flags_dict.get(flag.level)
              icon = flag.level
            }
          }
        })
    })
  }
  return icon
}
/**
 * Update the priority of a task.
 * @param {Event} event - The event object.
 * @param {Object} item - The item whose priority is to be updated.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export function onUpdatePriority(event, item, dispatch) {
  event.stopPropagation()
  event.preventDefault()
  const taskId = new Date().getTime() + '_priority_' + item._id
  const task_istd_batch = {
    id: taskId,
    title: t('view.backlog.update_prio'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'priority',
    percentage: 50,
    state: { value: t('view.backlog.running'), message: '' },
    operation: event.target.value,
    items: [
      {
        id: item._id,
        type: item.analyseType,
        name: item.name,
        compound: {
          name: item.name,
          istd: item.istd_name,
        },
        // batch: {
        //   id: batch._id,
        //   name: batch.name,
        // },
      },
    ],
  }
  dispatch(fetchAddTask(task_istd_batch))
  dispatch(fetchUpdatePriority(item, event.target.value))
    .then(function (resp) {
      displayPopupMessage(
        dispatch,
        'success',
        t('view.backlog.update_prio_success.title'),
        <Fragment>
          <Trans i18nKey="view.backlog.update_prio_success.msg">
            The priority is now {{ val1: event.target.value }} for
            <i>{{ val2: item.name }}</i>.
          </Trans>
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.backlog.everything_fine')))
    })
    .catch(function (error) {
      displayPopupMessage(
        dispatch,
        'error',
        t('view.backlog.update_prio_error.title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.backlog.update_prio_error.msg">
              Can not update the priority for <i>{{ val: item.name }}</i>.
            </Trans>
          </div>
          {generateErrorPart(error)}
          {/*<div style={{ whiteSpace: 'pre-line' }}>{error.message}</div>*/}
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

// export function handleUpdateBatch(event, row, setOpenConfirmUpdateBatch, setCurrentUpdateBatch) {
/**
 * Handle the update of a batch.
 * @param {Event} event - The event object.
 * @param {Object} row - The row data of the batch to be updated.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export function handleUpdateBatch(event, row, dispatch) {
  event.stopPropagation()
  event.preventDefault()
  // setOpenConfirmUpdateBatch(true)
  // setCurrentUpdateBatch(row)
  dispatch(fetchChangeSettings('currentBatchSelected', row))
  dispatch(fetchChangeSettings('openConfirmUpdateBatch', true))
}

/**
 * Open the batch details page.
 * @param {Object} row - The data row containing batch information.
 * @param {Object} history - The history object for navigation.
 */
export function openBatch(row, history) {
  history.push('/?' + DATABASE() + '/batch/' + (row._id ? row._id : ''))
}
/**
 * Open the batch details page in a new tab.
 * @param {Object} row - The data row containing batch information.
 */
export function openBatchInNewTab(row) {
  const url = `${window.location.origin}${window.location.pathname}?${DATABASE()}/batch/${
    row._id ? row._id : ''
  }`
  openInNewTab(url)
}

/**
 * Call to build a training batch.
 * @param {Event} event - The event object.
 * @param {Object} batch - The batch data.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export function callBuildATrainingBatch(
  event,
  batch,
  dispatch,
  // setOpenCreateTrainingBatch,
  // setCurrentUpdateBatch,
) {
  event.stopPropagation()
  event.preventDefault()
  // setOpenCreateTrainingBatch(true)
  // setCurrentUpdateBatch(batch)
  dispatch(fetchChangeSettings('currentBatchSelected', batch))
  dispatch(fetchChangeSettings('openCreateTrainingBatch', true))
}

/**
 * Handle the download of a report for a specific batch.
 * @param {Event} event - The event object.
 * @param {Object} row - The data row containing batch information.
 * @param {Boolean} detailVersion - The flag to indicate if the detailed version of the report is to be downloaded.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export async function handleDownloadReport(event, row, detailVersion, dispatch) {
  const downloadUrl = `${FLASK_URL()}/batch/${
    row ? row._id : ''
  }/reporting?details=${detailVersion}`

  displayPopupMessage(
    dispatch,
    'info',
    t('view.backlog.data_extraction.title'),
    <Fragment>
      <div>
        {t('view.backlog.data_extraction.msg')} {row ? row.name : ''}
      </div>
      <div>
        ({t(detailVersion ? 'view.batch.information.detail' : 'view.batch.information.normal')})
      </div>
    </Fragment>,
  )

  fetch(downloadUrl, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => {
      if (!response.ok) {
        const statusText = response.statusText
        const status = response.status
        const url = response.url
        return response.text().then((errorMessage) => {
          const error = new Error(`${statusText} : ${errorMessage}`)
          if (response.headers.get('content-type') === 'application/json') {
            error.stack = JSON.stringify(
              JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
              null,
              2,
            )
          } else {
            error.stack = new Error().stack
          }
          error.statusText = statusText
          error.status = status
          error.url = url
          throw error
        })
      }
      return response.blob()
    })
    .then((blob) => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      // link.setAttribute('download', row.name + '_report.xlsx')
      link.setAttribute('download', row.name + (detailVersion ? '_report_details.xlsx' : '_report.xlsx'))
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((error) => {
      if (error.status === 403) {
        dispatch(fetchChangeSettings('loginPopup', true))
      }
      displayPopupMessage(
        dispatch,
        'error',
        t('view.backlog.data_extraction.title'),
        <Fragment>
          <div>
            {t('view.backlog.data_extraction.msg')} {row ? row.name : ''}
          </div>
          <div>
            ({t(detailVersion ? 'view.batch.information.detail' : 'view.batch.information.normal')})
          </div>
          <div>{generateErrorPart(error)}</div>
        </Fragment>,
      )
    })
}

/**
 * Handle the stamping of a batch.
 * @param {Event} event - The event object.
 * @param {Object} row - The data row of the batch to be stamped.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export function handleStampBatch(event, row, dispatch) {
  const taskId = new Date().getTime() + '_stamp_batch_' + row._id
  const task = {
    id: taskId,
    title: t('utils.tools.stamp.title'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'stamp_batch',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: t('utils.tools.stamp.operation'),
    items: [
      {
        id: row._id,
        type: 'batch',
        name: row.name,
        batch: {
          id: row._id,
          name: row.name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))
  dispatch(fetchStampBatch(row._id))
    .then(() => {
      dispatch(fetchFinishTask(taskId, 'success', t('utils.tools.stamp.success')))
      // Update the sample (QC / Customers Sample / Others) to update the reported column
      dispatch(fetchAnalysesSentToLims(row._id))
    })
    .catch((error) => dispatch(fetchFinishTask(taskId, 'error', error)))
}
/**
 * Handle the retirement of a batch.
 * @param {Event} event - The event object.
 * @param {Object} row - The data row of the batch to be retired.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export function handleRetiredBatch(event, row, dispatch) {
  const taskId = new Date().getTime() + '_retire_batch_' + row._id
  const task = {
    id: taskId,
    title: t('utils.tools.retired.title'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'retire_batch',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: t('utils.tools.retired.operation'),
    items: [
      {
        id: row._id,
        type: 'batch',
        name: row.name,
        batch: {
          id: row._id,
          name: row.name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))
  dispatch(fetchRetireBatch(row._id))
    .then(() => dispatch(fetchFinishTask(taskId, 'success', t('utils.tools.retired.success'))))
    .catch((error) => dispatch(fetchFinishTask(taskId, 'error', error)))
}

/**
 * Handle setting a batch as a calibration reference.
 * @param {Event} event - The event object.
 * @param {Object} row - The data row of the batch to be set as calibration reference.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export function handleSetBatchAsCalibRef(event, row, dispatch) {
  const taskId = new Date().getTime() + '_setAsCalibRef_batch_' + row._id
  const task = {
    id: taskId,
    title: t('utils.tools.calibRef.title'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: row.calibRef ? 'remove_as_calibration_reference' : 'set_as_calibration_reference',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: row.calibRef
      ? t('utils.tools.calibRef.undo_operation')
      : t('utils.tools.calibRef.operation'),
    items: [
      {
        id: row._id,
        type: 'batch',
        name: row.name,
        batch: {
          id: row._id,
          name: row.name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))
  dispatch(fetchSetAsCalibRefBatch(row._id))
    .then(() => dispatch(fetchFinishTask(taskId, 'success', t('utils.tools.calibRef.success'))))
    .catch((error) => dispatch(fetchFinishTask(taskId, 'error', error)))
}
/**
 * Handle the generation of a method for a batch.
 * @param {Event} event - The event object.
 * @param {Object} row - The data row of the batch for which the method is to be generated.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export function handleGenerateMethod(event, row, dispatch) {
  displayPopupMessage(
    dispatch,
    'info',
    t('utils.tools.generateMethod.title'),
    <Trans i18nKey="utils.tools.generateMethod.msg">
      The method for batch {{ val: row.name }} will be generated.
    </Trans>,
  )
  const taskId = new Date().getTime() + '_generateMethod_batch_' + row._id
  const task = {
    id: taskId,
    title: t('utils.tools.generateMethod.title'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'generate_method',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: t('utils.tools.generateMethod.operation'),
    items: [
      {
        id: row._id,
        type: 'batch',
        name: row.name,
        batch: {
          id: row._id,
          name: row.name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))
  dispatch(fetchGenerateBatchMethod(row))
    .then((response) => {
      dispatch(fetchFinishTask(taskId, 'success', t('utils.tools.generateMethod.success')))
      const getReturnMessage = (code, new_method) => {
        switch (code) {
          // Success
          case 0:
            return (
              <Trans i18nKey="utils.tools.generateMethod.code_success">
                Generated method persisted in database (name={{ val: new_method?.name }})
              </Trans>
            )
          // Duplicate
          case 1:
            return t('utils.tools.generateMethod.code_duplicate')
          // Inactif
          case 2:
          default:
            return t('utils.tools.generateMethod.code_inactif')
        }
      }
      displayPopupMessage(
        dispatch,
        response.generatedResponse.code === 0 ? 'success' : 'info',
        t('utils.tools.generateMethod.title'),
        getReturnMessage(
          response.generatedResponse.code,
          response?.generatedResponse?.methods?.[0],
        ),
      )
    })
    .catch((error) => {
      dispatch(fetchFinishTask(taskId, 'error', error))
      displayPopupMessage(
        dispatch,
        'error',
        t('utils.tools.generateMethod.title'),
        <Fragment>
          <div>{t('utils.tools.generateMethod.error')}</div>
          {generateErrorPart(error)}
        </Fragment>,
      )
    })
}
/**
 * Explore the batch details.
 * @param {Object} row - The data row containing batch information.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export function exploreBatch(row, dispatch) {
  dispatch(fetchDisplayJson(row._id, 'batch'))
}
/**
 * Save the batch backup.
 * @param {Object} row - The data row containing batch information.
 */
export function saveBatch(row) {
  saveBatchBackup(row._id)
}

/**
 * Handle opening the logs popup.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export function handleOpenLogs(dispatch) {
  dispatch(fetchChangeSettings('logsPopup', true))
}

/**
 * Handle the click event on a row.
 * @param {Event} event - The event object.
 * @param {Object} row - The data row.
 * @param {number} delta - The delta value for lease check.
 * @param {Object} history - The history object for navigation.
 * @param {Function} dispatch - The dispatch function to trigger actions.
 */
export function onClick(event, row, delta, history, dispatch) {
  const isLoading = function () {
    return !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment'
  }
  if (!isLoading()) {
    const openFct = () => {
      const dbName = DATABASE()
      history.push('/?' + dbName + '/batch/' + row._id)
    }
    getClickAction(dispatch, event, row, 'batch', openFct)
  }
}

/**
 * Custom overlay component to display when there are no rows in the DataGrid.
 * @returns {JSX.Element} - The rendered custom no rows overlay component.
 */
export function CustomNoRowsOverlay() {
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }))

  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box style={{ fontSize: 'larger' }} sx={{ mt: 1 }}>
        {t('view.backlog.no_data')}
      </Box>
    </StyledGridOverlay>
  )
}
