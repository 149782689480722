import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LoginView from './common/LoginView'
import { TransitionBottom } from './common/Style'
import { Dialog, DialogContent } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { displayPopupMessage } from './common/Message'
import ErrorBoundaryGuard from './ErrorBoundaryGuard'

const styles = (theme) => ({
  customPaperDialog: {
    // height: isMobile ? '100%' : '70%',
    width: 396,
    backgroundColor: 'transparent',
  },
  customRootDialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
})

const mapStateToProps = (state) => {
  return {}
}

class Login extends React.Component {
  constructor(props) {
    super(props)
  }
  async componentDidMount() {}

  render() {
    const { classes, dispatch, history } = this.props

    function onLoginSuccess() {
      // history.push('/')
      // displayPopupMessage(
      //   dispatch,
      //   'success',
      //   'Login success',
      //   <Fragment>
      //     <div>
      //       {'Welcome '} + {localStorage.getItem('SESSION_user_name')} + {' on'} +{' '}
      //       {localStorage.getItem('__config.json__APPLICATION_TITLE')} + {'platform.'}
      //     </div>
      //   </Fragment>,
      // )
    }

    return (
      <Dialog
        open={true}
        TransitionComponent={TransitionBottom}
        aria-labelledby="dialog-title-user"
        classes={{
          paper: classes.customPaperDialog,
          root: classes.customRootDialog,
        }}
      >
        <DialogContent style={{ padding: 0 }}>
          <ErrorBoundaryGuard>
            <LoginView onValidSuccess={onLoginSuccess} />
          </ErrorBoundaryGuard>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Login)))
