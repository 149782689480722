// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import cache from './cache/reducers'
import batches from './batches/reducers'
import analyses from './analyses/reducers'
import unitaryAnalyses from './unitaryAnalyses/reducers'
import unitaryCalibrations from './unitaryCalibrations/reducers'
import message from './message/reducers'
import detail from './detail/reducers'
import drawer from './drawer/reducers'
import lims from './lims/reducers'
import tasks from './tasks/reducers'
import auditTrail from './auditTrail/reducers'
import screening from './screening/reducers'
import json from './json/reducers'
import filters from './filters/reducers'
import test from './test/reducers'
import teams from './teams/reducers'
import settings from './settings/reducers'
import configuration from './configuration/reducers'
import global from './global/reducers'
import logs from './logs/reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import accountings from './accountings/reducers'
import codDriveConfiguration from './codDriveConfiguration/reducers'
import assistant from './assistant/reducers'
import prefetch from './prefetch/reducers'
import {
  createStateSyncMiddleware,
  initMessageListener,
  withReduxStateSync,
  initStateWithPrevTab,
} from 'redux-state-sync'

/**
 * Combines all reducers into a single root reducer
 */
const rootReducer = combineReducers({
  cache,
  batches,
  analyses,
  unitaryAnalyses,
  unitaryCalibrations,
  message,
  settings,
  detail,
  drawer,
  lims,
  tasks,
  teams,
  auditTrail,
  screening,
  json,
  filters,
  test,
  configuration,
  global,
  logs,
  accountings,
  codDriveConfiguration,
  assistant,
  prefetch,
})
/**
 * Configuration for Redux State Sync Middleware
 * Used to synchronize the application state across multiple tabs
 */
const configSyncMiddleware = {
  // TOGGLE_TODO will not be triggered in other tabs
  // blacklist: ['TOGGLE_TODO'],
  // List of action types that should be synchronized across tabs
  whitelist: [
    // 'CHANGE_SETTING',
    'GET_AI_MESSAGES',
    'ADD_AI_MESSAGE',
    'RECEIVE_AI_MESSAGE',
    'ADD_USER_MESSAGE',
    'CLEAR_AI_MESSAGES',
    'RECEIVE_ASSISTANT_STATUS',
    'TELL_ME_MORE',
    'ASSISTANT_WINDOW_OPENED',
    'ASSISTANT_WINDOW_CLOSED',
    'DISPLAY_DETAIL',
    'DETAIL_WINDOW_OPENED',
    'DETAIL_WINDOW_CLOSED',
  ],
  // predicate: action => action.type !== 'TOGGLE_TODO',
  // channel: 'my_broadcast_channel',
  // Broadcast channel configuration
  broadcastChannelOption: {
    type: 'localstorage', //['native', 'idb', 'localstorage', 'node']
    webWorkerSupport: true,
  },
  initiateWithState: true, // Ensures the initial state is synchronized across tabs
  prepareState: (state) => {
    // Do not share the messages (serialization error in message)
    // Do not share the loginPopup key (error in multi tabs)
    return {
      ...state,
      message: { messages: [] },
      settings: { ...state.settings, loginPopup: false },
    }
  },
}
/**
 * Middleware to log Redux actions to the console
 */
const loggerMiddleware = createLogger()
/**
 * Middleware for syncing Redux state across tabs
 */
const syncMiddleware = createStateSyncMiddleware(configSyncMiddleware)

/**
 * Debugging middleware to log intercepted actions before reducers process them
 * @param store - Redux store
 */
const debugMiddleware = (store) => (next) => (action) => {
  console.log('🟢 Intercepted action before reducer:', action.type)
  return next(action)
}

/**
 * List of middlewares used in the Redux store
 */
const middlewares = [
  thunkMiddleware,
  syncMiddleware,
  // loggerMiddleware,
  // debugMiddleware
]

/**
 * Creates and configures the Redux store with middleware and Redux dev tools
 */
const store = createStore(
  withReduxStateSync(rootReducer),
  {},
  composeWithDevTools(applyMiddleware(...middlewares)),
)
// Initializes synchronization with previous tabs
initStateWithPrevTab(store)

// Optional: Listen for messages from Redux State Sync
// initMessageListener(store)
// initMessageListener((message) => {
//   // console.log('🔄 Message Redux-State-Sync reçu :', message)
//
//   if (message.type === '&_RECEIVE_INIT_STATE') {
//     // console.log('🚀 Synchronisation initiale détectée entre onglets !')
//     store.dispatch({ type: 'SYNC_RECEIVED' })
//   }
// })

// Uncomment the following if you need to configure the store with an initial state
// export default function configureStore(preloadedState) {
//   return createStore(
//     rootReducer,
//     preloadedState,
//     composeWithDevTools(applyMiddleware(...middlewares)),
//   )
// }

// Uncomment the following if you need a function to return the store instance
// export default function configureStore() {
//   return store
// }
/**
 * Exports the Redux store as the default export
 */
export default store
