// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import {
  REQUEST_BATCHES,
  RECEIVE_BATCHES,
  RECEIVE_BATCH,
  RECEIVE_UPDATE_BATCHES,
  RECEIVE_CREATE_SUB_BATCH,
  RECEIVE_MOVE_SUB_BATCH,
  AUTHENTICATION_FAILURE,
  RECEIVE_CHANGE_ANALYSES_TYPE,
  ITEM_NOT_FOUND,
  RECEIVE_CREATE_TRAINING_BATCH,
  REQUEST_SEARCH_BATCHES,
  RECEIVE_SEARCH_BATCHES,
  RECEIVE_DEVICES,
  RECEIVE_UPDATE_PRIORITY,
  RECEIVE_UPDATE_NOTE,
  POLLING_BATCHES,
  RECEIVE_SERVICES,
  RECEIVE_TYPES,
  CLEAN_CURRENT_BATCH_LEASE,
  RECEIVE_CURRENT_BATCH_LEASE,
  REQUEST_STAMP_BATCH,
  RECEIVE_STAMP_BATCH,
  REQUEST_RETIRE_BATCH,
  RECEIVE_RETIRE_BATCH,
  REQUEST_CALIBREF_BATCH,
  RECEIVE_CALIBREF_BATCH,
  REQUEST_RESTORE_BATCH,
  RECEIVE_RESTORE_BATCH,
} from './actions'
import { AUTHENTICATION_ERROR, DEFAULT_ERROR, NOT_FONDED, PERMISSION_ERROR } from '../tools'

/**
 * Initial state for the batches reducer.
 * @type {{items: *[], isFetching: boolean, error: boolean, batchNotFound: boolean, keyword: null, isSearchingBatches: boolean, searchedBatches: *[], devices: *[], services: *[], types: *[], lastModificationDate: number, updatedBatch: null, currentBatchLease: {date: number, operator: null, reason: null}, batchesLastDateRequest: null, batchesSearchedLastDateRequest: null, isFetchingRetireBatch: boolean, isFetchingCalibRefBatch: boolean}}
 */
const initialState = {
  items: [], // List of batch items
  isFetching: false, // Indicates if batches are being fetched
  error: false, // Indicates if an error occurred
  batchNotFound: false, // Flag to indicate if a batch was not found
  keyword: null, // Search keyword
  isSearchingBatches: false, // Indicates if a search operation is in progress
  searchedBatches: [], // List of searched batches
  devices: [], // List of devices
  services: [], // List of services
  types: [], // List of batch types
  lastModificationDate: 0, // Last modification date of batches
  updatedBatch: null, // Batch that was last updated
  currentBatchLease: { date: 0, operator: null, reason: null }, // Current lease details of the batch
  batchesLastDateRequest: null, // Date of the last batches request
  batchesSearchedLastDateRequest: null, // Date of the last search request
  isFetchingRetireBatch: false, // Indicates if a retire batch operation is in progress
  isFetchingCalibRefBatch: false, // Indicates if a calibration reference batch operation is in progress
}

/**
 * Function to update fields in batch items that might be missing from the database response.
 * @param {Array|Object} items - Batch items or a single batch item.
 * @returns {Array|Object} Updated batch items with missing fields added.
 */
const updateEmptyField = (items) => {
  const updateField = (item) => {
    if (item.content.molecules === undefined) {
      item.content['molecules'] = {} // Ensure 'molecules' field exists in the batch content
    }
    return item
  }
  if (Array.isArray(items)) {
    return items.map((item) => {
      return updateField(item)
    })
  } else {
    return updateField(items)
  }
}

/**
 * Function to compare two lists of batches for equality.
 * @param {Array} a - First list of batches.
 * @param {Array} b - Second list of batches.
 * @returns {boolean} True if the batches are equal, false otherwise.
 */
const batchesEqual = function (a, b) {
  if (a.length === 0 && b.length === 0) return true
  if (a.length !== b.length) return false

  const aIds = a.map((as) => as._id)
  const bIds = b.map((bs) => bs._id)

  for (let i = 0; i < aIds.length; ++i) {
    if (
      !bIds.includes(aIds[i]) ||
      a[i].modificationDate !== b[bIds.indexOf(a[i]._id)].modificationDate
    ) {
      return false
    }
  }
  for (const bId of bIds) {
    if (!aIds.includes(bId)) {
      return false
    }
  }
  return true
}

/**
 * Function to get the last modification date from a list of batches.
 * @param {Array} batches - List of batches.
 * @returns {number|null} The last modification date, or null if no batches are present.
 */
const getLastModificationDate = function (batches) {
  const lastBatch = batches.toSorted(function (a, b) {
    if (a.modificationDate < b.modificationDate) return 1
    if (a.modificationDate > b.modificationDate) return -1
    return 0
  })[0]
  return lastBatch ? lastBatch.modificationDate : null
}

/**
 * Reducer function for managing batch-related state.
 * @param {Object} state - Current state.
 * @param {Object} action - Action dispatched to the reducer.
 * @returns {Object} Updated state.
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case POLLING_BATCHES: {
      // Compare old and new batches to see if update is needed
      const noModification = batchesEqual(state.items, action.batches)
      if (noModification) {
        return {
          ...state,
        }
      } else {
        let lmd = getLastModificationDate(action.batches)
        return {
          ...state,
          items: updateEmptyField(action.batches),
          lastModificationDate: lmd || state.lastModificationDate,
        }
      }
    }
    case REQUEST_BATCHES:
      if (!action.from && !action.to) {
        return {
          ...state,
          batchesLastDateRequest: action.dateLock,
          isFetching: !action.polling,
        }
      } else {
        return {
          ...state,
          isSearchingBatches: false,
          isFetching: true,
          error: false,
          batchNotFound: false,
          from: action.from,
          to: action.to,
          batchesLastDateRequest: action.dateLock,
        }
      }

    case RECEIVE_BATCHES:
      if (action.errorReturn) {
        // Force to stop to display the loading case error happened
        return {
          ...state,
          isFetching: false,
        }
      } else if (action.dateLock !== state.batchesLastDateRequest) {
        // Avoid displaying an old request after a more recent one which was faster.
        return {
          ...state,
        }
      } else {
        let lmdrb = getLastModificationDate(action.batches)
        return {
          ...state,
          isSearchingBatches: false,
          isFetching: false,
          error: false,
          batchNotFound: false,
          items: updateEmptyField(action.batches),
          lastModificationDate: lmdrb || state.lastModificationDate,
        }
      }

    case RECEIVE_BATCH: {
      // Add the new batch in all case, but we have to remove the old one from the store
      const listItems = state.items.filter((item) => item._id !== action.batch._id)
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: [...listItems, updateEmptyField(action.batch)],
      }
    }
    case RECEIVE_UPDATE_BATCHES: {
      // console.log('reducers batches')
      const batchIds = action.objectsUpdated.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      let lmdrub = getLastModificationDate(action.objectsUpdated)
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: state.items.map(function (batch) {
          if (batchIds[batch._id]) {
            return updateEmptyField(batchIds[batch._id])
          }
          return batch
        }),
        lastModificationDate: lmdrub || state.lastModificationDate,
      }
    }
    case RECEIVE_CREATE_SUB_BATCH:
      // Add new sub-batch in the store
      return {
        ...state,
        items: [...state.items, updateEmptyField(action.objectsUpdated.batch)],
        lastModificationDate: action.objectsUpdated.batch.modificationDate,
      }
    case RECEIVE_MOVE_SUB_BATCH:
    case RECEIVE_CHANGE_ANALYSES_TYPE:
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: state.items,
      }

    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: true,
        batchNotFound: false,
      }
    case ITEM_NOT_FOUND:
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        batchNotFound: true,
        error: false,
      }
    case RECEIVE_CREATE_TRAINING_BATCH:
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: [
          ...state.items.filter((item) => item._id !== action.trainingBatch._id),
          updateEmptyField(action.trainingBatch),
        ],
        lastModificationDate: action.trainingBatch.modificationDate,
      }
    case REQUEST_SEARCH_BATCHES:
      return {
        ...state,
        isSearchingBatches: true,
        keyword: action.keyword,
        error: false,
        batchesSearchedLastDateRequest: action.dateLock,
      }
    case RECEIVE_SEARCH_BATCHES:
      // Avoid displaying an old request after a more recent one which was faster.
      if (action.dateLock !== state.batchesSearchedLastDateRequest) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          isSearchingBatches: false,
          error: false,
          searchedBatches: updateEmptyField(action.searchedBatches),
        }
      }
    case RECEIVE_DEVICES:
      return {
        ...state,
        error: false,
        devices: action.devices,
      }
    case RECEIVE_SERVICES:
      return {
        ...state,
        error: false,
        services: action.services,
      }
    case RECEIVE_TYPES:
      return {
        ...state,
        error: false,
        types: action.types,
      }
    case RECEIVE_UPDATE_PRIORITY:
      // let lmdrup = getLastModificationDate(action.updatedBatch.modificationDate)
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: state.items.map(function (batch) {
          if (action.updatedBatch._id === batch._id) {
            return updateEmptyField(action.updatedBatch)
          }
          return batch
        }),
        lastModificationDate: state.lastModificationDate,
      }
    case RECEIVE_UPDATE_NOTE:
      return {
        ...state,
        isSearchingBatches: false,
        isFetching: false,
        error: false,
        batchNotFound: false,
        items: state.items.map(function (batch) {
          if (action.updatedBatch._id === batch._id) {
            return updateEmptyField(action.updatedBatch)
          }
          return batch
        }),
        lastModificationDate: action.updatedBatch.modificationDate,
      }
    case CLEAN_CURRENT_BATCH_LEASE:
      return {
        ...state,
        currentBatchLease: { date: 0, operator: null, reason: null },
      }
    case RECEIVE_CURRENT_BATCH_LEASE:
      return {
        ...state,
        currentBatchLease: action.currentBatchLease,
      }
    case REQUEST_STAMP_BATCH:
      return {
        ...state,
        isFetchingStampBatch: true,
      }
    case RECEIVE_STAMP_BATCH:
      return {
        ...state,
        isFetchingStampBatch: false,
        items: state.items.map(function (batch) {
          if (action.stampedBatch._id === batch._id) {
            return updateEmptyField(action.stampedBatch)
          }
          return batch
        }),
      }
    case REQUEST_RETIRE_BATCH:
      return {
        ...state,
        isFetchingRetireBatch: true,
      }
    case RECEIVE_RETIRE_BATCH:
      return {
        ...state,
        isFetchingRetireBatch: false,
        items: state.items.map(function (batch) {
          if (action.retiredBatch._id === batch._id) {
            return updateEmptyField(action.retiredBatch)
          }
          return batch
        }),
      }
    case REQUEST_CALIBREF_BATCH:
      return {
        ...state,
        isFetchingCalibRefBatch: true,
      }
    case RECEIVE_CALIBREF_BATCH: {
      const returnedBatchIds = action.calibRefBatches.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isFetchingCalibRefBatch: false,
        items: state.items.map(function (batch) {
          if (returnedBatchIds[batch._id]) {
            return updateEmptyField(returnedBatchIds[batch._id])
          }
          return batch
        }),
      }
    }
    case REQUEST_RESTORE_BATCH:
      return {
        ...state,
      }
    case RECEIVE_RESTORE_BATCH: {
      const listItemsWithoutRestored = state.items.filter(
        (item) => item._id !== action.restoredBatch._id,
      )
      return {
        ...state,
        items: [...listItemsWithoutRestored, action.restoredBatch],
      }
    }
    case PERMISSION_ERROR:
    case NOT_FONDED:
    case AUTHENTICATION_ERROR:
    case DEFAULT_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}
