// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { DISPLAY_AUDIT_TRAIL, REQUEST_AUDIT_TRAIL, HIDE_AUDIT_TRAIL } from './actions'
import React from 'react'

const initialState = {
  auditTrail: [],
  fetchingAuditTrail: false,
  display: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_AUDIT_TRAIL:
      return {
        ...state,
        auditTrail: [],
        fetchingAuditTrail: true,
        display: action.refId,
      }

    case DISPLAY_AUDIT_TRAIL:
      return {
        ...state,
        auditTrail: action.auditTrail,
        fetchingAuditTrail: false,
      }

    case HIDE_AUDIT_TRAIL:
      return {
        ...state,
        display: null,
      }
    default:
      return state
  }
}
