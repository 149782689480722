// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Draggable from 'react-draggable'
import { t } from 'i18next'
import Button from '@material-ui/core/Button'
import { PUBLIC_URL } from '../../utils/config'
import Box from '@material-ui/core/Box'
import Stack from '@mui/material/Stack'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { withRouter } from 'react-router-dom'
import CancelIcon from '@mui/icons-material/Cancel'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  customModal: {
    display: 'none',
    position: 'fixed',
    top: 'calc(50% - 200px)',
    left: 'calc(50% - 300px)',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1400,
    borderRadius: 5,
    width: '40%',
    minWidth: 765,
    height: '60%',
  },
  open: {
    display: 'block',
  },
  modalContent: {
    padding: 0,
    width: '100%',
    height: '100%',
    /* Style de votre contenu modal */
  },
  header: {
    cursor: 'move',
    height: 40,
    position: 'absolute',
    width: 'calc(100% - 120px)',
    opacity: 0,
  },
  closeButton: {
    position: 'absolute',
    right: 22,
    top: 6,
  },
  cardImg: {
    filter: 'blur(3px)',
    height: 30,
    userSelect: 'none',
    opacity: 0.5,
    borderRadius: '5px 5px 0px 0px',
    backgroundImage: 'url(' + PUBLIC_URL + '/images/helper_bkg.jpg' + ')',
    backgroundSize: 'cover',
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
}))

const WikiDialog = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const modalRef = useRef(null)

  const open = useSelector(function (state) {
    return state.settings.wikiPopup
  })
  const wikiCODDocLanguage = useSelector((state) => state.settings.wikiCODDoc)

  const closeFct = () => {
    dispatch(fetchChangeSettings('wikiPopup', false))
  }

  return (
    <Draggable handle=".modal-handle">
      <div ref={modalRef} className={clsx(classes.customModal, open ? classes.open : '')}>
        <div className={clsx('modal-handle', classes.header)} />
        <Tooltip
          arrow
          placement={'top'}
          title={
            <div style={{ fontSize: 13, padding: 5 }}>{t('view.dialogs.config.helper.close')}</div>
          }
        >
          <IconButton className={classes.closeButton} size="small" onClick={closeFct}>
            <CancelIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <div className={classes.modalContent}>
          <iframe
            style={{ borderRadius: 5, overflowX: 'hidden' }}
            title={t('view.dialogs.config.helper.wiki')}
            src={
              localStorage.getItem('__config.json__ALPHACOD_WIKI_URL') +
              'doku.php?id=fujitsu:alphacod:prod:' +
              wikiCODDocLanguage +
              ':manual'
            }
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
      </div>
    </Draggable>
  )
})
export default WikiDialog
