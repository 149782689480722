// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import {
  REQUEST_GLOBAL_CONFIGURATIONS,
  RECEIVE_GLOBAL_CONFIGURATIONS,
  FAILED_RECEIVE_GLOBAL_CONFIGURATIONS,
} from './actions'
import React from 'react'

const initialState = {
  globalConfigs: [],
  featuresConfig: null,
  customerConfig: null,
  isFetchingGlobalConfigs: false,
  isGlobalConfigsLoaded: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_GLOBAL_CONFIGURATIONS:
      return {
        ...state,
        isFetchingGlobalConfigs: true,
      }

    case RECEIVE_GLOBAL_CONFIGURATIONS:
      return {
        ...state,
        globalConfigs: action.globalConfigs,
        featuresConfig: action.globalConfigs.filter((gc) => gc.name === 'features')[0],
        customerConfig: action.globalConfigs.filter((gc) => gc.name === 'customer')[0],
        isFetchingGlobalConfigs: false,
        isGlobalConfigsLoaded: true,
      }
    case FAILED_RECEIVE_GLOBAL_CONFIGURATIONS:
      return {
        ...state,
        isFetchingGlobalConfigs: false,
        isGlobalConfigsLoaded: true,
      }
    default:
      return state
  }
}
