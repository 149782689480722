// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useMemo, useRef, useState, memo } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import clippyRead from '../../../resources/images/clippy_read.gif'
import assistantIcon from '../../../resources/images/assistant-ia_mini.png'
import clsx from 'clsx'
import Loading from '../../Loading'
import TextField from '@material-ui/core/TextField'
import { t } from 'i18next'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'
import { alpha, makeStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Paper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Badge from '@mui/material/Badge'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import clippyIcon from '../../../resources/images/Nostalgia_Clippy_mini.png'
import Typography from '@mui/material/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import { copyToClipboard } from '../../../utils/utilities'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { marked } from 'marked'
import {
  fetchAddAIMessage,
  fetchAddUserMessage,
  fetchAssitantStatus,
} from '../../../redux/assistant/actions'
import AIMenu from './AIMenu'
import InfoIcon from '@mui/icons-material/Info'

const useStyles = makeStyles((theme) => ({
  modalContent: {
    padding: 17,
    // height: 'calc(100% - 70px)',
    overflow: 'auto',
    /* Style de votre contenu modal */
  },
  assistantMessage: {
    padding: '2px 0px',
    '& > div > .MuiPaper-root': {
      borderRadius: '20px 20px 20px 0px',
      padding: 10,
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    '&.error > div > .MuiPaper-root': {
      backgroundColor: alpha(theme.palette.warning.main, 0.3),
    },
  },
  userMessage: {
    marginLeft: 'auto',
    textAlign: 'right',
    padding: '2px 0px',
    '& > div > .MuiPaper-root': {
      borderRadius: '20px 20px 0px 20px',
      textAlign: 'left',
      padding: 10,
      backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    },
  },
  systemMessage: {
    padding: '2px 0px',
    '& > div > .MuiPaper-root': {
      borderRadius: '20px 20px 20px 0px',
      padding: 10,
      backgroundColor: alpha(theme.palette.info.main, 0.2),
    },
  },
  systemIcon: {
    color: theme.palette.info.main,
  },
  systemMsg: {
    userSelect: 'text',
    fontFamily: 'monospace',
    '& p': {
      margin: 5,
    },
  },
  errorMessage: {
    width: 'calc(100% - 34px)',
    marginLeft: '17px !important',
    padding: '10px 0px 0px',
    '& > .MuiPaper-root': {
      textAlign: 'center',
      borderRadius: 10,
      padding: 10,
      backgroundColor: alpha('#ff2f2f', 0.2),
    },
  },
  inputMsg: {
    margin: '0px 17px 15px !important',
    width: 'calc(100% - 34px)',
    '& textarea': {
      overflow: 'auto !important',
      maxHeight: 230,
    },
    '& .MuiInputBase-root': {
      borderRadius: 10,
    },
  },
  displayMenu: {
    margin: '0px 0px 15px 17px !important',
    width: 'calc(100% - 60px)',
  },
  msgContainer: {
    height: 'calc(100% - 0px)',
  },
  clippyImg: {
    width: 55,
    borderRadius: 20,
  },
  clippyImgBgr: {
    background: 'white',
  },
  clippyImgMsg: {
    width: 35,
    borderRadius: 20,
  },
  assistantImg: {
    background: theme.palette.primary.main,
    borderRadius: 20,
    padding: '6px 3px',
  },
  positionBadge: {
    '& .MuiBadge-badge': {
      right: '14%',
      bottom: '14%',
      transform: 'scale(1) translate(50%, 50%)',
      transformOrigin: '100% 100%',
    },
  },
  assistantBadge: {
    width: 35,
    height: 35,
    '& .MuiBadge-badge': {
      // width: 15,
      // height: 15,
      // padding: 0,
      // minWidth: 0,
      // right: -8,
      // color: fade(theme.palette.primary.main, 0.6),
      padding: 0,
      background: 'white',
      color: 'white',
      // boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
      border: '2px solid white',
    },
  },
  assistantBadgeMini: {
    '& .MuiBadge-badge': {
      width: 15,
      height: 15,
      minWidth: 0,
      border: '1px solid white',
      zIndex: 0,
    },
  },
  assistantBadgeOnline: {
    '& .MuiBadge-badge': {
      // color: 'green',
      background: 'green',
    },
  },
  assistantBadgeOffline: {
    '& .MuiBadge-badge': {
      // color: 'red',
      background: '#a1a1a1',
    },
  },
  badgeIconMini: {
    width: 9,
    height: 9,
  },
  assistantFetching: {
    width: 'calc(100% - 60px)',
    height: 25,
    overflow: 'hidden',
    marginLeft: 30,
    position: 'absolute',
    bottom: 55,
  },
  clippyFetching: {
    width: 'calc(100% - 60px)',
    height: 41,
    overflow: 'hidden',
    marginLeft: 9,
    position: 'absolute',
    bottom: 55,
  },
  clippyWaiting: {
    background: 'white',
    height: 45,
    width: 60,
    paddingTop: 27,
    paddingLeft: 9,
    marginLeft: '0px !important',
    borderRadius: '0px 20px 0px 0px',
  },
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      width: 'auto',
      display: 'block',
    },
  },
  moreButton: {
    margin: '4px 0px 0px 4px',
  },
}))
const AIContainer = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { displayMenu } = props

  const admin = JSON.parse(localStorage.getItem('SESSION_user_json'))?.admin

  const scrollContainerRef = useRef(null)
  const messagesEndRef = useRef(null)
  const inputRef = useRef(null)

  const [errorMsg, setErrorMsg] = useState(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [currentTime, setCurrentTime] = useState(Date.now())
  const [newQuery, setNewQuery] = useState('')

  const open = useSelector(function (state) {
    return state.settings.assistantPopup
  })

  const assistant_loaded = useSelector(function (state) {
    return state.assistant.loaded
  })
  const ai_messages = useSelector(function (state) {
    return state.assistant.messages
  })
  const assistantType = useSelector(function (state) {
    return state.settings.assistantType
  })
  const ai_fetching = useSelector(function (state) {
    return state.assistant.ai_fetching
  })
  const systemError = useSelector(function (state) {
    return state.assistant.ai_status === 'offline'
  })

  // const systemError = useMemo(() => {
  //   return assistant_online === 'offline'
  // }, [assistant_online])

  const clippyActivated = useMemo(() => {
    return admin && assistantType === 'clippy'
  }, [assistantType])

  const messages = useMemo(() => {
    return ai_messages.map((message, index) => {
      if (message.cmd_system) {
        return (
          <SystemMessage
            key={new Date(message.date).getTime() + '_' + index}
            message={message}
            classes={classes}
            error={systemError}
            time={currentTime}
          />
        )
      } else if (message.is_assistant) {
        // return getAssistantMsg(message)
        return (
          <AssistantMessage
            key={new Date(message.date).getTime() + '_' + index}
            message={message}
            classes={classes}
            error={systemError}
            time={currentTime}
          />
        )
      } else {
        // return getUserMsg(message)
        return (
          <UserMessage
            key={new Date(message.date).getTime() + '_' + index}
            message={message}
            classes={classes}
            error={systemError}
            time={currentTime}
          />
        )
      }
    })
  }, [ai_messages.length, clippyActivated, currentTime, systemError])

  useEffect(() => {
    dispatch(fetchAssitantStatus())
      .then((resp) => {
        if (resp === 'online' && assistant_loaded && ai_messages.length === 0) {
          const message = t('view.dialogs.assistant.intro', {
            val: localStorage.SESSION_user_name,
          })
          dispatch(
            fetchAddAIMessage({
              is_assistant: true,
              date: new Date(),
              msg: message,
              forbidCopy: true,
            }),
          )
        }
        setErrorMsg(null)
      })
      .catch((error) => {
        let resp = error
        if (error.status && error.statusText) {
          resp =
            (error.status ? t('view.dialogs.assistant.error') + ' ' + error.status + '\n' : '') +
            (error.statusText ? error.statusText : '')
        }
        setErrorMsg(resp)
      })
    if (inputRef.current) {
      inputRef.current.focus()
    }
    scrollToBottom()
  }, [systemError])

  // Effet pour mettre à jour l'heure actuelle toutes les minutes
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now()) // Mise à jour de l'heure actuelle
    }, 30000) // 30000 ms = 30 sec

    return () => clearInterval(interval) // Nettoyage de l'intervalle lors du démontage
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [ai_messages.length, open])

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = parseInt(sessionStorage.getItem('scroll_position'))
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        setScrollPosition(scrollContainerRef.current.scrollTop)
        sessionStorage.setItem('scroll_position', scrollContainerRef.current.scrollTop)
      }
    }

    const container = scrollContainerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    // Nettoyer l'event listener lorsque le composant est démonté
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  function sendToAssistant() {
    dispatch(
      fetchAddUserMessage({
        date: new Date(),
        msg: newQuery,
        is_assistant: false,
      }),
    )
    // .catch((error) =>
    //   displayPopupMessage(
    //     dispatch,
    //     'error',
    //     t('view.dialogs.assistant.errorTitle'),
    //     <Fragment>
    //       <div>{t('view.dialogs.assistant.errorMsg')}</div>
    //       <div>{generateErrorPart(error)}</div>
    //     </Fragment>,
    //   ),
    // )
    // .finally(() => setNewQuery(''))
    setNewQuery('')
  }

  const ElapsedTime = memo(({ messageDate, time }) => {
    const elapsedTime = () => {
      const diffMs = time - new Date(messageDate)

      const secondes = Math.floor(diffMs / 1000)
      const minutes = Math.floor(secondes / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      if (secondes < 60) {
        return t('view.dialogs.assistant.instant')
      } else if (minutes < 60) {
        if (minutes === 1) {
          return (
            <Trans i18nKey="view.dialogs.assistant.minute">{{ val: minutes }} minute ago</Trans>
          )
        } else {
          return (
            <Trans i18nKey="view.dialogs.assistant.minutes">{{ val: minutes }} minutes ago</Trans>
          )
        }
      } else if (hours < 24) {
        return new Date(messageDate).toLocaleTimeString()
      } else {
        return new Date(messageDate).toLocaleString()
        // const minutesRestantes = minutes % 60
        // return `Il y a ${hours} heures et ${minutesRestantes} minutes`
        // } else {
        //   const heuresRestantes = hours % 24
        //   return `Il y a ${days} jours et ${heuresRestantes} heures`
      }
    }

    return elapsedTime()
  })

  function SystemMessage({ message, classes, error, time }) {
    const [showButton, setShowButton] = useState(false)

    const handleMouseEnter = () => {
      setShowButton(true)
    }
    const handleMouseLeave = () => {
      setShowButton(false)
    }
    return (
      <Grid item={true} xs={12} className={classes.systemMessage}>
        <div style={{ width: 'fit-content' }}>
          <Paper
            style={{ width: 'fit-content' }}
            elevation={0}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <InfoIcon className={classes.systemIcon} />
              <span className={classes.systemMsg}>
                {typeof message.msg === 'string' ? (
                  <div dangerouslySetInnerHTML={{ __html: marked(message.msg) }} />
                ) : (
                  message.msg
                )}
              </span>
            </Stack>
          </Paper>
          <div>
            <Typography variant="caption" gutterBottom style={{ marginLeft: 2 }}>
              <ElapsedTime messageDate={new Date(message.date)} time={time} />
            </Typography>
          </div>
        </div>
      </Grid>
    )
  }
  function AssistantMessage({ message, classes, error, time }) {
    const [showButton, setShowButton] = useState(false)

    const handleMouseEnter = () => {
      setShowButton(true)
    }
    const handleMouseLeave = () => {
      setShowButton(false)
    }
    return (
      <Grid
        item={true}
        xs={12}
        className={clsx(classes.assistantMessage, message.error ? 'error' : undefined)}
      >
        <div style={{ width: 'fit-content' }}>
          <Paper
            style={{ width: 'fit-content' }}
            elevation={0}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                overlap="circular"
                badgeContent={
                  assistant_loaded ? (
                    error ? (
                      <CloseIcon className={classes.badgeIconMini} />
                    ) : (
                      <DoneIcon className={classes.badgeIconMini} />
                    )
                  ) : (
                    0
                  )
                }
                className={clsx(
                  classes.positionBadge,
                  classes.assistantBadge,
                  classes.assistantBadgeMini,
                  error ? classes.assistantBadgeOffline : classes.assistantBadgeOnline,
                )}
              >
                <img
                  src={clippyActivated ? clippyIcon : assistantIcon}
                  alt="Assistant"
                  className={clippyActivated ? classes.clippyImgMsg : classes.assistantImg}
                  style={{ opacity: error || !assistant_loaded ? 0.2 : 1 }}
                />
              </Badge>
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ margin: 0, marginLeft: 10, paddingTop: 8 }}
              >
                {t('view.dialogs.assistant.title')}
              </Typography>
              {message.error ? (
                <WarningIcon
                  style={{ margin: '5px 5px 0px auto', width: 24, height: 24, color: 'orange' }}
                />
              ) : (
                !message.forbidCopy &&
                showButton && (
                  <IconButton
                    aria-label="copy"
                    size="small"
                    style={{ marginLeft: 'auto', width: 24, height: 24 }}
                    onClick={(e) => {
                      // function htmlToText(html) {
                      //   // Créer un nouvel objet DOMParser
                      //   const parser = new DOMParser()
                      //   // Parse le HTML
                      //   const doc = parser.parseFromString(html, 'text/html')
                      //   // Extraire le texte brut
                      //   return doc.body.textContent || ''
                      // }
                      // const html_version = marked(message.msg)
                      // copyToClipboard(e, htmlToText(html_version))
                      function docuWikiToText(docuWikiText) {
                        // Remplacer les titres docu-wiki (exemple `== Titre ==`) par un simple titre en texte
                        let plainText = docuWikiText.replace(/==\s*(.*?)\s*==/g, '$1\n')
                        // Remplacer le gras (exemple `**gras**`) par du texte brut
                        plainText = plainText.replace(/\*\*(.*?)\*\*/g, '$1')
                        // Remplacer les liens de type docu-wiki (exemple `[[Lien]]`) par du texte simple
                        plainText = plainText.replace(/\[\[(.*?)\]\]/g, '$1')
                        // Retirer les puces de listes (exemple `* Item`)
                        plainText = plainText.replace(/^\* /gm, '- ')
                        // Gérer les autres balises docu-wiki spécifiques (selon les besoins)
                        plainText = plainText.replace(/^###\s*(.*)$/gm, '[ $1 ]')
                        return plainText
                      }

                      copyToClipboard(e, docuWikiToText(message.msg))
                    }}
                  >
                    <ContentCopyIcon fontSize="inherit" />
                  </IconButton>
                )
              )}
            </div>
            {message.img && (
              <div className={classes.container}>
                <img
                  src={message.img}
                  alt="Image"
                  // className={clippyActivated ? classes.clippyImgMsg : classes.assistantImg}
                  // style={{ opacity: error || !assistant_loaded ? 0.2 : 1 }}
                />
              </div>
            )}
            <span style={{ userSelect: 'text' }}>
              {typeof message.msg === 'string' ? (
                // message.msg.split('\n').map((line, index) => (
                //     <Fragment key={index}>
                //       {line}
                //       <br />
                //     </Fragment>
                //   ))
                <div dangerouslySetInnerHTML={{ __html: marked(message.msg) }} />
              ) : (
                message.msg
              )}
            </span>
          </Paper>
          <div>
            <Typography variant="caption" gutterBottom style={{ marginLeft: 2 }}>
              <ElapsedTime messageDate={new Date(message.date)} time={time} />
            </Typography>
          </div>
        </div>
      </Grid>
    )
  }

  function UserMessage({ message, classes, time }) {
    const [showButton, setShowButton] = useState(false)
    const handleMouseEnter = () => {
      setShowButton(true)
    }
    const handleMouseLeave = () => {
      setShowButton(false)
    }
    const formattedMessage = message.msg.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ))
    return (
      <Grid item={true} xs={12} className={classes.userMessage}>
        <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
          <Paper
            style={{ width: 'fit-content', marginLeft: 'auto', userSelect: 'text' }}
            elevation={0}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {formattedMessage}
          </Paper>
          <div>
            <Typography
              variant="caption"
              gutterBottom
              style={{ textAlign: 'right', marginRight: 2 }}
            >
              {/*{new Date(message.date).toLocaleTimeString()}*/}
              {/*{elapsedTime(message.date, time)}*/}
              <ElapsedTime messageDate={new Date(message.date)} time={time} />
            </Typography>
          </div>
        </div>
      </Grid>
    )
  }

  function getErrorMsg(message) {
    let tmpMsg = message
    if (typeof message !== 'string') {
      tmpMsg = tmpMsg.toString()
    }
    const formattedMessage = tmpMsg.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ))
    return (
      <div className={classes.errorMessage}>
        <Paper elevation={0}>{formattedMessage}</Paper>
      </div>
    )
  }

  return (
    <Stack spacing={2} justifyContent="space-between" className={classes.msgContainer}>
      <Fragment>
        {systemError && errorMsg !== null && getErrorMsg(errorMsg)}
        <div className={classes.modalContent} ref={scrollContainerRef}>
          <Grid container spacing={0} style={{ padding: 0, width: '100%', margin: 0 }}>
            {messages}
            <div ref={messagesEndRef} />
          </Grid>
        </div>
      </Fragment>
      <span>
        {ai_fetching && assistant_loaded && !systemError && (
          <div className={clippyActivated ? classes.clippyFetching : classes.assistantFetching}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <img
                src={clippyActivated ? clippyRead : assistantIcon}
                alt="Assistant"
                className={clsx(
                  clippyActivated ? classes.clippyImg : classes.assistantImg,
                  clippyActivated ? classes.clippyImgBgr : undefined,
                )}
                style={{
                  opacity: systemError || !assistant_loaded ? 0.2 : 1,
                  borderRadius: clippyActivated ? '20px 0px 0px' : 20,
                }}
              />
              <div className={clippyActivated ? classes.clippyWaiting : undefined}>
                <Loading mode={'typing'} style={{ width: 34 }} />
              </div>
            </Stack>
          </div>
        )}
        <Fragment>
          <TextField
            disabled={systemError || !assistant_loaded}
            inputRef={inputRef}
            value={newQuery}
            onChange={(event) => setNewQuery(event.target.value)}
            autoFocus
            className={clsx(classes.inputMsg, displayMenu ? classes.displayMenu : undefined)}
            placeholder={t('view.dialogs.assistant.placeholder')}
            multiline
            size="small"
            variant="outlined"
            color="secondary"
            fullWidth
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
                e.stopPropagation()
                e.preventDefault()
                sendToAssistant()
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={newQuery === ''}
                    onClick={sendToAssistant}
                    className={classes.sendButton}
                    aria-label="send"
                    size="small"
                    color="secondary"
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {displayMenu && <AIMenu buttonClass={classes.moreButton} />}
        </Fragment>
      </span>
    </Stack>
  )
})
export default AIContainer
