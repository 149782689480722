// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useMemo } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import {
  isLeasePassed,
  isretiredAvailableForUser,
  isSetCalibRefAvailableForUser,
  openInNewTab,
} from '../../utils/utilities'
import {
  callBuildATrainingBatch,
  exploreBatch,
  handleDownloadReport,
  handleGenerateMethod,
  handleRetiredBatch,
  handleSetBatchAsCalibRef,
  handleStampBatch,
  handleUpdateBatch,
  openBatch,
  openBatchInNewTab,
  saveBatch,
} from '../backlog/Tools'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import Typography from '@material-ui/core/Typography'
import { t } from 'i18next'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Menu from '@material-ui/core/Menu'
import Divider from '@material-ui/core/Divider'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import SchoolIcon from '@material-ui/icons/School'
import { DATABASE, FLASK_URL } from '../../utils/config'
import LoopIcon from '@material-ui/icons/Loop'
import ReplayIcon from '@material-ui/icons/Replay'
import Link from '@material-ui/core/Link/Link'
import { displayPopupMessage } from '../common/Message'
import { XLSFileInvert } from '../common/Style'
import ApprovalIcon from '@mui/icons-material/Approval'
import SearchIcon from '@material-ui/icons/Search'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TerminalIcon from '@mui/icons-material/Terminal'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'

const useStyles = makeStyles((theme) => ({
  loadingBatchIcon: {
    animation: 'MuiCircularProgress-keyframes-circular-rotate 0.5s linear infinite',
    animationDirection: 'reverse',
    fontSize: '2rem',
  },
  divider: {
    margin: '5px 0px',
  },
}))

/**
 * ExtraMenu component that renders a menu with various batch-related actions.
 * It uses `withRouter` to access the router's history object.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.anchorEl - The element that the menu is anchored to.
 * @param {boolean} props.open - Boolean indicating if the menu is open.
 * @param {function} props.onClose - Function to call when the menu is closed.
 * @param {boolean} props.hideOpenBatch - Boolean indicating if the open batch option should be hidden.
 * @returns {JSX.Element} The rendered ExtraMenu component.
 */
const ExtraMenu = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { anchorEl, open, onClose, hideOpenBatch } = props

  const delta = useSelector((state) => state.settings.dateTimeDelta)

  /**
   * Memoized calculation of anchor and row based on the anchorEl prop.
   * If anchorEl is present, it updates the row with lease information.
   * Otherwise, it returns null values.
   *
   * @returns {Object} An object containing anchor and row.
   */
  const { anchor, row } = useMemo(() => {
    if (anchorEl) {
      let lease
      const leaseValue = anchorEl?.row?.content?.lease
      if (leaseValue && typeof leaseValue === 'number') {
        lease = { date: leaseValue, operator: null, reason: null }
      } else if (leaseValue && typeof leaseValue === 'object') {
        lease = leaseValue
      } else {
        lease = { date: 0, operator: null, reason: null }
      }
      const updatedRow = {
        ...anchorEl.row,
        lease: lease,
      }
      return { anchor: anchorEl.anchor, id: anchorEl.id, row: updatedRow }
    } else {
      return { anchor: null, id: null, row: null }
    }
  }, [anchorEl])

  const [extractMenuAnchor, setExtractMenuAnchor] = React.useState(null)
  const extraMenuOpen = Boolean(extractMenuAnchor)
  /**
   * Opens the extract menu
   * @param event
   */
  const openExtractMenu = (event) => {
    setExtractMenuAnchor(event.currentTarget)
  }
  /**
   * Closes the extract menu
   */
  const handleCloseExtraMenu = () => {
    onClose()
    setExtractMenuAnchor(null)
  }

  return (
    <Fragment>
      <Menu
        anchorEl={extractMenuAnchor}
        open={extraMenuOpen}
        onClose={handleCloseExtraMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={(event) => {
            handleCloseExtraMenu()
            handleDownloadReport(event, row, false, dispatch)
          }}
        >
          {t('view.batch.information.normal')}
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            handleCloseExtraMenu()
            handleDownloadReport(event, row, true, dispatch)
          }}
        >
          {t('view.batch.information.detail')}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchor}
        getcontentanchorel={null}
        // keepMounted
        open={open}
        onClose={onClose}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'center',
        // }}
      >
        {!hideOpenBatch && (
          <MenuItem
            disabled={
              (anchorEl &&
                !isLeasePassed(row.lease.date, delta) &&
                row.lease.reason === 'treatment') ||
              (anchorEl ? row.lockUpdate : true)
            }
            onClick={function () {
              openBatch(row, props.history)
              onClose()
            }}
          >
            <ListItemIcon>
              <OpenInBrowserIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('view.panel.batchesCmp.actions.open')}</Typography>
          </MenuItem>
        )}
        <MenuItem
          disabled={
            (anchorEl &&
              !isLeasePassed(row.lease.date, delta) &&
              row.lease.reason === 'treatment') ||
            (anchorEl ? row.lockUpdate : true)
          }
          onClick={() => {
            openBatchInNewTab(row)
            onClose()
          }}
        >
          <ListItemIcon>
            <OpenInNewIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.panel.batchesCmp.actions.openNew')}</Typography>
        </MenuItem>
        <Divider className={classes.divider} sx={{ my: 0.5 }} />
        <MenuItem
          disabled={
            (anchorEl &&
              !isLeasePassed(row.lease.date, delta) &&
              row.lease.reason === 'treatment') ||
            (anchorEl ? row.lockUpdate : true)
          }
          onClick={(event) => {
            // me.setState({ otherMenuEl: null })
            callBuildATrainingBatch(event, row, dispatch)
            onClose()
          }}
        >
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.backlog.menu.training')}</Typography>
        </MenuItem>
        {/*{AZURE_ENV ? null : (*/}
        <MenuItem
          disabled={
            (anchorEl &&
              !isLeasePassed(row.lease.date, delta) &&
              row.lease.reason === 'treatment') ||
            (anchorEl ? row.lockUpdate : true)
          }
          onClick={(event) => {
            // me.setState({ otherMenuEl: null })
            handleUpdateBatch(event, row, dispatch)
            onClose()
          }}
        >
          <ListItemIcon>
            {anchorEl &&
            !isLeasePassed(row.lease.date, delta) &&
            row.lease.reason === 'treatment' ? (
              <LoopIcon className={classes.loadingBatchIcon} />
            ) : (
              <ReplayIcon />
            )}
          </ListItemIcon>
          <Typography variant="inherit">{t('view.backlog.menu.replace')}</Typography>
        </MenuItem>
        {/*)}*/}

        <MenuItem
          disabled={
            (anchorEl &&
              !isLeasePassed(row.lease.date, delta) &&
              row.lease.reason === 'treatment') ||
            (anchorEl ? row.lockUpdate : true)
          }
          onClick={(event) => {
            openExtractMenu(event)
          }}
        >
          <ListItemIcon>
            <XLSFileInvert style={{ width: 26, height: 21 }} className={classes.colorBlue} />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.panel.batchesCmp.actions.download')}</Typography>
        </MenuItem>
        <Divider className={classes.divider} sx={{ my: 0.5 }} />
        <MenuItem
          disabled={false}
          onClick={(event) => {
            handleStampBatch(event, row, dispatch)
            onClose()
          }}
        >
          <ListItemIcon>
            <ApprovalIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.backlog.menu.stamp')}</Typography>
        </MenuItem>
        {anchorEl && isretiredAvailableForUser(row) && (
          <MenuItem
            disabled={anchorEl ? row.retired : false}
            onClick={(event) => {
              handleRetiredBatch(event, row, dispatch)
              onClose()
            }}
          >
            <ListItemIcon>
              <FolderDeleteOutlinedIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('view.backlog.menu.retired')}</Typography>
          </MenuItem>
        )}
        {isSetCalibRefAvailableForUser(row) && (
          <MenuItem
            disabled={anchorEl ? row.retired : false}
            onClick={(event) => {
              handleSetBatchAsCalibRef(event, row, dispatch)
              onClose()
            }}
          >
            <ListItemIcon>
              <BookmarkBorderIcon />
            </ListItemIcon>
            <Typography variant="inherit">
              {row.calibRef
                ? t('view.backlog.menu.removeCalibRef')
                : t('view.backlog.menu.setCalibRef')}
            </Typography>
          </MenuItem>
        )}
        <Divider className={classes.divider} sx={{ my: 0.5 }} />
        <MenuItem
          onClick={function (event) {
            // Generate method
            handleGenerateMethod(event, row, dispatch)
            onClose()
          }}
        >
          <ListItemIcon>
            <PostAddOutlinedIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.backlog.menu.generateMethod')}</Typography>
        </MenuItem>
        <Divider className={classes.divider} sx={{ my: 0.5 }} />
        <MenuItem
          onClick={function () {
            // handleOpenLogs(dispatch)
            openInNewTab(
              `${window.location.origin}${window.location.pathname}?${DATABASE()}/logs/batch/${
                row._id
              }`,
            )
            onClose()
          }}
        >
          <ListItemIcon>
            <TerminalIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.panel.batchesCmp.actions.logs')}</Typography>
        </MenuItem>
        <MenuItem
          onClick={function () {
            exploreBatch(row, dispatch)
            onClose()
          }}
        >
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.panel.batchesCmp.actions.explore')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => saveBatch(row)}>
          <ListItemIcon>
            <SaveAltIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.panel.batchesCmp.actions.save')}</Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  )
})
export default ExtraMenu
