// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

const initialState = {
  uaReceived: [],
  lastModificationDate: 0,
  numberDownloaded: 0,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_LAST_MODIFICATION_DATE':
      return {
        ...state,
        lastModificationDate: action.lastModificationDate,
        numberDownloaded: action.numberDownloaded,
      }
    default:
      return state
  }
}
