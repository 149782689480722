// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch, withRouter } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Trans, useTranslation } from 'react-i18next'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import RetiredCmp from '../common/RetiredCmp'
import SplitterLayout from 'react-splitter-layout'
import { DATABASE, DRAWER_WIDTH } from '../../utils/config'
import { getBatchById } from '../../redux/batches/selectors'
import { fetchBatch } from '../../redux/batches/actions'
import Navigation from '../Navigation'
import { fetchAnalyses } from '../../redux/analyses/actions'
import Information from './Information'
import Paper from '@material-ui/core/Paper'
import Loading from '../Loading'
import CalibTable from '../batch/CalibTable'
import Calibration from '../common/Calibration'
import QualityControlTable from '../batch/QualityControlTable'
import SamplesTable from '../batch/SamplesTable'
import OthersTable from '../batch/OthersTable'
import Table from './Table'
import Details from './Details'
import { fetchConfigurationFeatures } from '../../redux/configuration/actions'
import EmptyGlobal from './EmptyGlobal'
import { fetchDisplayChromatoDetail } from '../../redux/detail/actions'
import { displayPopupMessage } from '../common/Message'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    paddingTop: 8,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    // height: '500px',
    height: '100%',
    justifyContent: 'flex-start',
    flex: '1 1 auto',
    position: 'relative',
    // overflowY: 'auto',
  },
  mainSplitter: {
    height: 'inherit',
    paddingLeft: 60,
    '& > .layout-splitter': {
      display: 'none',
    },
  },
  mainSplitterFull: {
    height: 'calc(100% - 28px)',
    // paddingLeft: 241,
    '& > .layout-splitter': {
      display: 'none',
    },
  },
  centerSplitter: {
    padding: '0px 5px',
    '& > .layout-pane': {
      padding: '0px 5px 12px 2px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 12px 2px',
      height: 'auto !important',
    },
    '& > .layout-splitter': {
      height: '0 !important',
      opacity: 0,
    },
  },
  containerSplitter: {
    '& > .layout-pane': {
      paddingTop: 4,
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 6px 2px',
    },
  },
  deeperSplitter: {
    '& > .layout-pane': {
      padding: '1px 10px 12px 5px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '1px 5px 12px 2px',
    },
  },
  deeperSplitterCalib: {
    '& > .layout-pane': {
      padding: '1px 22px 22px 15px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '1px 22px 22px 2px',
    },
  },
  firstSplitter: {
    height: 'calc(100% - 64px)',
    '& > .layout-pane': {
      padding: '1px 8px 14px 0px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '13px 0px 2px 2px',
      minWidth: DRAWER_WIDTH(),
    },
  },
}))
const Global = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const contentFirstCntRef = useRef()
  const contentCalibrationRef = useRef()

  // const [openFormulasDialog, setOpenFormulasDialog] = React.useState()
  //
  // const analyse = useSelector(function (state) {
  //   const analysisId = items && items[0] ? items[0].analyseId : null
  //   return getAnalysisById(state.analyses, analysisId)
  // })

  const [panelSize, setPanelSize] = React.useState(0)

  const pinDrawer = useSelector((state) => state.drawer.pin)
  const batch = useSelector((state) => getBatchById(state.batches, props.match.params.batchId))
  const isDatabaseOffline = useSelector((state) => state.settings.configurationDatabase.length > 0)

  // Load the batch
  useEffect(function () {
    if (!isDatabaseOffline) {
      // Open the chromato detail if an ID is set in the URL
      const chromatoId = match.params.chromatoId
      if (chromatoId) {
        const regex = /ua=/i
        dispatch(fetchDisplayChromatoDetail(chromatoId.replace(regex, '')))
      }

      dispatch(fetchBatch(props.match.params.batchId)).then(function (resp) {
        if (resp && resp.type === 'ITEM_NOT_FOUND') {
          displayPopupMessage(
            dispatch,
            'warning',
            t('view.batch.batch.not_found.title'),
            <Trans i18nKey="view.batch.batch.not_found.msg">
              The batch with id {{ val: props.match.params.batchId }} can not be found.
            </Trans>,
          )
          props.history.push('/?' + DATABASE())
        }
      })
      dispatch(fetchAnalyses(props.match.params.batchId))
    }
  }, [])

  // // Open the chromato detail if an ID is set in the URL
  // useEffect(() => {
  //
  //   //6488373d2dd6f21b7928eebf
  //   // fetchUpdateDetailInfo(item, activeTab, hideGeneral, anchorEl, collection)
  // }, [])

  // Load the configuration features from the batch
  useEffect(
    function () {
      if (!isDatabaseOffline && batch && batch.content && batch.content.configuration) {
        dispatch(fetchConfigurationFeatures(batch.content.configuration)).then(function (resp) {
          if (resp.configFeatures) {
            // Store the batch configuration id
            localStorage.setItem('SETTINGS_configuration_profile', resp.configFeatures._id)
          }
        })
      }
    },
    [batch],
  )

  const onContentFirstSplitterSizeSave = function () {
    localStorage.setItem(
      'LAYOUT_first_secondaryPaneSize',
      contentFirstCntRef.current.state.secondaryPaneSize,
    )
  }
  const onContentCalibrationSplitterSizeSave = function () {
    localStorage.setItem(
      'LAYOUT_global_secondaryPaneSize',
      contentCalibrationRef.current.state.secondaryPaneSize,
    )
  }

  const innerCmpNavigation = (
    <ErrorBoundaryGuard>
      <Navigation batch={batch} />
    </ErrorBoundaryGuard>
  )
  const innerCmp = (
    <ErrorBoundaryGuard>
      <SplitterLayout
        primaryIndex={1}
        secondaryInitialSize={0}
        customClassName={pinDrawer ? classes.mainSplitterFull : classes.mainSplitter}
      >
        <Fragment></Fragment>
        <SplitterLayout
          vertical
          primaryIndex={1}
          secondaryInitialSize={120}
          customClassName={classes.centerSplitter}
        >
          <ErrorBoundaryGuard>
            <Information batch={batch} />
          </ErrorBoundaryGuard>
          <Paper elevation={0} className={classes.paper}>
            <SplitterLayout
              customClassName={classes.deeperSplitterCalib}
              ref={contentCalibrationRef}
              onDragEnd={onContentCalibrationSplitterSizeSave}
              secondaryInitialSize={
                localStorage.getItem('LAYOUT_global_secondaryPaneSize')
                  ? parseFloat(localStorage.getItem('LAYOUT_global_secondaryPaneSize'))
                  : 450
              }
              onSecondaryPaneSizeChange={function (size) {
                const newSize = size < 320 ? 12 : size < 520 ? 6 : size < 690 ? 4 : 3
                if (newSize !== panelSize) {
                  setPanelSize(newSize)
                }
              }}
            >
              <ErrorBoundaryGuard>
                <Table />
              </ErrorBoundaryGuard>
              <ErrorBoundaryGuard>
                <Details panelWidth={panelSize} />
              </ErrorBoundaryGuard>
            </SplitterLayout>
          </Paper>
        </SplitterLayout>
      </SplitterLayout>
    </ErrorBoundaryGuard>
  )

  if (isDatabaseOffline) {
    return <EmptyGlobal />
  }

  return !batch ? (
    <Loading mode="alpha" size={10} />
  ) : (
    <Fragment>
      <ErrorBoundaryGuard isDialog>
        <RetiredCmp batch={batch} />
      </ErrorBoundaryGuard>
      {pinDrawer ? (
        <SplitterLayout
          customClassName={classes.firstSplitter}
          ref={contentFirstCntRef}
          onDragEnd={onContentFirstSplitterSizeSave}
          secondaryMinSize={DRAWER_WIDTH()}
          primaryIndex={1}
          primaryMinSize={600}
          secondaryInitialSize={
            localStorage.getItem('LAYOUT_first_secondaryPaneSize')
              ? parseFloat(localStorage.getItem('LAYOUT_first_secondaryPaneSize'))
              : DRAWER_WIDTH()
          }
        >
          {innerCmpNavigation}
          {innerCmp}
        </SplitterLayout>
      ) : (
        <Fragment>
          {innerCmpNavigation} {innerCmp}
        </Fragment>
      )}
    </Fragment>
  )
})
export default Global
