// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import './index.css'
import React, { Fragment, useEffect, useState } from 'react'
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import Container from './view/Container'
import store from './redux/configureStore'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css'
import { API_URL, APPLICATION_CONFIG_JSON, DATABASE, LANGUAGES } from './utils/config'
import { displayPopupMessage, generateErrorPart } from './view/common/Message'
import { fetchConfigurations } from './redux/configuration/actions'
import { fetchChangeSettings } from './redux/settings/actions'
import { StyledEngineProvider } from '@mui/material/styles'
import ErrorBoundaryGuard from './view/ErrorBoundaryGuard'
import { initReactI18next, Trans } from 'react-i18next'
import { getWikiHelper, getParentDomain, getWikiDocLanguage } from './utils/utilities'
import i18n, { t } from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { fetchGlobalConfigurations } from './redux/global/actions'
import { testDatabaseAvailability } from './utils/database'
import background from './resources/images/background_light.webp'
import Loading from './view/Loading'
import { fetchAddMessage } from './redux/message/actions'
import LoginDialog from './view/dialog/LoginDialog'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(
  '74973b754975b522e2026e308f33ac1aTz04MzAxMCxFPTE3Mzc4OTgxMjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
)

// const store = configureStore()
const baseName = window.location.pathname.split('/')[1]

// Load the config file properties (public/config.json) in the localStorage
fetch(APPLICATION_CONFIG_JSON)
  .then((res) => res.text())
  .then((data) => {
    const configJson = JSON.parse(data)
    Object.keys(configJson)
      .filter((key) => key !== '____WARNING____')
      .forEach((setting) => localStorage.setItem('__config.json__' + setting, configJson[setting]))
  })
  .catch((error) =>
    displayPopupMessage(
      store.dispatch,
      'error',
      <Trans i18nKey="app.error_msg.title">Configuration file</Trans>,
      <Fragment>
        <div>
          <Trans i18nKey="app.error_msg.msg">
            There is something wrong in the <b>config.json</b> file
          </Trans>
          :
        </div>
        <div>{error.toString()}</div>
      </Fragment>,
      8000,
    ),
  )
  .finally(() => store.dispatch(fetchChangeSettings('configJsonLoaded', true)))

// Test if user is signed in to test service and DB
const cookies = Object.fromEntries(
  document.cookie.split('; ').map((v) => v.split(/=(.*)/s).map(decodeURIComponent)),
)

i18n
  .use(Backend)
  .use(LanguageDetector)
  // .use(lngDetector)
  .use(initReactI18next)
  .init(
    {
      // resources,
      backend: {
        loadPath: `${window.location.pathname}locales/{{lng}}/{{ns}}.json`,
      },
      fallbackLng: 'en',
      nonExplicitSupportedLngs: true,
      detection: {
        checkWhitelist: true,
        // order: ['customDetector', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      },

      debug: false,
      load: 'languageOnly',
      // supportedLngs: availableLanguages,
      supportedLngs: Object.keys(LANGUAGES),

      interpolation: {
        escapeValue: false,
      },
    },
    (err, t) => {
      if (err) {
        displayPopupMessage(
          store.dispatch,
          'error',
          'Loading interface language',
          <Fragment>
            <div>The interface language management mechanism does not currently work.</div>
            <div>{generateErrorPart(err)}</div>
          </Fragment>,
        )
      } else {
        // The language is loaded so we can load the wikiCOD
        getWikiHelper(store.dispatch)
        getWikiDocLanguage(store.dispatch)
      }
    },
  )

/**
 * The main application container component.
 * Handles authentication, database availability check, and application readiness state.
 *
 * @param {Object} history - The history object for navigation.
 * @returns {JSX.Element} - The AppContainer component.
 */
const AppContainer = ({ history }) => {
  const [isReady, setIsReady] = useState(false) // Flag to track if the app is ready
  const dispatch = useDispatch()

  const isWelcomeMessagePresent = useSelector(
    (state) => state.message.messages.filter((msg) => msg.connexionMessage).length > 0,
  )

  useEffect(() => {
    /**
     * Function to check database availability and user authentication.
     * Fetches the current user session and verifies access rights.
     */
    const checkDatabaseAndAuth = async () => {
      try {
        // Parse cookies into an object
        const cookies = Object.fromEntries(document.cookie.split('; ').map((c) => c.split('=')))
        let isUserConnected = false

        // Check if session ID cookie is available
        if (cookies.ToriiSessionId && cookies.ToriiSessionId !== '') {
          const response = await fetch(`${API_URL()}/users/whoami`, {
            method: 'GET',
            credentials: 'include',
          })

          if (response.status === 200) {
            isUserConnected = true // User is authenticated
          } else if (response.status === 403) {
            // User authentication failed, reset configuration and show login popup
            await dispatch(fetchChangeSettings('configurationDatabase', []))
            // Do not display the popup login if we are on the login page
            if (history.location.pathname !== '/?login' && history.location.search !== '?login') {
              await dispatch(fetchChangeSettings('loginPopup', true))
            }
          }
        } else {
          // No session found, reset configuration and show login popup
          await dispatch(fetchChangeSettings('configurationDatabase', []))
          // Do not display the popup login if we are on the login page
          if (history.location.pathname !== '/?login' && history.location.search !== '?login') {
            await dispatch(fetchChangeSettings('loginPopup', true))
          }
        }

        // If the user is authenticated, load database and configurations
        if (isUserConnected) {
          if (!isWelcomeMessagePresent) {
            await dispatch(
              fetchAddMessage({
                title: localStorage.getItem('SESSION_user_name'),
                message: t('view.panel.toolbar.message.connexion'),
                // message: 'Bonjour',
                type: 'success',
                date: new Date().getTime(),
                isNew: false,
                connexionMessage: true,
              }),
            )
          }
          // Redirect to the stored URL before Azure login
          const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin')
          if (redirectAfterLogin && redirectAfterLogin !== '') {
            // Set the good URL for the next steps
            history.push('/' + redirectAfterLogin)
            sessionStorage.removeItem('redirectAfterLogin')
          }

          const dbLoaded = await testDatabaseAvailability(dispatch)
          const dbCorrectlyLoaded = !(typeof dbLoaded === 'boolean' && dbLoaded === false)

          if (dbLoaded.redirectTo) {
            // Redirect if needed based on database availability check
            history.push('?' + dbLoaded.redirectTo)
          }
          // Fetch global and database configurations
          // Only if we have a valid database
          if (dbCorrectlyLoaded) {
            // Store the database (prefix) in cookies
            document.cookie =
              'COD_db_prefix=' +
              DATABASE() +
              '; path=/;SameSite=None;Secure;Domain=' +
              getParentDomain(window.location.hostname)
            // Fetch global and database configurations and service availability
            await dispatch(fetchGlobalConfigurations())
            await dispatch(fetchConfigurations(DATABASE()))
          }
        } else {
          // Store the URL to redirect to it after the login
          const storedValue = window.location.search === '?login' ? '' : window.location.search
          sessionStorage.setItem('redirectAfterLogin', storedValue)
        }
      } catch (error) {
        displayPopupMessage(
          store.dispatch,
          'error',
          'Error loading the application',
          <div>{generateErrorPart(error)}</div>,
        )
      } finally {
        setIsReady(true) // Mark application as ready
      }
    }

    checkDatabaseAndAuth()
  }, [history, dispatch])

  return (
    <StyledEngineProvider injectFirst>
      {/* Notifications system for global messages */}
      <ReactNotifications className={'notification_cmp'} />
      {/* Error boundary to catch runtime errors */}
      <ErrorBoundaryGuard
        style={{
          height: 'calc(100% - 20px)',
          width: 'calc(100% - 20px)',
          position: 'absolute',
        }}
      >
        {/* Render the main container if the app is ready, otherwise show a loading screen */}
        {isReady ? (
          <Fragment>
            <Container />
            <ErrorBoundaryGuard isDialog>
              <LoginDialog />
            </ErrorBoundaryGuard>
          </Fragment>
        ) : (
          <Fragment>
            {/* Background image during loading */}
            <img
              id="APPLICATION_BACKGROUND_ID"
              src={background}
              alt="Background"
              className="background-container"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                position: 'absolute',
                opacity: 0.2,
              }}
            />
            {/* Loading spinner */}
            <Loading mode="alpha" size={10} />
          </Fragment>
        )}
      </ErrorBoundaryGuard>
    </StyledEngineProvider>
  )
}
// Wrap AppContainer with router to provide navigation functionality
const AppWithRouter = withRouter(AppContainer)

/**
 * The root application component.
 * Wraps the app with Redux Provider and React Router.
 *
 * @returns {JSX.Element} - The main application component.
 */
const App = () => (
  <Provider store={store}>
    <Router basename={`/${baseName}`}>
      <AppWithRouter />
    </Router>
  </Provider>
)
// Export the app, enabling Hot Module Replacement (HMR) in development mode
export default process.env.NODE_ENV === 'development' ? hot(App) : App
