// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React from 'react'
import { TransitionBottom } from '../common/Style'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LoginView from '../common/LoginView'
import { Dialog, DialogContent } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'

/**
 * Styles for the LoginDialog component
 */
const useStyles = makeStyles((theme) => ({
  customRootDialog: {
    // height: isMobile ? '100%' : '70%',
    width: 396,
    backgroundColor: 'transparent',
  },
}))
/**
 * LoginDialog Component
 * Displays a login modal dialog when the `loginPopup` state is true in Redux.
 * @type {React.ComponentClass<Omit<RouteComponentProps<any>, keyof RouteComponentProps<any>> & WithRouterProps<React.ComponentType<RouteComponentProps<any>>>> & WithRouterStatics<React.ComponentType<RouteComponentProps<any>>>}
 */
const LoginDialog = withRouter((props) => {
  const classes = useStyles()
  const { history } = props

  /**
   * Selects the state to determine if the login dialog should be open.
   */
  const openDialog = useSelector(function (state) {
    return state.settings.loginPopup
  })

  /**
   * Function to reload the current page.
   * Used to refresh the application state after a successful login.
   */
  function reload() {
    // const current = '/' + props.location.search
    // history.replace(`/reload`)
    // setTimeout(() => {
    //   history.push(current)
    // })

    window.location.reload()
  }

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={TransitionBottom}
      aria-labelledby="dialog-title-user"
      classes={{
        paper: classes.customRootDialog,
      }}
    >
      <DialogContent style={{ padding: 0 }}>
        <ErrorBoundaryGuard>
          <LoginView onValidSuccess={reload} fromDialog={true} />
        </ErrorBoundaryGuard>
      </DialogContent>
    </Dialog>
  )
})
export default LoginDialog
