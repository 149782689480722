// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { find } from 'lodash'
import { API_BO_URL } from '../../utils/config'

export const getUnitaryCalibrationsFilteredById = (store, id) =>
  find(store.unitaryCalibrationsFiltered, { _id: id })

export async function getUnitaryCalibrationsRawById(id) {
  try {
    const response = await fetch(`${API_BO_URL()}.unitary_calibration/${id}`, {
      method: 'GET',
      credentials: 'include',
    })
    const json = await response.json()
    const error = response.status !== 200
    if (error) {
      console.log(response.statusText)
      return null
    } else {
      return json
    }
  } catch (e) {
    console.log(e)
    return null
  }
}
