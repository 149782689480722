// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { forwardRef, Fragment } from 'react'
import { makeStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import TextField from '@material-ui/core/TextField'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import { alpha } from '@material-ui/core/styles'
import { ToggleButton } from '@mui/material'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import clsx from 'clsx'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  sortableTableHeader: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: 3,
    margin: '0px 10px',
    padding: '5px 10px',
    background: alpha(theme.palette.primary.main, 0.02),
    minWidth: 100,
    cursor: 'grab',
    verticalAlign: 'top',
  },
  sortableTableHeadertitle: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    borderBottom: '1px solid',
    borderBottomColor: alpha(theme.palette.primary.main, 0.35),
  },
  sorting: {
    zIndex: theme.zIndex.modal + 100,
  },
  columnSizetextfield: {
    width: '100%',
    marginTop: 10,
    '& input': {
      padding: 8,
      background: 'white',
    },
  },
  selectFormat: {
    '& > div': {
      padding: '8px 32px 8px 10px',
      background: 'white',
    },
  },
  columnDecimals: {
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 8,
    },
  },
}))
export default function TableTab({
  propsColumns,
  settingsVisible,
  settingsSize,
  settingsOrder,
  settingsDecimals,
  settingsFormat,
  setSettingsVisible,
  setSettingsSize,
  setSettingsOrder,
  setSettingsDecimals,
  setSettingsFormat,
}) {
  const classes = useStyles()

  const onVisibleEnd = (event, label) => {
    setSettingsVisible({ ...settingsVisible, [label]: event.target.checked })
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSettingsOrder(arrayMove(settingsOrder, oldIndex, newIndex))
  }
  const [open, setOpen] = React.useState(
    settingsOrder.reduce((json, value) => {
      json[value] = false
      return json
    }, {}),
  )

  const handleClose = (key) => {
    setOpen((oldOpen) => ({ ...oldOpen, [key]: false }))
  }

  const handleOpen = (key) => {
    setOpen((oldOpen) => ({ ...oldOpen, [key]: true }))
  }

  const SortableItem = SortableElement(
    forwardRef(
      ({ columnKey, label, visible, size, decimals, format, type }, ref) => (
        <td ref={ref} tabIndex={0} className={classes.sortableTableHeader}>
          <div className={classes.sortableTableHeadertitle}>{label}</div>
          <Checkbox
            style={{ marginLeft: 'calc(50% - 12px)', padding: 0, marginTop: 6, marginBottom: 2 }}
            icon={<VisibilityOff />}
            checkedIcon={<Visibility />}
            name={label}
            checked={visible}
            onChange={(event) => onVisibleEnd(event, columnKey)}
          />
          <TextField
            className={classes.columnSizetextfield}
            label={t('view.common.settingsView.tableTab.size')}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            defaultValue={size}
            onChange={(event) => onSizeEnd(event, columnKey)}
            onBlur={(e) => onSizeEnd(e, columnKey, true)}
          />
          {type === 'number' && (
            <Fragment>
              <FormControl fullWidth size="small" style={{ marginTop: 13 }} variant="outlined">
                <InputLabel>Format</InputLabel>
                <Select
                  variant="outlined"
                  className={classes.selectFormat}
                  value={format}
                  label={t('view.common.settingsView.tableTab.format')}
                  open={open[columnKey]}
                  onClose={() => handleClose(columnKey)}
                  onOpen={() => handleOpen(columnKey)}
                  onChange={(event) => onFormatEnd(event, columnKey)}
                >
                  <MenuItem key={'auto'} value={'auto'}>
                    {t('view.common.settingsView.tableTab.auto')}
                  </MenuItem>
                  <MenuItem key={'std'} value={'std'}>
                    {t('view.common.settingsView.tableTab.std')}
                  </MenuItem>
                  <MenuItem key={'sci'} value={'sci'}>
                    {t('view.common.settingsView.tableTab.sci')}
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                className={clsx(classes.columnSizetextfield, classes.columnDecimals)}
                style={{ marginTop: 14, marginBottom: 8, background: 'white' }}
                label={t('view.common.settingsView.tableTab.decimals')}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment: (
                    <InputAdornment position="start" style={{ width: 10 }}>
                      <Checkbox
                        checked={decimals !== -1 && format !== 'auto'}
                        disabled={format === 'auto'}
                        style={{ padding: 0 }}
                        size="small"
                        onChange={(event) => onDecimalsEnd(event, columnKey, true, true)}
                      />
                    </InputAdornment>
                  ),
                }}
                disabled={decimals === -1 || format === 'auto'}
                variant="outlined"
                defaultValue={decimals === -1 || format === 'auto' ? null : decimals}
                onChange={(event) => onDecimalsEnd(event, columnKey)}
                onBlur={(e) => onDecimalsEnd(e, columnKey, true)}
              />
            </Fragment>
          )}
        </td>
      ),
      {
        withRef: true,
      },
    ),
  )

  const SortableList = SortableContainer(({ orders, visibles, sizes, decimals, format }) => {
    return (
      <table style={{ borderSpacing: 15 }}>
        <tbody>
          <tr>
            {orders.map((value, index) => (
              <SortableItem
                key={`item-${value}`}
                columnKey={value}
                index={index}
                label={
                  propsColumns.filter((col) => col.key === value)[0]
                    ? propsColumns.filter((col) => col.key === value)[0].label
                    : value
                }
                type={
                  propsColumns.filter((col) => col.key === value)[0]
                    ? propsColumns.filter((col) => col.key === value)[0].type
                    : 'unknown'
                }
                visible={visibles[value]}
                size={sizes[value]}
                decimals={decimals[value]}
                format={format[value]}
              />
            ))}
          </tr>
        </tbody>
      </table>
    )
  })

  let settingsTimer = null
  const onSizeEnd = (event, label, forceSave) => {
    const value = parseFloat(event.currentTarget.value)
    clearTimeout(settingsTimer)
    if (forceSave) {
      setSettingsSize({ ...settingsSize, [label]: value })
    } else {
      settingsTimer = setTimeout(function () {
        setSettingsSize({ ...settingsSize, [label]: value })
      }, 1500)
    }
  }
  const onDecimalsEnd = (event, label, forceSave, checkbox) => {
    const value = checkbox ? (event.target.checked ? 0 : -1) : parseFloat(event.currentTarget.value)
    clearTimeout(settingsTimer)
    if (forceSave) {
      setSettingsDecimals({ ...settingsDecimals, [label]: value })
    } else {
      settingsTimer = setTimeout(function () {
        setSettingsDecimals({ ...settingsDecimals, [label]: value })
      }, 1500)
    }
  }
  const onFormatEnd = (event, label) => {
    const value = event.target.value
    setSettingsFormat({ ...settingsFormat, [label]: value })
  }

  return (
    <span>
      <div style={{ padding: 0, margin: '0px 14px' }}>
        <div style={{ margin: '20px 0px 20px 10px', fontSize: '1.1rem' }}>
          {t('view.common.settingsView.tableTab.msg')}
        </div>
      </div>
      <div style={{ padding: 0, margin: '0px 14px', overflow: 'auto' }}>
        {/*<div style={{ margin: '0px 0px 20px 10px' }}>*/}
        {/*  You can reorder, show and hide all the table column bellow:*/}
        {/*</div>*/}
        <SortableList
          lockToContainerEdges={true}
          lockAxis={'x'}
          axis={'x'}
          orders={settingsOrder}
          visibles={settingsVisible}
          sizes={settingsSize}
          onSortEnd={onSortEnd}
          helperClass={classes.sorting}
          decimals={settingsDecimals}
          format={settingsFormat}
        />
        {/*<SortableComponent />*/}
      </div>
    </span>
  )
}
