// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { useRouteMatch, withRouter } from 'react-router-dom'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'
import clsx from 'clsx'
import DetailContent from './DetailContent'
import { SyncSwitch } from './SyncSwitch'
import { API_BO_URL } from '../../../utils/config'
import { displayPopupMessage, generateErrorPart } from '../Message'
import { fetchBatch } from '../../../redux/batches/actions'
import { rebuildUnitaryAnalyses } from '../../../utils/rebuilders'
import { fetchConfigurationFeatures } from '../../../redux/configuration/actions'
import { fetchUpdateDetailInfo } from '../../../redux/detail/actions'
import { fetchChangeSettings } from '../../../redux/settings/actions'
import { Trans } from 'react-i18next'

/**
 * Custom styles for the component
 */
const useStyles = makeStyles((theme) => ({
  customModal: {
    position: 'fixed',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
}))

/**
 * Component for handling the detail window with routing support
 */
const DetailWindowWithRouter = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const modalRef = useRef(null)
  // const match = useRouteMatch()

  // const unitaryAnalysisId = match.params.unitaryAnalysisId

  // const {
  //   internalMode: showDetail,
  //   detail: { item, activeTab, hideGeneral, anchorEl, collection },
  // } = useSelector((state) => state.detail)

  // useEffect(() => {
  //   console.log(item)
  // }, [item, activeTab, hideGeneral, anchorEl, collection, showDetail])

  // useEffect(() => {
  //   // dispatch({ type: 'DETAIL_WINDOW_OPENED' })
  //
  //   // Method called at the closing window
  //   const handleUnload = () => {
  //     // dispatch({ type: 'DETAIL_WINDOW_CLOSED' })
  //   }
  //   // Add event listener for window closing
  //   window.addEventListener('beforeunload', handleUnload)
  //   // Clean up events when component closes
  //   return () => {
  //     window.removeEventListener('beforeunload', handleUnload)
  //   }
  // }, [dispatch])

  /**
   * Function to download the unitary analysis details
   * @param {string} unitaryAnalysisId - The ID of the unitary analysis to fetch
   */
  const downloadUADetail = (unitaryAnalysisId) => {
    fetch(`${API_BO_URL()}.unitary_analysis/${unitaryAnalysisId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        const rebuildJson = rebuildUnitaryAnalyses(json)
        dispatch(fetchBatch(rebuildJson.batchId)).then(function (resp) {
          if (resp && resp.type === 'ITEM_NOT_FOUND') {
            displayPopupMessage(
              dispatch,
              'warning',
              'Batch not found',
              <Trans i18nKey="view.analysis.analysis.not_found_id">
                The batch with id {{ val: rebuildJson.batchId }} can not be found.
              </Trans>,
            )
          }
          // Load the configuration features from the batch
          if (resp && resp.batch && resp.batch.content && resp.batch.content.configuration) {
            dispatch(fetchConfigurationFeatures(resp.batch.content.configuration)).then(function (
              resp,
            ) {
              // Store the batch configuration id
              resp?.configFeatures?._id &&
                localStorage.setItem('SETTINGS_configuration_profile', resp.configFeatures._id)
            })
          }
        })
        dispatch(fetchUpdateDetailInfo(rebuildJson, 0, false, null, 'analyse'))
      })
      .catch((error) => {
        if (error.status === 403) {
          return dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get Unitary Analysis',
          <Fragment>
            <div>An error does not allow to get the UA:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }

  const {
    internalMode: showDetail,
    detail: { item, activeTab, hideGeneral, anchorEl, collection },
  } = useSelector((state) => state.detail)

  // State variables to handle synchronization and UI updates
  const [autoUpdate, setAutoUpdate] = useState(true)
  const [localItem, setLocalItem] = useState(item)
  const [localActiveTab, setLocalActiveTab] = useState(activeTab)
  const [localHideGeneral, setLocalHideGeneral] = useState(hideGeneral)
  const [localCollection, setLocalCollection] = useState(collection)

  /**
   * Effect hook to update local state when Redux state changes
   */
  useEffect(() => {
    if (autoUpdate) {
      if (item && item._id !== localItem?._id) {
        setLocalItem(item)
      }
      if (activeTab !== localActiveTab) {
        setLocalActiveTab(activeTab)
      }
      if (hideGeneral !== localHideGeneral) {
        setLocalHideGeneral(hideGeneral)
      }
      if (collection !== localCollection) {
        setLocalCollection(collection)
      }
    }
  }, [item, activeTab, hideGeneral, collection, autoUpdate])

  /**
   * Effect hook to fetch unitary analysis details on initialization
   */
  useEffect(() => {
    const uaId = props.match.params.unitaryAnalysisId
    if (uaId) {
      downloadUADetail(uaId)
    }
  }, [])

  /**
   * Function to toggle automatic updates for the detail window
   */
  const handleChangeSync = () => {
    setAutoUpdate((prev) => !prev)
  }

  return (
    <Fragment>
      <div className={classes.customModal}>
        <ErrorBoundaryGuard>
          <DetailContent
            externalWindowMode
            localItem={localItem}
            localActiveTab={localActiveTab}
            localHideGeneral={localHideGeneral}
            localCollection={localCollection}
          />
        </ErrorBoundaryGuard>
      </div>
      <SyncSwitch autoUpdate={autoUpdate} onToggle={handleChangeSync} />
    </Fragment>
  )
})

// const areEqual = (prevProps, nextProps) => {
//   const propsChanged = Object.keys(nextProps).filter((key) => prevProps[key] !== nextProps[key])
//
//   if (propsChanged.length > 0) {
//     console.log('Changed props:', propsChanged)
//     propsChanged.forEach((prop) => {
//       console.log(`${prop} changed from`, prevProps[prop], 'to', nextProps[prop])
//     })
//   }
//
//   return propsChanged.length === 0
// }

/**
 * Memoized version of DetailWindow to prevent unnecessary re-renders
 */
const DetailWindow = React.memo(
  React.forwardRef((props, ref) => {
    return <DetailWindowWithRouter {...props} forwardedRef={ref} />
  }),
  // areEqual,
)

export default DetailWindow
