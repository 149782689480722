// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useMemo } from 'react'
import { Analysis, genericStyles } from '../common/Style'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { getAnalysisById } from '../../redux/analyses/selectors'
import { alpha } from '@material-ui/core/styles'
import { PUBLIC_URL } from '../../utils/config'
import Badge from '@material-ui/core/Badge'
import FormControl from '@material-ui/core/FormControl'
import clsx from 'clsx'
import FilterListIcon from '@material-ui/icons/FilterList'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Button from '@mui/material/Button'
import { Tooltip } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Stack } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  badgeCount: {
    '& .MuiBadge-badge': {
      right: 2,
      top: -1,
      color: alpha(theme.palette.secondary.main, 0.8),
      background: 'white',
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeFilter: {
    margin: '0px 48px 0px 10px',
    background: 'lightgrey',
    color: 'white',
    fontSize: 12,
    padding: '1px 8px',
    borderRadius: 14,
  },
  iconFilter: {
    float: 'left',
    position: 'absolute',
    top: 2,
  },
  formControl: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: 34,
    padding: '0px 12px 0px 14px',
    borderRadius: 18,
    backgroundColor: '#e0e0e0',
    color: '#666',
    '& .MuiInput-root::after': {
      borderBottomWidth: '0px !important',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  formControlActive: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiInput-root': {
      color: 'white',
    },
    '& .MuiInput-underline::after': {
      borderBottomWidth: '0px !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  formSelect: {
    paddingLeft: 30,
    color: '#666',
    '&:before': {
      borderBottomWidth: '0px !important',
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottomWidth: '0px !important',
    },
    '& > .MuiSelect-select:focus': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiSelect-root': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 190,
    },
  },
  addButton: {
    color: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
  },
  addButtonMenu: {
    '&:hover svg': {
      color: theme.palette.secondary.main,
    },
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  cardError: {
    border: '1px solid #f44336',
    borderRadius: 3,
    '& .MuiCardHeader-content .MuiCardHeader-subheader': {
      color: '#f44034cc',
    },
  },
  list: {
    width: '100%',
    height: 330,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
    background: 'white',
  },
  settingsHeader: {
    height: 130,
    background: 'url(' + PUBLIC_URL + '/images/filter_background.jpg) no-repeat',
    backgroundSize: '100%',
    boxShadow: 'inset 0px 0px 20px 10px rgba(0,0,0,0.1)',
    paddingTop: 65,
    textShadow: '2px 1px 2px grey',
    '& h2': {
      fontSize: 40,
      textShadow: '2px 1px 10px white',
      color: theme.palette.secondary.main,
      fontWeight: 400,
    },
  },
  deleteButton: {
    marginLeft: 10,
    padding: 0,
    position: 'absolute',
    right: 4,
    '& .deleteButtonIconUnactive': {
      opacity: 1,
      position: 'absolute',
    },
    '& .deleteButtonIconActive': {
      opacity: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .deleteButtonIconUnactive': {
      opacity: 0,
    },
    '&:hover .deleteButtonIconActive': {
      color: theme.palette.common.error,
      opacity: 1,
    },
  },
  editButton: {
    marginLeft: 10,
    padding: 0,
    position: 'absolute',
    right: 30,
    '& .editButtonIconUnactive': {
      opacity: 1,
      position: 'absolute',
    },
    '& .editButtonIconActive': {
      opacity: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .editButtonIconUnactive': {
      opacity: 0,
    },
    '&:hover .editButtonIconActive': {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
  },
  dialogFilter: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 1000,
    },
    '& .MuiListItem-dense': {
      padding: 0,
    },
    '& .MuiListItem-dense .MuiCheckbox-root': {
      padding: '0px 0px 0px 17px',
    },
    '& .MuiListItem-dense .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 5,
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
    },
  },
  clearIcon: {
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  warningMessage: {
    paddingTop: 10,
    color: theme.palette.common.warning,
  },
  checkboxLabel: {
    margin: 5,
  },
}))
const FilterButton = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { validFilterFunction } = props
  const genericClasses = genericStyles()
  const dispatch = useDispatch()
  const filters = ['std', 'mb', 'mbd', 'stde', 'blk', 'spl', 'oth']
  const [activeFiltersTmp, setActiveFiltersTmp] = React.useState(
    Object.assign(
      {},
      ...filters.map((filter) => ({
        [filter]: localStorage.getItem('COMPOUND_filter_' + filter) === 'true',
      })),
    ),
  )
  const [activeFilters, setActiveFilters] = React.useState(
    Object.assign(
      {},
      ...filters.map((filter) => ({
        [filter]: localStorage.getItem('COMPOUND_filter_' + filter) === 'true',
      })),
    ),
  )

  const convertLabel = (label) => {
    let convertedLabel = ''
    switch (label) {
      case 'std':
        convertedLabel = 'calibration'
        break
      case 'mb':
        convertedLabel = 'matrix_bio'
        break
      case 'mbd':
        convertedLabel = 'matrix_bio_doped'
        break
      case 'stde':
        convertedLabel = 'std_end'
        break
      case 'blk':
        convertedLabel = 'blank'
        break
      case 'spl':
        convertedLabel = 'sample'
        break
      case 'oth':
        convertedLabel = 'other'
        break
    }
    return convertedLabel
  }

  const isFetchingUnitaryCompoundDistinct = useSelector(function (state) {
    return state.unitaryAnalyses.isFetchingUnitaryCompoundDistinct
  })
  const unitaryCompoundDistinct = useSelector(function (state) {
    return state.unitaryAnalyses.unitaryCompoundDistinct
  })

  // const analyse = useSelector(function (state) {
  //   const analysisId = items && items[0] ? items[0].analyseId : null
  //   return getAnalysisById(state.analyses, analysisId)
  // })
  // const configFeatures = useSelector(function (state) {
  //   return state.configuration.configFeatures
  // })
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setActiveFilters(activeFiltersTmp)
    setAnchorEl(null)
    // Apply new filters
    Object.entries(activeFiltersTmp).forEach((af) =>
      localStorage.setItem('COMPOUND_filter_' + af[0], af[1]),
    )
    validFilterFunction(activeFiltersTmp)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    if (open) {
      setActiveFiltersTmp(
        Object.assign(
          {},
          ...filters.map((filter) => ({
            [filter]: localStorage.getItem('COMPOUND_filter_' + filter) === 'true',
          })),
        ),
      )
    }
  }, [open])

  const handleChange = (event) => {
    const name = event.target ? event.target.name : null
    const checked = event.target.checked
    if (name) {
      setActiveFiltersTmp((prevFilters) => {
        return {
          ...prevFilters,
          [name]: checked,
        }
      })
    }
  }
  return (
    <Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FormGroup style={{ padding: 10 }}>
          {filters.map((filter, index) => (
            <FormControlLabel
              key={index}
              style={{ marginRight: 10 }}
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  checked={activeFiltersTmp[filter]}
                  onChange={handleChange}
                  name={filter}
                  style={{ marginRight: 6 }}
                />
              }
              label={
                <Fragment>
                  <span>{filter}</span>
                  {!isFetchingUnitaryCompoundDistinct && (
                    <span className={classes.badgeFilter}>
                      {unitaryCompoundDistinct[convertLabel(filter)]
                        ? unitaryCompoundDistinct[convertLabel(filter)].count
                        : 0}
                    </span>
                  )}
                </Fragment>
              }
            />
          ))}
        </FormGroup>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          style={{ padding: 10 }}
        >
          <Button variant="outlined" onClick={handleClose}>
            {t('view.compound.filter.apply')}
          </Button>
          <Button variant="outlined" onClick={() => setAnchorEl(null)}>
            {t('view.compound.filter.cancel')}
          </Button>
        </Stack>
      </Popover>
      <Badge
        className={classes.badgeCount}
        max={10000}
        invisible={
          isFetchingUnitaryCompoundDistinct ||
          (activeFilters && Object.entries(activeFilters).filter((af) => af[1]).length === 0)
        }
        badgeContent={Object.entries(activeFilters)
          .filter((af) => {
            return af[1] && unitaryCompoundDistinct[convertLabel(af[0])]
          })
          .map((a) => unitaryCompoundDistinct[convertLabel(a[0])].count)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)}
      >
        <Tooltip
          arrow
          placement="top"
          title={
            <div style={{ fontSize: 13, padding: 5 }}>
              {activeFilters && Object.entries(activeFilters).filter((af) => af[1]).length > 0 ? (
                <Fragment>
                  <div>{t('view.compound.filter.active')}</div>
                  <div>
                    {Object.entries(activeFilters)
                      .filter((af) => af[1])
                      .map((aff) => aff[0])
                      .join(', ')}
                  </div>
                </Fragment>
              ) : (
                <em>{t('view.compound.filter.no_active')}</em>
              )}
            </div>
          }
        >
          <Button
            className={clsx(
              classes.formControl,
              activeFilters && Object.entries(activeFilters).filter((af) => af[1]).length > 0
                ? classes.formControlActive
                : null,
            )}
            onClick={handleClick}
            variant="outlined"
            startIcon={<Analysis style={{ marginLeft: 5 }} />}
            endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          >
            {activeFilters && Object.entries(activeFilters).filter((af) => af[1]).length > 0 ? (
              Object.entries(activeFilters)
                .filter((af) => af[1])
                .map((aff) => aff[0])
                .join(', ')
            ) : (
              <em>{t('view.compound.filter.none')}</em>
            )}
          </Button>
        </Tooltip>
      </Badge>
    </Fragment>
  )
})
export default FilterButton
