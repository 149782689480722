// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React from 'react'
import { connect, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Loading from '../../Loading'
import Popover from '@material-ui/core/Popover'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'
import { getBatchById } from '../../../redux/batches/selectors'
import DetailContent from './DetailContent'
import { fetchChangeSettings } from '../../../redux/settings/actions'

const styles = (theme) => ({
  popoverContainer: {
    background: '#00000021',
    '& > .MuiPopover-paper': {
      height: 'calc(100% - 50px)',
      width: 'calc(100% - 50px)',
      top: '25px !important',
      left: '25px !important',
      // padding: 20,
      overflow: 'hidden',
    },
  },
})

const mapStateToProps = (state, ownProps) => {
  const { detail, global, batches, settings } = state
  // const batch = getBatchById(batches, ownProps.match.params.batchId)
  const batchID = detail?.detail?.item?.batchId
  const batch = getBatchById(batches, batchID)

  return {
    batch,
    showDetail: settings.displayChromatoDetailDialog,
    item: detail.detail.item,
    activeTab: detail.detail.activeTab,
    hideGeneral: detail.detail.hideGeneral,
    anchorEl: detail.detail.anchorEl,
    collection: detail.detail.collection,
    featuresConfig: global.featuresConfig,
  }
}

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class Detail extends React.Component {
        constructor(props) {
          super(props)
          this.handleCloseFlagsDetail = this.handleCloseFlagsDetail.bind(this)
        }

        handleCloseFlagsDetail = (event) => {
          const { dispatch } = this.props
          event.stopPropagation()
          event.preventDefault()
          dispatch(fetchChangeSettings('displayChromatoDetailDialog', false))
        }

        render() {
          const { classes, showDetail, item, activeTab, hideGeneral, collection } = this.props
          if (!item) {
            return (
              <Popover
                id={undefined}
                open={false}
                anchorEl={null}
                className={classes.popoverContainer}
              >
                <Loading mode={'miniCircle'} />
              </Popover>
            )
          }

          const id = showDetail ? 'simple-popover' : undefined

          return (
            <Popover
              id={id}
              open={showDetail}
              // anchorEl={anchorEl.isConnected ? anchorEl : document.getElementById('myAnalyseList')}
              anchorEl={document.getElementById('myAnalyseList')}
              onClose={(event) => this.handleCloseFlagsDetail(event)}
              className={classes.popoverContainer}
            >
              <ErrorBoundaryGuard>
                <DetailContent
                  localItem={item}
                  localActiveTab={activeTab}
                  localHideGeneral={hideGeneral}
                  localCollection={collection}
                />
              </ErrorBoundaryGuard>
            </Popover>
          )
        }
      },
    ),
  ),
)
