// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { REQUEST_TEAMS, RECEIVE_TEAMS } from './actions'
import React from 'react'

const initialState = {
  teams: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_TEAMS:
      return {
        ...state,
      }
    case RECEIVE_TEAMS:
      return {
        ...state,
        teams: action.teams,
      }
    default:
      return state
  }
}
