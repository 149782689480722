// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect } from 'react'
import clsx from 'clsx'
import { genericStyles, TransitionBottom } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { t } from 'i18next'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import image from '../../resources/images/accounting_background.jpg'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { withRouter } from 'react-router-dom'
import { Stack, TextField } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import InputAdornment from '@mui/material/InputAdornment'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { FLASK_URL } from '../../utils/config'
import * as I18n from 'i18next'
import { displayPopupMessage } from '../common/Message'
import LoadingButton from '@mui/lab/LoadingButton'
import { Trans } from 'react-i18next'
import Input from '@mui/material/Input'

const useStyles = makeStyles((theme) => ({
  header: {
    overflow: 'hidden',
  },
  title: {
    overflow: 'hidden',
    width: 'calc(100% - 135px)',
    display: 'block',
    lineHeight: 1,
  },
}))
const ScreeningServiceDialog = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const genericClasses = genericStyles()

  const { open } = props

  const [uploadInProgress, setUploadInProgress] = React.useState(false)
  const [configRef, setConfigRef] = React.useState('none')
  const [methodFile, setMethodFile] = React.useState(null)
  const [methodFileName, setMethodFileName] = React.useState('')

  const configurations = useSelector((state) =>
    state.configuration.configurations.filter((config) =>
      config.content.hasOwnProperty('default_screening'),
    ),
  )

  useEffect(() => {
    if (configRef !== 'none') {
      const confSelected = configurations.filter((config) => config._id === configRef)[0]
      if (confSelected) {
        setMethodFileName(confSelected?.content?.default_screening?.split(/[/\\]+/).pop())
      }
    } else {
      setMethodFileName('')
    }
  }, [configRef, configurations.length])

  const handleClose = () => {
    dispatch(fetchChangeSettings('displayScreeningServiceDialog', false))
  }

  function normalizeFileName(fileName) {
    const lowerCaseName = fileName.toLowerCase()
    if (lowerCaseName.endsWith('.cod.xlsx')) {
      return fileName.slice(0, -9) + '.cod.xlsx'
    }
    if (lowerCaseName.endsWith('.cod') || lowerCaseName.endsWith('.xlsx')) {
      return fileName.slice(0, fileName.lastIndexOf('.')) + '.cod.xlsx'
    }
    if (lowerCaseName.endsWith('.xls')) {
      return fileName.slice(0, -4) + '.cod.xlsx'
    }
    return fileName + '.cod.xlsx'
  }

  const handleGenerate = () => {
    setUploadInProgress(true)
    let formData = new FormData()
    formData.append('file', methodFile)
    formData.append('filename', methodFileName)
    formData.append('config_id', configRef)

    const errorDisplay = function (msg) {
      displayPopupMessage(
        dispatch,
        'error',
        I18n.t('view.dialogs.screeningService.title_process'),
        <Fragment>
          <div>{msg}</div>
        </Fragment>,
      )
      setUploadInProgress(false)
    }

    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.addEventListener(
      'load',
      function (e) {
        if (e.target.status === 200) {
          try {
            let fileName = normalizeFileName(methodFileName)
            const blob = new Blob([this.response])
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            setUploadInProgress(false)
            handleClose()
            displayPopupMessage(
              dispatch,
              'success',
              I18n.t('view.dialogs.screeningService.title_process'),
              <Trans i18nKey="view.dialogs.screeningService.process_success">
                Conversion is complete, the converted file ({{ val: fileName }}) is ready to be
                downloaded.
              </Trans>,
            )
          } catch (e) {
            errorDisplay(e.message)
          }
        } else {
          errorDisplay(e.target.status + ': ' + e.target.statusText)
        }
      },
      false,
    )
    xhr.addEventListener(
      'error',
      function (error) {
        errorDisplay(error.target.status + ': ' + error.target.statusText)
      },
      false,
    )
    xhr.addEventListener(
      'abort',
      function () {
        displayPopupMessage(
          dispatch,
          'warning',
          I18n.t('view.dialogs.screeningService.title_process'),
          <Fragment>
            <div>{I18n.t('view.dialogs.screeningService.process_abort')}.</div>
          </Fragment>,
        )
      },
      false,
    )

    xhr.upload.addEventListener(
      'progress',
      function (e) {
        setUploadInProgress(true)
      },
      false,
    )
    xhr.open('POST', `${FLASK_URL()}/convert-method-file-to-cod-method`)
    xhr.withCredentials = true
    xhr.send(formData)
  }

  const handleChangeConfigRef = (event) => {
    setConfigRef(event.target.value)
  }

  const handleClickRemoveMethodFile = (event) => {
    setMethodFile(null)
  }

  const handleChangeInputFile = (files) => {
    setMethodFile(files[0])
  }

  return (
    <Dialog
      open={open}
      fullWidth={false}
      maxWidth={'xl'}
      onClose={handleClose}
      TransitionComponent={TransitionBottom}
    >
      <span>
        <DialogTitle style={{ padding: 0 }}>
          <Box className={genericClasses.header}>
            <CardMedia
              className={clsx(genericClasses.cardImg, classes.header)}
              component="img"
              alt="header image"
              height="130"
              image={image}
              title="Header image"
            />
            <span className={clsx(genericClasses.title, classes.title)}>
              {t('view.dialogs.screeningService.title')}
            </span>
            <UploadFileIcon className={genericClasses.icon} />
          </Box>
        </DialogTitle>
        <DialogContent style={{ marginTop: 10 }}>
          <Stack direction="column" justifyContent="space-between" alignItems="center" spacing={3}>
            {methodFile ? (
              <TextField
                fullWidth
                label={t('view.dialogs.screeningService.uploaded')}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={uploadInProgress}
                        onClick={handleClickRemoveMethodFile}
                        edge="end"
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={methodFile?.name}
                disabled={uploadInProgress}
              />
            ) : (
              <div style={{ width: '100%', height: 56 }}>
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  style={{ height: 56 }}
                  color="primary"
                  onClick={() => {
                    document.getElementById('uploadFileForScreeningService').click()
                  }}
                >
                  {t('view.dialogs.screeningService.upload')}
                </Button>
                <Input
                  id={'uploadFileForScreeningService'}
                  style={{ opacity: 0, height: 0, width: 50 }}
                  type="file"
                  onChange={(event) => {
                    handleChangeInputFile(event.target.files)
                  }}
                />
              </div>
            )}
            <FormControl fullWidth disabled={uploadInProgress}>
              <InputLabel>{t('view.dialogs.screeningService.configRef')}</InputLabel>
              <Select
                MenuProps={{ disablePortal: true }} // Fix the FocusTrap issue
                value={configRef}
                label={t('view.dialogs.screeningService.configRef')}
                onChange={handleChangeConfigRef}
              >
                <MenuItem value={'none'}>None</MenuItem>
                {configurations.map((config) => (
                  <MenuItem value={config._id}>{config.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              required
              fullWidth
              label={t('view.dialogs.screeningService.name')}
              InputLabelProps={{
                shrink: true,
              }}
              value={methodFileName}
              error={methodFileName === ''}
              InputProps={{
                readOnly: configRef !== 'none',
              }}
              onChange={(event) => {
                setMethodFileName(event.target.value)
              }}
              disabled={uploadInProgress}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={handleGenerate}
            loading={uploadInProgress}
            color="primary"
            disabled={!methodFile || methodFileName === ''}
          >
            {t('view.dialogs.screeningService.generate')}
          </LoadingButton>
          <Button onClick={handleClose} color="primary">
            {t('view.dialogs.screeningService.close')}
          </Button>
        </DialogActions>
      </span>
    </Dialog>
  )
})
export default ScreeningServiceDialog
