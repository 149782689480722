// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useMemo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  GridActionsCellItem,
  GridColumnMenu,
  gridFilteredDescendantCountLookupSelector,
  GridMoreVertIcon,
  useGridSelector,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import {
  getHeaderTooltipCmp,
  isLeasePassed,
  isretiredAvailableForUser,
  isSetCalibRefAvailableForUser,
  openInNewTab,
  StripedDataGridPro,
} from '../../utils/utilities'
import { t } from 'i18next'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import NoteButton from '../common/NoteButton'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { CustomTooltip, XLSFileInvert } from '../common/Style'
import LoopIcon from '@material-ui/icons/Loop'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import {
  BorderLinearProgress,
  callBuildATrainingBatch,
  CustomNoRowsOverlay,
  exploreBatch,
  FlagItem,
  getFlagIcon,
  getFlagName,
  handleDownloadReport,
  handleGenerateMethod,
  handleRetiredBatch,
  handleSetBatchAsCalibRef,
  handleStampBatch,
  handleUpdateBatch,
  onClick,
  onUpdatePriority,
  openBatch,
  openBatchInNewTab,
  OperatorTooltip,
  saveBatch,
  StyledBadge,
} from './Tools'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import SchoolIcon from '@material-ui/icons/School'
import ReplayIcon from '@material-ui/icons/Replay'
import ApprovalIcon from '@mui/icons-material/Approval'
import SearchIcon from '@mui/icons-material/Search'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import Loading from '../Loading'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Stack } from '@mui/material'
import FaceIcon from '@mui/icons-material/Face'
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import TerminalIcon from '@mui/icons-material/Terminal'
import ListItemIcon from '@mui/material/ListItemIcon'
import ExpandIcon from '@mui/icons-material/Expand'
import ListItemText from '@mui/material/ListItemText'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { flagsFilterOperators } from '../common/ColumnFilters'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { isNavigationKey } from '@mui/x-data-grid/internals'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { DATABASE } from '../../utils/config'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'
import Typography from '@material-ui/core/Typography'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'

/**
 * Custom styles for the TableCmp component.
 * @param theme Material-UI theme object.
 */
const useStyles = makeStyles((theme) => ({
  tableCmp: {
    top: 10,
    height: '100%',
    display: 'contents',
    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    fontSize: 14,
    fontWeight: 400,
    padding: 5,
    lineHeight: 1.5,
  },
  trainingButton: {
    width: 24,
    height: 24,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  actionMenu: { justifyContent: 'left', alignItem: 'start' },
  actionDivider: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0px !important',
    margin: '5px 0px',
  },
  formControlLoading: {
    '& .MuiSelect-select.Mui-disabled': {
      cursor: 'wait !important',
    },
  },
  retired: {
    color: '#bd170b',
  },
  loadingBatchIcon: {
    animation: 'circle 1s linear infinite',
    animationDirection: 'reverse',
    fontSize: '2rem',
  },
}))

/**
 * Default date template error value.
 */
const DUE_DATE_TEMPLATE_ERROR = '0000-00-00 00:00:00'

/**
 * Initial state configuration for the data grid.
 */
const defaultState = {
  pinnedColumns: {
    // left: [GRID_TREE_DATA_GROUPING_FIELD],
    right: ['actions'], // Pin the actions column to the right.
  },
  sorting: {
    sortModel: [
      {
        field: 'name',
        sort: 'asc', // Sort by name in ascending order by default.
      },
    ],
  },
}

/**
 * Component for handling status input values in the data grid.
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.item - The current filter item.
 * @param {Function} props.applyValue - Function to apply the filter value.
 * @returns {JSX.Element} The status input value component.
 */
function StatusInputValue(props) {
  const { item, applyValue } = props

  const handleFilterChange = (event, newValue) => {
    applyValue({
      ...item,
      value:
        newValue?.length !== 0
          ? newValue.indexOf('empty') !== -1
            ? ['empty']
            : newValue
          : undefined,
    })
  }

  const isEmpty = useMemo(() => {
    return item?.value?.indexOf('empty') !== -1 && item?.value?.indexOf('empty') !== undefined
  }, [item.value])

  return (
    <Stack
      style={{ height: 48 }}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      spacing={2}
    >
      <ToggleButtonGroup
        variant="text"
        size="small"
        color="primary"
        value={item.value}
        onChange={handleFilterChange}
      >
        <ToggleButton value="calibRef" disabled={isEmpty}>
          <Tooltip
            arrow
            placement={'top'}
            title={<div style={{ fontSize: 13, padding: 5 }}>{t('view.backlog.calibRef')}</div>}
          >
            <BookmarkBorderIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="stamp" disabled={isEmpty}>
          <Tooltip
            arrow
            placement={'top'}
            title={<div style={{ fontSize: 13, padding: 5 }}>{t('view.backlog.stamped')}</div>}
          >
            <ApprovalIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="empty">
          <Tooltip
            arrow
            placement={'top'}
            title={<div style={{ fontSize: 13, padding: 5 }}>{t('view.backlog.empty')}</div>}
          >
            <CheckBoxOutlineBlankIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}

/**
 * Constructs a hierarchy for the given items.
 * Each item will have a `hierarchy` property that represents its position in the hierarchy.
 *
 * @param {Array} items - The array of items to construct the hierarchy for.
 * @returns {Array} The array of items with the hierarchy property added.
 */
function constructHierarchy(items) {
  const itemMap = new Map()
  items.forEach((item) => {
    itemMap.set(item.id, item)
  })
  items.forEach((item) => {
    const hierarchy = []
    let currentItem = item

    while (currentItem) {
      hierarchy.unshift(currentItem.id)
      const parentId = currentItem?.root_batch?.id
      if (!parentId || parentId === currentItem.id || !itemMap.has(parentId)) {
        break
      }
      currentItem = itemMap.get(parentId)
    }
    item.hierarchy = hierarchy
  })
  return items
}

/**
 * Removes duplicate objects from an array based on their \_id property.
 * If there are multiple objects with the same \_id, the one with the latest modificationDate is kept.
 *
 * @param {Array} array - The array of objects to process.
 * @returns {Array} - A new array with duplicate objects removed.
 */
function removeDuplicateIds(array) {
  const map = new Map()
  for (const obj of array) {
    if (!map.has(obj._id) || obj.modificationDate > map.get(obj._id).modificationDate) {
      map.set(obj._id, obj)
    }
  }
  return Array.from(map.values())
}

/**
 * Custom cell component for displaying hierarchical data in the data grid.
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.id - The ID of the current row.
 * @param {string} props.field - The field name of the current cell.
 * @param {Object} props.rowNode - The row node object containing information about the row.
 * @returns {JSX.Element} The custom cell component for hierarchical data.
 */
const CustomGridTreeDataGroupingCell = (props) => {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  )

  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.stopPropagation()
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent('cellNavigationKeyDown', props, event)
    }
  }

  const handleClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box sx={{ ml: rowNode.depth * 2 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <span>
            <IconButton onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={-1} size="small">
              {rowNode.childrenExpanded ? (
                <KeyboardArrowDownIcon fontSize="inherit" />
              ) : (
                <KeyboardArrowRightIcon fontSize="inherit" />
              )}
            </IconButton>
            <span>({filteredDescendantCount})</span>
          </span>
        ) : rowNode.depth > 0 ? (
          <span>|</span>
        ) : (
          <span />
        )}
      </div>
    </Box>
  )
}

/**
 * Custom component for the data grid.
 * Handles the display and management of batch data with hierarchical grouping and filters.
 */
const TableCmp = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { style, setCurrentUpdateBatch, setOpenConfirmUpdateBatch, setOpenCreateTrainingBatch } =
    props
  const apiRef = useGridApiRef()
  const autoSizeOptions = { includeHeaders: true, includeOutliers: true, expand: true }

  // Selector to retrieve batch data and settings from Redux store.
  const delta = useSelector((state) => state.settings.dateTimeDelta)
  const items = useSelector((state) => state.batches.items)
  const isFetching = useSelector((state) => state.batches.isFetching)
  const wikiCODHelper = useSelector((state) => state.settings.wikiCODHelper)

  const showRetired = localStorage.getItem('SETTINGS_show_retired')
    ? localStorage.getItem('SETTINGS_show_retired') === 'true'
    : false

  const [extractMenuAnchor, setExtractMenuAnchor] = React.useState(null)
  const [itemToExtract, setItemToExtract] = React.useState(null)
  const extraMenuOpen = Boolean(extractMenuAnchor)
  /**
   * Function to open the extract menu.
   * @param {Event} event - The event that triggered the function.
   * @param {Object} row - The data row representing a batch to extract.
   */
  const openExtractMenu = (event, row) => {
    setExtractMenuAnchor(event.currentTarget)
    setItemToExtract(row)
  }

  /**
   * Function to close the extract menu.
   */
  const handleCloseExtraMenu = () => {
    setExtractMenuAnchor(null)
  }

  /**
   * Function to determine if a batch is currently loading.
   * @param row Data row representing a batch.
   * @returns {boolean} True if the batch is loading, otherwise false.
   */
  const isLoading = function (row) {
    return !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment'
  }

  /**
   * Prepares the batch data for display, including hierarchy and flags.
   */
  const batches = useMemo(() => {
    if (items) {
      const itemsPrepared = items.map((item) => ({
        // hierarchy: [item._id],
        root_batch: item.content.root_batch,
        id: item._id,
        _id: item._id,
        name: item.name,
        path: item.content.folder,
        org_path: item.content.org_path,
        chromatoType: item.content.chromatography_type,
        configuration: item.content.configuration,
        batchDate: item.content.date_ts,
        dueDate:
          item.content.due_date && item.content.due_date !== DUE_DATE_TEMPLATE_ERROR
            ? item.content.due_date
            : null,
        device: item.content.device,
        priority: item.content.priority,
        operator: item.content.operator,
        creationDate: item.creationDate,
        analyseProgression: item.content.analyses_progression_requested
          ? item.content.analyses_progression_requested.toFixed(2)
          : 0,
        calibrationProgression: item.content.calibration_progression_requested
          ? item.content.calibration_progression_requested.toFixed(2)
          : 0,
        content: {
          progression_requested:
            ((item.content.analyses_progression_requested
              ? item.content.analyses_progression_requested
              : 0) +
              (item.content.calibration_progression_requested
                ? item.content.calibration_progression_requested
                : 0)) /
            2,
        },
        progression:
          ((item.content.analyses_progression_requested
            ? item.content.analyses_progression_requested
            : 0) +
            (item.content.calibration_progression_requested
              ? item.content.calibration_progression_requested
              : 0)) /
          2,
        flags: { globals: item.content.flags },
        subBatch:
          item.content.root_batch &&
          item.content.root_batch.id &&
          item.content.root_batch.id !== item._id
            ? item.content.root_batch
            : null,
        notes: item.content.notes ? item.content.notes : [],
        child_notes: item.content.child_notes
          ? item.content.child_notes
          : { calibrations: 0, analysis: 0 },
        lockUpdate:
          (item.content.root_batch && item.content.root_batch.id !== item._id) ||
          item.content.category === 'training',
        newUpdated: item.newUpdated,
        version: item.content.version,
        lease:
          item.content.lease && typeof item.content.lease === 'number'
            ? { date: item.content.lease, operator: null, reason: null }
            : item.content.lease &&
              item.content.lease !== null &&
              item.content.lease !== undefined &&
              typeof item.content.lease === 'object'
            ? item.content.lease
            : { date: 0, operator: null, reason: null },
        stamp: item.content.stamp,
        retired: item.content.retired,
        teams: item.teams,
        calibRef: item?.tags.indexOf('calibration_reference') !== -1,
      }))
      // const batchesPrepared = itemsPrepared.filter((item) => !item.subBatch)
      // const newROws = stableSort(batchesPrepared, getComparator(sortModel.sort, sortModel.field))
      // return newROws

      // Do not keep items with same ID
      // It happen if the polling bring a batch created by training (for exemple)
      // and at the end of the process, the batch is added in redux (twice then)
      return constructHierarchy(removeDuplicateIds(itemsPrepared))
    } else {
      return []
    }
  }, [items])

  /**
   * Autosizes the columns of the data grid.
   * This function adjusts the width of the columns to fit the content.
   */
  function autosizingColumns() {
    apiRef.current.autosizeColumns(autoSizeOptions)
  }

  /**
   * Resets the grid columns to their default state.
   */
  function resetColumns() {
    const cols = columns.map((col) => col.field)
    const dim = apiRef.current.exportState().columns.dimensions
    const colNames = Object.keys(dim)
    colNames.forEach((col) => (dim[col].width = 90))

    apiRef.current.setFilterModel({
      items: [],
      logicOperator: 'and',
      quickFilterLogicOperator: 'and',
      quickFilterValues: [],
    })
    apiRef.current.restoreState({
      ...defaultState,
      columns: {
        dimensions: { ...dim, actions: { maxWidth: 50, minWidth: 50, width: 50 } },
        orderedFields: ['__tree_data_group__', cols].flat(),
        columnVisibilityModel: {},
      },
    })
  }

  /**
   * Defines the columns for the data grid.
   * Uses useMemo to memoize the columns array to avoid unnecessary re-renders.
   */
  const columns = useMemo(() => {
    const columnArray = [
      {
        field: 'flags',
        headerName: t('view.backlog.header_names.flags'),
        renderCell: ({ row }) => <FlagItem row={row} isLoading={isLoading(row)} />,
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        valueFormatter: (params) => {
          return getFlagName(params.api.getRow(params.id))
        },
        filterOperators: flagsFilterOperators,
        valueGetter: ({ value }) => {
          switch (getFlagIcon((value.globals || []).filter((f) => !f.bypass_backlog))) {
            case 'info':
              return 0
            case 'action':
              return 1
            case 'warning':
              return 2
            case 'error':
              return 3
            default:
              return -1
          }
        },
      },
      {
        field: 'name',
        headerName: t('view.backlog.header_names.name'),
        renderCell: ({ value, row }) =>
          row.newUpdated ? (
            <Badge
              color="secondary"
              badgeContent=" "
              variant="dot"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              style={{ width: '100%' }}
            >
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                className={row.retired ? classes.retired : ''}
              >
                {value}
              </span>
            </Badge>
          ) : (
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              className={row.retired ? classes.retired : ''}
            >
              {value}
            </span>
          ),
        // valueFormatter: ({ value }) => value,
      },
    ]
    if (showRetired) {
      columnArray.push({
        field: 'retired',
        headerName: t('view.backlog.header_names.retired'),
        renderCell: ({ value }) =>
          value ? <FolderDeleteOutlinedIcon className={classes.retired} /> : <span></span>,
      })
    }
    columnArray.push([
      {
        field: 'status',
        headerName: t('view.backlog.header_names.status'),
        valueGetter: ({ row }) => {
          let res = []
          if (row.calibRef) res.push('calibRef')
          if (row.stamp) res.push('stamp')
          return res
        },
        sortComparator: (v1, v2) =>
          (v1.indexOf('calibRef') !== -1 ? 1 : 0) +
          (v1.indexOf('stamp') !== -1 ? 2 : 0) -
          ((v2.indexOf('calibRef') !== -1 ? 1 : 0) + (v2.indexOf('stamp') !== -1 ? 2 : 0)),
        renderCell: function ({ value, row }) {
          const calibRef = row.calibRef ? (
            <Tooltip
              // disableInteractive
              placement={'top'}
              title={<div className={classes.tooltip}>{t('view.backlog.calibRef')}</div>}
              arrow
            >
              <BookmarkBorderIcon style={{ color: '#0000008a' }} />
            </Tooltip>
          ) : null
          const stamp = row.stamp ? (
            <Tooltip
              // disableInteractive
              placement={'top'}
              title={
                <div className={classes.tooltip}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <AccessTimeIcon style={{ width: '0.7em', marginRight: 10 }} />
                    {row?.stamp?.date ? new Date((value - delta) * 1000).toLocaleString() : '--'}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <FaceIcon style={{ width: '0.7em', marginRight: 10 }} />
                    {row?.stamp?.operator?.name ? row.stamp.operator?.name : '--'}
                  </Stack>
                </div>
              }
              arrow
            >
              <ApprovalIcon style={{ color: '#0000008a' }} />
            </Tooltip>
          ) : null
          return (
            <span style={{ width: '100%' }}>
              <div style={{ width: 30, display: 'inline-block' }}>{stamp}</div>
              <div style={{ width: 30, display: 'inline-block' }}>{calibRef}</div>
            </span>
          )
        },
        filterOperators: [
          {
            label: 'is',
            value: 'is',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null
              }
              return (params) => {
                if (filterItem.value.length === 1 && filterItem.value[0] === 'empty') {
                  return params.value.length === 0
                }
                return (
                  filterItem.value.length === params.value.length &&
                  filterItem.value.every((value, index) => value === params.value[index])
                )
              }
            },
            InputComponent: StatusInputValue,
          },
          {
            label: 'not',
            value: 'not',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null
              }
              return (params) => {
                if (filterItem.value.length === 1 && filterItem.value[0] === 'empty') {
                  return params.value.length !== 0
                }
                return (
                  filterItem.value.length !== params.value.length ||
                  !filterItem.value.every((value, index) => value === params.value[index])
                )
              }
            },
            InputComponent: StatusInputValue,
          },
          {
            label: 'contains (or)',
            value: 'contains',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null
              }
              return (params) => {
                if (filterItem.value.length === 1 && filterItem.value[0] === 'empty') {
                  return params.value.length === 0
                }
                return filterItem.value.some((value) => params.value.includes(value))
              }
            },
            InputComponent: StatusInputValue,
          },
          {
            label: 'not contains (or)',
            value: 'not_contains',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null
              }
              return (params) => {
                if (filterItem.value.length === 1 && filterItem.value[0] === 'empty') {
                  return params.value.length !== 0
                }

                return !filterItem.value.some((value) => params.value.includes(value))
              }
            },
            InputComponent: StatusInputValue,
          },
        ],
      },
      {
        field: 'version',
        headerName: t('view.backlog.header_names.version'),
      },
      {
        field: 'configuration',
        headerName: t('view.backlog.header_names.configuration'),
      },
      {
        field: 'notes',
        type: 'number',
        headerName: t('view.backlog.header_names.note'),
        renderCell: ({ row }) => (
          <ErrorBoundaryGuard isDialog>
            <NoteButton row={row} collection={'batch'} isLoading={isLoading(row)} />
          </ErrorBoundaryGuard>
        ),
        valueGetter: ({ value }) => value.length,
      },
      {
        field: 'path',
        headerName: t('view.backlog.header_names.path'),
        renderCell: ({ value }) => (
          <Tooltip
            // disableInteractive
            placement={'top'}
            title={<div className={classes.tooltip}>{value}</div>}
            arrow
          >
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                direction: 'rtl',
                textAlign: 'left',
              }}
            >
              {value?.charAt(0) === '/' ? value.substring(1) + '/' : value}
            </div>
          </Tooltip>
        ),
      },
      {
        field: 'batchDate',
        type: 'dateTime',
        valueFormatter: ({ value }) =>
          value &&
          new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(value * 1000)),
        headerName: t('view.backlog.header_names.batch_date'),
      },
      {
        field: 'dueDate',
        type: 'dateTime',
        valueFormatter: ({ value }) =>
          value &&
          new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(value)),
        headerName: t('view.backlog.header_names.due_date'),
      },
      {
        field: 'device',
        headerName: t('view.backlog.header_names.device'),
      },
      {
        field: 'priority',
        headerName: t('view.backlog.header_names.priority'),
        renderCell: ({ value, row }) => (
          <FormControl
            className={clsx(classes.formControl, isLoading(row) ? classes.formControlLoading : '')}
          >
            <Select
              value={value}
              name={'priority'}
              onChange={(event) => onUpdatePriority(event, row, dispatch)}
              displayEmpty
              autoWidth
              disabled={isLoading(row)}
              inputProps={{ 'aria-label': 'Priority' }}
            >
              <MenuItem key={'normal'} value={'Normal'}>
                {t('view.backlog.normal')}
              </MenuItem>
              <MenuItem key={'urgent'} value={'Urgent'}>
                {t('view.backlog.urgent')}
              </MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        field: 'operator',
        headerName: t('view.backlog.header_names.last'),
        renderCell: ({ value, row }) => (
          <Tooltip
            // disableInteractive
            placement={'left'}
            title={
              !isLoading(row)
                ? value &&
                  value.length > 0 &&
                  value[value.length - 1].date && <OperatorTooltip row={row} />
                : ''
            }
            arrow
          >
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value && value.length > 0 ? value[value.length - 1].name : ''}
            </span>
          </Tooltip>
        ),
      },
      {
        field: 'progression',
        type: 'number',
        headerName: t('view.backlog.header_names.progress'),
        renderCell: ({ value, row }) => (
          <Tooltip
            // disableInteractive
            title={
              !isLoading(row) ? (
                <div className={classes.tooltip}>
                  <div>{`${t('view.backlog.calib')}: ${row.calibrationProgression}%`}</div>
                  <div>{`${t('view.backlog.analysis')}: ${row.analyseProgression}%`}</div>
                </div>
              ) : (
                ''
              )
            }
            arrow
          >
            <BorderLinearProgress variant="determinate" value={value} />
          </Tooltip>
        ),
      },
      {
        field: 'actions',
        type: 'actions',

        hideable: false,
        resizable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: false,
        disableReorder: true,
        disableExport: true,
        pinnable: false,

        width: 50,
        minWidth: 50,
        maxWidth: 50,
        getActions: ({ row }) =>
          isLoading(row)
            ? [
                <GridActionsCellItem
                  icon={
                    <CustomTooltip
                      // disableInteractive
                      placement="top"
                      arrow
                      title={
                        isLoading(row) ? (
                          <span>
                            {t('view.panel.batchesCmp.tooltip.loading')}
                            <div>
                              {t('view.panel.batchesCmp.tooltip.operator')}: {row.lease.operator}
                            </div>
                            <div>
                              {t('view.panel.batchesCmp.tooltip.reason')}: {row.lease.reason}
                            </div>
                          </span>
                        ) : (
                          t('view.panel.batchesCmp.tooltip.other')
                        )
                      }
                    >
                      <LoopIcon className={classes.loadingBatchIcon} />
                    </CustomTooltip>
                  }
                  label="Loading"
                />,
              ]
            : [
                // <GridActionsCellItem
                //   className={classes.actionMenu}
                //   showInMenu
                //   icon={<ViewColumnIcon />}
                //   label={t('view.backlog.menu.align')}
                //   onClick={autosizingColumns}
                // />,
                // <div disabled={true} className={classes.actionDivider} showInMenu label={''} />,
                <GridActionsCellItem
                  className={classes.actionMenu}
                  showInMenu
                  icon={<OpenInBrowserIcon />}
                  label={t('view.panel.batchesCmp.actions.open')}
                  onClick={() => openBatch(row, props.history)}
                />,
                <GridActionsCellItem
                  className={classes.actionMenu}
                  showInMenu
                  icon={<OpenInNewIcon />}
                  label={t('view.panel.batchesCmp.actions.openNew')}
                  onClick={() => openBatchInNewTab(row)}
                />,
                <GridActionsCellItem
                  disabled={true}
                  className={classes.actionDivider}
                  showInMenu
                  label={''}
                />,
                <GridActionsCellItem
                  disabled={
                    (!isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment') ||
                    (row ? row.lockUpdate : true)
                  }
                  className={classes.actionMenu}
                  showInMenu
                  icon={<SchoolIcon />}
                  label={t('view.backlog.menu.training')}
                  onClick={(event) =>
                    callBuildATrainingBatch(
                      event,
                      row,
                      dispatch,
                      // setOpenCreateTrainingBatch,
                      // setCurrentUpdateBatch,
                    )
                  }
                />,
                <GridActionsCellItem
                  disabled={
                    (!isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment') ||
                    (row ? row.lockUpdate : true)
                  }
                  onClick={(event) => {
                    handleUpdateBatch(
                      event,
                      row,
                      dispatch /*setOpenConfirmUpdateBatch, setCurrentUpdateBatch*/,
                    )
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={
                    !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment' ? (
                      <LoopIcon className={classes.loadingBatchIcon} />
                    ) : (
                      <ReplayIcon />
                    )
                  }
                  label={t('view.backlog.menu.replace')}
                />,
                <GridActionsCellItem
                  disabled={
                    (!isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment') ||
                    (row ? row.lockUpdate : true)
                  }
                  onClick={(event) => {
                    openExtractMenu(event, row)
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={
                    <XLSFileInvert
                      style={{ width: 26, height: 21 }}
                      className={classes.colorBlue}
                    />
                  }
                  label={t('view.panel.batchesCmp.actions.download')}
                />,
                <GridActionsCellItem
                  disabled={true}
                  className={classes.actionDivider}
                  showInMenu
                  label={''}
                />,
                <GridActionsCellItem
                  onClick={(event) => {
                    handleStampBatch(event, row, dispatch)
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={<ApprovalIcon />}
                  label={t('view.backlog.menu.stamp')}
                />,
                isretiredAvailableForUser(row) ? (
                  <GridActionsCellItem
                    disabled={row.retired}
                    onClick={(event) => {
                      handleRetiredBatch(event, row, dispatch)
                    }}
                    className={classes.actionMenu}
                    showInMenu
                    icon={<FolderDeleteOutlinedIcon />}
                    label={t('view.backlog.menu.retired')}
                  />
                ) : (
                  <Fragment />
                ),
                isSetCalibRefAvailableForUser(row) ? (
                  <GridActionsCellItem
                    disabled={row.retired}
                    onClick={(event) => {
                      handleSetBatchAsCalibRef(event, row, dispatch)
                    }}
                    className={classes.actionMenu}
                    showInMenu
                    icon={<BookmarkBorderIcon />}
                    label={
                      row.calibRef
                        ? t('view.backlog.menu.removeCalibRef')
                        : t('view.backlog.menu.setCalibRef')
                    }
                  />
                ) : (
                  <Fragment />
                ),
                <GridActionsCellItem
                  disabled={true}
                  className={classes.actionDivider}
                  showInMenu
                  label={''}
                />,
                <GridActionsCellItem
                  onClick={(event) => {
                    handleGenerateMethod(event, row, dispatch)
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={<PostAddOutlinedIcon />}
                  label={t('view.backlog.menu.generateMethod')}
                />,
                <GridActionsCellItem
                  disabled={true}
                  className={classes.actionDivider}
                  showInMenu
                  label={''}
                />,
                <GridActionsCellItem
                  onClick={() => {
                    openInNewTab(
                      `${window.location.origin}${
                        window.location.pathname
                      }?${DATABASE()}/logs/batch/${row._id}`,
                    )
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={<TerminalIcon />}
                  label={t('view.panel.batchesCmp.actions.logs')}
                />,
                <GridActionsCellItem
                  onClick={() => {
                    exploreBatch(row, dispatch)
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={<SearchIcon />}
                  label={t('view.panel.batchesCmp.actions.explore')}
                />,
                <GridActionsCellItem
                  onClick={() => {
                    saveBatch(row)
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={<SaveAltIcon />}
                  label={t('view.panel.batchesCmp.actions.save')}
                />,
              ],
      },
    ])
    return columnArray.flat()
  }, [])

  // useEffect(() => {
  //   if (batches.length >= 100) {
  //     displayPopupMessage(
  //       dispatch,
  //       'info',
  //       t('view.backlog.info_date.title'),
  //       <Fragment>
  //         <div>{t('view.backlog.info_date.msg1')}</div>
  //         <div>{t('view.backlog.info_date.msg2')}</div>
  //       </Fragment>,
  //     )
  //   }
  // }, [batches])

  /**
   * Custom action icon component for the data grid.
   * Displays a tooltip with additional information.
   * @returns {JSX.Element} The custom action icon component.
   */
  function CustomActionIcon() {
    return (
      <CustomTooltip
        // disableInteractive
        placement="top"
        arrow
        title={t('view.panel.batchesCmp.tooltip.other')}
      >
        <GridMoreVertIcon />
      </CustomTooltip>
    )
  }
  /**
   * Custom loading component for the data grid.
   * Displays a loading spinner when data is being fetched.
   * @returns {JSX.Element} The custom loading component.
   */
  function CustomLoading() {
    return (
      <div style={{ height: 'calc(100% - 96px)', position: 'relative' }}>
        <Loading mode="alpha" size={10} />
      </div>
    )
  }
  /**
   * Saves the current state of the data grid to local storage.
   * This function is memoized using React.useCallback to avoid unnecessary re-renders.
   */
  const saveSnapshot = React.useCallback(() => {
    const stateSnapshot = apiRef.current.exportState()
    if (Object.keys(stateSnapshot).length !== 0) {
      localStorage.setItem('BACKLOG_state', JSON.stringify(stateSnapshot))
    }
  }, [apiRef])

  /**
   * Custom menu item component for adapting the size of columns.
   * @param {Object} props - The properties passed to the component.
   * @param {string} props.myCustomLabel - The label for the menu item.
   * @param {Function} props.myCustomHandler - The handler function for the menu item.
   * @returns {JSX.Element} The custom menu item component.
   */
  function CustomAdaptSize(props) {
    const { myCustomLabel, myCustomHandler } = props
    return (
      <MenuItem style={{ padding: 2, opacity: 1 }} onClick={myCustomHandler}>
        <ListItemIcon style={{ minWidth: 36 }}>
          <ExpandIcon fontSize="small" style={{ rotate: '90deg' }} />
        </ListItemIcon>
        <ListItemText>{myCustomLabel}</ListItemText>
      </MenuItem>
    )
  }

  /**
   * Custom menu item component for resetting column properties.
   * @param {Object} props - The properties passed to the component.
   * @param {string} props.myCustomLabel - The label for the menu item.
   * @param {Function} props.myCustomHandler - The handler function for the menu item.
   * @returns {JSX.Element} The custom menu item component.
   */
  function CustomResetProps(props) {
    const { myCustomLabel, myCustomHandler } = props
    return (
      <MenuItem style={{ padding: 2, opacity: 1 }} onClick={myCustomHandler}>
        <ListItemIcon style={{ minWidth: 36 }}>
          <RestartAltIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomLabel}</ListItemText>
      </MenuItem>
    )
  }

  /**
   * Custom column menu component for the data grid.
   * This component provides custom menu items for adapting the size of columns and resetting column properties.
   *
   * @param {Object} props - The properties passed to the component.
   * @param {Object} props.colDef - The column definition object.
   * @returns {JSX.Element} The custom column menu component.
   */
  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuAdaptSize: props.colDef.type === 'actions' ? CustomAdaptSize : null,
          columnMenuResetProps: props.colDef.type === 'actions' ? CustomResetProps : null,
        }}
        slotProps={{
          columnMenuAdaptSize: {
            field: props.colDef.field,
            displayOrder: 10,
            myCustomLabel: t('view.backlog.menu.align'),
            myCustomHandler: autosizingColumns,
          },
          columnMenuResetProps: {
            field: props.colDef.field,
            displayOrder: 10,
            myCustomLabel: t('view.backlog.menu.reset'),
            myCustomHandler: resetColumns,
          },
        }}
      />
    )
  }

  return (
    <div className={classes.tableCmp}>
      <Menu
        anchorEl={extractMenuAnchor}
        open={extraMenuOpen}
        onClose={handleCloseExtraMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={(event) => {
            handleCloseExtraMenu()
            handleDownloadReport(event, itemToExtract, false, dispatch)
          }}
        >
          {t('view.batch.information.normal')}
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            handleCloseExtraMenu()
            handleDownloadReport(event, itemToExtract, true, dispatch)
          }}
        >
          {t('view.batch.information.detail')}
        </MenuItem>
      </Menu>
      <ErrorBoundaryGuard>
        <StripedDataGridPro
          initialState={{
            ...defaultState,
            ...JSON.parse(localStorage.getItem('BACKLOG_state')),
          }}
          onStateChange={saveSnapshot}
          rowHeight={64}
          loading={isFetching}
          slots={{
            moreActionsIcon: CustomActionIcon,
            noRowsOverlay: CustomNoRowsOverlay,
            noResultsOverlay: CustomNoRowsOverlay,
            loadingOverlay: CustomLoading,
            columnMenu: CustomColumnMenu,
          }}
          apiRef={apiRef}
          getRowClassName={(params) => {
            let classesAdded = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            if (isLoading(params.row)) {
              classesAdded += ' loading'
            }
            return classesAdded
          }}
          getCellClassName={(params) => {
            return 'customCell'
          }}
          disableVirtualization={batches.length < 50}
          rowBuffer={3}
          rowThreshold={3}
          columnBuffer={3}
          columnThreshold={3}
          treeData
          rows={batches}
          columns={columns}
          getTreeDataPath={(row) => row.hierarchy}
          groupingColDef={{
            // headerName: (
            //   <SubdirectoryArrowRightIcon
            //     style={{ position: 'relative', fontSize: 20, top: 5, color: '#0000008A' }}
            //   />
            // ),
            renderHeader: (params) => (
              <SubdirectoryArrowRightIcon
                style={{ position: 'relative', fontSize: 20, color: '#0000008A' }}
              />
            ),
            hideDescendantCount: false,
            // valueFormatter: (value, object) => (object?.hierarchy?.length === 1 ? '' : '|'),
            renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
            width: 60,
            // maxWidth: 60,
            // minWidth: 60,
          }}
          pagination={false}
          hideFooter={true}
          style={{ border: 'none' }}
          // Do not use autoSize: conflic with initialState
          // autosizeOnMount
          // autosizeOptions={autoSizeOptions}
          disableRowSelectionOnClick
          onRowClick={(params, event, details) =>
            onClick(event, params.row, delta, props.history, dispatch)
          }
          // onResize={() => hideWatermark()} //TODO To be deleted after purchasing a license
        />
      </ErrorBoundaryGuard>
    </div>
  )
})
export default TableCmp
