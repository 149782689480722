// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import Title from '../Title'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { t } from 'i18next'
import { Tooltip } from '@material-ui/core'
import StorageIcon from '@material-ui/icons/Storage'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import NoteButton from '../common/NoteButton'
import Link from '@material-ui/core/Link/Link'
import { FLASK_URL } from '../../utils/config'
import { displayPopupMessage } from '../common/Message'
import { BootstrapTooltip, XLSFileInvert } from '../common/Style'
import CircularProgressCmp from '../common/CircularProgressCmp'
import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import IconButton from '@material-ui/core/IconButton'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { getBatchById } from '../../redux/batches/selectors'
import { alpha } from '@material-ui/core/styles'
import Button from '@mui/material/Button'
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart'
import Menu from '@mui/material/Menu'
import { handleDownloadReport } from '../backlog/Tools'
import MenuItem from '@mui/material/MenuItem'

const useStyles = makeStyles((theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '0px 0px 4px 4px',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
    '&:hover .collapsor': {
      opacity: 1,
      bottom: 2,
    },
  },
  progression: {
    padding: 12,
    paddingTop: 0,
  },
  chip: {
    marginRight: 5,
    marginBottom: 5,
    borderColor: 'inherit',
    '& > svg': {
      color: 'inherit',
      marginLeft: '8px !important',
    },
  },
  chipTooltip: {
    fontSize: 14,
  },
  flagInfo: {
    color: theme.palette.common.infoBlue,
    backgroundColor: alpha(theme.palette.common.infoBlue, 0.05),
  },
  flagWarning: {
    color: theme.palette.common.warning,
    backgroundColor: alpha(theme.palette.common.warning, 0.05),
  },
  flagError: {
    color: theme.palette.common.error,
    backgroundColor: alpha(theme.palette.common.error, 0.05),
  },
  divider: {
    paddingBottom: 12,
    paddingTop: 5,
  },
  priorityIcon: {
    top: 4,
    position: 'relative',
    marginRight: 10,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  collapsor: {
    '&.collapsed': { opacity: 1, bottom: 2 },
    '&.notCollapsed': { opacity: 0, bottom: 12 },
    background: 'white',
    width: 50,
    height: 10,
    left: 'calc(50% - 25px)',
    position: 'absolute',
    borderRadius: '0px 0px 15px 15px',
    transition: 'all 125ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    // boxShadow:
    //   '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  collapseButton: {
    width: 30,
    height: 30,
    padding: 0,
    bottom: -4,
    left: 'calc(50% - 15px)',
    position: 'absolute',
    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      background: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  reportButton: {
    padding: 0,
    top: -7,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  colorBlue: {
    color: theme.palette.primary.main,
  },
}))
const Information = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [collapsed, setCollapsed] = React.useState(
    localStorage.getItem('GLOBAL_information_collapsed')
      ? JSON.parse(localStorage.getItem('GLOBAL_information_collapsed')) === true
      : false,
  )
  const [extractMenuAnchor, setExtractMenuAnchor] = React.useState(null)
  const extraMenuOpen = Boolean(extractMenuAnchor)
  const batch = useSelector((state) => getBatchById(state.batches, props.match.params.batchId))

  const collapse = function () {
    localStorage.setItem('GLOBAL_information_collapsed', !collapsed)
    setCollapsed(!collapsed)
  }
  /**
   * Function to open the extract menu.
   * @param {Event} event - The event that triggered the function.
   * @param {Object} row - The data row representing a batch to extract.
   */
  const openExtractMenu = (event, row) => {
    setExtractMenuAnchor(event.currentTarget)
  }

  /**
   * Function to close the extract menu.
   */
  const handleCloseExtraMenu = () => {
    setExtractMenuAnchor(null)
  }

  return (
    <Fragment>
      <Menu
        anchorEl={extractMenuAnchor}
        open={extraMenuOpen}
        onClose={handleCloseExtraMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={(event) => {
            handleCloseExtraMenu()
            handleDownloadReport(event, batch, false, dispatch)
          }}
        >
          {t('view.batch.information.normal')}
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            handleCloseExtraMenu()
            handleDownloadReport(event, batch, true, dispatch)
          }}
        >
          {t('view.batch.information.detail')}
        </MenuItem>
      </Menu>
      <Paper
        elevation={0}
        className={classes.paper}
        style={{
          height: collapsed ? '10px' : '100%',
          paddingTop: collapsed ? '0px' : '10px',
          paddingBottom: 0,
        }}
      >
        <div style={{ opacity: collapsed ? 0 : 1 }}>
          <Title>
            <Grid container spacing={3} direction="row" justifyContent="space-between">
              <Grid item style={{ paddingBottom: 0, height: 50 }}>
                <Box>
                  {t('view.batch.information.batch')} {batch ? batch.name : ''}
                </Box>
              </Grid>
              <Tooltip
                arrow
                title={<div className={classes.tooltip}>{t('view.batch.information.device')}</div>}
              >
                <Grid item style={{ paddingBottom: 0, height: 50 }}>
                  {/*<AssignmentLateIcon className={classes.priorityIcon} />*/}
                  {/*<StorageIcon style={{ marginRight: 12, color: '#737373', height: 19 }} />*/}
                  <StorageIcon className={classes.priorityIcon} />
                  {batch.content.device}
                </Grid>
              </Tooltip>
              <Tooltip
                arrow
                title={
                  <div className={classes.tooltip}>{t('view.batch.information.priority')}</div>
                }
              >
                <Grid item style={{ paddingBottom: 0, height: 50 }}>
                  <AssignmentLateIcon className={classes.priorityIcon} />
                  {batch.content.priority}
                </Grid>
              </Tooltip>
              {/*<Grid item>{batch.content.due_date}</Grid>*/}
              {batch.content.due_date && batch.content.due_date !== '0000-00-00 00:00:00' && (
                <Grid item style={{ paddingBottom: 0, height: 50 }}>
                  {new Intl.DateTimeFormat('en-GB', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                  }).format(new Date(batch.content.due_date))}
                </Grid>
              )}
              <Grid item style={{ height: 43, width: 48, padding: 0, paddingTop: 7 }}>
                <ErrorBoundaryGuard isDialog>
                  <NoteButton
                    title
                    row={{
                      _id: batch._id,
                      notes: batch.content.notes ? batch.content.notes : [],
                      child_notes: batch.content.child_notes
                        ? batch.content.child_notes
                        : { calibrations: 0, analysis: 0 },
                    }}
                    collection={'batch'}
                    batchName={batch.name}
                  />
                </ErrorBoundaryGuard>
              </Grid>
              <Grid item style={{ height: 43, width: 48, padding: 0, paddingTop: 20 }}>
                <Tooltip
                  arrow
                  title={
                    <div
                      style={{
                        fontSize: 13,
                        padding: 5,
                      }}
                    >
                      {t('view.batch.information.extract')}
                    </div>
                  }
                >
                  <IconButton
                    aria-label="reportXLSX"
                    className={clsx(classes.reportButton)}
                    onClick={openExtractMenu}
                  >
                    <XLSFileInvert
                      style={{ marginRight: 8, width: 22, height: 22 }}
                      className={classes.colorBlue}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item style={{ height: 43 }}>
                <ErrorBoundaryGuard isDialog>
                  <CircularProgressCmp />
                </ErrorBoundaryGuard>
              </Grid>
              {batch.content.operator && (
                <Grid item style={{ paddingBottom: 0, height: 50 }}>
                  <Box>
                    <Badge color="secondary" badgeContent={null}>
                      <Avatar alt="Owner" /*src={user}*/>
                        {batch.content.operator &&
                          batch.content.operator[batch.content.operator.length - 1].name.substring(
                            0,
                            2,
                          )}
                      </Avatar>
                    </Badge>
                  </Box>
                </Grid>
              )}
              <Grid container style={{ padding: 12, paddingTop: 0 }}>
                {/*{batch.content.flags.length !== 0 && (*/}
                {/*  <Grid item xs={12} className={classes.divider}>*/}
                {/*    <Divider variant="middle" />*/}
                {/*  </Grid>*/}
                {/*)}*/}
                {batch.content.flags &&
                  batch.content.flags.map((row) => (
                    <BootstrapTooltip
                      title={row.reason}
                      className={classes.chipTooltip}
                      level={row.level}
                      key={`${batch._id}_${row.level}_${row.title}`}
                    >
                      <Chip
                        variant="outlined"
                        className={clsx(
                          classes.chip,
                          row.level === 'warning'
                            ? classes.flagWarning
                            : row.level === 'error'
                            ? classes.flagError
                            : classes.flagInfo,
                        )}
                        icon={
                          row.level === 'warning' ? (
                            <WarningIcon />
                          ) : row.level === 'error' ? (
                            <ErrorIcon />
                          ) : (
                            <InfoIcon />
                          )
                        }
                        label={row.title}
                      />
                    </BootstrapTooltip>
                  ))}
              </Grid>
            </Grid>
          </Title>
        </div>
        <div
          className={clsx(collapsed ? 'collapsed' : 'notCollapsed', 'collapsor', classes.collapsor)}
        >
          <div
            style={{
              position: 'absolute',
              width: 48,
              height: 5,
              top: -2,
              left: 1,
              background: 'white',
            }}
          ></div>
          <IconButton
            aria-label="collapse"
            onClick={collapse}
            className={classes.collapseButton}
            style={{ transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)' }}
          >
            <ExpandLessIcon />
          </IconButton>
        </div>
      </Paper>
    </Fragment>
  )
})
export default Information
