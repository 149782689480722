// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

/* eslint-disable max-len */
import React, { Fragment } from 'react'
import {
  fetchSaveUASettings,
  fetchUpdateAnalysePeak,
  fetchUpdateCompoundPeak,
  fetchUpdateMainChannel,
  fetchUpdateUAValidation,
  fetchUpdateCompoundISTD,
  fetchUnitaryAnalysesDistinct,
  fetchUnitaryCompoundDistinct,
  fetchUpdateGlobalPeak,
  fetchResetAnalysePeak,
  fetchResetCompoundPeak,
} from '../redux/unitaryAnalyses/actions'
import {
  fetchUpdateUCValidation,
  fetchUpdateISTD,
  fetchUnitaryCalibrationsDistinct,
} from '../redux/unitaryCalibrations/actions'
import {
  fetchChangeAnalysesType,
  fetchCreateSubBatch,
  fetchCreateTrainingBatch,
  fetchMoveSampleToSubBatch,
} from '../redux/batches/actions'
import Tooltip from '@material-ui/core/Tooltip'
import {
  API_URL,
  DATABASE,
  FLASK_BASE_URL,
  FLASK_URL,
  GET_SECONDARY_COLOR,
  SERVICE,
} from './config'
import { fetchAnalyses, fetchComputeAIAnalyse, fetchUpdateAnalyse } from '../redux/analyses/actions'
import { fetchBatch } from '../redux/batches/actions'
import { fetchAddUserMessage } from '../redux/assistant/actions'
import { fetchAddTask, fetchFinishTask } from '../redux/tasks/actions'
import { fetchDisplayJson } from '../redux/json/actions'
import { fetchChangeSettings } from '../redux/settings/actions'
import Chip from '@material-ui/core/Chip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Button from '@material-ui/core/Button'
import { displayPopupMessage, generateErrorPart } from '../view/common/Message'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import { styled } from '@mui/material/styles'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { alpha } from '@material-ui/core'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

/**************************** GET SERVER TIME DELTA ***************************/
/**
 * Saves the time delta between the client and server.
 * Fetches the current datetime from the server and calculates the delta with the client's datetime.
 * If the server response is not OK, it throws an error with the response details.
 * If the server response is OK and contains a valid datetime, it calculates the delta and dispatches it to the settings.
 * If an error occurs, it displays a popup message with the error details.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @returns {Promise<number>} The time delta between the client and server in seconds.
 */
export const saveDateTimeDelta = (dispatch) => {
  const clientDateBefore = new Date().getTime() / 1000
  let url = `${FLASK_URL()}/datetime`
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .then(async (response) => {
      // check for error response
      if (!response.ok) {
        const statusText = response.statusText
        const status = response.status
        const url = response.url
        return response.text().then((errorMessage) => {
          const error = new Error(`${statusText} : ${errorMessage}`)
          if (response.headers.get('content-type') === 'application/json') {
            error.stack = JSON.stringify(
              JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
              null,
              2,
            )
          } else {
            error.stack = new Error().stack
          }
          error.statusText = statusText
          error.status = status
          error.url = url
          throw error
        })
      }
      const dataTimeResp = await response.json()
      if (typeof dataTimeResp.datetime === 'number') {
        const clientDateAfter = new Date().getTime() / 1000
        const serverDate = dataTimeResp.datetime
        const clientDateMean = clientDateBefore + (clientDateAfter - clientDateBefore) / 2
        const delta = serverDate - clientDateMean
        dispatch(fetchChangeSettings('dateTimeDelta', delta))
        return delta
      } else {
        throw response
      }
    })
    .catch((error) => {
      if (error.status === 403) {
        dispatch(fetchChangeSettings('loginPopup', true))
      }
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.save_date_delta.title'),
        <Fragment>
          <div>{t('view.common.utils.save_date_delta.msg1')}</div>
          <div>{generateErrorPart(error)}</div>
          <div>{t('view.common.utils.save_date_delta.msg2')}</div>
        </Fragment>,
      )
    })
}

/**
 * Checks if the lease period has passed.
 * Compares the current date (adjusted by the time delta) with the server lease date.
 *
 * @param {number} serverLeaseDate - The lease date from the server in seconds.
 * @param {number} delta - The time delta between the client and server in seconds.
 * @returns {boolean} True if the current date plus delta is greater than the server lease date, otherwise false.
 */
export const isLeasePassed = (serverLeaseDate, delta) => {
  const currentDate = new Date().getTime() / 1000
  return currentDate + delta > serverLeaseDate
}
/**************************** URL NAVIGATION **********************************/
/**
 * Navigates to a specified URL.
 * Replaces the current history entry with a reload URL, then navigates to the specified URL after a short delay.
 *
 * @param {string} url - The URL to navigate to.
 * @param {object} history - The history object used for navigation.
 */
export const goTo = (url, history) => {
  history.replace(`/reload`)
  setTimeout(() => {
    history.replace(url)
  })
}

/**************************** Retrieve current batch **********************************/
/**
 * Checks if the data has already been retrieved.
 * Constructs a filter to exclude tasks with specific tags and statuses, then fetches the tasks from the server.
 *
 * @returns {Promise<object>} The response from the server containing the tasks.
 */
export const isAlreadyRetrieve = async () => {
  let filter = [
    {
      property: 'tags',
      value: localStorage.getItem('__config.json__ALPHACOD_TAG'),
      filterType: 'string',
      operator: 'like',
    },
    {
      property: 'status',
      value: 'FINISHED',
      filterType: 'string',
      operator: 'ne',
    },
    {
      property: 'status',
      value: 'FAILED',
      filterType: 'string',
      operator: 'ne',
    },
  ]
  let v = encodeURIComponent(JSON.stringify(filter))
  const responseVal = await fetch(`${API_URL()}/tasks/page/?filter=${v}`, {
    method: 'GET',
    credentials: 'include',
  })
  // const authenticationError = response.status === 403
  // if (authenticationError) {
  //   return dispatch(fetchChangeSettings('loginPopup', true))
  // }
  const resp = await responseVal.json()
  return resp
}

/**
 * Retrieves batches from the server.
 * Displays a popup message with information about the retrieval process.
 * Constructs the URL for fetching the batches and dispatches the fetch action.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} batch - The batch object.
 * @param {string} option - The option for retrieval.
 * @param {string} screening_id - The screening ID.
 * @param {string} configurationProfile_name - The configuration profile name.
 * @returns {Promise<void>} A promise that resolves when the batches are retrieved.
 */
export const retrieveBatches = (
  dispatch,
  batch,
  option,
  screening_id,
  configurationProfile_name,
) => {
  // If batch, then pcd_analyze_scandir application else pcd_analyze_scandate application
  const taskId = new Date().getTime() + '_retrieve_current_batch'
  const task = {
    id: taskId,
    batchId: batch ? batch._id : null,
    title: batch
      ? t('view.common.utils.retrieve_batches.title_update')
      : t('view.common.utils.retrieve_batches.title_retrieve'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: batch ? 'update_batch' : 'retrieve_batches',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: batch
      ? t('view.common.utils.retrieve_batches.operation_update')
      : t('view.common.utils.retrieve_batches.operation_retrieve'),
    items: [],
  }
  // dispatch(fetchAddTask(task))
  displayPopupMessage(
    dispatch,
    'info',
    batch
      ? t('view.common.utils.retrieve_batches.title_update')
      : t('view.common.utils.retrieve_batches.title_retrieve'),
    <Fragment>
      {!batch && <div>{t('view.common.utils.retrieve_batches.info_retrieve')}</div>}
      {batch && (
        <div>
          <Trans i18nKey="view.common.utils.retrieve_batches.info_update">
            You will update the batch <i>{{ val: batch.name }}</i>.
          </Trans>
        </div>
      )}
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.retrieve_batches.take_time')}</i>
      </div>
    </Fragment>,
  )

  let url = `${API_URL()}/applications/page/`
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .then(async (response) => {
      if (!response.ok) {
        displayPopupMessage(
          dispatch,
          'error',
          batch
            ? t('view.common.utils.retrieve_batches.title_update')
            : t('view.common.utils.retrieve_batches.title_retrieve'),
          <Fragment>
            <div>{t('view.common.utils.retrieve_batches.error_msg1')}</div>
            <div>{t('view.common.utils.retrieve_batches.error_msg2')}</div>
          </Fragment>,
        )
        const authenticationError = response.status === 403
        if (authenticationError) {
          dispatch(fetchChangeSettings('loginPopup', true))
          throw new Error(t('view.common.utils.retrieve_batches.not_logged'))
        }
        let json = response.text()
        return json.then(Promise.reject.bind(Promise))
      }
      const resp = await response.json()
      const applicationName = batch
        ? localStorage.getItem('__config.json__PCD_DIR_APPLICATION_NAME')
        : localStorage.getItem('__config.json__PCD_APPLICATION_NAME')
      const application = resp.result.filter((app) => app.name === applicationName)[0]
      if (application) {
        let url = `${API_URL()}/tasks/new?&application=${application._id}&create=true`
        return fetch(url, {
          method: 'GET',
          credentials: 'include',
        }).then(async (response) => {
          if (!response.ok) {
            displayPopupMessage(
              dispatch,
              'error',
              batch
                ? t('view.common.utils.retrieve_batches.title_update')
                : t('view.common.utils.retrieve_batches.title_retrieve'),
              <Fragment>
                <div>
                  <Trans i18nKey="view.common.utils.retrieve_batches.error_msg3">
                    Cannot create a new task from the application <i>{{ val: application.name }}</i>
                    .
                  </Trans>
                </div>
                <div>{t('view.common.utils.retrieve_batches.error_msg2')}</div>
              </Fragment>,
            )
            const authenticationError = response.status === 403
            if (authenticationError) {
              dispatch(fetchChangeSettings('loginPopup', true))
              throw new Error("You're not logged.")
            }
            // dispatch(
            //   fetchFinishTask(
            //     taskId,
            //     'error',
            //     `Cannot create a new task from the application ${application.name}`,
            //   ),
            // )
            let json = response.text()
            return json.then(Promise.reject.bind(Promise))
          }
          const task = await response.json()
          if (task) {
            let taskUpdated = task
            if (batch) {
              let mode = taskUpdated.inputs.filter((input) => input.name === 'mode')[0]
              if (mode) mode['value'] = option
              let screeningId = taskUpdated.inputs.filter(
                (input) => input.name === 'screening_id',
              )[0]
              if (screeningId) screeningId['value'] = screening_id
              let batchName = taskUpdated.inputs.filter((input) => input.name === 'batch_name')[0]
              if (batchName) batchName['value'] = batch.name
              let batchId = taskUpdated.inputs.filter((input) => input.name === 'batch_id')[0]
              if (batchId) batchId['value'] = batch._id
              let scanDir = taskUpdated.inputs.filter((input) => input.name === 'scan_dir')[0]
              if (scanDir) scanDir['value'] = batch.org_path ? batch.org_path : batch.path
              let batchDevice = taskUpdated.inputs.filter(
                (input) => input.name === 'batch_device',
              )[0]
              if (batchDevice) batchDevice['value'] = batch.device
              let batchDate = taskUpdated.inputs.filter((input) => input.name === 'batch_date')[0]
              if (batchDate) batchDate['value'] = formatDate(parseFloat(batch.batchDate) * 1000)
              let batchType = taskUpdated.inputs.filter((input) => input.name === 'batch_type')[0]
              if (batchType) batchType['value'] = batch.chromatoType
              let batchConfiguration = taskUpdated.inputs.filter(
                (input) => input.name === 'configuration',
              )[0]
              if (batchConfiguration) batchConfiguration['value'] = configurationProfile_name
            } else {
              let scanDate = taskUpdated.inputs.filter((input) => input.name === 'scan_date')[0]
              if (scanDate) scanDate['value'] = formatDate(new Date().getTime())
            }
            let url = `${API_URL()}/tasks/validate`
            const data = {
              save: true,
              previousTask: task,
              task: taskUpdated,
            }
            return fetch(url, {
              method: 'POST',
              credentials: 'include',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data),
            }).then(async (response) => {
              if (!response.ok) {
                displayPopupMessage(
                  dispatch,
                  'error',
                  batch
                    ? t('view.common.utils.retrieve_batches.title_update')
                    : t('view.common.utils.retrieve_batches.title_retrieve'),
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.common.utils.retrieve_batches.error_msg4">
                        Cannot validate the task <i>{{ val1: task.name }}</i> (num:
                        {{ val2: task.num }}).
                      </Trans>
                    </div>
                    <div>{t('view.common.utils.retrieve_batches.error_msg2')}</div>
                  </Fragment>,
                )
                const authenticationError = response.status === 403
                if (authenticationError) {
                  dispatch(fetchChangeSettings('loginPopup', true))
                  throw new Error(t('view.common.utils.retrieve_batches.not_logged'))
                }
                // dispatch(
                //   fetchFinishTask(
                //     taskId,
                //     'error',
                //     `Cannot validate the task ${task.name} (num: ${task.num})`,
                //   ),
                // )
                let json = response.text()
                return json.then(Promise.reject.bind(Promise))
              }
              const validatedTask = await response.json()
              if (validatedTask) {
                let url = `${API_URL()}/tasks/submit?taskId=${validatedTask._id}`
                return fetch(url, {
                  method: 'POST',
                  credentials: 'include',
                  headers: { 'Content-Type': 'application/json' },
                }).then(async (response) => {
                  if (!response.ok) {
                    displayPopupMessage(
                      dispatch,
                      'error',
                      batch
                        ? t('view.common.utils.retrieve_batches.title_update')
                        : t('view.common.utils.retrieve_batches.title_retrieve'),
                      <Fragment>
                        <div>
                          <Trans i18nKey="view.common.utils.retrieve_batches.error_msg5">
                            Cannot submit the task <i>{{ val1: task.name }}</i> (num:
                            {{ val2: task.num }}).
                          </Trans>
                        </div>
                        <div>{t('view.common.utils.retrieve_batches.error_msg2')}</div>
                      </Fragment>,
                    )
                    const authenticationError = response.status === 403
                    if (authenticationError) {
                      dispatch(fetchChangeSettings('loginPopup', true))
                      throw new Error(t('view.common.utils.retrieve_batches.not_logged'))
                    }
                    // dispatch(
                    //   fetchFinishTask(
                    //     taskId,
                    //     'error',
                    //     `Cannot submit the task ${task.name} (num: ${task.num})`,
                    //   ),
                    // )
                    let json = response.text()
                    return json.then(Promise.reject.bind(Promise))
                  }
                  const submittedTask = (await response.json())[0]
                  if (submittedTask) {
                    displayPopupMessage(
                      dispatch,
                      'success',
                      batch
                        ? t('view.common.utils.retrieve_batches.title_update')
                        : t('view.common.utils.retrieve_batches.title_retrieve'),
                      <Fragment>
                        <div>{t('view.common.utils.retrieve_batches.success_msg1')}</div>
                        <div>
                          <Trans i18nKey="view.common.utils.retrieve_batches.success_msg2">
                            The task is named <i>{{ val1: submittedTask.name }}</i> with num
                            <i>{{ val2: submittedTask.num }}</i>.
                          </Trans>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <i>{t('view.common.utils.retrieve_batches.success_msg3')}</i>
                        </div>
                      </Fragment>,
                    )
                    // dispatch(fetchFinishTask(taskId, 'success', 'Everything is fine'))
                    return submittedTask
                  } else {
                    // dispatch(
                    //   fetchFinishTask(
                    //     taskId,
                    //     'error',
                    //     `Cannot submit the validated task ${task.name} (num: ${task.num})`,
                    //   ),
                    // )
                    return Promise.reject.bind(Promise)(
                      <Trans i18nKey="view.common.utils.retrieve_batches.error_msg6">
                        Cannot submit the validated task {{ val1: task.name }} (num:
                        {{ val2: task.num }}).
                      </Trans>,
                    )
                  }
                })
              } else {
                // dispatch(
                //   fetchFinishTask(
                //     taskId,
                //     'error',
                //     `Cannot validate the task ${task.name} (num: ${task.num})`,
                //   ),
                // )
                return Promise.reject.bind(Promise)(
                  <Trans i18nKey="view.common.utils.retrieve_batches.error_msg7">
                    Cannot validate the task {{ val1: task.name }} (num: {{ val2: task.num }}).
                  </Trans>,
                )
              }
            })
          } else {
            // dispatch(
            //   fetchFinishTask(taskId, 'error', `Cannot create a task from ${applicationName}.`),
            // )
            return Promise.reject.bind(Promise)(
              <Trans i18nKey="view.common.utils.retrieve_batches.error_msg8">
                Cannot create a task from {{ val: applicationName }}.
              </Trans>,
            )
          }
        })
      } else {
        // dispatch(
        //   fetchFinishTask(taskId, 'error', `Cannot get the application named ${applicationName}.`),
        // )
        return Promise.reject.bind(Promise)(
          <Trans i18nKey="view.common.utils.retrieve_batches.error_msg9">
            Cannot get the application named {{ val: applicationName }}.
          </Trans>,
        )
      }
    })
    .catch(function (error) {
      // dispatch(fetchFinishTask(taskId, 'error', error.toString()))
      displayPopupMessage(
        dispatch,
        'error',
        batch
          ? t('view.common.utils.retrieve_batches.title_update')
          : t('view.common.utils.retrieve_batches.title_retrieve'),
        <Fragment>
          {batch && (
            <div>
              <Trans i18nKey="view.common.utils.retrieve_batches.error_msg10">
                Cannot update the batch <i>{{ val: batch.name }}</i>
              </Trans>
            </div>
          )}
          {!batch && <div>{t('view.common.utils.retrieve_batches.error_msg11')}</div>}
          <div>{error.toString()}</div>
          <div>{t('view.common.utils.retrieve_batches.error_msg2')}</div>
        </Fragment>,
      )
    })
}

/**************************** TRAINING BATCH FUNCTION **********************************/
/**
 * Builds a training batch.
 * Creates a task for building the training batch and dispatches the action to create the training batch.
 * Displays a popup message with information about the process.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} batch - The batch object.
 * @param {string} trainingBatchName - The name of the training batch.
 */
export const buildATrainingBatch = (dispatch, batch, trainingBatchName) => {
  const taskId = new Date().getTime() + '_build_training_batch_' + batch._id
  const task = {
    id: taskId,
    title: t('view.common.utils.training_batch.title'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'build_batch',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: (
      <Trans i18nKey="view.common.utils.training_batch.operation">
        build a new batch {{ val: trainingBatchName }}
      </Trans>
    ),
    items: [
      {
        id: batch._id,
        name: batch.name,
      },
    ],
  }
  dispatch(fetchAddTask(task))
  displayPopupMessage(
    dispatch,
    'info',
    t('view.common.utils.training_batch.title'),
    <Fragment>
      <div>{t('view.common.utils.training_batch.info_msg1')}</div>
      <Chip variant="outlined" size="small" style={{ margin: 3 }} label={batch.name} />
      <div>
        <Trans i18nKey="view.common.utils.training_batch.named">
          named: {{ val: trainingBatchName }}
        </Trans>
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.training_batch.time')}</i>
      </div>
    </Fragment>,
  )
  dispatch(fetchCreateTrainingBatch(batch._id, trainingBatchName))
    .then(function (resp) {
      dispatch(fetchFinishTask(taskId, 'success', 'Everything is fine'))
      displayPopupMessage(
        dispatch,
        'success',
        t('view.common.utils.training_batch.title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.training_batch.created">
              The training batch from <i>{{ val: batch.name }}</i> is correctly created :
            </Trans>
          </div>
          <div>
            <Tooltip
              key={'go-training'}
              arrow
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  {t('view.common.utils.training_batch.tooltip')}
                </div>
              }
            >
              <Button
                variant="outlined"
                style={{
                  borderRadius: 16,
                  padding: '0px 8px',
                  fontWeight: 400,
                  textTransform: 'none',
                  fontSize: '0.8125rem',
                }}
                onClick={() => {
                  openInNewTab(
                    `${window.location.origin}${window.location.pathname}?${DATABASE()}/batch/${
                      resp.trainingBatch._id
                    }`,
                  )
                }}
              >
                {resp.trainingBatch.name}
              </Button>
            </Tooltip>
          </div>
          <div style={{ marginTop: 10 }}>
            <InfoOutlinedIcon />
            <i style={{ position: 'absolute', marginLeft: 4, marginTop: 4 }}>
              {t('view.common.utils.training_batch.click')}
            </i>
          </div>
        </Fragment>,
      )
    })
    .catch(function (error) {
      dispatch(fetchFinishTask(taskId, 'error', error))
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.training_batch.title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.training_batch.error">
              Can not build a training batch from <i>{{ val: batch.name }}</i>
            </Trans>
          </div>
          {generateErrorPart(error)}
        </Fragment>,
      )
    })
}

/**************************** UA PARAMETERS FUNCTION **********************************/
/**
 * Saves the Unitary Analysis (UA) parameters.
 * Dispatches an action to save the UA settings with the provided settings information, batch ID, and analysis ID.
 * Displays a popup message indicating the success or failure of the operation.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} settings - The settings object containing the UA parameters.
 * @param {object} settingsInfo - The information about the settings.
 * @param {string} batchId - The ID of the batch.
 * @param {string} analysisId - The ID of the analysis.
 */
export const saveUaParameters = (dispatch, settings, settingsInfo, batchId, analysisId) => {
  dispatch(fetchSaveUASettings(settingsInfo.id, settings, 'unitary_analysis', batchId, analysisId))
    .then(function (resp) {
      displayPopupMessage(
        dispatch,
        'success',
        t('view.common.utils.ua_param.title'),
        <Trans i18nKey="view.common.utils.ua_param.success">
          The settings is now updated for '{{ val: settingsInfo.name }}'
        </Trans>,
      )
    })
    .catch(function (error) {
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.ua_param.title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.ua_param.error2">
              Can not update settings for {{ val: settingsInfo.name }}:
            </Trans>
          </div>
          <div>{generateErrorPart(error)}</div>
        </Fragment>,
      )
    })
}

/**************************** RESET UNITARY ANALYSES **********************************/
/**
 * Resets the unitary analyses.
 * Creates a task for resetting the unitary analyses and dispatches the action to reset the analyses.
 * Displays a popup message with information about the process.
 *
 * @param {object} event - The event object.
 * @param {Array} uas - The array of unitary analyses to reset.
 * @param {function} dispatch - The Redux dispatch function.
 */
export const resetUnitaryAnalyses = (event, uas, dispatch) => {
  const taskId = new Date().getTime() + '_reset_uas_' + uas[0]._id
  const multi = uas.length > 1
  const task = {
    id: taskId,
    title: (
      <Trans i18nKey="view.common.utils.reset_ua.title">
        Reset unitary
        {{
          val: multi
            ? t('view.common.utils.reset_ua.analyses')
            : t('view.common.utils.reset_ua.analysis'),
        }}
      </Trans>
    ),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'reset_ua',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: t('view.common.utils.reset_ua.operation'),
    items: uas.map((unitaryAnalysis) => ({
      id: unitaryAnalysis._id,
      type: unitaryAnalysis.type,
      name: unitaryAnalysis.name,
      analysis: {
        id: unitaryAnalysis.analyseId,
        type: unitaryAnalysis.analyseType,
        name: unitaryAnalysis.analyse,
      },
      batch: {
        id: unitaryAnalysis.batchId,
        name: unitaryAnalysis.batch_name,
      },
    })),
  }
  dispatch(fetchAddTask(task))
  displayPopupMessage(
    dispatch,
    'info',
    <Trans i18nKey="view.common.utils.reset_ua.title">
      Reset unitary
      {{
        val: multi
          ? t('view.common.utils.reset_ua.analyses')
          : t('view.common.utils.reset_ua.analysis'),
      }}
    </Trans>,
    <Fragment>
      <Trans i18nKey="view.common.utils.reset_ua.info">
        You will reset the unitary
        {{
          val: multi
            ? t('view.common.utils.reset_ua.analyses')
            : t('view.common.utils.reset_ua.analysis'),
        }}
        :
      </Trans>
      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
        {uas.map((item) => (
          <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
        ))}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.reset_ua.time')}</i>
      </div>
    </Fragment>,
  )
  //TODO call REST url
  dispatch(fetchFinishTask(taskId, 'error', t('view.common.utils.reset_ua.not_exist')))
  displayPopupMessage(
    dispatch,
    'warning',
    <Trans i18nKey="view.common.utils.reset_ua.title">
      Reset unitary
      {{
        val: multi
          ? t('view.common.utils.reset_ua.analyses')
          : t('view.common.utils.reset_ua.analysis'),
      }}
    </Trans>,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.reset_ua.reset">
          The reset for unitary
          {{
            val: multi
              ? t('view.common.utils.reset_ua.analyses')
              : t('view.common.utils.reset_ua.analysis'),
          }}
          :
        </Trans>
        <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
          {uas.map((item) => (
            <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
          ))}
        </div>
        {t('view.common.utils.reset_ua.cannot')}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.reset_ua.rest')}</i>
      </div>
    </Fragment>,
  )
}

/**************************** RESET UNITARY CALIBRATION **********************************/
/**
 * Resets the unitary calibration.
 * Creates a task for resetting the unitary calibration and dispatches the action to reset the calibration.
 * Displays a popup message with information about the process.
 *
 * @param {object} event - The event object.
 * @param {Array} ucs - The array of unitary calibrations to reset.
 * @param {function} dispatch - The Redux dispatch function.
 */
export const resetUnitaryCalibration = (event, ucs, dispatch) => {
  const taskId = new Date().getTime() + '_reset_uas_' + ucs[0]._id
  const multi = ucs.length > 1
  const task = {
    id: taskId,
    title: (
      <Trans i18nKey="view.common.utils.reset_cal.title">
        Reset unitary
        {{
          val: multi
            ? t('view.common.utils.reset_cal.calibrations')
            : t('view.common.utils.reset_cal.calibration'),
        }}
      </Trans>
    ),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'reset_uc',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: t('view.common.utils.reset_cal.operation'),
    items: ucs.map(function (unitaryCalibration) {
      return {
        id: unitaryCalibration._id,
        type: unitaryCalibration.analyseType,
        name: unitaryCalibration.name,
        batch: {
          id: unitaryCalibration.batchId,
          name: unitaryCalibration.batchName,
        },
      }
    }),
  }
  dispatch(fetchAddTask(task))
  displayPopupMessage(
    dispatch,
    'info',
    <Trans i18nKey="view.common.utils.reset_cal.title">
      Reset unitary
      {{
        val: multi
          ? t('view.common.utils.reset_cal.calibrations')
          : t('view.common.utils.reset_cal.calibration'),
      }}
    </Trans>,
    <Fragment>
      <Trans i18nKey="view.common.utils.reset_ua.info">
        You will reset the unitary
        {{
          val: multi
            ? t('view.common.utils.reset_cal.calibrations')
            : t('view.common.utils.reset_cal.calibration'),
        }}
        :
      </Trans>
      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
        {ucs.map((item) => (
          <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
        ))}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.reset_cal.time')}</i>
      </div>
    </Fragment>,
  )
  //TODO call REST url
  dispatch(fetchFinishTask(taskId, 'error', t('view.common.utils.reset_cal.not_exist')))
  displayPopupMessage(
    dispatch,
    'warning',
    <Trans i18nKey="view.common.utils.reset_cal.title">
      Reset unitary
      {{
        val: multi
          ? t('view.common.utils.reset_cal.calibrations')
          : t('view.common.utils.reset_cal.calibration'),
      }}
    </Trans>,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.reset_cal.reset">
          The reset for unitary
          {{
            val: multi
              ? t('view.common.utils.reset_cal.calibrations')
              : t('view.common.utils.reset_cal.calibration'),
          }}
          :
        </Trans>
        <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
          {ucs.map((item) => (
            <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
          ))}
        </div>
        {t('view.common.utils.reset_cal.cannot')}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.reset_cal.rest')}</i>
      </div>
    </Fragment>,
  )
}

/**************************** VALIDATION FUNCTION **********************************/

/**
 * Handles the validation of a unitary analysis.
 * Stops the propagation of the event and prevents the default action.
 * Determines the new validation state based on the current validation state and the provided validation value.
 * Dispatches the validation update to the appropriate collection.
 *
 * @param {object} event - The event object.
 * @param {object} unitaryAnalysis - The unitary analysis object.
 * @param {string} validation - The new validation value.
 * @param {string} collection - The collection to which the unitary analysis belongs.
 * @param {function} dispatch - The Redux dispatch function.
 */
export const handleValidation = (event, unitaryAnalysis, validation, collection, dispatch) => {
  event.stopPropagation()
  const oldValidation = unitaryAnalysis.validation
  let newValidation = null
  let validationName = ''
  switch (oldValidation) {
    case '1':
      newValidation = validation === '1' ? '0' : validation
      break
    case '2':
      newValidation = validation === '2' ? '0' : validation
      break
    case '3':
      newValidation = validation === '3' ? '0' : validation
      break
    case '0':
    default:
      newValidation = validation
      break
  }
  switch (newValidation) {
    case '1':
      validationName = unitaryAnalysis.analyseType === 'sample' ? 'Detected' : 'OK'
      break
    case '2':
      validationName = unitaryAnalysis.analyseType === 'sample' ? 'Not detected' : 'KO'
      break
    case '3':
      validationName = 'Relance'
      break
    case '0':
    default:
      validationName = 'None'
      break
  }

  let data = {}
  data['value'] = newValidation
  callValidationDispatch(
    collection,
    dispatch,
    data,
    validationName,
    unitaryAnalysis,
    unitaryAnalysis.batchId,
  )
}

/**
 * Dispatches the validation update to the appropriate collection.
 * Handles the dispatching of validation updates for unitary analyses, compounds, or calibrations.
 * Constructs the task and dispatches the appropriate action based on the collection type.
 *
 * @param {string} collection - The collection to which the unitary analysis belongs.
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} data - The data object containing the validation information.
 * @param {string} validationName - The name of the validation state.
 * @param {object} unitaryAnalysis - The unitary analysis object.
 * @param {string} batchId - The ID of the batch.
 * @param {string} selectionType - The type of selection.
 */
export const callValidationDispatch = (
  collection,
  dispatch,
  data,
  validationName,
  unitaryAnalysis,
  batchId,
  selectionType,
) => {
  const multiAnalyse = !unitaryAnalysis
  let originalData = data
  if (multiAnalyse) {
    const result = {}
    data.forEach((ua) => (result[ua._id] = validationName))
    data = result

    switch (validationName) {
      case 1:
        validationName =
          selectionType && selectionType.length === 2
            ? 'Detected / OK'
            : originalData[0].analyseType === 'sample'
            ? 'Detected'
            : 'OK'
        break
      case 2:
        validationName =
          selectionType && selectionType.length === 2
            ? 'Not detected / KO'
            : originalData[0].analyseType === 'sample'
            ? 'Not detected'
            : 'KO'
        break
      case 3:
        validationName = 'Relance'
        break
      case 0:
      default:
        validationName = 'No validation'
        break
    }
  } else {
    originalData = [unitaryAnalysis]
  }

  const taskId = new Date().getTime() + '_validation_' + originalData[0]._id
  switch (collection) {
    case 'unitary_compound':
    case 'unitary_analysis':
      const task = {
        id: taskId,
        title: (
          <Trans i18nKey="view.common.utils.validation.title">
            {{
              val:
                collection === 'unitary_compound'
                  ? t('view.common.utils.validation.compound')
                  : t('view.common.utils.validation.analysis'),
            }}
            validation
          </Trans>
        ),
        operator: localStorage.getItem('SESSION_user_name'),
        date: new Date().getTime(),
        action: 'validation',
        percentage: 50,
        state: { value: 'running', message: '' },
        operation: validationName,
        items: originalData.map((orData) => ({
          id: orData._id,
          type: collection === 'unitary_compound' ? 'compound' : 'analysis',
          name: orData.name,
          analysis: {
            id: orData.analyseId,
            type: orData.analysetype,
            name: orData.analyse,
          },
          batch: {
            id: orData.batchId,
            name: orData.batch_name,
          },
        })),
      }
      dispatch(fetchAddTask(task))

      dispatch(fetchUpdateUAValidation(data, collection, unitaryAnalysis, multiAnalyse, batchId))
        .then(function (resp) {
          // Update progress component by reload analyses
          dispatch(fetchAnalyses(batchId))
          if (collection === 'unitary_analysis') {
            dispatch(
              fetchUnitaryAnalysesDistinct(
                multiAnalyse
                  ? resp.objectsUpdated.uas[0].content.analysis.id
                  : unitaryAnalysis.analyseId,
                JSON.parse(localStorage.getItem('ANALYSIS_filter_requested')) === true,
              ),
            )
          } else {
            dispatch(
              fetchUnitaryCompoundDistinct(
                batchId,
                multiAnalyse ? resp.objectsUpdated.uas[0].content.event : unitaryAnalysis.event,
              ),
            )
          }
          // Don't want to see the confirm message...
          // sendValidationResultMessage(
          //   dispatch,
          //   resp ? resp.type : null,
          //   data,
          //   unitaryAnalysis,
          //   multiAnalyse,
          //   collection,
          // )
          dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.validation.fine')))
        })
        .catch(function (error) {
          if (error && error.status !== undefined && error.status === 401) {
            error.statusText = t('view.common.utils.lease_locked.code')
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.common.utils.validation.update_title'),
              <Fragment>
                <div>{t('view.common.utils.validation.update_msg')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          } else {
            sendValidationResultMessage(dispatch, null, error, unitaryAnalysis)
          }
          dispatch(fetchFinishTask(taskId, 'error', error))
        })
      break
    case 'unitary_calibration':
      const taskCalib = {
        id: taskId,
        title: t('view.common.utils.validation.calib_valid'),
        operator: localStorage.getItem('SESSION_user_name'),
        date: new Date().getTime(),
        percentage: 50,
        state: { value: 'running', message: '' },
        operation: validationName,
        items: originalData.map((orData) => ({
          id: orData._id,
          type: 'calibration',
          name: orData.name,
          analysis: {
            id: orData.analyseId,
            type: orData.analysetype,
            name: orData.analyse,
          },
          batch: {
            id: orData.batchId,
            name: orData.batch_name,
          },
        })),
      }
      dispatch(fetchAddTask(taskCalib))
      // dispatch(fetchUpdateUCValidation(data, unitaryAnalysis, multiAnalyse, batchId))
      //   .then(function (resp) {
      //     // Update progress component by reload batches
      //
      //     // Update: do not download all batches, only the current one
      //     // dispatch(fetchBatches())
      //     dispatch(fetchBatch(batchId))
      //
      //     dispatch(fetchUnitaryCalibrationsDistinct(batchId))
      //     // Don't want to see the confirm message...
      //     // sendValidationResultMessage(
      //     //   dispatch,
      //     //   resp ? resp.type : null,
      //     //   data,
      //     //   unitaryAnalysis,
      //     //   multiAnalyse,
      //     //   collection,
      //     // )
      //     dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.validation.fine')))
      //   })
      //   .catch(function (error) {
      //     dispatch(fetchFinishTask(taskId, 'error', error.toString()))
      //
      //     if (error && error.code !== undefined && error.code === 554) {
      //       displayPopupMessage(
      //         dispatch,
      //         'warning',
      //         t('view.common.utils.validation.update_title'),
      //         <Fragment>
      //           <div>{t('view.common.utils.validation.update_msg')}</div>
      //           <div style={{ whiteSpace: 'pre-line' }}>{error.message}</div>
      //         </Fragment>,
      //       )
      //     } else {
      //       sendValidationResultMessage(dispatch, null, error, unitaryAnalysis)
      //     }
      //   })
      dispatch(fetchUpdateUCValidation(data, unitaryAnalysis, multiAnalyse, batchId))
        .then(function (resp) {
          // Update progress component by reload batches

          // Update: do not download all batches, only the current one
          // dispatch(fetchBatches())
          dispatch(fetchBatch(batchId))

          dispatch(fetchUnitaryCalibrationsDistinct(batchId))
          // Don't want to see the confirm message...
          // sendValidationResultMessage(
          //   dispatch,
          //   resp ? resp.type : null,
          //   data,
          //   unitaryAnalysis,
          //   multiAnalyse,
          //   collection,
          // )
          dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.validation.fine')))
        })
        .catch(function (error) {
          dispatch(fetchFinishTask(taskId, 'error', error))

          if (error && error.status !== undefined && error.status === 401) {
            error.statusText = t('view.common.utils.lease_locked.code')
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.common.utils.validation.update_title'),
              <Fragment>
                <div>{t('view.common.utils.validation.update_msg')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          } else {
            sendValidationResultMessage(dispatch, null, error, unitaryAnalysis)
          }
        })
      break
    default:
      break
  }
  const sendValidationResultMessage = (
    dispatch,
    type,
    data,
    unitaryAnalysis,
    multiAnalyse,
    collection,
  ) => {
    switch (type) {
      case 'RECEIVE_UPDATE_UA_VALIDATION':
      case 'RECEIVE_UPDATE_UC_VALIDATION':
        if (multiAnalyse) {
          displayPopupMessage(
            dispatch,
            'success',
            t('view.common.utils.validation.update_title'),
            <Trans i18nKey="view.common.utils.validation.confirm_update">
              The current selection is now updated to '{{ val: validationName }}'
            </Trans>,
          )
        } else {
          let itemName = ''
          if (collection === 'unitary_analysis') {
            itemName = unitaryAnalysis.peakName.name
          }
          if (collection === 'unitary_compound') {
            itemName = unitaryAnalysis.analyse
          }
          if (collection === 'unitary_calibration') {
            itemName = unitaryAnalysis.name
          }
          displayPopupMessage(
            dispatch,
            'success',
            t('view.common.utils.validation.update_title'),
            <Trans i18nKey="view.common.utils.validation.valid_update">
              The validation of '{{ val1: itemName }}' is updated to '{{ val2: validationName }}'
            </Trans>,
          )
        }
        break
      default:
        // TODO get the good error message
        // const error = 'error'
        displayPopupMessage(
          dispatch,
          'error',
          t('view.common.utils.validation.update_title'),
          <Fragment>
            <div>{t('view.common.utils.validation.update_msg')}</div>
            {/*<div>{JSON.parse(data.toString().replace('Error: {','{'))}</div>*/}
            {generateErrorPart(data)}
          </Fragment>,
        )
    }
  }
  // dispatch(fetchUpdateValidation(data, unitaryAnalysis._id, objectCollection, multiAnalyse))

  // const requestOptions = {
  //   method: 'PUT',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify(data),
  // }
  // const multipleTreatment = !unitaryAnalysis
  // const objectCollection = collection === 'unitary_compound' ? 'unitary_analysis' : collection
  // let url
  // if (multipleTreatment) {
  //   url = `${FLASK_URL}/${objectCollection}/validate`
  // } else {
  //   url = `${FLASK_URL}/${objectCollection}/${unitaryAnalysis._id}/validate`
  // }
  // return fetch(url, requestOptions)
  //   .then(async (response) => {
  //     // check for error response
  //     if (!response.ok) {
  //       let json = response.text()
  //       return json.then(Promise.reject.bind(Promise))
  //     }
  //     if (multipleTreatment) {
  //       // TODO update all UA from multiple treatment
  //       const validationMap = {
  //         '1': 'Detected',
  //         '2': 'Excluded',
  //         '3': 'Relance',
  //       }
  //       displayPopupMessage(
  // dispatch,
  //           'success',
  //           'Validation update',
  //           `The current selection is now updated to '${validationMap[validationName]}'`,
  //         )
  //     } else {
  //       let itemName = ''
  //       if (collection === 'unitary_analysis') {
  //         itemName = unitaryAnalysis.peakName
  //         dispatch(fetchUpdateAnalyseValidation(unitaryAnalysis._id, data.value))
  //       }
  //       if (collection === 'unitary_compound') {
  //         itemName = unitaryAnalysis.analyse
  //         dispatch(fetchUpdateCompoundValidation(unitaryAnalysis._id, data.value))
  //       }
  //       if (collection === 'unitary_calibration') {
  //         itemName = unitaryAnalysis.name
  //         dispatch(fetchUpdateCalibrationValidation(unitaryAnalysis._id, data.value))
  //       }
  //
  //       displayPopupMessage(
  // dispatch,
  //           'success',
  //           'Validation update',
  //           `The validation of '${itemName}' is updated to '${validationName}'`,
  //         )
  //     }
  //   })
  //   .catch(function (error) {
  //     // this.setState({ message: error })
  //     // this.setState({ open: true })
  //     // this._handleOpen()
  //     console.log('error', `There was an error! ${error}`)
  //     displayPopupMessage(
  // dispatch,
  //         'error',
  //         'Validation update',
  //         <Fragment>
  //           <div>Can not update validation.</div>
  //           <div>{error.toString()}</div>
  //         </Fragment>,
  //       )
  //   })
}

/**************************** UPDATE  MAIN CHANNEL **********************************/

/**
 * Updates the main channel for a given item.
 * Creates a task for updating the main channel and dispatches the action to update the main channel.
 * Displays a popup message with information about the process.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {string} newMainChannel - The new main channel to be set.
 * @param {object} item - The item object containing the details of the item to be updated.
 * @param {string} collection - The collection to which the item belongs.
 * @param {boolean} multiAnalyse - Indicates if multiple analyses are being updated.
 */
export const updateMainChannel = (dispatch, newMainChannel, item, collection, multiAnalyse) => {
  // displayPopupMessage(
  //           dispatch,'info', 'Main channel update', `Not implemented yet...`)
  const taskId = new Date().getTime() + '_main_channel_' + item._id
  const msg = multiAnalyse
    ? ' ' + t('view.common.utils.main_channel.all')
    : ' ' + t('view.common.utils.main_channel.current')
  const task = {
    id: taskId,
    title: 'Update main channel',
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'main_channel',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: t('view.common.utils.main_channel.channel') + ' ' + newMainChannel + msg,
    items: [
      {
        id: collection === 'analyse' ? item._id : item.analyseId,
        type: collection,
        name: collection === 'analyse' ? item.name : item.analyse,
        compound: {
          id: item._id,
          name: item.name,
        },
        analysis: {
          id: item.analyseId,
          name: item.analyse,
        },
        batch: {
          id: item.batchId,
          name: item.batch_name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))

  dispatch(
    fetchUpdateMainChannel(
      newMainChannel,
      item._id,
      collection,
      multiAnalyse,
      item.batchId,
      item.analyseId,
    ),
  )
    .then(function (resp) {
      displayPopupMessage(
        dispatch,
        'success',
        t('view.common.utils.main_channel.main_title'),
        multiAnalyse ? (
          <Fragment>{t('view.common.utils.main_channel.main_msg1')}</Fragment>
        ) : (
          <Fragment>
            <Trans i18nKey="view.common.utils.main_channel.main_msg2">
              The main channel is now updated for <i>{{ val: item.analyse }}</i>.
            </Trans>
          </Fragment>
        ),
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.main_channel.fine')))
    })
    .catch(function (error) {
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.main_channel.main_title'),
        multiAnalyse ? (
          <Fragment>
            <div>{t('view.common.utils.main_channel.not_update_2')}</div>
            <div>{error.toString()}</div>
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.main_channel.not_update_3">
                Can not update the main channel for <i>{{ val: item.analyse }}</i>
              </Trans>
            </div>
            {generateErrorPart(error)}
          </Fragment>
        ),
      )
      dispatch(fetchFinishTask(taskId, 'error', error))
    })

  // let data = {}
  // data['value'] = newMainChannel
  //
  // const requestOptions = {
  //   method: 'PUT',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify(data),
  // }
  // let url = ''
  // if (multiAnalyse) {
  //   url = `${FLASK_URL}/molecule/${item._id}/update_main_channel`
  // } else {
  //   url = `${FLASK_URL}/unitary_analysis/${item._id}/update_main_channel`
  // }
  //
  // return fetch(url, requestOptions)
  //   .then(async (response) => {
  //     // check for error response
  //     if (!response.ok) {
  //       let json = response.text()
  //       return json.then(Promise.reject.bind(Promise))
  //     }
  //     // TODO Update data after success
  //
  //     displayPopupMessage(
  // dispatch,
  //         'success',
  //         'Main channel update',
  //         multiAnalyse ? (
  //           <Fragment>The main channel is now updated for all analyse.</Fragment>
  //         ) : (
  //           <Fragment>
  //             The main channel is now updated for <i>{item.analyse}</i>.
  //           </Fragment>
  //         ),
  //       )
  //   })
  //   .catch(function (error) {
  //     console.log('error', `There was an error! ${error}`)
  //     displayPopupMessage(
  // dispatch,
  //         'error',
  //         'Main channel update',
  //         multiAnalyse ? (
  //           <Fragment>
  //             <div>Can not update the main channel for all analyse.</div>
  //             <div>{error.toString()}</div>
  //           </Fragment>
  //         ) : (
  //           <Fragment>
  //             <div>
  //               Can not update the main channel for <i>{item.analyse}</i>.
  //             </div>
  //             <div>{error.toString()}</div>
  //           </Fragment>
  //         ),
  //       )
  //   })
}

/**************************** UPDATE PEAK **********************************/

/**
 * Updates the global peak for a unitary analysis.
 * Dispatches an action to update the global peak based on the provided reintegration type and method.
 * Displays a popup message indicating the success or failure of the operation.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} unitaryAnalysis - The unitary analysis object.
 * @param {object} reintegrationType - The reintegration type object.
 * @param {string} reintegrationMethod - The reintegration method.
 */
export const updateGlobalPeak = (
  dispatch,
  unitaryAnalysis,
  reintegrationType,
  reintegrationMethod,
) => {
  let data = {}
  const reintegrationString = Object.entries(reintegrationType)
    .filter((entry) => entry[1])
    .map((entry) => t('view.dialogs.chromato_apply_all.' + entry[0]))
    .join(', ')
  if (
    unitaryAnalysis.main_peak_base &&
    unitaryAnalysis.main_peak_base.left &&
    unitaryAnalysis.main_peak_base.right
  ) {
    data[`x1`] = unitaryAnalysis.main_peak_base.left.x
    data[`y1`] = unitaryAnalysis.main_peak_base.left.y
    data[`x2`] = unitaryAnalysis.main_peak_base.right.x
    data[`y2`] = unitaryAnalysis.main_peak_base.right.y
    // if (reintegrationType === 'standards') {
    //   data[`analysis_type`] = 'calibration'
    // } else if (reintegrationType === 'samples') {
    //   data[`analysis_type`] = 'sample'
    // } else {
    //   reintegrationType = 'whole'
    // }
    data[`analysis_type`] = reintegrationType
    if (reintegrationMethod === 'line') {
      data[`peak_integration`] = 'straight_line'
    } else if (reintegrationMethod === 'square') {
      data[`peak_integration`] = 'square_line'
    } else if (reintegrationMethod === 'zero') {
      data[`peak_integration`] = 'floor_line'
    } else if (reintegrationMethod === 'base') {
      data[`peak_integration`] = 'base_line'
    }
    if (unitaryAnalysis.selectedChannel) {
      data[`channel`] = unitaryAnalysis.selectedChannel
    }
  } else {
    displayPopupMessage(
      dispatch,
      'error',
      <Trans i18nKey="view.common.utils.global_peak.reintegration">
        {{ val: reintegrationString }} reintegration
      </Trans>,
      <Fragment>
        <div>
          <Trans i18nKey="view.common.utils.global_peak.cant_find">
            Cannot find peaks to apply to the reintegration from
            <i>{{ val: unitaryAnalysis.name }}</i>
          </Trans>
        </div>
      </Fragment>,
    )
    return
  }

  const taskId = new Date().getTime() + '_update_peak_' + unitaryAnalysis._id
  const task = {
    id: taskId,
    title: (
      <Trans i18nKey="view.common.utils.global_peak.reintegration">
        {{ val: reintegrationString }} reintegration
      </Trans>
    ),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'update_peak',
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: (
      <Trans i18nKey="view.common.utils.global_peak.reintegration">
        {{ val: reintegrationString }} reintegration
      </Trans>
    ),
    items: [
      {
        id: unitaryAnalysis._id,
        type: unitaryAnalysis.type,
        name: unitaryAnalysis.name,
        analysis: {
          id: unitaryAnalysis.analyseId,
          type: unitaryAnalysis.analyseType,
          name: unitaryAnalysis.analyse,
        },
        batch: {
          id: unitaryAnalysis.batchId,
          name: unitaryAnalysis.batch_name,
        },
        compound: {
          id: unitaryAnalysis.event,
          name: unitaryAnalysis.peakName.name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))
  displayPopupMessage(
    dispatch,
    'info',
    <Trans i18nKey="view.common.utils.global_peak.reintegration">
      {{ val: reintegrationString }} reintegration
    </Trans>,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.global_peak.info_msg1">
          The reintegration from <i>{{ val1: unitaryAnalysis.name }}</i> will be applied to the
          {{ val2: reintegrationString }} analyzes.
        </Trans>
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.global_peak.time')}</i>
      </div>
    </Fragment>,
  )
  // dispatch(fetchFinishTask(taskId, 'error', "The REST request doesn't exist yet."))
  dispatch(
    fetchUpdateGlobalPeak(
      unitaryAnalysis._id,
      data,
      unitaryAnalysis.selectedChannel,
      unitaryAnalysis.batchId,
      unitaryAnalysis.analyseId,
    ),
  )
    .then(function (resp) {
      delete data.channel
      delete data.analysis_type
      delete data.peak_integration
      displayPopupMessage(
        dispatch,
        'success',
        <Trans i18nKey="view.common.utils.global_peak.reintegration">
          {{ val: reintegrationString }} reintegration
        </Trans>,
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.global_peak.success">
              The peaks reintegration of <i>{{ val1: unitaryAnalysis.name }}</i> are updated to the
              {{ val2: reintegrationString }} analyzes:
            </Trans>
          </div>
          {Object.keys(data).map(function (key) {
            const firstPart = key.charAt(0)
            const secondPart = key.charAt(1)
            const point = secondPart === '1' ? 'left' : 'right'
            return (
              <div key={key}>
                {point.charAt(0).toUpperCase() + point.substr(1)} point {firstPart} is now{' '}
                {data[key] !== '' && data[key] !== null ? data[key].toFixed(2) : data[key]}
              </div>
            )
          })}
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.global_peak.fine')))
    })
    .catch(function (error) {
      if (error && error.status !== undefined && error.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          <Trans i18nKey="view.common.utils.global_peak.reintegration">
            {{ val: reintegrationString }} reintegration
          </Trans>,
          <Fragment>
            <div>{t('view.common.utils.global_peak.cant_apply')}</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'error',
          <Trans i18nKey="view.common.utils.global_peak.reintegration">
            {{ val: reintegrationString }} reintegration
          </Trans>,
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.global_peak.error">
                The peaks reintegration of <i>{{ val1: unitaryAnalysis.name }}</i> can not be
                applied to the {{ val2: reintegrationString }}
                analyzes.
              </Trans>
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

/**
 * Updates the peak for a given item.
 * Dispatches an action to update the peak based on the provided index, value, and other parameters.
 * Displays a popup message indicating the success or failure of the operation.
 *
 * @param {object} item - The item object containing the details of the item to be updated.
 * @param {number} index - The index of the peak to be updated.
 * @param {object} value - The value object containing the new peak coordinates.
 * @param {function} dispatch - The Redux dispatch function.
 * @param {string} collection - The collection to which the item belongs.
 * @param {string} channel - The channel to be updated.
 * @param {boolean} updateCalibrationAfterPeakUpdate - Indicates if calibration should be updated after peak update.
 */
export const updatePeak = (
  item,
  index,
  value,
  dispatch,
  collection,
  channel,
  updateCalibrationAfterPeakUpdate,
) => {
  return new Promise((resolve, reject) => {
    const unitaryAnalyseId = item._id
    const data = {}

    if (index !== null) {
      const ind = index + 1
      data[`x${ind}`] = value.x
      data[`y${ind}`] = value.y
    } else {
      data[`x1`] = value[0].x
      data[`y1`] = value[0].y
      data[`x2`] = value[1].x
      data[`y2`] = value[1].y
    }

    data['channel'] = channel

    const taskId = new Date().getTime() + '_update_peak_' + item._id
    const task = {
      id: taskId,
      title: t('view.common.utils.update_peak.title'),
      operator: localStorage.getItem('SESSION_user_name'),
      date: new Date().getTime(),
      action: 'update_peak',
      percentage: 50,
      state: { value: 'running', message: '' },
      operation: item.name,
      items: [
        {
          id: item.analyseId,
          type: item.analysetype,
          name: item.analyse,
          analysis: {
            id: item.analyseId,
            type: item.analysetype,
            name: item.analyse,
          },
          batch: {
            id: item.batchId,
            name: item.batch_name,
          },
        },
      ],
    }

    dispatch(fetchAddTask(task))

    const handleSuccess = (resp) => {
      sendResultMessage(dispatch, resp?.type || null, data, item)
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_peak.fine')))
      resolve(resp) // Résolution de la promesse
    }

    const handleError = (error) => {
      if (error?.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          t('view.common.utils.update_peak.title'),
          <Fragment>
            <div>{t('view.common.utils.update_peak.cant_update')}</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        sendResultMessage(dispatch, null, error, item)
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
      reject(error) // Rejet de la promesse
    }

    if (collection === 'analyse') {
      dispatch(fetchUpdateAnalysePeak(unitaryAnalyseId, data, item.batchId, item.analyseId))
        .then(handleSuccess)
        .catch(handleError)
    }

    if (collection === 'compound') {
      dispatch(fetchUpdateCompoundPeak(unitaryAnalyseId, data, item.batchId, item.analyseId))
        .then((resp) => {
          handleSuccess(resp)
          if (updateCalibrationAfterPeakUpdate) {
            updateCalibrationAfterPeakUpdate()
          }
        })
        .catch(handleError)
    }
  })
}

/**
 * Sends a result message based on the type of response.
 * Handles the display of popup messages for success or error based on the response type.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {string} type - The type of response received.
 * @param {object} data - The data object containing the peak update information.
 * @param {object} item - The item object containing the details of the item being updated.
 */
const sendResultMessage = (dispatch, type, data, item) => {
  switch (type) {
    case 'RECEIVE_UPDATE_PEAK_ANALYSE':
    case 'RECEIVE_UPDATE_PEAK_COMPOUND':
      delete data.channel
      // displayPopupMessage(
      // dispatch,
      //     'success',
      //     'Peak update',
      //     <Fragment>
      //       <div>
      //         The peaks of <i>{item.name}</i> are updated to:.
      //       </div>
      //       {Object.keys(data).map(function (key) {
      //         const firstPart = key.charAt(0)
      //         const secondPart = key.charAt(1)
      //         const point = secondPart === '1' ? 'left' : 'right'
      //         return (
      //           <div key={key}>
      //             {point.charAt(0).toUpperCase() + point.substr(1)} point {firstPart} is now{' '}
      //             {data[key] !== '' && data[key] !== null ? data[key].toFixed(2) : data[key]}
      //           </div>
      //         )
      //       })}
      //     </Fragment>,
      //   )
      break
    default:
      // TODO get the good error message
      const error = 'error'
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.update_peak.title'),
        <Fragment>
          <div>{t('view.common.utils.update_peak.cant_update')}</div>
          {generateErrorPart(data)}
        </Fragment>,
      )
  }
}

/**
 * Resets the peak for a given item.
 * Creates a task for resetting the peak and dispatches the action to reset the peak.
 * Displays a popup message indicating the success or failure of the operation.
 *
 * @param {object} item - The item object containing the details of the item to be reset.
 * @param {number} index - The index of the peak to be reset.
 * @param {function} dispatch - The Redux dispatch function.
 * @param {string} collection - The collection to which the item belongs.
 * @param {string} channel - The channel to be reset.
 * @param {function} updateCalibrationAfterPeakUpdate - Function to update calibration after peak reset.
 */
export const resetPeak = (
  item,
  index,
  dispatch,
  collection,
  channel,
  updateCalibrationAfterPeakUpdate,
) => {
  const unitaryAnalyseId = item._id
  let data = {}
  data['channel'] = channel

  const taskId = new Date().getTime() + '_reset_peak_' + item._id
  const task = {
    id: taskId,
    title: t('view.common.utils.update_peak.title'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'reset_peak',
    percentage: 50,
    state: { value: 'running', message: '' },
    // operation: JSON.stringify(value),
    operation: item.name,
    items: [
      {
        id: item.analyseId,
        type: item.analysetype,
        name: item.analyse,
        analysis: {
          id: item.analyseId,
          type: item.analysetype,
          name: item.analyse,
        },
        batch: {
          id: item.batchId,
          name: item.batch_name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))

  if (collection === 'analyse') {
    return dispatch(fetchResetAnalysePeak(unitaryAnalyseId, data, item.batchId, item.analyseId))
      .then(function (resp) {
        sendResultMessage(dispatch, resp ? resp.type : null, data, item)
        dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_peak.fine')))
        return resp
      })
      .catch(function (error) {
        if (error && error.status !== undefined && error.status === 401) {
          error.statusText = t('view.common.utils.lease_locked.code')
          displayPopupMessage(
            dispatch,
            'warning',
            t('view.common.utils.update_peak.title'),
            <Fragment>
              <div>{t('view.common.utils.update_peak.cant_update')}</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
        } else {
          sendResultMessage(dispatch, null, error, item)
        }
        dispatch(fetchFinishTask(taskId, 'error', error))
        return null
      })
  }
  if (collection === 'compound') {
    return dispatch(fetchResetCompoundPeak(unitaryAnalyseId, data, item.batchId, item.analyseId))
      .then(function (resp) {
        sendResultMessage(dispatch, resp.type, data, item)
        dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_peak.fine')))
        //Reload Calibration view to display recompute calibration button
        if (updateCalibrationAfterPeakUpdate) {
          updateCalibrationAfterPeakUpdate()
        }
        return resp
      })
      .catch(function (error) {
        if (error && error.status !== undefined && error.status === 401) {
          error.statusText = t('view.common.utils.lease_locked.code')
          displayPopupMessage(
            dispatch,
            'warning',
            t('view.common.utils.update_peak.title'),
            <Fragment>
              <div>{t('view.common.utils.update_peak.cant_update')}</div>
              {generateErrorPart(error)}
            </Fragment>,
          )
        } else {
          sendResultMessage(dispatch, null, error, item)
        }
        dispatch(fetchFinishTask(taskId, 'error', error))
        return null
      })
  }
}

/**************************** UPDATE  ISTD **********************************/

/**
 * Updates the Internal Standard (ISTD) for a given item.
 * Displays a popup message with information about the ISTD update process.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} istd_newGroup - The new ISTD group object.
 * @param {object} istd_event - The event object related to the ISTD update.
 * @param {object} batch - The batch object containing the details of the batch.
 * @param {object} item - The item object containing the details of the item to be updated.
 * @param {string} collection - The collection to which the item belongs.
 */
export const updateISTD = (dispatch, istd_newGroup, istd_event, batch, item, collection) => {
  // displayPopupMessage(
  //           dispatch,'info', 'ISTD update', `Not implemented yet...`)
  displayPopupMessage(
    dispatch,
    'info',
    t('view.common.utils.update_istd.title'),
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.update_istd.info_1">
          Requested for ISTD update on <i>{{ val: item.name }}</i>.
        </Trans>
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.update_istd.info_2')}</i>
      </div>
    </Fragment>,
  )

  const taskId = new Date().getTime() + '_istd_' + item._id

  switch (collection) {
    case 'batch':
      const task_istd_batch = {
        id: taskId,
        title: t('view.common.utils.update_istd.title'),
        operator: localStorage.getItem('SESSION_user_name'),
        date: new Date().getTime(),
        action: 'istd',
        percentage: 50,
        state: { value: 'running', message: '' },
        operation: batch.content.internal_standards[istd_newGroup].name,
        items: [
          {
            id: item._id,
            type: item.analyseType,
            name: item.name,
            compound: {
              name: item.name,
              istd: item.istd_name,
            },
            batch: {
              id: batch._id,
              name: batch.name,
            },
          },
        ],
      }
      dispatch(fetchAddTask(task_istd_batch))
      dispatch(fetchUpdateISTD(istd_newGroup, istd_event, batch._id))
        .then(function (resp) {
          displayPopupMessage(
            dispatch,
            'success',
            t('view.common.utils.update_istd.title'),
            <Fragment>
              <Trans i18nKey="view.common.utils.update_istd.success">
                The ISTD is now updated for <i>{{ val: item.name }}</i>.
              </Trans>
            </Fragment>,
          )
          dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_istd.fine')))
        })
        .catch(function (error) {
          if (error && error.status !== undefined && error.status === 401) {
            error.statusText = t('view.common.utils.lease_locked.code')
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.common.utils.update_istd.title'),
              <Fragment>
                <div>{t('view.common.utils.update_istd.cant_1')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          } else {
            displayPopupMessage(
              dispatch,
              'error',
              t('view.common.utils.update_istd.title'),
              <Fragment>
                <div>
                  <Trans i18nKey="view.common.utils.update_istd.cant_2">
                    Can not update the ISTD for <i>{item.name}</i>.
                  </Trans>
                </div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          }
          dispatch(fetchFinishTask(taskId, 'error', error))
        })
      break
    case 'compound':
      const task_istd_compound = {
        id: taskId,
        title: t('view.common.utils.update_istd.title'),
        operator: localStorage.getItem('SESSION_user_name'),
        date: new Date().getTime(),
        action: 'istd',
        percentage: 50,
        state: { value: 'running', message: '' },
        operation: batch.content.internal_standards[istd_newGroup].name,
        items: [
          {
            id: item._id,
            type: item.analyseType,
            name: item.name,
            compound: {
              name: item.name,
              istd_name: item.istd_name,
            },
            batch: {
              id: batch._id,
              name: batch.name,
            },
          },
        ],
      }
      dispatch(fetchAddTask(task_istd_compound))
      dispatch(fetchUpdateCompoundISTD(istd_newGroup, istd_event, batch._id))
        .then(function (resp) {
          displayPopupMessage(
            dispatch,
            'success',
            t('view.common.utils.update_istd.title'),
            <Fragment>
              <Trans i18nKey="view.common.utils.update_istd.success">
                The ISTD is now updated for <i>{{ val: item.name }}</i>.
              </Trans>
            </Fragment>,
          )
          dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.update_istd.fine')))
        })
        .catch(function (error) {
          if (error && error.status !== undefined && error.status === 401) {
            error.statusText = t('view.common.utils.lease_locked.code')
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.common.utils.update_istd.title'),
              <Fragment>
                <div>{t('view.common.utils.update_istd.cant_1')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          } else {
            displayPopupMessage(
              dispatch,
              'error',
              t('view.common.utils.update_istd.title'),
              <Fragment>
                <div>
                  <Trans i18nKey="view.common.utils.update_istd.cant_2">
                    Can not update the ISTD for <i>{item.name}</i>.
                  </Trans>
                </div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          }
          dispatch(fetchFinishTask(taskId, 'error', error))
        })
      break
    default:
      // Never in: back hole
      displayPopupMessage(
        dispatch,
        'error',
        t('view.common.utils.update_istd.title'),
        <Trans i18nKey="view.common.utils.update_istd.cant_2">
          Can not update the ISTD for <i>{item.name}</i>.
        </Trans>,
      )
  }
}

/**************************** CREATE/MOVE SUB-BATCH **********************************/

/**
 * Creates a sub-batch.
 * Displays a popup message with information about the sub-batch creation process.
 * Constructs a task for creating the sub-batch and dispatches the action to create the sub-batch.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {string} batchId - The ID of the batch.
 * @param {string} batchName - The name of the batch.
 * @param {Array} selection - The array of selected samples.
 * @param {string} option - The option for sub-batch creation (e.g., 'copy' or 'move').
 */
export const createSubBatch = (dispatch, batchId, batchName, selection, option) => {
  displayPopupMessage(
    dispatch,
    'info',
    <Trans i18nKey="view.common.utils.create_sub.title">
      {{
        val:
          option === 'copy'
            ? t('view.common.utils.create_sub.copy')
            : t('view.common.utils.create_sub.move'),
      }}
      to a new sub-batch
    </Trans>,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.create_sub.msg1">
          A new sub-batch named
          <i>
            {{
              val: batchName,
            }}
          </i>
          will be created with the selected samples:
        </Trans>
      </div>
      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
        {selection.map((item, index) => (
          <Chip
            key={index}
            variant="outlined"
            size="small"
            style={{ margin: 3 }}
            label={item.name}
          />
        ))}
      </div>
    </Fragment>,
  )
  const taskId =
    new Date().getTime() +
    '_moveToNewSubBatch_' +
    batchId +
    '_' +
    batchName +
    '_' +
    selection[0]._id
  const taskSubBatch = {
    id: taskId,
    title: (
      <Trans i18nKey="view.common.utils.create_sub.sample_title">
        Sample
        {{
          val:
            option === 'copy'
              ? t('view.common.utils.create_sub.copy_lower')
              : t('view.common.utils.create_sub.move_lower'),
        }}
      </Trans>
    ),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    percentage: 50,
    action:
      option === 'copy'
        ? t('view.common.utils.create_sub.copy_lower')
        : t('view.common.utils.create_sub.move_lower'),
    state: { value: 'running', message: '' },
    operation: (
      <Trans i18nKey="view.common.utils.create_sub.operation">
        new sub-batch
        {{
          val: batchName,
        }}
      </Trans>
    ),
    items: selection.map((orData) => ({
      id: orData._id,
      type: 'analysis',
      name: orData.name,
      batch: {
        id: orData.content.batch.id,
        name: orData.content.batch.name,
      },
    })),
  }
  dispatch(fetchAddTask(taskSubBatch))
  dispatch(fetchCreateSubBatch(batchId, batchName, selection, option))
    .then(function (resp) {
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.create_sub.fine')))
      displayPopupMessage(
        dispatch,
        'success',
        <Trans i18nKey="view.common.utils.create_sub.title">
          {{
            val:
              option === 'copy'
                ? t('view.common.utils.create_sub.copy')
                : t('view.common.utils.create_sub.move'),
          }}
          to a new sub-batch
        </Trans>,
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.create_sub.sub_named">
              A new sub-batch named <i>{{ val: batchName }}</i> is created with the selected
              samples:
            </Trans>
          </div>
          <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
            {selection.map((item) => (
              <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
            ))}
          </div>
        </Fragment>,
      )
    })
    .catch(function (error) {
      if (error && error.status !== undefined && error.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          <Trans i18nKey="view.common.utils.create_sub.title">
            {{
              val:
                option === 'copy'
                  ? t('view.common.utils.create_sub.copy')
                  : t('view.common.utils.create_sub.move'),
            }}
            to a new sub-batch
          </Trans>,
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.create_sub.not_create">
                Can not create a sub-batch <i>{{ val: batchName }}</i>.
              </Trans>
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'error',
          <Trans i18nKey="view.common.utils.create_sub.title">
            {{
              val:
                option === 'copy'
                  ? t('view.common.utils.create_sub.copy')
                  : t('view.common.utils.create_sub.move'),
            }}
            to a new sub-batch
          </Trans>,
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.create_sub.not_create">
                Can not create a sub-batch <i>{{ val: batchName }}</i>.
              </Trans>
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

/**
 * Moves a sample to a sub-batch.
 * Displays a popup message with information about the sub-batch move process.
 * Constructs a task for moving the sample to the sub-batch and dispatches the action to move the sample.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {string} dest_batch_id - The ID of the destination batch.
 * @param {string} batchName - The name of the batch.
 * @param {Array} selection - The array of selected samples.
 * @param {string} option - The option for sub-batch move (e.g., 'copy' or 'move').
 */
export const moveSampleToSubBatch = (dispatch, dest_batch_id, batchName, selection, option) => {
  displayPopupMessage(
    dispatch,
    'info',
    <Trans i18nKey="view.common.utils.move_to_subbatch.title">
      {{
        val:
          option === 'copy'
            ? t('view.common.utils.move_to_subbatch.copy')
            : t('view.common.utils.move_to_subbatch.move'),
      }}
      to a sub-batch
    </Trans>,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.move_to_subbatch.selected_sample">
          The selected sample bellow will be
          {{
            val1:
              option === 'copy'
                ? t('view.common.utils.move_to_subbatch.copied')
                : t('view.common.utils.move_to_subbatch.moved'),
          }}
          to
          <i>{{ val2: batchName }}</i>:
        </Trans>
      </div>
      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
        {selection.map((item) => (
          <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
        ))}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.move_to_subbatch.time')}</i>
      </div>
    </Fragment>,
  )
  const taskId =
    new Date().getTime() +
    '_moveToSubBatch_' +
    dest_batch_id +
    '_' +
    batchName +
    '_' +
    selection[0]._id
  const taskSubBatch = {
    id: taskId,
    title: (
      <Trans i18nKey="view.common.utils.move_to_subbatch.title2">
        Sample
        {{
          val:
            option === 'copy'
              ? t('view.common.utils.move_to_subbatch.copy_lower')
              : t('view.common.utils.move_to_subbatch.move_lower'),
        }}
      </Trans>
    ),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    percentage: 50,
    action:
      option === 'copy'
        ? t('view.common.utils.move_to_subbatch.copy_lower')
        : t('view.common.utils.move_to_subbatch.move_lower'),
    state: { value: 'running', message: '' },
    operation: 'sub-batch ' + batchName,
    items: selection.map((orData) => ({
      id: orData._id,
      type: 'analysis',
      name: orData.name,
      batch: {
        id: orData.content.batch.id,
        name: orData.content.batch.name,
      },
    })),
  }
  dispatch(fetchAddTask(taskSubBatch))
  dispatch(
    fetchMoveSampleToSubBatch(dest_batch_id, selection, option, selection[0].content.batch.id),
  )
    .then(function (resp) {
      displayPopupMessage(
        dispatch,
        'success',
        <Trans i18nKey="view.common.utils.move_to_subbatch.title">
          {{
            val:
              option === 'copy'
                ? t('view.common.utils.move_to_subbatch.copy')
                : t('view.common.utils.move_to_subbatch.move'),
          }}
          to a sub-batch
        </Trans>,
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.move_to_subbatch.selected_moved">
              The selected sample bellow are moved to <i>{{ val: batchName }}</i>:
            </Trans>
          </div>
          <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
            {selection.map((item) => (
              <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
            ))}
          </div>
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.move_to_subbatch.fine')))
    })
    .catch(function (error) {
      if (error && error.status !== undefined && error.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          <Trans i18nKey="view.common.utils.move_to_subbatch.title">
            {{
              val:
                option === 'copy'
                  ? t('view.common.utils.move_to_subbatch.copy')
                  : t('view.common.utils.move_to_subbatch.move'),
            }}
            to a sub-batch
          </Trans>,
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.move_to_subbatch.canot">
                Can not
                {{
                  val1:
                    option === 'copy'
                      ? t('view.common.utils.move_to_subbatch.copy')
                      : t('view.common.utils.move_to_subbatch.move'),
                }}
                to a sub-batch <i>{{ val2: batchName }}</i>
              </Trans>
              .
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'error',
          <Trans i18nKey="view.common.utils.move_to_subbatch.title">
            {{
              val:
                option === 'copy'
                  ? t('view.common.utils.move_to_subbatch.copy')
                  : t('view.common.utils.move_to_subbatch.move'),
            }}
            to a sub-batch
          </Trans>,
          <Fragment>
            <div>
              <Trans i18nKey="view.common.utils.move_to_subbatch.canot">
                Can not
                {{
                  val1:
                    option === 'copy'
                      ? t('view.common.utils.move_to_subbatch.copy')
                      : t('view.common.utils.move_to_subbatch.move'),
                }}
                to a sub-batch <i>{{ val2: batchName }}</i>
              </Trans>
              :
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

/**
 * Changes the type of analyses.
 * Dispatches an action to change the type of analyses for the given batch and selection.
 * Displays a popup message indicating the success or failure of the operation.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {string} batchId - The ID of the batch.
 * @param {string} type - The new type of analyses.
 * @param {Array} selection - The array of selected analyses.
 * @param {boolean} recomputeCalibration - Indicates if calibration should be recomputed after changing the analyses type.
 */
export const changeAnalysesType = (dispatch, batchId, type, selection, recomputeCalibration) => {
  const arr = selection.map(function (analysis) {
    let obj = {
      type: analysis.content.type,
      value:
        analysis.content.type === 'sample'
          ? analysis.content.value.toString()
          : parseFloat(analysis.content.value),
    }
    if (analysis.content.type === 'calibration' && analysis.content.suffix) {
      obj['suffix'] = analysis.content.suffix
    }
    if (recomputeCalibration) {
      obj['recomputeCalibration'] = recomputeCalibration
    }
    return { [analysis._id]: obj }
  })

  let dictionary = Object.assign({}, ...arr)
  const result = { analyses_types: dictionary }

  displayPopupMessage(
    dispatch,
    'info',
    t('view.common.utils.analysesType.title'),
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.analysesType.will_moved">
          The analyses bellow will be moved out:
          <i>{{ val: type }}</i>:
        </Trans>
      </div>
      <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
        {selection.map((item) => (
          <Chip
            key={item.name}
            variant="outlined"
            size="small"
            style={{ margin: 3 }}
            label={item.name}
          />
        ))}
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.analysesType.time')}</i>
      </div>
    </Fragment>,
  )
  const taskId = new Date().getTime() + '_changeAnalyseType_' + type + '_' + selection[0]._id
  const taskCalib = {
    id: taskId,
    title:
      type === 'Quality Control'
        ? t('view.common.utils.analysesType.quali_control')
        : t('view.common.utils.analysesType.others_move'),
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    percentage: 50,
    action: 'move',
    state: { value: 'running', message: '' },
    operation:
      type === 'Quality Control'
        ? t('view.common.utils.analysesType.others')
        : t('view.common.utils.analysesType.qc_samples'),
    items: selection.map((orData) => ({
      id: orData._id,
      type: 'analysis',
      name: orData.name,
      batch: {
        id: orData.content.batch.id,
        name: orData.content.batch.name,
      },
    })),
  }
  dispatch(fetchAddTask(taskCalib))
  dispatch(fetchChangeAnalysesType(batchId, type, result, selection))
    .then(function (resp) {
      // Reload the calibration filters count
      dispatch(fetchUnitaryCalibrationsDistinct(batchId))
      displayPopupMessage(
        dispatch,
        'success',
        t('view.common.utils.analysesType.title'),
        <Fragment>
          <div>{t('view.common.utils.analysesType.change_are_done')}</div>
          <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
            {selection.map((item) => (
              <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
            ))}
          </div>
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.analysesType.fine')))
    })
    .catch(function (error) {
      if (error && error.status !== undefined && error.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          t('view.common.utils.analysesType.title'),
          <Fragment>
            <div>{t('view.common.utils.analysesType.canot')}.</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'error',
          t('view.common.utils.analysesType.title'),
          <Fragment>
            <div>
              <i>{t('view.common.utils.analysesType.canot')}:</i>
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

/**************************** TEST CHROME WEBBROWSER **********************************/

/**
 * Checks if the browser is Google Chrome.
 * Determines if the current browser is Google Chrome by checking various properties of the window and navigator objects.
 * If the browser is Google Chrome, it displays a popup message with information about the application.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @returns {boolean} True if the browser is Google Chrome, otherwise false.
 */
export const isOnChrome = (dispatch) => {
  var isChromium = window.chrome
  var winNav = window.navigator
  var vendorName = winNav.vendor
  var isOpera = typeof window.opr !== 'undefined'
  var isIEedge = winNav.userAgent.indexOf('Edge') > -1
  var isIOSChrome = winNav.userAgent.match('CriOS')

  if (isIOSChrome) {
    // is Google Chrome on IOS
    return false
  } else if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    // is Google Chrome
    console.log('USER:' + localStorage.getItem('SESSION_user_name'))
    displayPopupMessage(
      dispatch,
      'info',
      localStorage.getItem('__config.json__APPLICATION_TITLE'),
      <Fragment>
        <div>
          <Trans i18nKey="view.common.utils.isOnChrome.welcome">
            Welcome
            <i>
              {{
                val1: localStorage.getItem('SESSION_user_name')
                  ? localStorage.getItem('SESSION_user_name')
                  : '',
              }}
            </i>
            on {{ val2: localStorage.getItem('__config.json__APPLICATION_TITLE') }} platform.
          </Trans>
        </div>
        <div style={{ marginTop: 5 }}>
          <Trans i18nKey="view.common.utils.isOnChrome.python">
            The python service loaded is <i>{{ val: SERVICE() }}</i>.
          </Trans>
        </div>
        <div>
          <Trans i18nKey="view.common.utils.isOnChrome.database">
            The database working with is <i>{{ val: DATABASE() }}</i>.
          </Trans>
        </div>
      </Fragment>,
    )
  } else {
    displayPopupMessage(
      dispatch,
      'warning',
      t('view.common.utils.isOnChrome.title'),
      t('view.common.utils.isOnChrome.msg'),
    )
  }
}

/**************************** SORT **********************************/

/**
 * Determines the worst flag level for a given item.
 * Iterates through the global and channel-specific flags of the item to find the highest severity level.
 *
 * @param {object} item - The item object containing flags.
 * @param {object} item.flags - The flags object containing global and channel-specific flags.
 * @param {Array} item.flags.globals - An array of global flags.
 * @param {object} item.flags.channels - An object containing channel-specific flags.
 * @returns {number} The highest severity level found among the flags (0 for info, 1 for warning, 2 for error).
 */
function getWorstFlag(item) {
  const flags = item.flags
  let flags_dict = new Map()
  flags_dict.set('info', 0)
  flags_dict.set('warning', 1)
  flags_dict.set('error', 2)
  let flagValue = -1

  const flags_globals = flags && flags.globals ? flags.globals : []
  flags_globals.forEach((flag) => {
    if (flags_dict.get(flag.level) > flagValue) {
      flagValue = flags_dict.get(flag.level)
    }
  })
  const flags_channels = flags && flags.channels ? flags.channels : {}
  const flags_channels_entries = Object.entries(flags_channels)
  if (flags_channels_entries.length > 0 && flagValue !== 2) {
    flags_channels_entries.forEach((channel) => {
      channel[1].forEach((flag) => {
        if (flags_dict.get(flag.level) > flagValue) {
          if (channel[0] !== item.mainChannel.toString() && flag.level === 'error') {
            flagValue = 1
          } else {
            flagValue = flags_dict.get(flag.level)
          }
        }
      })
    })
  }
  return flagValue
}

/**
 * Compares two objects in descending order based on a specified property.
 * This function is typically used for sorting arrays.
 *
 * @param {object} a - The first object to compare.
 * @param {object} b - The second object to compare.
 * @param {string} orderBy - The property name to sort by.
 * @returns {number} - Returns a negative value if `b` should come before `a`,
 *                     a positive value if `a` should come before `b`,
 *                     or 0 if they are equal.
 */
export function descendingComparator(a, b, orderBy) {
  // Hack to sort on notes column
  if (orderBy === 'notes') {
    const aN = a.notes ? a.notes.length : a.content && a.content.notes ? a.content.notes.length : 0
    const bN = b.notes ? b.notes.length : b.content && b.content.notes ? b.content.notes.length : 0
    if (bN < aN) {
      return -1
    }
    if (bN > aN) {
      return 1
    }
    return 0
  }
  // Hack to sort on Progression column
  if (orderBy === 'progression') {
    const aP = a.content.progression_requested
    const bP = b.content.progression_requested
    if (bP < aP) {
      return -1
    }
    if (bP > aP) {
      return 1
    }
    return 0
  }
  // Hack to sort on flags column
  if (orderBy === 'flags') {
    let aFlag = 0
    let bFlag = 0

    // Case for analysis in batch view
    if ((a.content && a.content.flags) || (b.content && b.content.flags)) {
      aFlag = getWorstFlag({ ...a, flags: a.content.flags })
      bFlag = getWorstFlag({ ...b, flags: b.content.flags })
    } else {
      // All other cases
      aFlag = getWorstFlag(a)
      bFlag = getWorstFlag(b)
    }
    if (bFlag < aFlag) {
      return -1
    }
    if (bFlag > aFlag) {
      return 1
    }
    return 0
  }
  // Hack to sort on Operator column
  if (orderBy === 'operator') {
    const aOp = a.operator ? a.operator[a.operator.length - 1].name : ''
    const bOp = b.operator ? b.operator[b.operator.length - 1].name : ''
    if (bOp < aOp) {
      return -1
    }
    if (bOp > aOp) {
      return 1
    }
    return 0
  }
  // Case if string, lowercase to sort
  else if (
    (typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string') ||
    orderBy === 'peakName'
  ) {
    let objA = a[orderBy]
    let objB = b[orderBy]
    if (orderBy === 'peakName') {
      objA = a[orderBy].name
      objB = b[orderBy].name
    }
    const lowerA = objA.toLowerCase()
    const lowerB = objB.toLowerCase()
    if (lowerB < lowerA) {
      return -1
    }
    if (lowerB > lowerA) {
      return 1
    }
    return 0
  } else if (typeof b[orderBy] === 'boolean' && typeof a[orderBy] === 'boolean') {
    const ab = a[orderBy].toString()
    const bb = b[orderBy].toString()
    if (bb < ab) {
      return -1
    }
    if (bb > ab) {
      return 1
    }
    return 0
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
}

/**
 * Returns a comparator function for sorting.
 * Determines the comparator function based on the specified order and property.
 *
 * @param {string} order - The order of sorting ('asc' for ascending, 'desc' for descending).
 * @param {string} orderBy - The property name to sort by.
 * @returns {function} - A comparator function that compares two objects based on the specified order and property.
 */
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

/**
 * Performs a stable sort on an array.
 * This function sorts the array using the provided comparator function while maintaining the relative order of equal elements.
 *
 * @param {Array} array - The array to be sorted.
 * @param {function} comparator - The comparator function used to determine the order of elements.
 * @returns {Array} - The sorted array.
 */
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

/**
 * Validates if a given date string is in the format 'dd-mm-yy'.
 *
 * @param {string} dateString - The date string to validate.
 * @returns {boolean} True if the date string matches the format 'dd-mm-yy', otherwise false.
 */
export function isValidDate(dateString) {
  const regEx = /^\d{2}-\d{2}-\d{2}$/
  return dateString.match(regEx) != null
}

/**
 * Gets the start time of the current day.
 * Returns the timestamp for the start of the current day (00:00:00).
 *
 * @returns {number} The timestamp for the start of the current day in milliseconds.
 */
export function getTimeStartDay() {
  const t = new Date()
  return new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).getTime()
}
/**
 * Gets the end time of the current day.
 * Returns the timestamp for the end of the current day (23:59:59).
 *
 * @returns {number} The timestamp for the end of the current day in milliseconds.
 */
export function getTimeEndDay() {
  const t = new Date()
  return new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).getTime()
}

/**
 * Formats a date object into a string with the specified separator.
 *
 * @param {Date} date - The date object to format.
 * @param {string} [sep='-'] - The separator to use between day, month, and year.
 * @returns {string} The formatted date string in the format 'dd-mm-yyyy'.
 */
export function formatDate(date, sep) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join(sep ? sep : '-')
}

/**
 * Sets the order value from the analysis order.
 * If the maximum order value in the unitary analyses compound is greater than or equal to 1000,
 * it sorts the unitary analyses compound by order and assigns a new sequential order value.
 *
 * @param {Array} unitaryAnalysesCompound - The array of unitary analyses compound objects.
 */
export function setOrderValueFromAnalysisOrder(unitaryAnalysesCompound) {
  if (Math.max(...unitaryAnalysesCompound.map((u) => u['order'])) >= 1000) {
    // let order = 0
    // unitaryAnalysesCompound
    //   .sort(function (a, b) {
    //     if (a['order'] < b['order']) return -1
    //     if (a['order'] > b['order']) return 1
    //     return 0
    //   })
    //   .forEach((a) => {
    //     a['#'] = order
    //     order = order + 1
    //   })
    setOrderValueFromAnalysisOrder_new(unitaryAnalysesCompound)
  } else {
    setOrderValueFromAnalysisOrder_old(unitaryAnalysesCompound)
  }
  return unitaryAnalysesCompound
}

/**
 * Sets the order value from the analysis order for the new method.
 * Sorts the unitary analyses compound by order and assigns a new sequential order value.
 *
 * @param {Array} unitaryAnalysesCompound - The array of unitary analyses compound objects.
 */
export function setOrderValueFromAnalysisOrder_new(unitaryAnalysesCompound) {
  let order = 0
  const compute_order_analysis_new = (analysis_list, order) => {
    analysis_list
      .sort(function (a, b) {
        if (a['order'] < b['order']) return -1
        if (a['order'] > b['order']) return 1
        return 0
      })
      .forEach((a) => {
        a['#'] = order
        order = order + 1
      })
    return order
  }

  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'blank'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'calibration'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'matrix_bio'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'matrix_bio_doped'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'sample'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'std_end'),
    order,
  )
  order = compute_order_analysis_new(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'other'),
    order,
  )

  return unitaryAnalysesCompound
}

/**
 * Sets the order value from the analysis order for the old method.
 * Sorts the unitary analyses compound by various criteria and assigns a new sequential order value.
 *
 * @param {Array} unitaryAnalysesCompound - The array of unitary analyses compound objects.
 */
export function setOrderValueFromAnalysisOrder_old(unitaryAnalysesCompound) {
  let order = 0
  const compute_order_analysis = (analysis_list, order, calibration) => {
    analysis_list
      .sort(function (a, b) {
        if (a['#'] < b['#']) return -1
        if (a['#'] > b['#']) return 1
        return 0
      })
      .sort(function (a, b) {
        if (a.analyse < b.analyse) return -1
        if (a.analyse > b.analyse) return 1
        return 0
      })
      .sort(function (a, b) {
        if (calibration) {
          if (a.level < b.level) return -1
          if (a.level > b.level) return 1
          return 0
        } else {
          return 0
        }
      })
      .sort(function (a, b) {
        if (calibration) {
          if (a.suffix < b.suffix) return -1
          if (a.suffix > b.suffix) return 1
          return 0
        } else {
          return 0
        }
      })
      .forEach((a) => {
        a['#'] = order
        order = order + 1
      })
    return order
  }

  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'blank'),
    order,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'calibration'),
    order,
    true,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'matrix_bio'),
    order,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'matrix_bio_doped'),
    order,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'sample'),
    order,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'std_end'),
    order,
  )
  order = compute_order_analysis(
    unitaryAnalysesCompound.filter((c) => c.analyseType === 'other'),
    order,
  )

  return unitaryAnalysesCompound
}

/**************************** LIMS **********************************/

/**
 * Fetches the report to LIMS (Laboratory Information Management System).
 * Processes the analyses, batch ID, non-detected unitary analyses, and MOC filters,
 * and dispatches the necessary actions to report the data to LIMS.
 *
 * @param {Array} analyses - The array of analyses to be reported.
 * @param {string} batchId - The ID of the batch.
 * @param {Array} nonDetectedUAs - The array of non-detected unitary analyses.
 * @param {Array} mocFilters - The array of MOC (Method of Control) filters.
 * @returns {function} A thunk function that returns a promise resolving the dispatch actions.
 */
export function fetchReportToLims(analyses, batchId, nonDetectedUAs, mocFilters) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const uaLimsFiltered = analyses.map((ua) => ({
        ...ua,
        limsUA:
          mocFilters.length > 0
            ? ua.limsUA.filter((lua) => mocFilters.indexOf(lua.moc) > -1)
            : ua.limsUA,
      }))
      let data = {}
      // Set is used to keep only 1 occurence
      data['ua_ids'] = [
        ...new Set(
          uaLimsFiltered
            .map((analysis) =>
              analysis.limsUA.filter((ua) => ua.validation === '1').map((ua) => ua._id),
            )
            .flat(),
        ),
      ]
      // Set is used to keep only 1 occurence
      data['nd_ua_ids'] = [
        ...new Set(
          nonDetectedUAs
            .concat(
              uaLimsFiltered.map((analysis) =>
                analysis.limsUA.filter((ua) => ua.validation === '2'),
              ),
            )
            .flat()
            .filter((lua) =>
              mocFilters.length > 0 ? mocFilters.indexOf(lua.content.moc) > -1 : lua,
            )
            .map((ua) => ua._id),
        ),
      ]

      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: data }),
      }
      let url = `${FLASK_URL()}/analysis/report`
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

/**
 * Fetches the LMR (Laboratory Management Report) to LIMS (Laboratory Information Management System).
 * Processes the analyses, batch ID, and MOC filters,
 * and dispatches the necessary actions to report the data to LIMS.
 *
 * @param {Array} analyses - The array of analyses to be reported.
 * @param {string} batchId - The ID of the batch.
 * @param {Array} mocFilters - The array of MOC (Method of Control) filters.
 * @returns {function} A thunk function that returns a promise resolving the dispatch actions.
 */
export function fetchLmrReportToLims(analyses, batchId, mocFilters) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const uaLimsFiltered = analyses.map((ua) => ({
        ...ua,
        limsUA:
          mocFilters.length > 0
            ? ua.limsUA.filter((lua) => mocFilters.indexOf(lua.moc) > -1)
            : ua.limsUA,
      }))
      let data = {}
      data['ua_ids'] = uaLimsFiltered
        .map((analysis) =>
          analysis.limsUA.filter((ua) => ua.validation === '1').map((ua) => ua._id),
        )
        .flat()

      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: data }),
      }
      let url = `${FLASK_URL()}/analysis/report_lmr`
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

/**************************** AUDIT TRAIL **********************************/

/**
 * Exports the audit trail.
 * Displays a popup message with information about the audit trail export process.
 *
 * @param {function} dispatch - The Redux dispatch function.
 */
export function exportAuditTrail(dispatch) {
  displayPopupMessage(
    dispatch,
    'info',
    t('view.common.utils.audit_trail.title'),
    t('view.common.utils.audit_trail.msg'),
  )
}

/**
 * Handles the action report for a given analysis.
 * Stops the propagation of the event and prevents the default action.
 * Dispatches the appropriate action based on the provided value.
 *
 * @param {object} event - The event object.
 * @param {object} analyse - The analysis object.
 * @param {number} value - The value indicating the action to be taken.
 * @param {string} collection - The collection to which the analysis belongs.
 * @param {function} dispatch - The Redux dispatch function.
 */
export const handleActionReport = (event, analyse, value, collection, dispatch) => {
  event.stopPropagation()
  switch (value) {
    case 0:
      // Pas possible
      // const requestOptions = {
      //   method: 'GET',
      //   credentials: 'same-origin',
      //   // responseType: 'blob', // important
      //   // credentials: 'include',
      // }
      // let url = `${FLASK_URL}/analysis/${selection[0]._id}/audit_trail`
      // return fetch(url, requestOptions)
      //   .then(async (response) => {
      //     // check for error response
      //     if (!response.ok) {
      //       displayPopupMessage(
      // dispatch,
      //           'error',
      //           'Export audit trail',
      //           <Fragment>
      //             <div>Samples selected: {selection[0].name}</div>
      //             Cannot export audit trail.
      //           </Fragment>,
      //         )
      //       let json = response.text()
      //       return json.then(Promise.reject.bind(Promise))
      //     }
      //
      //     displayPopupMessage(
      // dispatch,
      //         'success',
      //         'Export audit trail',
      //         <Fragment>
      //           The audit trail for the analysis {selection[0].name} has been exported.
      //         </Fragment>,
      //       )
      //   })
      //
      displayPopupMessage(
        dispatch,
        'info',
        t('view.common.utils.audit_trail.export_title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.audit_trail.export_msg">
              Sample analysis selected: {{ val: analyse.name }}
            </Trans>
          </div>
        </Fragment>,
      )

      // OK
      // Create link to download
      // const blob = response.blob()
      // const url = window.URL.createObjectURL(new Blob([blob]));
      let url = `${FLASK_URL()}/analysis/${analyse._id}/audit_trail`
      let link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `audit_trail_${analyse.name}.txt`)
      document.body.appendChild(link)
      // Force download
      link.click()
      // Clean up and remove the link
      link.parentNode.removeChild(link)

      break
    case 1:
      displayPopupMessage(
        dispatch,
        'warning',
        'Report to LIMS',
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.audit_trail.report_msg1">
              Sample analysis selected: {analyse.name}
            </Trans>
          </div>
          {t('view.common.utils.audit_trail.report_msg2')}
        </Fragment>,
      )
      break
    default:
      break
  }
}

/**************************** BUILD EXTRA FLAGS INFO **********************************/
/**
 * Gets the severity level of a given flag.
 * Determines the severity level based on the flag's description.
 *
 * @param {string} flag - The flag description.
 * @returns {string|null} The severity level ('high', 'middle', 'low'), or null if the flag is unrecognized.
 */
export function getSeverity(flag) {
  switch (flag) {
    case 'Probable detection':
      return 'high'
    case 'Rare detection':
      return 'middle'
    case 'Interfering detection':
      return 'low'
    default:
      return null
  }
}

/**
 * Opens a URL in a new browser tab.
 * The new tab is opened with the 'noopener' and 'noreferrer' attributes to enhance security.
 *
 * @param {string} url - The URL to be opened in a new tab.
 */
export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

/**
 * Generates a tooltip for the requested analyses.
 * Parses the provided message, removes bullet points, and splits it into lines.
 * Constructs the tooltip content by mapping each line to a table row.
 *
 * @param {string} message - The message containing the analyses information.
 * @returns {JSX.Element} The tooltip content as a table.
 */
export const getAnalyseDemandeesTooltip = (message) => {
  const lines = message ? message.replace(/\* /g, '').split('\n') : []

  const content = lines.map((line) => {
    let columns = line.split('|')
    return (
      <table key={generateUUID()} style={{ overflowWrap: 'anywhere' }}>
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{columns[0]} </td>
          </tr>
          <tr style={{ fontSize: 12 }}>
            <td>
              <i>
                {columns[1]} {' , '} {columns[2]}
              </i>
            </td>
          </tr>
          <tr style={{ fontSize: 12 }}></tr>
        </tbody>
      </table>
    )
  })
  return (
    <React.Fragment>
      <div style={{ fontSize: 16, margin: 5, marginTop: 10, marginBottom: 10 }}>Package</div>
      {content}
    </React.Fragment>
  )
}

/**************************** VALIDATION FUNCTION **********************************/
/**
 * Handles the click action for a given item.
 * Stops the propagation of the event and prevents the default action.
 * If the Ctrl and Alt keys are pressed, it fetches and displays the JSON data for the item.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} event - The event object.
 * @param {object} item - The item object.
 * @param {string} collection - The collection to which the item belongs.
 * @param {boolean} open - Indicates if the item should be opened.
 */
export const getClickAction = (dispatch, event, item, collection, open) => {
  event.stopPropagation()
  event.preventDefault()
  if (event.ctrlKey && event.altKey) {
    let db_collection = collection
    let target = item._id
    if (collection === 'unitary_compound') {
      db_collection = 'unitary_analysis'
    }
    dispatch(fetchDisplayJson(target, db_collection))
  } else if (event.ctrlKey) {
    let url = ''
    if (collection === 'batch') {
      url = `${window.location.origin}${window.location.pathname}?${DATABASE()}/${collection}/${
        item._id
      }`
    } else {
      let target = item._id
      let url_collection = collection
      let batch_id = item.batchId
      switch (collection) {
        case 'analysis':
          batch_id = item.content?.batch?.id || item.batchId
          break
        case 'unitary_analysis':
        case 'unitary_calibration':
          target = item.event
          url_collection = 'compound'
          break
        case 'unitary_compound':
          target = item.analyseId
          url_collection = 'analysis'
          break
        default:
          break
      }
      url = `${window.location.origin}${
        window.location.pathname
      }?${DATABASE()}/${batch_id}/${url_collection}/${target}`
    }
    openInNewTab(url)
  } else {
    open()
  }
}

/**************************** BATCH LEASE **********************************/
/**
 * Tests the lease status of a batch.
 * If the batch has a lease and the current time is less than the lease time,
 * it displays a warning message and redirects to the home page if the current path is not the root.
 *
 * @param {object} batch - The batch object containing lease information.
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} history - The history object used for navigation.
 */
export const testBatchLease = (batch, dispatch, history) => {
  // TODO STEF catch error instead time
  // debugger
  // if (
  //   batch &&
  //   batch.content.lease &&
  //   Math.round(new Date().getTime() / 1000) < batch.content.lease
  // ) {
  //   if (history.location.pathname !== '/') {
  //     displayPopupMessage(
  //       dispatch,
  //       'warning',
  //       'Batch locked',
  //       <Fragment>
  //         <div>The batch {batch.name} cannot be opened at this time.</div>
  //         <div>
  //           An operation is runnning. Please wait until it is released.
  //         </div>
  //       </Fragment>,
  //       5000,
  //     )
  //     // history.push('/')
  //   }
  //   return <Fragment />
  // }
}

/**************************** CONVERT MASS VALUE **********************************/
/**
 * Converts the mass concentration value based on the provided configuration.
 * Adjusts the concentration value according to the specified unit in the settings.
 *
 * @param {number} value - The concentration value to be converted.
 * @param {object} raw - The raw data object.
 * @param {string} channel - The channel identifier.
 * @param {string} config - The configuration type ('mass' or other).
 * @returns {number} The converted concentration value.
 */
export const convertMassConcentrationValue = (value, raw, channel, config) => {
  if (config === 'mass') {
    switch (localStorage.getItem('SETTINGS_mass_reported_concentration_unit')) {
      case 'ppb':
      case 'μg/kg':
        return value * 1000
      case 'ppt':
      case 'ng/kg':
        return value * 1000000
      case 'ppm':
      case 'mg/kg':
      default:
        return value
    }
  } else {
    return convertVolumeConcentrationValue(value, raw, channel, true)
  }
}

/**************************** CONVERT VOLUME VALUE **********************************/
/**
 * Converts the volume concentration value based on the provided configuration.
 * Adjusts the concentration value according to the specified unit in the settings.
 *
 * @param {number} val - The concentration value to be converted.
 * @param {object} raw - The raw data object.
 * @param {string} channel - The channel identifier.
 * @param {boolean} reportedConc - Indicates if the reported concentration should be used.
 * @returns {number} The converted concentration value.
 */
export const convertVolumeConcentrationValue = (val, raw, channel, reportedConc) => {
  let value = val
  if (channel) {
    value = raw.channels[channel].concentration
  }
  switch (
    reportedConc
      ? localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
      : localStorage.getItem('SETTINGS_volume_concentration_unit')
  ) {
    case 'mg/l':
      return value * (1 / 1000)
    case 'ng/l':
      return value * 1000
    case 'pg/l':
      return value * 1000000
    case 'n mol/l':
      return (
        value *
        (1000 / (raw.channels_data[0] && raw.channels_data[0].q1 ? raw.channels_data[0].q1 : 1))
      )
    case 'p mol/l':
      return (
        value *
        (1000000 / (raw.channels_data[0] && raw.channels_data[0].q1 ? raw.channels_data[0].q1 : 1))
      )
    case 'μg/l':
    default:
      return value
  }
}

/*********************** DISPLAY HUMAN READABLE VALUE **********************************/
/**
 * Displays a human-readable representation of a number.
 * Converts the number to a string with the specified precision, fixed decimal places, and percentage format.
 *
 * @param {number} num - The number to be converted.
 * @param {number} precision - The precision level (0 to 9).
 * @param {boolean} fixed - Indicates if the number should be fixed to the specified precision.
 * @param {boolean} percentage - Indicates if the number should be formatted as a percentage.
 * @returns {string} The human-readable representation of the number.
 */
export function displayHumanReadable(num, precision, fixed, percentage) {
  // precision: 0 -> 9
  const _min = [1, 0.1, 0.01, 0.001, 0.0001, 0.00001, 0.000001, 0.000001, 0.0000001, 0.00000001]
  if (Math.abs(num) >= 1000000 && !percentage) {
    return num.toExponential(2)
  } else if (Math.abs(num) <= _min[precision] && !percentage) {
    return num.toExponential(2)
  } else {
    const num_length = parseInt(num) === 0 ? 0 : parseInt(num).toString().length
    let _len = Math.max(0, precision - num_length)
    if (fixed && fixed !== '' && fixed !== null && num_length <= precision) {
      _len = fixed
    }
    // return (num - Math.floor(num)) !== 0 ? num.toLocaleString('en-US', { minimumFractionDigits: _len }) : num.toFixed(0)
    // return (toLocaleString('en-US', { minimumFractionDigits: 4 }))
    return num - Math.floor(num) !== 0 ? num.toFixed(_len) : num.toFixed(0)
  }
}

/************************ CUSTOM RENDER FORMAT & DECIMALS *************************/
/**
 * Customizes the format and decimal places of a given value.
 * Adjusts the value based on the specified format and decimal places in the column information.
 *
 * @param {number} value - The original value to be formatted.
 * @param {number} autoValue - The automatically determined value for formatting.
 * @param {object} columnInfos - The object containing formatting information.
 * @param {string} columnInfos.format - The format type ('auto', 'sci', etc.).
 * @param {number} columnInfos.decimals - The number of decimal places to format the value to.
 * @returns {number|string} The formatted value.
 */
export function customFormatDecimals(value, autoValue, columnInfos) {
  let tmpVal = value
  if (columnInfos && tmpVal && typeof tmpVal === 'number') {
    if (columnInfos.format === 'auto') {
      tmpVal = autoValue
    } else if (columnInfos.format === 'sci') {
      if (columnInfos.decimals !== -1) {
        tmpVal = tmpVal.toExponential(columnInfos.decimals)
      } else {
        tmpVal = tmpVal.toExponential()
      }
    } else if (typeof columnInfos.decimals === 'number' && columnInfos.decimals !== -1) {
      tmpVal = tmpVal.toFixed(columnInfos.decimals)
    } else {
      // Default format if no settings was present in localStorage
      tmpVal = autoValue ? autoValue : tmpVal.toFixed(3)
    }
  }
  return tmpVal
}
/************************ NORMALIZATION FILE SIZE *********************************/
/**
 * Converts a file size to a human-readable string.
 * Formats the file size in either SI (base 10) or binary (base 2) units.
 *
 * @param {number} bytes - The file size in bytes.
 * @param {boolean} [si=false] - Whether to use SI units (base 10) or binary units (base 2).
 * @param {number} [dp=1] - The number of decimal places to include in the formatted string.
 * @returns {string} The formatted file size string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
}

/************************ FORMULAS DICTIONARY *********************************/
/**
 * Dictionary of formulas used in the application.
 * Each key represents a formula name, and the value is an object containing the origin and correspondence of the formula.
 *
 * @type {Object.<string, {origin: string, correspondence: string, readOnly?: boolean}>}
 * @property {Object} 'F cal' - Calibration factor formula.
 * @property {string} 'F cal'.origin - The origin of the formula.
 * @property {string} 'F cal'.correspondence - The corresponding field in the data.
 * @property {Object} PE - Weighing formula.
 * @property {string} PE.origin - The origin of the formula.
 * @property {string} PE.correspondence - The corresponding field in the data.
 * @property {Object} 'F dil' - Dilution factor formula.
 * @property {string} 'F dil'.origin - The origin of the formula.
 * @property {string} 'F dil'.correspondence - The corresponding field in the data.
 * @property {Object} Sm - Sample concentration formula.
 * @property {string} Sm.origin - The origin of the formula.
 * @property {string} Sm.correspondence - The corresponding field in the data.
 * @property {Object} 'V sol ext' - External solution volume formula.
 * @property {string} 'V sol ext'.origin - The origin of the formula.
 * @property {string} 'V sol ext'.correspondence - The corresponding field in the data.
 * @property {Object} 'V élu' - Eluate volume formula.
 * @property {string} 'V élu'.origin - The origin of the formula.
 * @property {string} 'V élu'.correspondence - The corresponding field in the data.
 * @property {Object} 'V ext dil' - Diluted external volume formula.
 * @property {boolean} 'V ext dil'.readOnly - Indicates if the formula is read-only.
 * @property {string} 'V ext dil'.origin - The origin of the formula.
 * @property {string} 'V ext dil'.correspondence - The corresponding field in the data.
 * @property {Object} 'V col' - Column volume formula.
 * @property {string} 'V col'.origin - The origin of the formula.
 * @property {string} 'V col'.correspondence - The corresponding field in the data.
 */
export const formulas_dico = {
  'F cal': {
    origin: 'formulas',
    correspondence: 'facteur_calcul',
  },
  PE: { origin: 'content', correspondence: 'pesee' },
  'F dil': {
    origin: 'formulas',
    correspondence: 'facteur_dilution',
  },
  Sm: { origin: 'content', correspondence: 'sm_concentration' },
  'V sol ext': {
    readOnly: true,
    origin: 'formulas',
    correspondence: 'vol_sol_ext',
  },
  'V élu': {
    readOnly: true,
    origin: 'formulas',
    correspondence: 'vol_elu',
  },
  'V ext dil': {
    readOnly: true,
    origin: 'formulas',
    correspondence: 'vol_ext_dilue',
  },
  'V col': {
    readOnly: true,
    origin: 'formulas',
    correspondence: 'vol_col',
  },
  'V tot': {
    readOnly: true,
    origin: 'formulas',
    correspondence: 'vol_total',
  },
  'F cor': {
    origin: 'formulas',
    correspondence: 'facteur_correction',
  },
  unite: {
    origin: 'formulas',
    correspondence: 'unite',
  },
}

/**
 * Saves the analysis value.
 * Dispatches an action to save the new value for the specified analysis parameter.
 * Displays a popup message indicating the success or failure of the operation.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} batch - The batch object.
 * @param {any} newValue - The new value to be saved.
 * @param {object} analyse - The analysis object.
 * @param {string} paramValue - The parameter value to be updated.
 * @param {object} formula - The formula object.
 */
export function saveAnalyseValue(dispatch, batch, newValue, analyse, paramValue, formula) {
  const paramUpper = paramValue.charAt(0).toUpperCase() + paramValue.slice(1)
  const title = (
    <Trans i18nKey="view.common.utils.save_value.value">{{ val: paramUpper }} value</Trans>
  )
  const taskId = new Date().getTime() + '_update_' + paramValue + analyse._id

  const formulasValue = formula ? (
    <span>
      :
      {Object.keys(newValue).map((formula) => (
        <div>
          <b>{analyse.content.formula_infos.resultat_calcule_sans_rdt}</b>
          <span> {t('view.common.utils.save_value.with')} </span>
          <div style={{ textAlign: 'end' }}>
            {Object.keys(newValue[formula])
              .map((dico) => dico + '=' + newValue[formula][dico])
              .join(', ')}
          </div>
        </div>
      ))}
    </span>
  ) : // .join(' , ')
  null

  const formulasValueStringify =
    analyse.content.formula_infos.resultat_calcule_sans_rdt +
    ' ' +
    t('view.common.utils.save_value.with') +
    ' ' +
    Object.keys(newValue)
      .map((formula) =>
        Object.keys(newValue[formula])
          .map((dico) => dico + '=' + newValue[formula][dico])
          .join(', '),
      )
      .toString()

  const task = {
    id: taskId,
    title: title,
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'update_' + formula ? 'formulas' : paramValue,
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: formula ? formulasValue : newValue,
    items: [
      {
        id: analyse._id,
        type: 'analysis',
        name: analyse.name,
        analysis: {
          id: analyse._id,
          name: analyse.name,
        },
        batch: {
          id: batch._id,
          name: batch.name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))

  displayPopupMessage(
    dispatch,
    'info',
    title,
    <Fragment>
      <div>
        <Trans i18nKey="view.common.utils.save_value.info">
          The {{ val1: paramValue }} for the analysis <i>{{ val2: analyse.name }}</i> will be
          updated to <i>{{ val3: formula ? formulasValueStringify : newValue + '.' }}</i>
        </Trans>
      </div>
      <div style={{ marginTop: 10 }}>
        <i>{t('view.common.utils.save_value.time')}</i>
      </div>
    </Fragment>,
  )

  dispatch(fetchUpdateAnalyse(newValue, analyse, paramValue, batch._id))
    .then(function (resp) {
      displayPopupMessage(
        dispatch,
        'success',
        title,
        <Fragment>
          <Trans i18nKey="view.common.utils.save_value.success">
            The {{ val1: formula ? 'formulas' : paramValue }} for the analysis
            {{ val2: analyse.name }} is now
            <i>{{ val3: formula ? formulasValueStringify : newValue + '.' }}</i>
          </Trans>
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.save_value.fine')))
    })
    .catch(function (error) {
      displayPopupMessage(
        dispatch,
        'error',
        title,
        <Fragment>
          <div>
            <Trans i18nKey="view.common.utils.save_value.cannot">
              Can not update the {{ val1: formula ? 'formulas' : paramValue }} for the analysis
              <i>{{ val2: analyse.name }}</i>.
            </Trans>
          </div>
          {generateErrorPart(error)}
        </Fragment>,
      )
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

/**
 * Initiates a batch backup download.
 * Constructs the URL for the batch dump and triggers a download of the batch dump file.
 *
 * @param {string} batchId - The ID of the batch to be backed up.
 */
export function saveBatchBackup(batchId) {
  let url = `${FLASK_URL()}/dump_batch/${batchId}`
  let link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `batch_${batchId}_dump.zip`)
  document.body.appendChild(link)
  // Force download
  link.click()
  // Clean up and remove the link
  link.parentNode.removeChild(link)
}

/**
 * Computes the AI analysis for a given analysis and batch.
 * Creates a task for computing the AI analysis and dispatches the action to compute the AI analysis.
 * Displays a popup message with information about the process.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} analyse - The analysis object.
 * @param {object} batch - The batch object.
 */
export function computeAIAnalyse(dispatch, analyse, batch) {
  const taskId = new Date().getTime() + '_compute_ia_' + analyse.name
  const title = 'Compute AI'

  const task = {
    id: taskId,
    title: title,
    operator: localStorage.getItem('SESSION_user_name'),
    date: new Date().getTime(),
    action: 'compute_ia_' + analyse.name,
    percentage: 50,
    state: { value: 'running', message: '' },
    operation: 'Compute IA score for ' + analyse.name,
    items: [
      {
        id: analyse._id,
        type: 'analysis',
        name: analyse.name,
        analysis: {
          id: analyse._id,
          name: analyse.name,
        },
        batch: {
          id: batch._id,
          name: batch.name,
        },
      },
    ],
  }
  dispatch(fetchAddTask(task))

  displayPopupMessage(
    dispatch,
    'info',
    title,
    <Fragment>
      <div>
        AI score and classification for the analysis <i>{analyse.name}</i> will be updated
      </div>
      <div style={{ marginTop: 10 }}>
        <i>This action may take time, you will be notified when it is completed.</i>
      </div>
    </Fragment>,
  )

  dispatch(fetchComputeAIAnalyse(analyse, batch._id))
    .then(function (resp) {
      if (resp.status == 400) {
        displayPopupMessage(
          dispatch,
          'error',
          `${title}`,
          <Fragment>
            <div>
              Can not compute AI score for the analysis <i>{analyse.name}</i>.
            </div>
            <div>{resp.message}</div>
          </Fragment>,
        )
        dispatch(fetchFinishTask(taskId, 'error', resp.message))
      } else {
        displayPopupMessage(
          dispatch,
          'success',
          `${title}`,
          <Fragment>Compute AI score for the analysis {analyse.name} is done</Fragment>,
        )
        dispatch(fetchFinishTask(taskId, 'success', 'Everything is fine'))
      }
    })
    .catch(function (error) {
      if (error && error.status !== undefined && error.status === 401) {
        error.statusText = t('view.common.utils.lease_locked.code')
        displayPopupMessage(
          dispatch,
          'warning',
          `${title}`,
          <Fragment>
            <div>
              Can not compute AI score for the analysis <i>{analyse.name}</i>.
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      } else {
        displayPopupMessage(
          dispatch,
          'error',
          `${title}`,
          <Fragment>
            <div>
              Can not compute AI score for the analysis <i>{analyse.name}</i>.
            </div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      }
      dispatch(fetchFinishTask(taskId, 'error', error))
    })
}

/**
 * Tests the availability of the service.
 * Fetches the version information from the Flask server to check if the service is available.
 * If the service is available, it dispatches the necessary actions.
 *
 * @param {function} dispatch - The Redux dispatch function.
 */
export function testServiceAvailability(dispatch) {
  const loadedService = SERVICE()
  // test if service are available
  //fetch(`${FLASK_BASE_URL()}/${loadedService}/version`, {
  fetch(`${FLASK_URL()}/version`, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.text()
      }
      throw new Error('Something went wrong')
    })
    .then((responseJson) => {
      // Service is online, do nothing
    })
    .catch((error) => {
      // Set a flag to say that service is not available
      dispatch(fetchChangeSettings('configurationService', true))
      displayPopupMessage(
        dispatch,
        'error',
        <Trans i18nKey="view.common.utils.test_service.title">Service not available</Trans>,
        <div>
          <div>
            <Trans i18nKey="view.common.utils.test_service.msg1">
              The service is currently not accessible.
            </Trans>
          </div>
          <div>
            <Trans i18nKey="view.common.utils.test_service.msg2">
              Please contact your administrator.
            </Trans>
          </div>
        </div>,
      )
    })
}
/************************ WIKI COD *********************************/
/**
 * Fetches the Wiki helper content.
 * Retrieves the helper content based on the user's language preference and updates the settings with the fetched content.
 * If the content for the user's language is not available, it fetches the content for the default language.
 * Displays a popup message if an error occurs during the fetch process.
 *
 * @param {function} dispatch - The Redux dispatch function.
 */
export function getWikiHelper(dispatch) {
  const myT = t
  const suffix = 'helper'
  const fisrtLang = localStorage.getItem('i18nextLng').slice(0, 2)
  const firstLangPromise = getHelperPromise(fisrtLang, suffix)
  firstLangPromise.then(
    function (returns) {
      dispatch(fetchChangeSettings('wikiCODHelper', returns))
    },
    function () {
      const defaultLangPromise = getHelperPromise('en', suffix)
      defaultLangPromise.then(
        function (returns) {
          dispatch(fetchChangeSettings('wikiCODHelper', returns))
          displayPopupMessage(
            dispatch,
            'warning',
            myT('view.common.table_multigrid.helper_title_error'),
            <div>
              <div>
                <Trans i18nKey="view.common.table_multigrid.helper_title_msg_first">
                  It is currently impossible to retrieve wiki information in the configured language
                  ({{ val: fisrtLang }}).
                </Trans>
              </div>
              <div>
                <Trans i18nKey="view.common.table_multigrid.helper_title_msg_second">
                  The wiki in the default language ({{ val: 'en' }}) will be loaded instead.
                </Trans>
              </div>
            </div>,
          )
        },
        function () {
          displayPopupMessage(
            dispatch,
            'error',
            myT('view.common.table_multigrid.helper_title_error'),
            <Trans i18nKey="view.common.table_multigrid.helper_title_msg_default">
              It is currently impossible to retrieve wiki information in the configured (
              {{ val1: fisrtLang }}) and default ({{ val2: 'en' }}) language.
            </Trans>,
          )
        },
      )
    },
  )
}

/**
 * Fetches the Wiki documentation content.
 * Retrieves the documentation content based on the user's language preference and updates the settings with the fetched content.
 * If the content for the user's language is not available, it fetches the content for the default language.
 * Displays a popup message if an error occurs during the fetch process.
 *
 * @param {function} dispatch - The Redux dispatch function.
 */
export function getWikiDocLanguage(dispatch) {
  const suffix = 'manual'
  const fisrtLang = localStorage.getItem('i18nextLng').slice(0, 2)
  const firstLangPromise = getHelperPromise(fisrtLang, suffix)
  firstLangPromise.then(
    function () {
      dispatch(fetchChangeSettings('wikiCODDoc', fisrtLang))
    },
    function () {
      const defaultLangPromise = getHelperPromise('en', suffix)
      defaultLangPromise.then(
        function () {
          dispatch(fetchChangeSettings('wikiCODDoc', 'en'))
        },
        function () {
          dispatch(fetchChangeSettings('wikiCODDoc', null))
        },
      )
    },
  )
}

/**
 * Fetches the helper content based on the specified language and suffix.
 * Constructs the URL for the helper content and fetches it from the server.
 * If the server response is not OK, it rejects the promise with the response details.
 * If the server response is OK, it resolves the promise with the response text.
 *
 * @param {string} lang - The language code for the helper content.
 * @param {string} suffix - The suffix for the helper content URL.
 * @returns {Promise<string>} A promise that resolves with the helper content.
 */
export function getHelperPromise(lang, suffix) {
  return new Promise((resolve, reject) => {
    // const url = PUBLIC_URL + '/page_web/fujitsu_alphacod_prod_' + lang + '_helper.html'
    const url =
      localStorage.getItem('__config.json__ALPHACOD_WIKI_URL') +
      'doku.php?id=fujitsu:alphacod:prod:' +
      lang +
      ':' +
      suffix
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.text()
      })
      .then((htmlContent) => {
        if (
          htmlContent.indexOf('<title>fujitsu:alphacod:prod:' + lang + ':' + suffix + ' [') !==
            -1 &&
          htmlContent.indexOf('id="this_topic_does_not_exist_yet"') === -1
        ) {
          resolve(htmlContent)
        } else {
          reject(new Error('Error'))
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Give this function "window.location.hostname" and it will return the parent domain
/**
 * Retrieves the parent domain from a given hostname.
 *
 * @param {string} hostname - The hostname from which to extract the parent domain.
 * @returns {string} The parent domain.
 */
export function getParentDomain(hostname) {
  var parts = hostname.split('.')
  if (parts.length > 2) {
    return parts[parts.length - 2] + '.' + parts[parts.length - 1]
  } else {
    return hostname
  }
}

/**
 * Copies the provided text to the clipboard.
 *
 * @param {Event} event - The event object.
 * @param {string} text - The text to be copied to the clipboard.
 */
export function copyToClipboard(event, text) {
  const myT = t
  event.stopPropagation()
  event.preventDefault()
  if (!navigator.clipboard) {
    let textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      const msg = successful
        ? myT('view.errorBoundary.copy.succes')
        : myT('view.errorBoundary.copy.unsucces')
      console.log(myT('view.errorBoundary.copy.return_msg') + ' ' + msg)
    } catch (err) {
      console.error(myT('view.errorBoundary.copy.error_msg'), err)
    }

    document.body.removeChild(textArea)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log(myT('view.errorBoundary.copy.async_succes_msg'))
    },
    function (err) {
      console.error(myT('view.errorBoundary.copy.error_msg'), err)
    },
  )
}

/**
 * Checks if the retired status is available for the user based on the batch information.
 *
 * @param {object} batch - The batch object containing user and status information.
 * @returns {boolean} True if the retired status is available, false otherwise.
 */
export function isretiredAvailableForUser(batch) {
  if (batch) {
    const user = JSON.parse(localStorage.getItem('SESSION_user_json'))
    if (user.admin) {
      return true
    }
    const listBatchId = batch.teams.map((bt) => bt.id)
    const userTeams = user.teams
    const userTeamsForBatch = userTeams.filter((ut) => listBatchId.includes(ut.id))
    return !userTeamsForBatch.every((utfb) => utfb.role !== 'admin')
  }
  return false
}

/**
 * Checks if setting the calibration reference is available for the user based on the batch information.
 *
 * @param {object} batch - The batch object containing user and status information.
 * @returns {boolean} True if setting the calibration reference is available, false otherwise.
 */
export function isSetCalibRefAvailableForUser(batch) {
  if (batch) {
    const user = JSON.parse(localStorage.getItem('SESSION_user_json'))
    if (user.admin) {
      return true
    }
    const listBatchId = batch.teams.map((bt) => bt.id)
    const userTeams = user.teams
    const userTeamsForBatch = userTeams.filter((ut) => listBatchId.includes(ut.id))
    return !userTeamsForBatch.every((utfb) => utfb.role !== 'admin')
  }
  return false
}

//TODO To be deleted after purchasing a license
/**
 * Hides the watermark from the application.
 */
export function hideWatermark() {
  function getElementByTextContent(selector, textContent) {
    let elements = document.querySelectorAll(selector)
    for (let i = 0; i < elements.length; i++) {
      if (
        elements[i].textContent.trim() === textContent.trim() &&
        !elements[i].hasAttribute('class')
      ) {
        const hasOnlyTextChildren = Array.from(elements[i].childNodes).every(function (child) {
          return (
            child.nodeType === 3 || (child.nodeType === 1 && child.tagName.toLowerCase() === 'br')
          )
        })
        if (hasOnlyTextChildren) {
          return elements[i]
        }
      }
    }
    return null
  }
  let divElement = getElementByTextContent('div', 'MUI X Missing license key')
  if (divElement) {
    divElement.style.display = 'none'
  }
}

/**
 * Checks if the user has permission to modify the calibration.
 *
 * @param {object} batch - The batch object containing user and status information.
 * @returns {boolean} True if the user can modify the calibration, false otherwise.
 */
export function canIModifyTheCalibration(batch) {
  if (batch) {
    const user = JSON.parse(localStorage.getItem('SESSION_user_json'))
    if (user?.admin) {
      return true
    }
    const listBatchId = batch.teams.map((bt) => bt.id)
    const userTeams = user?.teams
    const userTeamsForBatch = userTeams?.filter((ut) => listBatchId.includes(ut.id))
    return !userTeamsForBatch?.every((utfb) => utfb.role !== 'admin')
  } else {
    return false
  }
}

/**
 * A dictionary of flags used in the application.
 * The keys represent the flag names, and the values are the corresponding flag details.
 */
export const flagsDict = new Map([
  ['area', 'ISTD area'],
  ['area', 'Blank area'],
  // ['mass_conc', 'Concentration'],
  ['amt_conc', 'Concentration'],
  ['Bio matrix', 'Concentration'],
  ['Dev std end', 'Concentration'],
  ['s_n', 'S/N'],
  ['devAccuracy', 'Accuracy'],
  ['devRrt', 'Dev RRT Std end'],
  ['devRrt', 'Dev RRT Calibration'],
  ['areaRatio', 'Validity domain'],
  ...Array.from(
    {
      length: Number(
        localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'),
      ),
    },
    (_, i) => [`amt_conc_${i + 1}`, 'Concentration'],
  ),
])

/**
 * Customizes the cell content with flags.
 *
 * @param {Array} flags - The array of flags to be applied.
 * @param {string} columnKey - The key of the column to be customized.
 * @param {string} classes - The CSS classes to be applied.
 * @returns {JSX.Element} The customized cell content.
 */
export const customCellWithFlag = (flags, columnKey, classes) => {
  var flags_dict = new Map()
  flags_dict.set('', -1)
  flags_dict.set('info', 0)
  flags_dict.set('warning', 1)
  flags_dict.set('error', 2)
  var level = ''
  var flagFiltered = []

  function getUpperFlag(flag, tag, columnKey, level) {
    if (flag.title === columnKey) {
      let icon = null
      switch (flag.level) {
        case 'info':
          icon = <InfoIcon className={classes.flagInfo} />
          break
        case 'warning':
          icon = <WarningIcon className={classes.flagWarning} />
          break
        case 'error':
          icon = <ErrorIcon className={classes.flagError} />
          break
        default:
          icon = null
          break
      }
      let msg =
        // flagFiltered.push(icon + ' - ' + tag + ': ' + flag.reason)
        // flagFiltered.push(`${icon} + ' - ' + ${tag} + ': ' + ${flag.reason}`)
        flagFiltered.push(
          <Fragment>
            {' '}
            {icon} {tag}: {flag.reason}
          </Fragment>,
        )
    }
    if (flag.title === columnKey && flags_dict.get(flag.level) > flags_dict.get(level)) {
      return (level = flag.level)
    }
    return level
  }

  const flags_globals = flags.globals ? flags.globals : []
  flags_globals.forEach((flag) => {
    level = getUpperFlag(flag, '[Globals]', columnKey, level)
  })
  const flags_channels = flags.channels ? flags.channels : {}
  const flags_channels_entries = Object.entries(flags_channels)
  if (flags_channels_entries.length > 0 && flags_dict.get(level) !== 2) {
    flags_channels_entries.forEach((channel, index1) => {
      channel[1].forEach((flag) => {
        let index = index1 + 1
        level = getUpperFlag(flag, 'Ch.' + index, columnKey, level)
      })
    })
  }
  return [level, flagFiltered]
}

/**
 * Checks if the execution time is active.
 *
 * @returns {boolean} True if the execution time is active, false otherwise.
 */
export function isExecutionTimeActive() {
  return sessionStorage.getItem('table_execution_time')
    ? sessionStorage.getItem('table_execution_time') === 'true'
    : false
}

/**
 * Checks if Clippy is activated.
 *
 * @returns {boolean} True if Clippy is activated, false otherwise.
 */
export function isClippyActivated() {
  return localStorage.getItem('SETTINGS_assistant_extra')
    ? localStorage.getItem('SETTINGS_assistant_extra') === 'true'
    : false
}

/**
 * Provides additional information based on the given row and matrix.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {object} row - The row object containing data.
 * @param {object} matrix - The matrix object containing data.
 */
export function tellMeMore(dispatch, row, matrix) {
  const message = t('view.dialogs.assistant.tell_me_more_msg', {
    val1: matrix ? matrix : 'apple',
    val2: row.name,
  })
  dispatch(fetchChangeSettings('assistantPopup', true))
  dispatch(
    fetchAddUserMessage({
      date: new Date(),
      msg: message,
      is_assistant: false,
      tell_me_more: true,
    }),
  )
}

/**
 * Provides additional information based on the given image and data.
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @param {string} img - The image URL or identifier.
 * @param {object} data - The data object containing additional information.
 */
export function tellMeMoreImg(dispatch, img, data) {
  const message = t('view.dialogs.assistant.tell_me_more_img', {
    val: data.name,
  })
  dispatch(fetchChangeSettings('assistantPopup', true))
  dispatch(
    fetchAddUserMessage({
      date: new Date(),
      img: img,
      msg: message,
      is_assistant: false,
      tell_me_more: true,
    }),
  )
}

/**************************** GET THE TOOLTIP WITH WIKI **********************************/
/**
 * Retrieves the tooltip content based on the provided key and wiki helper.
 *
 * @param {string} key - The key to identify the tooltip content.
 * @param {object} wikiCODHelper - The wiki helper object containing tooltip information.
 * @returns {string} The tooltip content.
 */
function getTooltipContent(key, wikiCODHelper) {
  const startMarker = 'tooltip_start_' + key + '"></a>'
  const endMarker = '<a name="' + 'tooltip_end_' + key
  const startIndex = wikiCODHelper?.indexOf(startMarker)
  const endIndex = wikiCODHelper?.indexOf(endMarker)

  if (startIndex !== -1 && endIndex !== -1) {
    return wikiCODHelper?.slice(startIndex + startMarker.length, endIndex)
  } else {
    return ''
  }
}
/**
 * Retrieves the header tooltip component.
 *
 * @param {object} params - The parameters for the tooltip.
 * @param {object} wikiCODHelper - The wiki helper object containing tooltip information.
 * @param {function} customHeader - The custom header function.
 * @returns {JSX.Element} The header tooltip component.
 */
export function getHeaderTooltipCmp(params, wikiCODHelper, customHeader) {
  const tooltipContent = getTooltipContent(params.field, wikiCODHelper)
  return (
    <Tooltip
      disableHoverListener={tooltipContent === '' || params.colDef.hideHeaderTooltip}
      placement="top"
      arrow
      title={
        <div style={{ fontSize: 13, padding: 5, fontWeight: 100, lineHeight: '1.3em' }}>
          <div
            dangerouslySetInnerHTML={{
              __html: tooltipContent ? tooltipContent : '',
            }}
          />
        </div>
      }
    >
      <span
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          fontWeight: 'var(--unstable_DataGrid-headWeight)',
        }}
      >
        {customHeader ? customHeader : params.colDef.headerName}
      </span>
    </Tooltip>
  )
}

/**************************** COLOR STYLE FOR DATAGRID **********************************/
/**
 * A styled component for the DataGridPro with striped rows.
 *
 * @param {object} theme - The theme object provided by the styled-components library.
 * @returns {JSX.Element} The styled DataGridPro component.
 */
export const StripedDataGridPro = styled(DataGridPro)(({ theme }) => {
  const secondaryColor = GET_SECONDARY_COLOR() || '#000000'

  const safeAlpha = (color, value) => {
    try {
      return alpha(color, value)
    } catch {
      return 'rgba(0, 0, 0, 0)'
    }
  }

  return {
    ['& .customCell, & .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within']:
      {
        border: 'none',
        outline: 'none',
      },
    [`& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within`]: {
      outline: 'none',
    },
    [`& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainerContent`]: {
      marginLeft: 13,
      width: 50,
      height: 50,
    },
    [`& .check-box-selection`]: {
      justifyContent: 'center',
    },
    [`& .loading`]: {
      opacity: 0.5,
      '& .customCell': {
        cursor: 'wait',
      },
    },
    [`& .selection`]: {
      backgroundColor: safeAlpha(secondaryColor, 0.12) + '!important',
      '&:hover, &.Mui-hovered': {
        backgroundColor: safeAlpha(secondaryColor, 0.3) + '!important',
      },
    },
    ['& .MuiDataGrid-row.Mui-selected']: {
      backgroundColor: '#ffffff',
      '&:hover, &.Mui-hovered': {
        backgroundColor: '#0000000a',
      },
    },
    [`& .even`]: {
      backgroundColor: '#fafafa',
      '&:hover, &.Mui-hovered': {
        backgroundColor: '#0000000a',
      },
      '&.Mui-selected': {
        backgroundColor: '#fafafa',
        '&:hover, &.Mui-hovered': {
          backgroundColor: '#0000000a',
        },
      },
    },
  }
})

/**************************** UUID GENERATOR **********************************/
/**
 * Generates a UUID (Universally Unique Identifier).
 *
 * @returns {string} A randomly generated UUID.
 */
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**************************** JSON TOOLS **********************************/
/**
 * Computes the difference between two JSON objects.
 *
 * @param {object} originJson - The original JSON object.
 * @param {object} modifiedJson - The modified JSON object.
 * @returns {object} The differences between the original and modified JSON objects.
 */
export function jsonDiff(originJson, modifiedJson) {
  const result = {}
  if (Object.is(originJson, modifiedJson)) {
    return undefined
  }
  if (!modifiedJson || typeof modifiedJson !== 'object') {
    return modifiedJson
  }
  Object.keys(originJson || {})
    .concat(Object.keys(modifiedJson || {}))
    .forEach((key) => {
      let originDoesNotExist = false
      let destinatinationDoesNotExist = false
      if (modifiedJson[key] !== originJson[key] && !Object.is(originJson[key], modifiedJson[key])) {
        // result[key] = { 'Old value': obj1[key], 'New value': obj2[key] }
        if (modifiedJson[key] === undefined) {
          // Case for a deleted prop
          destinatinationDoesNotExist = true
          result[key] = JSON.stringify(originJson[key])
        } else if (originJson[key] === undefined) {
          // Case for a new prop
          originDoesNotExist = true
          result[key] = JSON.stringify(modifiedJson[key])
        } else {
          // Case for a modified value
          result[key] = JSON.stringify(['#', originJson[key], '$SEP$', modifiedJson[key], '#'])
            .replace('["#",', '#@$#START#')
            .replace(',"#"]', '#END#@$#')
            .replaceAll('","$SEP$","', '"#SEPARATOR#"')
            .replaceAll(',"$SEP$",', '#SEPARATOR#')
        }
      }
      if (typeof modifiedJson[key] === 'object' && typeof originJson[key] === 'object') {
        const value = jsonDiff(originJson[key], modifiedJson[key])
        if (value !== undefined) {
          result[key] = value
        }
      }
      if (originDoesNotExist) {
        result['#ORIGINDOESNOTEXISTSTART#' + key] =
          '#ORIGIN_D_N_E#' + result[key] + '#ORIGINDOESNOTEXISTEND#'
        delete result[key]
      }
      if (destinatinationDoesNotExist) {
        result['#DESTINATIONDOESNOTEXISTSTART#' + key] =
          '#DESTINATION_D_N_E#' + result[key] + '#DESTINATIONDOESNOTEXISTEND#'
        delete result[key]
      }
    })
  return result
}

/**
 * Converts the differences between two JSON objects into a more readable format.
 *
 * @param {object} diffs - The differences between two JSON objects.
 * @returns {object} The converted differences in a readable format.
 */
export function convertJsonDiff(diffs) {
  if (diffs) {
    return diffs
      .replaceAll('\\"', '"')
      .replaceAll('"#ORIGINDOESNOTEXISTSTART#', '#ORIGINDOESNOTEXIST#"')
      .replaceAll('#ORIGINDOESNOTEXISTEND#"', '#ORIGINDOESNOTEXIST#')
      .split('#ORIGINDOESNOTEXIST#')
      .map((oldOriginSplited) => {
        if (oldOriginSplited.indexOf('#ORIGIN_D_N_E#') !== -1) {
          return (
            <span style={{ color: 'green', display: 'inline-block' }}>
              <AddCircleOutlineIcon style={{ margin: '-8px 5px -8px -17px', width: 12 }} />
              {/*<span>{oldOriginSplited.replace('"#ORIGIN_D_N_E#', '').replaceAll('\\n', '<br>')}</span>*/}
              {/*<span style={{ marginLeft: 15 }}>*/}
              <span>
                {JSON.stringify(
                  JSON.parse('{' + oldOriginSplited.replace('"#ORIGIN_D_N_E#', '') + '}'),
                  null,
                  2,
                )}
              </span>
            </span>
          )
        } else {
          return oldOriginSplited
            .replaceAll('"#DESTINATIONDOESNOTEXISTSTART#', '#DESTINATIONDOESNOTEXIST#"')
            .replaceAll('#DESTINATIONDOESNOTEXISTEND#"', '#DESTINATIONDOESNOTEXIST#')
            .split('#DESTINATIONDOESNOTEXIST#')
            .map((originSplited, idx) => {
              if (originSplited.indexOf('#DESTINATION_D_N_E#') !== -1) {
                return (
                  <span key={idx} style={{ color: 'red', display: 'inline-block' }}>
                    <HighlightOffIcon style={{ margin: '-8px 5px -8px -17px', width: 12 }} />
                    <span>
                      {JSON.stringify(
                        JSON.parse('{' + originSplited.replace('"#DESTINATION_D_N_E#', '') + '}'),
                        null,
                        2,
                      )}
                    </span>
                  </span>
                )
              } else {
                let result = originSplited
                  .replaceAll('"#@$#START#', '#@$#')
                  .replaceAll('#END#@$#"', '#@$#')
                result = result.split('#@$#')
                return result.map((res, index) => {
                  if (res.indexOf('#SEPARATOR#') !== -1) {
                    const resultSplited = res.split('#SEPARATOR#')
                    return (
                      <span key={index}>
                        <span style={{ color: 'red' }}>{resultSplited[0]}</span>
                        <ArrowRightAltIcon style={{ margin: '-8px 5px' }} />
                        <span style={{ color: 'green' }}>{resultSplited[1]}</span>
                      </span>
                    )
                  } else {
                    return res
                  }
                })
              }
            })
        }
      })
  } else {
    return '{}'
  }
}
