// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React from 'react'

/**
 * Action type for getting settings.
 */
export const GET_SETTINGS = 'GET_SETTINGS'

/**
 * Action type for adding settings.
 */
export const ADD_SETTINGS = 'ADD_SETTINGS'

/**
 * Action type for changing a setting.
 */
export const CHANGE_SETTING = 'CHANGE_SETTING'

/**
 * Action type for resetting settings.
 */
export const RESET_SETTINGS = 'RESET_SETTINGS'

/**
 * Async action to fetch and dispatch the getSettings action.
 * @returns {Function} A function that takes dispatch as an argument and dispatches the getSettings action.
 */
export function fetchGetSettings() {
  return async (dispatch) => {
    return dispatch(getSettings())
  }
}
/**
 * Action creator for getting settings.
 * @returns {Object} An action object with type GET_SETTINGS.
 */
export function getSettings() {
  return {
    type: GET_SETTINGS,
  }
}
/**
 * Async action to fetch and dispatch the setChangeSettings action.
 * @param {string} setting - The setting to change.
 * @param {any} value - The new value for the setting.
 * @returns {Function} A function that takes dispatch as an argument and dispatches the setChangeSettings action.
 */
export function fetchChangeSettings(setting, value) {
  return async (dispatch) => {
    return dispatch(setChangeSettings(setting, value))
  }
}
/**
 * Action creator for changing a setting.
 * @param {string} setting - The setting to change.
 * @param {any} value - The new value for the setting.
 * @returns {Object} An action object with type CHANGE_SETTING, setting, and value.
 */
export function setChangeSettings(setting, value) {
  return {
    type: CHANGE_SETTING,
    setting: setting,
    value: value,
  }
}
