// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { genericStyles, TransitionBottom } from '../common/Style'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import { GET_SECONDARY_COLOR, PUBLIC_URL } from '../../utils/config'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import DialogActions from '@mui/material/DialogActions'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Paper } from '@mui/material'
import Stack from '@mui/material/Stack'
import ReactJson from 'react-json-view'
import Divider from '@material-ui/core/Divider'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { styled } from '@mui/material/styles'
import NumbersIcon from '@mui/icons-material/Numbers'
import FontDownloadIcon from '@mui/icons-material/FontDownload'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CompressIcon from '@mui/icons-material/Compress'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import { alpha } from '@material-ui/core'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import CancelIcon from '@mui/icons-material/Cancel'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import SplitterLayout from 'react-splitter-layout'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { RichTreeView } from '@mui/x-tree-view'
import ArticleIcon from '@mui/icons-material/Article'
import { fetchGetMethodFiles } from '../../redux/screening/actions'
import { fetchChangeSettings } from '../../redux/settings/actions'
import Loading from '../Loading'
import { convertJsonDiff, jsonDiff } from '../../utils/utilities'
import DialogContentText from '@mui/material/DialogContentText'
import WarningIcon from '@mui/icons-material/Warning'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

/**
 * Styling hook for custom CSS
 * @type {(props?: any) => ClassNameMap<string>}
 */
const useStyles = makeStyles((theme) => ({
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: 'white',
    textShadow: '2px 1px 10px white',
  },
  button: {
    // '& .expertMode': {
    //   display: 'none',
    // },
    // '& .normalMode': {
    //   display: 'block',
    // },
    // '&:hover .normalMode': {
    //   display: 'none',
    // },
    // '&:hover .expertMode': {
    //   display: 'block',
    // },
    '& .flip-box-inner': {
      position: 'relative',
      width: 60,
      height: 60,
      transition: 'transform 0.4s',
      transformStyle: 'preserve-3d',
    },
    '&:hover .flip-box-inner': {
      transform: 'rotateY(180deg)',
    },
    '& .flip-box-front, .flip-box-back': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      '-webkit-backface-visibility': 'hidden',
      backfaceVisibility: 'hidden',
    },
    '& .flip-box-back': {
      position: 'relative',
      transform: 'rotateY(180deg)',
    },
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
  customRootDialog: {
    fontSize: 14,
    height: '80%',
  },
  customConfirmDialog: {
    maxHeight: '70%',
  },
  infoPaper: {
    color: theme.palette.secondary.main,
    '& .MuiTableCell-body': {
      color: 'white',
      padding: '5px 14px',
      border: 'none',
    },
  },
  collapsedCmp: {
    margin: '0px 6px',
    color: '#ffffffd4',
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after':
      {
        border: 'none',
      },
    '& .MuiSelect-select.MuiSelect-select, & .MuiSelect-icon': {
      color: '#ffffffd4',
    },
  },
  reactJsonCmp: {
    '& .react-json-view': {
      height: '100%',
      overflow: 'auto',
      backgroundColor: '#2e2f28 !important',
    },
    '& .react-json-view .key-modal-request > div': {
      zIndex: 1,
      position: 'fixed !important',
      marginTop: '66px  !important',
      marginLeft: '377px !important',
    },
  },
  firstSplitter: {
    height: 'calc(100% - 182px)',
    '& > .layout-pane': {
      // padding: '1px 8px 14px 0px',
      overflow: 'hidden',
      paddingLeft: 10,
      paddingBottom: 1,
    },
    '& > .layout-pane:first-child': {
      padding: '17px 5px 17px 0px',
      minWidth: 200,
    },
  },
  customTreeItem: {
    '& > .MuiTreeItem-content:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.5),
    },
    '& > .Mui-selected, & > .Mui-selected.Mui-focused, & > .Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  customTreeRootItem: {
    '& > .MuiTreeItem-content:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
    '& > .Mui-selected, & > .Mui-selected.Mui-focused, & > .Mui-selected:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },
  questionTitle: {
    color: theme.palette.secondary.main,
  },
  configurationTitle: {
    marginTop: 6,
    color: theme.palette.secondary.main,
  },
  richTreeView: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '& .Mui-selected': {
      backgroundColor: theme.palette.secondary.main + ' !important',
      width: '100%',
      paddingRight: 15,
    },
    '& .MuiTreeItem-label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .MuiTreeItem-iconContainer': {
      color: '#ae81ff',
    },
    '& .Mui-expanded .MuiTreeItem-iconContainer': {
      color: '#66d9ef',
    },
  },
}))
const defaultProps = {
  theme: 'monokai',
  src: null,
  collapsed: 1,
  collapseStringsAfter: false,
  onAdd: false,
  onEdit: false,
  onDelete: false,
  displayObjectSize: false,
  enableClipboard: false,
  indentWidth: 4,
  displayDataTypes: false,
  iconStyle: 'triangle',
  info: true,
}

const secondaryColor = GET_SECONDARY_COLOR()

/**
 * StyledToggleButtonGroup component is a styled component for the ToggleButtonGroup component.
 * @type {StyledComponent<PropsOf<(props: ToggleButtonGroupProps) => JSX.Element> & MUIStyledCommonProps<Theme>, {}, {}>}
 */
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    border: 0,
    '&.MuiButtonBase-root': {
      margin: 1,
      color: '#ffffffd4',
    },
    '&.MuiButtonBase-root.Mui-selected': {
      backgroundColor: alpha(secondaryColor, 0.2),
      color: secondaryColor,
      margin: 1,
    },
    '&.MuiDivider-root': {
      height: '60%',
      top: '20%',
      position: 'relative',
      backgroundColor: '#ffffff1f',
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))

/**
 * MethodManagerDialog component is a React component that displays a dialog for managing method files.
 * It uses various Material-UI components for styling and layout, and Redux for state management.
 * The component is wrapped with `withRouter` to access routing-related props.
 *
 * @component
 * @param {object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered component.
 */
const MethodManagerDialog = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const genericClasses = genericStyles()
  const refConfigMenu = useRef()

  const [collapsed, setCollapsed] = React.useState(1)
  const [options, setOptions] = React.useState(() => ['info'])
  const [currentMethodFileSelected, setCurrentMethodFileSelected] = React.useState()
  const [currentMethodFileEditable, setCurrentMethodFileEditable] = React.useState()
  const [properties, setProperties] = React.useState(() => defaultProps)
  const [expanded, setExpanded] = React.useState([])
  const [openSaveConfirm, setOpenSaveConfirm] = React.useState(false)

  const configurations = useSelector((state) => state.configuration.configurations)

  const openDialog = useSelector((state) => state.settings.displayMethodManagerDialog)
  const isFetching = useSelector((state) => state.screening.isFetchingMethodFiles)
  const methodFiles = useSelector((state) => state.screening.methodFiles)

  /**
   * useEffect hook to update the properties state based on the selected options.
   * It sets various properties such as displayDataTypes, displayObjectSize, onEdit, onAdd, onDelete, enableClipboard, and info
   * based on the presence of corresponding values in the options array.
   *
   * @param {Array} options - The array of selected options.
   */
  useEffect(() => {
    setProperties({
      ...defaultProps,
      displayDataTypes: options.indexOf('type') !== -1,
      displayObjectSize: options.indexOf('size') !== -1,
      onEdit: options.indexOf('edit') !== -1,
      onAdd: options.indexOf('add') !== -1,
      onDelete: options.indexOf('delete') !== -1,
      enableClipboard: options.indexOf('clipboard') !== -1,
      info: options.indexOf('info') !== -1,
    })
  }, [options])

  /**
   * useMemo hook to convert the method files into a dictionary format using their _id as keys.
   * This allows easier access to the method files by their unique ID.
   */
  const methodFilesConverted = useMemo(() => {
    let globalResult = {}
    if (methodFiles.length > 0) {
      globalResult = Object.assign(
        {},
        ...methodFiles.map(function (methodFile) {
          return {
            [methodFile._id]: methodFile,
          }
        }),
      )
    }
    return globalResult
  }, [methodFiles])

  /**
   * useMemo hook to filter out unnecessary information, keeping only the "content" property from each method file.
   * This is used to simplify the method files data for further processing.
   */
  const methodFilesFiltered = useMemo(() => {
    return Object.keys(methodFilesConverted).reduce((result, key) => {
      result[key] = methodFilesConverted[key].content // Replace value with "content" property
      return result
    }, {})
  }, [methodFilesConverted])

  const [methodFilesJson, setMethodFilesJson] = React.useState(methodFilesFiltered)

  /**
   * useEffect hook to fetch method files when the dialog is opened.
   * It also sets the default selected method file after fetching.
   */
  useEffect(() => {
    if (openDialog) {
      const timeout = setTimeout(() => {
        dispatch(fetchGetMethodFiles()).then((result) => {
          setCurrentMethodFileSelected(result?.methodFiles?.[0]?._id)
        })
      }, 0) // Small delay to stabilize the DOM.
      return () => clearTimeout(timeout) // Cleanup.
    }
  }, [openDialog])

  /**
   * useEffect hook to update the method files JSON state when the filtered method files change.
   */
  useEffect(() => {
    setMethodFilesJson(methodFilesFiltered)
  }, [methodFilesFiltered])


  /**
   * Converts a flat array of items into a hierarchical tree based on the "parent_method.id".
   * It also formats the creation date of child items.
   *
   * @param {Array} items - Array of objects such as [{ _id, name, content }, ...]
   * @returns {Array} - Tree structure with root items and their respective children.
   */
  function buildTree(items) {
    const map = {}
    items.forEach((item) => {
      map[item._id] = {
        ...item,
        label: item.name,
        id: item._id, // Rename _id to id
        children: [],
      }
    })
    const usedAsChild = new Set()
    items.forEach((item) => {
      const childId = item.content?.parent_method?.id // The referenced id in parent_method
      if (childId && map[childId]) {
        const date = new Date(map[childId].creationDate * 1000) // Get the creation date of the item
        const formattedDate = date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
        map[item._id].children.push({ ...map[childId], label: '📅 ' + formattedDate })
        usedAsChild.add(childId)
      }
    })
    const roots = []
    items.forEach((item) => {
      if (!usedAsChild.has(item._id)) {
        roots.push({ ...map[item._id], editable: true })
      }
    })
    return roots
  }

  /**
   * useMemo hook to generate the tree structure of the method files.
   */
  const richTreeViewItems = useMemo(() => {
    return buildTree(methodFiles)
  }, [methodFiles])

  /**
   * useEffect hook to update the editable state of the current method file.
   * It checks if the current method file is in the list of rich tree view items.
   * richTreeViewItems is a tree structure where first level is roots (editables).
   */
  useEffect(() => {
    if (richTreeViewItems.length > 0) {
      setCurrentMethodFileEditable(
        richTreeViewItems.some((rtvi) => rtvi._id === currentMethodFileSelected),
      )
    }
  }, [currentMethodFileSelected, richTreeViewItems])

  /**
   * useMemo hook to determine whether the current user has permission to edit the configuration.
   * This depends on whether the user is an admin or belongs to a team with the necessary permissions.
   */
  const canIEditTheConfiguration = useMemo(() => {
    if (currentMethodFileSelected) {
      const user = JSON.parse(localStorage.getItem('SESSION_user_json'))
      if (user.admin) {
        return true
      }
      const configTeams = configurations.filter(
        (conf) => conf._id === currentMethodFileSelected,
      )?.[0]?.teams
      const userTeams = user.teams
      const userTeamsForConfig = userTeams.filter((ut) => configTeams.includes(ut.id))
      return !userTeamsForConfig.every((utfb) => utfb.role !== 'admin')
    }
    return false
  }, [configurations, currentMethodFileSelected])

  /**
   * Closes the dialog by dispatching the action to hide it.
   */
  function handleCloseDialog() {
    dispatch(fetchChangeSettings('displayMethodManagerDialog', false))
  }

  /**
   * Saves the size of the first pane in the layout (for the Method Manager).
   * Stores the size in local storage.
   */
  function onContentFirstSplitterSizeSave() {
    localStorage.setItem(
      'LAYOUT_methodManager_paneSize',
      refConfigMenu.current.state.secondaryPaneSize,
    )
  }

  /**
   * Handles the selection of a method file in the tree view.
   * Sets the selected method file's ID.
   */
  const handleSelect = (event, nodeId) => {
    setCurrentMethodFileSelected(nodeId)
  }

  /**
   * Handles changes in the selected options and updates the options state.
   */
  function handleOption(event, newOptions) {
    setOptions(newOptions)
  }

  /**
   * Handles the collapse/expand state change of the tree view.
   */
  function handleCollapsedChange(event) {
    setCollapsed(event.target.value)
  }

  /**
   * Handles the toggle (expand/collapse) of tree nodes.
   */
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  /**
   * Shows the save confirmation dialog when saving methods.
   */
  function handleSaveMethods() {
    setOpenSaveConfirm(true)
  }

  /**
   * Closes the save confirmation dialog without saving.
   */
  function handleCloseSaveConfirm() {
    setOpenSaveConfirm(false)
  }

  /**
   * Resets the modified method files to their original state.
   */
  function resetModification() {
    setMethodFilesJson(methodFilesFiltered)
  }

  /**
   * Handles the actual saving process for the method files.
   */
  function handleSaveConfirm() {
    //TODO: Save the configuration
  }

  /**
   * useMemo hook to compute the differences between the current and original method files in JSON format.
   */
  const jsonsDifferences = useMemo(() => {
    return Object.assign(
      {},
      ...Object.keys(methodFilesFiltered).map(function (methodFileFiltered_id) {
        return {
          [methodFileFiltered_id]: jsonDiff(
            methodFilesFiltered[methodFileFiltered_id],
            methodFilesJson[methodFileFiltered_id],
          ),
        }
      }),
    )
  }, [JSON.stringify(methodFilesJson)])

  /**
   * useMemo hook to process and format the differences, replacing IDs with their corresponding names.
   */
  const jsonsDifferencesToConfirm = useMemo(() => {
    const tmp = JSON.parse(JSON.stringify(jsonsDifferences))
    const tmpKeys = Object.keys(tmp)
    tmpKeys.forEach((jd_id) => {
      const conf = configurations.filter((conf) => conf._id === jd_id)[0]
      if (conf) {
        tmp[conf.name] = tmp[jd_id]
        delete tmp[jd_id]
      }
    })
    return tmp
  }, [JSON.stringify(jsonsDifferences)])

  return (
    <Fragment>
      <Dialog
        disableEnforceFocus
        autoFocus={false}
        open={openDialog}
        disablePortal={true}
        onClose={handleCloseDialog}
        TransitionComponent={TransitionBottom}
        aria-labelledby="dialog-title-user"
        fullWidth
        maxWidth={'lg'}
        classes={{
          paper: classes.customRootDialog,
        }}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Box className={classes.header}>
            <CardMedia
              className={classes.cardImg}
              component="img"
              alt="header image"
              height={130}
              image={PUBLIC_URL + '/images/messages_bkg_2.jpg'}
              title="Header image"
            />
            <span className={classes.titleCard}>
              {t('view.dashboardToolbar.actions.methodManager')}
            </span>
            <ArticleIcon className={genericClasses.icon} />
          </Box>
        </DialogTitle>
        <DialogContent
          style={{ padding: 0, background: '#424242', minHeight: 222 }}
          className={classes.reactJsonCmp}
        >
          <SplitterLayout
            customClassName={classes.firstSplitter}
            ref={refConfigMenu}
            onDragEnd={onContentFirstSplitterSizeSave}
            secondaryMinSize={220}
            primaryMinSize={460}
            primaryIndex={1}
            secondaryInitialSize={
              localStorage.getItem('LAYOUT_methodManager_paneSize')
                ? parseFloat(localStorage.getItem('LAYOUT_methodManager_paneSize'))
                : 220
            }
          >
            <ErrorBoundaryGuard>
              {isFetching ? (
                <Loading mode="miniCircle" size={10} />
              ) : (
                <RichTreeView
                  className={classes.richTreeView}
                  sx={{ height: '100%', overflow: 'auto', color: 'white' }}
                  items={richTreeViewItems}
                  getItemId={(item) => item.id}
                  slots={{
                    expandIcon: ArrowRightIcon,
                    collapseIcon: ArrowDropDownIcon,
                  }}
                  onSelectedItemsChange={handleSelect}
                  selectedItems={currentMethodFileSelected}
                  expandedItems={expanded}
                  onExpandedItemsChange={handleToggle}
                />
              )}
            </ErrorBoundaryGuard>

            <Fragment>
              <ErrorBoundaryGuard>
                {isFetching ? (
                  <Loading mode="miniCircle" size={10} />
                ) : (
                  <ReactJson
                    // name={configuration ? configuration.name : false}
                    name={false}
                    collapsed={collapsed >= 1 ? collapsed : collapsed === 0}
                    style={{ padding: 20, paddingTop: 67 }}
                    theme={properties.theme}
                    src={methodFilesJson[currentMethodFileSelected]}
                    collapseStringsAfterLength={properties.collapseStringsAfter}
                    onEdit={
                      currentMethodFileEditable && properties.onEdit
                        ? (e) => {
                            setMethodFilesJson((previousConfig) => {
                              return {
                                ...previousConfig,
                                [currentMethodFileSelected]: e.updated_src,
                              }
                            })
                          }
                        : false
                    }
                    onDelete={
                      currentMethodFileEditable && properties.onDelete
                        ? (e) => {
                            setMethodFilesJson((previousConfig) => {
                              return {
                                ...previousConfig,
                                [currentMethodFileSelected]: e.updated_src,
                              }
                            })
                          }
                        : false
                    }
                    onAdd={
                      currentMethodFileEditable && properties.onAdd
                        ? (e) => {
                            setMethodFilesJson((previousConfig) => {
                              return {
                                ...previousConfig,
                                [currentMethodFileSelected]: e.updated_src,
                              }
                            })
                          }
                        : false
                    }
                    displayObjectSize={properties.displayObjectSize}
                    enableClipboard={properties.enableClipboard}
                    indentWidth={properties.indentWidth}
                    displayDataTypes={properties.displayDataTypes}
                    iconStyle={properties.iconStyle}
                  />
                )}
              </ErrorBoundaryGuard>
              <Paper
                elevation={0}
                sx={{
                  display: 'flex',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  flexWrap: 'wrap',
                }}
                style={{ position: 'absolute', top: 10, left: 20, backgroundColor: '#424242' }}
              >
                <StyledToggleButtonGroup
                  size="small"
                  value={options}
                  onChange={handleOption}
                  aria-label="text formatting"
                >
                  <ToggleButton value="type" aria-label="type">
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.common.configurationExpert.dialog.expert.tooltip.type')}
                        </div>
                      }
                    >
                      <FontDownloadIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="size" aria-label="size">
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.common.configurationExpert.dialog.expert.tooltip.size')}
                        </div>
                      }
                    >
                      <NumbersIcon />
                    </Tooltip>
                  </ToggleButton>
                  {currentMethodFileEditable && (
                    <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                  )}
                  {currentMethodFileEditable && (
                    <ToggleButton
                      value="edit"
                      aria-label="edit"
                      disabled={!canIEditTheConfiguration}
                    >
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <div style={{ fontSize: 13, padding: 5 }}>
                            {t('view.common.configurationExpert.dialog.expert.tooltip.edit')}
                          </div>
                        }
                      >
                        <EditIcon style={{ opacity: canIEditTheConfiguration ? 1 : 0.2 }} />
                      </Tooltip>
                    </ToggleButton>
                  )}
                  {currentMethodFileEditable && (
                    <ToggleButton value="add" aria-label="add" disabled={!canIEditTheConfiguration}>
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <div style={{ fontSize: 13, padding: 5 }}>
                            {t('view.common.configurationExpert.dialog.expert.tooltip.add')}
                          </div>
                        }
                      >
                        <AddCircleIcon style={{ opacity: canIEditTheConfiguration ? 1 : 0.2 }} />
                      </Tooltip>
                    </ToggleButton>
                  )}
                  {currentMethodFileEditable && (
                    <ToggleButton
                      value="delete"
                      aria-label="delete"
                      disabled={!canIEditTheConfiguration}
                    >
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <div style={{ fontSize: 13, padding: 5 }}>
                            {t('view.common.configurationExpert.dialog.expert.tooltip.delete')}
                          </div>
                        }
                      >
                        <CancelIcon style={{ opacity: canIEditTheConfiguration ? 1 : 0.2 }} />
                      </Tooltip>
                    </ToggleButton>
                  )}
                  {/*<ToggleButton value="clipboard" aria-label="clipboard">*/}
                  {/*  <Tooltip*/}
                  {/*    arrow*/}
                  {/*    placement="top"*/}
                  {/*    title={<div style={{ fontSize: 13, padding: 5 }}>Enable clipboard</div>}*/}
                  {/*  >*/}
                  {/*    <ContentPasteIcon />*/}
                  {/*  </Tooltip>*/}
                  {/*</ToggleButton>*/}
                  <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    style={{ margin: '5px 5px 0px' }}
                    className={classes.collapsedCmp}
                  >
                    <Select value={collapsed} onChange={handleCollapsedChange}>
                      <MenuItem value={0}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <CompressIcon />
                          <span>
                            {t('view.common.configurationExpert.dialog.expert.menu.collapse')}
                          </span>
                        </Stack>
                      </MenuItem>
                      <MenuItem value={-1}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <CompressIcon />
                          <span>
                            {t('view.common.configurationExpert.dialog.expert.menu.uncollapse')}
                          </span>
                        </Stack>
                      </MenuItem>
                      <MenuItem value={1}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <CompressIcon />
                          <span>
                            {t('view.common.configurationExpert.dialog.expert.menu.depth1')}
                          </span>
                        </Stack>
                      </MenuItem>
                      <MenuItem value={2}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <CompressIcon />
                          <span>
                            {t('view.common.configurationExpert.dialog.expert.menu.depth2')}
                          </span>
                        </Stack>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {currentMethodFileEditable && (
                    <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                  )}
                  {currentMethodFileEditable && (
                    <ToggleButton value="info" aria-label="info">
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <div style={{ fontSize: 13, padding: 5 }}>
                            {t('view.common.configurationExpert.dialog.expert.tooltip.keyboard')}
                          </div>
                        }
                      >
                        <TipsAndUpdatesIcon />
                      </Tooltip>
                    </ToggleButton>
                  )}
                </StyledToggleButtonGroup>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  display: 'flex',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  flexWrap: 'wrap',
                }}
                style={{
                  display: currentMethodFileEditable
                    ? properties.info
                      ? 'block'
                      : 'none'
                    : 'none',
                  position: 'absolute',
                  top: 10,
                  right: 25,
                  backgroundColor: '#424242',
                }}
              >
                <div style={{ fontSize: 13, padding: 5 }} className={classes.infoPaper}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <div
                      style={{ fontWeight: 600, fontSize: 18, marginLeft: 14, marginBottom: 10 }}
                    >
                      {t('view.common.configurationExpert.dialog.expert.keyboard.title')}
                    </div>
                    <IconButton
                      color="inherit"
                      style={{ padding: 0, marginRight: 10, marginBottom: 10 }}
                      onClick={() => {
                        setOptions(options.filter((option) => option !== 'info'))
                      }}
                    >
                      <CancelPresentationIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            {t('view.common.configurationExpert.dialog.expert.keyboard.edit')}
                          </TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>ctrl + click</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            {t('view.common.configurationExpert.dialog.expert.keyboard.submit')}
                          </TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>ctrl + Enter</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            {t('view.common.configurationExpert.dialog.expert.keyboard.cancelEdit')}
                          </TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>Escape</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            {t('view.common.configurationExpert.dialog.expert.keyboard.add')}
                          </TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>Enter</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            {t('view.common.configurationExpert.dialog.expert.keyboard.cancelAdd')}
                          </TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>Escape</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Paper>
            </Fragment>
          </SplitterLayout>
        </DialogContent>
        <DialogActions style={{ padding: 8 }}>
          <Button
            variant="contained"
            className={classes.deletePrefsButton}
            startIcon={<RotateLeftIcon />}
            onClick={resetModification}
            disabled={Object.keys(jsonsDifferences).every(
              (jd) => jsonsDifferences[jd] === undefined,
            )}
          >
            {t('view.common.configurationExpert.dialog.expert.button.clear')}
          </Button>
          <div style={{ flex: 'auto' }}></div>
          <Button
            // autoFocus
            size={'medium'}
            onClick={handleSaveMethods}
            color="primary"
            disabled={Object.keys(jsonsDifferences).every(
              (jd) => jsonsDifferences[jd] === undefined,
            )}
          >
            {t('view.common.configurationExpert.dialog.expert.button.save')}
          </Button>
          <Button size={'medium'} onClick={handleCloseDialog} color="primary">
            {t('view.common.configurationExpert.dialog.expert.button.cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      {openSaveConfirm && (
        <Dialog
          open={openSaveConfirm}
          fullWidth
          maxWidth={'md'}
          disablePortal={true}
          TransitionComponent={TransitionBottom}
          onClose={handleCloseSaveConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{
            paper: classes.customConfirmDialog,
          }}
        >
          <DialogTitle className={classes.questionTitle}>
            {t('view.dashboardToolbar.actions.methodManager')}
          </DialogTitle>
          <DialogContent style={{ marginTop: 15 }}>
            <DialogContentText component="span">
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
              >
                <WarningIcon color="warning" style={{ height: 50, width: 50 }} />
                <div style={{ overflow: 'hidden', width: '100%' }}>
                  <div style={{ marginBottom: 10 }}>
                    {t('view.common.configurationExpert.dialog.expertSave.msg1') + ':'}
                  </div>
                  {Object.entries(jsonsDifferencesToConfirm).map((entry, idx) => (
                    <Fragment key={idx + entry[0]}>
                      <Stack
                        key={idx + entry[0]}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        className={classes.configurationTitle}
                      >
                        <DescriptionOutlinedIcon />
                        <div>{methodFilesConverted[entry[0]]?.name}</div>
                      </Stack>
                      <pre
                        style={{
                          fontSize: 13,
                          lineHeight: '16px',
                          background: '#efefef',
                          padding: 7,
                          margin: '0px 0px 20px',
                          color: '#444444',
                          overflow: 'auto',
                          maxHeight: 360,
                          width: 'calc(100% - 85px)',
                        }}
                      >
                        {convertJsonDiff(JSON.stringify(entry[1], null, 2))}
                      </pre>
                    </Fragment>
                  ))}
                  <div>{t('view.common.configurationExpert.dialog.expertSave.msg2') + '.'}</div>
                  <div style={{ marginTop: 20 }}>
                    {t('view.common.configurationExpert.dialog.expertSave.msg3') + '?'}
                  </div>
                </div>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveConfirm} autoFocus>
              {t('view.common.configurationExpert.dialog.expertSave.button.save')}
            </Button>
            <Button onClick={handleCloseSaveConfirm}>
              {t('view.common.configurationExpert.dialog.expertSave.button.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  )
})
export default MethodManagerDialog
