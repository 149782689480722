// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import Title from '../Title'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import { withRouter } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import {
  getComparator,
  stableSort,
  moveSampleToSubBatch,
  getClickAction,
  getHeaderTooltipCmp,
  handleValidation,
  canIModifyTheCalibration,
  updateISTD,
  customFormatDecimals,
  displayHumanReadable,
  callValidationDispatch,
  StripedDataGridPro,
} from '../../utils/utilities'
import {
  Molecule,
  Nothing,
  NumberOneIcon,
  Relance,
  StyledDialogTitle,
  TransitionTop,
} from '../common/Style'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Button from '@material-ui/core/Button/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import { getBatchById } from '../../redux/batches/selectors'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import NoteButton from '../common/NoteButton'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { fetchChangeSettings } from '../../redux/settings/actions'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import MoveDialog from './MoveDialog'
import { Stack } from '@mui/material'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { CustomNoRowsOverlay, getFlagIcon } from './Tools'
import { useGridApiRef } from '@mui/x-data-grid'
import Loading from '../Loading'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { getFlagName } from '../analysis/Tools'
import { flagsFilterOperators, validationFilterOperators } from '../common/ColumnFilters'
import { GridActionsCellItem, GridColumnMenu } from '@mui/x-data-grid-pro'
import { openAnalysisInNewTab } from '../compound/Tools'
import { fetchDisplayJson } from '../../redux/json/actions'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core'
import {
  fetchAddAnalysisVisibilityTag,
  fetchRemoveAnalysisVisibilityTag,
} from '../../redux/analyses/actions'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import AlphacodModifiedButtonIcon from '../common/AlphacodModifiedButtonIcon'
import ClearIcon from '@material-ui/icons/Clear'
import { fetchGetLogs } from '../../redux/logs/actions'
import { fetchGetCodDriveConfigs } from '../../redux/codDriveConfiguration/actions'
import {
  fetchUnitaryCalibrationsDistinct,
  fetchUnitaryCalibrationsFiltered,
} from '../../redux/unitaryCalibrations/actions'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Badge from '@material-ui/core/Badge'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import clsx from 'clsx'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import InputBase from '@material-ui/core/InputBase'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import { DATABASE } from '../../utils/config'

const useStyles = makeStyles((theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    height: '100%',
    display: 'contents',
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.common.black, 0.03),
    },
  },
  toolbar: {
    position: 'absolute',
    top: 48,
    left: 70,
    background: '#fff',
    padding: 5,
    paddingRight: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  actionDivider: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0px !important',
    margin: '5px 0px',
  },
  actionContainer: {
    '& button': {
      padding: 0,
      marginRight: 5,
      width: 24,
    },
    '& > button:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  formControl: {
    '& .MuiInput-root::before': {
      display: 'none',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '0px 24px 0px 0px !important',
      fontSize: 14,
    },
  },
  buttonCalib: {
    borderRadius: '18px !important',
    backgroundColor: '#e0e0e0',
    padding: '3px 12px 3px 14px',
    marginRight: 20,
    height: 32,
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
    '&.Mui-selected .MuiBadge-badge': {
      color: alpha(theme.palette.secondary.main, 0.8),
    },
  },
  progress: {
    paddingTop: '0px !important',
    paddingBottom: 10,
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  notSelected: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.06),
    },
    '& div:first-child': {
      color: alpha(theme.palette.common.black, 0.54),
    },
    '&:hover div:first-child': {
      color: theme.palette.secondary.main,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    // pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearIcon: {
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    color: 'black',
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '16ch',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
      },
    },
  },
  whiteSpace: {
    flexGrow: 1,
  },
  badgeCount: {
    '& .MuiBadge-badge': {
      right: '-12px',
      top: '-5px',
      color: alpha(theme.palette.primary.main, 0.6),
      background: 'white',
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountRect: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: alpha(theme.palette.primary.main, 0.6),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountRectSelected: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: alpha(theme.palette.secondary.main, 0.8),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  filterButton: {
    borderRadius: 18,
    border: '1px solid #c5c5c5',
    backgroundColor: 'rgb(224 224 224)',
    marginRight: 5,
    marginLeft: 15,
    width: 50,
    height: 34,
    padding: 8,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
  selectedButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    width: '100%',
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
})(LinearProgress)

const CustomDialog = withStyles((theme) => ({
  paper: {
    height: '100%',
    overflow: 'hidden',
    padding: 20,
  },
}))(Dialog)

const StyledToggleButton = withStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.secondary.light + ' !important',
    color: 'white !important',
  },
}))(ToggleButton)

const CalibTable = withRouter((props) => {
  const { batch, tableItemSelectedForColor, onDisplayCalib } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const apiRef = useGridApiRef()
  const searchRef = React.createRef()
  const unitaryCalibrationsFiltered = useSelector(
    (state) => state.unitaryCalibrations.unitaryCalibrationsFiltered,
  )
  const isFetchingUnitaryCalibrationsFiltered = useSelector(
    (state) => state.unitaryCalibrations.isFetchingUnitaryCalibrationsFiltered,
  )
  const isFetchingUnitaryCalibrationsDistinct = useSelector(
    (state) => state.unitaryCalibrations.isFetchingUnitaryCalibrationsDistinct,
  )
  const unitaryCalibrationsDistinct = useSelector(
    (state) => state.unitaryCalibrations.unitaryCalibrationsDistinct,
  )
  const wikiCODHelper = useSelector((state) => state.settings.wikiCODHelper)
  const featuresConfig = useSelector((state) => state.global.featuresConfig)

  const [settingsTimer, setSettingsTimer] = React.useState(null)
  const [settingsFormat, setSettingsFormat] = useState({})
  const [settingsDecimals, setSettingsDecimals] = useState({})
  const [rowSelectionModel, setRowSelectionModel] = React.useState([])
  const [openBatchMove, setOpenBatchMove] = React.useState(false)
  const [selection, setSelection] = React.useState([])
  const [openExtentedView, setOpenExtentedView] = React.useState(false)
  const [moveBatch, setMoveBatch] = React.useState(null)
  const [search, setSearch] = React.useState('')
  const [filterSelected, setFilterSelected] = React.useState(
    localStorage.getItem('BATCH_filter_calibration')
      ? localStorage.getItem('BATCH_filter_calibration')
      : 'istd',
  )
  const [untreatedChecked, setUntreatedChecked] = React.useState(
    localStorage.getItem('BATCH_filter_untreated')
      ? JSON.parse(localStorage.getItem('BATCH_filter_untreated')) === true
      : false,
  )
  const [validationOk, setValidationOk] = React.useState(
    localStorage.getItem('BATCH_filter_detected')
      ? JSON.parse(localStorage.getItem('BATCH_filter_detected')) === true
      : false,
  )
  const [validationKo, setValidationKo] = React.useState(
    localStorage.getItem('BATCH_filter_notDetected')
      ? JSON.parse(localStorage.getItem('BATCH_filter_notDetected')) === true
      : false,
  )
  const [open, setOpen] = React.useState(false)
  const [newISTDGroup, setNewISTDGroup] = React.useState(null)
  const [itemToUpdate, setItemToUpdate] = React.useState(null)
  const [timer, setTimer] = React.useState(null)
  const [displayClear, setDisplayClear] = React.useState(false)

  const defaultState = {
    sorting: {
      sortModel: [
        {
          field: 'name',
          sort: 'asc',
        },
      ],
    },
  }

  useEffect(() => {
    dispatch(
      fetchUnitaryCalibrationsFiltered(
        props.match.params.batchId,
        filterSelected,
        untreatedChecked,
        validationOk,
        validationKo,
        localStorage.getItem('BATCH_sorter_calibration'),
      ),
    )
    dispatch(fetchUnitaryCalibrationsDistinct(props.match.params.batchId, untreatedChecked))
  }, [])

  const saveSnapshot = React.useCallback(
    (fullscreenMode) => {
      if (apiRef?.current?.exportState) {
        const stateSnapshot = apiRef.current.exportState()
        if (Object.keys(stateSnapshot).length !== 0) {
          localStorage.setItem('BATCH_calib_state', JSON.stringify(stateSnapshot))
        }
      }
    },
    [apiRef],
  )

  const canEditCalib = useMemo(() => {
    const edit_calibration = featuresConfig?.content?.edit_calibration
    const param_exist = typeof edit_calibration === 'boolean'
    const getRights = canIModifyTheCalibration(batch)
    return param_exist ? edit_calibration && getRights : true
  }, [featuresConfig, batch])

  const echClient = useMemo(() => {
    return getEchClient()
  }, [JSON.stringify(unitaryCalibrationsFiltered), search])

  const selectionEmpty = useMemo(() => {
    return selection.length === 0
  }, [JSON.stringify(selection)])

  function getEchClient() {
    return unitaryCalibrationsFiltered
      .filter((uc) => {
        if (search === '') return true
        return (
          (uc.name && uc.name.toLowerCase().includes(search.toLowerCase())) ||
          (uc.state && uc.state.toLowerCase().includes(search.toLowerCase())) ||
          (uc.event && uc.event.toString().toLowerCase().includes(search.toLowerCase())) ||
          (uc.rt && uc.rt.toString().toLowerCase().includes(search.toLowerCase())) ||
          (uc.r2 && uc.r2.toString().toLowerCase().includes(search.toLowerCase())) ||
          (uc.istd && uc.istd.toString().toLowerCase().includes(search.toLowerCase())) ||
          (uc.ret_time && uc.ret_time.toString().toLowerCase().includes(search.toLowerCase())) ||
          (uc.q1 && uc.q1.toString().toLowerCase().includes(search.toLowerCase())) ||
          (uc.q3 && uc.q3.toString().toLowerCase().includes(search.toLowerCase())) ||
          (uc.type && uc.type.toString().toLowerCase().includes(search.toLowerCase()))
        )
      })
      .map((ech) => ({
        ...ech,
        original_row: ech,
        id: ech._id,
      }))
  }

  function onSelect(selected) {
    setSelection(selected.map((i) => getEchClient()[i]))
  }

  function clearSelection() {
    setRowSelectionModel([])
    if (onSelect) onSelect([])
  }

  function onSearch(e) {
    clearSelection()
    const newValue = e.target.value
    clearTimeout(timer)
    const newTimer = setTimeout(function () {
      setSearch(newValue)
    }, 1500)
    setTimer(newTimer)
    setDisplayClear(newValue !== '')
  }
  function clearSearch() {
    searchRef.current.children[0].value = ''
    setSearch('')
    setDisplayClear(false)
  }

  function CustomLoading() {
    return (
      <div style={{ height: 'calc(100% - 96px)', position: 'relative' }}>
        <Loading mode="miniCircle" />
      </div>
    )
  }

  function onDisplayClient(id) {
    localStorage.setItem('BATCH_previous_selection', id)
    props.history.push('/?' + DATABASE() + '/' + props.match.params.batchId + '/analysis/' + id)
  }
  function onWhichUpdateISTD(event, item) {
    setOpen(true)
    setNewISTDGroup(event.target.value)
    setItemToUpdate(item)
  }
  function handleClose(event, confirm) {
    setOpen(false)
    if (typeof confirm === 'boolean' && confirm) {
      updateISTD(dispatch, newISTDGroup, itemToUpdate.event, batch, itemToUpdate, 'batch')
    }
  }

  function moleculeDoubleClick(item) {
    localStorage.setItem('BATCH_previous_selection', item._id)
    props.history.push(
      '/?' + DATABASE() + '/' + props.match.params.batchId + '/compound/' + item.event,
    )
  }

  async function multiValidate(value) {
    callValidationDispatch(
      'unitary_calibration',
      dispatch,
      selection,
      value,
      null,
      props.match.params.batchId,
    )
    clearSelection()
  }

  async function applyFilter(event, newFilter) {
    clearSelection()
    if (newFilter) {
      setValidationOk(false)
      setValidationKo(false)
      setUntreatedChecked(false)
      localStorage.setItem('BATCH_filter_detected', 'false')
      localStorage.setItem('BATCH_filter_notDetected', 'false')
      localStorage.setItem('BATCH_filter_untreated', 'false')
    }
    dispatch(
      fetchUnitaryCalibrationsFiltered(
        props.match.params.batchId,
        newFilter,
        newFilter ? false : untreatedChecked,
        newFilter ? false : validationOk,
        newFilter ? false : validationKo,
        localStorage.getItem('BATCH_sorter_calibration'),
      ),
    )
    localStorage.setItem('BATCH_filter_calibration', newFilter)
    setFilterSelected(newFilter)
  }

  async function handleUntreatedChange(event, newFilter) {
    clearSelection()
    const pressed = !untreatedChecked
    if (pressed) {
      setFilterSelected(null)
      setValidationOk(false)
      setValidationKo(false)
      localStorage.setItem('BATCH_filter_calibration', null)
      localStorage.setItem('BATCH_filter_detected', 'false')
      localStorage.setItem('BATCH_filter_notDetected', 'false')
    }
    dispatch(
      fetchUnitaryCalibrationsFiltered(
        props.match.params.batchId,
        pressed ? null : filterSelected,
        !untreatedChecked,
        pressed ? false : validationOk,
        pressed ? false : validationKo,
        localStorage.getItem('BATCH_sorter_calibration'),
      ),
    )
    localStorage.setItem('BATCH_filter_untreated', (!untreatedChecked).toString())
    setUntreatedChecked((oldState) => !oldState)
  }

  async function handleChangeValidationOk(event, newFilter) {
    clearSelection()
    const pressed = !validationOk
    if (pressed) {
      setUntreatedChecked(false)
      setFilterSelected(null)
      localStorage.setItem('BATCH_filter_untreated', 'false')
      localStorage.setItem('BATCH_filter_calibration', null)
    }

    dispatch(
      fetchUnitaryCalibrationsFiltered(
        props.match.params.batchId,
        pressed ? null : filterSelected,
        pressed ? false : untreatedChecked,
        !validationOk,
        validationKo,
        localStorage.getItem('BATCH_sorter_calibration'),
      ),
    )
    localStorage.setItem('BATCH_filter_detected', (!validationOk).toString())
    setValidationOk((oldState) => !oldState)
  }

  async function handleChangeValidationKo(event, newFilter) {
    clearSelection()
    const pressed = !validationKo
    if (pressed) {
      setUntreatedChecked(false)
      setFilterSelected(null)
      localStorage.setItem('BATCH_filter_untreated', 'false')
      localStorage.setItem('BATCH_filter_calibration', null)
    }

    dispatch(
      fetchUnitaryCalibrationsFiltered(
        props.match.params.batchId,
        pressed ? null : filterSelected,
        pressed ? false : untreatedChecked,
        validationOk,
        !validationKo,
        localStorage.getItem('BATCH_sorter_calibration'),
      ),
    )
    localStorage.setItem('BATCH_filter_detected', (!validationKo).toString())
    setValidationKo((oldState) => !oldState)
  }

  const columnsMemoized = useMemo(() => {
    return [
      {
        field: 'flags',
        width: 80,
        type: 'string',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.calibTable.columns.flags'),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <ErrorBoundaryGuard isDialog>
              <AlphacodFlagIcon item={row} hideGeneralTab={true} />
            </ErrorBoundaryGuard>
          )
        },
        valueFormatter: (params) => {
          return getFlagName(params.api.getRow(params.id))
        },
        filterOperators: flagsFilterOperators,
        valueGetter: ({ row }) => {
          const flagIco = getFlagIcon(row)
          switch (flagIco) {
            case 'info':
              return 0
            case 'action':
              return 1
            case 'warning':
              return 2
            case 'error':
              return 3
            default:
              return -1
          }
        },
      },
      {
        field: 'notes',
        width: 80,
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.qualityControlTable.column.notes'),
        valueGetter: ({ value }) => value?.length,
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <ErrorBoundaryGuard isDialog>
              <NoteButton
                row={{
                  _id: row.id,
                  notes: row.notes ? row.notes : [],
                }}
                collection={'unitary_calibration'}
              />
            </ErrorBoundaryGuard>
          )
        },
      },
      {
        field: 'modified',
        headerName: t('view.batch.calibTable.columns.modif'),
        type: 'boolean',
        renderCell: function ({ row }) {
          return (
            <ErrorBoundaryGuard>
              <AlphacodModifiedButtonIcon item={row} />
            </ErrorBoundaryGuard>
          )
        },
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
      },
      {
        field: 'name',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.qualityControlTable.column.name'),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <Tooltip
              arrow
              placement="top"
              title={<div className={classes.tooltip}>{row.name}</div>}
            >
              <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {row.name}
              </div>
            </Tooltip>
          )
        },
      },
      {
        field: 'state',
        type: 'text',
        headerName: t('view.batch.calibTable.columns.state'),
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
      },
      {
        field: 'validation',
        headerName: t('view.batch.calibTable.columns.valid'),
        type: 'custom',
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <div className={classes.actionContainer}>
              <IconButton
                disabled={!canEditCalib}
                aria-label="detected"
                onClick={(event) => {
                  handleValidation(event, row, '1', 'unitary_calibration', dispatch)
                }}
              >
                {value === '1' ? (
                  <CheckIcon className={classes.selected} />
                ) : (
                  <CheckIcon className={classes.notSelected} />
                )}
              </IconButton>
              <IconButton
                disabled={!canEditCalib}
                aria-label="notDetected"
                onClick={(event) =>
                  handleValidation(event, row, '2', 'unitary_calibration', dispatch)
                }
              >
                {value === '2' ? (
                  <ClearIcon className={classes.selected} />
                ) : (
                  <ClearIcon className={classes.notSelected} />
                )}
              </IconButton>
            </div>
          )
        },
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        valueFormatter: ({ value }) => {
          switch (value) {
            case '1':
              return 'detected'
            case '2':
              return 'not detected'
            case '0':
            default:
              return ''
          }
        },
        filterOperators: validationFilterOperators,
      },
      {
        field: 'event',
        headerName: t('view.batch.calibTable.columns.event'),
        type: 'number',
        customFormat: true,
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          if (value && value !== '' && value !== null)
            return parseFloat(customFormatDecimals(value, value.toFixed(1), columnInfos))
          return value
        },
        valueFormatter: ({ value }) =>
          value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
      },
      {
        field: 'r2',
        customFormat: true,
        headerName: t('view.batch.calibTable.columns.r2'),
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          if (value && value !== '' && value !== null)
            return parseFloat(customFormatDecimals(value, value.toFixed(2), columnInfos))
          return value
        },
        valueFormatter: ({ value }) =>
          value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
      },
      {
        field: 'rt',
        customFormat: true,
        headerName: t('view.batch.calibTable.columns.rt'),
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          if (value && value !== '' && value !== null)
            return customFormatDecimals(value, value.toFixed(1), columnInfos)
          return value
        },
        valueFormatter: ({ value }) =>
          value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
      },
      {
        field: 'istd',
        headerName: t('view.batch.calibTable.columns.istd'),
        type: 'text',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        valueGetter: ({ row }) => {
          if (row.type === 'ISTD') {
            return row.istd_group.toString()
          } else {
            return batch.content?.internal_standards?.[row.istd_group]?.name
          }
        },
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          if (row.type === 'ISTD') {
            return row.istd_group.toString()
          } else {
            return (
              <FormControl className={classes.formControl}>
                <Select
                  value={row.istd_group}
                  name={'istd'}
                  onChange={(event) => onWhichUpdateISTD(event, row)}
                  displayEmpty
                  autoWidth
                  inputProps={{
                    readOnly: !canEditCalib,
                    'aria-label': 'ISTD',
                  }}
                >
                  {Object.entries(
                    batch && batch.content.internal_standards
                      ? batch.content.internal_standards
                      : [],
                  ).map((internal_standard) => (
                    <MenuItem key={internal_standard[1].name} value={internal_standard[1].istd}>
                      {internal_standard[1].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
        },
      },
      {
        field: 'ret_time',
        customFormat: true,
        headerName: t('view.batch.calibTable.columns.rt_meth'),
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          if (value && value !== '' && value !== null)
            return parseFloat(customFormatDecimals(value, value.toFixed(2), columnInfos))
          return value
        },
        valueFormatter: ({ value }) =>
          value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
      },
      {
        field: 'q1',
        customFormat: true,
        headerName: t('view.batch.calibTable.columns.q1'),
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          if (value && value !== '' && value !== null)
            return parseFloat(customFormatDecimals(value, value.toFixed(1), columnInfos))
          return value
        },
        valueFormatter: ({ value }) =>
          value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
      },
      {
        field: 'q3',
        customFormat: true,
        headerName: t('view.batch.calibTable.columns.q3'),
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          if (value && value !== '' && value !== null)
            return parseFloat(customFormatDecimals(value, value.toFixed(1), columnInfos))
          return value
        },
        valueFormatter: ({ value }) =>
          value?.toLocaleString(undefined, { maximumFractionDigits: 20 }),
      },
      {
        field: 'type',
        type: 'string',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.calibTable.columns.type'),
      },
      {
        field: 'nb_points',
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.calibTable.columns.nb_points'),
      },
      {
        field: 'curve_fit',
        type: 'string',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.calibTable.columns.curve_fit'),
      },
      {
        field: 'curve_weighting',
        type: 'string',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.calibTable.columns.curve_weighting'),
      },
      {
        field: 'curve_zero',
        type: 'string',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.calibTable.columns.curve_zero'),
        renderCell: function ({ value, colDef: { columnInfos } }) {
          const displayValue =
            value === 'none' ? t('view.common.calibration.ignored').toLowerCase() : value
          return displayValue
        },
      },
      {
        field: 'std_dev',
        headerName: t('view.batch.calibTable.columns.std_dev') + ' (%)',
        renderHeader: (params) =>
          getHeaderTooltipCmp(params, wikiCODHelper, t('view.batch.calibTable.columns.std_dev')),
        type: 'number',
        customFormat: true,
        renderCell: function ({ value, colDef: { columnInfos } }) {
          if (value && value !== '' && value !== null) {
            let v = value * 100
            return customFormatDecimals(v, displayHumanReadable(v, 2, 2, true), columnInfos) + ' %'
          }
          return value
        },
      },
      {
        field: 'actions',
        type: 'actions',
        hideable: false,
        resizable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: false,
        disableReorder: true,
        disableExport: true,
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        getActions: ({ row, ...otherParams }) => [
          <GridActionsCellItem
            className={classes.actionMenu}
            showInMenu
            icon={<OpenInBrowserIcon />}
            label={t('view.batch.othersTable.actions.open')}
            onClick={() =>
              props.history.push('/?' + DATABASE() + '/' + row.batchId + '/analysis/' + row._id)
            }
          />,
          <GridActionsCellItem
            className={classes.actionMenu}
            showInMenu
            icon={<OpenInNewIcon />}
            label={t('view.batch.othersTable.actions.openNew')}
            onClick={() => openAnalysisInNewTab(row)}
          />,
          <GridActionsCellItem
            disabled={true}
            className={classes.actionDivider}
            showInMenu
            label={''}
          />,
          <GridActionsCellItem
            onClick={() => {
              dispatch(fetchDisplayJson(row._id, 'analysis'))
            }}
            className={classes.actionMenu}
            showInMenu
            icon={<SearchIcon />}
            label={t('view.batch.othersTable.actions.explore')}
          />,
        ],
      },
    ]
  }, [classes])

  const columnsUpdatedMemoized = useMemo(() => {
    const order = JSON.parse(localStorage.getItem('BATCH_calib_state'))?.columns?.orderedFields
    const compareOrder = (a, b) => {
      const indexA = order?.indexOf(a.field)
      const indexB = order?.indexOf(b.field)
      if (indexA === -1 || indexB === -1) {
        return 0
      }
      return indexA - indexB
    }
    const settingsFormatSaved = localStorage.getItem('TABLE_calibrationTable_columns_format')
      ? localStorage
          .getItem('TABLE_calibrationTable_columns_format')
          .split('[|,|]')
          .reduce(function (map, obj) {
            map[obj.split('[|:|]')[0]] = obj.split('[|:|]')[1]
            return map
          }, {})
      : {}
    const columnFormat = localStorage.getItem('TABLE_calibrationTable_columns_format')
      ? columnsMemoized.reduce(function (map, obj) {
          map[obj.field] =
            typeof settingsFormatSaved[obj.field] === 'string'
              ? settingsFormatSaved[obj.field]
              : 'auto'
          return map
        }, {})
      : columnsMemoized.reduce(function (map, obj) {
          map[obj.field] = 'auto'
          return map
        }, {})
    setSettingsFormat(columnFormat)

    const settingsDecimalsSaved = localStorage.getItem('TABLE_calibrationTable_columns_decimals')
      ? localStorage
          .getItem('TABLE_calibrationTable_columns_decimals')
          .split('[|,|]')
          .reduce(function (map, obj) {
            map[obj.split('[|:|]')[0]] = parseInt(obj.split('[|:|]')[1])
            return map
          }, {})
      : {}
    const columnDecimals = localStorage.getItem('TABLE_calibrationTable_columns_decimals')
      ? columnsMemoized.reduce(function (map, obj) {
          map[obj.field] =
            typeof settingsDecimalsSaved[obj.field] === 'number'
              ? settingsDecimalsSaved[obj.field]
              : 3
          return map
        }, {})
      : columnsMemoized.reduce(function (map, obj) {
          map[obj.field] = 3
          return map
        }, {})
    setSettingsDecimals(columnDecimals)
    // return column and sort it by the good order
    return columnsMemoized
      .map((col) => ({
        ...col,
        columnInfos: { decimals: columnDecimals[col.field], format: columnFormat[col.field] },
      }))
      .sort(compareOrder)
  }, [columnsMemoized])

  const onFormatChanged = useCallback(
    (event, field) => {
      event.stopPropagation()
      event.preventDefault()
      const value = event.target.value
      localStorage.setItem(
        'TABLE_calibrationTable_columns_format',
        Object.entries({ ...settingsFormat, [field]: value })
          .map((format) => format[0] + '[|:|]' + format[1])
          .join('[|,|]'),
      )
      setSettingsFormat({ ...settingsFormat, [field]: value })
    },
    [settingsFormat],
  )

  const onDecimalsChanged = useCallback(
    (event, field, forceSave, checkbox) => {
      // const value = event.target.value
      // localStorage.setItem(
      //   'TABLE_myAnalyseMoleculesTable_columns_decimals',
      //   Object.entries({...settingsDecimals, [field]: value})
      //     .map((decimals) => decimals[0] + '[|:|]' + decimals[1])
      //     .join('[|,|]'),
      // )
      // setSettingsDecimals({ ...settingsDecimals, [field]: value })

      function save(val) {
        localStorage.setItem(
          'TABLE_calibrationTable_columns_decimals',
          Object.entries({ ...settingsDecimals, [field]: val })
            .map((decimals) => decimals[0] + '[|:|]' + decimals[1])
            .join('[|,|]'),
        )
        setSettingsDecimals({ ...settingsDecimals, [field]: val })
      }
      const value = checkbox
        ? event.target.checked
          ? 0
          : -1
        : parseFloat(event.currentTarget.value)
      clearTimeout(settingsTimer)
      if (forceSave) {
        // setSettingsDecimals({ ...settingsDecimals, [label]: value })
        save(value)
      } else {
        setSettingsTimer(
          setTimeout(function () {
            // setSettingsDecimals({ ...settingsDecimals, [label]: value })
            save(value)
          }, 1500),
        )
      }
    },
    [settingsDecimals, settingsTimer],
  )

  const CustomNumberFormat = React.memo((props) => {
    const { myCustomFormat, myCustomDecimal, field } = props
    return (
      <MenuItem
        disabled
        style={{ padding: 15, opacity: 1 }}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
        }}
      >
        <ListItemIcon style={{ minWidth: 36 }}>
          <NumberOneIcon fontSize="small" />
        </ListItemIcon>
        <ListItemSecondaryAction
          style={{ top: 6, marginLeft: 52, width: 'auto', transform: 'none' }}
        >
          <FormControl
            size="small"
            style={{ marginRight: 5, width: 'calc(50% - 3px)' }}
            variant="outlined"
          >
            <InputLabel>Format</InputLabel>
            <Select
              variant="outlined"
              className={classes.selectFormat}
              value={myCustomFormat}
              label={t('view.common.settingsView.tableTab.format')}
              onChange={(event) => {
                event.stopPropagation()
                event.preventDefault()
                onFormatChanged(event, field)
              }}
            >
              <MenuItem key={'auto'} value={'auto'}>
                {t('view.common.settingsView.tableTab.auto')}
              </MenuItem>
              <MenuItem key={'std'} value={'std'}>
                {t('view.common.settingsView.tableTab.std')}
              </MenuItem>
              <MenuItem key={'sci'} value={'sci'}>
                {t('view.common.settingsView.tableTab.sci')}
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={clsx(classes.columnSizetextfield, classes.columnDecimals)}
            style={{ width: 'calc(50% - 3px)', background: 'white' }}
            label={t('view.common.settingsView.tableTab.decimals')}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start" style={{ width: 10 }}>
                  <Checkbox
                    checked={myCustomDecimal !== -1 && myCustomFormat !== 'auto'}
                    disabled={myCustomFormat === 'auto'}
                    style={{ padding: 0 }}
                    size="small"
                    onChange={(event) => onDecimalsChanged(event, field, true, true)}
                  />
                </InputAdornment>
              ),
            }}
            // disabled={decimals === -1 || format === 'auto'}
            variant="outlined"
            size="small"
            defaultValue={
              myCustomDecimal === -1 || myCustomFormat === 'auto' ? null : myCustomDecimal
            }
            onChange={(event) => onDecimalsChanged(event, field)}
            onBlur={(e) => onDecimalsChanged(e, field, true)}
          />
        </ListItemSecondaryAction>
      </MenuItem>
    )
  })
  const CustomColumnMenu = React.memo((props) => {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          // Add new item
          columnMenuNumberFormat: props.colDef.customFormat ? CustomNumberFormat : null,
        }}
        slotProps={{
          columnMenuNumberFormat: {
            field: props.colDef.field,
            displayOrder: 15,
            myCustomFormat: settingsFormat[props.colDef.field],
            myCustomDecimal: settingsDecimals[props.colDef.field],
          },
        }}
      />
    )
  })

  const getRenderCmp = (fullscreenMode) => {
    return (
      <Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={TransitionTop}
          aria-labelledby="dialog-title"
        >
          <StyledDialogTitle>{t('view.batch.calibTable.istd_update')}</StyledDialogTitle>
          <DialogContent>
            <DialogContentText>{t('view.batch.calibTable.sure')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(event) => handleClose(event, true)} color="primary">
              {t('view.batch.calibTable.ok')}
            </Button>
            <Button autoFocus onClick={(event) => handleClose(event, false)} color="primary">
              {t('view.batch.calibTable.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        {/*<Paper className={classes.paper} elevation={0}>*/}
        <Stack
          direction="column"
          justifyContent="space-around"
          alignItems="stretch"
          spacing={0}
          style={{ height: '100%' }}
        >
          <Title>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="space-between"
              style={{ marginTop: 8 }}
            >
              <Grid item style={{ paddingTop: 0 }}>
                <Box>{t('view.batch.calibTable.calibration')}</Box>
              </Grid>
              <Grid item className={classes.progress}>
                <ToggleButtonGroup
                  value={filterSelected}
                  exclusive
                  onChange={applyFilter}
                  aria-label="text alignment"
                >
                  <StyledToggleButton
                    className={classes.buttonCalib}
                    value={'istd'}
                    aria-label="ISTD"
                  >
                    <Tooltip
                      key={'tooltip_istd'}
                      arrow
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.batch.calibTable.all_istd')}
                        </div>
                      }
                    >
                      <Badge
                        className={classes.badgeCount}
                        max={10000}
                        showZero={!isFetchingUnitaryCalibrationsDistinct}
                        badgeContent={
                          !isFetchingUnitaryCalibrationsDistinct && unitaryCalibrationsDistinct.True
                            ? unitaryCalibrationsDistinct.True.count
                            : 0
                        }
                      >
                        <GroupWorkIcon style={{ marginRight: 5 }} />
                        {t('view.batch.calibTable.istd')}
                      </Badge>
                    </Tooltip>
                  </StyledToggleButton>
                  <StyledToggleButton className={classes.buttonCalib} value={'ok'} aria-label="OK">
                    <Tooltip
                      key={'tooltip_filter_ok'}
                      arrow
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.batch.calibTable.all_ok')}
                        </div>
                      }
                    >
                      <Badge
                        className={classes.badgeCount}
                        max={10000}
                        showZero={!isFetchingUnitaryCalibrationsDistinct}
                        badgeContent={
                          !isFetchingUnitaryCalibrationsDistinct && unitaryCalibrationsDistinct.ok
                            ? unitaryCalibrationsDistinct.ok.count
                            : 0
                        }
                      >
                        <ThumbUpIcon style={{ marginRight: 5 }} />
                        {t('view.batch.calibTable.ok_button')}
                      </Badge>
                    </Tooltip>
                  </StyledToggleButton>
                  <StyledToggleButton className={classes.buttonCalib} value={'ko'} aria-label="KO">
                    <Tooltip
                      key={'tooltip_filter_ko'}
                      arrow
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.batch.calibTable.all_ko')}
                        </div>
                      }
                    >
                      <Badge
                        className={classes.badgeCount}
                        max={10000}
                        showZero={!isFetchingUnitaryCalibrationsDistinct}
                        badgeContent={
                          !isFetchingUnitaryCalibrationsDistinct && unitaryCalibrationsDistinct.ko
                            ? unitaryCalibrationsDistinct.ko.count
                            : 0
                        }
                      >
                        <ThumbDownIcon style={{ marginRight: 5 }} />
                        {t('view.batch.calibTable.ko_button')}
                      </Badge>
                    </Tooltip>
                  </StyledToggleButton>
                </ToggleButtonGroup>
                <div style={{ display: 'inline-flex' }}>
                  <Tooltip
                    key={'tooltip_untreated'}
                    arrow
                    title={
                      <div style={{ fontSize: 13, padding: 5 }}>
                        {t('view.batch.calibTable.untreated')}
                      </div>
                    }
                  >
                    <Badge
                      className={clsx(
                        classes.badgeCountRect,
                        untreatedChecked ? classes.badgeCountRectSelected : null,
                      )}
                      max={10000}
                      showZero={!isFetchingUnitaryCalibrationsDistinct}
                      overlap="circular"
                      badgeContent={
                        !isFetchingUnitaryCalibrationsDistinct && unitaryCalibrationsDistinct[0]
                          ? unitaryCalibrationsDistinct[0].count
                          : 0
                      }
                    >
                      <IconButton
                        style={{ marginLeft: 50 }}
                        aria-label="untreated"
                        className={clsx(
                          classes.filterButton,
                          untreatedChecked ? classes.selectedButton : classes.notSelectedButton,
                        )}
                        onClick={handleUntreatedChange}
                      >
                        <NewReleasesIcon />
                      </IconButton>
                    </Badge>
                  </Tooltip>
                  <Tooltip
                    key={'tooltip_detected'}
                    arrow
                    title={
                      <div style={{ fontSize: 13, padding: 5 }}>
                        {t('view.batch.calibTable.filter_ok')}
                      </div>
                    }
                  >
                    <Badge
                      className={clsx(
                        classes.badgeCountRect,
                        validationOk ? classes.badgeCountRectSelected : null,
                      )}
                      max={10000}
                      showZero={!isFetchingUnitaryCalibrationsDistinct}
                      overlap="circular"
                      badgeContent={
                        !isFetchingUnitaryCalibrationsDistinct && unitaryCalibrationsDistinct[1]
                          ? unitaryCalibrationsDistinct[1].count
                          : 0
                      }
                    >
                      <IconButton
                        aria-label="detected"
                        className={clsx(
                          classes.filterButton,
                          validationOk ? classes.selectedButton : classes.notSelectedButton,
                        )}
                        onClick={handleChangeValidationOk}
                      >
                        <CheckIcon />
                      </IconButton>
                    </Badge>
                  </Tooltip>
                  <Tooltip
                    key={'tooltip_notdetected'}
                    arrow
                    title={
                      <div style={{ fontSize: 13, padding: 5 }}>
                        {t('view.batch.calibTable.filter_ko')}
                      </div>
                    }
                  >
                    <Badge
                      className={clsx(
                        classes.badgeCountRect,
                        validationKo ? classes.badgeCountRectSelected : null,
                      )}
                      max={10000}
                      showZero={!isFetchingUnitaryCalibrationsDistinct}
                      overlap="circular"
                      badgeContent={
                        !isFetchingUnitaryCalibrationsDistinct && unitaryCalibrationsDistinct[2]
                          ? unitaryCalibrationsDistinct[2].count
                          : 0
                      }
                    >
                      <IconButton
                        aria-label="notDetected"
                        className={clsx(
                          classes.filterButton,
                          validationKo ? classes.selectedButton : classes.notSelectedButton,
                        )}
                        onClick={handleChangeValidationKo}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Badge>
                  </Tooltip>
                </div>
              </Grid>
              <Grid item className={classes.progress}>
                {/*<Divider orientation="vertical" />*/}
              </Grid>
              <Grid item className={classes.progress}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder={t('view.batch.calibTable.search')}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={onSearch}
                    ref={searchRef}
                  />
                  {displayClear && (
                    <IconButton
                      className={clsx(classes.clearIcon, classes.searchIcon)}
                      aria-label="clear"
                      onClick={clearSearch}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </div>
              </Grid>
              {!fullscreenMode && (
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <div className={classes.tooltip}>
                      {t('view.batch.qualityControlTable.full_screen')}
                    </div>
                  }
                >
                  <Grid item className={classes.progress}>
                    <IconButton
                      size="small"
                      aria-label="extend"
                      onClick={() => {
                        setOpenExtentedView(true)
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Grid>
                </Tooltip>
              )}
            </Grid>
          </Title>
          <div className={classes.tableContainer}>
            <ErrorBoundaryGuard>
              <StripedDataGridPro
                initialState={{
                  ...defaultState,
                  ...JSON.parse(localStorage.getItem('BATCH_calib_state')),
                }}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel)
                  setSelection(
                    echClient
                      .filter((objet) => newRowSelectionModel.includes(objet._id))
                      .map((tmpRow) => tmpRow.original_row),
                  )
                }}
                rowSelectionModel={rowSelectionModel}
                onStateChange={() => saveSnapshot(fullscreenMode)}
                rowHeight={40}
                loading={isFetchingUnitaryCalibrationsFiltered}
                slots={{
                  // moreActionsIcon: CustomActionIcon,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                  loadingOverlay: CustomLoading,
                  columnMenu: CustomColumnMenu,
                }}
                apiRef={apiRef}
                getRowClassName={(params) => {
                  let classesAdded = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  if (tableItemSelectedForColor === params.id) {
                    classesAdded += ' selection'
                  }
                  return classesAdded
                }}
                getCellClassName={(params) => {
                  return 'customCell'
                }}
                // disableVirtualization={batches.length < 50}
                rowBuffer={3}
                rowThreshold={3}
                columnBuffer={3}
                columnThreshold={3}
                rows={echClient}
                columns={columnsUpdatedMemoized}
                pagination={false}
                hideFooter={true}
                style={{ border: 'none' }}
                // Do not use autoSize: conflic with initialState
                // autosizeOnMount
                // autosizeOptions={autoSizeOptions}
                onRowClick={(params, event, details) => {
                  const openFct = () => {
                    onDisplayCalib(params.row)
                  }
                  getClickAction(dispatch, event, params.row, 'unitary_calibration', openFct)
                }}
                onRowDoubleClick={(params, event, details) => {
                  moleculeDoubleClick(params.row)
                }}
                // onResize={() => hideWatermark()} //TODO To be deleted after purchasing a license
              />
            </ErrorBoundaryGuard>
          </div>
          {!selectionEmpty && (
            <span
              className={classes.toolbar}
              style={{ visibility: selectionEmpty ? 'hidden' : 'visible' }}
            >
              <div>
                <Tooltip
                  arrow
                  title={
                    <div className={classes.tooltip}>{t('view.batch.calibTable.selection_ok')}</div>
                  }
                >
                  <IconButton
                    className={classes.toolbarButton}
                    aria-label="ok"
                    onClick={() => multiValidate(1)}
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  title={
                    <div className={classes.tooltip}>{t('view.batch.calibTable.selection_ko')}</div>
                  }
                >
                  <IconButton
                    className={classes.toolbarButton}
                    aria-label="ko"
                    onClick={() => multiValidate(2)}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </span>
          )}
          {/*</Paper>*/}
        </Stack>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <CustomDialog
        fullWidth={true}
        maxWidth={'xl'}
        open={openExtentedView}
        onClose={() => setOpenExtentedView(false)}
        TransitionComponent={TransitionTop}
        aria-labelledby="draggable-dialog-title"
      >
        {openExtentedView && getRenderCmp(true)}
        <DialogActions>
          <Button onClick={() => setOpenExtentedView(false)} color="primary">
            {t('view.batch.samplesTable.close')}
          </Button>
        </DialogActions>
      </CustomDialog>
      {getRenderCmp()}
    </Fragment>
  )
})
export default CalibTable
