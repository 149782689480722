// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useCallback, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
// import _ from 'lodash'

import ChromatoScatter from './ChromatoScatter'
import { alpha } from '@material-ui/core/styles'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'
import ContextMenu from './ContextMenu'
import { fetchUpdateDetailInfo } from '../../../redux/detail/actions'
import { useSelector } from 'react-redux'
// import diff from 'deep-diff'

const useStyles = makeStyles((theme) => ({
  chromatoGrid: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
      '& button': {
        opacity: 1,
      },
    },
    // '&:hover span.fullscreenMode': {
    //   opacity: '0.5 !important',
    //   transition: '0.1s',
    // },
    // '&:hover span.fullscreenMode:hover': {
    //   opacity: '1 !important',
    //   transition: '0.1s',
    // },
  },
  blink: {
    // animation: 'blink-fade 1s ease-in-out 0s',
    background: alpha(theme.palette.secondary.light, 0.19),
    '&:hover': {
      background: alpha(theme.palette.secondary.light, 0.37),
    },
  },
  noBlink: {
    cursor: 'pointer',
  },
}))

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render')
//
//   whyDidYouRender(React, {
//     exclude: [/ConnectFunction/, /ChromatoScatter/],
//     trackAllPureComponents: false,
//     // trackExtraHooks: [[{ useSelector }, 'useSelector']],
//     trackExtraHooks: [[require('react-redux'), 'useSelector']],
//     logOnDifferentValues: true,
//     notifier: ({ Component, displayName, prevProps, nextProps, reason }) => {
//       if (displayName === 'ChromatoScatter') {
//         console.log(`🔔 Re-render detected in ${displayName} due to:`, reason)
//
//         // Vérification ciblée sur unitaryAnalyse
//         if (prevProps?.unitaryAnalyse && nextProps?.unitaryAnalyse) {
//           const differences = diff(prevProps.unitaryAnalyse, nextProps.unitaryAnalyse)
//
//           if (differences) {
//             console.log('🔍 Differences in unitaryAnalyse:', differences)
//             differences.forEach((difference) => {
//               console.log(`⚡ Change at ${difference.path?.join('.') || 'root'}:`, {
//                 from: difference.lhs,
//                 to: difference.rhs,
//                 kind: difference.kind,
//               })
//             })
//           } else {
//             console.log('✅ No differences in unitaryAnalyse.')
//           }
//         }
//       }
//     },
//   })
//
//   ChromatoScatter.whyDidYouRender = true
//   // ChromatoScatter.whyDidYouRender = {
//   //   logOnDifferentValues: true,
//   //   customName: 'ChromatoScatter',
//   //   diffNameColor: 'green',
//   //   diffPathColor: 'blue',
//   //   diffValueColor: 'red',
//   //   // Vérification ciblée sur unitaryAnalyse
//   //   include: [/ChromatoScatter/],
//   //   diffFunction: (prevProps, nextProps) => {
//   //     const keysToCheck = [
//   //       '_id',
//   //       'mainChannel',
//   //       'selectedChannel',
//   //       'channels_data',
//   //       'main_peak_base',
//   //     ]
//   //
//   //     keysToCheck.forEach((key) => {
//   //       if (!_.isEqual(prevProps.unitaryAnalyse[key], nextProps.unitaryAnalyse[key])) {
//   //         console.log(`🔍 unitaryAnalyse.${key} has changed:`, {
//   //           prev: prevProps.unitaryAnalyse[key],
//   //           next: nextProps.unitaryAnalyse[key],
//   //         })
//   //       }
//   //     })
//   //   },
//   // }
// }

export default function ChromatoGraph({
  bypassLockChromatoEdition,
  getSelectedChannel,
  onChangeChannel,
  unitaryAnalyse,
  style,
  selected,
  chromatoSize,
  dispatch,
  uidKey,
  onClick,
  onDoubleClick,
  title,
  collection,
  isIstd,
  checkSelected,
  onSelect,
  index,
  selection,
  canEdit,
  aiAssistantActivated,
}) {
  const classes = useStyles()
  const clickTimeout = useRef(null)
  const isDoubleClick = useRef(false)

  const chartRef = useRef(null)
  // const externalModeActive = useSelector(
  //   (state) => typeof state.detail.externalMode === 'boolean' && state.detail.externalMode,
  // )

  const [contextMenu, setContextMenu] = React.useState(null)

  function handleContextMenuOpen(event) {
    event.preventDefault()
    // setSelectedRow(event.currentTarget.getAttribute('data-id'))
    setContextMenu(
      contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null,
    )
  }

  function handleCloseContextMenu() {
    setContextMenu(null)
  }

  // Memoize callbacks
  const handleChangeChannel = useCallback(onChangeChannel, [onChangeChannel])
  const handleSelect = useCallback(onSelect, [onSelect])
  const handleClick = useCallback(onClick, [onClick])

  const memoizedUnitaryAnalyse = useMemo(
    () => unitaryAnalyse,
    [
      unitaryAnalyse._id,
      unitaryAnalyse.modification_date,
      unitaryAnalyse.mainChannel,
      unitaryAnalyse.selectedChannel,
      unitaryAnalyse.channels_data,
      unitaryAnalyse.main_peak_base,
      unitaryAnalyse.validation,
      unitaryAnalyse.area,
      unitaryAnalyse.global_score,
      unitaryAnalyse.height,
      unitaryAnalyse.isArea,
      unitaryAnalyse.istd_dev_area,
      unitaryAnalyse.modified,
      unitaryAnalyse.status,
      unitaryAnalyse.unlocked,
    ],
  )

  // Memoize currentPeaks to avoid unnecessary recalculations
  const memoizedCurrentPeaks = useMemo(() => {
    if (unitaryAnalyse.mainChannel === unitaryAnalyse.selectedChannel) {
      return unitaryAnalyse.main_peak_base?.left && unitaryAnalyse.main_peak_base?.right
        ? [unitaryAnalyse.main_peak_base.left, unitaryAnalyse.main_peak_base.right]
        : []
    }
    const selectedChannelData = unitaryAnalyse.channels_data.find(
      (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
    )
    return selectedChannelData?.peak?.base
      ? [selectedChannelData.peak.base.left, selectedChannelData.peak.base.right]
      : []
  }, [
    unitaryAnalyse.mainChannel,
    unitaryAnalyse.selectedChannel,
    unitaryAnalyse.channels_data,
    unitaryAnalyse.main_peak_base?.left,
    unitaryAnalyse.main_peak_base?.right,
  ])

  return (
    <Fragment>
      <ErrorBoundaryGuard isDialog>
        <ContextMenu
          chartRef={chartRef}
          contextMenu={contextMenu}
          onClose={handleCloseContextMenu}
          aiAssistantActivated={aiAssistantActivated}
          unitaryAnalyse={unitaryAnalyse}
          collection={collection}
        />
      </ErrorBoundaryGuard>
      <div
        style={style}
        key={uidKey}
        className={clsx(
          'unitaryAnalyse',
          classes.chromatoGrid,
          selected ? classes.blink : classes.noBlink,
        )}
        event={unitaryAnalyse.event}
        unitaryanalysesid={unitaryAnalyse._id}
        onClick={(event) => {
          // Do not trigger the clic if it's just a selection for the checkbox
          if (event.target.type === 'checkbox') return
          // Delai to avoid in case of double clic
          clickTimeout.current = setTimeout(() => {
            if (!isDoubleClick.current) {
              onClick(unitaryAnalyse)
              clickTimeout.current = null
              //TODO find a better way to update the detail in case of no detail view is opened
              dispatch(
                fetchUpdateDetailInfo(
                  unitaryAnalyse,
                  0,
                  false,
                  event.currentTarget,
                  collection,
                  true,
                ),
              )
            }
            isDoubleClick.current = false
          }, 250)
        }}
        onDoubleClick={(event) => {
          if (event.target.type === 'checkbox') return
          // Cancel the simple clic in case of double clic
          isDoubleClick.current = true
          // Cancel timeout for the simple clic
          clearTimeout(clickTimeout.current)
          onDoubleClick(event)
        }}
        onContextMenu={handleContextMenuOpen}
      >
        <ErrorBoundaryGuard>
          <ChromatoScatter
            chartRef={chartRef}
            aiAssistantActivated={aiAssistantActivated}
            bypassLockChromatoEdition={bypassLockChromatoEdition}
            isSelected={selected}
            canEdit={canEdit}
            index={index}
            checkSelected={checkSelected}
            selection={selection}
            onSelect={handleSelect}
            updateCalibrationAfterPeakUpdate={handleClick}
            getSelectedChannel={getSelectedChannel}
            onChangeChannel={handleChangeChannel}
            keyValue={`scatter_${uidKey}`}
            unitaryAnalyse={memoizedUnitaryAnalyse}
            dispatch={dispatch}
            collection={collection}
            title={title(memoizedUnitaryAnalyse)}
            chromatoSize={chromatoSize}
            showChannels={true}
            currentPeaks={memoizedCurrentPeaks}
          />
        </ErrorBoundaryGuard>
      </div>
    </Fragment>
  )
}
