// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { fetchChangeSettings } from '../../../redux/settings/actions'
import { withRouter } from 'react-router-dom'
import AIContainer from './AIContainer'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'
import clsx from 'clsx'
import { t } from 'i18next'
import clippyIcon from '../../../resources/images/Nostalgia_Clippy_mini.png'
import assistantIcon from '../../../resources/images/assistant-ia_mini.png'

const useStyles = makeStyles((theme) => ({
  customModal: {
    position: 'fixed',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
}))

const AIAssistant = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const modalRef = useRef(null)
  const [isHovered, setIsHovered] = useState(false)
  const [systemMsg, setSystemMsg] = useState(null)

  const admin = JSON.parse(localStorage.getItem('SESSION_user_json'))?.admin
  const open = useSelector(function (state) {
    return state.settings.assistantPopup
  })
  const assistantType = useSelector(function (state) {
    return state.settings.assistantType
  })
  const ai_fetching = useSelector(function (state) {
    return state.assistant.ai_fetching
  })
  const assistant_online = useSelector(function (state) {
    return state.assistant.ai_status === 'online'
  })
  const assistant_loaded = useSelector(function (state) {
    return state.assistant.loaded
  })
  const aiAssistantActivated = useSelector(function (state) {
    return (
      typeof state?.global?.featuresConfig?.content?.ai_assistant === 'boolean' &&
      state?.global?.featuresConfig?.content?.ai_assistant
    )
  })

  const clippyActivated = useMemo(() => {
    return admin && assistantType === 'clippy'
  }, [assistantType])

  useEffect(() => {
    // Gestion de l'ouverture de la fenêtre (équivalent à onload)
    dispatch({ type: 'ASSISTANT_WINDOW_OPENED' })
    dispatch(fetchChangeSettings('assistantPopup', false))
    // Gestion de la fermeture de la fenêtre (équivalent à onunload)
    const handleUnload = () => {
      dispatch({ type: 'ASSISTANT_WINDOW_CLOSED' })
    }
    // Ajouter l'écouteur d'événement pour la fermeture de la fenêtre
    window.addEventListener('beforeunload', handleUnload)
    // Nettoyer les événements à la fermeture du composant
    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [dispatch])

  useEffect(() => {
    document.title =
      // (assistant_loaded && (assistant_online ? (ai_fetching ? '💬 ' : '🟢 ') : '🔴 ')) +
      (assistant_loaded && (assistant_online ? (ai_fetching ? '⛬ ' : '◉ ') : '◎ ')) +
      t('view.dialogs.assistant.title') +
      ' - ' +
      localStorage.getItem('__config.json__APPLICATION_TITLE')
    let link = document.querySelector("link[rel~='icon']")
    link.href = clippyActivated ? clippyIcon : assistantIcon
  }, [clippyActivated, ai_fetching, assistant_online, assistant_loaded])

  return (
    aiAssistantActivated && (
      <Fragment>
        <div ref={modalRef} className={clsx(classes.customModal, open ? classes.open : undefined)}>
          <ErrorBoundaryGuard>
            <AIContainer displayMenu />
          </ErrorBoundaryGuard>
        </div>
      </Fragment>
    )
  )
})
export default AIAssistant
