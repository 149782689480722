// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useMemo } from 'react'
import { Checkbox, DialogActions, DialogTitle, FormControlLabel, Stack } from '@mui/material'
import { genericStyles, TransitionBottom, ZipFileInvert } from '../../common/Style'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import image from '../../../resources/images/hero_bg_lr.jpg'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { humanFileSize } from '../../../utils/utilities'
import Loading from '../../Loading'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import * as I18n from 'i18next'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import * as PropTypes from 'prop-types'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Tab from '@mui/material/Tab'
import InfoIcon from '@mui/icons-material/Info'
import ZipPanel from './ZipPanel'
import InfosPanel from './InfosPanel'
import SendPanel from './SendPanel'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import Badge from '@mui/material/Badge'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core'
import { fetchChangeSettings } from '../../../redux/settings/actions'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'

const useStyles = makeStyles((theme) => ({
  customRootDialog: {
    width: '70%',
  },
  warningIcon: {
    color: theme.palette.common.warning,
    verticalAlign: 'sub',
    marginRight: 10,
  },
  textareaPreferences: {
    // marginBottom: 15,
    '& textarea': {
      whiteSpace: 'nowrap',
      overflow: 'auto !important',
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      background: 'black',
    },
  },
  deletePrefsButton: {
    color: 'white',
    background: theme.palette.common.error,
    '&:hover': {
      backgroundColor: '#a10404',
    },
  },

  // settingsHeader: {
  //   height: 80,
  //   background: 'url(' + IMAGE_URL + '/images/settings_background.jpg) no-repeat',
  //   backgroundSize: '100%',
  //   boxShadow: 'inset 0px 0px 20px 10px rgba(0,0,0,0.1)',
  //   paddingTop: 40,
  //   fontSize: 40,
  //   textShadow: '2px 1px 2px grey',
  //   color: 'white',
  // },
  settingsInfoText: {
    paddingTop: 15,
    paddingBottom: 20,
    fontSize: '1rem',
  },
  colorPicker: {
    width: '100% !important',
    height: '100px !important',
    padding: '18.5px 14px',
    '& div': {
      cursor: 'pointer',
    },
  },
  colorPickerCnt: {
    border: '1px solid #c4c4c4',
    width: 'calc(50% - 8px)',
    display: 'inline-flex',
    marginTop: 30,
    borderRadius: 4,
    position: 'relative',
  },
  colorPickerCntRegion: {
    width: 'calc(100% - 8px)',
  },
  colorPickerTitle: {
    backgroundColor: 'white',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 7px',
    fontSize: '1rem',
    fontFamily: '"FujitsuInfinityPro", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    transform: 'translate(-4px, -8px) scale(0.75)',
  },
  colorChangeDiv: {
    width: 50,
    height: 16,
    display: 'inline-flex',
    position: 'relative',
    top: 2,
    borderRadius: 4,
    margin: '0px 10px',
  },
  colorChangeMessage: {
    width: 50,
    height: 16,
    display: 'inline-flex',
    position: 'relative',
    top: 2,
    borderRadius: 4,
    margin: '0px 10px',
  },
  switchBackground: {
    '& > .MuiTypography-root': {
      fontSize: 'small',
      marginRight: 10,
    },
  },
  input: {
    width: 50,
    '&:hover::before': {
      borderBottom: '0 !important',
    },
    '&::before': {
      borderBottom: 0,
    },
  },
  visibilityColor: {
    color: theme.palette.primary.main,
  },
  chiplevel: {
    height: 20,
    marginLeft: 10,
    '& span': {
      padding: 5,
    },
  },
  datagrid: {
    height: 300,
    display: 'inline-flex',
    width: '100%',
    marginTop: 30,
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-row:nth-child(even):hover': {
      backgroundColor: '#0000000F',
    },
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: '#00000008',
    },
    '& .MuiDataGrid-row:nth-child(odd):hover': {
      backgroundColor: '#00000012',
    },
  },
  dialogTitle: {
    backgroundImage: 'linear-gradient(' + alpha(theme.palette.primary.main, 0.12) + ', white)',
    backgroundColor: 'white',
    borderTop: '4px solid ' + theme.palette.primary.main,
  },
  zipBadge: {
    '& .MuiBadge-badge': {
      right: -4,
      top: 8,
      // backgroundColor: theme.palette.secondary.main,
    },
  },
  infoBadge: {
    '& .MuiBadge-badge': {
      right: -4,
      // backgroundColor: theme.palette.secondary.main,
    },
  },
  sendBadge: {
    '& .MuiBadge-badge': {
      right: -4,
      top: 1,
      // backgroundColor: theme.palette.secondary.main,
    },
  },
  autoClose: {
    border: '1px solid ' + theme.palette.primary.main,
    color: theme.palette.primary.main,
    borderRadius: 5,
    paddingRight: 10,
    marginLeft: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 'smaller',
    },
    '& .MuiCheckbox-root': {
      padding: 5,
    },
  },
}))

function SwipeableViews(props) {
  return null
}

SwipeableViews.propTypes = {
  axis: PropTypes.any,
  onChangeIndex: PropTypes.any,
  children: PropTypes.node,
}
export default function CompressDialog({
  globalProgress,
  currentFile,
  totalFiles,
  zippedFiles,
  acceptedFiles,
  zipLevel,
  remainingTime,
  sendMessage,
  uploadInProgress,
  batchName,
  batchDefault,
  batchDescription,
  setBatchName,
  setBatchDescription,
  zipDone,
  uploadProgress,
  onCancel,
  configuration,
  setConfiguration,
  aiModeActive,
  setAiModeActive,
  batchDevice,
  setBatchDevice,
  batchDate,
  setBatchDate,
  config_ai_mode,
  setConfig_ai_mode,
}) {
  const classes = useStyles()
  const genericClasses = genericStyles()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [tabActive, setTabActive] = React.useState('1')
  const [autoClose, setAutoClose] = React.useState(false)

  const handleClose = () => {
    dispatch(fetchChangeSettings('uploadOpenCompress', false))
  }
  const handleCancel = () => {
    onCancel()
  }

  const task = useSelector(function (state) {
    return state.tasks.task
  })

  const openDialog = useSelector(function (state) {
    return state.settings.uploadOpenCompress
  })

  const handleChange = (event, newValue) => {
    setTabActive(newValue)
  }

  useEffect(() => {
    if (batchName !== '') {
      if (zipDone) {
        setTabActive('3')
      } else {
        setTabActive('2')
      }
    }
  }, [batchName])

  useEffect(() => {
    if (zipDone && batchName !== '') {
      setTabActive('3')
    }
  }, [zipDone])

  useEffect(() => {
    if (globalProgress === 0) {
      setTabActive('1')
    }
  }, [globalProgress])

  useEffect(() => {
    if (task && task.status === 'FINISHED' && autoClose) {
      handleClose()
    }
  }, [task])

  const totalSize = useMemo(() => {
    return humanFileSize(
      acceptedFiles.map((file) => file.size).reduce((a, b) => a + b, 0),
      true,
    )
  }, [JSON.stringify(acceptedFiles)])

  return (
    <Dialog
      open={openDialog}
      onClose={() => handleClose()}
      TransitionComponent={TransitionBottom}
      aria-labelledby="draggable-dialog-title"
      classes={{
        paper: classes.customRootDialog,
      }}
    >
      <DialogTitle style={{ padding: 0 }}>
        <Box className={genericClasses.header}>
          <CardMedia
            className={genericClasses.cardImg}
            style={{ opacity: 0.6 }}
            component="img"
            alt="header image"
            height="130"
            image={image}
            title="Header image"
          />
          <span className={genericClasses.title}>
            {/*{batchName !== '' ? t('view.dialogs.compress.title') : 'New batch'}*/}
            {globalProgress === 0 ? (
              t('view.dialogs.compress.title')
            ) : (
              <span>
                {tabActive === '1' && t('view.dialogs.compress.edit')}
                {tabActive === '2' && t('view.dialogs.compress.zip')}
                {tabActive === '3' && t('view.dialogs.compress.info')}
              </span>
            )}
          </span>
          {globalProgress === 0 ? (
            <CreateNewFolderIcon className={genericClasses.icon} />
          ) : (
            <span>
              {tabActive === '1' && <BorderColorIcon className={genericClasses.icon} />}
              {tabActive === '2' && <ZipFileInvert className={genericClasses.icon} />}
              {tabActive === '3' && <InfoIcon className={genericClasses.icon} />}
            </span>
          )}
        </Box>
      </DialogTitle>
      <DialogContent style={{ margin: 0, padding: 0, height: 610 }}>
        {globalProgress === 0 ? (
          <span>
            <DialogTitle className={classes.dialogTitle}>
              {I18n.t('view.dialogs.compress.upload_title')}
            </DialogTitle>
            <div
              style={{
                padding: 30,
                paddingTop: 80,
                height: 'calc(100% - 68px)',
              }}
            >
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                style={{ height: '100%' }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={5}
                  style={{ width: '100%' }}
                >
                  <Loading mode={'bubbles'} style={{ position: 'relative', top: 0 }} />
                  <div>{t('view.dialogs.compress.uploading')}</div>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={1}
                  style={{ marginTop: 50 }}
                >
                  <UploadFileIcon />
                  <div>
                    {totalFiles}{' '}
                    {totalFiles > 1
                      ? t('view.dialogs.compress.files')
                      : t('view.dialogs.compress.file')}{' '}
                    ({totalSize})
                  </div>
                </Stack>
              </Stack>
            </div>
          </span>
        ) : (
          <TabContext value={tabActive}>
            <TabList onChange={handleChange}>
              <Tab
                icon={
                  batchName !== '' ? (
                    <Badge
                      badgeContent={
                        <DoneIcon style={{ height: 13, width: 13, position: 'absolute' }} />
                      }
                      color="secondary"
                      className={classes.infoBadge}
                    >
                      <BorderColorIcon />
                    </Badge>
                  ) : (
                    <BorderColorIcon />
                  )
                }
                aria-label="info"
                value="1"
              />
              <Tab
                icon={
                  zipDone ? (
                    <Badge
                      badgeContent={
                        <DoneIcon style={{ height: 13, width: 13, position: 'absolute' }} />
                      }
                      color="secondary"
                      className={classes.zipBadge}
                    >
                      <ZipFileInvert style={{ width: 20 }} />
                    </Badge>
                  ) : (
                    <ZipFileInvert style={{ width: 20 }} />
                  )
                }
                aria-label="zip"
                value="2"
                disabled={batchName === ''}
              />
              <Tab
                icon={
                  (task && ['FINISHED', 'FAILED', 'CANCELLED'].indexOf(task.status) !== -1) ||
                  sendMessage === I18n.t('view.panel.dropzone.sent_ko') ? (
                    <Badge
                      badgeContent={
                        task && task.status === 'FINISHED' ? (
                          <DoneIcon style={{ height: 13, width: 13, position: 'absolute' }} />
                        ) : (
                          <ClearIcon style={{ height: 13, width: 13, position: 'absolute' }} />
                        )
                      }
                      color={task && task.status === 'FINISHED' ? 'secondary' : 'error'}
                      className={classes.sendBadge}
                    >
                      <InfoIcon />
                    </Badge>
                  ) : (
                    <InfoIcon />
                  )
                }
                aria-label="send"
                value="3"
                disabled={batchName === ''}
              />
            </TabList>
            <TabPanel value="1">
              <ErrorBoundaryGuard>
                <InfosPanel
                  batchDefault={batchDefault}
                  batchName={batchName}
                  batchDescription={batchDescription}
                  setBatchName={setBatchName}
                  setBatchDescription={setBatchDescription}
                  configuration={configuration}
                  setConfiguration={setConfiguration}
                  aiModeActive={aiModeActive}
                  setAiModeActive={setAiModeActive}
                  batchDevice={batchDevice}
                  setBatchDevice={setBatchDevice}
                  batchDate={batchDate}
                  setBatchDate={setBatchDate}
                  config_ai_mode={config_ai_mode}
                  setConfig_ai_mode={setConfig_ai_mode}
                />
              </ErrorBoundaryGuard>
            </TabPanel>
            <TabPanel value="2">
              <ErrorBoundaryGuard>
                <ZipPanel
                  globalProgress={globalProgress}
                  currentFile={currentFile}
                  totalFiles={totalFiles}
                  zippedFiles={zippedFiles}
                  acceptedFiles={acceptedFiles}
                  zipLevel={zipLevel}
                  remainingTime={remainingTime}
                />
              </ErrorBoundaryGuard>
            </TabPanel>
            <TabPanel value="3">
              <ErrorBoundaryGuard>
                <SendPanel sendMessage={sendMessage} uploadProgress={uploadProgress} />
              </ErrorBoundaryGuard>
            </TabPanel>
          </TabContext>
        )}
        {/*{batchName !== '' ? (*/}
        {/*  <Fragment>*/}
        {/*    <div style={{ filter: globalProgress === 0 ? 'blur(4px)' : 'none' }}>*/}
        {/*      <DialogContentText className={classes.settingsInfoText}>*/}
        {/*        {t('view.dialogs.compress.level') + ': '}*/}
        {/*        <Chip color="secondary" label={zipLevel} className={classes.chiplevel} />*/}
        {/*      </DialogContentText>*/}
        {/*      <Progress progress={globalProgress} width={'100%'} />*/}
        {/*      {globalProgress !== 100 && currentFile && (*/}
        {/*        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>*/}
        {/*          <SettingsIcon*/}
        {/*            style={{*/}
        {/*              animation: 'rotation 2s infinite linear',*/}
        {/*              marginTop: 8,*/}
        {/*              color: 'rgba(0, 0, 0, 0.6)',*/}
        {/*            }}*/}
        {/*          />*/}
        {/*          <DialogContentText style={{ marginTop: 10 }}>{currentFile}</DialogContentText>*/}
        {/*        </Stack>*/}
        {/*      )}*/}

        {/*      <Stack*/}
        {/*        direction="row"*/}
        {/*        justifyContent="space-between"*/}
        {/*        alignItems="center"*/}
        {/*        spacing={2}*/}
        {/*        style={{ marginTop: 10 }}*/}
        {/*      >*/}
        {/*        <DialogContentText>*/}
        {/*          {globalProgress !== 100 && remainingTime && (*/}
        {/*            <Stack*/}
        {/*              direction="row"*/}
        {/*              justifyContent="flex-start"*/}
        {/*              alignItems="center"*/}
        {/*              spacing={1}*/}
        {/*            >*/}
        {/*              <AccessTimeFilledIcon />*/}
        {/*              <div>{remainingTime}</div>*/}
        {/*            </Stack>*/}
        {/*          )}*/}
        {/*        </DialogContentText>*/}
        {/*        <DialogContentText>*/}
        {/*          {t('view.dialogs.compress.total') +*/}
        {/*            ': ' +*/}
        {/*            (zippedFiles.length !== 0*/}
        {/*              ? globalProgress === 100*/}
        {/*                ? zippedFiles.length*/}
        {/*                : zippedFiles.length - 1*/}
        {/*              : 0) +*/}
        {/*            '/' +*/}
        {/*            totalFiles}*/}
        {/*        </DialogContentText>*/}
        {/*      </Stack>*/}
        {/*      <DataGrid*/}
        {/*        className={classes.datagrid}*/}
        {/*        density={'compact'}*/}
        {/*        rows={rows}*/}
        {/*        columns={columns}*/}
        {/*        disableSelectionOnClick*/}
        {/*        // style={{ border: 'none' }}*/}
        {/*        sortModel={sortModel}*/}
        {/*        onSortModelChange={function (newSortModel) {*/}
        {/*          localStorage.setItem('DATAGRID_dropzone_sort', JSON.stringify(newSortModel))*/}
        {/*          setSortModel(newSortModel)*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      {sendMessage && (*/}
        {/*        <Stack*/}
        {/*          direction="row"*/}
        {/*          justifyContent="flex-start"*/}
        {/*          alignItems="center"*/}
        {/*          spacing={1}*/}
        {/*          style={{ marginTop: 25, color: 'rgba(0, 0, 0, 0.6)' }}*/}
        {/*        >*/}
        {/*          <SendIcon />*/}
        {/*          <span>:</span>*/}
        {/*          <span>{sendMessage}</span>*/}
        {/*        </Stack>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*<div*/}
        {/*  style={{*/}
        {/*    display: globalProgress === 0 ? 'block' : 'none',*/}
        {/*    position: 'absolute',*/}
        {/*    top: '36%',*/}
        {/*    // verticalAlign: 'middle',*/}
        {/*    // alignContent: 'center',*/}
        {/*    // textAlign: 'center',*/}
        {/*    width: '60%',*/}
        {/*    left: '15%',*/}
        {/*    // padding: 30,*/}
        {/*    // paddingTop: 40,*/}
        {/*    background: 'white',*/}
        {/*    boxShadow:*/}
        {/*      '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <DialogTitle className={classes.dialogTitle}>*/}
        {/*    {I18n.t('view.dialogs.compress.upload_title')}*/}
        {/*  </DialogTitle>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      padding: 30,*/}
        {/*      paddingTop: 40,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>*/}
        {/*      <Loading mode={'bubbles'} style={{ position: 'relative' }} />*/}
        {/*      <div>{t('view.dialogs.compress.uploading')}</div>*/}
        {/*    </Stack>*/}
        {/*    <Stack*/}
        {/*      direction="row"*/}
        {/*      justifyContent="left"*/}
        {/*      alignItems="center"*/}
        {/*      spacing={1}*/}
        {/*      style={{ marginTop: 20 }}*/}
        {/*    >*/}
        {/*      <UploadFileIcon />*/}
        {/*      <div>*/}
        {/*        {totalFiles}{' '}*/}
        {/*        {totalFiles > 1*/}
        {/*          ? t('view.dialogs.compress.files')*/}
        {/*          : t('view.dialogs.compress.file')}{' '}*/}
        {/*        ({totalSize})*/}
        {/*      </div>*/}
        {/*    </Stack>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*  </Fragment>*/}
        {/*) : (*/}
        {/*  <div>NAME</div>*/}
        {/*)}*/}
      </DialogContent>

      <DialogActions style={{ padding: 8 }}>
        <Stack
          direction="row"
          justifyContent={task && task.status === 'FINISHED' ? 'flex-end' : 'space-between'}
          alignItems="center"
          spacing={2}
          style={{ width: '100%' }}
        >
          {globalProgress !== 0 && (
            <Fragment>
              {(!task || task.status !== 'FINISHED') && (
                <FormControlLabel
                  // style={{ marginLeft: 0 }}
                  className={classes.autoClose}
                  label={t('view.dialogs.compress.autoClose')}
                  control={
                    <Checkbox
                      size="small"
                      checked={autoClose}
                      onChange={() => setAutoClose(!autoClose)}
                    />
                  }
                />
              )}
              <Button
                size={'medium'}
                onClick={() => handleClose()}
                color="primary"
                disabled={batchName === ''}
              >
                {t('view.dialogs.compress.hide')}
              </Button>
            </Fragment>
          )}
        </Stack>
        <Button size={'medium'} onClick={() => handleCancel()} color="primary">
          {t('view.dialogs.compress.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
