// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { AUTHENTICATION_FAILURE } from '../unitaryAnalyses/actions'
import { API_BO_URL, CACHE_IS_USED } from '../../utils/config'
import { fetchChangeSettings } from '../settings/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'
import { fetchCachePopulate } from '../cache/actions'

export const REQUEST_DETAIL = 'REQUEST_DETAIL'
export const DISPLAY_DETAIL = 'DISPLAY_DETAIL'
export const HIDE_DETAIL = 'HIDE_DETAIL'
export const REQUEST_CHANGE_DETAIL_ACTIVE_TAB = 'REQUEST_CHANGE_DETAIL_ACTIVE_TAB'
export const CHANGE_DETAIL_ACTIVE_TAB = 'CHANGE_DETAIL_ACTIVE_TAB'
export const CHANGE_DETAIL_CHANNEL = 'CHANGE_DETAIL_CHANNEL'
export const REQUEST_UNITARY_ANALYSES_CHANGE_LOCK_DETAIL =
  'REQUEST_UNITARY_ANALYSES_CHANGE_LOCK_DETAIL'
export const REQUEST_CHROMATO = 'REQUEST_CHROMATO'
export const RECEIVE_CHROMATO = 'RECEIVE_CHROMATO'
export const RECEIVE_UPDATE_UNITARY_ANALYSES_DETAIL = 'RECEIVE_UPDATE_UNITARY_ANALYSES_DETAIL'
export const RECEIVE_UPDATE_VALIDATION_DETAIL = 'RECEIVE_UPDATE_VALIDATION_DETAIL'

export function requestDisplayDetail() {
  return {
    type: REQUEST_DETAIL,
  }
}
export function requestChangeDetailActiveTab() {
  return {
    type: REQUEST_CHANGE_DETAIL_ACTIVE_TAB,
  }
}
export function displayDetail(detail, externalMode) {
  return {
    type: DISPLAY_DETAIL,
    detail: detail,
    externalMode: externalMode,
  }
}
export function changeDetailActiveTab(newActiveTab) {
  return {
    type: CHANGE_DETAIL_ACTIVE_TAB,
    newActiveTab: newActiveTab,
  }
}
export function hideDetail(externalMode) {
  return {
    type: HIDE_DETAIL,
    externalMode: externalMode,
  }
}

export function fetchDisplayChromatoDetail(analyseId) {
  return async (dispatch) => {
    dispatch(requestChromato(analyseId))
    const url = `${API_BO_URL()}.unitary_analysis/${analyseId}`
    let promise
    if (CACHE_IS_USED()) {
      promise = dispatch(fetchCachePopulate(url))
    } else {
      promise = fetch(url, {
        method: 'GET',
        credentials: 'include',
      }).then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
    }
    return promise
      .then((json) => {
        return dispatch(receiveChromato(json))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get unitary analysis',
          <Fragment>
            <div>An error does not allow to get unitary analysis:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
export function requestChromato(analyseId) {
  return {
    type: REQUEST_CHROMATO,
    unitaryAnalysisId: analyseId,
  }
}
export function receiveChromato(unitaryAnalyses) {
  return {
    type: RECEIVE_CHROMATO,
    unitaryAnalysis: unitaryAnalyses,
  }
}

export function fetchUpdateDetailInfo(
  item,
  activeTab,
  hideGeneral,
  anchorEl,
  collection,
  externalMode,
) {
  return async (dispatch) => {
    dispatch(requestDisplayDetail())
    const detail = {
      item: item,
      activeTab: activeTab,
      hideGeneral: hideGeneral,
      // anchorEl: anchorEl,
      collection: collection,
    }
    return dispatch(displayDetail(detail, externalMode))
  }
}

export function fetchChangeDetailActiveTab(newActiveTab) {
  return async (dispatch) => {
    dispatch(requestChangeDetailActiveTab())
    return dispatch(changeDetailActiveTab(newActiveTab))
  }
}

// Not use anymore: use settings displayChromatoDetailDialog
export function fetchHideDetail(externalMode) {
  return async (dispatch) => {
    dispatch(requestDisplayDetail())
    return dispatch(hideDetail(externalMode))
  }
}

export function fetchChangeSelectedChannelDetail(channel) {
  return async (dispatch) => {
    return dispatch({
      type: CHANGE_DETAIL_CHANNEL,
      channel: channel,
    })
  }
}

export function fetchChangeLockDetail(uaId) {
  return async (dispatch) => {
    return dispatch({
      type: REQUEST_UNITARY_ANALYSES_CHANGE_LOCK_DETAIL,
      uaId: uaId,
    })
  }
}

export function receiveUpdateUnitaryAnalysesDetail(json, channel, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_UNITARY_ANALYSES_DETAIL,
    uaChannel: channel,
    uaUpdated: json,
    uaCollection: collection,
  }
}

export function receiveUpdateValidationDetail(data, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_VALIDATION_DETAIL,
    uasUpdated: data,
    uaCollection: collection,
  }
}
