// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useMemo } from 'react'
import Paper from '@material-ui/core/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { alpha, withStyles } from '@material-ui/core/styles'
import Title from '../Title'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import { withRouter } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import {
  getComparator,
  stableSort,
  moveSampleToSubBatch,
  getClickAction,
  getHeaderTooltipCmp, StripedDataGridPro,
} from '../../utils/utilities'
import { TransitionTop } from '../common/Style'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Button from '@material-ui/core/Button/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import { getBatchById } from '../../redux/batches/selectors'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import NoteButton from '../common/NoteButton'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { fetchChangeSettings } from '../../redux/settings/actions'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import MoveDialog from './MoveDialog'
import { Stack } from '@mui/material'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { CustomNoRowsOverlay } from './Tools'
import { useGridApiRef } from '@mui/x-data-grid'
import Loading from '../Loading'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { getFlagName } from '../analysis/Tools'
import { flagsFilterOperators } from '../common/ColumnFilters'
import { getFlagIcon } from '../backlog/Tools'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { openAnalysisInNewTab } from '../compound/Tools'
import { fetchDisplayJson } from '../../redux/json/actions'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core'
import {
  fetchAddAnalysisVisibilityTag,
  fetchRemoveAnalysisVisibilityTag,
} from '../../redux/analyses/actions'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import { DATABASE } from '../../utils/config'

const useStyles = makeStyles((theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    height: 'calc(100% - 35px)',
    overflow: 'hidden',
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.common.black, 0.03),
    },
  },
  toolbar: {
    position: 'absolute',
    top: 48,
    left: 70,
    background: '#fff',
    padding: 5,
    paddingRight: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  visibilityButton: {
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  actionDivider: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0px !important',
    margin: '5px 0px',
  },
}))

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    width: '100%',
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
})(LinearProgress)

const CustomDialog = withStyles((theme) => ({
  paper: {
    height: '100%',
  },
}))(Dialog)

const QualityControlTable = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const apiRef = useGridApiRef()
  const analysis = useSelector((state) => state.analyses.items)
  const analysesSentToLims = useSelector((state) => state.analyses.analysesSentToLims)
  const analysesNotSentToLims = useSelector((state) => state.analyses.analysesNotSentToLims)
  const isFetching = useSelector((state) => state.analyses.isFetching)
  const wikiCODHelper = useSelector((state) => state.settings.wikiCODHelper)

  const [rowSelectionModel, setRowSelectionModel] = React.useState([])
  const [openBatchMove, setOpenBatchMove] = React.useState(false)
  const [selection, setSelection] = React.useState([])
  const [openExtentedView, setOpenExtentedView] = React.useState(false)
  const [moveBatch, setMoveBatch] = React.useState(null)

  const defaultState = {
    sorting: {
      sortModel: [
        {
          field: 'name',
          sort: 'asc',
        },
      ],
    },
  }

  const saveSnapshot = React.useCallback(
    (fullscreenMode) => {
      if (apiRef?.current?.exportState) {
        const stateSnapshot = apiRef.current.exportState()
        if (Object.keys(stateSnapshot).length !== 0) {
          localStorage.setItem('BATCH_qc_state', JSON.stringify(stateSnapshot))
        }
      }
    },
    [apiRef],
  )

  const echClient = useMemo(() => {
    return getEchClient()
  }, [JSON.stringify(analysis)])

  const selectionEmpty = useMemo(() => {
    return selection.length === 0
  }, [JSON.stringify(selection)])

  function getEchClient() {
    return analysis
      .filter(
        (analyse) =>
          (analyse.content.type === 'blank' ||
            analyse.content.type === 'matrix_bio' ||
            analyse.content.type === 'matrix_bio_doped' ||
            analyse.content.type === 'std_end' ||
            analyse.content.type === 'calibration') &&
          !analyse.content.inProgress,
      )
      .map((ech) => ({
        ...ech.content,
        original_row: ech,
        id: ech._id,
        _id: ech._id,
        batchId: ech.content.batch.id,
        analyseId: ech._id,
        name: ech.name,
        level: ech.content.level,
        disabled: ech.content.disabled,
        type: ech.content.type,
        nb_ok: ech.content.nb_ok,
        nb_ko: ech.content.nb_ko,
        suffix: ech.content.suffix,
        sentToLims:
          analysesSentToLims && analysesSentToLims.indexOf(ech._id) !== -1
            ? true
            : analysesNotSentToLims && analysesNotSentToLims.indexOf(ech._id) !== -1
            ? false
            : null,
        visibility: !(ech.tags && ech.tags.toString().indexOf('off_visibility') !== -1),
      }))
  }

  function onChangeVisibility(event, item) {
    event.stopPropagation()
    event.preventDefault()

    const displayMessage = (mode, error) => {
      displayPopupMessage(
        dispatch,
        error ? 'error' : 'success',
        t('view.batch.othersTable.visibility_title'),
        <Fragment>
          {error ? (
            <Fragment>
              <div>
                <Trans i18nKey="view.batch.othersTable.visibility_msg1">
                  The visibility for the analysis <i>{{ val: item.name }}</i> can't be updated:
                </Trans>
              </div>
              {generateErrorPart(error)}
            </Fragment>
          ) : (
            <div>
              <Trans i18nKey="view.batch.othersTable.visibility_msg2">
                The analysis <i>{{ val1: item.name }}</i> is now
                {{
                  val2:
                    mode === 'add'
                      ? t('view.batch.othersTable.visible')
                      : t('view.batch.othersTable.hidden'),
                }}{' '}
                in the navigation panel.
              </Trans>
            </div>
          )}
        </Fragment>,
      )
    }
    // Add or Remove tag off_visibility on analysis object
    if (item.visibility) {
      dispatch(fetchRemoveAnalysisVisibilityTag(item))
        .then(function (resp) {
          displayMessage('remove')
        })
        .catch(function (error) {
          displayMessage('remove', error)
        })
    } else {
      dispatch(fetchAddAnalysisVisibilityTag(item))
        .then(function (resp) {
          if (resp.error) {
            displayMessage('add', resp.error)
          } else {
            displayMessage('add')
          }
        })
        .catch(function (error) {
          displayMessage('add', error)
        })
    }
  }

  function onSelect(selected) {
    setSelection(selected.map((i) => getEchClient()[i]))
  }

  function clearSelection() {
    setRowSelectionModel([])
    if (onSelect) onSelect([])
  }

  function handleCloseMove(event, save) {
    setOpenBatchMove(false)
    if (typeof save === 'boolean' && save) {
      clearSelection()
    }
  }
  function CustomLoading() {
    return (
      <div style={{ height: 'calc(100% - 96px)', position: 'relative' }}>
        <Loading mode="miniCircle" />
      </div>
    )
  }

  function onDisplayClient(id) {
    localStorage.setItem('BATCH_previous_selection', id)
    props.history.push('/?' + DATABASE() + '/' + props.match.params.batchId + '/analysis/' + id)
  }

  function handleOpenRenameDialog(event, row) {
    event.stopPropagation()
    event.preventDefault()
    dispatch(fetchChangeSettings('analysisToRename', row))
  }

  async function multiValidate() {
    setOpenBatchMove(true)
    setMoveBatch(null)
  }

  const columnsMemoized = useMemo(() => {
    return [
      {
        field: 'visibility',
        width: 80,
        headerName: t('view.batch.othersTable.visibility'),
        type: 'boolean',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <Tooltip
              arrow
              title={<div className={classes.tooltip}>{t('view.batch.othersTable.show_hide')}</div>}
            >
              <IconButton
                aria-label="changeVisibility"
                className={classes.visibilityButton}
                onClick={(event) => onChangeVisibility(event, row)}
              >
                {!row.visibility ? (
                  <VisibilityOffIcon
                    // style={{ color: '#AF0000' }}
                    className={'visibilityOffIcon'}
                  />
                ) : (
                  <VisibilityIcon className={'visibilityIcon'} />
                )}
              </IconButton>
            </Tooltip>
          )
        },
      },
      {
        field: 'sentToLims',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        width: 80,
        type: 'boolean',
        headerName: t('view.batch.samplesTable.column.reported'),
        renderCell: function ({ value }) {
          return value ? (
            <CheckIcon style={{ color: '#0000008a' }} />
          ) : value === false ? (
            <CloseIcon style={{ color: '#0000008a' }} />
          ) : null
        },
      },
      {
        field: 'flags',
        width: 80,
        type: 'custom',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.qualityControlTable.column.flags'),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <ErrorBoundaryGuard isDialog>
              <AlphacodFlagIcon item={row} hideGeneralTab={true} />
            </ErrorBoundaryGuard>
          )
        },
        valueFormatter: (params) => {
          return getFlagName(params.api.getRow(params.id))
        },
        filterOperators: flagsFilterOperators,
        valueGetter: ({ value }) => {
          switch (getFlagIcon((value.globals || []).filter((f) => !f.bypass_backlog))) {
            case 'info':
              return 0
            case 'action':
              return 1
            case 'warning':
              return 2
            case 'error':
              return 3
            default:
              return -1
          }
        },
      },
      {
        field: 'notes',
        width: 80,
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.qualityControlTable.column.notes'),
        valueGetter: ({ value }) => value?.length,
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <ErrorBoundaryGuard isDialog>
              <NoteButton
                row={{
                  _id: row.id,
                  notes: row.notes ? row.notes : [],
                }}
                collection={'analysis'}
              />
            </ErrorBoundaryGuard>
          )
        },
      },
      {
        field: 'name',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.qualityControlTable.column.name'),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <Tooltip arrow title={<div className={classes.tooltip}>{row.file}</div>}>
              <Stack
                style={{ overflow: 'hidden' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
              >
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {row.name}
                </div>
                <div
                  style={{ display: 'none', height: 20, marginBottom: 7, marginLeft: 5 }}
                  className={'nameAdorment'}
                >
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={(event) => handleOpenRenameDialog(event, row.original_row)}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    <Tooltip
                      arrow
                      placement={'top'}
                      title={
                        <div className={classes.tooltip}>
                          {t('view.batch.qualityControlTable.rename')}
                        </div>
                      }
                    >
                      <DriveFileRenameOutlineIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              </Stack>
            </Tooltip>
          )
        },
      },
      {
        field: 'level',
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.qualityControlTable.column.level'),
      },
      {
        field: 'type',
        type: 'string',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.qualityControlTable.column.type'),
      },
      {
        field: 'nb_ok',
        width: 80,
        type: 'number',
        headerName: t('view.batch.qualityControlTable.column.ok'),
        renderHeader: (params) =>
          getHeaderTooltipCmp(
            params,
            wikiCODHelper,
            <ThumbUpAltOutlinedIcon style={{ opacity: 0.8 }} />,
          ),
      },
      {
        field: 'nb_ko',
        width: 80,
        type: 'number',
        headerName: t('view.batch.qualityControlTable.column.ko'),
        renderHeader: (params) =>
          getHeaderTooltipCmp(
            params,
            wikiCODHelper,
            <ThumbDownAltOutlinedIcon style={{ opacity: 0.8 }} />,
          ),
      },
      {
        field: 'suffix',
        type: 'string',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.qualityControlTable.column.suffix'),
      },
      {
        field: 'progression',
        minWidth: 120,
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.samplesTable.column.progression'),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <Tooltip
              key={row.id}
              arrow
              title={
                <div className={classes.tooltip}>
                  <div>
                    {t('view.batch.samplesTable.column.progression') +
                      `: ${
                        row.progression_requested ? row.progression_requested.toFixed(2) : '0.00'
                      } %`}
                  </div>
                  <div style={{ marginTop: 10 }}>
                    {t('view.batch.samplesTable.column.detected') +
                      `: ${row.nb_detected_validated ? row.nb_detected_validated : '0'}`}
                  </div>
                  <div>
                    {t('view.batch.samplesTable.column.suspected') +
                      `: ${row.nb_suspected_validated ? row.nb_suspected_validated : '0'}`}
                  </div>
                  <div>
                    {t('view.batch.samplesTable.column.excluded') +
                      `: ${row.nb_excluded_validated ? row.nb_excluded_validated : '0'}`}
                  </div>
                  <div style={{ marginTop: 10 }}>{`OK: ${
                    row.nb_ok_validated ? row.nb_ok_validated : '0'
                  }`}</div>
                  <div>{`KO: ${row.nb_ko_validated ? row.nb_ko_validated : '0'}`}</div>
                </div>
              }
            >
              <BorderLinearProgress
                variant="determinate"
                value={row.progression_requested ? row.progression_requested : 0}
              />
            </Tooltip>
          )
        },
      },
      {
        field: 'actions',
        type: 'actions',
        hideable: false,
        resizable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: false,
        disableReorder: true,
        disableExport: true,
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        getActions: ({ row, ...otherParams }) => [
          <GridActionsCellItem
            className={classes.actionMenu}
            showInMenu
            icon={<OpenInBrowserIcon />}
            label={t('view.batch.othersTable.actions.open')}
            onClick={() => props.history.push('/?' + DATABASE() + '/' + row.batchId + '/analysis/' + row._id)}
          />,
          <GridActionsCellItem
            className={classes.actionMenu}
            showInMenu
            icon={<OpenInNewIcon />}
            label={t('view.batch.othersTable.actions.openNew')}
            onClick={() => openAnalysisInNewTab(row)}
          />,
          <GridActionsCellItem
            disabled={true}
            className={classes.actionDivider}
            showInMenu
            label={''}
          />,
          <GridActionsCellItem
            onClick={() => {
              dispatch(fetchDisplayJson(row._id, 'analysis'))
            }}
            className={classes.actionMenu}
            showInMenu
            icon={<SearchIcon />}
            label={t('view.batch.othersTable.actions.explore')}
          />,
        ],
      },
    ]
  }, [])

  const getRenderCmp = (fullscreenMode) => {
    return (
      <Fragment>
        <ErrorBoundaryGuard isDialog>
          <MoveDialog
            selection={selection}
            open={openBatchMove}
            closeFct={handleCloseMove}
            origin={'qc'}
          />
        </ErrorBoundaryGuard>
        <Paper className={classes.paper} elevation={0}>
          <Title>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              {/*<Grid item>*/}
              <Box>{t('view.batch.qualityControlTable.qc')}</Box>
              {!fullscreenMode && (
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <div className={classes.tooltip}>
                      {t('view.batch.qualityControlTable.full_screen')}
                    </div>
                  }
                >
                  <IconButton
                    size="small"
                    aria-label="extend"
                    onClick={() => {
                      setOpenExtentedView(true)
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Title>
          <div className={classes.tableContainer}>
            <ErrorBoundaryGuard>
              <StripedDataGridPro
                initialState={{
                  ...defaultState,
                  ...JSON.parse(localStorage.getItem('BATCH_qc_state')),
                }}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel)
                  setSelection(
                    echClient
                      .filter((objet) => newRowSelectionModel.includes(objet._id))
                      .map((tmpRow) => tmpRow.original_row),
                  )
                }}
                rowSelectionModel={rowSelectionModel}
                onStateChange={() => saveSnapshot(fullscreenMode)}
                rowHeight={40}
                loading={isFetching}
                slots={{
                  // moreActionsIcon: CustomActionIcon,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                  loadingOverlay: CustomLoading,
                  // columnMenu: CustomColumnMenu,
                }}
                apiRef={apiRef}
                getRowClassName={(params) => {
                  let classesAdded = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  // if (isLoading(params.row)) {
                  //   classesAdded += ' loading'
                  // }
                  return classesAdded
                }}
                getCellClassName={(params) => {
                  return 'customCell'
                }}
                // disableVirtualization={batches.length < 50}
                rowBuffer={3}
                rowThreshold={3}
                columnBuffer={3}
                columnThreshold={3}
                rows={echClient}
                columns={columnsMemoized}
                pagination={false}
                hideFooter={true}
                style={{ border: 'none' }}
                // Do not use autoSize: conflic with initialState
                // autosizeOnMount
                // autosizeOptions={autoSizeOptions}
                onRowClick={(params, event, details) => {
                  const openFct = () => {
                    onDisplayClient(params.row.id)
                  }
                  getClickAction(dispatch, event, params.row, 'analysis', openFct)
                }}
                // onResize={() => hideWatermark()} //TODO To be deleted after purchasing a license
              />
            </ErrorBoundaryGuard>
          </div>
          {!selectionEmpty && (
            <span
              className={classes.toolbar}
              style={{ visibility: selectionEmpty ? 'hidden' : 'visible' }}
            >
              <div>
                <Tooltip
                  arrow
                  title={
                    <div className={classes.tooltip}>
                      {t('view.batch.qualityControlTable.move_to_others')}
                    </div>
                  }
                >
                  <IconButton
                    className={classes.toolbarButton}
                    aria-label="Move to Others"
                    onClick={multiValidate}
                  >
                    <SwapHorizIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </span>
          )}
        </Paper>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <CustomDialog
        fullWidth={true}
        maxWidth={'xl'}
        open={openExtentedView}
        onClose={() => setOpenExtentedView(false)}
        TransitionComponent={TransitionTop}
        aria-labelledby="draggable-dialog-title"
      >
        {openExtentedView && getRenderCmp(true)}
        <DialogActions>
          <Button onClick={() => setOpenExtentedView(false)} color="primary">
            {t('view.batch.samplesTable.close')}
          </Button>
        </DialogActions>
      </CustomDialog>
      {getRenderCmp()}
    </Fragment>
  )
})
export default QualityControlTable
