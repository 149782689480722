// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { forwardRef, Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Tooltip } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { fetchCleanNewMessages, fetchGetMessages } from '../../redux/message/actions'
import MessageEmpty from '@material-ui/icons/ChatBubble'
import MessageFull from '@material-ui/icons/Chat'
import IconButton from '@material-ui/core/IconButton'
import { fetchGetData } from '../../redux/test/actions'
import StorageIcon from '@material-ui/icons/Storage'
import clsx from 'clsx'
import { InfoTooltip, TransitionBottom } from './Style'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import { API_BO_URL, API_URL, DATABASE, PUBLIC_URL } from '../../utils/config'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import Grid from '@material-ui/core/Grid'
import Badge from '@material-ui/core/Badge'
import StarsIcon from '@material-ui/icons/Stars'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { displayPopupMessage, generateErrorPart } from './Message'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { t } from 'i18next'
import { receiveCurrentBatchLease } from '../../redux/batches/actions'
import { fetchConfigurations } from '../../redux/configuration/actions'

/**
 * Custom styles for the DatabaseInput component.
 * @param theme Material-UI theme object.
 */
const useStyles = makeStyles((theme) => ({
  newMessageIcon: {
    color: '#ffc800',
    position: 'absolute',
    top: -10,
    right: 13,
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  badgeDatabase: {
    '& .MuiBadge-badge': {
      right: 9,
      top: 10,
    },
  },
}))

/**
 * Component to handle database selection input.
 * Fetches and displays a list of databases, allowing users to choose one.
 */
const DatabaseInput = forwardRef((props, ref) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // State to manage the currently selected database.
  const [databaseSelected, setDatabaseSelected] = React.useState(DATABASE())
  const [databaseList, setDatabaseList] = React.useState([{ name: DATABASE() }])

  /**
   * Effect to fetch available databases when the component is visible.
   * Handles API call errors and updates the database list.
   */
  useEffect(
    function () {
      let filter = encodeURIComponent(
        JSON.stringify([
          {
            property: 'name',
            value: '\\.batch', // Filter for batch-related databases.
            filterType: 'string',
            operator: 'like',
          },
        ]),
      )
      if (props.visible) {
        let url = `${API_URL()}/bo_classes/page/?filter=${filter}`
        fetch(url, {
          method: 'GET',
          credentials: 'include',
        })
          .then((response) => {
            if (!response.ok) {
              return handleApiError(response)
            }
            return response.json()
          })
          .then((json) => {
            let dataBaseList = json.result.map(function (db) {
              return {
                name: db.name.split('.batch')[0],
              }
            })
            setDatabaseList(
              dataBaseList.sort(function (a, b) {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
              }),
            )
          })
          .catch((error) => handleFetchError(error, dispatch))
      }
    },
    [props.visible],
  )

  /**
   * Handles API errors by parsing the response and throwing an appropriate error.
   * @param response The API response object.
   * @returns {Promise<never>} Throws an error with details.
   */
  const handleApiError = (response) => {
    const statusText = response.statusText
    const status = response.status
    const url = response.url
    return response.text().then((errorMessage) => {
      const error = new Error(`${statusText} : ${errorMessage}`)
      if (response.headers.get('content-type') === 'application/json') {
        error.stack = JSON.stringify(
          JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
          null,
          2,
        )
      } else {
        error.stack = new Error().stack
      }
      error.statusText = statusText
      error.status = status
      error.url = url
      throw error
    })
  }

  /**
   * Displays a popup message in case of fetch error and prompts for login if needed.
   * @param error The error object.
   * @param dispatch Redux dispatch function.
   */
  const handleFetchError = (error, dispatch) => {
    if (error.status === 403) {
      dispatch(fetchChangeSettings('loginPopup', true))
    }
    displayPopupMessage(
      dispatch,
      'error',
      t('view.common.databaseInput.title'),
      <Fragment>
        <div>{t('view.common.databaseInput.error')}</div>
        {generateErrorPart(error)}
        <div>{t('view.common.databaseInput.contact')}</div>
      </Fragment>,
    )
  }

  /**
   * Handles the change of selected database from the dropdown.
   * @param event The change event object.
   */
  const handleDatabaseChanged = (event) => {
    const value = event.target.value
    setDatabaseSelected(value)
    dispatch(fetchConfigurations(value))
  }

  return (
    <FormControl
      required
      fullWidth
      variant="outlined"
      className={classes.formControl}
      error={
        databaseSelected === '' ||
        databaseSelected === null ||
        databaseSelected === undefined ||
        databaseList.map((db) => db.name).indexOf(databaseSelected) === -1
      }
    >
      <InputLabel>{t('view.common.databaseInput.database')}</InputLabel>
      <Select
        variant="outlined"
        onChange={handleDatabaseChanged}
        label={t('view.common.databaseInput.database')}
        value={databaseSelected}
        ref={ref}
      >
        {databaseList.map((db) => (
          <MenuItem key={db.name} value={db.name}>
            {db.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

/**
 * Higher-order component to wrap a component with router functionality.
 * @param Component The component to wrap.
 * @returns A new component with router integration.
 */
const withRouterForwardRef = (Component) => {
  const WithRouter = withRouter(({ forwardedRef, ...props }) => (
    <Component ref={forwardedRef} {...props} />
  ))

  return forwardRef((props, ref) => <WithRouter {...props} forwardedRef={ref} />)
}

// Export the DatabaseInput component wrapped with router and forward ref.
const forwardedRef = withRouterForwardRef(DatabaseInput)
export default forwardedRef
// export default DatabaseInput
