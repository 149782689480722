// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { find } from 'lodash'
/**
 * Retrieves a unitary analysis filtered by ID.
 * @param {Object} store - The Redux store.
 * @param {string} id - The ID of the unitary analysis.
 * @returns {Object} The unitary analysis object.
 */
export const getUnitaryAnalysisFilteredById = (store, id) =>
  find(store.unitaryAnalysesFiltered, { _id: id })

/**
 * Retrieves a unitary analysis compound by ID.
 * @param {Object} store - The Redux store.
 * @param {string} id - The ID of the unitary analysis.
 * @returns {Object} The unitary analysis object.
 */
export const getUnitaryAnalysisCompoundById = (store, id) =>
  find(store.unitaryAnalysesCompound, { _id: id })

/**
 * Retrieves a unitary analysis compound ISTD by ID from the compound view ISTD store.
 * @param {Object} store - The Redux store.
 * @param {string} id - The ID of the unitary analysis.
 * @returns {Object} The unitary analysis object.
 */
export const getUnitaryAnalysisCompoundISTDById = (store, id) =>
  find(store.allUnitaryAnalysesCompoundIstd, { _id: id })

/**
 * Retrieves a unitary analysis ISTD by ID from the analysis view ISTD store.
 * @param {Object} store - The Redux store.
 * @param {string} id - The ID of the unitary analysis.
 * @returns {Object} The unitary analysis object.
 */
export const getUnitaryAnalysisISTDById = (store, id) =>
  find(store.allUnitaryAnalysesIstd, { _id: id })

/**
 * Retrieves a unitary analysis ISTD by ID from the detail view ISTD store.
 * @param {Object} store - The Redux store.
 * @param {string} id - The ID of the unitary analysis.
 * @returns {Object} The unitary analysis object.
 */
export const getUnitaryAnalysisISTDFromDetailStore = (store, id) =>
  find(store.unitaryCompoundISTD, { _id: id })