// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { fetchChangeSettings } from '../../../redux/settings/actions'
import { withRouter } from 'react-router-dom'
import AIContainer from './AIContainer'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'
import clsx from 'clsx'
import { t } from 'i18next'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@mui/material/IconButton'
import { fetchClearMessages } from '../../../redux/assistant/actions'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import DeleteIcon from '@material-ui/icons/Delete'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const useStyles = makeStyles((theme) => ({
  customModal: {
    position: 'fixed',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
}))

const AIMenu = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { buttonClass, sizeAndPos, setSizeAndPos } = props

  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null)
  const openMenu = Boolean(anchorMenuEl)
  const handleClickMenu = (event) => {
    setAnchorMenuEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorMenuEl(null)
  }

  const openExportFct = () => {
    let sizeAndPos = localStorage.getItem('ASSISTANT_size_and_pos')
      ? JSON.parse(localStorage.getItem('ASSISTANT_size_and_pos'))
      : {
          width: 330,
          height: 580,
          x: window.innerWidth - 330 - 30,
          y: window.innerHeight - 580 - 10,
        }
    window.open(
      // '/?assistant',
      `${window.location.origin}${window.location.pathname}?assistant`,
      t('view.dialogs.assistant.title'),
      `width=${sizeAndPos.width},height=${sizeAndPos.height - 40},left=${sizeAndPos.x - 8},top=${
        sizeAndPos.y + 75
      }`,
    )
  }

  const centerFct = () => {
    if (setSizeAndPos) {
      setSizeAndPos({
        width: 330,
        height: 580,
        x: window.innerWidth - 330 - 30,
        y: window.innerHeight - 580 - 10,
      })
      localStorage.setItem(
        'ASSISTANT_size_and_pos',
        JSON.stringify({
          width: 330,
          height: 580,
          x: window.innerWidth - 330 - 30,
          y: window.innerHeight - 580 - 10,
        }),
      )
    }
  }

  const handleClear = () => {
    dispatch(fetchClearMessages())
  }

  return (
    <Fragment>
      <IconButton onClick={handleClickMenu} className={buttonClass} aria-label="more" size="small">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorMenuEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {setSizeAndPos && (
          <MenuItem
            onClick={() => {
              handleCloseMenu()
              openExportFct()
            }}
          >
            <ListItemIcon>
              <OpenInNewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('view.dialogs.assistant.external_window')}</ListItemText>
          </MenuItem>
        )}
        {sizeAndPos &&
          (sizeAndPos.x !== window.innerWidth - 330 - 30 ||
            sizeAndPos.y !== window.innerHeight - 580 - 10 ||
            sizeAndPos.width !== 330 ||
            sizeAndPos.height !== 580) && (
            <MenuItem
              onClick={() => {
                handleCloseMenu()
                centerFct()
              }}
            >
              <ListItemIcon>
                <MyLocationIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('view.dialogs.assistant.reinit_pos')}</ListItemText>
            </MenuItem>
          )}
        <MenuItem
          onClick={() => {
            handleCloseMenu()
            handleClear()
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('view.dialogs.assistant.clear')}</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  )
})
export default AIMenu
