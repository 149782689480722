// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import {
  ADD_TASK,
  FINISH_TASK,
  REMOVE_TASK,
  REQUEST_TASKS,
  REQUEST_POLLING_TASKS,
  RECEIVE_TASKS,
  CLEAR_TASK,
  RECEIVE_TASK,
  REQUEST_BATCH_TASKS,
  RECEIVE_BATCH_TASKS,
} from './actions'
import React from 'react'
import { API_URL } from '../../utils/config'

const initialState = {
  task: null,
  tasks: [],
  isFetchingBatchTasks: false,
  batchTasks: [],
  pollingTasks: [],
  totalTasks: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_TASKS:
      return {
        ...state,
        totalTasks: 0,
      }
    case RECEIVE_TASKS:
      return {
        ...state,
        tasks: action.gatewayTasks,
        totalTasks: action.totalTasks,
      }
    case REQUEST_POLLING_TASKS:
      return {
        ...state,
        pollingTasks: action.gatewayPollingTasks,
      }

    case ADD_TASK:
      state.tasks.push(action.task)
      state.pollingTasks.push(action.task)
      return {
        ...state,
      }

    case REMOVE_TASK:
      // state.tasks.remove(action.task)
      return {
        ...state,
        tasks: state.tasks,
        pollingTasks: state.pollingTasks,
      }

    case FINISH_TASK:
      let taskFinished = state.tasks.filter((task) => task.id === action.taskId)[0]
      if (taskFinished) {
        taskFinished.state = { value: action.status, message: action.message }
        taskFinished.percentage = 100
      }
      let pollingTaskFinished = state.pollingTasks.filter((task) => task.id === action.taskId)[0]
      if (pollingTaskFinished) {
        pollingTaskFinished.state = { value: action.status, message: action.message }
        pollingTaskFinished.percentage = 100
      }
      return {
        ...state,
      }
    case CLEAR_TASK:
      return {
        ...state,
        task: null,
      }
    case RECEIVE_TASK:
      return {
        ...state,
        task: action.gatewayTask,
      }
    case REQUEST_BATCH_TASKS:
      return {
        ...state,
        isFetchingBatchTasks: true,
      }
    case RECEIVE_BATCH_TASKS:
      return {
        ...state,
        batchTasks: action.batchTasks,
        isFetchingBatchTasks: false,
      }
    default:
      return state
  }
}
