// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect } from 'react'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { API_BO_URL, DATABASE, FLASK_URL, RELEASE_VERSION_PATH, SERVICE } from '../../utils/config'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone'
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone'
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone'
import ArchiveTwoToneIcon from '@material-ui/icons/ArchiveTwoTone'
import Tooltip from '@material-ui/core/Tooltip'
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'
import KoIcon from '@material-ui/icons/Clear'
import OkIcon from '@material-ui/icons/Done'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { t } from 'i18next'
import { receiveCurrentBatchLease } from '../../redux/batches/actions'
import { useWhyDidYouRenderTool } from '../common/whyDidYouRenderTool'

const useStyles = makeStyles((theme) => ({
  informationContainer: {
    paddingLeft: 10,
    '& .MuiGrid-item': {
      paddingTop: 15,
    },
  },
  auditButton: {
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
}))

export default function InformationDialog({ open, closeFct }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [version, setVersion] = React.useState('')

  // useWhyDidYouRenderTool(
  //   'ChromatoDetailScatter',
  //   {
  //     open,
  //     closeFct,
  //   },
  //   { version },
  // )

  useEffect(
    function () {
      if (open) {
        let url = `${FLASK_URL()}/version`
        fetch(url, {
          method: 'GET',
          credentials: 'include',
        })
          .then((response) => {
            if (!response.ok) {
              const statusText = response.statusText
              const status = response.status
              const url = response.url
              return response.text().then((errorMessage) => {
                const error = new Error(`${statusText} : ${errorMessage}`)
                if (response.headers.get('content-type') === 'application/json') {
                  error.stack = JSON.stringify(
                    JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                    null,
                    2,
                  )
                } else {
                  error.stack = new Error().stack
                }
                error.statusText = statusText
                error.status = status
                error.url = url
                throw error
              })
            }
            return response.text()
          })
          .then((version_rep) => {
            if (version_rep.indexOf('<!DOCTYPE html>') === -1) {
              setVersion(version_rep)
            } else {
              setVersion('unknown')
            }
          })
          .catch((error) => {
            if (error.status === 403) {
              dispatch(fetchChangeSettings('loginPopup', true))
            }
            displayPopupMessage(
              dispatch,
              'error',
              t('view.dialogs.information.flask_error.title'),
              <Fragment>
                <div>{t('view.dialogs.information.flask_error.msg1')}</div>
                {generateErrorPart(error)}
                <div>{t('view.dialogs.information.flask_error.msg2')}</div>
              </Fragment>,
            )
          })
      }
    },
    [open],
  )

  const handleClose = (event) => {
    closeFct(event)
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <Dialog
      open={open}
      onClose={(event) => handleClose(event)}
      TransitionComponent={TransitionTop}
      aria-labelledby="dialog-reset-title"
    >
      {/*<StyledDialogTitle>{localStorage.getItem('__config.json__APPLICATION_TITLE')}</StyledDialogTitle>*/}
      <StyledDialogTitle>
        {localStorage.getItem('__config.json__APPLICATION_SUBTITLE') === '' ? (
          <Typography className={classes.title} component="h1" variant="h6" color="inherit" noWrap>
            {localStorage.getItem('__config.json__APPLICATION_TITLE')}
          </Typography>
        ) : (
          <div className={classes.title}>
            <table>
              <thead>
                <tr>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      style={{ lineHeight: '20px' }}
                    >
                      {localStorage.getItem('__config.json__APPLICATION_TITLE')}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      style={{ fontSize: '0.5em', float: 'right', lineHeight: '10px' }}
                    >
                      {localStorage.getItem('__config.json__APPLICATION_SUBTITLE')}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </StyledDialogTitle>
      <DialogContent style={{ maxWidth: 520, overflow: 'hidden' }}>
        <Grid container spacing={1} className={classes.informationContainer}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={1} style={{ paddingTop: 12 }}>
              <FaceTwoToneIcon color="secondary" />
            </Grid>
            <Grid item xs={4}>
              {t('view.dialogs.information.name')}
            </Grid>
            <Grid item xs={7}>
              {localStorage.getItem('SESSION_user_name')}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} style={{ paddingTop: 0 }}>
            <Grid item xs={1} style={{ paddingTop: 5, marginBottom: 8 }}></Grid>
            <Grid item xs={4} style={{ paddingTop: 5, marginBottom: 8 }}>
              {t('view.dialogs.information.full_name')}
            </Grid>
            <Grid item xs={7} style={{ paddingTop: 5, marginBottom: 8 }}>
              {localStorage.getItem('SESSION_user_json')
                ? JSON.parse(localStorage.getItem('SESSION_user_json')).fullName
                : ''}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} style={{ paddingTop: 0 }}>
            <Grid item xs={1} style={{ paddingTop: 5, marginBottom: 8 }}></Grid>
            <Grid item xs={4} style={{ paddingTop: 5, marginBottom: 8 }}>
              {t('view.dialogs.information.admin')}
            </Grid>
            <Grid item xs={7} style={{ paddingTop: 2, color: '#696969' }}>
              {localStorage.getItem('SESSION_user_json') ? (
                JSON.parse(localStorage.getItem('SESSION_user_json')).admin ? (
                  <OkIcon />
                ) : (
                  <KoIcon />
                )
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} style={{ paddingTop: 0 }}>
            <Grid item xs={1} style={{ paddingTop: 5, marginBottom: 8 }}></Grid>
            <Grid item xs={4} style={{ paddingTop: 5, marginBottom: 8 }}>
              {t('view.dialogs.information.teams')}
            </Grid>
            <Grid item xs={7} style={{ paddingTop: 5, marginBottom: 8 }}>
              {localStorage.getItem('SESSION_user_json')
                ? JSON.parse(localStorage.getItem('SESSION_user_json')).teams.map((team, index) => (
                    <Chip key={index} size="small" style={{ marginRight: 5 }} label={team.name} />
                  ))
                : ''}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={1} style={{ paddingTop: 12 }}>
              <DnsTwoToneIcon color="secondary" />
            </Grid>
            <Grid item xs={4}>
              {t('view.dialogs.information.database')}
            </Grid>
            <Grid item xs={7}>
              {DATABASE()}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={1} style={{ paddingTop: 12 }}>
              <SettingsTwoToneIcon color="secondary" />
            </Grid>
            <Grid item xs={4}>
              {t('view.dialogs.information.python_service')}
            </Grid>
            <Grid item xs={7}>
              {SERVICE()}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={1} style={{ paddingTop: 12 }}>
              <ArchiveTwoToneIcon color="secondary" />
            </Grid>
            <Grid item xs={4}>
              {t('view.dialogs.information.version')}
            </Grid>
            <Grid item xs={2}>
              {version}
            </Grid>
            <Grid item xs={5} style={{ paddingTop: 12 }}>
              <Tooltip
                arrow
                title={
                  <div style={{ fontSize: 12, padding: 5 }}>
                    {t('view.dialogs.information.open_release')}
                  </div>
                }
              >
                <IconButton
                  color="primary"
                  className={classes.auditButton}
                  aria-label="release"
                  onClick={() => openInNewTab(RELEASE_VERSION_PATH())}
                >
                  <HelpTwoToneIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={(event) => handleClose(event)} color="primary">
          {t('view.dialogs.information.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
