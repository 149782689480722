// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { PIN_UNPIN_DRAWER } from './actions'
import React from 'react'

const initialState = {
  pin: localStorage.getItem('NAVIGATION_pin_panel')
    ? localStorage.getItem('NAVIGATION_pin_panel') === 'true'
    : false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PIN_UNPIN_DRAWER:
      return {
        ...state,
        pin: action.pin,
      }
    default:
      return state
  }
}
