// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { GET_SETTINGS, CHANGE_SETTING } from './actions' // Import actions to manage settings
import { isClippyActivated } from '../../utils/utilities' // Import utility function to check if Clippy is activated

// Initial state of the application settings
const initialState = {
  configurationDatabase: [''], // List of available databases. If not empty, the user must choose a database to run the application.
  configurationProfile: false, // Flag for configuration profile
  configurationService: false, // Flag for configuration service
  loginPopup: false, // Flag to manage the login popup visibility
  version: null, // Application version
  clickUpload: false, // Flag to track if upload was triggered
  taskId: null, // ID of the current task
  uploadOpenCompress: false, // Flag to manage the compression upload state
  uploadSendMessage: null, // Message to be sent during upload
  uploadRemainingTime: 0, // Time remaining for the upload
  uploadGlobalProgress: 0, // Overall progress of the upload
  uploadCustomMessage: null, // Custom message for upload status
  uploadAcceptedFiles: [], // List of accepted files for upload
  uploadIsDragActive: false, // Flag to track if drag-and-drop upload is active
  configJsonLoaded: false, // Flag to track if the configuration JSON is loaded
  dateTimeDelta: 0, // Time difference for date and time
  wikiCODHelper: '', // Helper URL for COD (Chromatography on Demand)
  wikiCODDoc: null, // Document for COD Wiki
  dataBaseLoaded: false, // Flag to check if the database is loaded
  helperPopup: false, // Flag to show or hide helper popup
  wikiPopup: false, // Flag to show or hide wiki popup
  currentBatchSelected: null, // Current batch selected in the application
  openConfirmReceiveAllBatch: false, // Flag to confirm receiving all batches
  openConfirmUpdateBatch: false, // Flag to confirm batch update
  openCreateTrainingBatch: false, // Flag to create training batch
  displayAccountingDialog: false, // Flag to show or hide accounting dialog
  displayScreeningServiceDialog: false, // Flag to show or hide screening service dialog
  analysisToRename: null, // Analysis that needs to be renamed
  logsPopup: false, // Flag to show or hide logs popup
  servicePopup: false, // Flag to show or hide service popup
  assistantPopup: false, // Flag to show or hide assistant popup
  assistantType: isClippyActivated() ? 'clippy' : 'default', // Set assistant type based on whether Clippy is activated
  displayMethodManagerDialog: false, // Flag to show or hide method manager dialog
}

// Reducer function to handle the settings state changes based on dispatched actions
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS:
      // Return the current state when settings are fetched
      return {
        ...state,
      }
    case CHANGE_SETTING:
      // Only update the setting if its value has changed
      if (state[action.setting] !== action.value) {
        return {
          ...state,
          [action.setting]: action.value, // Update the specific setting
        }
      } else {
        return {
          ...state, // Return current state if no change
        }
      }
    default:
      return state // Return the current state by default
  }
}
