// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import {
  REQUEST_ANALYSES,
  RECEIVE_ANALYSES,
  AUTHENTICATION_FAILURE,
  REQUEST_SEARCH_ANALYSES,
  RECEIVE_SEARCH_ANALYSES,
  REQUEST_UPDATE_ANALYSE,
  RECEIVE_UPDATE_ANALYSE,
  RECEIVE_REDUCE_ANALYSES,
  RECEIVE_UPDATE_ANALYSES,
  RECEIVE_UPDATE_ANALYSIS_NOTE,
  RECEIVE_UPDATE_ANALYSIS_TAG,
  RECEIVE_CREATE_SUBBATCH_REDUCE_ANALYSES,
  RECEIVE_MOVE_SUBBATCH_REDUCE_ANALYSES,
  REQUEST_ANALYSES_SENT_TO_LIMS,
  RECEIVE_ANALYSES_SENT_TO_LIMS,
  REQUEST_CHANGE_ANALYSES_TYPE,
  REQUEST_UNDO_ANALYSES_TYPE,
  RECEIVE_UPDATE_ANALYSES_FORMULA,
  RECEIVE_COMPUTE_AI_ANALYSE,
  RECEIVE_RENAME_ANALYSE,
  RECEIVE_DISABLE_ANALYSE,
} from './actions'
import { rebuildAnalyses } from '../../utils/rebuilders'
import { AUTHENTICATION_ERROR, DEFAULT_ERROR, NOT_FONDED, PERMISSION_ERROR } from '../tools'

const initialState = {
  items: [],
  isFetching: false,
  batchId: null,
  error: false,
  keyword: null,
  isSearchingAnalyses: false,
  searchedAnalyses: [],
  searchedBatchId: null,
  isFetchingAnalysesSentToLims: false,
  analysesSentToLims: null,
  analysesNotSentToLims: null,
  analysesSearchedLastDateRequest: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_ANALYSES:
      return {
        ...state,
        isFetching: true,
        batchId: action.batchId,
        error: false,
      }
    case RECEIVE_ANALYSES:
      return {
        ...state,
        isFetching: false,
        error: false,
        items: action.analyses.map((analyse) => rebuildAnalyses(analyse)),
      }

    case REQUEST_SEARCH_ANALYSES:
      return {
        ...state,
        isSearchingAnalyses: true,
        keyword: action.keyword,
        error: false,
        analysesSearchedLastDateRequest: action.dateLock,
      }
    case RECEIVE_SEARCH_ANALYSES:
      // Avoid displaying an old request after a more recent one which was faster.
      if (action.dateLock !== state.analysesSearchedLastDateRequest) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          isSearchingAnalyses: false,
          error: false,
          searchedAnalyses: action.searchedAnalyses,
        }
      }

    case REQUEST_UPDATE_ANALYSE:
      return {
        ...state,
        isSearchingAnalyses: true,
        error: false,
      }
    case RECEIVE_UPDATE_ANALYSE:
      const analyseIds = action.objectsUpdated.analyses.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isSearchingAnalyses: false,
        error: false,
        items: state.items.map(function (analyse) {
          if (analyseIds[analyse._id]) {
            return analyseIds[analyse._id]
          }
          return analyse
        }),
      }
    case RECEIVE_CREATE_SUBBATCH_REDUCE_ANALYSES:
      // in case of the current batch is the same where the analyses should be removed
      if (action.searchedBatchId === state.batchId) {
        const analysisId = action.analysesSelected.map((as) => as._id)
        return {
          ...state,
          isSearchingAnalyses: false,
          error: false,
          items: state.items.filter((analyse) => analysisId.indexOf(analyse._id) === -1),
        }
      }
      return {
        ...state,
        isSearchingAnalyses: false,
        error: false,
        // items: state.items.filter((analyse) => !action.analysesId[analyse._id]),
      }
    case RECEIVE_MOVE_SUBBATCH_REDUCE_ANALYSES:
      // in case of the current batch is the same where the analyses should be removed
      if (action.searchedBatchId === state.batchId && action.option === 'move') {
        const analysisId = action.analysesSelected.map((as) => as._id)
        return {
          ...state,
          isSearchingAnalyses: false,
          error: false,
          items: state.items.filter((analyse) => analysisId.indexOf(analyse._id) === -1),
        }
      }
      // Update analyses store to add new analyses in destination batch
      if (action.destBatchId === state.batchId) {
        action.objectsUpdated.analyses.forEach((obj) => state.items.push(rebuildAnalyses(obj)))
        return {
          ...state,
          isSearchingAnalyses: false,
          error: false,
        }
      }
      return {
        ...state,
        isSearchingAnalyses: false,
        error: false,
        // items: state.items.filter((analyse) => !action.analysesId[analyse._id]),
      }

    case RECEIVE_REDUCE_ANALYSES:
      let removedAnalyseIds = action.analysesSelected.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      if (action.option === 'copy') {
        removedAnalyseIds = {}
      }
      return {
        ...state,
        isSearchingAnalyses: false,
        error: false,
        items: state.items.filter((analyse) => !removedAnalyseIds[analyse._id]),
      }

    case RECEIVE_UPDATE_ANALYSES:
      const removedAnalysesMap = action.analysesSelected.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      let analyseItems = state.items.filter((analyse) => !removedAnalysesMap[analyse._id])
      return {
        ...state,
        isSearchingAnalyses: false,
        error: false,
        items: [...analyseItems, ...action.objectsUpdated.analyses],
      }

    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSearchingAnalyses: false,
        error: true,
      }
    case RECEIVE_UPDATE_ANALYSIS_NOTE:
      return {
        ...state,
        isFetching: false,
        items: state.items.map(function (analysis) {
          if (action.objectsUpdated._id === analysis._id) {
            return action.objectsUpdated
          }
          return analysis
        }),
      }
    case RECEIVE_UPDATE_ANALYSIS_TAG:
      return {
        ...state,
        isFetching: false,
        items: state.items.map(function (analysis) {
          if (action.objectsUpdated._id === analysis._id) {
            return action.objectsUpdated
          }
          return analysis
        }),
      }
    case REQUEST_ANALYSES_SENT_TO_LIMS:
      return {
        ...state,
        isFetchingAnalysesSentToLims: true,
        error: false,
      }
    case RECEIVE_ANALYSES_SENT_TO_LIMS:
      return {
        ...state,
        error: false,
        isFetchingAnalysesSentToLims: false,
        analysesSentToLims: Object.keys(action.analysesSentToLims),
        analysesNotSentToLims: Object.keys(action.analysesNotSentToLims),
      }
    case REQUEST_CHANGE_ANALYSES_TYPE:
      const objectsIds = action.objectsToChangeType.map((otct) => otct._id)
      return {
        ...state,
        items: state.items.map((item) => {
          if (objectsIds.indexOf(item._id) !== -1) {
            return { ...item, content: { ...item.content, inProgress: true } }
          } else {
            return item
          }
        }),
      }
    case REQUEST_UNDO_ANALYSES_TYPE:
      const objectsUndoIds = action.objectsToChangeType.map((otct) => otct._id)
      return {
        ...state,
        items: state.items.map((item) => {
          if (objectsUndoIds.indexOf(item._id) !== -1) {
            delete item.content.inProgress
            // return { ...item, content: { ...item.content, inProgress: true } }
            // } else {
            //   return item
          }
          return item
        }),
      }
    case RECEIVE_UPDATE_ANALYSES_FORMULA:
      const analysesIds = action.objectsUpdated.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        items: state.items.map((item) => {
          if (analysesIds[item._id]) {
            return analysesIds[item._id]
          }
          return item
        }),
      }
    case RECEIVE_COMPUTE_AI_ANALYSE:
      const ids = action.objectsUpdated.analyses.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isSearchingAnalyses: false,
        error: false,
        items: state.items.map(function (analyse) {
          if (ids[analyse._id]) {
            return ids[analyse._id]
          }
          return analyse
        }),
      }
    case RECEIVE_RENAME_ANALYSE:
      return {
        ...state,
        items: state.items.map(function (analyse) {
          if (analyse._id === action.objectUpdated._id) {
            return { ...analyse, name: action.objectUpdated.name }
          }
          return analyse
        }),
      }
    case RECEIVE_DISABLE_ANALYSE:
      return {
        ...state,
        items: state.items.map(function (analyse) {
          if (analyse._id === action.objectUpdated._id) {
            return {
              ...analyse,
              content: {
                ...analyse.content,
                disabled: action.objectUpdated.content.disabled,
              },
            }
          }
          return analyse
        }),
      }
    case PERMISSION_ERROR:
    case NOT_FONDED:
    case AUTHENTICATION_ERROR:
    case DEFAULT_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}
