// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Profiler, useEffect, useState } from 'react'
import { isExecutionTimeActive } from '../utils/utilities'

const ProfilerContainer = (props) => {
  const { children, name } = props

  const [cumulatedTime, setCumulatedTime] = useState(0)
  const [displayedStartMessage, setDisplayedStartMessage] = useState(false)

  function formatTime(time) {
    if (time < 1000) {
      return `${time.toFixed(2)}ms`
    } else if (time < 60000) {
      return `${(time / 1000).toFixed(2)}s`
    } else {
      return `${(time / 60000).toFixed(2)}min`
    }
  }

  useEffect(() => {
    let intervalId

    if (isExecutionTimeActive()) {
      if (!displayedStartMessage) {
        setDisplayedStartMessage(true)
        console.info(`${String.fromCharCode(parseInt('231a', 16))} Profiler: Start to render...`)
      }
      intervalId = setInterval(() => {
        if (cumulatedTime > 0) {
          console.info(
            `${String.fromCharCode(parseInt('231a', 16))} Profiler: ${name} took ${formatTime(
              cumulatedTime,
            )} to render.`,
          )
          setCumulatedTime(0)
          setDisplayedStartMessage(false)
        }
      }, 3000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [cumulatedTime])

  return isExecutionTimeActive() ? (
    <Profiler
      id={name}
      onRender={(id, phase, actualDuration, baseDuration, startTime, commitTime) => {
        setCumulatedTime((oldValue) => oldValue + actualDuration)
      }}
    >
      {children}
    </Profiler>
  ) : (
    children
  )
}
export default ProfilerContainer
