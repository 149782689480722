// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import { REQUEST_COD_DRIVE_CONFIGS, RECEIVE_COD_DRIVE_CONFIGS } from './actions'
import React from 'react'

const initialState = {
  codDriveConfig: [],
  isFetchingCodDriveConfig: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_COD_DRIVE_CONFIGS:
      return {
        ...state,
        isFetchingCodDriveConfig: true,
      }

    case RECEIVE_COD_DRIVE_CONFIGS:
      return {
        ...state,
        codDriveConfig: action.codDriveConfig,
        isFetchingCodDriveConfig: false,
      }
    default:
      return state
  }
}
