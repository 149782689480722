// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { t } from 'i18next'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import React, { Fragment } from 'react'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import PersonIcon from '@mui/icons-material/Person'
import { Molecule, Nothing, Relance } from './Style'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined'

/*******************************************************/
/*                     Flags                           */
/*******************************************************/
function FlagsInputValue(props) {
  const { item, applyValue } = props

  const handleFilterChange = (event) => {
    applyValue({
      ...item,
      value: event.target.value,
    })
  }

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
      <InputLabel>Value</InputLabel>
      <Select value={item.value} onChange={handleFilterChange} label="Value">
        <MenuItem value={null}>
          <em style={{ marginLeft: 33 }}>{t('view.analysis.filterButton.none')}</em>
        </MenuItem>
        <MenuItem value={'info'}>
          <InfoIcon style={{ top: 3, position: 'absolute', color: '#547fa8' }} />
          <span style={{ marginLeft: 33 }}>{t('view.dialogs.logs.filters.info')}</span>
        </MenuItem>
        <MenuItem value={'action'}>
          <LiveHelpOutlinedIcon style={{ top: 3, position: 'absolute', color: '#547fa8' }} />
          <span style={{ marginLeft: 33 }}>{t('view.dialogs.logs.filters.action')}</span>
        </MenuItem>
        <MenuItem value={'warning'}>
          <WarningIcon style={{ top: 3, position: 'absolute', color: '#e68d09' }} />
          <span style={{ marginLeft: 33 }}>{t('view.dialogs.logs.filters.warning')}</span>
        </MenuItem>
        <MenuItem value={'error'}>
          <ErrorIcon style={{ top: 3, position: 'absolute', color: '#c70505' }} />
          <span style={{ marginLeft: 33 }}>{t('view.dialogs.logs.filters.error')}</span>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export const flagsFilterOperators = [
  {
    label: 'is',
    value: 'is',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return params.formattedValue === filterItem.value
      }
    },
    InputComponent: FlagsInputValue,
  },
  {
    label: 'not',
    value: 'not',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return params.formattedValue !== filterItem.value
      }
    },
    InputComponent: FlagsInputValue,
  },
]

/*******************************************************/
/*                     Peak                            */
/*******************************************************/
function PeakInputValue(props) {
  const { item, applyValue } = props

  const handleFilterChange = (event) => {
    applyValue({
      ...item,
      value: event.target.value,
    })
  }

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
      <InputLabel>Value</InputLabel>
      <Select value={item.value} onChange={handleFilterChange} label="Value">
        <MenuItem value={null}>
          <em style={{ marginLeft: 33 }}>{t('view.analysis.filterButton.none')}</em>
        </MenuItem>
        <MenuItem value={'auto'}>
          <AutoGraphIcon style={{ top: 3, position: 'absolute' }} />
          <span style={{ marginLeft: 33 }}>{t('view.analysis.filterButton.auto')}</span>
        </MenuItem>
        <MenuItem value={'manual'}>
          <PersonIcon style={{ top: 3, position: 'absolute' }} />
          <span style={{ marginLeft: 33 }}>{t('view.analysis.filterButton.user')}</span>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export const peaksFilterOperators = [
  {
    label: 'is',
    value: 'is',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return params.formattedValue === filterItem.value
      }
    },
    InputComponent: PeakInputValue,
  },
  {
    label: 'not',
    value: 'not',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return params.formattedValue !== filterItem.value
      }
    },
    InputComponent: PeakInputValue,
  },
]

/*******************************************************/
/*                     Validation                      */
/*******************************************************/
function ValidationInputValue(props) {
  const { item, applyValue } = props

  const handleFilterChange = (event) => {
    applyValue({
      ...item,
      value: event.target.value,
    })
  }

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
      <InputLabel>Value</InputLabel>
      <Select value={item.value} onChange={handleFilterChange} label="Value">
        <MenuItem value={null}>
          <em style={{ marginLeft: 78 }}>{t('view.analysis.filterButton.none')}</em>
        </MenuItem>
        <MenuItem value={1}>
          <span style={{ top: 3, position: 'absolute', color: '#00000091' }}>
            <Molecule /> / <CheckIcon />
          </span>
          <span style={{ marginLeft: 78 }}>{t('view.analysis.filterButton.detected_ok')}</span>
        </MenuItem>
        <MenuItem value={2}>
          <span style={{ top: 3, position: 'absolute', color: '#00000091' }}>
            <Nothing /> / <ClearIcon />
          </span>
          <span style={{ marginLeft: 78 }}>{t('view.analysis.filterButton.not_detected_ko')}</span>
        </MenuItem>
        <MenuItem value={3}>
          <Relance style={{ top: 6, position: 'absolute', color: '#00000091' }} />
          <span style={{ marginLeft: 78 }}>{t('view.analysis.filterButton.relaunch')}</span>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export const validationFilterOperators = [
  {
    label: 'is',
    value: 'is',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return params.value.toString() === filterItem.value.toString()
      }
    },
    InputComponent: ValidationInputValue,
  },
  {
    label: 'not',
    value: 'not',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return params.formattedValue !== filterItem.value
      }
    },
    InputComponent: ValidationInputValue,
  },
]

/*******************************************************/
/*                     Type                            */
/*******************************************************/
function TypeInputValue(props) {
  const { item, applyValue } = props

  const handleFilterChange = (event) => {
    applyValue({
      ...item,
      value: event.target.value,
    })
  }

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
      <InputLabel>Value</InputLabel>
      <Select value={item.value} onChange={handleFilterChange} label="Value">
        <MenuItem value={null}>
          <em>{t('view.analysis.filterButton.none')}</em>
        </MenuItem>
        <MenuItem value={'Target'}>
          <span>Target</span>
        </MenuItem>
        <MenuItem value={'ISTD'}>
          <span>ISTD</span>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export const typeFilterOperators = [
  {
    label: 'is',
    value: 'is',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return params.formattedValue === filterItem.value
      }
    },
    InputComponent: TypeInputValue,
    InputComponentProps: { type: 'singleSelect' },
  },
  {
    label: 'not',
    value: 'not',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return params.formattedValue !== filterItem.value
      }
    },
    InputComponent: TypeInputValue,
    InputComponentProps: { type: 'singleSelect' },
  },
]
