// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment, useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider'
import { withRouter } from 'react-router-dom'
import Loading from '../Loading'
import { AutoSizer, MultiGrid } from 'react-virtualized'
import ChromatoGraph from './chromato/ChromatoGraph'
import { fetchUpdateDetailInfo } from '../../redux/detail/actions'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Nothing } from './Style'
import { LAYOUT_CONFIG_VALUE } from '../../utils/config'
import SelectAllIcon from '@material-ui/icons/SelectAll'
import clsx from 'clsx'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { canIModifyTheCalibration } from '../../utils/utilities'
import { fetchChangeSettings } from '../../redux/settings/actions'

const styles = (theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
    marginTop: -12,
  },
  chromatoContainer: {
    overflow: 'hidden',
    height: '100%',
    overflowY: 'hidden',
    '&:hover': {
      overflowY: 'auto',
    },
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  chromatoFullWidth: {
    '& #myAnalyseChromatoGrid > div': {
      width: '100% !important',
      maxWidth: '100% !important',
    },
    '& #myAnalyseChromatoGrid > div > .unitaryAnalyse': {
      width: '100% !important',
    },
  },
  paperContainer: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    height: '100%',
  },
  chromatoSlider: {
    width: 50,
  },
  sliderContainer: {
    width: 50,
    paddingTop: '30px !important',
    paddingBottom: '30px !important',
    paddingLeft: '25px !important',
  },
  multiGridContainer: {
    height: '100%',
    width: 'calc(100% - 50px)',
    paddingRight: '20px !important',
  },
  container: {
    height: '100%',
  },
  lineLabel: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  emptyChroma: {
    width: '100%',
    height: '100%',
  },
  noItem: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    top: 'calc(50% - 24px)',
    position: 'relative',
    fontWeight: 400,
    fontSize: '1.5rem',
  },
})

export default withRouter(
  connect(null, null, null, { forwardRef: true })(
    withStyles(styles)(
      class Grid_MultiGrid extends React.Component {
        constructor(props) {
          super(props)
          this.refsChromato = {}

          this.state = {
            item: null,
            unitaryanalysesid: null,
            error: null,
            isLoaded: false,
            items: [],
            analysis: null,
            batch: null,
            chromatoSize: localStorage.getItem('CHROMATO_size')
              ? parseFloat(localStorage.getItem('CHROMATO_size'))
              : 200,
            selected: null,
            activePan: true,
            scrollToItem: 0,
            activeScroll: false,
            selectedRows: [],
          }
          this.getSelectedChannel = this.getSelectedChannel.bind(this)
        }

        // Update the selection from the table component selection update
        customSelection(selection) {
          // setSelectedRows(selection)
          // if (propOnSelect) propOnSelect(selection)
          this.setState({ selectedRows: selection })
        }

        scrollTo(id) {
          const { data } = this.props
          const { chromatoSize } = this.state
          const isIstdSync = localStorage.getItem('SETTINGS_chromato_display') === 'istd_combi'

          const grid = document.getElementById('myAnalyseChromatoGrid')
          const index = data.map((uaf) => uaf._id).indexOf(id)
          if (grid && index !== -1) {
            let rowIndex = 0
            if (isIstdSync) {
              rowIndex = index
            } else {
              rowIndex =
                Math.ceil(
                  (index + 1) / Math.floor(grid.offsetWidth / Math.ceil(chromatoSize / 0.8)),
                ) - 1
            }
            this.setState({ selected: id, scrollToItem: rowIndex })
          }
        }

        setGridItemSelectedForColor(id) {
          this.setState({ selected: id })
        }

        updateCharts() {
          if (localStorage.getItem('SETTINGS_chromato_display') === 'istd_combi') {
            if (document.getElementById('myAnalyseChromatoGridContainer')) {
              const newSize =
                (document.getElementById('myAnalyseChromatoGridContainer').offsetHeight - 15) / 2
              if (this.state.chromatoSize !== newSize) {
                // this.updateCharts()
                this.setState({
                  chromatoSize: newSize,
                })
                if (this.multiGridRef && this.multiGridRef.recomputeGridSize) {
                  this.multiGridRef.recomputeGridSize()
                }
              }
            }
            // if (document.getElementById('myAnalyseChromatoGrid') && !this.state.activeScroll) {
            //   this.setState({
            //     activeScroll: true,
            //   })
            //   const element = document.getElementById('myAnalyseChromatoGrid')
            //   element.addEventListener('wheel', (event) => {
            //     event.preventDefault()
            //
            //     element.scrollBy({
            //       left: event.deltaY < 0 ? -70 : 70,
            //     })
            //   })
            // }
          }
          this.addHorizontalScrollListener()
        }

        componentDidUpdate() {
          this.updateCharts()
        }

        handleDisplayDetail = (event, item, collection) => {
          const { dispatch } = this.props
          event.stopPropagation()
          event.preventDefault()
          dispatch(fetchUpdateDetailInfo(item, 0, false, event.currentTarget, collection))
          dispatch(fetchChangeSettings('displayChromatoDetailDialog', true))
        }

        getSelectedChannel(uaId) {
          const { data, istd } = this.props
          const selCh = data.concat(istd).filter((ua) => ua._id === uaId)[0]
          return selCh ? selCh.selectedChannel : null
        }

        addHorizontalScrollListener() {
          const isIstdSync = localStorage.getItem('SETTINGS_chromato_display') === 'istd_combi'
          if (isIstdSync && document.getElementById('myAnalyseChromatoGrid')) {
            const element = document.getElementById('myAnalyseChromatoGrid')
            element.addEventListener('wheel', (event) => {
              if (!event.ctrlKey && !event.altKey) {
                event.preventDefault()
                element.scrollBy({
                  left:
                    (event.deltaY < 0
                      ? -1 * (this.state.chromatoSize / 0.8)
                      : this.state.chromatoSize / 0.8) / 4,
                })
              }
            })
          }
        }

        render() {
          const {
            classes,
            dispatch,
            onChromatoClick,
            data,
            isFetchingData,
            collection,
            title,
            onChangeChannel,
            istd,
            isFetchingIstd,
            selection,
            tableGridRef,
            batch,
            featuresConfig,
            aiAssistantActivated,
          } = this.props

          const me = this
          const { selected, scrollToItem, selectedRows } = this.state
          let { chromatoSize } = this.state
          const layout = LAYOUT_CONFIG_VALUE(collection === 'analyse' ? 'analysis' : 'compound')

          const isIstdSync = localStorage.getItem('SETTINGS_chromato_display') === 'istd_combi'

          if (layout === 'one' && data.length === 0) {
            return (
              <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.noItem} variant="h5" noWrap>
                  {t('view.common.grid_multigrid.no_active')}
                  <Box>
                    <SelectAllIcon style={{ fontSize: 40 }} />
                  </Box>
                </Typography>
              </Paper>
            )
          } else if (isFetchingData || (isIstdSync && isFetchingIstd)) {
            return <Loading mode="miniCircle" />
          }

          const onSelect = (event, checked, row) => {
            const selectedRows = me.state.selectedRows
            const shiftKey = event.nativeEvent.shiftKey
            let rows = []
            if (checked) {
              if (shiftKey) {
                let originMultiSelection = 0
                selectedRows
                  .sort(function (a, b) {
                    return b - a
                  })
                  .forEach(function (rowIndex) {
                    if (rowIndex < row - 1 && originMultiSelection === 0) {
                      originMultiSelection = rowIndex
                    }
                  })
                for (let i = originMultiSelection + 1; i < row - 1; i++) {
                  rows.push(i)
                }
              }
              rows = [...rows, ...selectedRows, row - 1]
              me.setState({ selectedRows: rows })
              // Update the table selection
              tableGridRef.current.customSelection(rows, true)
            } else {
              const index = selectedRows.indexOf(row - 1)
              selectedRows.splice(index, 1)
              rows = selectedRows
              me.setState({ selectedRows: rows })
              // Update the table selection
              tableGridRef.current.customSelection(rows, true)
            }
          }

          const chromatoSizeChange = (id, value) => {
            this.setState({ chromatoSize: value })
            // this.multiGridRef.forceUpdate()
            // this.multiGridRef.forceUpdateGrids()
            if (this.multiGridRef && this.multiGridRef.recomputeGridSize) {
              this.multiGridRef.recomputeGridSize()
            }
            this.addHorizontalScrollListener()
          }
          const chromatoSizeSave = (id, value) => {
            localStorage.setItem('CHROMATO_size', value)
          }

          function canEditCalib(item) {
            // const { batch, featuresConfig } = this.props
            const edit_calibration = featuresConfig?.content?.edit_calibration
            const param_exist = typeof edit_calibration === 'boolean'
            const getRights = canIModifyTheCalibration(batch)
            // In this case we want to know if the item can be edited or not
            if (item) {
              if (item.sampleType === 'Standard') {
                return param_exist ? edit_calibration && getRights : true
              } else {
                return true
              }
            } else {
              // Here it's to know if the edition is active or not
              return param_exist ? edit_calibration && getRights : true
            }
          }

          const _cellRenderer = function ({ columnIndex, key, rowIndex, style }) {
            const index =
              rowIndex *
                Math.floor((me.multiGridRef._lastRenderedWidth - 10) / (chromatoSize / 0.8)) +
              columnIndex

            const item = data[index]
            if (item) {
              return (
                <ErrorBoundaryGuard key={`chromato_${key}`}>
                  <ChromatoGraph
                    aiAssistantActivated={aiAssistantActivated}
                    canEdit={canEditCalib(item)}
                    selection={selection}
                    index={index + 1}
                    checkSelected={this.state.selectedRows.includes(index)}
                    onSelect={onSelect}
                    getSelectedChannel={this.getSelectedChannel}
                    key={`chromato_${key}`}
                    uidKey={`chromato_${key}`}
                    unitaryAnalyse={item}
                    selected={item._id === selected}
                    style={style}
                    chromatoSize={chromatoSize}
                    onClick={onChromatoClick}
                    onDoubleClick={(event) => this.handleDisplayDetail(event, item, collection)}
                    dispatch={dispatch}
                    title={title}
                    collection={collection}
                    onChangeChannel={onChangeChannel}
                  />
                </ErrorBoundaryGuard>
              )
            } else return <Fragment key={`chromato_${key}`}></Fragment>
          }.bind(this)

          const _cellRendererIstd = function ({ columnIndex, key, rowIndex, style }) {
            let item = null
            if (rowIndex === 0) {
              //Molecule row
              item = data[columnIndex]
            } else if (istd) {
              //Istd row
              if (collection === 'compound') {
                item = istd.filter((i) => i.analyseId === data[columnIndex].analyseId)[0]
              } else {
                item = istd.filter((i) => i.istdEvent === data[columnIndex].istdEvent)[0]
              }
            }

            if (rowIndex === 1 && data[columnIndex].type === 'ISTD') {
              // Do not display a chromato on the ISTD line if the Compound line is already an iSTD
              return (
                <div key={`chromato_${key}`} style={style}>
                  <div className={classes.emptyChroma}>
                    <Typography className={classes.noItem} variant="h6" noWrap>
                      {t('view.common.grid_multigrid.no_graph')}
                      <Box>
                        <Nothing style={{ width: 35, height: 35 }} />
                      </Box>
                    </Typography>
                  </div>
                </div>
                // <ChromatoGraphEmpty
                //   uidKey={`chromato_${key}`}
                //   unitaryAnalyse={item}
                //   selected={item._id === selected}
                //   style={style}
                //   onDoubleClick={(event) => this.handleDisplayDetail(event, item, collection)}
                // />
              )
            } else if (item) {
              return (
                <ErrorBoundaryGuard key={`chromato_${key}`}>
                  <ChromatoGraph
                    canEdit={canEditCalib(item)}
                    selection={rowIndex === 0 ? selection : false} // Active the selection only for the first row, not for ISTD
                    index={columnIndex + 1}
                    checkSelected={this.state.selectedRows.includes(columnIndex)}
                    onSelect={onSelect}
                    getSelectedChannel={this.getSelectedChannel}
                    key={`chromato_${key}`}
                    uidKey={`chromato_${key}`}
                    unitaryAnalyse={item}
                    selected={item._id === selected}
                    style={style}
                    chromatoSize={chromatoSize}
                    onClick={layout === 'one' && rowIndex === 1 ? function () {} : onChromatoClick}
                    onDoubleClick={(event) => this.handleDisplayDetail(event, item, collection)}
                    dispatch={dispatch}
                    title={title}
                    collection={collection}
                    onChangeChannel={onChangeChannel}
                  />
                </ErrorBoundaryGuard>
              )
            } else return <Fragment key={`chromato_${key}`}></Fragment>
          }.bind(this)

          var ret = (
            <Paper
              elevation={0}
              className={classes.paperContainer}
              style={{ marginTop: layout === 'one' && !isIstdSync ? -12 : 0 }}
            >
              {layout === 'one' && !isIstdSync ? (
                <ChromatoGraph
                  bypassLockChromatoEdition
                  canEdit={canEditCalib(data[0])}
                  getSelectedChannel={this.getSelectedChannel}
                  key={`chromato_0`}
                  uidKey={`chromato_0`}
                  unitaryAnalyse={data[0]}
                  selected={false}
                  style={{
                    height: 'calc(100% - 40px)',
                    width: 'calc(100% - 40px)',
                    margin: 10,
                    position: 'absolute',
                  }}
                  chromatoSize={chromatoSize}
                  onClick={() => () => true}
                  onDoubleClick={(event) => this.handleDisplayDetail(event, data[0], collection)}
                  dispatch={dispatch}
                  title={title}
                  collection={collection}
                  onChangeChannel={onChangeChannel}
                />
              ) : (
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="space-between"
                  className={classes.container}
                >
                  {isIstdSync ? (
                    // <Grid item className={classes.sliderContainer}>
                    <Grid
                      className={classes.sliderContainer}
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        style={{ top: 'calc(25% - 52px)', position: 'relative' }}
                        className={classes.lineLabel}
                        variant="h6"
                      >
                        {t('view.common.grid_multigrid.compound')}
                      </Typography>
                      <Typography
                        style={{ top: 'calc(75% - 40px)', position: 'absolute' }}
                        className={classes.lineLabel}
                        variant="h6"
                      >
                        {t('view.common.grid_multigrid.istd')}
                      </Typography>
                    </Grid>
                  ) : (
                    // </Grid>
                    <Grid item className={classes.sliderContainer}>
                      <Slider
                        min={150}
                        max={400}
                        className={classes.chromatoSlider}
                        valueLabelDisplay="auto"
                        orientation="vertical"
                        track={false}
                        value={this.state.chromatoSize}
                        aria-labelledby="vertical-slider"
                        onChange={chromatoSizeChange}
                        onChangeCommitted={chromatoSizeSave}
                      />
                    </Grid>
                  )}
                  <Grid item className={classes.multiGridContainer}>
                    <div
                      id="myAnalyseChromatoGridContainer"
                      className={clsx(
                        classes.chromatoContainer,
                        layout === 'one' && isIstdSync ? classes.chromatoFullWidth : '',
                      )}
                    >
                      <ErrorBoundaryGuard>
                        <AutoSizer>
                          {({ width, height }) => (
                            <MultiGrid
                              {...this.props}
                              {...this.state}
                              id="myAnalyseChromatoGrid"
                              ref={(ref) => (this.multiGridRef = ref)}
                              cellRenderer={isIstdSync ? _cellRendererIstd : _cellRenderer}
                              columnWidth={
                                layout === 'one' && isIstdSync ? width : chromatoSize / 0.8
                              }
                              columnCount={
                                isIstdSync
                                  ? data.length
                                  : Math.floor((width - 10) / (chromatoSize / 0.8))
                              }
                              // enableFixedColumnScroll
                              // enableFixedRowScroll
                              height={height}
                              rowHeight={chromatoSize}
                              rowCount={
                                isIstdSync
                                  ? 2
                                  : Math.ceil(
                                      data.length / Math.floor((width - 10) / (chromatoSize / 0.8)),
                                    )
                              }
                              width={width}
                              hideTopRightGridScrollbar
                              hideBottomLeftGridScrollbar
                              scrollToRow={isIstdSync ? undefined : scrollToItem}
                              scrollToColumn={isIstdSync ? scrollToItem : undefined}
                            />
                          )}
                        </AutoSizer>
                      </ErrorBoundaryGuard>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Paper>
          )
          this.addHorizontalScrollListener()
          return ret
        }
      },
    ),
  ),
)
