// © Fujitsu Limited 2021-2025
//
// This software is the confidential and proprietary information of Fujitsu Limited.
// You shall not disclose such Confidential Information and shall use it only in
// accordance with the terms of the license agreement you entered into with Fujitsu Limited.
//
// Unauthorized copying of this file, via any medium, is strictly prohibited.
// All rights reserved.

import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import { getBatchById } from '../../redux/batches/selectors'
import { getAnalysisById } from '../../redux/analyses/selectors'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tooltip from '@material-ui/core/Tooltip'
import { t } from 'i18next'

const styles = () => ({
  progression: {
    padding: 12,
    paddingTop: 0,
  },
})

const mapStateToProps = (state, ownProps) => {
  const { batches, analyses } = state
  const batch = getBatchById(batches, ownProps.match.params.batchId)
  const analysis = getAnalysisById(analyses, ownProps.match.params.analysisId)
  return {
    batch,
    analysis,
  }
}

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class Detail extends React.Component {
        render() {
          const { classes, batch, analysis, type, style } = this.props

          function CircularProgressWithLabel(props) {
            return (
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant="determinate"
                  style={{ color: '#ededed', position: 'absolute' }}
                  {...props}
                  value={100}
                />
                <CircularProgress variant="determinate" {...props} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="primary"
                    style={{ fontSize: '0.65rem', textAlign: 'center' }}
                  >{`${props.value.toFixed(1)}%`}</Typography>
                </Box>
              </Box>
            )
          }

          CircularProgressWithLabel.propTypes = {
            value: PropTypes.number.isRequired,
          }

          let analysisProgress = 0
          if (type !== 'analyse') {
            analysisProgress = batch.content.analyses_progression_requested
              ? batch.content.analyses_progression_requested
              : 0
          } else {
            analysisProgress = analysis.content.progression_requested
              ? analysis.content.progression_requested
              : 0
          }

          return (
            <Fragment>
              <Tooltip
                arrow
                title={
                  <div style={{ fontSize: 13, padding: 5 }}>
                    {t('view.common.circularProgress.calib')}
                  </div>
                }
              >
                <span style={{ ...style, marginRight: 10 }}>
                  {(!type || (type && type === 'calibration')) && (
                    <CircularProgressWithLabel
                      value={
                        batch.content.calibration_progression_requested
                          ? batch.content.calibration_progression_requested
                          : 0
                      }
                    />
                  )}
                </span>
              </Tooltip>
              <Tooltip
                arrow
                title={
                  <div style={{ fontSize: 13, padding: 5 }}>
                    {type && type === 'analyse'
                      ? t('view.common.circularProgress.progression')
                      : t('view.common.circularProgress.analysis_progress')}
                  </div>
                }
              >
                <span style={style}>
                  {(!type || (type && type === 'analyse')) && (
                    <CircularProgressWithLabel value={analysisProgress} />
                  )}
                </span>
              </Tooltip>
            </Fragment>
          )
        }
      },
    ),
  ),
)
